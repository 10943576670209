import styled from "styled-components";
//
// export const PalavrasChaveContainer = styled.div`
//     width: 100%;
//     transition: height 1s;
//     margin-bottom: 0.4rem;
//
//
//     div.title {
//         background-color: #c1c1c1;
//         width: 100%;
//         font-size: 1rem;
//         padding: 0.1rem 0.5rem;
//         cursor: pointer;
//     }
//
//     div.content {
//         animation: 1s slideDown;
//         height: 30vh;
//         background-color: white;
//         padding: 0.1rem 0.5rem;
//         box-shadow: 0px 0px 5px 1px #00000085;
//         margin: 0 1px;
//         overflow: auto;
//
//         @keyframes slideDown {
//             0% {opacity: 0; height: 0; overflow: hidden;}
//             80% {height: 30vh}
//             100% {opacity: 1; overflow: auto}
//         }
//     }
// `;

export const InputObservacoes = styled.textarea`
  border-radius: 7px;
  border: 1px solid #3a566e61;
  padding: 0.5rem;
  font-size: 1rem;
  max-height: 70%;

  &:focus {
    outline: 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-black {
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const PalavrasChaveContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgb(155, 155, 155);
  border-radius: 0.4rem;
  box-shadow: 1px 1px 7px 0px rgb(155, 155, 155);

  div.title {
    display: flex;
    padding: 10px;
    margin-bottom: 0;
    background-color: #3a566e;
    border-radius: 5px 5px 0px 0px;
    font-size: 15px;
    height: 40px;
    text-align: center;
    border-bottom: 1px solid rgb(113, 113, 113);
  }

  div.content {
    animation: 1s slideDown;
    height: 24.5vh;
    background-color: white;
    padding: 0.1rem 0.5rem;
    margin: 0 1px;
    margin-bottom: 5px;
    overflow: auto;

    @keyframes slideDown {
      0% {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      80% {
        height: 24.5vh;
      }
      100% {
        opacity: 1;
        overflow: auto;
      }
    }
  }
`;

export const ContainerFiltro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: 0.4rem;
  box-shadow: 1px 1px 7px 0px #0000004a;
  padding: 15px;

  .date-input-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .date-input {
      display: flex;
      .box {
        width: 32px;
        height: 32px;
        background-color: #e9ecef;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        margin-right: -2px;
        z-index: 2;
      }
      .input {
        /* width: 32px; */
        width: 160px;
        height: 32px;
        padding: 0 8px;

        :focus {
          outline: #3a566e;
        }
      }
    }
  }
`;

export const FiltroPeriodoContainer = styled.div`
  width: 100%;
  color: #3a566e;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 150px;
    font-weight: 600;
  }

  .options-container {
    display: flex;
    flex-direction: column;
  }

  .submit-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;

    #aplicar-filtro-monitoramento {
      border-radius: 7px;
      border: none;
      padding: 3px 15px;
      background-color: #45994b;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      margin-top: 3rem;
      box-shadow: 1px 1px 7px 0px #00000080;
    }
  }
`;

export const SingleOption = styled.div`
  width: 150px;
  border: 1px solid #c3c3c3;
  color: ${props => (props.selected ? "#fff" : "#3a566e")};
  background-color: ${props => (props.selected ? "#3a566e" : "#DEDEDE")};
  font-weight: ${props => (props.selected ? "bold" : "regular")};
  font-size: 15px;
  margin-bottom: 5px;
  padding: 2px 10px;
  border-radius: 5px;

  :hover {
    background-color: ${props => (props.selected ? "#3a566e" : "#f1f1f1")};
  }

  @media (max-height: 740px) {
    font-size: 12px;
  }
`;

export const ObservacoesDisplay = styled.div`
  background: #60798ede;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  box-shadow: 0px 4px 4px #00000040;
  width: 100%;
  margin-top: 10px;
  position: relative;

  .delete-icon {
    position: absolute;
    right: 5px;
    bottom: 4px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scroll-black {
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const HelperPalavraChave = styled.span`
  line-height: 1;
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 13px;
  color: #3a566e;

  b {
    font-weight: 600;
  }
`;
