import React from 'react'
import { useEffect } from 'react'
import { Trash2 } from 'react-feather'

import CardModalidade from './filtroModalidade'
import CardEstados from './filtroEstados'
import CardPortais from './filtroPortais'
import FiltroTipoData from './filtroTipoData'
import CardData from './filtroData'
import FiltroComprador from './filtroComprador'
import FiltroCodigo from './filtroCodigoPregao'
import FiltroMaterialServico from './filtroMaterialServico'
import CardPalavrasBuscaManual from './filtroPalavraBuscaManual'
import ImportarDePerfil from './ImportarDePerfil'
import CustomizedTabs from './components/Tab'
import FiltroMeEpp from './filtroMeEpp'
import BuscaManualStore from "../ManualSearch/Store"
import { useFiltroEdital } from './context/FiltroEditaisContext'

import { ContainerSearch } from './styles'

const TABS = ['Avançado', 'Básico']

const FiltroBuscaManual = () => {
  const {
    state,
    filtrarManual,
    simpleManualFilter,
    showFiltro,
    openFiltro,
    getInitialProfileData,
    onChangeComprador,
    onChangePregao,
    limpar,
    handleTab
  } = useFiltroEdital()
  const refContainer = React.useRef()

  useEffect(() => {
    openFiltro()
    getInitialProfileData()
  }, [])

  const handleSelectedTab = value => handleTab(value)

  const handleClean = () => limpar()

  const props = {
    handleClean: handleClean,
    onChangeComprador: onChangeComprador,
    onChangePregao: onChangePregao
  }

  const handleClick = () => {
    state.tab === 0 ? filtrarManual() : simpleManualFilter()
    BuscaManualStore.pagination.changePage(1)
  }

  console.log(!state.filtro)

  return (
    <>
      {!state.filtro && (
        <ContainerSearch ref={refContainer}>
          <div
            style={{
              display: 'flex',
              marginTop: '50px',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '50px'
            }}
          >
            <div className="width-pattern" style={{ margin: '10px' }}>
              <CustomizedTabs
                handleSelectedTab={handleSelectedTab}
                tabOptions={TABS}
                value={state.tab}
              />
            </div>

            {state.tab === 0 ? (
              <Licitacoes {...props} />
            ) : (
              <Licitacao {...props} />
            )}

            <div className="action-buttons">
              <button
                id="filtrar-editais"
                className="icon-scale-xs"
                onClick={handleClick}
              >
                FILTRAR
              </button>
              <button
                id="fechar-modal-filtro-editais"
                className="icon-scale-xs"
                onClick={() => showFiltro(true)}
              >
                FECHAR
              </button>
            </div>
          </div>
        </ContainerSearch>
      )}
    </>
  )
}

const Licitacoes = ({ handleClean, onChangeComprador, onChangePregao }) => {
  useEffect(() => {
    onChangeComprador('')
    onChangePregao('')
  }, [])

  return (

    <>
      <div
        className="width-pattern"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px'
        }}
      >
        <ImportarDePerfil />
        <button
          id="limpar-busca-manual"
          className="icon-scale-xs"
          onClick={handleClean}
        >
          <Trash2 /> LIMPAR
        </button>
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <CardData />
        <CardEstados />
        <CardPortais />
        <CardModalidade />
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <CardPalavrasBuscaManual />
        <FiltroTipoData />
      </div>
      <h3>FILTROS ESPECÍFICOS PARA O PORTAL COMPRASNET: </h3>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <FiltroMeEpp />
        <FiltroMaterialServico />
      </div>
    </>
  )
}

const Licitacao = ({ onChangeComprador, onChangePregao }) => {
  useEffect(() => {
    onChangeComprador('')
    onChangePregao('')
  }, [])

  return (
    <>
      <div
        className="width-pattern"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          margin: '10px'
        }}
      ></div>
      <FiltroCodigo />
      <FiltroComprador />
    </>
  )
}

export default FiltroBuscaManual
