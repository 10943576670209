import { observable, action } from "mobx";

class ModalLateralStore {
  @observable isActive = false;
  @observable animation = false;
  @observable id = "Deu certo!!";

  @action setModal = (status, id) => {
    this.isActive = status;
    this.id = id;
  };

  @action toggleModal = () => {
    this.animation = true;
    setTimeout(() => {
      this.isActive = false;
      this.animation = false;
    }, 850);
  };

  @action limparVariaveis = () => {
    this.isActive = false;
    this.animation = false;
    this.id = "Deu certo!!";
  };
}

const modalLateralStore = new ModalLateralStore();
export default modalLateralStore;
