import React, { useContext, useEffect } from 'react'
import { File as FileIcon, Search, XCircle, Check } from 'react-feather'

import {
  FormatDateData,
  removeAcento,
  removeSpecialChar,
  ToastError
} from '../../../../../../../Stores/Funcoes/FuncoesGerais'
import { FileInput } from '../dadosPdf/styles'
import { ReactTooltip } from '../../../../../../../globalStyles'
import { DocumentosContext } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DadosPdfContext } from '../dadosPdf/context'
import { PropostaEspecificaContext } from '../../context'
import PDFMaker from '../dadosPdf/pdf/MyDocument'

import {
  ContainerDocumentos,
  FileInputLabel,
  DocumentSelectText,
  SingleDocument
} from './styles'

const DocumentosProposta = () => {
  const {
    setFilesProposta,
    setDocsProposta,
    filesProposta,
    docsProposta,
    docsHabilitacao
  } = useContext(DocumentosContext)
  const { pdf, setPdf, setPdfFile } = useContext(DadosPdfContext)
  const {
    edital: {
      comprador: { codigo },
      numero
    },
    rows
  } = useContext(PropostaEspecificaContext)
  const filename = `Proposta_${numero}_${codigo}.pdf`

  useEffect(() => {
    if (!pdf) {
      const newPdf = { name: filename, loading: false }
      setPdf(newPdf)
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const handleDocument = e => {
    let document = e.target.files[0]
    let jaExiste, name, file
    if (document) {
      name = removeSpecialChar(removeAcento(document.name)) // Tratamento de caracteres especiais
      file = new File([document], name)
      console.log(file, document)
      jaExiste = docsProposta.find(doc => doc.name === file.name)
      if (file.size >= 50000000) {
        return ToastError('O tamanho maximo deve ser de 50mb, conforme regra do Comprasnet')
      }
      if (!jaExiste)
        jaExiste = docsHabilitacao.find(doc => doc.name === file.name)
    }
    e.target.value = null

    if (jaExiste) {
      return ToastError('Já existe um documento com esse nome.')
    }
    setFilesProposta([...filesProposta, file])
    setDocsProposta([...docsProposta, { name: file.name }])
  }

  return (
    <ContainerDocumentos headerColor="#297788" left>
      <ReactTooltip effect="solid" />
      <div className="header">
        <FileIcon />
        <span>ANEXAR DOCUMENTOS DA PROPOSTA</span>
      </div>
      <div className="selecionar-arquivo">
        <FileInputLabel
          htmlFor="enviar-documento"
          className="position-relative"
          data-tip="Tamanho máximo 50mb."
          data-type="warning"
          data-effect="solid"
          data-place="bottom"
        >
          <div className="position-absolute" style={{ pointerEvents: 'none' }}>
            <Search />
          </div>
          <DocumentSelectText htmlFor="select-document-proposta">
            SELECIONE O DOCUMENTO
          </DocumentSelectText>
          <FileInput
            type="file"
            id="select-document-proposta"
            style={{ position: 'absolute', width: '100%', left: 0 }}
            onChange={handleDocument}
          />
        </FileInputLabel>
      </div>
      <div className="body custom-scroll-blue">
        {pdf &&
          <SingleDocument>
            <div className="name">{pdf.name}</div>
            <PDFMaker filename={filename} />
            <div
              className="close-button ml-auto"
              onClick={() => {
                setPdf(null);
                setPdfFile(null);
                ReactTooltip.hide();
              }}
              data-tip={'REMOVER PDF'}
              data-type="error"
              data-place="left"
            >
              <XCircle className="icon-scale-xs" />
            </div>
          </SingleDocument>
        }
        {docsProposta.map((documento, i) => {
          const { loading, name, created_at, error, enviado } = documento

          const handleDelete = () => {
            if (enviado) return
            setFilesProposta(filesProposta.filter(f => f.name !== name))
            setDocsProposta(docsProposta.filter(f => f.name !== name))
            ReactTooltip.hide()
          }

          return (
            <SingleDocument key={i} loading={loading} enviado={enviado}>
              <div className="name">{name}</div>
              {name === filename && <PDFMaker filename={filename} />}
              {enviado && (
                <Check
                  className="enviado"
                  data-tip="Documento já foi enviado ao portal"
                  data-type="success"
                  data-place="right"
                />
              )}
              {error ? (
                <div className="document-error">{error}</div>
              ) : (
                <div className="date">{FormatDateData(created_at)}</div>
              )}
              {loading ? (
                <div className="loading-document-spinner">
                  <FontAwesomeIcon icon="spinner" spin />
                </div>
              ) : (
                <div
                  className="close-button"
                  onClick={handleDelete}
                  data-tip={enviado ? 'EXCLUIR DO PORTAL' : 'REMOVER DOCUMENTO'}
                  data-type="error"
                  data-place="left"
                >
                  <XCircle className="icon-scale-xs" />
                </div>
              )}
            </SingleDocument>
          )
        })}
      </div>
    </ContainerDocumentos>
  )
}

export default DocumentosProposta
