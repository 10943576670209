import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  color: #fff;
  border-radius: 12px;
  padding: 2px 12px;
  font-size: 85%;
  font-weight: 600;
  box-shadow: 1px 1px 4px #0000005c;
  user-select: none;
`;

const BadgeStatus = ({ status, background }) => {
  return (
    <Container background={background}>
      {status}
    </Container>
  )
}

export default BadgeStatus
