import React, { useState, useEffect } from "react";
import { CardMsg } from "../../../globalStyles";
import { Type, RotateCcw } from "react-feather";
import { NomeacaoContainer, OpcaoDinamica, OpcaoEspecifica } from "../styles";

export const NomeacaoDeArquivos = ({ changeFileName, nomeacaoEditais }) => {
  const [dinamicas, setDinamicas] = useState([]);
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");

  useEffect(() => {
    if (!nomeacaoEditais) {
      setDinamicas([]);
      return setResult("");
    }
    if (nomeacaoEditais !== result.slice(1)) {
      let dinamicas = nomeacaoEditais.match(/\{\w*/g) || [];
      dinamicas = dinamicas.map(el => el.slice(1));
      setDinamicas(dinamicas);
      setResult(`_${nomeacaoEditais}`);
    }
  }, [nomeacaoEditais]);

  const handleDinamicas = din => {
    const newDinamicas = dinamicas.includes(din)
      ? dinamicas.filter(el => el !== din)
      : [...dinamicas, din];
    const newResult = newDinamicas.includes(din)
      ? `${result}_{${din}}`
      : result.replace(`_{${din}}`, "");

    setResult(newResult);
    changeFileName(newResult);
    setDinamicas(newDinamicas);
  };

  const handleChange = ({ target: { value } }) => {
    value.slice(-1) === " " ? setInput(`${input}-`) : setInput(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setResult(`${result}_${input}`);
    changeFileName(`${result}_${input}`);
    setInput("");
  };

  const handleLimpar = () => {
    setResult("");
    changeFileName("");
    setDinamicas([]);
    setInput("");
  };

  return (
    <CardMsg justifyContent="space-between" margin="10px 0 0" height="auto">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Type />
        &nbsp;&nbsp;
        <div className="d-flex flex-column">
          <div style={{ padding: "0px 10px 0px 10px" }}>
            NOMEAÇÃO DE ARQUIVOS
          </div>
          <small style={{ fontSize: "10px", marginLeft: "10px" }}>
            Selecionar a estrutura de nomeação dos editais baixados dentro do
            sistema
          </small>
        </div>
      </div>
      <NomeacaoContainer>
        <div id="opcoes-dinamicas-nomeacao">
          <OpcaoDinamica
            className="icon-scale-xs"
            onClick={() => handleDinamicas("DATA")}
            selected={dinamicas.includes("DATA")}
          >
            DATA
          </OpcaoDinamica>
          <OpcaoDinamica
            className="icon-scale-xs"
            onClick={() => handleDinamicas("UASG")}
            selected={dinamicas.includes("UASG")}
          >
            UASG
          </OpcaoDinamica>
          <OpcaoDinamica
            className="icon-scale-xs"
            onClick={() => handleDinamicas("COMPRADOR")}
            selected={dinamicas.includes("COMPRADOR")}
          >
            COMPRADOR
          </OpcaoDinamica>
          <OpcaoDinamica
            className="icon-scale-xs"
            onClick={() => handleDinamicas("NUMERO")}
            selected={dinamicas.includes("NUMERO")}
          >
            NÚMERO
          </OpcaoDinamica>
          <OpcaoDinamica
            className="icon-scale-xs"
            onClick={() => handleDinamicas("PORTAL")}
            selected={dinamicas.includes("PORTAL")}
          >
            PORTAL
          </OpcaoDinamica>
          <OpcaoEspecifica onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="ADICIONAR PALAVRA ESPECÍFICA"
              value={input}
              onChange={handleChange}
            />
            <button type="submit" className="icon-scale">
              +
            </button>
          </OpcaoEspecifica>
        </div>
        <div id="visualizacao-nomeacao">
          {result ? (
            result.slice(1).concat(".zip")
          ) : (
              <span style={{ color: "grey" }}>
                Ex: 21-01-2020_WaveCode_125454_ComprasNet
              </span>
            )}
          <RotateCcw
            className="icon-scale"
            style={{ marginLeft: "auto", color: "#8e8585", marginRight: "5px" }}
            data-tip="LIMPAR"
            data-place="left"
            data-type="error"
            data-effect="solid"
            onClick={handleLimpar}
          />
        </div>
      </NomeacaoContainer>
    </CardMsg>
  );
};
