import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0;
  animation: 2200ms fadeIn forwards;

  img {
    width: 200px;
  }

  button {
    margin-top: 73px;
    border: none;
    padding: 8px 30px;
    font-weight: 500;
    color: white;
    background: ${props => (props.disabled ? 'grey' : '#0086bb')};
    border-radius: 19px;
    box-shadow: 1px 1px 2px 0px #000000bf;
    font-size: 17px;
  }
`
