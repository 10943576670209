import React, { createContext, useState } from 'react'

const ModalContext = createContext(null)

const ModalProvider = ({ children }) => {
  const [modalQualificar, setQualificar] = useState(false)
  const [modalReprovar, setReprovar] = useState(false)
  const [modalConfirmacao, setConfirmacao] = useState(false)
  const [modalAprovar, setAprovar] = useState(false)

  const showConfirmacao = () => {
    setConfirmacao(true)
    setTimeout(() => {
      setConfirmacao(false)
    }, 3000)
  }

  const openModalReprovar = () => {
    setReprovar(true)
  }

  const openModalQualificar = () => {
    setQualificar(true)
  }

  const openModalAprovar = () => {
    setAprovar(true)
  }

  const closeModal = () => {
    setQualificar(false)
    setReprovar(false)
    setAprovar(false)
  }

  return (
    <ModalContext.Provider
      value={{
        openModalQualificar,
        modalQualificar,
        closeModal,
        openModalReprovar,
        showConfirmacao,
        modalConfirmacao,
        modalReprovar,
        modalAprovar,
        openModalAprovar
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
