import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Minus } from "react-feather";
import { inject, observer } from "mobx-react";
import { sliceString } from "../../Services/funcoes";
import { Monitorar } from "./BotoesMenu";
import { FormatDateData, FormatDateHora } from "../../Stores/Funcoes/FuncoesGerais";
// import { BtnMonitorar } from "./ModalMonitorarLicitacoes/BtnMonitorar";
// import { MenuAcoes } from "./MenuLicitacoes";

@inject("LicitacoesPaginadaStore")
@observer
class SelecinarLicitacoesParaMonitorar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      animation: false
    };
  }

  render() {
    const { licitacaoMonitorada } = this.props;
    const {
      DelllicitacaoMonitorada,
      excluirAgendada,
      isActive,
      animation,
      togleModal,
      status_limite_monitoramento,
      numero_monitorar_chat,
      AddLicitacoesMonitoradas
    } = this.props.LicitacoesPaginadaStore;
    return (
      <>
        {/* <div
          style={{
            background: "#3a7080",
            width: "3px",
            height: "79px",
            marginTop: "-7px",
            marginRight: "10px"
          }}
        />
        <div style={{ textAlign: "center", color: "white" }}>
          <FontAwesomeIcon
            onClick={togleModal}
            className="icon-scale icon-header"
            icon="comment-dots"
            style={{ color: animation ? "#6ecad1" : "#fff" }}
            data-tip="MONITORAR"
            data-effect="solid"
            data-type="light"
          />
          <div className="contador-mensagem">Monitorar</div>
        </div> */}
        <Monitorar onClick={togleModal} />
        <LicitacoesSelecionadas
          isActive={isActive}
          animation={animation}
          togleModal={() => togleModal()}
          licitacaoMonitorada={licitacaoMonitorada}
          delllicitacaoMonitorada={DelllicitacaoMonitorada}
          excluirAgendada={excluirAgendada}
          status_limite_monitoramento={status_limite_monitoramento}
          numero_monitorar_chat={numero_monitorar_chat}
          AddLicitacoesMonitoradas={AddLicitacoesMonitoradas}
        />
      </>
    );
  }
}

export default SelecinarLicitacoesParaMonitorar;

const LicitacoesSelecionadas = ({
  isActive,
  animation,
  togleModal,
  licitacaoMonitorada,
  delllicitacaoMonitorada,
  excluirAgendada,
  status_limite_monitoramento,
  numero_monitorar_chat,
  AddLicitacoesMonitoradas
}) => {
  return (
    <>
      {isActive && (
        <ContainerLicitacoes animation={animation}>
          <HearderContainer>
            <div
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <FontAwesomeIcon icon="sync" />
              <div style={{ marginLeft: "15px" }}>Licitações selecionadas</div>
            </div>
            <div className={"icon-scale"} onClick={() => togleModal()}>
              <Minus />
            </div>
          </HearderContainer>

          <div
            style={{
              padding: "20px",
              width: "100%",
              height: "95%",
              marginTop: "38px"
            }}
          >
            <div style={{ textAlign: "center" }}>
              LICITAÇÕES MONITORADAS{" "}
              <span style={{ marginLeft: "20px", color: "#007900" }}>
                <b>{numero_monitorar_chat}/10</b>
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontSize: "11px",
                color: "#a5a5a5",
                marginTop: "10px"
              }}
            >
              VOCÊ PODE MONITORAR 10 LICITAÇÕES NESTE PLANO
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "11px",
                marginTop: "25px",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                padding: "5px",
                fontWeight: "bold"
              }}
            >
              <div style={{ width: "100px", textAlign: "center" }}>
                N. PREGÃO
              </div>
              <div style={{ width: "280px", textAlign: "center" }}>
                COMPRADOR
              </div>
              <div
                style={{
                  width: "120px",
                  textAlign: "center",
                  marginLeft: "5px"
                }}
              >
                DATA
              </div>
              <div
                style={{
                  width: "250px",
                  textAlign: "center"
                }}
              >
                SITUAÇÃO
              </div>
              <div style={{ width: "130px", textAlign: "center" }}>STATUS</div>
            </div>

            <div
              style={{
                width: "100%",
                height: "66%",
                marginTop: "5px",
                overflow: "auto"
              }}
            >
              {licitacaoMonitorada.map(licitacao => {
                const {
                  comprador,
                  codigo,
                  data_disputa,
                  situacao,
                  id,
                  monitorar_chat
                } = licitacao;
                return (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "11px",
                      borderBottom: "1px solid #A5A5A5",
                      padding: "5px",
                      textAlign: "center"
                    }}
                  >
                    <div style={{ width: "100px" }}>{codigo}</div>
                    <div style={{ width: "280px" }}>
                      <TextoLicita>{comprador.nome}</TextoLicita>
                    </div>
                    <div style={{ width: "140px", marginLeft: "5px" }}>
                      {typeof data_disputa === 'string' ? data_disputa : `${FormatDateData(data_disputa)} ${FormatDateHora(data_disputa).slice(0, 5)}`}
                    </div>
                    <div style={{ width: "250px" }}>
                      {sliceString(situacao.descricao, 12)}
                    </div>

                    <div style={{ display: "flex", width: "150px" }}>
                      <div
                        style={{
                          padding: "2px 5px",
                          borderRadius: "5px",
                          background: monitorar_chat ? "#12ac35" : "#268ccf",
                          color: "white",
                          fontSize: "10px"
                        }}
                      >
                        {monitorar_chat ? "MONITORANDO" : "A CONFIRMAR"}
                      </div>
                      {monitorar_chat &&
                        situacao.descricao.slice(0, 8) === "Agendado" && (
                          <div
                            onClick={() => excluirAgendada(id)}
                            className="icon-scale-xs"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "18px",
                                height: "18px",
                                borderRadius: "3px",
                                background: "#e08484",
                                color: "white",
                                fontSize: "10px",
                                fontWeight: 700,
                                marginLeft: "5px"
                              }}
                            >
                              x
                            </div>
                          </div>
                        )}

                      {!monitorar_chat && (
                        <div
                          onClick={() => delllicitacaoMonitorada(licitacao)}
                          className="icon-scale"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                              background: "#a5a5a5",
                              color: "white",
                              fontSize: "10px",
                              marginLeft: "5px"
                            }}
                          >
                            x
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {status_limite_monitoramento ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#42504e",
                  marginTop: "10px"
                }}
              >
                <FontAwesomeIcon icon="exclamation-circle" size={"2x"} />
                <div>LIMITE EXCEDIDO DE MONITORAMENTO DE LICITAÇÕES</div>
              </div>
            ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px"
                    }}
                  >
                    <div
                      onClick={() => AddLicitacoesMonitoradas()}
                      className="icon-scale"
                      style={{
                        background: "#007900",
                        padding: "2px 25px",
                        color: "white",
                        borderRadius: "7px",
                        fontSize: "13px"
                      }}
                    >
                      CONFIRMAR MONITORAMENTO
                  </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "11px",
                      color: "#a5a5a5",
                      marginTop: "10px"
                    }}
                  >
                    CLICK AQUI PARA MONITORAR AS LICITAÇÕES SELECIONADAS
                </div>
                  <small
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      color: "#ff5b5b"
                    }}
                  >
                    Esse processo é irreversível, apenas &nbsp;
                  <b>licitações agendadas</b> &nbsp; poderão ser removidas após
                  essa confirmação.
                </small>
                </>
              )}
          </div>
        </ContainerLicitacoes>
      )}
    </>
  );
};

export const TextoLicita = styled.div`
  width: 250px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`;

export const ContainerLicitacoes = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
  position: absolute;
  z-index: 88888;
  width: 753px;
  height: 700px;
  right: 22px;
  left: calc(50% - 376.5px);
  top: 105px;
  bottom: 50px;
  box-shadow: 0px 2px 5px 1px #6b6b6b69;
  border-radius: 8px;
  animation: ${props =>
    props.animation
      ? "scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940)"
      : "scale-out-tr 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530)"};

  @keyframes scale-in-top {
    0% {
      transform: scale(0);
      transform-origin: 50% 0%;
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform-origin: 50% 0%;
      opacity: 1;
    }
  }

  @keyframes scale-out-tr {
    0% {
      transform: scale(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scale(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
`;

export const HearderContainer = styled.div`
  color: white;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  background: #3f889a;
  position: absolute;
  z-index: 88888;
  width: 753px;
  height: 35px;
  top: -1px;
  bottom: 50px;
  border-radius: 8px 8px 0px 0px;
`;
