import React from 'react';
import useEnviarProposta from './LogicaDeEnvio';


const BotaoEnviar = ({ handleNextStep }) => {
  const { enviar } = useEnviarProposta();

  const handleSubmit = () => {
    enviar();
    handleNextStep();
  }
  return (
    <button id="action-submit" onClick={handleSubmit}>ENVIAR</button>
  )
}

export default BotaoEnviar
