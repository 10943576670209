import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { ContainerNav } from "./styles";
import { useKeyPress } from "../../../UsefulComponents/useKeyPress";

const Navegacao = inject(
  "DetalhesEditalStore",
  "BuscaManualStore"
)(
  observer(({ DetalhesEditalStore, BuscaManualStore }) => {
    const {
      setDisableNext,
      disableNext,
      disablePrevious,
      setDisablePrevious
    } = BuscaManualStore;
    return (
      <NavegacaoComStore
        props={DetalhesEditalStore}
        setDisablePrevious={setDisablePrevious}
        disablePrevious={disablePrevious}
        disableNext={disableNext}
        setDisableNext={setDisableNext}
        {...DetalhesEditalStore}
      />
    );
  })
);

const NavegacaoComStore = ({
  props,
  setDisableNext,
  disableNext,
  disablePrevious,
  setDisablePrevious
}) => {
  const {
    toggleModal,
    editais,
    id,
    total_registros,
    setId,
    fetchMore,
    fetching,
    consultarItens,
    toggleModalReprovar,
    toggleModalAprovar,
    toggleModalQualificar,
    closeConfirmationModal
  } = props;
  const { modalAprovar, modalReprovar, modalQualificar, mudarStatus } = props;
  const editaisId = editais.map(ed => ed.id);

  const ArrowLeft = useKeyPress("ArrowLeft");
  const ArrowRight = useKeyPress("ArrowRight");
  const Delete = useKeyPress("Delete");
  const Enter = useKeyPress("Enter");
  const Esc = useKeyPress("Escape");

  const modalFechado = !modalAprovar && !modalReprovar && !modalQualificar;

  const { status } = editais.find(ed => ed.id === id);

  useEffect(() => {
    if (Esc) return modalFechado ? toggleModal() : closeConfirmationModal();
    if (Delete) {
      if (!modalFechado) return closeConfirmationModal();
      switch (status) {
        case "reprovado":
          return;

        default:
          return toggleModalReprovar();
      }
    }
    if (Delete && status !== "reprovado") return toggleModalReprovar();
    if (Enter) {
      if (modalReprovar) return mudarStatus("reprovado");
      switch (status) {
        case "qualificado":
          return !modalFechado ? mudarStatus("aprovado") : toggleModalAprovar();
        case "neutro":
          return !modalFechado
            ? mudarStatus("qualificado")
            : toggleModalQualificar();
        case "reprovado":
          return !modalFechado
            ? mudarStatus("qualificado")
            : toggleModalQualificar();
        default:
          break;
      }
    }
  }, [status, Delete, Enter, Esc]);

  const atual = editaisId.lastIndexOf(id) + 1;

  useEffect(() => {
    if (ArrowRight && modalFechado) handleNext();
    if (ArrowLeft && modalFechado) handlePrevious();
  }, [ArrowLeft, ArrowRight]);

  const handleNext = async () => {
    if (atual === total_registros) return;
    if (fetching) return;
    const next = editaisId[atual];
    next ? setId(next, true) : (await fetchMore(1)) && setDisableNext(true);
    await consultarItens();
  };

  const handlePrevious = async () => {
    if (atual === 1) return;
    if (fetching) return;
    const previous = editaisId[atual - 2];
    previous
      ? setId(previous)
      : (await fetchMore(-1)) && setDisablePrevious(true);
    await consultarItens();
  };

  return (
    <ContainerNav>
      <div className='counter'>{`${atual}/${total_registros} EDITAIS`}</div>
      <div className='actions'>
        {atual !== 1 && (
          <div
            className='previous button icon-scale-xs'
            onClick={disablePrevious ? null : () => handlePrevious()}
          >
            <FontAwesomeIcon icon='angle-double-left' />
          </div>
        )}
        {atual !== total_registros && (
          <div
            className='next button icon-scale-xs'
            onClick={disableNext ? null : () => handleNext()}
          >
            <FontAwesomeIcon icon='angle-double-right' />
          </div>
        )}
        <div
          className='close button icon-scale-xs'
          onClick={() => toggleModal(null)}
        >
          <FontAwesomeIcon icon='times' />
        </div>
      </div>
    </ContainerNav>
  );
};

export default Navegacao;
