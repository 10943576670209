import React from "react";
import { ZoomIn, ZoomOut, Airplay } from "react-feather";
import { ReactTooltip } from "../../../globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Usability = ({
  handleFullScreen,
  sizeControl,
  som,
  handleSom
  /* fullScreen */
}) => {
  const { inc, dec } = sizeControl;
  return (
    <div
      style={{
        display: "flex",
        color: "#fff",
        marginLeft: "auto",
        alignItems: "center",
        width: "160px",
        justifyContent: "space-between"
      }}
    >
      <ReactTooltip />

      <div className="d-flex justify-content-between" style={{ width: "55%" }}>
        <ZoomIn
          size={34}
          data-tip="AUMENTAR FONTE"
          data-type="info"
          data-place="bottom"
          data-effect="solid"
          className="icon-scale"
          onClick={inc}
        />
        <ZoomOut
          size={34}
          data-tip="DIMINUIR FONTE"
          data-type="info"
          data-place="bottom"
          data-effect="solid"
          className="icon-scale"
          onClick={dec}
        />
      </div>

      <Airplay
        style={{ margin: "10px" }}
        size={35}
        data-tip="TELA CHEIA"
        data-type="warning"
        data-place="bottom"
        data-effect="solid"
        className="icon-scale"
        onClick={handleFullScreen}
      />

      <div
        onClick={() => handleSom()}
        style={{ fontSize: "24px", width: "40px" }}
        data-tip={som ? "DESATIVAR SOM" : "ATIVAR SOM"}
        data-type="info"
        data-place="bottom"
        data-effect="solid"
        className="icon-scale"
      >
        <FontAwesomeIcon
          id="bell"
          style={{ color: som ? "#ffd311" : "white" }}
          icon={som ? "bell" : "bell-slash"}
        />
      </div>
    </div>
  );
};
