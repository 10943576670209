import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  width: 450px;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 400px;
  }
`;

export const Badge = styled.div`
  font-size: 11px;
  color: white;
  padding: 4px 10px;
  border-radius: 7px;
`;

export const BadgeVerde = styled(Badge)`
  background: #007900;
`;

export const BadgeAzull = styled(Badge)`
  background: #0496cf;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 3.5rem 1rem;
  font-size: 16px;

  .date-box {
    margin-bottom: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 0.9em;
  }

  .hour-box {
    font-weight: 300;
    display: flex;
    align-items: center;
    font-size: 0.9em;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
    /* flex-direction: column; */

    margin: 1rem 0.8rem;
  }

  @media (max-width: 1200px) {
    font-size: 13px;
    /* flex-direction: column; */
    width: 105%;
    margin: 0.5rem 0;
  }
`;

export const LicitacaoInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #3a566e;
  font-size: 0.9rem;
  overflow: auto;
  width: 103%;

  @media (max-width: 1400px) {
    font-size: 0.8rem;
    width: 105%;
  }

  @media (max-width: 1200px) {
    font-size: 0.7rem;
    width: 107%;
  }

  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CardWarpPaginacao = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: flex-start;
  position: absolute;
`;

export const CardWarpMsg = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 15px 15px 15px;
  width: 100%;
  font-size: 12px;
`;
export const CardMsg = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding: 15px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 4px 0.1px #00000073;

  @media (max-width: 1200px) {
    padding: 10px 7px;
  }
`;
export const CardBodyMsg = styled.div`
  display: flex;
  width: ${props => (props.width ? props.width : "100%")};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"};
  justify-content: space-between;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : null};
`;

export const BtnEditarProposta = styled.button`
  background-color: #d97033;
  border: 1px solid #d97033;
  border-radius: 10px;
  color: white;
  min-width: 80px;
  min-heigth: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;

  a {
    color: white;
    link-decoration: none;

    :hover {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
  }

  :hover {
    cursor: pointer;
    color: white;
    background-color: #c16132;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  :active {
    background-color: #ae572c;
  }
`;

export const BtnDownload = styled.button`
  background-color: #1093d9;
  border: 1px solid #1093d9;
  border-radius: 10px;
  color: white;
  min-width: 80px;
  min-heigth: 100px;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;

  a {
    color: white;
    text-decoration: none;

    :hover {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
  }

  :hover {
    cursor: pointer;
    color: white;
    background-color: #0f84c4;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  :active {
    background-color: #0f6ca5;
  }
`;

export const BtnVisializar = styled.button`
  background-color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 10px;
  color: white;
  min-width: 80px;
  min-height: 100px;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;

  a {
    color: white;
    text-decoration: none;

    :hover {
      color: white;
      cursor: pointer;
      text-decoration: none;
    }
  }

  :hover {
    cursor: pointer;
    color: white;
    background-color: #46974a;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  :active {
    background-color: #469048;
  }
`;
