import styled from "styled-components";

export const PlanoContainer = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 17px;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 1px #9e9e9e;
  background: #fff;
  min-height: 100px;
  margin-bottom: ${props => (props.last ? 0 : "15px")};
`;

export const EmpresaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  span {
    font-size: 120%;
    font-weight: 700;
    color: #3a566e;
  }
  small {
    font-weight: 300;
    color: #a5a5a5;
  }

  .teste-gratis {
    font-size: 13px;
    color: #6faf48;
    margin-top: auto;
    font-style: italic;
    font-weight: 400;
  }
`;

export const ModulosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: auto;
`;

export const SingleModulo = styled.div`
  color: ${props => (props.status ? "#3a566e" : "grey")};
  font-weight: ${props => (props.status ? "500" : "300")} !important;
  font-style: italic;
  font-size: 93% !important;
  margin-right: 4px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  span {
    margin-right: 4px;
    width: 20px;
  }
`;

export const DiasRestantesLabel = styled.div`
  display: flex;
  margin: auto 15px auto auto;
  font-size: 90%;
  font-weight: 300;
`;

export const VencimentoLabel = styled(DiasRestantesLabel)`
  color: ${props => (props.status ? "#da7b7b" : "#000")};
  margin-left: 0;

  span {
    font-weight: 500;
    margin-left: 4px;
  }
`;

export const DaysRemainingContainer = styled.div`
  margin-left: 10px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* border: 1px solid black; */
  border-radius: 5px;
  box-shadow: 1px 1px 4px 0px #292929;
  font-weight: 600;
  padding: 5px 15px;
  background: ${props => getFallbackBg(props.status)};
  background: ${props => getBgColor(props.status)};
  text-shadow: 1px 1px 4px #313131;
  font-size: 83%;

  span {
  }
`;

// const getColor = color => {
//   switch (color) {
//     case "longDue":
//       return "#fff";
//     case "almostDue":
//       return "";
//     case "overDue":
//       return "#fff";
//   }
// };

const getBgColor = bgColor => {
  switch (bgColor) {
    case "longDue":
      return "linear-gradient(to right,#159957,#159399bf)";
    case "almostDue":
      return "linear-gradient(to right,#f7971eb0,#ffd200b3)";
    case "overDue":
      return "linear-gradient(to right, #d66d75, #e29587)";
    default:
      return "#000";
  }
};

const getFallbackBg = bgColor => {
  switch (bgColor) {
    case "longDue":
      return "#159957";
    case "almostDue":
      return "#f7971eb0,";
    case "overDue":
      return "#d66d75";
    default:
      return "#000";
  }
};
