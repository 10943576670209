import React from "react";
import {AlertTriangle, MessageCircle} from "react-feather";
import {
  CardMD,
  CardMDBarra,
  CardMDBody,
  CardMDHearder,
  Titulo,
  MessagesContainer
} from "../styleDashboard";
import { CardHoraMsg, CardTextoMsg, ContainerMsg } from "./styleMensagens";
import {CardNull} from "../Editais/styleEditais";

const BodyMsg = ({ item }) => {
  const { origem, data_hora, texto } = item;
  let hora = data_hora.substr(10);
  let data = data_hora.substr(0, 10);

  return (
    <ContainerMsg>
      <CardHoraMsg>
        Origem:
        <span>{origem}</span>
        <div style={{ marginTop: "5px" }}> {data}</div>
        <div> {hora}</div>
      </CardHoraMsg>

      <CardTextoMsg>
        <p>{texto}</p>
      </CardTextoMsg>
    </ContainerMsg>
  );
};




export const Mensagens = ({ data }) => {
  const { ultimas_msg } = data;

  return (
    <CardMD color={"#c0263b"} height={"200px"}>
      <CardMDHearder>
        <div style={{ marginRight: "15px" }}>
          <MessageCircle size={"35px"} />
        </div>
        <Titulo>
          <h5>ÚLTIMAS MENSAGENS</h5>
          <span>Últimas Mensagens recebidas</span>
        </Titulo>
      </CardMDHearder>
      <CardMDBarra />
      <CardMDBody>
        <MessagesContainer>

          {ultimas_msg.length > 0 ? ultimas_msg.map(item => {
            return <BodyMsg item={item} />;
          })

            : <CardNull>
              <AlertTriangle size={'50px'}/>
              <div>Não há Mensagens</div>
          </CardNull>}
        </MessagesContainer>
      </CardMDBody>
    </CardMD>
  );
};
