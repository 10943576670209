import $ from 'jquery'
import { observable, action } from 'mobx'

import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'
import Api from '../../../../Stores/Conexao/conexao'
import { tratarDadosEditais } from '../../Components/tratarDadosEditais'
import DetalhesEditalStore from '../../../../Components/Modules/ModalDetalhes/store'

class BuscaManualStore {
  @observable isFetching = false
  @observable tab = 0

  @observable typeSearch = ''

  @observable disableNext = false
  @observable disablePrevious = false

  @action setDisableNext = value => (this.disableNext = value)
  @action setDisablePrevious = value => (this.disablePrevious = value)

  @action setTab = tab => (this.tab = tab)

  @observable warningErrorInRequest = 'NENHUM EDITAL ENCONTRADO'

  @observable total_paginas = 0
  @observable total_registros = 0
  @observable editais = []

  @observable ordenacao = 'data_disputa'
  @observable crescente = true

  @action changeEditais = (id, status) =>
  (this.editais = this.editais.map(ed =>
    ed.id === id ? { ...ed, status: status } : ed
  ))

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }

  @observable filterOpen = true
  @action toggleFilter = () => (this.filterOpen = !this.filterOpen)

  @observable filtros = {
    dmin: new Date().toLocaleDateString('pt-br'),
    dmax: new Date().toLocaleDateString('pt-br'),
    qualData: 'inicial',
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString('pt-br')
      this.filtros.dmax = new Date().toLocaleDateString('pt-br')
    },
    changeDate: range => {
      const { qualData } = this.filtros

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString('pt-br')
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

      return (this.filtros.qualData =
        qualData === 'inicial' ? 'final' : 'inicial')
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value
    }
  }

  @observable estado = []
  @action setEstados = estado => {
    this.estado = Array.isArray(estado)
      ? estado
      : $('#selecionar-estados-busca').val()
  }

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  }

  @observable comprador = {
    codComprador: '',
    onChangeComprador: ({ target: { value } }) =>
      (this.comprador.codComprador = value)
  }

  @observable pregao = {
    codPregao: '',
    onChangePregao: ({ target: { value } }) => (this.pregao.codPregao = value)
  }

  @observable palavrasChave = {
    palavras: [],
    onChangePalavras: tags => {
      this.palavrasChave.palavras = tags
    },
    palavraAtual: '',
    onChangeInput: palavra => {
      this.palavrasChave.palavraAtual = palavra
    }
  }

  getData = page => {
    const { dmin, dmax } = this.filtros
    return {
      pagina: page || this.pagination.page,
      dmax,
      dmin,
      pregao: this.pregao.codPregao,
      comprador: this.comprador.codComprador,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      palavra: this.palavrasChave.palavras.join(','),
      estado: this.estado,
      ordenacao: this.ordenacao,
      crescente: this.crescente
    }
  }

  getDataFiltro = (data, page) => {
    const {
      date: { dmin, dmax },
      comprador,
      pregao,
      estados,
      palavra,
      modalidade,
      tipoDate,
      portais,
      tipoItem,
      meEpp,
      palavraExclusao
    } = data
    this.filtros.dmin = dmin
    this.filtros.dmax = dmax
    const query = {
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      dmax: dmax,
      dmin: dmin,
      pagina: page || this.pagination.page,
      status: 'todos',
      tipo_data: tipoDate,
      portal: portais,
      estados: estados,
      palavras: palavra,
      palavras_exclusao: palavraExclusao,
      modalidade: modalidade,
      ordenacao: this.ordenacao || 'data_disputa',
      crescente: this.crescente,
      tipo_item: tipoItem.join(','),
      empresa_me: meEpp.join(','),
      pregao: data.pregao ? data.pregao : '',
      comprador: data.comprador ? data.comprador : '',
    }
    return query
  }

  getDataFiltroSimple = (data, page) => {
    let query = `/portal/pesquisa_editais_basico/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${data.dmax ? data.dmax : ''}&dmin=${data.dmin ? data.dmin : ''
      }`
    query += `&pagina=${page || this.pagination.page}`
    query += `&status=todos`
    query += `&tipo_data=${data.tipoDate ? data.tipoDate : ''}`
    query += `&portal=${data.portais ? data.portais : ''}`
    query += `&estados=${data.estados ? data.estados : ''}`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    query += `&comprador=${data.comprador ? data.comprador : ''}`
    query += `&pregao=${data.pregao ? data.pregao : ''}`
    query += `&tipo_item=${data.tipoItem ? data.tipoItem.join(',') : ''}`
    query += `&empresa_me=${data.meEpp ? data.meEpp.join(',') : ''}`

    return query
  }

  @action fetchEditaisNovo = async data => {
    this.typeSearch = 'advanced'
    this.isFetching = true
    const param = this.getDataFiltro(data)
    DetalhesEditalStore.setArrayWords(data.palavra)

    try {
      const res = await Api.post('/portal/pesquisa_editais/', param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      this.warningErrorInRequest = 'Não foi possível realizar a pesquisa, revise os dados e tente novamente! Caso o problema persista entre em contato com nossa equipe de suporte'
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditaisNovoSimple = async (data, id) => {
    this.typeSearch = 'basic'
    this.isFetching = true
    const param = this.getDataFiltroSimple(data)

    if (this.filterOpen) this.pagination.page = 1

    try {
      const res = await Api.get(param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
    } catch (err) {
      this.warningErrorInRequest = 'Não foi possível realizar a pesquisa, revise os dados e tente novamente! Caso o problema persista entre em contato com nossa equipe de suporte'
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEditais = async (id, data, type = "", page) => {
    this.typeSearch = 'advanced'
    this.isFetching = true
    if (this.filterOpen) this.pagination.page = 1

    const param = this.getDataFiltro(data, id)

    try {
      const res = await Api.post('/portal/pesquisa_editais/', param)
      const { editais, total_registros } = res.data
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      if (this.filterOpen) this.toggleFilter()
      if (type === "modalDetail") {
        DetalhesEditalStore.tratarEditaisAdicionados(tratarDadosEditais(editais), id)
        if (page > 0) {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[0].id)
        } else {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[9].id)
        }
      }
    } catch (err) {
      this.warningErrorInRequest = 'Não foi possível realizar a pesquisa, revise os dados e tente novamente! Caso o problema persista entre em contato com nossa equipe de suporte'
    } finally {
      this.disableNext = false
      this.disablePrevious = false
      this.isFetching = false
    }
  }

  fetchMore = async (page, data, type) => {
    this.isFetching = true
    try {
      if (this.typeSearch === "basic") {
        const res = await Api.get(this.getDataFiltroSimple(data, page))
        DetalhesEditalStore.tratarEditaisAdicionados(tratarDadosEditais(res.data.editais), page)
        if (type > 0) {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[0].id)
        } else {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[9].id)
        }
      }
      else {
        const res = await Api.get(this.getDataFiltro(data, page))
        return res.data.editais
      }
    } catch (err) {
      this.warningErrorInRequest = 'Não foi possível realizar a pesquisa, revise os dados e tente novamente! Caso o problema persista entre em contato com nossa equipe de suporte'
    } finally {
      this.disableNext = false
      this.disablePrevious = false
      this.isFetching = false
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis()
  }

  limparVariaveis = () => {
    this.isFetching = false

    this.total_paginas = 0
    this.total_registros = 0
    this.editais = []
    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    }
    this.filterOpen = true

    this.filtros = {
      dmin: new Date().toLocaleDateString('pt-br'),
      dmax: new Date().toLocaleDateString('pt-br'),
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString('pt-br')
        this.filtros.dmax = new Date().toLocaleDateString('pt-br')
      },
      changeDate: range => {
        const { qualData } = this.filtros

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          'pt-br'
        )
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

        return (this.filtros.qualData =
          qualData === 'inicial' ? 'final' : 'inicial')
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value
      }
    }

    this.estado = []

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    }

    this.palavrasChave = {
      palavras: [],
      onChangePalavras: tags => {
        this.palavrasChave.palavras = tags
      },
      palavraAtual: '',
      onChangeInput: palavra => {
        this.palavrasChave.palavraAtual = palavra
      }
    }
  }
}

export default new BuscaManualStore()
