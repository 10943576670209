import React from 'react'
import { format } from 'date-fns'
import { Description } from '@material-ui/icons'
import ReactTooltip from 'react-tooltip'

import ManualQualifyButton from '../../StatusNotice/manualQualifyButton'
import NumeroEdital from '../../../../Components/ReusableComponents/TableCells/NumeroEdital'
import maquinadeestadosstore from '../../../../Stores/MaquinaDeEstadosStore'

const formattedDate = data => {
  return format(new Date(data), 'dd/MM/yyyy')
}

export const columnsBuscaManual = [
  {
    name: 'NUM.',
    selector: 'numero',
    width: '120px',
    wrap: true,
    cell: ({ licitacao, status }) => (
      <>
        <NumeroEdital numero={licitacao} />
        &nbsp;
        {status === 'neutro' && (
          <>
            <Description data-tip color="primary" data-for="boletim" />
            <ReactTooltip
              id="boletim"
              effect="solid"
              type="info"
              place="bottom"
            >
              Boletim diário
            </ReactTooltip>
          </>
        )}
      </>
    )
  },

  {
    name: 'OBJETO',
    selector: 'objeto',
    // sortable: true,
    grow: 5,
    wrap: true,
    cell: ({ objeto }) => <div>{objeto}</div>
  },
  {
    name: 'DATA DISPUTA',
    selector: 'disputa',
    sortable: true,
    center: true,
    grow: 1,
    style: {
      fontWeight: 'bold',
      marginRight: '9px'
    },
    cell: ({ disputa }) => (disputa ? formattedDate(disputa) : '')
  },
  {
    name: 'ORGÃO',
    selector: 'orgao',
    sortable: true,
    grow: 3,
    wrap: true
  },

  {
    name: 'PORTAL',
    selector: 'portal',
    sortable: true,
    grow: 1,
    cell: ({ portal }) => <div className="hover-portal-tabela">{portal}</div>
  },

  {
    name: 'UF',
    selector: 'uf',
    sortable: true,
    grow: 0.3,
    wrap: true
  },

  {
    name: 'STATUS',
    selector: 'status',
    // sortable: true,
    wrap: true,
    center: true,
    grow: 0.3,
    cell: edital => (
      <ManualQualifyButton
        statusEdict={maquinadeestadosstore.status}
        edital={edital}
        typeSearch="manualSearch"
      />
    )
  }
]
