export const NOTIFICACOA = "notificacao";
export const MON_EDITAIS = "editaisselecionados";
export const MON_VERDE = "monitoramento_verde";
export const MON_VERMELHO = "monitoramento_vermelho";
export const MON_OCORRENCIA = "ocorrenciasdodia";
export const MON_LICT_DIA = "licitacoes_dia";
export const MONITORAMENTO = "monitoramento";

export const setMonitoramento = type => {
  localStorage.setItem(MONITORAMENTO, type);
};
export const getMonitoramento = () => localStorage.getItem(MONITORAMENTO);
