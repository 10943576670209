import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { inject, observer } from 'mobx-react'
import { DownloadCloud } from 'react-feather'
import { Pagination } from '@material-ui/lab'

import Spinner from '../../../UsefulComponents/Spinner'
import SubHeader from '../SubHeader/SubHeader'
import SectionTitle from '../SubHeader/SectionTitle'
import Filtros from '../SubHeader/Filtros/Filtros'
import StatusModal from '../StatusNotice'
import FiltroDownload from '../Filter/filtroDownload'
import DetalhesDownload from './DetalhesDownload'
// import DownloadEditais from './Download'
import { columnsDownload } from './contexto/tabelaDownload'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import { sortEditais } from '../Components/sortEditais'
import { BtnDownload, BtnDownloadTodos } from './btnDownload'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

import * as S from '../Style'

const Download = inject(
  'DownloadEditaisStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(
    ({ DownloadEditaisStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
      // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
      const {
        isFetching,
        fetchEditais,
        editais,
        total_registros,
        total_paginas,
        pagination,
        crescente,
        downloadSelecionadosNovo,
        errorDownload,
        downloadTodosConcluido
      } = DownloadEditaisStore
      const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
      const { toggleModal } = DetalhesEditalStore
      return (
        <DownloadComStore
          downloadSelecionadosNovo={downloadSelecionadosNovo}
          toggleModal={toggleModal}
          data={{ isFetching, fetchEditais }}
          crescente={crescente}
          idEmpresa={id_fornecedor}
          editais={editais}
          total_registros={total_registros}
          total_paginas={total_paginas}
          pagination={pagination}
          errorDownload={errorDownload}
          downloadTodosConcluido={downloadTodosConcluido}
          getStatus={getStatus}
        />
      )
    }
  )
)

const DownloadComStore = ({
  data,
  getStatus,
  total_registros,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  downloadSelecionadosNovo,
  errorDownload,
  downloadTodosConcluido
}) => {
  const { isFetching } = data
  const [statusDetalhe, setStatusDetalhe] = useState(false)
  const {
    state,
    // filtrar,
    // showFiltro,
    consultaDownload,
    consultaDownloadTodos,
    openFiltro
  } = useFiltroEdital()

  const { filtro } = state
  // const [editalSelect, setEditalSelect] = useState([]);
  // const [edital, setEdital] = useState([]);
  const [baixarTodos, setBaixarTodos] = useState(false)

  const [selected, setSelected] = useState([])

  const CheckBox = React.forwardRef((props, ref) => {
    let { name } = props
    name = name.slice(11) // remover o que for além do id do edital
    const current = editais.map(e => String(e.id))

    // const isChecked = (() => {
    //   if (name === 'rows') return selected.every(s => current.includes(s));
    //   return
    // })();

    const isChecked =
      name === 'rows'
        ? current.every(s => selected.includes(s))
        : selected.includes(name)

    const handleChange = e => {
      if (props.name === 'select-all-rows') {
        setSelected(prev =>
          isChecked
            ? prev.filter(e => !current.includes(e))
            : [...new Set([...prev, ...current])]
        )
      } else {
        setSelected(
          selected.includes(name)
            ? selected.filter(i => i !== name)
            : [...selected, name]
        )
      }
    }

    return (
      <input
        {...props}
        onChange={handleChange}
        checked={isChecked}
        forwardedRef={ref}
        type="checkbox"
      />
    )
  })

  const handlePagina = (event, value) => {
    pagination.changePage(value)
    consultaDownload()
  }

  useEffect(() => {
    openFiltro(true)
    getStatus()
  }, [])

  useEffect(() => {
    setSelected([])
  }, [filtro])

  useEffect(() => {
    if (!statusDetalhe) setSelected([])
  }, [statusDetalhe])

  const downloadEditais = type => {
    setBaixarTodos(type)
    setStatusDetalhe(true)

    type ? consultaDownloadTodos() : downloadSelecionadosNovo(selected)
  }

  if (isFetching) return <Spinner />

  return (
    <S.Container>
      {statusDetalhe ? (
        <DetalhesDownload
          edital={selected}
          filtro={<Filtros />}
          setStatusDetalhe={setStatusDetalhe}
          baixarTodos={baixarTodos}
          total_registros={total_registros}
          errorDownload={errorDownload}
          downloadTodosConcluido={downloadTodosConcluido}
        />
      ) : (
        <>
          <SubHeader overflow>
            <SectionTitle title="DOWNLOAD" icon={<DownloadCloud />} />
            <Filtros />
          </SubHeader>
          <S.Body>
            <S.ContainerTable filter={editais.length}>
              {filtro ? (
                <FiltroDownload />
              ) : (
                <>
                  <DataTable
                    className="tabela-padrao"
                    fixedHeader
                    allowOverflow={true}
                    overflowY={true}
                    noHeader
                    responsive={true}
                    // onSelectedRowsChange={handleRowSelected}
                    // onRowSelected={e => handleRowSelected(e)}
                    selectableRowsComponent={CheckBox}
                    selectableRowSelected={row => {
                      return true
                    }}
                    selectableRows
                    columns={columnsDownload}
                    onRowClicked={edital => toggleModal(edital.id, state)}
                    onSort={(c, d) => sortEditais(c, d, state)}
                    defaultSortAsc={crescente}
                    sortServer={true}
                    data={editais}
                    striped={true}
                    highlightOnHover
                    noDataComponent={
                      <ExclamationWarning
                        title="NENHUM EDITAL ENCONTRADO"
                        height="70vh"
                      />
                    }
                  />
                  <S.ContainerPagination>
                    <div style={{ width: '25%' }}/>
                    <div className={'pagination'}>
                      <Pagination
                        count={total_paginas}
                        color="primary"
                        shape="rounded"
                        page={pagination.page}
                        onChange={(event, value) => handlePagina(event, value)}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <BtnDownloadTodos
                      onClick={() => downloadEditais(true)}
                      number={total_registros}
                    />
                    <BtnDownload
                      onClick={() => downloadEditais(false)}
                      number={selected.length || 0}
                    />
                  </S.ContainerPagination>
                </>
              )}
            </S.ContainerTable>
          </S.Body>
          <StatusModal />
        </>
      )}
    </S.Container>
  )
}

export default Download
