import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { inject, observer } from "mobx-react";
import Pagination from "@material-ui/lab/Pagination";

import LicitacaoStores from "../../Stores/LicitacaoStores";
import { columnsLicitacao, rowTheme } from "./EstruturaTabela";
import { MenuLicitacoes } from "./MenuLicitacoes";
import { setLicitacaoId } from "../../Services/licitacao";
import { ExclamationWarning } from "../../Components/Notifications/ExclamationWarning";
import { ContainerDataTable } from "./styleLicitacoes";

// Import styles
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBodyLicitacoes
} from "../../globalStyles";
import { getPortalId } from "../../Services/portais";

@inject("LicitacoesPaginadaStore", "MaquinaDeEstadosStore", "LicitacaoStores")
@observer
class LicitacoesPaginada extends Component {
  componentDidMount() {
    this.props.LicitacoesPaginadaStore.searchBids();
  }

  redireciona(id) {
    setLicitacaoId(id);
    this.props.LicitacaoStores.licitacao_vista(id);
    this.props.LicitacaoStores.pagina_atual = "1";
    this.props.history.push("/licitacao");
  }

  licitacaoHandleChange = ({ target: { value } }) => {
    this.props.LicitacoesPaginadaStore.changePage(1);
    this.props.LicitacoesPaginadaStore.setTipoLicitacao(value);
    this.props.LicitacoesPaginadaStore.searchBids();
  };

  portalHandleChange = ({ target: { value } }) => {
    this.props.LicitacoesPaginadaStore.changePage(1);
    this.props.LicitacoesPaginadaStore.setPortal(value);
    this.props.LicitacoesPaginadaStore.searchBids();
  };

  sortByDate = array =>
    array
      .sort((a, b) => new Date(b.data_disputa) - new Date(a.data_disputa))
      .reverse();

  handlePageChange = (event, newPage) => {
    const { page } = this.props.LicitacoesPaginadaStore;

    if (page > newPage) {
      this.props.LicitacoesPaginadaStore.changePage(newPage);
      this.props.LicitacoesPaginadaStore.searchBids();
    }
    if (page < newPage) {
      this.props.LicitacoesPaginadaStore.changePage(newPage);
      this.props.LicitacoesPaginadaStore.searchBids();
    }
  };

  handleSearch = value => {
    if (value === "") this.props.LicitacoesPaginadaStore.searchPagedBids(1);
    this.props.LicitacoesPaginadaStore.changePage(1);
    this.props.LicitacoesPaginadaStore.setValueInputSearch(value);
  };

  search = () => {
    if (this.props.LicitacoesPaginadaStore.valueInputSearch) {
      this.props.LicitacoesPaginadaStore.searchBidsInput();
    }
  };

  licitacoes = () => {
    const { portal, licitacoesFilter } = this.props.LicitacoesPaginadaStore;
    const licitacoes = licitacoesFilter.filter(lic =>
      portal.includes(getPortalId(lic.portal))
    );
    return licitacoes.length > 0 ? licitacoes : licitacoesFilter;
  };

  render() {
    const {
      tipo_licitacao,
      fim_progresso_carregano_lic,
      licitacaoMonitorada,
      page,
      licitacoesFilter,
      totalAmountOfBids,
      portal
    } = this.props.LicitacoesPaginadaStore;
    return (
      <>
        <ComponentHeader
          style={{ justifyContent: "space-between" }}
          id="pagina-licitacoes"
        >
          <MenuLicitacoes
            totalAmountOfBids={totalAmountOfBids}
            licitacaoMonitorada={licitacaoMonitorada}
            tipo_licitacao={tipo_licitacao}
            licitacaoHandleChange={this.licitacaoHandleChange}
            portalHandleChange={this.portalHandleChange}
            portal={portal}
            licitacoesFilter={licitacoesFilter}
            Search={this.handleSearch}
            onClick={this.search}
            fim_progresso_carregano_lic={fim_progresso_carregano_lic}
          />
        </ComponentHeader>
        <ComponentHeaderOverflow />
        <ContentBodyLicitacoes>
          <div style={{ display: "flex", overflowY: "hidden" }}>
            <ContainerDataTable>
              <DataTable
                fixedHeader
                className="tabelalo"
                fixedHeaderScrollHeight="92vh"
                subHeader
                allowOverflow={true}
                overflowY={true}
                striped
                id="tabela-principal-licitacoes"
                pointerOnHover
                onRowClicked={e => this.redireciona(e.id)}
                columns={columnsLicitacao}
                highlightOnHover
                style={{ height: "100%" }}
                customTheme={rowTheme}
                data={this.licitacoes()}
                noDataComponent={
                  <ExclamationWarning
                    title="Nenhuma licitação encontrada"
                    height="70vh"
                  />
                }
              />
              {licitacoesFilter && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Pagination
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "fixed",
                      bottom: "1.5rem"
                    }}
                    count={Math.ceil(totalAmountOfBids / 50)}
                    onChange={this.handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                  />
                  <div
                    style={{
                      marginLeft: "57%",
                      marginTop: "1.5%",
                      fontSize: "14px"
                    }}
                  >
                    Total de licitações exibidas: {licitacoesFilter.length}
                  </div>
                </div>
              )}
            </ContainerDataTable>
          </div>
        </ContentBodyLicitacoes>
      </>
    );
  }
}

export default LicitacoesPaginada;
