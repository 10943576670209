import styled from 'styled-components'

export const CompanySelectForm = styled.form`
  min-width: 400px;
  box-shadow: 1px 1px 4px #d8d8d8;
  border-radius: 7px;

  .header-selecionar-empresa {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8e7e7db;
    color: #000000;
    width: 50px;
    height: 38px;
    margin-right: -8px;

    @media (max-width: 1500px) {
      height: 30px;
    }
  }

  @media only screen and (max-width: 700px) {
    width: 300px;
  }

  @media only screen and (max-width: 300px) {
    width: 100px;
  }

  div select {
    border: none !important;
  }

  #empresas_multi {
    height: 38px;
    @media (max-width: 1500px) {
      height: 30px;
      padding: 0 10px;
    }
  }
`

export const BolaVerdePiscando = styled.div`
  background-color: ${props => (props.color ? props.color : '#44ca44;')};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  box-shadow: 1px 1px 4px 1px #00000082;
  margin-left: 10px;
  animation: ${props =>
    !props.animation && '1700ms blink ease-in-out infinite'};

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
