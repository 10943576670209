import Modal from "react-modal";
import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { ModalHead } from "../Modals/ModalHead";
import { BobyModal } from "../Modals/styleModal";
import { DescricaoPlano, TituloPlano } from "./stylesBACKUP";
import { CardCartoes } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconsContratar from "./Icones/IconsContratar";

const hiper = "/static/Imagens/bandeira_hiper.png";
const visa = "/static/Imagens/bandeira_visa.png";
const american = "/static/Imagens/bandeira_american.png";
const diners = "/static/Imagens/bandeira_diners.png";
const master = "/static/Imagens/bandeira_master.png";

const brasil = "/static/Imagens/banco_brasil.png";
const itau = "/static/Imagens/banco-Itau.png";
const bradesco = "/static/Imagens/banco_bradesco.png";
const caixa = "/static/Imagens/banco_caixa.png";
const santader = "/static/Imagens/banco_santader.png";

@inject("FinanceiroStores")
@observer
class ModalContratarPlano extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      statusCartao: true,
      statusConta: false,
      statusBoleto: false
      // renovacao: true
    };
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  handleCloseModal = () => {
    this.props.FinanceiroStores.handleCloseModal();
  };

  handleOpenModal = () => {
    this.props.FinanceiroStores.handleOpenModal();
  };

  handleCheck = type => {
    this.setState({
      statusCartao: false,
      statusConta: false,
      statusBoleto: false,
      [type]: !this.state[type]
    });
  };

  // handleCheckedRenov = ({ target: { checked } }) => {
  //     this.setState({ checkedRenov: checked })
  // }

  handleChecked = ({ target: { checked } }) => {
    this.setState({ checked });
  };

  handleContratar = () => {
    this.props.FinanceiroStores.contratar_modulo(
      this.props.Upgrademodulos.id,
      this.state.checkedRenov
    );
    this.handleCloseModal();
  };

  static defaultProps = {
    combo: {
      name: "NOME DO COMBO",
      id: 51,
      description: "mensal",
      value: 300
    },
    FinanceiroStores: {
      soma: 450
    },
    active: true,
    close: () => {}
  };

  render() {
    const { soma } = this.props.FinanceiroStores;
    const { combo, active, close } = this.props;
    const { statusCartao, statusConta, statusBoleto } = this.state;

    return (
      <>
        <Modal
          className="Modal_Load"
          style={{ content: { width: "70%", left: "18%", top: "12%" } }}
          overlayClassName="Overlay_Load"
          isOpen={active}
        >
          {ModalHead(close, "CONTRATAR PLANO", "box")}

          <BobyModal style={{ padding: "1rem 2rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: "1rem",
                flexWrap: "wrap"
              }}
            >
              <TituloPlano
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  alignSelf: "flex-start"
                }}
              >
                {combo.name}
              </TituloPlano>
              <div style={{ alignSelf: "flex-start" }}>
                <IconsContratar contrato={combo.id} />
              </div>
            </div>

            <div className="row">
              <div className="col-9">
                <DescricaoPlano style={{ marginBottom: "1rem" }}>
                  {combo.description} "Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat.{" "}
                </DescricaoPlano>

                <small style={{ fontWeight: "bold" }}>Termo de contrato</small>
                <textarea
                  style={{ resize: "none", fontSize: "10px" }}
                  className="form-control"
                  rows="15"
                  disabled={true}
                  type="text"
                  placeholder='"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                  readOnly
                />
              </div>

              <div className="col-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <small style={{ fontWeight: "700" }}>Valor Plano:</small>
                  <div style={{ fontSize: "1.5em", fontWeight: "200" }}>
                    R$ <span style={{ color: "#008000" }}>{soma},00</span>
                  </div>
                  <small style={{ fontWeight: "700" }}>Desconto COMBO: </small>
                  <div style={{ fontSize: "1.5em", fontWeight: "200" }}>
                    {" "}
                    R${" "}
                    <span style={{ color: "#008000" }}>
                      {soma < 0 ? 0 : soma - combo.value},00
                    </span>
                  </div>
                  <small style={{ fontWeight: "700" }}>Valor total: </small>
                  <div style={{ fontSize: "1.5em", fontWeight: "400" }}>
                    R${" "}
                    <span style={{ color: "#008000" }}>
                      {combo.value.toString().replace(".", ",")},00
                    </span>
                  </div>
                  {/* <div>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.state.checkedRenov}
                                            onChange={this.handleCheckedRenov}
                                            id="defaultCheck1"
                                        />
                                        <small style={{ color: 'gray' }}>Renovação automatica</small>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <div style={{ marginLeft: "1rem" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={this.handleChecked}
                    id="defaultCheck1"
                  />
                  <small style={{ color: "gray" }}>
                    Condordo com os termos
                  </small>
                </div>
              </div>
            </div>

            <br />
            <Row>
              <Col md={9} style={{ marginTop: "20px" }}>
                <Row
                  style={{
                    margin: 0,
                    marginTop: "-1.5rem",
                    justifyContent: "space-between"
                  }}
                >
                  {/*Cartão de Credtio*/}
                  <CardCartoes
                    width="250px"
                    height="45px"
                    style={{ marginTop: "1.5em" }}
                    onClick={() => this.handleCheck("statusCartao")}
                  >
                    <label>Cartão de crédito</label>
                    <div>
                      {!statusCartao ? (
                        <FontAwesomeIcon
                          icon="circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#7a7a7a"
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="check-circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#209c1c"
                          }}
                        />
                      )}
                    </div>
                    <Row style={{ marginTop: "-22px", marginLeft: "25px" }}>
                      <img src={visa} alt="logo-visa" />
                      <img src={master} alt="logo-master" />
                      <img src={american} alt="logo-american" />
                      <img src={diners} alt="logo-diners" />
                      <img src={hiper} alt="logo-hiper" />
                    </Row>
                  </CardCartoes>

                  {/***************/}

                  <CardCartoes
                    width="180px"
                    height="45px"
                    style={{ marginTop: "1.5em" }}
                    onClick={() => this.handleCheck("statusConta")}
                  >
                    <label>Débito em conta </label>
                    <div>
                      {!statusConta ? (
                        <FontAwesomeIcon
                          icon="circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#7a7a7a"
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="check-circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#209c1c"
                          }}
                        />
                      )}
                    </div>
                    <Row style={{ marginTop: "-25px", marginLeft: "25px" }}>
                      <img src={brasil} alt="logo-brasil" />
                      <img src={itau} alt="logo-itau" />
                      <img src={bradesco} alt="logo-bradesco" />
                      <img src={caixa} alt="logo-caixa" />
                      <img src={santader} alt="logo-santander" />
                    </Row>
                  </CardCartoes>

                  <CardCartoes
                    width="70px"
                    height="45px"
                    style={{ marginTop: "1.5em" }}
                    onClick={() => this.handleCheck("statusBoleto")}
                  >
                    <label>Boleto </label>
                    <div>
                      {!statusBoleto ? (
                        <FontAwesomeIcon
                          icon="circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#7a7a7a"
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="check-circle"
                          style={{
                            marginLeft: "-1px",
                            color: "#209c1c"
                          }}
                        />
                      )}
                    </div>
                    <Row style={{ marginTop: "-25px", marginLeft: "25px" }}>
                      <FontAwesomeIcon icon="barcode" size={"2x"} />
                    </Row>
                  </CardCartoes>
                </Row>
              </Col>
              <div
                className="col-3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: "flex-end"
                }}
              >
                <Button
                  disabled={!this.state.checked}
                  onClick={this.handleContratar}
                  className="btn-sm btn-success"
                  style={{ width: "80%", height: "2.5rem" }}
                >
                  CONTRATAR
                </Button>
              </div>
            </Row>
          </BobyModal>
        </Modal>
      </>
    );
  }
}

export default ModalContratarPlano;
