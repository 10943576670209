import { Button, Col, Row } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const azul =
  "linear-gradient(2deg, rgba(96,179,247,1) 0%, rgba(83,154,212,1) 100%)";
const red =
  "linear-gradient(180deg, rgba(173, 15, 15, 1) 19%, rgba(203, 42, 42, 1) 79%)";
const green = "linear-gradient(180deg, #3dad0f 19%, #5db33a 79%)";

export const ModalHead = (
  clouseModal,
  titulo,
  icon,
  color = "primary",
  background
) => {
  return (
    <Row
      style={{
        width: "100.4%",
        marginLeft: "-0.2%",
        marginTop: "-0.4%",
        marginBottom: "12px"
      }}
    >
      <Col
        md={12}
        style={{
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          height: "45px",
          backgroundImage: !background
            ? azul
            : background === "red"
            ? red
            : background === "green"
            ? green
            : azul,
          color: "white"
        }}
      >
        <Row>
          <Col md={10} style={{ paddingTop: "13px" }}>
            <h6 style={{ color: "white" }}>
              <span style={{ color: "white" }}>
                <FontAwesomeIcon icon={icon} />
              </span>{" "}
              &nbsp;&nbsp;{titulo}
            </h6>
          </Col>
          <Col md={2} style={{ marginTop: "8px" }} className="text-right">
            <Button
              onClick={clouseModal}
              style={{ color: "#fff", borderColor: "#fff" }}
              bsClass="btn btn-sm btn-outline"
              bsStyle={color}
              className=""
            >
              X
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
