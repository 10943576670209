import React, { useState } from "react";
import HeaderItens from "./headerItens";
import DataTable from "react-data-table-component";
import { columnsItens } from "./tabella/tabela";
import { ContainerTabela } from "./styles";
import FooterDetalhes from "../detalhes/footerDetalhes";
import DetalhesEditalStore from "../../store";
import { ExclamationWarning } from "../../../../Notifications/ExclamationWarning";

const markKeyword = (words, listKeyword) => {
  if (typeof words !== "string") return words;

  const list = listKeyword.map(word =>
    word
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase()
  );

  const wordsNormalized = words
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();

  const bitmap = Array(words.length).fill(false);

  for (const keyword of list) {
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedKeyword, "g");
    const matches = wordsNormalized.matchAll(regex);
    for (const match of matches) {
      const end = match.index + match[0].length;
      for (let i = match.index; i < end; i++) {
        bitmap[i] = true;
      }
    }
  }
  const slices = [];
  let start = 0;
  let isActive = bitmap[0];
  for (let i = 0; i < bitmap.length; i++) {
    if (isActive !== bitmap[i]) {
      const slice = words.slice(start, i);
      slices.push({ isActive, slice });
      start = i;
      isActive = bitmap[i];
    }
  }

  const slice = words.slice(start, bitmap.length);
  slices.push({ isActive, slice });

  return slices.map(({ isActive, slice }) => {
    if (isActive) {
      return (
        <mark
          style={{
            backgroundColor: "#0190FF",
            color: "white",
            padding: "1px 0",
            borderRadius: "3px"
          }}
        >
          {slice}
        </mark>
      );
    }
    return slice;
  });
};

const ItensEdital = props => {
  const { id, itens, editais } = props;
  const [palavra, setPalavra] = useState("");
  const edital = editais.find(edital => edital.id === id);
  const { licitacao } = edital;
  const normalizedWords = palavra
    .toString()
    .trim()
    .toUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  const filteredItems = itens.filter(item => {
    if (!normalizedWords) return true;
    const fields = [
      item.codigo,
      item.descricao,
      item.descricao_completa,
      item.grupo,
      item.unidade_fornecimento
    ];
    for (const field of fields) {
      if (!field) continue;
      const result = field
        .toString()
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (result.includes(normalizedWords)) return true;
    }
  });

  let formatKeyword = [];
  if (
    typeof edital.selecionado.palavras_usadas === "string" &&
    edital.selecionado.palavras_usadas.trim()
  ) {
    formatKeyword.push(...edital.selecionado.palavras_usadas.trim().split(","));
  }
  if (formatKeyword.length === 0) {
    formatKeyword = Array.from(DetalhesEditalStore.words || []);
  }

  formatKeyword.push(
    ...normalizedWords
      .replace(/-/g, " ")
      .replace(/\(|\)/g, " ")
      .replace(/,/g, " ")
      .split(" "),
    ...normalizedWords.split(" ")
  );

  const data = formatKeyword
    ? filteredItems.map(item => ({
        codigo: item.codigo,
        grupo: item.grupo,
        descricao_completa: (
          <div>
            <h6>{markKeyword(item.descricao, formatKeyword)}</h6>
            {markKeyword(item.descricao_completa, formatKeyword)}
          </div>
        ),
        quantidade: item.quantidade,
        unidade_fornecimento: item.unidade_fornecimento,
        tipo: item.tipo,
        margem_de_preferencia: item.margem_de_preferencia,
        decreto7174: item.decreto7174,
        tratamento_diferenciado: item.tratamento_diferenciado
      }))
    : filteredItems.map(item => ({
        codigo: item.codigo,
        grupo: item.grupo,
        descricao_completa: (
          <div>
            <h6>{item.descricao}</h6>
            {item.descricao_completa}
          </div>
        ),
        quantidade: item.quantidade,
        unidade_fornecimento: item.unidade_fornecimento,
        tipo: item.tipo,
        margem_de_preferencia: item.margem_de_preferencia,
        decreto7174: item.decreto7174,
        tratamento_diferenciado: item.tratamento_diferenciado
      }));

  return (
    <>
      <HeaderItens
        licitacao={licitacao}
        setPalavra={setPalavra}
        palavra={palavra}
      />
      <ContainerTabela>
        <DataTable
          className='tabela-itens-detalhes'
          noHeader
          responsive={true}
          columns={columnsItens}
          data={data}
          striped={true}
          noDataComponent={
            <ExclamationWarning
              title={(itens||[]).length === 0 ? 'SEM ITENS':'NENHUM RESULTADO ENCONTRADO'}
              height='auto'
            />
          }
          highlightOnHover
        />
      </ContainerTabela>
      <FooterDetalhes />
    </>
  );
};

export default ItensEdital;
