import styled from 'styled-components'

export const ContainerTable = styled.div`
  max-height: 76vh;

  .table-items-confirmation-proposal {
    margin: 0;
    padding: 0;
    overflow: auto;

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(128, 187, 224)),
        color-stop(0.72, rgb(80, 132, 166)),
        color-stop(0.86, rgb(95, 163, 204))
      );
    }
  }

  .rdt_Table {
    font-size: 11px;
    overflow: auto;
    height: auto;
  }

  .rdt_TableHeadRow {
    border-top: 2px solid #458ab9;
    border-bottom: 2px solid #458ab9;
  }

  .rdt_TableCell {
    font-size: 10px;
  }

  .rdt_TableBody {
    max-height: calc(100vh - 386px);
    overflow: inherit;
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      /* width: 10px; */
      height: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(128, 187, 224)),
        color-stop(0.72, rgb(80, 132, 166)),
        color-stop(0.86, rgb(95, 163, 204))
      );
    }
  }

  .rdt_TableRow {
    padding: 3px 0;
    min-height: unset;
  }

  .rdt_TableCol .rdt_TableCol_Sortable div {
    font-weight: 600;
    font-size: 10px;
    color: #296188;
  }
`

export const ContainerValue = styled.div`
  display: flex;
  justify-content: flex-end;

  .amount {
    font-size: 125%;
    text-align: right;
    font-weight: 600;
    margin-right: 50px;
    margin-top: 20px;
  }

  .blue {
    color: #296188;
  }

  .green {
    color: green;
  }
`
