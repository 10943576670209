import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { Bookmark } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'

import { SelectInput } from '../../../../UsefulComponents/Inputs'
import { ModalContext } from '../context/modal'
import { useStatusEdital } from '../context'

import * as S from './style'

const ModalEdictApproval = inject('MaquinaDeEstadosStore')(
  observer(({ MaquinaDeEstadosStore }) => {
    const { status, usuarios } = MaquinaDeEstadosStore
    return <ModalApprove status={status} users={usuarios} />
  })
)

const ModalApprove = ({ status, users }) => {
  const { statusEdital, changePriority, edital } = useStatusEdital()
  const { closeModal, modalAprovar } = useContext(ModalContext)
  const [userId, setUserID] = useState(users.length && users[0].id)

  let bulletinStatus
  if (status) bulletinStatus = status.aprovado

  return ReactDOM.createPortal(
    <>
      {modalAprovar && (
        <S.Overlay>
          <S.Container>
            <div className="header">
              <div className="icon background-aprovado">
                <Bookmark />
              </div>
              <div className="title color-aprovado">APROVAR</div>
            </div>
            <div className="body">
              {bulletinStatus[2] && (
                <div className="select-user">
                  <div className="title">
                    SELECIONE UM USUÁRIO PARA ENCAMINHAR O EDITAL
                  </div>
                  <SelectInput
                    value={userId}
                    icon={<FontAwesomeIcon icon="users" />}
                    onChange={e => setUserID(Number(e.target.value))}
                  >
                    {users.map(user => (
                      <option key={user.id} value={user.id} >
                        {user.username}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              )}
              {bulletinStatus[1] && (
                <div className="nivel-prioridade">
                  <div className="title">NÍVEL DE PRIORIDADE</div>
                  <SelectInput
                    icon={<FontAwesomeIcon icon="bookmark" />}
                    onChange={e => changePriority(e.target.value)}
                    value={edital.selecionado.prioridade}
                  >
                    <option value="alta">Alta</option>
                    <option value="media">Média</option>
                    <option value="baixa">Baixa</option>
                  </SelectInput>
                </div>
              )}
            </div>
            <div className="footer">
              <div
                className="confirmar"
                onClick={() => statusEdital('aprovado', userId)}
              >
                CONFIRMAR
              </div>
              <div className="fechar" onClick={closeModal}>
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalEdictApproval
