import React from 'react'
import styled from 'styled-components'
import { ReactTooltip } from '../../../globalStyles'

const Container = styled.b`
  text-overflow: ellipsis;
  overflow: hidden;
`

const NumeroEdital = ({ numero }) => {
  return (
    <>
      <ReactTooltip />
      <Container
        data-tip={numero}
        data-type="info"
        data-effect="solid"
        data-place="right"
      >
        {numero}
      </Container>
    </>
  )
}

export default NumeroEdital
