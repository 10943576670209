import React, { useEffect } from 'react'
import { Settings } from 'react-feather'

import ComponentContent from './ComponentContent'
import ActionButtons from './ActionButtons'
import SectionTitle from '../SubHeader/SectionTitle'
import SubHeader from '../SubHeader/SubHeader'
import { useSetting } from './Context'

import { Container, Content } from './Style/styles'

const Setting = () => {
  const { initialRequest, settings } = useSetting()

  const { component, icon, title, subTitle } = settings.find(
    item => item.active
  )

  useEffect(() => {
    initialRequest()
  }, [])

  console.log(settings)

  return (
    <Container>
      <SubHeader>
        <SectionTitle title="CONFIGURAÇÕES DO BOLETIM" icon={<Settings />} />
      </SubHeader>

      <Content>
        <div className="options">
          <ComponentContent icon={icon} title={title} subtitle={subTitle}>
            {component}
          </ComponentContent>
          <ActionButtons />
        </div>
      </Content>
    </Container>
  )
}

export default Setting
