import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Container, Body } from '../../../Components/Modules/styles'
import { Search } from 'react-feather'

import Editais from './Notice'
import SectionTitle from '../SubHeader/SectionTitle'
import SubHeader from '../SubHeader/SubHeader'
import Spinner from '../../../UsefulComponents/Spinner'
import FiltroBuscaManual from '../Filter/filtroBuscaManual'
import BotaoFiltrar from '../Filter/botaoFiltro'
import ModalQualificarManual from '../StatusNotice/modals/modalQualificarManual'
import ModalConfirmation from '../StatusNotice/modals/modalConfirmation'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

import * as S from './Style'

const ManualSearch = inject(
  'BuscaManualStore',
  'MaquinaDeEstadosStore'
)(
  observer(({ BuscaManualStore, MaquinaDeEstadosStore }) => {
    const { isFetching } = BuscaManualStore
    const { getStatus } = MaquinaDeEstadosStore

    return <ManualSearchStore isFetching={isFetching} getStatus={getStatus} />
  })
)

const ManualSearchStore = ({ isFetching, getStatus }) => {
  const [openFilter, setOpenFilter] = useState(false)
  const { showFiltro, clearData, state: { filtro } } = useFiltroEdital()

  useEffect(() => {
    getStatus()
  }, [])

  const handleChange = () => {
    showFiltro(!filtro)
    setOpenFilter(!openFilter)
    clearData()
  }

  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="BUSCA MANUAL" icon={<Search />} />
        <BotaoFiltrar onClick={handleChange} />
      </SubHeader>
      <Body>
        <S.BodyContainer>
          <FiltroBuscaManual />
          {isFetching ? <Spinner /> : <Editais />}
        </S.BodyContainer>
      </Body>
      <ModalQualificarManual />
      <ModalConfirmation />
    </Container>
  )
}

export default ManualSearch
