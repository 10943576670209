import React from 'react';
import styled from 'styled-components';
import {Calendar, Clock} from 'react-feather';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #888888;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 3px;
    height: 20px;
  }

  .title {
    font-weight: 700;
    margin-bottom: 10px;
    justify-content: flex-end;
    color: #000;
  }
  .day {
    margin-bottom: 5px;
  }
`;

const DateDisplay = ({date, title}) => {
  const {day, hour} = date;
  return (
    <Container>
      <div className="title">{title}</div>
      <div className="day">
        <Calendar/>
        {day}
      </div>
      <div className="hour">
        <Clock/>
        {hour}
      </div>
    </Container>
  )
};

export default DateDisplay
