import { observable, action } from 'mobx'
import LicitacoesPaginadaStore from './LicitacoesPaginadaStore'
import PainelAvisoStores from './PainelAvisoStores'
import MonitoramentoStore from './MonitoramentoStore'
import OcorrenciasDoDiaStores from './OcorrenciasDoDiaStores'
import FinanceiroStores from './FinanceiroStores'
import DashboardStores from './DashboardStores'
import BoletimStore from '../Pages/Prospection/DailyBulletin/Store'
import Api from './Conexao/conexao'
import {
  MODULO_EDITAIS,
  MODULO_BOLETIM,
  MODULO_MONITORAMENTO
} from '../Services/contratos'
import MenuStores from './MenuStores'
import SideStores from './SideStores'
import ConfiguracoesMonitoramentoStores from './ConfiguracoesMonitoramentoStores'
import { AtualizaDadosEmpresa } from './StateStores'
import { loadAlertaAudio } from '../som/AlertasSistema'
import loginStores from './LoginStores'
import { ConsoleHelper } from '../utils/ConsoleHelper'
import { initPushNotification } from './FirebaseStore'

export const EDITAIS = '/editais'
export const BUSCA_DE_EDITAIS = '/buscadeeditais'
export const CONF_BUSCA_DE_EDITAIS = '/configuracoesbuscadeeditais'

export const LICITACOES = '/licitacoes'
export const LICITACAO = '/licitacao'
export const PAINEL_MONITORAMENTO = '/painel_monitoramento'
export const MONITORAMENTO = '/monitoramento'
export const OCORRENCIAS_DO_DIA = '/ocorrenciasdodia'
export const CADASTRO = '/cadastro'
export const RECUPERAR_SENHA = '/recuperar_senha'
export const CONF_MONITORAMENTO = '/configuracoesmonitoramento'
export const CONF_GERAL = '/configuracaogeral'
export const FUNIL = '/funil'
export const AGENDA = '/agenda'
export const FINANCAS = '/financas'
export const DASHBOARD = '/dashboard'
export const FINANCEIRO = '/financeiro'
export const PROPOSTA = '/proposta/cadastrar'
export const GERENCIAR_PROPOSTA = '/proposta/gerenciar'

class MaquinaDeEstadosStore {
  @observable
  _props = ''
  @observable
  licitacao_monitorada = 'primeira'
  @observable
  tipo_visualizacao = 'todas'
  @observable
  pagina_atual_mensagens = 1
  @observable
  pagina_visualizada = 1
  @observable
  tipo_licitacao = 'todas'

  @observable perfil = false

  @observable empresas = []
  @observable usuarios = []
  @observable id_fornecedor = null
  @observable usuarioLogado = null
  @observable contratos = []
  @observable credenciais = []
  @observable modalidade = []
  @observable portais = []
  @observable isPushWorking = true
  @observable comboId = null
  @observable portaisContratados = { editais: [], monitoramento: [] }

  @action setIsPushWorking = state => (this.isPushWorking = !!state)

  @observable comboIdEmpresaFree = false

  @observable
  dadosUser = {
    user: { user: '', email: '' },
    fornecedor: { id: '', cnpj: '' }
  }

  @observable timeoutPattern = 300000
  @observable timeoutNewNotificationsId = null

  @observable status = {}

  @action
  fetchNewNotifications = timeout => {
    this.timeoutNewNotificationsId = setInterval(
      SideStores.consulta_notificacoes,
      timeout
    )
  }

  @action
  reloadSearchNewNotifications = () => {
    clearTimeout(this.timeoutNewNotificationsId)
    this.fetchNewNotifications(this.timeoutPattern)
    SideStores.consulta_notificacoes()
  }

  @action
  getStatus = async () => {
    try {
      const rest = await Api.get(`/portal/config_status/${this.getIdEmpresa()}`)
      this.status = rest.data.config_status
    } catch (err) {
      ConsoleHelper('err', err)
    }
  }

  @action
  checkIdCombo = () => {
    this.comboId = this.empresas.find(
      empresa => empresa.id === this.id_fornecedor
    ).combo_id
  }

  validaContrato = modulo => {
    if (!this.getContrato(modulo)) {
      this.redireciona_configuracaogeral()
    }
  }

  consultaModalidade = async () => {
    try {
      const res = await Api.get('api/modalidade/')
      this.modalidade = res.data
    } catch (err) {
      ConsoleHelper('err', err)
    }
  }

  consultaPortal = async () => {
    try {
      const res = await Api.get('api/portal/')
      this.portais = res.data
    } catch (err) {
      ConsoleHelper('err', err)
    }
  }

  @action fetchPerfil = async () => {
    try {
      const res = await Api.get(
        `portal/dados_config/?fornecedor=${this.id_fornecedor}`
      )
      this.perfil = res.data
    } catch (err) {
      ConsoleHelper(err)
    }
  }

  @action
  checkComnoFree = id => {
    const dado = this.empresas.filter(item => Number(item.id) === Number(id))
    this.comboIdEmpresaFree = Number(dado[0].combo_id) === 34
  }

  checkComboId = combo_id => (this.comboIdEmpresaFree = Number(combo_id) === 34)

  @action
  verificaContrato = () => {
    let url = window.location.pathname
    switch (url) {
      case DASHBOARD:
        this.validaContrato(MODULO_BOLETIM)
        break
      case EDITAIS:
        this.validaContrato(MODULO_BOLETIM)
        break
      case BUSCA_DE_EDITAIS:
        this.validaContrato(MODULO_EDITAIS)
        break
      case CONF_BUSCA_DE_EDITAIS:
        this.validaContrato(MODULO_BOLETIM)
        break
      case LICITACOES:
        this.validaContrato(MODULO_EDITAIS)
        break
      case LICITACAO:
        this.validaContrato(MODULO_EDITAIS)
        break
      case FUNIL:
        this.validaContrato(MODULO_EDITAIS)
        break
      case OCORRENCIAS_DO_DIA:
        this.validaContrato(MODULO_MONITORAMENTO)
        break
      case PAINEL_MONITORAMENTO:
        this.validaContrato(MODULO_EDITAIS)
        break
      case MONITORAMENTO:
        this.validaContrato(MODULO_MONITORAMENTO)
        break
      case CONF_MONITORAMENTO:
        this.validaContrato(MODULO_MONITORAMENTO)
        break
      default:
    }
  }

  @observable credencialExpired = null

  @action consultaEmpresas = async () => {
    await this.consultaModalidade()
    await this.consultaPortal()
    this.fetchNewNotifications(this.timeoutPattern)
    try {
      const res = await Api.get('empresas_dados/')
      let { empresa_default, empresas, usuarios, user_id } = res.data
      !empresa_default.length &&
        empresa_default.push({ id_fornecedor: null, combo_id: null })
      const { id_fornecedor, combo_id } = empresa_default[0]
      this.usuarioLogado = usuarios.find(user => user.id === user_id)

      this.empresas = empresas
      if (id_fornecedor) this.setContratos(id_fornecedor)
      this.id_fornecedor = id_fornecedor
      this.setEmailUser(this.usuarioLogado.email)
      this.setIdEmpresa(id_fornecedor)
      this.setContratos(id_fornecedor)
      this.setIdUser(user_id)
      this.usuarios = usuarios.sort((a, b) => (a.id === user_id ? -1 : 1));
      await this.fetchPerfil()

      this.updateCredenciais(id_fornecedor)
      MenuStores.empresas = this.usuarioLogado.empresas || []
      this.verificaContrato()
      DashboardStores.consulta_dashboard()
      await initPushNotification()
      SideStores.consulta_notificacoes()
      ConfiguracoesMonitoramentoStores.consulta_api()
      AtualizaDadosEmpresa()
      this.checkComboId(combo_id)
      loadAlertaAudio()

      this.testPushNotification()

      await BoletimStore.fetchEditais()
      return id_fornecedor
    } catch (err) {
      ConsoleHelper('err', err)
    }
  }

  @observable pushInterval = null
  @observable pushTries = 0
  @observable notificationsPermission = Notification.permission === 'granted'

  @action testPushNotification = () => {
    if (this.notificationsPermission) {
      Api.get(
        `monitoramento/alerta_push/?fornecedor=${this.id_fornecedor}`
      ).catch(error => {
        console.error(error)
      })
    }
  }

  @action updateCredenciais = (id = this.id_fornecedor) =>
    (this.credenciais = this.empresas.find(
      em => Number(em.id) === Number(id)
    ).credenciais)

  @action consultaContratos = id => {
    const contratos = this.empresas.find(el => el.id === Number(id)).contratos
    if (contratos) {
      return contratos
    } else {
      return []
    }
  }

  @action getContrato = (modulo, idEmpresa) => {
    if (modulo === 'licitacoes')
      return this.contratos.find(item => item.status === true).status
    if (this.contratos.length) {
      if (idEmpresa)
        return this.empresas
          .find(em => em.id === Number(idEmpresa))
          .contratos.find(cont => cont.name_modulo === modulo).status
      return this.contratos.find(item => item.name_modulo === modulo).status
    }
  }

  @action getContratoByModulo = modulo => {
    return this.contratos.find(item => item.name_modulo === modulo)
  }

  @action
  setDadosUser = (user, id, cnpj, email, login) => {
    this.dadosUser = {
      user: { user: user, email: email },
      fornecedor: { id: id, cnpj: cnpj }
    }
    if (!login) this.id_fornecedor = id
  }

  @action setPortaisContratados = () => {
    const { portal: portalEditais } = this.contratos[1]
    const { portal: portalMonitoramento } = this.contratos[2]
    return (this.portaisContratados = {
      editais: portalEditais,
      monitoramento: [portalMonitoramento]
    })
  }

  @action
  getCnpjEmpresa = () => {
    const {
      fornecedor: { cnpj }
    } = this.dadosUser
    return cnpj
  }

  @action
  getIdEmpresa = () => {
    return this.id_fornecedor
  }

  @action
  getEmailUser = () => {
    if (this.dadosUser) {
      const {
        user: { email }
      } = this.dadosUser
      return email
    }
  }

  @action
  setEmailUser = email => {
    this.dadosUser.user.email = email
    loginStores.email = email
  }

  @action
  setIdEmpresa = id => {
    this.dadosUser.fornecedor.id = Number(id)
    this.id_fornecedor = Number(id)
  }

  @action setContratos = id => (this.contratos = this.consultaContratos(id))

  @action
  setCnpjEmpresa = cnpj => {
    this.dadosUser.fornecedor.cnpj = cnpj
  }

  @action
  setIdUser = id => {
    this.dadosUser.user.id = id
  }

  @action
  getIdUser = () => {
    const {
      user: { id }
    } = this.dadosUser
    return id
  }

  @action
  getDataValidadeContrato = modulo => {
    if (this.contratos.length > 0) {
      let dataValidade = this.contratos.find(
        item => item.name_modulo === modulo
      ).data_validade
      return dataValidade
    }
  }

  @action
  instancia_props = props => {
    this._props = props
  }

  @action
  redireciona_login = () => {
    this._props.history.push('/')
  }

  @action
  redireciona_cadastro = () => {
    this._props.history.push(CADASTRO)
  }

  @action
  redireciona_recuperar_senha = () => {
    this._props.history.push(RECUPERAR_SENHA)
  }

  @action
  redireciona_editais = () => {
    this._props.history.push(EDITAIS)
  }

  @action
  redireciona_buscaeditais = () => {
    this._props.history.push(BUSCA_DE_EDITAIS)
  }

  @action
  redireciona_licitacoes = () => {
    this._props.history.push(LICITACOES)
  }

  @action
  redireciona_monitoramento = () => {
    this._props.history.push(MONITORAMENTO)
  }

  @action
  redireciona_ocorrenciasdodia = () => {
    this._props.history.push(OCORRENCIAS_DO_DIA)
  }

  @action
  redireciona_configuracoesbuscadeeditais = () => {
    this._props.history.push(CONF_BUSCA_DE_EDITAIS)
  }

  @action
  redireciona_configuracoesmonitoramento = () => {
    this._props.history.push(CONF_MONITORAMENTO)
  }

  @action
  redireciona_configuracaogeral = () => {
    this._props.history.push(CONF_GERAL)
  }

  @action
  redireciona_licitacao = () => {
    this._props.history.push(LICITACAO)
  }

  @action
  redireciona_funil = () => {
    this._props.history.push(FUNIL)
  }

  @action
  redireciona_financas = () => {
    this._props.history.push(FINANCEIRO)
  }

  @action
  redireciona_proposta = () => {
    this._props.history.push(PROPOSTA)
  }

  @action redireciona_gerenciar_proposta = () =>
    this._props.history.push(GERENCIAR_PROPOSTA)

  @action
  redireciona_pagina_atual = () => {
    let url = this._props.location.pathname

    if (url === EDITAIS) {
      this._props.history.push(EDITAIS)
    }

    if (url === LICITACOES) {
      LicitacoesPaginadaStore.pagina_atual = 1
      LicitacoesPaginadaStore.consulta_api()
    }

    if (url === LICITACAO || url === FUNIL || url === AGENDA) {
      this._props.history.push(LICITACOES)
      LicitacoesPaginadaStore.pagina_atual = 1
      LicitacoesPaginadaStore.consulta_api()
    }

    if (url === PAINEL_MONITORAMENTO) {
      PainelAvisoStores.consulta_api(this.getIdEmpresa())
    }

    if (url === MONITORAMENTO) {
      MonitoramentoStore.mensagens = []
      MonitoramentoStore.verificada = false
      MonitoramentoStore.consultaApi()
    }

    if (url === OCORRENCIAS_DO_DIA) {
      OcorrenciasDoDiaStores.pagina_atual = 1
      OcorrenciasDoDiaStores.consultaApi()
    }

    if (url === FINANCEIRO) {
      FinanceiroStores.consulta_modulos()
      FinanceiroStores.consulta_empresa()
      FinanceiroStores.consulta_boletos()
    }
  }

  @action redirect = id => {
    MonitoramentoStore.selectLicitacao = id
    MonitoramentoStore.primeira_vez = '1'
    localStorage.setItem('licitacao_monitorada', id)
    MonitoramentoStore.consulta_especifica(id)
    this._props.history.push('/monitoramento')
  }

  @action limparVariaveis = () => {
    this._props = ''
    this.licitacao_monitorada = 'primeira'
    this.tipo_visualizacao = 'todas'
    this.pagina_atual_mensagens = 1
    this.pagina_visualizada = 1
    this.tipo_licitacao = 'todas'
    this.id_fornecedor = null
    this.empresas = []
    this.portaisContratados = []

    this.dadosUser = {
      user: { user: '', email: '' },
      fornecedor: { id: '', cnpj: '' }
    }

    this.contratos = []
  }
}

const maquinadeestadosstore = new MaquinaDeEstadosStore()
export default maquinadeestadosstore
