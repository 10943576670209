import React, { useState } from 'react'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  BodyMessage,
  ActionButtons,
  ModalOverlay
} from '../sytleModal'

import { AlertCircle } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactTooltip } from '../../../globalStyles'

export const ModalConfirmacao = ({
  button,
  width,
  title,
  ico,
  message,
  confirmAction,
  secondButtonText,
  secondAction,
  disabled
}) => {
  const [active, setActive] = useState(false)

  const handleConfirm = () => {
    confirmAction()
    setActive(false)
  }

  const handleSecondAction = () => {
    if (secondAction === 'CLOSE') return setActive(false)
    if (secondAction) secondAction()
    setActive(false)
  }

  return (
    <>
      <ReactTooltip />
      <span onClick={!disabled && setActive}>{button}</span>
      {active && (
        <ModalOverlay>
          <ModalContainer
            width={width || '700px'}
            left="unset"
            right="unset"
            top="unset"
            bottom="unset"
          >
            <ModalHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AlertCircle color="#fff" />
                <ModalTitle>{title}</ModalTitle>
              </div>
              <FontAwesomeIcon
                className="icon-scale"
                icon="times"
                color="#fff"
                style={{ cursor: 'pointer', opacity: '0.9', fontSize: '15px' }}
                onClick={() => setActive(!active)}
              />
            </ModalHeader>
            <div
              style={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}
            >
              {ico || <AlertCircle color={'#3A566E'} size={'5rem'} />}
            </div>

            <BodyMessage>{message}</BodyMessage>
            <ActionButtons>
              <button
                className="icon-scale-xs modal-back"
                onClick={handleSecondAction}
              >
                {secondButtonText || 'VOLTAR'}
              </button>
              <button
                className="icon-scale-xs modal-confirm"
                onClick={handleConfirm}
              >
                CONFIRMAR
              </button>
            </ActionButtons>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export const ModalConfirmacaoSemBotao = ({
  active,
  close,
  width,
  title,
  message,
  confirmAction,
  secondButtonText,
  secondAction
}) => {
  const handleConfirm = () => {
    confirmAction()
    close()
  }

  const handleSecondAction = () => {
    if (secondAction === 'CLOSE') return close()
    if (secondAction) secondAction()
    close()
  }

  return (
    <>
      <ReactTooltip />
      {active && (
        <ModalOverlay>
          <ModalContainer
            width={width || '700px'}
            left="unset"
            right="unset"
            top="unset"
            bottom="unset"
          >
            <ModalHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AlertCircle color="#fff" />
                <ModalTitle>{title}</ModalTitle>
              </div>
              <FontAwesomeIcon
                className="icon-scale"
                icon="times"
                color="#fff"
                style={{ cursor: 'pointer', opacity: '0.9', fontSize: '15px' }}
                onClick={close}
              />
            </ModalHeader>
            <div
              style={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}
            >
              <AlertCircle color={'#3A566E'} size={'5rem'} />
            </div>

            <BodyMessage>{message}</BodyMessage>
            <ActionButtons>
              <button
                className="icon-scale-xs modal-back"
                onClick={handleSecondAction}
              >
                {secondButtonText || 'VOLTAR'}
              </button>
              <button
                className="icon-scale-xs modal-confirm"
                onClick={handleConfirm}
              >
                CONFIRMAR
              </button>
            </ActionButtons>
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export default ModalConfirmacao
