import React, { useState, useEffect } from "react";
import { Calendar, Clock } from "react-feather";

export const DateAndTime = ({ date }) => {
  let interval;
  const [width, changeWidth] = useState(window.innerWidth);
  const [time, changeTime] = useState(new Date());
  const [blink, changeBlink] = useState(false);
  useEffect(() => {
    changeWidth(window.innerWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    interval = setInterval(() => {
      changeTime(new Date());
      changeBlink(!blink);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [blink]);

  const formatHour = date => {
    return date.toString().substr(date.toString().indexOf(":") - 2, 8);
  };
  return (
    <div
      className="d-flex justify-content-around text-white"
      style={{
        alignItems: "center",
        fontWeight: 500,
        width: "280px",
        fontSize: width < 1400 ? "13px" : "16px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Calendar className="mr-1" />
        {date()}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: blink ? "transparent" : "#fff",
          transition: "1s"
        }}
      >
        <Clock className="mr-1" />
        <span style={{ width: "100px" }}>{formatHour(time)}</span>
      </div>
    </div>
  );
};
