import { observable, action } from "mobx";

import MaquinaDeEstadosStore from "../../../../Stores/MaquinaDeEstadosStore";
import Api from "../../../../Stores/Conexao/conexao";
import { tratarDadosEditais } from "../../Components/tratarDadosEditais";
import DetalhesEditalStore from "../../../../Components/Modules/ModalDetalhes/store";
import BuscaManualStore from "../../ManualSearch/Store/index";
import { ConsoleHelper } from "../../../../utils/ConsoleHelper";

class QualificadosStore {
  @observable isFetching = false;
  @observable editais = [];
  @observable total_paginas = 0;

  @action changeEditais = (id, status) =>
    (this.editais = this.editais.map(ed =>
      ed.id === id ? { ...ed, status } : ed
    ));

  @observable total_registros = 0;

  @observable ordenacao = "data_disputa";
  @observable crescente = true;

  @observable usuario = null;
  @action changeUser = ({ target: { value } }) => {
    this.usuario = value !== "null" ? Number(value) : null;
  };

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  };

  @observable filtros = {
    dmin: null,
    dmax: null,
    qualData: "inicial",
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString("pt-br");
      this.filtros.dmax = new Date().toLocaleDateString("pt-br");
    },
    changeDate: range => {
      const { qualData } = this.filtros;

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString("pt-br");
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString("pt-br");

      return (this.filtros.qualData =
        qualData === "inicial" ? "final" : "inicial");
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value;
    }
  };

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  };

  generateUrl = () => {
    const { dmin, dmax } = this.filtros;
    const searchParams = new URLSearchParams();
    const url = "/portal/boletins/";
    searchParams.append("fornecedor", MaquinaDeEstadosStore.getIdEmpresa());

    if (dmin) searchParams.append("dmin", dmin);
    if (dmax) searchParams.append("dmax", dmax);
    searchParams.append("pagina", this.pagination.page);
    searchParams.append("status", "qualificado");
    searchParams.append("ordenacao", this.ordenacao || "data_disputa");
    searchParams.append("crescente", this.crescente);
    searchParams.append("usuario", this.usuario);
    return `${url}?${searchParams.toString()}`;
  };

  @action fetchEditais = async (
    perfil_id = null,
    perfil = undefined,
    idPerfil = null,
    type = "",
    page = null,
    id = null
  ) => {
    this.dataIsFiltered = false;
    this.isFetching = true;
    try {
      const res = await Api.get(this.generateUrl(perfil_id));
      const { editais, total_registros, config_perfis } = res.data;
      if (type === "modalDetail") {
        DetalhesEditalStore.tratarEditaisAdicionados(
          tratarDadosEditais(editais),
          page
        );
        if (id && id > 0) {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[0].id
          );
        } else {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[9].id
          );
        }
      }
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      );
      this.editais = tratarDadosEditais(editais);
      this.total_paginas = Math.ceil(total_registros / 10);
      this.total_registros = total_registros;
    } catch (err) {
    } finally {
      BuscaManualStore.setDisableNext(false);
      BuscaManualStore.setDisablePrevious(false);
      this.isFetching = false;
    }
  };

  @observable dataIsFiltered = false;

  @action setDataIsFiltered = value => {
    this.dataIsFiltered = value;
  };

  @action fetchEdital = async data => {
    const {
      date: { dmin, dmax },
      pregao,
      pregaoUASG: uasg,
      tipoDate
    } = data;
    this.isFetching = true;
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    query += `&dmin=${dmin}`;
    query += `&dmax=${dmax}`;
    query += `&date_type=${tipoDate}`;
    query += `&uasg=${uasg}`;
    query += `&edital=${pregao}`;
    query += `&pagina=${this.pagination.page}`;
    const queryPattern =
      "&status=qualificado&ordenacao=data_disputa&crescente=true&usuario=null";
    query += queryPattern;
    try {
      const res = await Api.get(query);
      const { editais, total_registros } = res.data;
      this.editais = tratarDadosEditais(editais);
      this.total_paginas = Math.ceil(total_registros / 10);
      this.total_registros = total_registros;
    } catch (err) {
      ConsoleHelper("err", err);
    } finally {
      this.isFetching = false;
    }
  };

  fetchMore = async page => {
    try {
      const res = await Api.get(this.generateUrl(page));
      return res.data.editais;
    } catch (err) {}
  };

  trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.fetchEditais();
  };

  limparVariaveis = () => {
    this.isFetching = false;

    this.usuario = null;

    this.editais = [];
    this.total_paginas = 0;
    this.total_registros = 0;

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    };

    this.filtros = {
      dmin: new Date().toLocaleDateString("pt-br"),
      dmax: new Date().toLocaleDateString("pt-br"),
      qualData: "inicial",
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString("pt-br");
        this.filtros.dmax = new Date().toLocaleDateString("pt-br");
      },
      changeDate: range => {
        const { qualData } = this.filtros;

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          "pt-br"
        );
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString("pt-br");

        return (this.filtros.qualData =
          qualData === "inicial" ? "final" : "inicial");
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value;
      }
    };

    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    };
  };
}

export default new QualificadosStore();
