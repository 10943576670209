import { action, observable } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import ModalLoadingStore from "./ModalLoadingStore";

class DashboardStores {
  @observable data = [];
  @observable status = false;

  @action
  consulta_dashboard = async () => {
    this.status = false;
    ModalLoadingStore.handleOpenModal();
    try {
      let response = await Api.get(
        `dashboard/${MaquinaDeEstadosStore.getIdEmpresa()}`
      );
      this.data = response.data;
      this.status = true;
    } catch (e) {
      this.status = false;
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action limparVariaveis = () => {
    this.data = [];
    this.status = false;
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consulta_dashboard();
  };
}

const dashboardStores = new DashboardStores();
export default dashboardStores;
