import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { CardMsg } from "../../globalStyles";
import { XCircle } from "react-feather";
import TagsInput from "react-tagsinput";
import { ConfiguracaoEditaisContext } from "./stores/store";

const PalavraChaveExclusao = observer(() => {
  const {
    handleChangeInput_palavra_exclusao,
    handleChange_palavra_exclusao,
    tag_palavra_exclusao,
    tags_palavra_exclusao
  } = useContext(ConfiguracaoEditaisContext);
  return (
    <CardMsg
      minHeight={"15vh"}
      height="150px"
      flexDirection={"column"}
      margin={"0px 0px 0px 0px"}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", textAlign: "center" }}>
          <XCircle color={"red"} />
          &nbsp;&nbsp;
        </div>
        <div style={{ padding: "0px 10px 0px 10px" }}>
          PALAVRAS-CHAVE EXCLUSÃO
          <div style={{ fontSize: "10px" }}>
            As palavras-chave só serão sincronizadas no próximo horário de
            agendamento de envio.
          </div>
        </div>
      </div>

      <TagsInput
        classNameRemove={"react-tagsinput-remove"}
        className="tagsinputred"
        inputProps={{ placeholder: "Adicionar palavra" }}
        value={tags_palavra_exclusao.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
        onChange={e => handleChange_palavra_exclusao(e)}
        inputValue={tag_palavra_exclusao}
        onChangeInput={e => handleChangeInput_palavra_exclusao(e)}
      />
    </CardMsg>
  );
});

// const PalavraChaveExclusao = inject("ConfiguracoesBuscaEditaisStores")(
//   observer(props => {
//     const {
//       handleChangeInput_palavra_exclusao,
//       handleChange_palavra_exclusao,
//       tag_palavra_exclusao,
//       tags_palavra_exclusao
//     } = props.ConfiguracoesBuscaEditaisStores;

//     return (
//       <CardMsg
//         minHeight={"15vh"}
//         height="150px"
//         flexDirection={"column"}
//         margin={"0px 0px 0px 0px"}
//       >
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <div style={{ display: "flex", textAlign: "center" }}>
//             <XCircle color={"red"} />
//             &nbsp;&nbsp;
//           </div>
//           <div style={{ padding: "0px 10px 0px 10px" }}>
//             PALAVRAS-CHAVE EXCLUSÃO
//             <div style={{ fontSize: "10px" }}>
//               As palavras-chave só serão sincronizadas no próximo horário de
//               agendamento de envio.
//             </div>
//           </div>
//         </div>

//         <TagsInput
//           classNameRemove={"react-tagsinput-remove"}
//           className="tagsinputred"
//           inputProps={{ placeholder: "Adicionar palavra" }}
//           value={tags_palavra_exclusao}
//           onChange={e => handleChange_palavra_exclusao(e)}
//           inputValue={tag_palavra_exclusao}
//           onChangeInput={e => handleChangeInput_palavra_exclusao(e)}
//         />
//       </CardMsg>
//     );
//   })
// );

export default PalavraChaveExclusao;
