import React, { Component } from "react";
import "react-tagsinput/react-tagsinput.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import Modal from "react-modal";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("LoginStores")
@observer
class ModalRedefinirSenha extends Component {
  render() {
    return (
      <span>
        <Modal
          className="Modal_Load"
          overlayClassName="Overlay_Load"
          isOpen={this.props.LoginStores.status_modal}
        >
          <Grid fluid={true} style={{ height: "100%" }}>
            <Row>
              <Col md={12} fluid>
                <Col md={1} style={{ float: "right" }} className="text-right">
                  <Button
                    onClick={() =>
                      this.props.LoginStores.toggleModal(this.props)
                    }
                    bsClass="btn btn-sm btn-outline"
                    bsStyle="danger"
                    className=""
                  >
                    X
                  </Button>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center">
                <span className="" style={{ color: "green", fontSize: "50px" }}>
                  <FontAwesomeIcon icon="check-circle" />
                </span>
                <h5>&nbsp; &nbsp; SENHA ALTERADA COM SUCESSO!</h5>
                <p>Voltar para tela de login</p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center">
                <hr />
                <Button
                  bsStyle="primary"
                  onClick={e =>
                    this.props.LoginStores.voltar_tela_login(e, this.props)
                  }
                  style={{ marginLeft: "10px" }}
                  className="text-center"
                >
                  Login
                </Button>
              </Col>
            </Row>
            <br />
          </Grid>
        </Modal>
      </span>
    );
  }
}

export default ModalRedefinirSenha;
