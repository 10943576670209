export const qualifications = [
  { id: 1, name: 'PRIORIDADE', icon: ' ', byId: 'select-priority' },
  { id: 2, name: 'USUÁRIO RESPONSÁVEL', icon: ' ', byId: 'select-user' }
]

export const approvals = [
  { id: 0, name: 'APROVAÇÃO', icon: ' ', byId: 'approved-qualified' },
  { id: 1, name: 'PRIORIDADE', icon: ' ', byId: 'approved-priority' },
  { id: 2, name: 'USUÁRIO RESPONSÁVEL', icon: ' ', byId: 'approved-user' }
]

export const reprovals = [
  { id: 0, name: 'MOTIVO/BOLETIM', icon: ' ', byId: 'reason-report-card' },
  { id: 1, name: 'MOTIVO/QUALIFICADOS', icon: ' ', byId: 'reason-qualified' },
  { id: 2, name: 'MOTIVO/APROVADOS', icon: ' ', byId: 'reason-approved' },
]
