import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";

class ModalNotificacoesStores {
  @observable notificacoes = [];
  @observable MsgObs = "";
  @observable size = "";
  @observable data = "";
  @observable consultando_notificacoes = false;

  @action consulta_api = () => {
    if (this.consultando_notificacoes) {
      //CONSULTANDO NOTIFICACOES, ENTAO NAO CONSULTA DEVOLTA!!!
    } else {
      this.consultando_notificacoes = true;
      Api.post("monitoramento/notificacoes/", {})
        .then(response => {
          this.notificacoes = response.data.notificacoes;
          this.size = response.data.notificacoes.length;
          this.consultando_notificacoes = false;
          //MenuStores.ativarTudo();
        })
        .catch(error => {
          if (!statusConexao()) {
            StatusConexaoStores.handleOpenModal();
          }
          this.consultando_notificacoes = false;
        });
    }
  };

  @action formatandoData(data) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).format(new Date(data.timestamp));
  }

  @action limparVariaveis = () => {
    this.notificacoes = [];
    this.MsgObs = "";
    this.size = "";
    this.data = "";
    this.consultando_notificacoes = false;
  };
}

const modalNotificacoesStores = new ModalNotificacoesStores();
export default modalNotificacoesStores;
