import React, { useRef, useEffect } from 'react';

const ItemDesabilitado = ({value, status}) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.parentElement.parentElement.parentElement.parentElement.style.backgroundColor = "#e2e2e2";
  }, [])
  return (
    <div ref={ref}>
      {status && value}
    </div>
  )
}

export default ItemDesabilitado
