import React, { useEffect, useState } from 'react'
import { Progress } from 'reactstrap'
import { Calendar, DownloadCloud, Key, Layers, Map } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Api from '../../../Stores/Conexao/conexao'
import SubHeader from '../SubHeader/SubHeader'
import SectionTitle from '../SubHeader/SectionTitle'
import DownloadEditaisStore from './store'
import StatusModal from '../StatusNotice'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'
import { FormatDateData } from '../../../Stores/Funcoes/FuncoesGerais'

import * as Style from '../Style'
import * as S from './style/style'

const DetalhesDownload = ({
  edital,
  filtro,
  setStatusDetalhe,
  baixarTodos,
  total_registros,
  errorDownload,
  downloadTodosConcluido
}) => {
  const {
    state: { palavra, portais, date, estados, modalidade }
  } = useFiltroEdital()
  const [objeto, setStatus] = useState({
    color: 'black',
    background: '#F4F2F5',
    status: null
  })
  const [resApi, setResApi] = useState(false)
  const [barra, setBarra] = useState(0)
  const [editais, setEdital] = useState(edital)
  const { color, background, status } = objeto

  useEffect(() => {
    if (downloadTodosConcluido) {
      setStatus({
        ...objeto,
        color: 'white',
        background: 'green',
        status: 'todos'
      })
      setBarra(100)
    }
  }, [downloadTodosConcluido])

  useEffect(() => {
    if (errorDownload) {
      setStatus({ ...objeto, status: 'error', background: '#8e140e' })
      setBarra(100)
      // setTimeout(() => setBarra(0), 1000)
    }
  }, [errorDownload])

  useEffect(() => {
    if (baixarTodos) {
      const editalTodos = []
      for (let i = 1; i <= total_registros; i++) {
        editalTodos.push({ id: i, licitacao: i })
      }
      // downloadTodos()
      setEdital(editalTodos)
    } else {
      downloadSelecionadosNovo(edital)
    }

    return () => {
      DownloadEditaisStore.errorDownload = false
      DownloadEditaisStore.downloadTodosConcluido = false
    }
  }, [])

  const nomearAquivo = () => {
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore
    if (!editais.length) return
    const date = FormatDateData(new Date())
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia
    const fileName = `${editais.length}_Editais_${empresaName}_${date}.zip`
    return fileName
  }

  const download = () => {
    const fileName = nomearAquivo()
    const url = window.URL.createObjectURL(new Blob(resApi))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const downloadTodos = () => window.open(downloadTodosConcluido, '_blank')

  const downloadSelecionadosNovo = async listaId => {
    const dados = [...listaId]
    try {
      const res = await Api.post(
        'portal/download_zip/',
        { dados, fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa() },
        { responseType: 'blob' }
      )
      setResApi([res.data])
      setBarra(100)
      const newOnjeto = {
        ...objeto,
        color: 'white',
        background: 'green',
        status: true
      }
      setStatus(newOnjeto)
    } catch (err) {
    } finally {
    }
  }

  const handleStatus = resp => {
    if (resp) {
      const newOnjeto = {
        ...objeto,
        color: 'white',
        background: 'green',
        status: true
      }
      setStatus(newOnjeto)
    } else {
      const newOnjeto = {
        ...objeto,
        color: 'white',
        background: 'red',
        status: false
      }
      setStatus(newOnjeto)
    }
  }

  useEffect(() => {
    const intv = setInterval(() => {
      setBarra(b => {
        if (b < 99) return b + 1
        clearInterval(intv)
        return b
      })
    }, 500)
  }, [])

  return (
    <>
      <SubHeader overflow>
        <SectionTitle title="DOWNLOAD" icon={<DownloadCloud />} />
      </SubHeader>
      <Style.Body>
        <Style.ContainerTable>
          <div style={{ padding: '15px', width: '97%', marginTop: '15px' }}>
            <S.TitleHeard>
              {status === true && 'ARQUIVOS PRONTOS'}
              {status === 'error' && 'ERRO'}
              {!status && 'PREPARANDO OS ARQUIVOS'}
            </S.TitleHeard>
            <Progress color={'success'} animated value={barra}>
              {Math.round(barra)}%
            </Progress>
            <S.InfoHeard>
              ESSE PROCESSO PODE LEVAR ALGUM TEMPO PARA SER FINALIZADO
            </S.InfoHeard>
          </div>
          <S.WrapperInfoDownload status={status} background={background}>
            <S.WrapperCard>
              <S.Card>
                <DownloadCloud size={'2vw'} />
                <span>DOWNLOAD</span>
                <div>{editais.length}</div>
              </S.Card>
              <S.Card>
                <Calendar size={'2vw'} />
                <span>DATA</span>
                <div>{date.dmax}</div>
              </S.Card>
              <S.Card>
                <Layers size={'2vw'} />
                <span>PORTAIS</span>
                <div>{portais.length}</div>
              </S.Card>
              <S.Card>
                <Map size={'2vw'} />
                <span>ESTADOS</span>
                <div>{estados.length}</div>
              </S.Card>
              <S.Card>
                <Key size={'2vw'} />
                <span>PALAVRA CHAVE</span>
                <div>{palavra.length}</div>
              </S.Card>
            </S.WrapperCard>
            <div className={'cardInfo'}>
              {!status && (
                <div className={'spinner'}>
                  <FontAwesomeIcon
                    spin={status === null}
                    icon="spinner"
                    color="#1976D2"
                    style={{ marginRight: '10px', fontSize: '50px' }}
                  />
                </div>
              )}
              {status && (
                <FontAwesomeIcon
                  icon="cloud-download-alt"
                  color={status !== 'error' ? '#1976D2' : '#FF0134'}
                  style={{ marginRight: '10px', fontSize: '7vw' }}
                />
              )}
              {status == true && (
                <div className="btnDownload" onClick={download}>
                  BAIXAR EDITAIS
                </div>
              )}
              {status == 'todos' && (
                <div className="btnDownload" onClick={downloadTodos}>
                  BAIXAR EDITAIS
                </div>
              )}
              {status === 'error' && (
                <p style={{ color: 'red' }}>ERRO: ARQUIVOS NÃO ENCONTADOS</p>
              )}
              {status === null && (
                <p>
                  ESTAMOS PREPARANDO SEUS ARQUIVOS EM UMA PASTA ZIPADA. ASSIM
                  QUE O PROCESSO FOR FINALIZADO CLICK NO ICONE ACIMA
                </p>
              )}
            </div>
          </S.WrapperInfoDownload>
          <S.ButtonClose onClick={() => setStatusDetalhe(false)}>
            FECHAR
          </S.ButtonClose>
        </Style.ContainerTable>
      </Style.Body>
      <StatusModal />
    </>
  )
}

export default DetalhesDownload
