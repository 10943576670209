import React from "react";
import { AlertCircle } from "react-feather";

import { EditalNotFound, ModalCloseButton } from "../../globalStyles";

export const ExclamationWarning = ({ title, height, closeButton, width }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        background: "white",
        // height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: height || "auto",
        width: width || "unset"
      }}
    >
      <AlertCircle color={"#3A566E"} size={"5rem"} />
      <EditalNotFound>{title}</EditalNotFound>
      {closeButton && (
        <ModalCloseButton onClick={closeButton}>FECHAR</ModalCloseButton>
      )}
    </div>
  );
};
