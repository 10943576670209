import moment from "moment";

export const formatData = data => {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false
  }).format(new Date(data));
};

export function formatarDataUsa(data) {
  let dados = moment(data, "DD/MM/YYYY, HH:mm:ss").format(
    "YYYY/MM/DD, HH:mm:ss"
  );
  return String(dados);
}

export const compareData = data_contrato => {
  let partesData = formatData(data_contrato).split("/");
  let data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
  return data >= new Date();
};

export const compareDataNew = data_contrato => {
  let dataAtual = formatData(new Date());
  let dataContrato = formatData(new Date(data_contrato));

  let data1 = moment(dataAtual, "DD/MM/YYYY ");
  let data2 = moment(dataContrato, "DD/MM/YYYY");
  let diferenca = data2.diff(data1, "days");

  return diferenca;
};

export const sliceString = (string, characters) => {
  if (string.length <= characters) return string;
  return string.substring(characters, 0).concat("...");
};
