import React, { useState } from 'react'
import { CardBody, CardFiltro, CardHeard, CardTitulo } from './styles'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import { FormControl, FormGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { Layers } from 'react-feather';
import { BodySwitch, CustomSwitch } from '../../../globalStyles';

const FiltroMaterialServico = () => {
  const { state: { tipoItem }, onChangeTipoItem } = useFiltroEdital();


  const handleSwitch = (e) => {
    const { value, checked } = e.target;

    if (!checked || tipoItem.includes(value))
      onChangeTipoItem(tipoItem.filter(el => el !== value))
    else
      onChangeTipoItem([...tipoItem, value]);
  }


  return (
    <CardFiltro>
      <CardHeard>
        <Layers />
        <CardTitulo>
          <div>FILTRO MATERIAL/SERVIÇO</div>
          <p>Selecione o tipo de edital</p>
        </CardTitulo>
      </CardHeard>
      <CardBody style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <BodySwitch
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomSwitch
            checked={tipoItem.includes('M')}
            onChange={handleSwitch}
            value="M"
            color="primary"
          />
          <b style={{ fontWeight: 500 }}>MATERIAIS</b>
        </BodySwitch>
        <BodySwitch
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomSwitch
            checked={tipoItem.includes('S')}
            onChange={handleSwitch}
            value="S"
            color="primary"
          />
          <b style={{ fontWeight: 500 }}>SERVIÇOS</b>
        </BodySwitch>
      </CardBody>
    </CardFiltro>
  )
}

export default FiltroMaterialServico