import React, { useContext, useEffect } from 'react';
import BotoesNavegacao from './BotoesNavegacao';
import { PropostaEspecificaContext } from './context';
import { FluxoDeEnvioContext } from './fluxoDeEnvio/context';
import { DocumentosContext } from './fluxoDeEnvio/enviarDocumentos/context';
import FluxoDeEnvio from './fluxoDeEnvio/FluxoDeEnvio';

import { Container } from './styles';

const PropostaEspecifica = () => {
  const { steps } = useContext(FluxoDeEnvioContext);
  const { cleanData } = useContext(DocumentosContext);
  const { component } = steps.find(step => step.active);


  useEffect(() => {
    return cleanData
  }, [])

  return (
    <Container>
      <FluxoDeEnvio />
      {component}
      <BotoesNavegacao />
    </Container>
  )
}

export default PropostaEspecifica
