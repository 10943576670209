
import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import ReactDOM from 'react-dom'
import { Key } from 'react-feather';
import { getPortalName } from '../../Services/portais';
import maquinadeestadosstore from '../../Stores/MaquinaDeEstadosStore';
import { useState } from 'react';
import ModalEditarCredencial from './ModalEditarCredencial';

const Container = styled.div`
  position: absolute;
  width: 357px;
  height: 60px;
  background: white;
  box-shadow: 2px 2px 6px #00000085;
  display: flex;
  align-items: center;
  color: #3a566e;
  font-size: 12px;
  font-weight: 500;
  z-index: 222;
  cursor: pointer;
  left: -357px;
  bottom: 5px;
  user-select: none;
  animation: 1s ease-in slideRightWarning forwards 3s;
  

  svg { 
    margin: 0 10px;
  }

  button {
    margin: 0 10px 0 auto;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #00000085;
    background: #267c90;
    font-weight: 600;
    color: #e6e6e6;
  }

  @keyframes slideRightWarning {
    from {
      left: -357px;
    }
    to {
      left: 0;
    }
  }
`;

const AvisoCredencial = inject("MaquinaDeEstadosStore")(
  observer(({ MaquinaDeEstadosStore: { credenciais } }) => {
    return <AvisoCredencialComStore credenciais={credenciais} />
  }));

const AvisoCredencialComStore = ({ credenciais }) => {
  const credencialVencida = credenciais.find(cr => cr.expired);
  if (!credenciais.length || !credencialVencida) return null;

  const [modalEditar, setModalEditar] = useState(false);

  const handleEditar = (e) => {
    setModalEditar(!modalEditar);
  }


  return ReactDOM.createPortal(
    <>
      <Container onClick={handleEditar}>
        <Key />
        Credenciais expiradas para {getPortalName(credencialVencida.portal_id)}
        <button type="button" className="icon-scale-xs">EDITAR</button>
      </Container>
      {modalEditar && <ModalEditarCredencial closeModal={() => setModalEditar(false)} credencial={credencialVencida} />}
    </>
    , document.querySelector('body')
  )
}

export default AvisoCredencial
