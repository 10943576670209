import { observable, action } from "mobx";
import $ from "jquery";

class StoreSelecaoPortais {
  @observable todosPortais = [
    { nome: "ComprasNet", id: 5 },
    { nome: "Licitações-e", id: 6 },
    { nome: "Compras RS", id: 12 },
    { nome: "Compras Banrisul", id: 13 },
    { nome: "Portal de Compras Vitória", id: 15 },
    { nome: "Portal de Compras Natal", id: 17 },
    { nome: "Portal FIESC SESI ", id: 18 },
    { nome: "Portal de Compras Amazonas ", id: 129 },
    { nome: "Portal de Compras Paraíba", id: 130 },
    { nome: "Portal ComprasNet Goiânia", id: 131 },
    { nome: "Portal de Compras Empro", id: 137 }
  ];

  @observable portal = this.todosPortais.map(p => p.id);

  @action changePortal = () => (this.portal = $("#portais-select").val());

  @action limparVariaveis = () => {
    this.portal = this.todosPortais.map(p => p.id);
  };
}

const storeSelecaoPortais = new StoreSelecaoPortais();
export default storeSelecaoPortais;
