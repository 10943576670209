import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import $ from "jquery";
import {
  AlertaInfo,
  AlertaSuccess,
  AlertaVerde,
  AlertaVermelho
} from "./Funcoes/FuncoesGerais";
import ModalLoadingStore from "./ModalLoadingStore";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import SideStores from "./SideStores";
import LoginStores from "./LoginStores";
import EditaisStores from "./EditaisStores";

class BuscaDeEditaisStores {
  @observable
  todosEstados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO"
  ];

  // MATERIAIS/SERVIÇOS
  @observable tipo_item = ["M", "S"];

  // PORTAIS
  @observable
  todosPortais = [...MaquinaDeEstadosStore.portais];

  @observable
  portal = this.todosPortais.map(p => p.id);

  // DATA
  @observable
  dataInicial = new Date().toLocaleDateString("pt-br");
  @observable
  dataFinal = new Date().toLocaleDateString("pt-br");

  // EDITAIS
  @observable
  editais = null;
  @observable
  numero_editais = [];
  @observable
  currentEditais = [];
  @observable
  itens_edital = [];
  @observable
  estado = null;
  @observable
  palavra = "";
  @observable
  showing = false;
  @observable
  pagina_atual = 0;
  @observable
  flagConsultar = true;
  @observable
  pesquisaDataType = "abertura";
  @observable
  statusDataType = true;
  @observable
  styleData = "abertura";
  @observable
  editais_total = 0;

  @observable
  filtroMe = false;

  @observable
  isFetching = false;
  @observable
  ordenar = "disputa";
  @observable
  qualData = "inicial";

  @observable
  palavrasConfiguradas = null;
  @observable
  incluindoPalavras = false;

  @observable
  palavrasChave = [];
  @observable
  palavraAtual = "";

  @action changeMaterialServico = ({ target: { value } }) => {
    this.tipo_item = this.tipo_item.includes(value)
      ? this.tipo_item.filter(el => el !== value)
      : [...this.tipo_item, value];
  };

  @action
  changePortal = () => (this.portal = $("#portais-select").val());

  @action
  sincronizar_Licit_E = id => {
    this.editais = this.editais.map(item => {
      return id === item.id ? { ...item, sincronizado: true } : item;
    });

    Api.post("licitacoes/cadastrar_licitacao_manual/", {
      id_edital: id,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      user_pk: LoginStores.user_id
        ? LoginStores.user_id
        : MaquinaDeEstadosStore.getIdUser()
    })
      .then(response => {
        AlertaSuccess("Edital adicinado as licitações com sucesso!");
      })
  };

  @action handleStatusMe = () => (this.filtroMe = !this.filtroMe);

  @action
  limparFiltros = () => {
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.editais = null;
    this.numero_editais = [];
    this.currentEditais = [];
    this.itens_edital = [];
    this.estado = null;
    this.palavra = "";
    this.showing = false;
    this.tipo_item = ["M", "S"];
    this.filtroMe = false;
    this.pagina_atual = 0;
    this.flagConsultar = true;
    this.editais_total = 0;
    this.isFetching = false;
    this.palavrasChave = [];
    this.palavraAtual = "";
    this.incluindoPalavras = false;
  };

  @action
  handleOrdenacao = () => {
    this.ordenar = this.ordenar === "disputa" ? "abertura" : "disputa";
    this.pesquisa_palavra();
  };

  @action
  handleStatusPesquisa = ({ target: { value } }) => {
    this.pesquisaDataType = value;
    this.statusDataType = value === "abertura";
    this.pagina_atual = 1;
  };

  @action
  fixPaginaAtual = () => {
    if (this.pagina_atual === 0) {
      this.pagina_atual = 1;
    }
  };

  @action
  onPageChanged = data => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    this.fixPaginaAtual();
    this.pesquisa_palavra();
  };

  @action
  handleConsulta = () => {
    this.pesquisa_palavra();
  };

  @action
  abreDate = () => {
    this.showing = true;
  };

  @action
  fecharDate = () => {
    this.showing = false;
    if (this.qualData === "final") this.qualData = "inicial";
  };

  @action
  handleSelect = range => {
    this.dataInicial = new Date(range.startDate).toLocaleDateString("pt-br");
    this.dataFinal = new Date(range.endDate).toLocaleDateString("pt-br");
    if (this.qualData === "final")
      setTimeout(() => {
        this.fecharDate();
        this.qualData = "inicial";
      }, 250);
    else this.qualData = "final";
  };

  @action
  onChange = evento => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;
    this.estado = $("#selecionar-estado").val();
  };

  @action
  loadDataPalavraChave = () => {
    if (!this.tipo_item.length) this.tipo_item = ["M", "S"];
    return {
      tipo: this.pesquisaDataType,
      pagina: this.pagina_atual,
      estado: $("#selecionar-estado").val(),
      dt_inicio: this.dataInicial,
      dt_fim: this.dataFinal,
      palavra: this.palavrasChave.join(","),
      ordenar: this.ordenar,
      empresa_me: this.filtroMe,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      portal: this.portal.map(por => Number(por)),
      tipo_item: this.tipo_item
    };
  };

  @action
  loadPalavrasConfiguradas = async () => {
    const { getIdEmpresa } = MaquinaDeEstadosStore;
    ModalLoadingStore.handleOpenModal();
    try {
      const res = await Api.get(`portal/configuracao_novo/${getIdEmpresa()}`);
      const { perfis_configuracao, perfil_ativado } = res.data;
      let palavras;
      if (perfis_configuracao.length)
        palavras = perfis_configuracao.find(pe => pe.id === perfil_ativado)
          .palavra_classificao;
      if (palavras) this.palavrasConfiguradas = palavras;
    } catch (err) {
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action handlePalavrasConfiguradas = async checked => {
    if (!this.palavrasConfiguradas) {
      await this.loadPalavrasConfiguradas();
    }
    const makeArray = this.palavrasConfiguradas.split(",");
    // Se checkbox for true, adicionar as palavras. Caso contrário, remover.
    if (checked) {
      this.palavrasChave = this.palavrasChave.concat(makeArray);
      this.incluindoPalavras = true;
    } else {
      this.palavrasChave = this.palavrasChave.filter(
        pal => !makeArray.includes(pal)
      );
      this.incluindoPalavras = false;
    }
  };

  @action
  consulta_api(id) {
    this.styleData = this.pesquisaDataType;
    const data = new FormData();
    data.set("codigo_edital", id);
    data.set("id_fornecedor", MaquinaDeEstadosStore.getIdEmpresa());

    Api.post("portal/itens_edital/", { id: id })
      .then(response => {
        this.itens_edital = response.data.itens_edital;
      })
  }

  @action
  pesquisa_palavra = () => {
    if (!this.estado || this.estado.length === 0)
      return AlertaInfo("Selecione os estados que deseja pesquisar.");
    this.isFetching = true;
    this.styleData = this.pesquisaDataType;
    this.showing = false;
    this.fixPaginaAtual();
    this.numero_editais = 0;
    ModalLoadingStore.handleOpenModal();
    Api.post("portal/pesquisa_palavra_alterado/", this.loadDataPalavraChave())
      .then(res => {
        this.editais = res.data.editais;
        this.numero_editais = res.data.total_registros;
        this.editais_total = res.data.total_registros;
      })
      .catch(error => {
        AlertaVermelho("Erro ao carregar os editais");
      })
      .finally(() => {
        this.isFetching = false;
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action
  adicionar_favorito_busca = id => {
    this.isFetching = true;
    const data = new FormData();
    data.set("codigo_edital", id);
    data.set("id_fornecedor", MaquinaDeEstadosStore.getIdEmpresa());
    Api.post("portal/inserir_busca_edital_favorito/", data, {})
      .then(res => {
        if (res.data.resposta === "SIM") {
          AlertaVerde("Adicionado aos favoritos com sucesso!");
          SideStores.consulta_notificacoes();
        } else {
          AlertaVermelho("Erro ao adicionar edital aos favoritos!");
        }
      })
      .catch(error => {
        AlertaVermelho("Erro ao adicionar o edital aos favoritos");
      })
      .finally(() => (this.isFetching = false));
  };

  @action
  favoritar(id) {
    Api.post("portal/marcar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaSuccess("Adicionado aos favoritos com sucesso!");
        // this.handleConsulta()
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível favoritar o edital");
      });
  }

  @action
  desfavoritar(id) {
    Api.post("portal/tirar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaInfo("Removido dos favoritos");
        SideStores.consulta_notificacoes();
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Não foi possível desfavoritar o edital");
      });
  }

  @action
  enviar_proposta = id => {
    Api.post(
      "portal/edital_cadastrado/",
      {
        fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
        id_edital: id
      },
      {}
    )
      .then(response => { })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaVermelho("Erro Ao Adicionar Edital Para os Favoritos!");
      });
  };

  @action
  formatDate(data) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).format(new Date(data));
  }

  @action
  changeAll = tags => {
    this.palavrasChave = tags;
  };

  @action
  changeSingle = tag => {
    this.palavraAtual = tag;
  };

  @action limparVariaveis = () => {
    this.todosEstados = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO"
    ];

    this.tipo_item = ["M", "S"];

    // PORTAIS
    this.todosPortais = [
      { nome: "ComprasNet", id: 5 },
      { nome: "Licitações-e", id: 6 },
      { nome: "Compras RS", id: 12 },
      { nome: "Compras Banrisul", id: 13 },
      { nome: "Portal de Compras Vitória", id: 15 },
      { nome: "Portal de Compras Natal", id: 17 },
      { nome: "Portal FIESC SESI ", id: 18 },
      { nome: "Portal de Compras Amazonas ", id: 129 },
      { nome: "Portal de Compras Paraíba", id: 130 },
      { nome: "Portal ComprasNet Goiânia", id: 131 },
      { nome: "Portal de Compras Empro", id: 137 }
    ];

    this.portal = this.todosPortais.map(p => p.id);

    // DATA
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");

    // EDITAIS
    this.editais = null;
    this.numero_editais = [];
    this.currentEditais = [];
    this.itens_edital = [];
    this.estado = null;
    this.palavra = "";
    this.showing = false;
    this.pagina_atual = 0;
    this.flagConsultar = true;
    this.pesquisaDataType = "abertura";
    this.statusDataType = true;
    this.styleData = "abertura";
    this.editais_total = 0;

    this.filtroMe = false;

    this.isFetching = false;
    this.ordenar = "disputa";
    this.qualData = "inicial";

    this.palavrasConfiguradas = null;
    this.incluindoPalavras = false;

    this.palavrasChave = [];
    this.palavraAtual = "";
  };

  @action trocouDeEmpresa = () => {
    this.palavrasChave = [];
    this.palavrasConfiguradas = null;
    this.incluindoPalavras = false;
  };
}

const buscaDeEditaisStores = new BuscaDeEditaisStores();
export default buscaDeEditaisStores;
