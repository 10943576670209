import React, { useReducer } from 'react';

import Api from '../../../Stores/Conexao/conexao';

const estadoInicial = {
  error: '',
  success: ''
}

const actions = {
  SET_ERROR: 'SET_ERROR',
  SET_SUCCESS: 'SET_SUCCESS'
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ERROR:
      return { ...state, error: action.payload };
    case actions.SET_SUCCESS:
      return { ...state, success: action.payload };
    default:
      return state;
  }
}

const CadastroContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, estadoInicial);

  const onSubmit = async (values) => {
    dispatch({ type: actions.SET_ERROR, payload: '' });

    const data = new FormData();
    data.set('first_name', values.first_name);
    data.set('last_name', values.last_name);
    data.set('username', values.email_cobranca);
    data.set('email_cobranca', values.email_cobranca);
    data.set('password', values.password);
    data.set('repeat_password', values.repeat_password);
    data.set('razao_social', values.razao_social);
    data.set('nome_fantasia', values.nome_fantasia);
    data.set('cnpj', values.cnpj);
    data.set('phone', values.phone);

    try {
      await Api.post('/cadastro_inical/', data);
      dispatch({ type: actions.SET_SUCCESS, payload: 'Cadastro realizado com sucesso' });

      setTimeout(() => {
        window.location.pathname = '/';
      }, 4000);
    } catch (err) {
      dispatch({ type: actions.SET_ERROR, payload: err.response.data.error });
    }
  }

  const value = {
    ...state,
    onSubmit
  }

  return (
    <CadastroContext.Provider value={value}>
      {children}
    </CadastroContext.Provider>
  )
};

const CadastroProvider = Provider;

export { CadastroContext }
export default CadastroProvider;