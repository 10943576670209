import { AlertCircle } from "react-feather";
import { CredentialNotFound, ModalCloseButton } from "../../globalStyles";
import React from "react";

export const AvisoExclamacaoRow = ({ title, height, closeButton }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <AlertCircle
        color={"#3A566E"}
        size={"2rem"}
        // style={{ marginRight: "10px" }}
      />
      <CredentialNotFound>{title}</CredentialNotFound>
      {closeButton && (
        <ModalCloseButton onClick={closeButton}>FECHAR</ModalCloseButton>
      )}
    </div>
  );
};
