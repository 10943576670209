import React, { Component } from "react";
import { Button } from "react-bootstrap";
import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import { inject, observer } from "mobx-react";
import ModalRedefinirSenha from "./ModalRedefinirSenha";
import { Box, Logo, Wrapper, WrapperImag } from "./styleSide";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

@inject("LoginStores")
@observer
class Redefinir_Senha extends Component {
  componentDidMount() {
    MaquinaDeEstadosStore._props = this.props;
  }
  render() {
    return (
      <Wrapper>
        <WrapperImag />
        <Box
          animationPlayState={false}
          animation={true}
          width={"450px"}
          height={"500px"}
        >
          <Logo>
            <img src="/static/Imagens/logo_wavecode.png" alt="logo-wavecode" />
            <h4>Redefinir Senha</h4>
          </Logo>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            variant="outlined"
            type="password"
            required
            fullWidth
            id="novaSenha"
            name="novaSenha"
            label="Nova Senha"
            autoFocus
            onChange={this.props.LoginStores.onChange}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            error={this.props.LoginStores.statusRecuperarSenha}
            margin="normal"
            variant="outlined"
            type="password"
            required
            fullWidth
            id="rSenha"
            name="rSenha"
            label="Repetir a Nova Senha"
            autoFocus
            onChange={this.props.LoginStores.onChange}
          />
          <FormHelperText
            style={{
              marginBottom: "5px",
              color: this.props.LoginStores.statusRecuperarSenha
                ? "red"
                : "green"
            }}
            id="component-helper-text"
          >
            {this.props.LoginStores.statusRecuperarSenha &&
              "Verifique suas senhas!"}
          </FormHelperText>

          <Button
            style={{ fontSize: "13px" }}
            onClick={e => this.props.LoginStores.redefinir(e)}
            disabled={this.props.LoginStores.statusRecuperarSenha}
            size={"sm"}
            bsClass="btn"
            bsStyle="primary"
            active={true}
          >
            ENVIAR
          </Button>
          <br />
          {/*<Link to={"/recuperar_senha"} title={"Recuperar Senha"}>*/}
          {/*    <FontAwesomeIcon icon="arrow-alt-circle-left"/> Não possui token ?*/}
          {/*</Link>*/}
          <ModalRedefinirSenha {...this.props} />
        </Box>
      </Wrapper>
    );
  }
}

export default Redefinir_Senha;

//                 <form id={"redefinir_senha"}>
//                     <FormGroup
//                         controlId="formBasicTextredefinir">
//                         <ControlLabel>Redefina Sua Senha</ControlLabel>
//                         <FormControl name="token" id={"token"} className="form-control "
//                                      placeholder="Digite o Token de Recuperação de Senha..."
//                                      type="text"/><br/>
//                         <FormControl name="senha_nova" id={"senha_nova"} className="form-control "
//                                      placeholder="Nova Senha..." type="password"/><br/>
//                         <FormControl name="rsenha" id={"rsenha"} className="form-control "
//                                      placeholder="Repetir a Nova Senha..." type="password"/><br/>
//                         <FormControl.Feedback/>
//                         <HelpBlock style={{
//                             color: this.props.LoginStores.color,
//                             marginTop: '2%'
//                         }}>{this.props.LoginStores.msg_senha}</HelpBlock>
//                     </FormGroup>
//                     <Link to={"/recuperar_senha"} title={"Recuperar Senha"}>
//                         <FontAwesomeIcon icon="arrow-alt-circle-left"/> Não possui token ?
//                     </Link>
//                     <Button style={{float: 'right'}} onClick={(e) => this.props.LoginStores.redefinir(e, this.props)} title="recuperar senha" className="btn btn-primary">
//                         <FontAwesomeIcon icon="eraser"/> Enviar
//                     </Button>
//                     <ModalRedefinirSenha {...this.props}/>
//                 </form>
