import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'

import * as S from '../Style'

class SelectUsersCustomizado extends Component {
  render() {
    const { id, key, users, defaultValue, onChange, titulo } = this.props
    return (
      <S.CustomInput>
        <span>{titulo}</span>
        <FormControl
          id={id}
          bsClass="select-users"
          componentClass="select"
          multiple
          onChange={onChange}
          key={key}
          defaultValue={defaultValue}
        >
          {users.map(user => {
            return (
              <option key={user.id} value={user.id} id={user.id}>
                {user.first_name} {user.last_name}
              </option>
            )
          })}
        </FormControl>
      </S.CustomInput>
    )
  }
}

export default SelectUsersCustomizado
