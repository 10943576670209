import styled, { keyframes } from "styled-components";

//Animations

const ContratarModulosAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const BodyContainer = styled.div`
  margin: 1.5em 1em;
  padding: 1em;
  border-radius: 10px;
  background-color: #fff;
`;

export const BodyContainerEmEspera = styled(BodyContainer)`
  display: flex;
  margin: 0;
  justify-content: space-between;
  background-color: #ffffff6b;
  padding-left: 2.4em;
  padding-right: 2.4em;
`;

export const BodyContainerContratar = styled(BodyContainerEmEspera)`
  background-color: #fff;
`;

export const BodyContainerSelected = styled(BodyContainerEmEspera)`
  background-color: #5a86ed57;
`;

export const ModulosAContratar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 2.4em;
  padding-right: 2.4em;
`;

export const BodyContainerModulos = styled(BodyContainer)`
  animation-name: ${ContratarModulosAnimation};
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
`;

export const CardModuloLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressLabel = styled.h6`
  text-transform: uppercase;
  text-align: left;
  //padding-top: 1em;
`;

export const AguardandoPagamento = styled(ProgressLabel)`
  font-size: 0.8em;
`;

export const ModulosAtivosLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  display: flex;
  height: 0.7rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #90a2b382;
  border-radius: 0.25rem;
  flex-grow: 1;
  margin: 0.6em;
  margin-right: 1em;
  margin-left: 4em;
  /* border: 1.2px solid #ffffff; */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px #0000001f;
`;

export const CardModulo = styled.div`
  outline: none;
  display: block;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px #0000001f;
  padding: 12px;
  overflow: hidden;
  margin: 15px 0;
  max-width: 30%;
  min-width: 30%;

  h2 {
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0em;
    margin-bottom: 0.35em;
    text-transform: uppercase;
  }

  p {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #0000008a;
  }

  .label__checkbox {
    display: none;
  }

  .label__check {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    background: white;
    vertical-align: middle;
    margin-right: 20px;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.3s ease;

    i.icon {
      opacity: 0.2;
      font-size: calc(3rem + 1vw);
      color: transparent;
      transition: opacity 0.3s 0.1s ease;
      -webkit-text-stroke: 3px rgba(0, 0, 0, 0.5);
    }

    &:hover {
      border: 3px solid rgba(0, 0, 0, 0.2);
    }
  }

  .label__checkbox:checked + .label__text .label__check {
    animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
    .icon {
      opacity: 1;
      transform: scale(0);
      color: white;
      -webkit-text-stroke: 0;
      animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
    }
  }

  .center {
    position: absolute;
    //top: 50%;
    //left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes icon {
    from {
      opacity: 0;
      transform: scale(0.3);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes check {
    0% {
      width: 1.5rem;
      height: 1.5rem;
      border-width: 5px;
    }
    10% {
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.1;
      background: rgba(0, 0, 0, 0.2);
      border-width: 15px;
    }
    12% {
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.4;
      background: rgba(0, 0, 0, 0.1);
      border-width: 0;
    }
    50% {
      width: 2.4rem;
      height: 2.4rem;
      background: #00d478;
      border: 0;
      opacity: 0.6;
    }
    100% {
      width: 2.4rem;
      height: 2.4rem;
      background: #00d478;
      border: 0;
      opacity: 1;
    }
  }
`;

export const CardModuloAtual = styled(CardModulo)`
  background-color: #a2f1c636;
  max-width: 100%;
`;

export const ModulosIconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding: 5px;
  margin-left: -5px;
`;

export const ActionButtonsContainer = styled(ModulosIconsContainer)`
  justify-content: space-evenly;
  flex-wrap: nowrap;
  min-width: 10%;
`;

export const ModulosIcons = styled.div`
  background: ${({ bg }) =>
    bg === "blue" ? "#4F94CD" : bg === "purple" ? "#9c5aed" : "#bdbdbd"};
  width: 30px;
  height: 30px;
  font-size: 0.95em;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s;
  color: #fff;

  :hover {
    transform: scale(1.1);
  }
`;

export const ActionButtons = styled(ModulosIcons)`
  /* box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px #0000001f; */
  height: 35px;
  width: 35px;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;

export const StackedIcons = styled.div`
  position: relative;
`;

export const PaymentHistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TituloPlano = styled.h2`
  font-size: 1.2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0em;
  margin-bottom: 0.35em;
  text-transform: uppercase;

  @media only screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

export const DescricaoPlano = styled.p`
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #0000008a;
  margin-bottom: 0;
`;

export const PrecoPlano = styled(DescricaoPlano)`
  text-align: right;
  font-size: 1.7em !important;
  color: #008000 !important;
`;

export const BtnUpgrade = styled.button`
  background-color: ${props => (props.disabled ? "#989898" : "#5091c7")};
  border: 1px solid #fff0;
  border-radius: 10px;
  color: white;
  width: 80px;
  height: 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  :hover {
    transform: ${props => (props.disabled ? "unset" : "scale(1.05)")};
    background-color: ${props => (props.disabled ? "#989898" : "#4777a5")};
  }

  :focus {
    outline: 0;
  }
`;

export const BtnCancelar = styled(BtnUpgrade)`
  background-color: red;
  width: 60px;
  height: 60px;
  :hover {
    transform: scale(1.05);
    background-color: #b10000;
  }
`;

export const BtnComprar = styled(BtnCancelar)`
  background-color: green;
  :hover {
    transform: scale(1.05);
    background-color: #006500;
  }
`;

export const BtnGerarBoleto = styled(BtnCancelar)`
  background-color: #1f8eb1;
  padding: 0;
  :hover {
    transform: scale(1.05);
    background-color: #225869;
  }
`;

//Table head
export const TabelaHistorico = styled.table`
  font-size: 0.8em;
  background-color: "white";
  width: 100%;
  margin: 20px 0 15px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px #0000001f;
`;

// style = { item.dt_liquidacao ? { background: '#fff2e4d6' } : { background: 'white' } }
