import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import {
  AlertaSuccess,
  FormatDateData,
  FormatDateHora
} from "./Funcoes/FuncoesGerais";

class LicitacaoStores {
  @observable licitacao = [];

  @observable mensagens = [];
  @observable mensagens_atuais = [];
  @observable numero_mensagens_atual = 0;
  @observable pagina_atual = "1";
  @observable numeroPaginaMsg = 5;

  @observable tracks = [];
  @observable tracks_atuais = [];
  @observable numero_tracks_atual = 0;
  @observable pagina_tracks = "1";
  @observable numeroPaginaTracks = 5;

  @observable itens = [];
  @observable itens_atuais = [];
  @observable numero_itens_atual = 0;
  @observable pagina_itens = "1";

  @observable numeroPagina = 10;

  @observable cor = "";
  @observable descricao = "";
  @observable portal = "";
  @observable comprador = [];
  @observable edital = [];
  @observable data_disputa = "";
  @observable hora_disputa = "";
  @observable licitacao_id = "nenhuma";
  @observable edital_selecionado = [];
  @observable isActiveEditar = false;

  @action consulta_api = id => {
    ModalLoadingStore.handleOpenModal();
    this.isActiveEditar = false;
    this.mensagens_atuais = [];
    this.tracks_atuais = [];
    this.itens_atuais = [];
    this.numero_mensagens_atual = 0;
    this.numero_tracks_atual = 0;
    this.numero_itens_atual = 0;

    Api.get("licitacoes/licitacao/" + id + "/", {})
      .then(res => {
        const {
          licitacao,
          edital,
          mensagens,
          configuracao,
          tracks,
          itens
        } = res.data;
        this.licitacao = licitacao;
        this.mensagens = mensagens;
        this.tracks = tracks;
        this.itens = itens;
        this.edital = edital;
        this.edital_selecionado = edital.edital_selecionado;
        this.cor = licitacao.situacao.cor;
        this.descricao = licitacao.situacao.descricao;
        this.portal = edital.nome_portal;
        this.comprador = edital.nome_comprador;
        this.data_disputa = FormatDateData(licitacao.data_disputa);
        this.hora_disputa = FormatDateHora(licitacao.data_disputa);
        this.itens_atuais = itens;
        this.numero_itens_atual = itens.length;
        this.mensagens_atuais = mensagens;
        this.numero_mensagens_atual = mensagens.length;
        this.tracks_atuais = tracks;
        this.numero_tracks_atual = tracks.length;
        this.isActiveEditar = true;
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };

  consulta_api_sem_loading = id => {
    Api.get("licitacoes/licitacao/" + id + "/", {})
      .then(response => {
        this.itens = response.data.itens;
        this.itens_atuais = response.data.itens;
      })
      .finally(() => {});
  };

  @action
  editarItem = (descMinimo, minimo, descMaximo, licitacao_id, id) => {
    Api.post("licitacoes/lance_item/", {
      item: id,
      valor_minimo: minimo,
      desconto_minimo: descMinimo,
      desconto_maximo: descMaximo
    })
      .then(response => {
        this.consulta_api_sem_loading(licitacao_id);
        AlertaSuccess("Item alterado com sucesso!");
      })
      .catch(error => {});
  };

  @action onClick = id => {
    this.props.history.push("/licitacao");
    this.consulta_api(id);
  };
  @action licitacao_vista = id => {
    this.licitacao_id = id;
  };

  @action onChange = evento => {
    // const campo = evento.target.id;
    this.numeroPagina = parseInt(evento.target.value);
  };

  @action onPageChangedItens = data => {
    let itens = this.itens;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    let itensAtual = itens.slice(offset, offset + pageLimit);
    this.itens_atuais = itensAtual;
    this.pagina_itens = currentPage;
  };

  @action onPageChangedTrack = data => {
    let track = this.tracks;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    let trackAtual = track.slice(offset, offset + pageLimit);
    this.tracks_atuais = trackAtual;
    this.pagina_tracks = currentPage;
  };

  @action onPageChanged = data => {
    let mensagens = this.mensagens;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    let mensagensatuais = mensagens.slice(offset, offset + pageLimit);
    this.mensagens_atuais = mensagensatuais;
    this.pagina_atual = currentPage;
  };

  @action limparVariaveis = () => {
    this.licitacao = [];

    this.mensagens = [];
    this.mensagens_atuais = [];
    this.numero_mensagens_atual = 0;
    this.pagina_atual = "1";
    this.numeroPaginaMsg = 5;

    this.tracks = [];
    this.tracks_atuais = [];
    this.numero_tracks_atual = 0;
    this.pagina_tracks = "1";
    this.numeroPaginaTracks = 5;

    this.itens = [];
    this.itens_atuais = [];
    this.numero_itens_atual = 0;
    this.pagina_itens = "1";

    this.numeroPagina = 10;

    this.cor = "";
    this.descricao = "";
    this.portal = "";
    this.comprador = [];
    this.edital = [];
    this.data_disputa = "";
    this.hora_disputa = "";
    this.licitacao_id = "nenhuma";
    this.edital_selecionado = [];
    this.isActiveEditar = false;
  };
}

const licitacaoStores = new LicitacaoStores();
export default licitacaoStores;
