import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { DataAtual } from '../../Stores/Funcoes/FuncoesGerais'
import { ChevronsUp } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MensagemOcorrenciaDia from './MensagemOcorrenciaDia'
import ModalConfirmacao from '../../Components/newModal/modalConfirmacao'
import { SelectCompany } from './Header/SelectCompany'
import { DateAndTime } from './Header/DateAndTime'
import { MessageCount } from './Header/MessageCount'
import { Usability } from './Header/Usability'
import { ExclamationWarning } from '../../Components/Notifications/ExclamationWarning'

import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  BackToTop,
  ReactTooltip
} from '../../globalStyles'
import './animation.css'

@inject(
  'OcorrenciasDoDiaStores',
  'MenuStores',
  'MonitoramentoStore',
  'MaquinaDeEstadosStore'
)
@observer
class OcorrenciaDoDia extends Component {
  constructor(props) {
    super(props)
    this.timer = undefined
    this.state = {
      status: false,
      monitor: false,
      titleSize: 0.7,
      bodySize: 0.9,
      containerHeight: 100,
      isVisible: false,
      total_mensagens: 0,
      scrollPos: null
    }
  }

  componentDidMount() {
    const {
      consultaApi,
      saindoDeFullScreen
    } = this.props.OcorrenciasDoDiaStores
    const { id_fornecedor } = this.props.MaquinaDeEstadosStore
    const { fullScreen } = this.props.MenuStores
    if (!fullScreen && !saindoDeFullScreen) {
      // this.props.OcorrenciasDoDiaStores.idEmpresa = id_fornecedor;
      consultaApi()
    } else if (saindoDeFullScreen)
      this.props.OcorrenciasDoDiaStores.saindoDeFullScreen = false
    else this.props.OcorrenciasDoDiaStores.saindoDeFullScreen = true
    const list = document.getElementById('ocorrencias-dia-list')
    list.addEventListener('scroll', this.listenToScroll)

    // TIMER
    // window.addEventListener("blur", this.backgroundRequestTimer, false);
    // window.addEventListener("focus", this.clearTimer, false);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    clearInterval(this.props.OcorrenciasDoDiaStores.intervaloSonoro)
    this.props.OcorrenciasDoDiaStores.pagina_atual = 1
    const list = document.getElementById('ocorrencias-dia-list')
    list.removeEventListener('scroll', this.listenToScroll)

    // TIMER
    window.removeEventListener('blur', this.backgroundRequestTimer)
    window.removeEventListener('focus', this.clearTimer)
  }

  backgroundRequestTimer = () => {
    const { consultaApi } = this.props.OcorrenciasDoDiaStores
    this.timer = setInterval(() => {
      consultaApi(true)
    }, 60 * 1000)
  }

  clearTimer = () => {
    // this.props.OcorrenciasDoDiaStores.consultaApi(true);
    clearInterval(this.timer)
  }

  listenToScroll = () => {
    const {
      handleInfinityScroll,
      isFetching,
      mensagens
    } = this.props.OcorrenciasDoDiaStores
    const list = document.getElementById('ocorrencias-dia-list')
    const winScroll = list.scrollTop
    const height = list.scrollHeight - list.clientHeight
    const scrolled = winScroll - height
    this.setState({ scrollPos: scrolled })
    if (scrolled === 0 && !isFetching && mensagens.length >= 10) {
      handleInfinityScroll()
    }
  }

  sizeMsg = size => {
    return `${this.state.fontSize + size}px`
  }

  handleTela = e => {
    // // window.alert(e.target)
    // var docElm = document.documentElement;
    // if (docElm.requestFullscreen) {
    //   docElm.requestFullscreen();
    // }
    // else if (docElm.mozRequestFullScreen) {
    //   docElm.mozRequestFullScreen();
    // }
    // else if (docElm.webkitRequestFullScreen) {
    //   docElm.webkitRequestFullScreen();
    // }
    this.props.MenuStores.handleTela()
  }

  handleSizeIncrement = () => {
    const { bodySize, titleSize, containerHeight } = this.state
    if (containerHeight < 150)
      this.setState({
        bodySize: bodySize + 0.1,
        titleSize: titleSize + 0.1,
        containerHeight: containerHeight + 3
      })
  }

  handleSizeDecrement = () => {
    const { bodySize, titleSize, containerHeight } = this.state
    if (containerHeight > 80)
      this.setState({
        bodySize: bodySize - 0.1,
        titleSize: titleSize - 0.1,
        containerHeight: containerHeight - 3
      })
  }

  handleAnimacao = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }

  render() {
    const {
      handleSom,
      som,
      mensagens,
      total_mensagens,
      verificarTodas,
      total_mensagens_nao_verificadas,
      nome_empresa,
      isFetching,
      pagina_atual
    } = this.props.OcorrenciasDoDiaStores
    const { empresas, fullScreen } = this.props.MenuStores
    const { bodySize, titleSize } = this.state
    const todasVerificadas = total_mensagens_nao_verificadas === 0

    return (
      <>
        <audio id="avisos">
          <source src="../static/audio/avisos.mp3" type="audio/mpeg"></source>
        </audio>

        <ComponentHeader height="8vh" style={{ alignItems: 'center' }}>
          <div
            className="d-flex justify-content-between"
            style={{ width: window.innerWidth < 1400 ? '60%' : '50%' }}
          >
            <SelectCompany empresas={empresas} />
            <DateAndTime date={DataAtual} />
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ width: '300px', alignItems: 'center' }}
          >
            <MessageCount
              total={total_mensagens}
              notVerified={total_mensagens_nao_verificadas}
            />

            <ModalConfirmacao
              title="VERIFICAR TODAS AS MENSAGENS"
              message="Tem certeza que deseja verificar todas as mensagens?"
              confirmAction={verificarTodas}
              disabled={todasVerificadas}
              button={
                <FontAwesomeIcon
                  className={`icon-scale ${
                    todasVerificadas ? '' : 'blink-envelope'
                  }`}
                  icon={todasVerificadas ? 'envelope-open-text' : 'envelope'}
                  style={{
                    fontSize: '25px',
                    color: todasVerificadas ? '#a5ada5' : '#0dd00d'
                  }}
                  data-tip={
                    todasVerificadas ? 'JÁ VERIFICADAS' : 'VERIFICAR TODAS'
                  }
                  data-type={todasVerificadas ? 'light' : 'success'}
                  data-place="right"
                  data-effect="solid"
                />
              }
            />
          </div>
          <Usability
            fullScreen={fullScreen}
            handleSom={handleSom}
            som={som}
            handleFullScreen={this.handleTela}
            sizeControl={{
              inc: this.handleSizeIncrement,
              dec: this.handleSizeDecrement
            }}
          />
        </ComponentHeader>
        <ComponentHeaderOverflow height="11vh" widthFull="100%" />
        <ContentBody
          bRadius
          id="ocorrencias-dia-list"
          style={{
            height: fullScreen ? '90vh' : '82.5vh',
            overflow: 'auto',
            position: 'relative',
            marginBottom: 0
          }}
        >
          {mensagens.length ? (
            mensagens.map(msg => {
              return (
                <MensagemOcorrenciaDia
                  msg={msg}
                  titleSize={titleSize}
                  bodySize={bodySize}
                />
              )
            })
          ) : isFetching ? (
            <div
              style={{
                display: 'flex',
                height: '82.5vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <ExclamationWarning
              title={`Não foram encontradas mensagens ${
                nome_empresa ? 'para ' + nome_empresa : ''
              }`}
              height={fullScreen ? '86.7vh' : '78.2vh'}
            />
          )}
          {isFetching && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </ContentBody>

        {pagina_atual > 2 &&
          document.getElementById('ocorrencias-dia-list').scrollTop > 1500 && (
            <BackToTop
              onClick={() =>
                document
                  .getElementById('ocorrencias-dia-list')
                  .scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              }
              className="icon-scale"
            >
              <ChevronsUp />
            </BackToTop>
          )}
      </>
    )
  }
}

export default OcorrenciaDoDia
