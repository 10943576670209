import styled from "styled-components";

export const ContainerFunil = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const CardFunil = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  min-width: 250px;
  background: white;
  min-height: 75.5vh;
  margin: 10px;
  flex: 1;
  flex-wrap: wrap;
  border-radius: 10px;
  //max-width: 400px;
`;

export const CardHeaderFunil = styled.div`
  background: ${props => props.background};
  height: 100px;
  border-radius: 5px 5px 0px 0px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

export const CardAnim = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  animation: ${props =>
    props.status === 1
      ? "2s slideOutLeft"
      : props.status === 3 && "1s slide-in-left"};
  @keyframes slideOutLeft {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

export const CardAnim1 = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-evenly;

  animation: ${props =>
    props.status === 1 ? "1s slide-in-right" : "2s slide-out-right"};
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
  }
`;

export const Portais = styled.div`
  display: flex;
  width: 260px;
  justify-content: space-between;

  .cont-port {
    display: flex;
    align-items: center;
  }
`;

export const PortalLogo = styled.img`
  border-radius: 50%;
  box-shadow: 0px 1px 8px 1px #00000094;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  filter: ${props => (props.greyscale ? "grayscale(100%)" : "none")};
  -webkit-filter: ${props => (props.greyscale ? "grayscale(100%)" : "none")};
`;
