import { observable, action } from "mobx";
import { getPortalName } from "../Services/portais";
import { playChain } from "../som/AlertasSistema";

class AlertaVerbalStore {
  @observable sua_empresa_foi_citada = null;
  @observable encontramos_mensagens_importantes = null;
  @observable encontramos_novas_mensagens = null;
  @observable licitacao_atualizada_nova_situacao = null;
  @observable comprasnet = null;
  @observable licitacoes_e = null;
  @observable atencao = null;
  @observable urgente = null;
  @observable cancelada = null;
  @observable licitacao_com_situacao_atualizada = null;

  @action handlePlayChain = (tipostring, portal, novaSituacao) => {
    switch (tipostring) {
      case "empresa":
        playChain(["urgente", "sua-empresa-foi-citada", portal]);
        break;
      case "situacao":
        playChain([
          "atencao",
          "licitacao-atualizada-nova-situacao",
          novaSituacao,
          portal
        ]);
        break;
      case "especifica":
        playChain(["urgente", "encontramos-mensagens-importantes", portal]);
        break;
      case "mensagem":
        playChain(["atencao", "encontramos-novas-mensagens", portal]);
        break;
      case "importante":
        playChain(["urgente", "encontramos-mensagens-importantes", portal]);
        break;
    }
  };
}

export default new AlertaVerbalStore();
