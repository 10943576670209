import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'

import * as S from '../Style'

class SelectEmpresasCustomizado extends Component {
  render() {
    const {
      id,
      key,
      empresas,
      defaultValue,
      onChange,
      titulo,
      disabled
    } = this.props
    return (
      <S.CustomInput>
        <span>{titulo}</span>
        <FormControl
          id={id}
          bsClass="select-empresas"
          componentClass="select"
          multiple
          onChange={onChange}
          key={key}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {empresas.map(empresa => {
            return (
              <option key={empresa.id} id={empresa.id} value={empresa.id}>
                {empresa.nome_fantasia}
              </option>
            )
          })}
        </FormControl>
      </S.CustomInput>
    )
  }
}

export default SelectEmpresasCustomizado
