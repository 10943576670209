import React from 'react';
import { Layers, Search } from "react-feather";
import { ContainerHeader, WrapperSearch } from "./styles";
import { WrapperLicitacao } from "../detalhes/styles";
import { FormControl } from "react-bootstrap";

const HeaderItens = ({ licitacao, setPalavra, palavra }) => {
  return (
    <ContainerHeader>
      <WrapperSearch />

      <WrapperLicitacao>
        <div className="icon">
          <Layers color="#fff" />
        </div>
        <div className="title">
          LICITAÇÃO <b>{licitacao}</b>
        </div>
      </WrapperLicitacao>

      <WrapperSearch>
        <FormControl
          type="text"
          style={{ maxWidth: "250px" }}
          placeholder="Pesquisar palavra específica"
          className="mr-sm-2"
          value={palavra}
          onChange={(e) => setPalavra(e.target.value)}
        />
        <div><Search /></div>
      </WrapperSearch>

    </ContainerHeader>
  );
};

export default HeaderItens;
