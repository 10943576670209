import React from 'react'
import ReactDOM from 'react-dom'

import { inject, observer } from 'mobx-react'
import { TextAreaInput } from '../../../../UsefulComponents/Inputs'
import { ThumbsDown } from 'react-feather'

import * as S from './Style'

const ModalReprovar = inject('DetalhesEditalStore')(
  observer(({ DetalhesEditalStore }) => {
    const {
      modalReprovar,
      toggleModalReprovar,
      isFetching,
      mudarStatus,
      onChangeMessage,
      msg_reprovar
    } = DetalhesEditalStore
    return (
      <ModalFailEdict
        modalFail={modalReprovar}
        toggleModalFail={toggleModalReprovar}
        changeStatus={mudarStatus}
        isFetching={isFetching}
        msg_reprovar={msg_reprovar}
        onChangeMessage={onChangeMessage}
      />
    )
  })
)

const ModalFailEdict = ({
  modalFail,
  onChangeMessage,
  toggleModalFail,
  changeStatus,
  isFetching,
  msg_reprovar
}) => {
  const changeStatusEdict = () => {
    changeStatus('reprovado')
    toggleModalFail()
  }

  return ReactDOM.createPortal(
    <>
      {modalFail && (
        <S.Overlay>
          <S.Container isFetching={isFetching} color={'#ec4242'}>
            <div className="header">
              <div className="icon">
                <ThumbsDown />
              </div>
              <div className="title">REPROVAR</div>
            </div>
            <div className="body">
              <div className="title-message">MENSAGEM</div>
              <TextAreaInput
                rows={10}
                onChange={e => onChangeMessage(e.target.value)}
                value={msg_reprovar}
              />
            </div>
            <div className="footer">
              <div className="confirmar" onClick={changeStatusEdict}>
                CONFIRMAR
              </div>
              <div className="fechar" onClick={toggleModalFail}>
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalReprovar
