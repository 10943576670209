import React, { useState } from 'react'
import { MoreVertical } from 'react-feather'

import ModalSelecaoModalidades from './modalLateral/ModalSelecaoModalidade'
import MaquinaDeEstadosStore from '../../Stores/MaquinaDeEstadosStore'
import { useFiltroEdital } from '../Prospection/Filter/context/FiltroEditaisContext'

import { CardMsg } from '../../globalStyles'

const FiltroModalidade = () => {
  // const { onChangeModalidade, state } = useFiltroEdital()
  // const [modalidade] = useState(MaquinaDeEstadosStore.modalidade)

  return (
    <CardMsg
      height="100%"
      flexDirection="row"
      margin="0px 0px 10px 0px"
      minHeight="150px"
      maxHeight="150px"
      justifyContent="space-between"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '50%',
          marginRight: '10px'
        }}
      >
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <MoreVertical />
          &nbsp;&nbsp;
        </div>
        <div style={{ padding: '0px 10px 0px 10px' }}>
          SELEÇÃO DE MODALIDADE
          <div style={{ fontSize: '10px' }}>
            Selecionar as modalidades que deseja buscar.
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ModalSelecaoModalidades />
      </div>
    </CardMsg>
  )
}

export default FiltroModalidade
