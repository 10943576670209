import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { FileText } from 'react-feather'
import { useHistory } from 'react-router-dom'

import Spinner from '../../../UsefulComponents/Spinner'
import SubHeader from '../SubHeader/SubHeader'
import SectionTitle from '../SubHeader/SectionTitle'
import PerfilSelection from '../SubHeader/PerfilSelection'
import StatusModal from '../StatusNotice'
import BotaoFiltrar from '../Filter/botaoFiltro'
import TabelaBoletim from './tableBulletin'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'
import FilterDailyBulletin from '../Filter/filterDailyBulletin'

import './Style/style.css'
import * as Style from '../Style'

const BoletimDiario = inject(
  'BoletimStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(({ BoletimStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
    // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
    const { toggleModal } = DetalhesEditalStore
    const {
      isFetching,
      fetchEditaisNovo,
      editais,
      total_registros,
      total_paginas,
      pagination,
      crescente,
      profiles,
      totalEditaisProcurados,
      idProfile,
      inputChange,
      dataIsFiltered,
      fetchEdital,
      setDataIsFiltered
    } = BoletimStore
    const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
    return (
      <BoletimDiarioComStore
        idProfile={idProfile}
        toggleModal={toggleModal}
        data={{ isFetching, fetchEditaisNovo }}
        idEmpresa={id_fornecedor}
        editais={editais}
        total_registros={total_registros}
        total_paginas={total_paginas}
        pagination={pagination}
        crescente={crescente}
        perfis={profiles}
        getStatus={getStatus}
        totalEditaisProcurados={totalEditaisProcurados}
        inputChange={inputChange}
        dataIsFiltered={dataIsFiltered}
        fetchEdital={fetchEdital}
        setDataIsFiltered={setDataIsFiltered}
      />
    )
  })
)

const BoletimDiarioComStore = ({
  data,
  idProfile,
  idEmpresa,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  perfis,
  getStatus,
  dataIsFiltered,
  fetchEdital,
  setDataIsFiltered
}) => {
  const { isFetching, fetchEditaisNovo } = data
  const { state, setPerfilData, clearData } = useFiltroEdital()
  const { page, changePage } = pagination

  const { push } = useHistory()

  useEffect(() => {
    getStatus()
    changePage(1)
  }, [])

  useEffect(() => {
    setDataIsFiltered(false)
    if (idEmpresa) fetchEditaisNovo(idProfile, state, false)
  }, [idEmpresa])

  const handlePagina = (event, value) => {
    changePage(value)
    dataIsFiltered ? fetchEdital(state) : fetchEditaisNovo(idProfile, state)
  }

  const handleRedirect = () => {
    const { id } = perfis.find(p => p.activated)
    setPerfilData(id)
    push(`/prospeccao/pesquisa/${id}`)
  }

  return (
    <Style.Container>
      <SubHeader overflow>
        <SectionTitle title="BOLETIM DIÁRIO" icon={<FileText />} />
        <PerfilSelection />
        <BotaoFiltrar noActive />
      </SubHeader>
      <Style.Body>
        <Style.ContainerTable filter={editais.length && !state.filtro}>
          <FilterDailyBulletin status={'daily bulletin'} isFetching={isFetching} />
          {isFetching ? (
            <Spinner />
          ) : (
            !state.filtro && (
              <TabelaBoletim
                toggleModal={toggleModal}
                crescente={crescente}
                editais={editais}
                handlePagina={handlePagina}
                page={page}
                total_paginas={total_paginas}
              />
            )
          )}
        </Style.ContainerTable>
      </Style.Body>
      <StatusModal />
    </Style.Container>
  )
}

export default BoletimDiario
