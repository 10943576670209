import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  position: absolute;
  width: 100vw;
  padding: 50px;
  right: 0;
  background: #f3f3f3;
  z-index: 1000000;
  animation: 500ms fadeIn ease-in forwards;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
`

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 20px;
  background: #fff;
  height: 80vh;
  box-shadow: 1px 1px 8px 0px #00000054;

  .abas {
    position: absolute;
    display: flex;
    left: 20px;
    top: -25px;
    width: 200px;
    height: 25px;
  }
`

export const Tab = styled.div`
  display: flex;
  color: #fff;
  font-size: 13px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #418baf;
  font-weight: 500;
  width: 150px;
  box-shadow: -1px 0px 2px 0px #00000070;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.active ? '1' : '0.6')};
  margin-top: ${props => !props.active && '3px'};
  margin-right: 5px;
  cursor: pointer;
  user-select: none;

  :hover {
    opacity: ${props => (props.active ? '1' : '0.8')};
  }
`

export const ContainerNav = styled.div`
  display: flex;
  align-self: flex-end;
  width: 250px;
  margin: 15px;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .counter {
    color: #afaca9;
    font-weight: 500;
    font-size: 14px;
  }

  .actions {
    display: flex;
    .button {
      padding: 5px;
      border-radius: 50%;
      margin-right: 15px;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 1px 1px 4px #00000063;
    }
    .button:last-child {
      margin-right: 0;
    }
    .close {
      background: red;
      color: #fff;
      box-shadow: 1px 1px 4px 0px #000000de;
      font-size: 16px;
    }
  }
`

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  font-size: 80%;
  overflow: hidden;

  .actions {
    display: flex;

    .info {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        margin-bottom: 10px;

        b {
          margin-right: 10px;
        }
      }
    }

    .data {
      margin-left: auto;
      display: flex;
      width: 270px;
      justify-content: space-between;
      padding: 20px;
      border-left: 1px solid #cacaca;
    }
  }

  .descricao {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    b {
      margin-bottom: 5px;
    }
  }
`

export const ContainerFooter = styled.div`
  background: #37516b;
  position: relative;
  margin-top: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;

  .action-buttons {
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 1px 1px 4px #00000063;
    }
    .button:last-child {
      margin-right: 0;
    }
    .close {
      background: red;
      color: #fff;
      font-size: 16px;
    }
  }
`
