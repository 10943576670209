import styled from "styled-components";

export const WrapContainer = styled.div`
  /* border-radius: 20px; */
  font-family: "Helvetica Neue";
  margin: 1.2em;
  /* background-color: #fff;
    border-radius: 20px 20px 0 0; */

  .cont-header {
    color: #000;
    font-size: 1.2rem;
    background-color: #fff;
    padding: 0.8rem 1.6rem;
    border-radius: 20px 20px 0 0;
    display: flex;

    .icon {
      margin-right: 1em;
      margin-top: 0.25em;
    }
  }
  .cont-body.atual {
    background-color: #4682b4;
  }
  .cont-body.boleto {
    background-color: #834666;
  }
  .cont-body.selected {
    background-color: #585456;
  }
  .cont-body.history {
    background-color: #fff;
    color: #000;
  }
  .cont-body.notification {
    background-color: #b1b1b1;
  }
  .cont-body {
    font-size: 15px;
    color: #fff;
    border-radius: 0 0 20px 20px;
    padding: 1.7rem 1.2rem;
    padding-bottom: 1.2rem;

    .title {
      font-size: 22px;
      font-weight: 500;
      font-family: "Helvetica Neue";
    }

    .middle-content {
      display: flex;
      justify-content: space-between;

      .description {
        font-weight: 300;
        max-width: 65em;
      }

      .icons-container {
        display: flex;
        align-items: center;
        margin-right: 1em;
      }

      .preco-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .price-tag {
          font-weight: 500;
          font-size: 1.2em;
        }
      }
    }

    .history-table {
      font-size: 1em;
      background-color: "white";
      width: 100%;

      thead {
        background: #eceeef;
        outline: 1px solid #dddddd;
        color: #55595c;
      }

      th,
      td {
        padding: 0.5em 1.5em;
      }

      td {
        color: #373a3c;
      }
    }

    .date {
      border-radius: 10px;
      width: fit-content;
      padding: 0.12em 0.5em;
      font-size: 0.9em;
      font-weight: 300;
      display: inline;
      margin-right: 2em;
    }

    .date.vencimento {
      background-color: #be9b3d;
    }

    .date.restantes {
      background-color: #ba3835;
    }
  }

  .cont-body.table-historico {
    padding: 0;
  }

  .cont-footer {
    text-align: left;
  }
`;

export const ModulosDisponiveis = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Helvetica Neue";

  .modulo-card {
    background: ${props => (props.active ? "#DBEADB" : "#FFF")};
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 2rem;
    padding-bottom: 1rem;
    margin: 0.6rem 1.2rem;

    .card-heading {
      font-weight: 300;
    }

    .card-title {
      display: flex;
      font-size: 1.25rem;
      justify-content: space-between;
      font-weight: 500;
      flex-wrap: wrap;
    }

    .card-description {
      font-size: 15px;
    }

    .card-price {
      color: #178317;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: right;
    }

    .card-subtime {
      font-size: 15px;
      font-weight: 300;
      text-align: right;
    }
    .label__checkbox {
      display: none;
    }

    .label__check {
      display: inline-block;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.1);
      background: white;
      vertical-align: middle;
      margin-right: 20px;
      width: 2.4em;
      height: 2.4em;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.3s ease;

      i.icon {
        opacity: 0.2;
        font-size: calc(3rem + 1vw);
        color: transparent;
        transition: opacity 0.3s 0.1s ease;
        -webkit-text-stroke: 3px rgba(0, 0, 0, 0.5);
      }

      &:hover {
        border: 3px solid rgba(0, 0, 0, 0.2);
      }
    }

    .label__checkbox:checked + .label__text .label__check {
      animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
      .icon {
        opacity: 1;
        transform: scale(0);
        color: white;
        -webkit-text-stroke: 0;
        animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
      }
    }

    .label__checkbox:disabled + .label__text .label__check {
      animation: checkDisabled 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22)
        forwards;
      .icon {
        opacity: 1;
        transform: scale(0);
        color: white;
        -webkit-text-stroke: 0;
        animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
      }
    }

    .center {
      position: absolute;
      //top: 50%;
      //left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -1em;
    }

    @keyframes icon {
      from {
        opacity: 0;
        transform: scale(0.3);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes check {
      0% {
        width: 1.5em;
        height: 1.5em;
        border-width: 5px;
      }
      10% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.1;
        background: rgba(0, 0, 0, 0.2);
        border-width: 15px;
      }
      12% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.4;
        background: rgba(0, 0, 0, 0.1);
        border-width: 0;
      }
      50% {
        width: 2.4em;
        height: 2.4em;
        background: #00d478;
        border: 0;
        opacity: 0.6;
      }
      100% {
        width: 2.4em;
        height: 2.4em;
        background: #00d478;
        border: 0;
        opacity: 1;
      }
    }
    @keyframes checkDisabled {
      0% {
        width: 1.5em;
        height: 1.5em;
        border-width: 5px;
      }
      10% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.1;
        background: rgba(0, 0, 0, 0.2);
        border-width: 15px;
      }
      12% {
        width: 1.5em;
        height: 1.5em;
        opacity: 0.4;
        background: rgba(0, 0, 0, 0.1);
        border-width: 0;
      }
      50% {
        width: 2.4em;
        height: 2.4em;
        background: #5896ca;
        border: 0;
        opacity: 0.6;
      }
      100% {
        width: 2.4em;
        height: 2.4em;
        background: #5896ca;
        border: 0;
        opacity: 1;
      }
    }
  }
`;

export const ActionButton = styled.button`
  width: ${props => (props.size ? props.size : "78px")};
  height: ${props => (props.size ? props.size : "78px")};
  background: ${props => props.bgColor || "#fff"};
  text-align: center;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${props => props.borderColor || "#000"};
  color: ${props => props.color || "#000"};
  box-shadow: ${props =>
    props.disabled
      ? "inset 1px 3px 6px 0px #00000057, 0px 1px 1px 0px #0000002b, 0px 2px 1px -1px #0000005c"
      : "1px 3px 13px 0px #000000d1, 0px 1px 1px 0px #000000e0, 0px 2px 1px -1px #0000005c"};

  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  margin: 0 1em;
  font-weight: 500;
  transition: 400ms;
  font-size: 0.81rem;

  :focus {
    outline: 0;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  height: 0.7rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #90a2b382;
  border-radius: 0.25rem;
  flex-grow: 1;
  margin: 0.6em;
  margin-right: 1em;
  margin-left: 4em;
  /* border: 1.2px solid #ffffff; */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px #0000001f;
`;

export const CardCartoes = styled.div`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  border-radius: 10px;
  border: 1.2px solid #cccc;
  margin-right: 32px;
  cursor: pointer;

  label {
    position: relative;
    left: 8px;
    top: -20px;
    background: white;
    padding: 5px;
    color: #999999;
    font-size: 12px;
  }

  div {
    position: relative;
    left: -5px;
    top: -28px;
  }

  img {
    margin: 2px;
    height: 25px;
  }
`;
