import React, { useContext, useEffect, useReducer, useState } from 'react';
import { API } from '../../../ListaDeLicitacoes/api';
import ApiStore from '../../../../../../../Stores/Conexao/conexao'
import useAxios from 'axios-hooks';
import { PropostaEspecificaContext } from '../../context';
// import { ToastSuccess } from '../../../../../../Stores/Funcoes/FuncoesGerais';
import maquinadeestadosstore from '../../../../../../../Stores/MaquinaDeEstadosStore';
// import { PropostaEspecificaContext } from '../context';

// const { nome, rg, cpf, dadosBancarios, observacoes, validade, handleAssinatura, loadingAssinatura, onChange, assinatura, noTitle } = props;

const initialState = {
  nome: '',
  rg: '',
  cpf: '',
  dadosBancarios: '',
  observacoes: '',
  validade: '',
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  inscEstadual: '',
  inscMunicipal: '',
  telefone: '',
  celular: '',
  rua: '',
  numero: '',
  bairro: '',
  cidade: '',
  uf: '',
  complemento: '',
  cep: '',
  email: '',
  site: '',
  cabecalhoDesabilitado: '',
  assinatura: '',
  logo: '',
  id: null,
  // pdf: null,
  pdfFile: null
};

const actions = {
  ON_CHANGE: 'ON_CHANGE',
  SET_DATA: 'SET_DATA',
  SET_CABECALHO: 'SET_CABECALHO',
  SET_LOGO: 'SET_LOGO',
  SET_ASSINATURA: 'SET_ASSINATURA',
  CLEAN_DATA: 'CLEAN_DATA',
  // SET_PDF: 'SET_PDF',
  SET_PDF_FILE: 'SET_PDF_FILE'
}

function reducer(state, action) {
  switch (action.type) {
    case actions.ON_CHANGE:
      return { ...state, [action.payload.name]: action.payload.value };
    case actions.SET_DATA:
      return { ...state, ...action.payload };
    case actions.SET_CABECALHO:
      return { ...state, cabecalhoDesabilitado: action.payload };
    case actions.SET_LOGO:
      return { ...state, logo: action.payload };
    case actions.SET_ASSINATURA:
      return { ...state, assinatura: action.payload };
    // case actions.SET_PDF:
    //   return { ...state, pdf: action.payload };
    case actions.SET_PDF_FILE:
      return { ...state, pdfFile: action.payload };
    case actions.CLEAN_DATA:
      return initialState;
    default:
      return state;
  }
}

const DadosPdfContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendData = {
    nome_representante: state.nome,
    rg: state.rg,
    cpf: state.cpf,
    dados_bancario: state.dadosBancarios,
    validade_proposta: state.validade,
    obs: state.observacoes,
    assinatura: state.assinatura,
    id: state.id,
    fornecedor: {
      cnpj: state.cnpj,
      nome_fantasia: state.nomeFantasia, // PRECISA MANDAR ISSO?
      razao_social: state.razaoSocial,
      phone: state.telefone,
      insc_municipal: state.inscMunicipal,
      insc_estadual: state.inscEstadual,
      celular: state.celular,
      website: state.site,
      email: state.email,
      logo: state.logo,
      endereco: {
        estado: state.uf,  // PRECISA MANDAR ISSO?
        uf: state.uf,
        cidade: state.cidade,
        rua: state.rua,
        bairro: state.bairro,
        numero: state.numero,
        complemento: state.complemento,
        cep: state.cep
      }
    }
  };

  const [data, setData] = useState(null);
  const getData = async () => {
    const data = await ApiStore.get(
      `portal/proposta_conf/?fornecedor=${maquinadeestadosstore.getIdEmpresa()}`
    )
    setData(data.data)
  }

  const [{ data: data1, loading: loading1, error: error1 }, saveData] = useAxios(
    {
      method: 'POST',
      url: API.salvarDadosPdf(),
      data: sendData,
    },
    {
      manual: true,
      useCache: false
    }
  )


  useEffect(() => {
    if (data) {
      const { assinatura, cpf, dados_bancario, fornecedor, nome_representante, obs, rg, validade_proposta, id } = data;
      const { celular, cnpj, email, insc_estadual, insc_municipal, logo, nome_fantasia, phone, razao_social, website, endereco } = fornecedor;
      const { bairro, cep, cidade, complemento, numero, rua, uf } = endereco;
      const payload = {
        assinatura,
        cpf,
        dadosBancarios: dados_bancario,
        nome: nome_representante,
        observacoes: obs,
        rg,
        validade: validade_proposta,
        celular,
        cnpj,
        email,
        site: website,
        inscEstadual: insc_estadual,
        inscMunicipal: insc_municipal,
        logo,
        razaoSocial: razao_social,
        telefone: phone,
        nomeFantasia: nome_fantasia,
        bairro,
        cep,
        cidade,
        complemento,
        numero,
        rua,
        uf,
        id
      };
      dispatch({ type: actions.SET_DATA, payload })
    }

  }, [data])

  const setCabecalho = (checked) => {
    localStorage.setItem('proposta-cabecalho-desabilitado', checked);
    dispatch({ type: actions.SET_CABECALHO, payload: checked });
  }
  const [pdf, setPdf] = useState(null);

  const value = {
    ...state,
    cleanData: () => dispatch({ type: actions.CLEAN_DATA }),
    setCabecalho,
    getData,
    saveData: () => { saveData() },
    setLogo: (logo) => dispatch({ type: actions.SET_LOGO, payload: logo }),
    setAssinatura: (assinatura) => dispatch({ type: actions.SET_ASSINATURA, payload: assinatura }),
    onChange: ({ target: { name, value } }) => dispatch({ type: actions.ON_CHANGE, payload: { name, value } }),
    // setPdf: (pdf) => dispatch({ type: actions.SET_PDF, payload: pdf }),
    setPdf,
    pdf,
    setPdfFile: (file) => dispatch({ type: actions.SET_PDF_FILE, payload: file }),
    // setPdfStatus: (status) => dispatch({ type: actions.SET_PDF, payload: { ...state.pdf, ...status } })
    setPdfStatus: (status) => setPdf(prev => ({ ...prev, ...status }))
  }



  return (
    <DadosPdfContext.Provider value={value}>
      {children}
    </DadosPdfContext.Provider>
  )
};

const DadosPdfProvider = Provider;

export { DadosPdfContext }
export default DadosPdfProvider;