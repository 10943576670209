import styled from 'styled-components';

export const Overflow = styled.div`
  position: absolute;
  z-index: 1113123213;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
`;

export const Container = styled.div`
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
  }

  .close-cropping {
    bottom: 17px;
    position: absolute;
    left: 20px;
    color: #a0a0a0;
    width: 40px;
    height: 40px;
  }

  .done-cropping {
    bottom: 17px;
    position: absolute;
    right: 20px;
    color: #3c9a3c;
    width: 40px;
    height: 40px;
  }
`;