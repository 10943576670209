import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  margin-top: 4px;
  font-size: 12px;


 .react-grid-checkbox{
    background: #fff;
    border-radius: 5px;
    border: 2px solid #a20b0b;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    text-align: center;
 }

  .react-grid-Container{
    height: 100%;
    width: 100%;
    left: 10px;
    overflow: hidden;
  }
  .react-grid-Main{
    border: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

  }
  .react-grid-Grid{
    margin: 0;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    /* left: 6px; */
    overflow: auto;
  }

  .react-grid-Cell__value{
  text-align: center;
  }
  .react-grid-HeaderCell {
    font-size: 100%;
    //border-left: 1px solid #949494 !important;
  }

  .react-grid-Viewport{
    top: 40px !important;
  }
  .react-grid-HeaderCell{
    border-right: 1px solid #949494 !important;
    text-align: center;
    
    
  }
  .react-grid-HeaderRow{
    border-bottom: 1px solid #3a566e;
    overflow: hidden !important;

    width: 100% !important;
  }
  .react-grid-Canvas {
    overflow: auto !important;

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(linear,
                        left bottom,
                        left top,
                        color-stop(0.44, rgb(128, 187, 224)),
                        color-stop(0.72, rgb(80, 132, 166)),
                        color-stop(0.86, rgb(95, 163, 204)   ));
    }
  }
`;

export const ContainerSubMenu = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  background:#d6d6d6;
  padding-left: 15px;
  padding-right: 15px;
  height: 75px;
  margin-bottom: 10px;

`;

export const WrapperEx = styled.div`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 300px;
  border-radius: 5px;
  background: white;
  height: 55px;
  margin-right: 10px;

 .barra{
    width: 1px;
    height: 100%;
    border-right: 1px solid #c3c3c3;
 }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 98%;
  border-radius: 5px;
  background: white;
  height: 55px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-right: 15px;
  border-right: 1px solid #c3c3c3;
`;

export const WrapperCarregarItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  width: 170px;
  cursor: pointer;
  transition: 200ms;


  svg{
    font-size: 30px;
  width: 20px;
  height: 20px;
  }
  :hover{
  transform: scale(1.1);
  }
`;


// Modal de confirmação dos itens da planilha
export const Overlay = styled.div`
  z-index: 3123213;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000000f5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerModal = styled.div`
  width: 90vw;
  max-height: 95vh;
  min-height: 95vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #464646;
  animation: 1s fadeIn ease-in-out;
  display: flex;
  flex-direction: column;

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: #296188;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
    position: relative;
    user-select: none;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;

      span {
        font-weight: 600;
        font-size: 90%;
      }
      svg {
        margin-right: 10px;
      }
    }
    button.close-button {
      position: absolute;
      right: -5px;
      top: -5px;
      background: #bd1c1c;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 1px 1px 7px 1px #101010;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    .section-header {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      
      .container-title {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 600;
          font-size: 90%;
        }
        .subtitle {
          font-size: 75%;
          color: #8c8c8c;
        }
      }

      .input-pesquisar {
        display: flex;
        border-radius: 7px;
        border: 2px solid #29618885;
        min-width: 150px;
        align-items: center;
        align-self: center;
        height: fit-content;
        transition: 400ms;

        :focus-within {
          border: 2px solid #458ab9 !important;
          box-shadow: 1px 1px 7px #afafaf96;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          color: #2d5f82;
        }

        input {
          border: none;
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
          padding-left: 5px;

          ::placeholder {
            color: #296188a1;
            font-weight: 300;
          }

          :focus {
            outline: none;
            box-shadow: inset 1px 1px 4px #afafaf;
          }
        }
      }
    }

    .section-body {
      /* height: 700px; */
    }
  }

  .botoes-container {
    display: flex;
    width: 50%;
    margin: 25px auto;
    font-size: 12px;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 15px;
    box-shadow: 1px 1px 4px #000000ba;
    user-select: none;
    margin-top: auto;

    button {
      border: none;
      background: none;
      width: 50%;
      padding: 10px;
      font-weight: 500;
      cursor: pointer;
    }

    #confirmar-proximo-passo {
      background: green;
      color: #fff;
      border-right: 1px solid #828282;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    #fechar-modal-confirmacao {
      background: #c5bfbf;
      color: black;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

export const WrapperLicitacao = styled.div`
  position: absolute;
  left: calc(50% - 84.275px);
  top: -17px;
  display: flex;
  justify-content: center;

  .icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    box-shadow: 1px 1px 4px 1px #00000087;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .title {
    text-align: center;
    margin-top: 35px;
    font-size: 20px;
    margin-bottom: 5px;
  }
`;

export const InputValorUnitario = styled.input`
  font-size: 90%;
  height: 98%;
  width: 98%;

  :focus {
    background: #eee;
    outline: 1px solid grey;
  }
`;


