import React from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { ThumbsUp } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SelectInput } from '../../../../UsefulComponents/Inputs'
import Spinner from '../../../../UsefulComponents/Spinner'

import * as S from './Style'

const ModalQualificar = inject(
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(({ MaquinaDeEstadosStore, DetalhesEditalStore }) => {
    const { status, usuarios } = MaquinaDeEstadosStore
    const {
      modalQualificar,
      mudarPrioridade,
      prioridade,
      toggleModalQualificar,
      mudarStatus,
      isFetching,
      user: { changeUser, id }
    } = DetalhesEditalStore
    return (
      <ModalQualifyEdict
        status={status}
        users={usuarios}
        modalQualify={modalQualificar}
        changePriority={mudarPrioridade}
        priority={prioridade}
        toggleModalQualify={toggleModalQualificar}
        changeStatus={mudarStatus}
        isFetching={isFetching}
        changeUser={changeUser}
        id={id}
      />
    )
  })
)

const ModalQualifyEdict = ({
  status,
  users,
  modalQualify,
  changePriority,
  priority,
  toggleModalQualify,
  changeStatus,
  isFetching,
  changeUser,
  id
}) => {
  const changeStatusEdict = () => {
    changeStatus('qualificado')
    toggleModalQualify()
  }

  if (!modalQualify) return null

  let bulletinStatus
  if (status.qualificado.length) bulletinStatus = status.qualificado

  return ReactDOM.createPortal(
    <>
      {modalQualify && (
        <S.Overlay>
          <S.Container isFetching={isFetching} color={'#32a532'}>
            <div className="header">
              <div className="icon">
                <ThumbsUp />
              </div>
              <div className="title">QUALIFICAR</div>
            </div>
            {isFetching ? (
              <Spinner />
            ) : (
              <div className="body">
                {bulletinStatus[2] && (
                  <div className="select-user">
                    <div className="title">
                      SELECIONE UM USUÁRIO PARA ENCAMINHAR O EDITAL
                    </div>
                    <SelectInput
                      icon={<FontAwesomeIcon icon="users" />}
                      onChange={changeUser}
                      value={id}
                    >
                      {users.map((user, index) => (
                        <option key={index} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </SelectInput>
                  </div>
                )}
                {bulletinStatus[1] && (
                  <div className="nivel-prioridade">
                    <div className="title">NÍVEL DE PRIORIDADE</div>
                    <SelectInput
                      icon={<FontAwesomeIcon icon="bookmark" />}
                      onChange={changePriority}
                      value={priority}
                    >
                      <option value="alta">Alta</option>
                      <option value="media">Média</option>
                      <option value="baixa">Baixa</option>
                    </SelectInput>
                  </div>
                )}
              </div>
            )}
            <div className="footer">
              <div
                className="confirmar"
                onClick={!isFetching && changeStatusEdict}
              >
                CONFIRMAR
              </div>
              <div
                className="fechar"
                onClick={!isFetching && toggleModalQualify}
              >
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalQualificar
