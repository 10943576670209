import styled from "styled-components";

export const ModalContainer = styled.div`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  position: absolute;
  left: ${props => (props.left ? props.left : "80px")};
  right: ${props => (props.right ? props.right : "80px")};
  bottom: ${props => props.bottom || "30px"};
  top: ${props => props.top || "30px"};
  background-color: #f5f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0px 1px 8px 1px #00000091;
  max-height: ${props => props.maxHeight || "unset"};
`;

export const ModalHead = styled.div`
  background-color: #436177;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ContainerHeaderBG = styled.div`
    background-color: #eaeaea;
    height: auto;/* ${props => props.height || "30%"}; */
    padding: ${props => props.padding || "2rem"};
`;

export const HeaderBG = styled.div`
  background-color: transparent;
  /* padding: 1.5rem; */
  color: #000;
  height: 70%;
  font-size: 13px;

  @media (max-width: 1200px) {
    font-size: 11px;
  }
`;

export const DataSpan = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const DateHeader = styled.b`
  font-size: 16px;
  text-align: center;
  margin-bottom: 6px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;

  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1100px) {
    width: 200px;
  }
`;

export const ModalTitle = styled.h5`
  margin: 0;
  color: #fff;
  margin-left: 0.8rem;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`;

export const VerticalLine = styled.div`
  height: 100%;
  border-left: 1.5px solid #000;
  margin: 0 30px;

  @media (max-width: 1200px) {
    margin: 0 15px;
  }
`;

export const VerticalLine2 = styled.div`
  height: 130px;
  border-left: 1.3px solid #000;
  margin: 0 3rem;
  top: 0;
  margin-top: -25px;
`;

export const BodyMessage = styled.h5`
  text-align: center;
  margin: 2rem;
  white-space: normal;
  color: #436077;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem;

  button {
    font-size: 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    min-width: 90px;
    width: fit-content;
    justify-content: center;
    padding-top: 2px;
    margin-right: 10px;
    font-weight: 600;
  }

  .modal-confirm {
    background-color: #436077;
    color: #fff;
    /* transition: background-color 500ms; */

    :hover {
      background-color: #4a9256;
    }
  }

  .modal-back {
    color: #3a566e;

    :hover {
      color: #fff;
      background-color: #a95a5a;
    }
  }

  .baixar-novamente {
    color: #3a566e;

    :hover {
      color: #fff;
      background-color: #52947b;
    }
  }
`;

export const ButtonImprimir = styled.button`
  border: none;
  padding: 5px 15px;
  border-radius: 7px;
  background: #436077;
  color: #fff;
  font-weight: 600;
  box-shadow: 1px 1px 6px 2px #909090;
  margin-bottom: 5px;
  transition: 200ms;

  :hover {
    background: #eaeaea;
    color: #436077;
  }
  :focus {
    outline: none;
  }
`;
