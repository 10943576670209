import React, { useState, useRef, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Wrapper, Box, DivReturnoLogin } from './style/styleCadastro'
import { Logo, WrapperImag } from '../Login/styleSide'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Form } from 'formik'
import { CadastroContext } from './context'
import * as yup from 'yup'

import Cnpj from './components/CNPJ'
import Telefone from './components/Telefone'
import ReCaptcha from './components/SubComponents/ReCaptcha'
import Buttoon from './components/SubComponents/Button'
import MUInput from './components/MUInput'

const imgLogo = 'static/Imagens/logo_wavecode.png'

const estadoInicial = {
  first_name: '',
  last_name: '',
  email_cobranca: '',
  password: '',
  repeat_password: '',
  razao_social: '',
  nome_fantasia: '',
  cnpj: '',
  phone: ''
}

const FormCadastro = () => {
  const { onSubmit, error, success } = useContext(CadastroContext)
  const formRef = useRef(null)

  const [state, setState] = useState({
    isVerified: true,
    submitEnabled: false,
    captchaShowing: true
  })

  const { submitEnabled, captchaShowing } = state

  const validarCadastro = yup.object().shape({
    first_name: yup
      .string()
      .required('O primeiro nome é obrigatório')
      .min(4, 'Minimo 4 caracteres')
      .max(255, 'Maximo 255 caracteres'),
    last_name: yup
      .string()
      .required('O ultimo nome é obrigatório')
      .min(4, 'Minimo 4 caracteres')
      .max(255, 'Maximo 255 caracteres'),
    email_cobranca: yup
      .string()
      .email('Digite um e-mail válido')
      .required('O email é obrigatório')
      .max(255, 'Maximo 255 caracteres'),
    password: yup
      .string()
      .min(4, 'A senha deve ter no mínimo 4 caracteres')
      .required('A senha é obrigatória'),
    repeat_password: yup
      .string()
      .oneOf([yup.ref('password')], 'As senhas devem serem iguais')
      .required('A confirmação da é obrigatória'),
    razao_social: yup
      .string()
      .required('A razão social é obrigatória')
      .min(4, 'Minimo 4 caracteres')
      .max(250, 'Maximo 250 caracteres'),
    nome_fantasia: yup
      .string()
      .required('O nome fantasia é obrigatório')
      .min(4, 'Minimo 4 caracteres')
      .max(250, 'Maximo 250 caracteres'),
    cnpj: yup.string().required('O CNPJ é obrigatório'),
    phone: yup.string().required('O telefone é obrigatório')
  })

  return (
    <>
      <WrapperImag></WrapperImag>
      <Wrapper>
        <Box>
          <Logo>
            <img src={imgLogo} alt={'logo'} />
            <h3>Comece agora</h3>Cadastre seus dados e use por 7 dias grátis.
          </Logo>
          <Formik
            initialValues={estadoInicial}
            onSubmit={(values, actions) => onSubmit(values)}
            validationSchema={validarCadastro}
          >
            {({ errors, touched, handleChange, handleBlur }) => (
              <Form ref={formRef}>
                <MUInput
                  label="Primeiro Nome"
                  name="first_name"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type="text"
                />
                <MUInput
                  label="Ultimo Nome"
                  name="last_name"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type="text"
                />
                <MUInput
                  label="Email Cobrança"
                  name="email_cobranca"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type="email"
                />
                <Row>
                  <Col md={6}>
                    <MUInput
                      label="Senha"
                      name="password"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      type="password"
                    />
                  </Col>
                  <Col md={6}>
                    <MUInput
                      label="Repetir Senha"
                      name="repeat_password"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      type="password"
                    />
                  </Col>
                </Row>
                <MUInput
                  label="Razão Social"
                  name="razao_social"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type="text"
                />
                <MUInput
                  label="Nome Fantasia"
                  name="nome_fantasia"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type="text"
                />
                <Cnpj
                  name="cnpj"
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Telefone
                  name="phone"
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                {captchaShowing && <ReCaptcha setState={setState} />}

                {submitEnabled && <Buttoon />}
              </Form>
            )}
          </Formik>
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div class="alert alert-success" role="alert">
              {success}
            </div>
          )}

          <Link to={'/'} title={'Login'}>
            <DivReturnoLogin>
              <FontAwesomeIcon icon="arrow-alt-circle-left" /> Voltar para tela
              de login
            </DivReturnoLogin>
          </Link>
        </Box>
      </Wrapper>
    </>
  )
}

export default FormCadastro
