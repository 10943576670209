import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin-left: 30px;
`;


export const TabLista = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  color: #fff;
  height: 23px;
  margin: 0 6px;
  background: #409AC3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 120px;
  width: 150px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  opacity: ${props => props.active ? 1 : '0.6'};
  cursor: pointer;
  transition: 100ms;
  user-select: none;
  text-transform: uppercase;

  .active{
      background: #c34069;
  }

  :hover {
    height: 25px;
    opacity: ${props => props.active ? 1 : '0.8'};
  }

  .counter {
    position: absolute;
    right: -4px;
    top: -5.5px;
    border-radius: 7px;
    padding: 0px 4px;
    background: ${props => props.active ? "#f9ab19" : "#fff"};
    color: black;
    min-width: 30px;
    font-size: 11px;
    font-weight: 700;
    box-shadow: 0px 0px 5px #3e3c3c;
    transition: 400ms;
    user-select: none;
  }
`;
