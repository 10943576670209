import styled from "styled-components";

const Container = styled.div`
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
  height: 100%;
  

  .edital{
    color: white;
    font-weight: 700;
    user-select: text;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.paddingTop || 0};

  height: ${props => props.height || 'calc(100vh - 166px)'};

  .botoes-container {
      display: flex;
      width: 50%;
      margin: 25px auto;
      font-size: 12px;
      margin-bottom: 30px;
      z-index: 1;
      border-radius: 15px;
      box-shadow: 1px 1px 4px #000000ba;

      button {
        border: none;
        background: none;
        width: 50%;
        padding: 10px;
        font-weight: 500;
        cursor: pointer;
      }


      #salvar-proposta {
        background: green;
        color: #fff;
        border-right: 1px solid #828282;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      #confirmar-proximo-passo {
        background: #3a566e;
        color: #fff;
        border-right: 1px solid #828282;
      }
      #fechar-modal-confirmacao {
        background: #c5bfbf;
        color: black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

  @media (max-height: 780px) {
    /* height: calc(100vh - 225px); */
  }
`;



const ContainerTabela = styled.div`
  height: ${props => props.height || '100%'};
  width: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 0 20px; */
  background: #fff;
  color: #000;
  z-index: 1;
  border-radius: 10px;
  box-shadow: #00000063 1px 1px 6px;
  overflow: hidden;

  .tabela-proposta-carregar {
    /* height: calc(100vh + 11px); */
    height: calc(100vh + 11px);

    .rdt_TableBody {
      max-height: calc(100vh - 358px);
    }
  }

  .rdt_TableHeadRow {
    box-shadow: 1px 1px 9px #949494;
    border: none;
  }

  .rdt_TableCol_Sortable{
    font-weight: bold;
    color: black;
  }
  .rdt_TableRow{
    padding-bottom: 5px;
    padding-top: 5px;
    min-height: unset;
  }

  .tabela-padrao {
    opacity: 0;
    animation: 400ms fadeIn ease-out forwards;
  }


  .rdt_TableBody {
    /* max-height: calc(100vh - 365px); */
    max-height: calc(100vh - 287px);
    
    
    overflow: auto;
    @media (max-height: 780px) {
      /* max-height: calc(100vh - 216px); */
    }
  }
  

  .rdt_TableCell:nth-child(5) {
    padding-left: 18px;
  }

  .rdt_TableCell:last-child {
    padding-right: calc(48px / 6);
  }

  .tabela-gerenciar-proposta {
    height: calc(100vh + 45px);

    .rdt_TableBody {
      max-height: calc(100vh - 254px);
    }

    .rdt_TableRow {
      min-height: 55px;
      margin: 7px 10px;
      width: auto;
      box-shadow: 1px 1px 5px #00000033;
    }
  }

  .rdt_TableBody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  .rdt_TableBody::-webkit-scrollbar {
	  width: 10px;
	  background-color: #F5F5F5;
  }

  .rdt_TableBody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
                      left bottom,
                      left top,
                      color-stop(0.44, rgb(128, 187, 224)),
                      color-stop(0.72, rgb(80, 132, 166)),
                      color-stop(0.86, rgb(95, 163, 204)   ));
  }
`;


const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px;
  min-height: 50px;
  box-shadow: 1px 1px 9px #4e4e4e;

  @media (max-height: 780px) {
    /* padding: 10px; */
  }

  div{
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }

  .MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff;
    background-color: #5b8eb1;
  }
`;





export { Container, Body, ContainerTabela, ContainerPagination }
