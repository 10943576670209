const tabIds = new Set();

let broadcastChannel;
let myTabId;
/**
 *  A ideia é saber qual tab é a principal, para somente uma aba emitir sons de alerta.
 */

export const initChannel = () => {
  const myTabIdArray = crypto.getRandomValues(new Uint32Array(4))[0];
  myTabId = `${Date.now()}-${myTabIdArray.toString(16)}`;

  broadcastChannel = new BroadcastChannel("tabId");

  broadcastChannel.onmessage = event => {
    if (event.data.type === "response") {
      tabIds.add(event.data.tabId);
    } else if (event.data.type === "request") {
      broadcastChannel.postMessage({ type: "response", tabId: myTabId });
    }
  };
};

const isMainTabPromise = async () => {
  tabIds.clear();
  tabIds.add(myTabId);
  broadcastChannel.postMessage({ type: "request", tabId: myTabId });

  await new Promise(resolve => {
    setTimeout(resolve, 500);
  });

  const sordedTabIds = Array.from(tabIds).sort();
  return sordedTabIds[0] === myTabId;
};

let currentPromise = null
export const isMainTab = () => {
    if (currentPromise == null){
        currentPromise = isMainTabPromise()
        currentPromise.finally(() => {
            currentPromise = null
        })
    }
    return currentPromise
}