import styled from 'styled-components';

export const Overflow = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 223;
  background: #000;
  background: #000000c9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: 1s ease-in fadeIn forwards;
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 7px;
  width: 600px;
  height: 435px;
  box-shadow: 1px 1px 8px #00000059;

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: #882929;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
    position: relative;
    user-select: none;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;
      

      span {
        font-weight: 600;
        font-size: 90%;
      }
      svg {
        margin-right: 10px;
      }
    }
    button.close-button {
      position: absolute;
      right: -5px;
      top: -5px;
      background: #bd1c1c;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 1px 1px 7px 1px #101010;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
  .modal-body {
    overflow: auto;
    width: 100%;
    padding: 20px 40px;
    max-height: 91vh;
    display: flex;
    flex-direction: column;

    .section-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .title {
        display: flex;
        flex-direction: column;

        b {
          color: #bc1c1c;
        }

        .main-title {
          font-weight: 700;
          font-size: 110%;
          margin-bottom: 6px;
          color: #1b4663;
        }

        .sub-title {
          font-size: 80%;
          color: #aaaab3;
          font-size: 80%;
          color: #bc1c1c;
          font-weight: 700;
        }
      }
    }

    .section-suggestion {
      display: flex;
      flex-direction: column;
      font-size: 80%;
      color: #1b4663;

      b { margin-bottom: 10px }
      span { margin-bottom: 5px }

      a { margin-left: 10px}
    }

    .botoes-container {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      button {
        border-radius: 7px;
        box-shadow: 1px 1px 3px #00000078;
        border: none;
        color: white;
        padding: 5px;
        font-size: 12px;
        margin-bottom: 15px;
        font-weight: 600;

        :last-child { margin-bottom: 10px }
      }

      #estou-ciente-navegador-antigo {
        background: #0b6f0b;
      }

      #solicitar-ajuda-suporte {
        background: #0b4a6f;
      }

      span.ajuda-solicitada { 
        color: #0b6f0b;
        text-align: center;
        font-size: 12px;
      }

      

      #submit-editar-cred {
        background: green;
        color: #fff;
        border-right: 1px solid #828282;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        transition: 300ms ease-in;

        :disabled {
          background: #a5a5a5;
        }
      }
      #close-editar-cred {
        background: #c5bfbf;
        color: black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }

    }

    .cred-alterada {
      display: flex;
      flex-direction: column;
      color: green;
      font-size: 80%;
      align-items: center;

      span.title {
        font-size: 13px;
        opacity: 0;
        animation: 2s fadeIn forwards;
        font-weight: 600;
      }
      
      svg {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
      }

      span.fechar {
        color: #888787;
        font-size: 11px;
        margin-top: 5px;
        font-weight: 600;
      }
    }
  }
`;
