import React, { useContext } from 'react'
import { Bookmark, ThumbsDown, ThumbsUp } from 'react-feather'
import { ContainerFooter } from './style'
import { ModalContext } from '../context/modal'
import { useStatusEdital } from '../context'

const ModalConfirmation = () => {
  const { edital } = useStatusEdital()
  const { modalConfirmation } = useContext(ModalContext)

  const statusConfirmation = () => {
    switch (edital.selecionado.status) {
      case 'qualificado':
        return (
          <div className="símbolo qualificado">
            <ThumbsUp />
          </div>
        )
      case 'aprovado':
        return (
          <div className="símbolo aprovado">
            <Bookmark />
          </div>
        )
      case 'reprovado':
        return (
          <div className="símbolo desqualificado">
            <ThumbsDown />
          </div>
        )
    }
  }

  return (
    <>
      {modalConfirmation && (
        <ContainerFooter>
          <div className="status">
            {statusConfirmation()}
            <div className="title">{`EDITAL: ${edital.licitacao}`}</div>
            <div className="message">{edital.selecionado.status}</div>
          </div>
        </ContainerFooter>
      )}
    </>
  )
}

export default ModalConfirmation
