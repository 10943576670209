import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Edit } from "react-feather";
import {
  CardMD,
  CardMDBarra,
  CardMDBody,
  CardMDHearder,
  Titulo
} from "../styleDashboard";
import {
  CardBarra,
  CardMDBodyContainer1,
  CardMDBodyContainer2,
  CardMDTextBody
} from "./styleLicitacao";

export const LicitacaoGrafico = ({ data }) => {
  const {
    lic_total,
    lic_agendadas,
    lic_andamento,
    lic_realizadas,
    lic_vencidas,
    lic_canceladas
  } = data;
  const dataGrafico = {
    labels: ["AGENDADO", "EM ANDAMENTO", "REALIZADO", "ATA", "CANCELADA"],
    datasets: [
      {
        data: [
          lic_agendadas,
          lic_andamento,
          lic_realizadas,
          lic_vencidas,
          lic_canceladas
        ],
        backgroundColor: [
          "#4b9488",
          "#2c588f",
          "#ff9610",
          "#399300",
          "#b4382b"
        ],
        hoverBackgroundColor: [
          "#4b9488",
          "#2c588f",
          "#ff9610",
          "#399300",
          "#b4382b"
        ]
      }
    ]
  };
  return (
    <CardMD color={"#399300"}>
      <div style={{ display: "flex", width: "100%" }}>
        <CardMDHearder>
          <div style={{ marginRight: "15px" }}>
            <Edit size={"35px"} />
          </div>
          <Titulo>
            <h5>LICITAÇÕES</h5>
            <span>Status das licitações</span>
          </Titulo>
        </CardMDHearder>

        <CardBarra background={"#086f6c"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "120px",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            marginRight: "8px"
          }}
        >
          <div>
            <h2>
              <b>{lic_total}</b>
            </h2>
          </div>
          <div style={{ fontSize: "11px", marginTop: "-10px" }}>
            Total Licitações
          </div>
        </div>
      </div>
      <CardMDBarra />

      <CardMDBody>
        <CardMDBodyContainer1>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <CardMDTextBody color={"#4b9488"}>
              <h3>{lic_agendadas}</h3>
              <CardBarra background={"#086f6c"} />
              <div>AGENDADO</div>
            </CardMDTextBody>
            <CardMDTextBody color={"#2c588f"}>
              <h3>{lic_andamento}</h3>
              <CardBarra background={"#086f6c"} />
              <div>EM ANDAMENTO</div>
            </CardMDTextBody>
            <CardMDTextBody color={"#ff9610"}>
              <h3>{lic_realizadas}</h3>
              <CardBarra background={"#086f6c"} />
              <div>REALIZADO</div>
            </CardMDTextBody>
            <CardMDTextBody color={"#399300"}>
              <h3>{lic_vencidas}</h3>
              <CardBarra background={"#086f6c"} />
              <div>ATA</div>
            </CardMDTextBody>
            <CardMDTextBody color={"#b4382b"}>
              <h3>{lic_canceladas}</h3>
              <CardBarra background={"#086f6c"} />
              <div>CANCELADA</div>
            </CardMDTextBody>
          </div>
        </CardMDBodyContainer1>
        <CardMDBodyContainer2>
          <Doughnut data={dataGrafico} legend={false} />
        </CardMDBodyContainer2>
      </CardMDBody>
    </CardMD>
  );
};
