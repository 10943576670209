import React from "react";
import { Div } from "../../style/styleCadastro";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

const Telefone = ({ errors, touched, name, handleChange, handleBlur }) => {
    return (
        <>
            <InputMask
                mask={"55 (99) 9 9999-9999"}
                onChange={handleChange}
                onBlur={handleBlur}
            >
                {() => (
                    <TextField
                        error={touched[name] && errors[name]}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="dense"
                        name={name}
                        type="text"
                        fullWidth
                        label="Telefone"
                    />
                )}
            </InputMask>
            {errors[name] && touched[name] && <Div>{errors[name]}</Div>}
        </>
    )
}

export default Telefone;