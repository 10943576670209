import React, { useContext } from 'react'
import { ThumbsUp } from 'react-feather'

import RoundBadge from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'

const ButtonQualify = ({ edital, status }) => {
  const { setEdital, statusDirectEdict } = useStatusEdital()
  const { openModalQualificar } = useContext(ModalContext)

  const showModalQualify = () => {
    setEdital(edital)
    openModalQualificar()
  }

  const qualify = () => {
    statusDirectEdict(edital, 'qualificado', edital)
  }

  return (
    <>
      <RoundBadge
        icon={<ThumbsUp />}
        background={edital.status === 'qualificado' ? '#58c340' : '#b9dbb6'}
        onClick={
          status.qualificado && !status.qualificado[1] && !status.qualificado[2]
            ? qualify
            : showModalQualify
        }
      />
    </>
  )
}

export default ButtonQualify
