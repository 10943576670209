import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSetting } from './Context/index.js'

import * as S from './Style/styles'

const ActionButtons = () => {
  const {
    settings,
    thereWasChange,
    selectedAreas,
    initialSelectedAreas,
    qualifiedFormat,
    initialQualifiedFormat,
    approvedFormat,
    reprovedFormat,
    initialApprovedFormat,
    initialReproveFormat,
    clean,
    saveData,
    saveOtherData
  } = useSetting()
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const able = useMemo(() => {
    return !(
      selectedAreas.sort().join() === initialSelectedAreas.sort().join() &&
      JSON.stringify(reprovedFormat) === JSON.stringify(initialReproveFormat) &&
      JSON.stringify(qualifiedFormat) ===
      JSON.stringify(initialQualifiedFormat) &&
      JSON.stringify(approvedFormat) === JSON.stringify(initialApprovedFormat)
    )
  }, [selectedAreas, qualifiedFormat, approvedFormat, reprovedFormat])

  const active = () => {
    return settings.find(item => item.active === true)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    clean()
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleSubmit = async () => {
    let currentComponent = active()
    setLoading(true)
    currentComponent.title !== 'FORMATO PERSONALIZADO' && (await saveData())
    currentComponent.title === 'FORMATO PERSONALIZADO' &&
      (await saveOtherData())
    setLoading(false)
  }

  return (
    <>
      <S.Bottoms>
        <button
          className="limpar"
          onClick={showModal}
          disabled={!able || !thereWasChange}
        >
          DESFAZER ALTERAÇÕES
        </button>
        <button
          className="salvar"
          onClick={handleSubmit}
          disabled={!able || loading || !thereWasChange}
        >
          {loading ? <FontAwesomeIcon spin icon="spinner" /> : 'SALVAR'}
        </button>
      </S.Bottoms>
      {isModalVisible && (
        <Dialog
          open={true}
          onClose={handleCancel}
        >
          <DialogTitle>
            Wavecode diz:
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja desfazer as alterações que não foram salvas?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Não</Button>
            <Button onClick={handleOk} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ActionButtons
