import React, { useContext, useEffect, useState } from 'react'
import {
  ContainerDocumentos,
  FileInputLabel,
  DocumentSelectText,
  SingleDocument
} from './styles'
import { File as FileIcon, Search, XCircle, Check } from 'react-feather'
import { sliceString } from '../../../../../../../Services/funcoes'
import { FileInput } from '../dadosPdf/styles'
import {
  FormatDateData,
  removeAcento,
  removeSpecialChar,
  ToastError
} from '../../../../../../../Stores/Funcoes/FuncoesGerais'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactTooltip } from '../../../../../../../globalStyles'
import { DocumentosContext } from './context'

const DocumentosHabilitacao = () => {
  const {
    setFilesHabilitacao,
    setDocsHabilitacao,
    filesHabilitacao,
    docsHabilitacao,
    docsProposta
  } = useContext(DocumentosContext)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const handleDocument = e => {
    let document = e.target.files[0]
    let jaExiste, name, file
    if (document) {
      name = removeSpecialChar(removeAcento(document.name)) // Tratamento de caracteres especiais
      file = new File([document], name)
      jaExiste = docsHabilitacao.find(doc => doc.name === file.name)
      if (file.size >= 50000000) {
        return ToastError('O tamanho maximo deve ser de 50mb, conforme regra do Comprasnet')
      }
      if (!jaExiste) jaExiste = docsProposta.find(doc => doc.name === file.name)
    }
    e.target.value = null
    if (jaExiste) {
      return ToastError('Já existe um documento com esse nome.')
    }
    setFilesHabilitacao([...filesHabilitacao, file])
    setDocsHabilitacao([...docsHabilitacao, { name: file.name }])
  }

  return (
    <ContainerDocumentos headerColor="#389848">
      <ReactTooltip effect="solid" />
      <div className="header">
        <FileIcon />
        <span>ANEXAR DOCUMENTOS DE HABILITAÇÃO</span>
      </div>
      <div className="selecionar-arquivo">
        <FileInputLabel
          htmlFor="enviar-documento"
          className="position-relative"
          data-tip="Tamanho máximo 50mb."
          data-type="warning"
          data-effect="solid"
          data-place="bottom"
        >
          <div className="position-absolute" style={{ pointerEvents: 'none' }}>
            <Search />
          </div>
          <DocumentSelectText htmlFor="select-document-habilitacao">
            SELECIONE O DOCUMENTO
          </DocumentSelectText>
          <FileInput
            type="file"
            id="select-document-habilitacao"
            style={{ position: 'absolute', width: '100%', left: 0 }}
            // accept="image/*"
            onChange={handleDocument}
          />
        </FileInputLabel>
        {/* <button className="icon-scale-xs" disabled={!documentFile} onClick={() => handleAnexoDocumento('habilitacao')}>ANEXAR ARQUIVO</button> */}
      </div>
      <div className="body custom-scroll-blue">
        {docsHabilitacao.map((documento, i) => {
          const { id, name, loading, created_at, error, enviado } = documento

          const handleDelete = () => {
            if (enviado) return
            else {
              setFilesHabilitacao(filesHabilitacao.filter(f => f.name !== name))
              setDocsHabilitacao(docsHabilitacao.filter(f => f.name !== name))
              ReactTooltip.hide()
            }
          }

          return (
            <SingleDocument key={i} loading={loading} enviado={enviado}>
              <div className="name">{name}</div>
              {enviado && (
                <Check
                  className="enviado"
                  data-tip="Documento já foi enviado ao portal"
                  data-type="success"
                  data-place="right"
                />
              )}
              <div className="date">{FormatDateData(created_at)}</div>
              {error ? (
                <div className="document-error">{error}</div>
              ) : (
                <div className="date">{FormatDateData(created_at)}</div>
              )}
              {loading ? (
                <div className="loading-document-spinner">
                  <FontAwesomeIcon icon="spinner" spin />
                </div>
              ) : (
                <div
                  className="close-button"
                  onClick={handleDelete}
                  data-tip={enviado ? 'EXCLUIR DO PORTAL' : 'REMOVER DOCUMENTO'}
                  data-type="error"
                  data-place="left"
                >
                  <XCircle className="icon-scale-xs" />
                </div>
              )}
            </SingleDocument>
          )
        })}
      </div>
    </ContainerDocumentos>
  )
}

export default DocumentosHabilitacao
