import { toast } from "react-toastify";
import React from 'react';
import styled from "styled-components";
import { MessageSquare } from "react-feather";
import licitacaoStores from "../Stores/LicitacaoStores";
import maquinadeestadosstore from "../Stores/MaquinaDeEstadosStore";

let mensagens = [];

export const CustomToast = (info, config, onClick) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-right",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: true
  };

  if (config) configPadrao = { ...configPadrao, ...config };
  if (onClick) configPadrao.onClick = onClick;

  if (document.visibilityState === "hidden") {
    mensagens.push(() => toast(<MensagemImportante info={info} />, configPadrao));
    window.onfocus = () => {
      mensagens.forEach(msg => msg());
      mensagens = [];
      window.onfocus = () => { };
    }
  } else return toast(<MensagemImportante info={info} />, configPadrao)
};

const MessageLayout = styled.div`
  display: flex;
  flex-direction: column;
  

  .title {
    font-weight: 600;
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 4px;

    svg {
      width: 40px;
      margin-right: 5px;
    }
  }

  .info {
    font-weight: 300;
    margin: 2px 5px;
    color: #d8d8d8;
    font-size: 11px;

    b {
      font-weight: 600;
    }
  }
`;

export const MensagemImportante = ({ info }) => {
  const { empresa, licitacao_cod, portal, descricao_tipo, tipostring, situacao } = info;

  return (
    <MessageLayout>
      <div className="title"><MessageSquare />{descricao_tipo.toUpperCase()}</div>
      <div className="info">LICITAÇÃO: <b>{licitacao_cod}</b></div>
      <div className="info">EMPRESA: <b>{empresa}</b></div>
      <div className="info">PORTAL: <b>{portal}</b></div>
      {tipostring === "situacao" &&
        <div className="info">NOVA SITUACAO: <b>{situacao}</b></div>
      }
    </MessageLayout>
  )
}




