// import { compareData, formatData } from "./funcoes";
// import MaquinaDeEstadosStore from "../Stores/MaquinaDeEstadosStore";

export const CONTRATO = 'contrato'
export const MODULO_TESTE = 'modulo_teste'
export const MODULO_LICITACOES = 'licitacoes'
export const MODULO_EDITAIS = 'editais'
export const MODULO_BOLETIM = 'boletim'
export const MODULO_MONITORAMENTO = 'monitoramento'
export const DATA_VALIDADE = 'data_validade'
export const MODULO_PROPOSTA = 'proposta'

// export const getContratoProposta = () => {
//   const { contratos } = MaquinaDeEstadosStore;
//   if (contratos.length > 0) {
//     let contrato_modulo = contratos.find(
//       item => item.name_modulo === MODULO_MONITORAMENTO_EDITAIS_PROPOSTA
//     );
//     return contrato_modulo.status;
//   }
// };

// export const getDataValidadeContrato = modulo => {
//   const { contratos } = MaquinaDeEstadosStore;

//   if (contratos.length > 0) {
//     let validadeContrato = contratos.find(item => {
//       return item.name_modulo === modulo;
//     }).data_validade;
//     return contratos && formatData(validadeContrato);
//   }
// };

// export const getContrato = modulo => {
//   const { contratos } = MaquinaDeEstadosStore;

//   if (modulo === MODULO_MONITORAMENTO_EDITAIS) {
//     let contrato_modulo = contratos.find(item => item.name_modulo === modulo);
//     let { data_validade } = contrato_modulo;

//     const resultado = contratos.map(item => {
//       if (item.name_modulo === MODULO_EDITAIS) {
//         return { ...item, data_validade: data_validade };
//       }
//       return item;
//     });

//     MaquinaDeEstadosStore.contratos = resultado;

//     if (compareData(data_validade)) {
//       return contrato_modulo.status;
//     } else {
//       return false;
//     }
//   }
// };

// export const verificaContrato = () => {
//   if (!getContrato(MODULO_EDITAIS) && !getContrato(MODULO_MONITORAMENTO)) {
//     MaquinaDeEstadosStore.redireciona_financas();
//     return true;
//   } else {
//     return false;
//   }
// };
