import styled from 'styled-components';

export const Overlay = styled.div`
  z-index: 3123213;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000000c9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 1000px;
  max-height: 96vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #464646;
  animation: 1s fadeIn ease-in-out;

  /* @media (max-height: 820px) {
    height: 600px;
  } */

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: #296188;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;

      span {
        font-weight: 600;
        font-size: 90%;
      }
      svg {
        margin-right: 10px;
      }
    }
    button.close-button {
      position: absolute;
      right: -5px;
      top: -5px;
      background: #bd1c1c;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 1px 1px 7px 1px #101010;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  .modal-body {
    overflow: auto;
    width: 100%;
    padding: 20px 40px;
    max-height: 91vh;

    .section-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        flex-direction: column;

        .main-title {
          font-weight: 700;
          font-size: 110%;
        }

        .sub-title {
          font-size: 80%;
          color: #aaaab3;
        }
      }

      .upload-company-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .wrapper-image {
          padding: 6px;
          border: 1px solid #e2e2e2;
          border-radius: 12px;

          .spinner {
            animation: 2s rotateZ infinite;
            width: 50px;
            height: 50px;
            padding: 6px;
            font-size: 1rem;
            color: #131e2557;
          }

          @keyframes rotateZ {
            100% {transform: rotate(360deg)}
          }

          img {
            width: 50px;
            height: 50px;
          }
        }

        /* button {
          border: none;
          background: green;
          border-radius: 7px;
          margin-top: 7px;
          font-size: 70%;
          padding: 1px 10px;
          color: white;
        } */
      }
    }

    .section-cabecalho, .section-rodape {
      display: flex;
      flex-direction: column;
      
      .title {
        font-weight: 700;
        font-size: 75%;
        margin-bottom: 7px;
      }

      .input-razao-cnpj,
      .input-est-mun-fone-cel,
      .logradouro, 
      .logradouro2, 
      .logradouro3,
      .input-nome-rep-legal {
        display: flex;
      }

      .imprimir-dados-textarea {
        display: flex;
        flex-direction: column;

        div:first-child {
          margin-bottom: 0px;
        }
      }
    }

    .section-rodape {
      margin-top: 20px;
    }

    .botoes-container {
      display: flex;
      /* border-radius: 15px; */
      width: 50%;
      margin: auto;
      font-size: 13px;
      margin-top: 15px;

      button {
        border:none;
        background: none;
        width: 50%;
        padding: 10px;
        font-weight: 500;
      }

      #imprimir-proposta {
        background: green;
        color: #fff;
        border-right: 1px solid #828282;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      #fechar-modal-imprimir {
        background: #c5bfbf;
        color: black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AssinaturaImg = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 6px;
  
  svg#remover-assinatura {
    color: #e82e2e;
    position: absolute;
    bottom: -7px;
    left: -7px;
    width: 20px;
  }

  img {
    max-height: 120px;
    max-width: 200px;
    max-width: 280px;
    max-height: 200px;
  }
`;

export const InserLogoEmpresa = styled.label`
  border: none;
  background: green;
  border-radius: 7px;
  margin-top: 7px;
  font-size: 70%;
  padding: 1px 10px;
  color: white;
  font-weight: 600;
`;