import React, { useState } from 'react';
import styled from 'styled-components';
import loginStores from '../../Stores/LoginStores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Overlay = styled.div`
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 31232131232;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #000000e8;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 1px 1px 1px black;
  border-radius: 10px;
  height: 90%;
  width: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px 30px;
  position: relative;

  svg {
    position: absolute;
    right: 35px;
    top: 31px;
    font-size: 20px;
    color: #1d2331
  }

  input {
    margin-bottom: 30px;
    border: 2px solid #1d2331;
    font-weight: 500;
  }
`;

const User = styled.div`
  font-weight: 500;
  background: #1d2331;
  color: white;
  padding: 5px 13px;
  margin: 2px 0px;
  box-shadow: 1px 1px 3px #0000009e;
  transition: 300ms ease-in-out;
  cursor: pointer;

  :hover {
    background: gainsboro;
    color: #1d2331;
  }
`;

const ListaDeUsuariosSuporte = ({ listaDeUsuarios }) => {
  const [users, setUsers] = useState(listaDeUsuarios);

  const handleChange = (e) => {
    const { value } = e.target;
    if (!value) setUsers(listaDeUsuarios);
    else setUsers(users.filter(us => us.email.includes(value)));
  }

  const handleClick = (user) => {
    loginStores.inicializar(user);
  }

  return (
    <Overlay>
      <Container>
        <FontAwesomeIcon icon="search" />
        <input type="text" onChange={handleChange} />
        {users.map((user, index) => (
          <User key={index} onClick={() => handleClick(user)}>{user.email}</User>
        ))}
      </Container>
    </Overlay>
  )
}

export default ListaDeUsuariosSuporte
