import { action, observable } from 'mobx'
import { createContext } from 'react'
import axios from 'axios'
import Api from '../../../Stores/Conexao/conexao'
import $ from 'jquery'
import {
  AlertaError,
  AlertaSuccess,
  ToastSuccess
} from '../../../Stores/Funcoes/FuncoesGerais'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import ModalLoadingStore from '../../../Stores/ModalLoadingStore'
import CompradoresStore from '../../../Stores/CompradoresStore'

class ConfiguracaoEditaisStore {
  @observable houveAlteracao = false
  @observable novoPerfil = false

  @observable nomePerfil = ''

  // SELEÇÃO DE PORTAIS
  @observable todosPortais = [...MaquinaDeEstadosStore.portais]
  @observable portal = this.todosPortais.map(p => p.id)

  //   @observable listaDePerfis = [{ nome: "perfil 1" }, { nome: "perfil 2" }];
  @observable listaDePerfis = []

  @observable compradoresExclusao = []
  @observable compradoresFiltrados = []

  @observable id = ''
  @observable notificao_email = ''

  @observable tag_notificao_email = null
  @observable tags_notificao_email = []
  @observable tags_palavra_exclusao = []
  @observable tag_palavra_exclusao = ''
  @observable tags_palavra_classificao = []
  @observable tag_palavra_classificao = ''

  @observable empresa_me = 'Todos'

  @observable isFetching = false
  @observable defaultValue = ''

  @observable palavra_classificao = ''
  @observable palavra_exclusao = ''

  // SELEÇÃO DE MODALIDADES
  @observable modalidades = [...MaquinaDeEstadosStore.modalidade]
  @observable modalidade = this.modalidades.map(p => p.id)

  // NOMEAÇÃO ARQUIVOS
  @observable nomeacaoEditais = ''

  // FILTRO MATERIAL/SERVIÇOS
  @observable materiaisServicos = ['M', 'S']

  @observable area = []

  //DIAS DA SEMANA
  @observable
  weekDays = []
  @observable
  allWeekDays = ['SEG', 'TER', 'QUA', 'QUI', 'SEX']
  //HORARIOS
  @observable
  hours = []
  @observable
  allHours = ['7', '9', '11', '13', '15', '17', '19']

  //FILTRO ESTADOS
  @observable
  estados = []
  @observable
  todosEstados = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    {
      nome: 'Amapá',
      sigla: 'AP'
    },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    {
      nome: 'Ceará',
      sigla: 'CE'
    },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    {
      nome: 'Goiás',
      sigla: 'GO'
    },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    {
      nome: 'Mato Grosso do Sul',
      sigla: 'MS'
    },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    {
      nome: 'Paraíba',
      sigla: 'PB'
    },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    {
      nome: 'Piauí',
      sigla: 'PI'
    },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    {
      nome: 'Rio Grande do Sul',
      sigla: 'RS'
    },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    {
      nome: 'Santa Catarina',
      sigla: 'SC'
    },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' }
  ]

  @action criarPerfil = prompt => {
    if (prompt.length > 15) {
      AlertaError('O número máximo de caracteres é 15!')
      return setTimeout(
        () => document.getElementById('criar-perfil-button').click(),
        500
      )
    }

    this.nomePerfil = prompt
    this.novoPerfil = true
    this.limparVariaveis()
  }

  @action deletarPerfil = async () => {
    ModalLoadingStore.handleOpenModal()
    try {
      const id = this.id
      await Api.post('/portal/delete_configuracao/', { id })
      this.limparVariaveis()
      this.listaDePerfis = this.listaDePerfis.filter(pe => pe.id !== id)
    } catch (err) {
      AlertaError('Erro ao deletar perfil')
    } finally {
      ModalLoadingStore.handleCloseModal()
    }
  }

  @action mudarPerfil = selected => {
    const perfilSelecionado =
      this.listaDePerfis.find(perf => perf.id === selected) ||
      this.listaDePerfis[0]
    const {
      id,
      notificao_dia,
      notificao_email,
      notificao_hora,
      palavra_exclusao,
      palavra_classificao,
      estados,
      comprador,
      comprador_exclusao,
      empresa_me,
      portal,
      modalidade,
      nomeacao_download,
      tipo_item,
      nome_perfil,
      area
    } = perfilSelecionado

    let empresaMe

    switch (empresa_me[0]) {
      case 'Todos':
        empresaMe = 'Todos'
        break
      case 'Excluir':
        empresaMe = 'Excluir'
        break
      default:
        empresaMe = empresa_me
    }

    this.empresa_me = empresaMe
    this.defaultValue = Array.isArray(empresaMe) ? 'FILTRAR ME/EPP' : empresaMe

    this.estados = estados
    this.id = id
    this.portal = portal
    this.modalidade = modalidade
    this.notificao_email = notificao_email
    this.palavra_exclusao = palavra_exclusao
    this.palavra_classificao = palavra_classificao
    this.nomeacaoEditais = nomeacao_download
    this.materiaisServicos =
      typeof tipo_item !== 'object' ? tipo_item.split(',') : tipo_item
    this.nomePerfil = nome_perfil
    this.area = area

    if (palavra_classificao != null) {
      this.tags_palavra_classificao = palavra_classificao.split(',')
    } else {
      this.tags_palavra_classificao = []
    }
    if (palavra_exclusao != null) {
      this.tags_palavra_exclusao = palavra_exclusao.split(',')
    } else {
      this.tags_palavra_exclusao = []
    }
    if (notificao_email != null) {
      this.tags_notificao_email = notificao_email.split(',')
    } else {
      this.tags_notificao_email = []
    }
    this.weekDays = notificao_dia
    this.hours = notificao_hora
    this.popularCompradoresStore(comprador, comprador_exclusao)
  }

  @action changeSelectedPortal = portal => {
    let listaAtual
    switch (portal) {
      case 'ComprasNet':
        listaAtual = this.compradoresComprasNet
        break
      case 'Licitações-e':
        listaAtual = this.compradoresLicitacoesE
        break
      default:
        return
    }
    this.compradoresListaAtual = listaAtual
    this.selectedPortal = portal
    this.houveAlteracao = true
  }

  @action changePortal = () => (this.portal = $('#portais-select').val())

  @action changePortals = portal => {
    this.portal = this.portal.includes(portal)
      ? this.portal.filter(el => el !== portal)
      : [...this.portal, portal]
    this.houveAlteracao = true
  }

  @action changeModalidade = () =>
    (this.modalidade = $('#modalidade-select').val())

  @action changeFileName = name => {
    this.nomeacaoEditais = name.slice(1)
    this.houveAlteracao = true
  }

  @action changeMateriaisServicos = ({ target: { value } }) => {
    this.materiaisServicos = value
  }

  @action
  handleDay = day => {
    if (this.weekDays.includes(day))
      this.weekDays = this.weekDays.filter(el => el !== day)
    else this.weekDays = [...this.weekDays, day]
    this.houveAlteracao = true
  }

  @action
  handleHour = hour => {
    if (this.hours.includes(hour))
      this.hours = this.hours.filter(el => el !== hour)
    else this.hours = [...this.hours, hour]
    this.houveAlteracao = true
  }

  @action handleSwitch = () => {
    if (this.selectedPortal === 'ComprasNet')
      this.exclusaoComprasNet = !this.exclusaoComprasNet
    else this.exclusaoLicitacoesE = !this.exclusaoLicitacoesE
  }

  handleAllSelect = () => {
    this.estados.length === 27
      ? (this.estados = [])
      : (this.estados = this.todosEstados.map(el => el.sigla))
    this.houveAlteracao = true
  }

  @action changeState = sigla => {
    this.houveAlteracao = true
    if (sigla === 'TODOS')
      return (this.estados = this.todosEstados.map(es => es.sigla))
    if (sigla === 'NENHUM') return (this.estados = [])
    const jaInclui = this.estados.includes(sigla)

    if (jaInclui) {
      this.estados = this.estados.filter(sta => sta !== sigla)
    } else {
      this.estados = [...this.estados, sigla]
    }
  }

  @action adicionarTypeEmpresa = type => {
    this.empresa_me = [...this.empresa_me, type]
    this.houveAlteracao = true
  }

  @action removerTypeEmpresa = type => {
    this.empresa_me =
      this.empresa_me.filter(item_type => {
        return item_type !== type
      }) || []
    this.houveAlteracao = true
  }

  @action handleFiltroMe = ({ target: { value } }) => {
    this.empresa_me = this.empresa_me.includes(value)
      ? this.empresa_me.filter(item => item !== value)
      : [...this.empresa_me, value]
    this.houveAlteracao = true
  }

  @action onChangeFiltro = ({ target: { value } }) => {
    this.houveAlteracao = true
    this.empresa_me = value === 'FILTRAR ME/EPP' ? [] : value
  }

  onChange(event, sigla, i) {
    this.houveAlteracao = true
    this.handleCheck(event, i)
    if (event.target.checked) {
      const estados = this.estados
      const itemExist = estados.includes(sigla)
      if (!itemExist) {
        estados.push(sigla)
      }
    } else {
      const estados = this.estados.filter(estados => {
        return estados !== sigla
      })
      this.estados = estados
    }
  }

  @action consulta_api = async () => {
    this.isFetching = false
    ModalLoadingStore.handleOpenModal()
    try {
      const res = await Api.get(
        `portal/configuracao_novo/${MaquinaDeEstadosStore.getIdEmpresa()}`
      )
      const { perfis_configuracao } = res.data
      this.listaDePerfis = perfis_configuracao
    } catch (err) {
      this.listaDePerfis = []
    } finally {
      this.isFetching = true
      ModalLoadingStore.handleCloseModal()
    }
  }

  validationStates = () => {
    return !(
      this.estados.length === 0 ||
      this.hours.length === 0 ||
      this.weekDays.length === 0 ||
      this.tags_notificao_email.length === 0
    )
  }

  popularCompradoresStore = (filtrados, excluidos) => {
    const listaDeFiltrados = filtrados.map(el => ({
      ...el,
      portal: String(el.portal)
    }))

    const listaDeExcluidos = excluidos.map(el => ({
      ...el,
      portal: String(el.portal)
    }))

    CompradoresStore.listaDeFiltrados = listaDeFiltrados
    CompradoresStore.responseFiltrados = listaDeFiltrados
    CompradoresStore.listaDeExcluidos = listaDeExcluidos
    CompradoresStore.responseExcluidos = listaDeExcluidos
  }

  @action salva_api = () => {
    if (!this.validationStates())
      return AlertaError(
        'Os campos de email, agendamento e estados não podem ficar em branco'
      )
    const editingName = document.getElementById('name-perfi')
    if (editingName && editingName.value.length > 15)
      return AlertaError(
        'O número máximo de caracteres para o nome do perfil é 15!'
      )

    if (this.empresa_me.length === 0)
      return AlertaError('Selecione um dos tipos de filtro para ME/EPP')

    // if (!this.portal.length) return AlertaError("Selecione ao menos um portal");
    if (this.novoPerfil) return this.criarNovoPerfil()

    let palavra_classificao = this.tags_palavra_classificao.toString()
    let palavra_exclusao = this.tags_palavra_exclusao.toString()
    let notificao_email = this.tags_notificao_email.toString()
    let empresa_me = this.empresa_me

    if (palavra_exclusao === '') {
      palavra_exclusao = null
    }
    if (palavra_classificao === '') {
      palavra_classificao = null
    }
    if (notificao_email === '') {
      notificao_email = null
    }
    if (typeof empresa_me === 'string') empresa_me = [empresa_me]
    ModalLoadingStore.handleOpenModal()

    let materialsAndServices

    materialsAndServices = this.materiaisServicos
    if (this.materiaisServicos.length === 3)
      materialsAndServices = this.materiaisServicos.split(',')
    if (
      this.materiaisServicos.length === 1 &&
      typeof this.materiaisServicos === 'string'
    ) {
      materialsAndServices = [this.materiaisServicos]
    }

    Api.post('portal/configuracao_novo/', {
      id: this.id,
      notificao_email: notificao_email,
      notificao_dia: this.weekDays,
      notificao_hora: this.hours,
      estados: this.estados,
      palavra_classificao: palavra_classificao,
      palavra_exclusao: palavra_exclusao,
      portal: this.portal.map(el => Number(el)),
      modalidade: this.modalidade.map(el => Number(el)),
      empresa_me: empresa_me,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      comprador: CompradoresStore.listaDeFiltrados.map(el => el.id),
      comprador_exclusao: CompradoresStore.listaDeExcluidos.map(el => el.id),
      nomeacao_download: this.nomeacaoEditais,
      tipo_item: materialsAndServices,
      nome_perfil: this.nomePerfil,
      area: this.area
    })
      .then(res => {
        const { perfis_configuracao } = res.data
        this.listaDePerfis = this.listaDePerfis.map(per =>
          per.id === perfis_configuracao.id ? perfis_configuracao : per
        )
        ToastSuccess('Configurações salvas!')
        this.houveAlteracao = false
      })
      .catch(err => {
        AlertaError('Erro ao salvar os dados.')
      })
      .finally(() => ModalLoadingStore.handleCloseModal())
    this.handleChangeInput_palavra_exclusao('')
    this.handleChangeInput_palavra_classificao('')
    this.handleChangeInput_notificao_email('')
  }

  criarNovoPerfil = () => {
    let palavra_classificao = this.tags_palavra_classificao.toString()
    let palavra_exclusao = this.tags_palavra_exclusao.toString()
    let notificao_email = this.tags_notificao_email.toString()
    let empresa_me = this.empresa_me

    if (palavra_exclusao === '') {
      palavra_exclusao = null
    }
    if (palavra_classificao === '') {
      palavra_classificao = null
    }
    if (notificao_email === '') {
      notificao_email = null
    }
    if (typeof empresa_me === 'string') empresa_me = [empresa_me]
    ModalLoadingStore.handleOpenModal()
    Api.post('portal/configuracao/', {
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      notificao_email: notificao_email,
      notificao_dia: this.weekDays,
      notificao_hora: this.hours,
      estados: this.estados,
      palavra_classificao: palavra_classificao,
      palavra_exclusao: palavra_exclusao,
      portal: this.portal.map(el => Number(el)),
      modalidade: this.modalidade.map(el => Number(el)),
      empresa_me: empresa_me,
      comprador: CompradoresStore.listaDeFiltrados.map(el => el.id),
      comprador_exclusao: CompradoresStore.listaDeExcluidos.map(el => el.id),
      nomeacao_download: this.nomeacaoEditais,
      tipo_item:
        typeof this.materiaisServicos !== 'object'
          ? this.materiaisServicos.split(',')
          : this.materiaisServicos,
      nome_perfil: this.nomePerfil,
      area: this.area
    })
      .then(res => {
        const { perfis_configuracao } = res.data
        this.listaDePerfis = [...this.listaDePerfis, perfis_configuracao]
        AlertaSuccess('Perfil criado!')
        this.houveAlteracao = false
        this.novoPerfil = false
      })
      .catch(err => {
        AlertaError('Erro ao salvar os dados.')
      })
      .finally(() => ModalLoadingStore.handleCloseModal())
    this.handleChangeInput_palavra_exclusao('')
    this.handleChangeInput_palavra_classificao('')
    this.handleChangeInput_notificao_email('')
  }

  @action
  altera_chave_classificacao(text) {
    this.palavra_classificao = text
    this.houveAlteracao = true
  }

  @action
  altera_chave_exclusao(text) {
    this.palavra_exclusao = text
    this.houveAlteracao = true
  }

  @action changeNomePerfil = nome => {
    this.houveAlteracao = true
    this.nomePerfil = nome
  }

  @action
  handleChange_notificao_email = tags => {
    let emailRegExp = /^(([^<;>;()[\]\\.,;:\s@"]+(\.[^<;>;()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    if (tags.length !== 0) {
      if (emailRegExp.test(tags[tags.length - 1])) {
        this.tags_notificao_email = tags
      } else {
        AlertaError('Email inválido')
      }
    } else {
      this.tags_notificao_email = tags
    }
    this.houveAlteracao = true
  }

  @action
  handleChangeInput_notificao_email = tag => {
    this.tag_notificao_email = tag
    this.houveAlteracao = true
  }

  @action
  handleChange_palavra_exclusao = tags => {
    this.tags_palavra_exclusao = tags
    this.houveAlteracao = true
  }
  @action
  handleChangeInput_palavra_exclusao = tag => {
    this.tag_palavra_exclusao = tag
    this.houveAlteracao = true
  }

  @action
  handleChange_palavra_classificao = tags => {
    this.tags_palavra_classificao = tags
    this.houveAlteracao = true
  }

  @action
  handleChangeInput_palavra_classificao = tag => {
    this.tag_palavra_classificao = tag
    this.houveAlteracao = true
  }

  @action limparVariaveis = () => {
    this.id = ''
    this.notificao_email = ''
    this.houveAlteracao = false
    this.tag_notificao_email = null
    this.tags_notificao_email = []
    this.tags_palavra_exclusao = []
    this.tag_palavra_exclusao = ''
    this.tags_palavra_classificao = []
    this.tag_palavra_classificao = ''

    this.empresa_me = 'Todos'

    this.isFetching = false
    this.defaultValue = ''

    this.palavra_classificao = ''
    this.palavra_exclusao = ''
    //FILTRO COMPRADORES

    //DIAS DA SEMANA
    this.weekDays = []
    this.allWeekDays = ['SEG', 'TER', 'QUA', 'QUI', 'SEX']

    //HORARIOS
    this.hours = []
    this.allHours = ['7', '9', '11', '13', '15', '17', '19']

    // NOMEAÇÃO ARQUIVOS
    this.nomeacaoEditais = ''

    // MODALIDADE
    this.modalidade = []

    // FILTRO MATERIAL/SERVIÇOS
    this.materiaisServicos = ['M', 'S']

    //FILTRO ESTADOS
    this.estados = []
    this.todosEstados = [
      { nome: 'Acre', sigla: 'AC' },
      { nome: 'Alagoas', sigla: 'AL' },
      { nome: 'Amapá', sigla: 'AP' },
      { nome: 'Amazonas', sigla: 'AM' },
      { nome: 'Bahia', sigla: 'BA' },
      { nome: 'Ceará', sigla: 'CE' },
      { nome: 'Distrito Federal', sigla: 'DF' },
      { nome: 'Espírito Santo', sigla: 'ES' },
      { nome: 'Goiás', sigla: 'GO' },
      { nome: 'Maranhão', sigla: 'MA' },
      { nome: 'Mato Grosso', sigla: 'MT' },
      { nome: 'Mato Grosso do Sul', sigla: 'MS' },
      { nome: 'Minas Gerais', sigla: 'MG' },
      { nome: 'Pará', sigla: 'PA' },
      { nome: 'Paraíba', sigla: 'PB' },
      { nome: 'Paraná', sigla: 'PR' },
      { nome: 'Pernambuco', sigla: 'PE' },
      { nome: 'Piauí', sigla: 'PI' },
      { nome: 'Rio de Janeiro', sigla: 'RJ' },
      { nome: 'Rio Grande do Norte', sigla: 'RN' },
      { nome: 'Rio Grande do Sul', sigla: 'RS' },
      { nome: 'Rondônia', sigla: 'RO' },
      { nome: 'Roraima', sigla: 'RR' },
      { nome: 'Santa Catarina', sigla: 'SC' },
      { nome: 'São Paulo', sigla: 'SP' },
      { nome: 'Sergipe', sigla: 'SE' },
      { nome: 'Tocantins', sigla: 'TO' }
    ]
    CompradoresStore.limparVariaveis()
  }

  @action trocouDeEmpresa = () => {
    this.limparVariaveis()
    this.consulta_api()
  }
}

const configuracaoeditaisstore = new ConfiguracaoEditaisStore()
export default configuracaoeditaisstore

export const ConfiguracaoEditaisContext = createContext(
  new ConfiguracaoEditaisStore()
)
