import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import { Edit } from "react-feather";
import {
  ModalContainer,
  SaveButton
} from "../../../../../Pages/GeneralConfiguration/modalLateral/styles";
import { FormControl, FormGroup, Label } from "react-bootstrap";
import {closeModal, closeModalOutSide, ModalLateral, ModalProvider} from "../../../../newModal";

const ModalEditarItem = ({ itens }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minimo, setMinimo] = useState(itens.valor_minimo);
  const [descMinimo, setDescMinimo] = useState(itens.valor_desconto_minimo);
  const [descMaximo, setDescMaximo] = useState(itens.valor_desconto_maximo);
  const [animation, setAnimation] = useState(false);

  const {
    id,
    licitacao_id,
    codigo,
    ipg_cod,
    descricao_completa,
    grupo,
    quantidade
  } = itens;
  return (
    <ModalProvider>
      <Edit
        color={isModalOpen ? "#007900" : "#3A566E"}
        data-tip="ITEM"
        data-place="bottom"
        data-type="info"
        data-effect="solid"
        onClick={() => setIsModalOpen(true)}
      />

      {isModalOpen && (
        <ModalLateral
          icon="sticky-note"
          title={"CONFIGURAR VALORES ITENS"}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <div style={{ padding: "5px", height: "auto" }}>
              <FormGroup>
                <Label>
                  <b>Desconto Mínimo</b>
                </Label>
                <FormControl
                  type={"number"}
                  onChange={e => setDescMinimo(e.target.value)}
                  value={descMinimo}
                  placeholder="R$ 0,00"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <b>Desconto Máximo</b>
                </Label>
                <FormControl
                  value={descMaximo}
                  onChange={e => setDescMaximo(e.target.value)}
                  type={"number"}
                  placeholder="R$ 0,00"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <b>Valor Minimo</b>
                </Label>
                <FormControl
                  value={minimo}
                  onChange={e => setMinimo(e.target.value)}
                  type={"number"}
                  placeholder="R$ 0,00"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </FormGroup>

              <BtnSalvar
                minimo={minimo}
                descMaximo={descMaximo}
                descMinimo={descMinimo}
                id={id}
                licitacao_id={licitacao_id}
              />

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  background: "#a5a5a5",
                  marginTop: "20px",
                  marginBottom: "20px"
                }}
              ></div>
              <div
                style={{
                  height: "auto",
                  width: "auto",
                  whiteSpace: "normal",
                  overflow: "auto"
                }}
              >
                <div style={{ marginBottom: "10px" }}>Codigo: {codigo}</div>
                <div style={{ marginBottom: "10px" }}>Grupo: {grupo}</div>
                <div style={{ marginBottom: "10px" }}>
                  Quantidade: {quantidade}
                </div>
                <div style={{ marginBottom: "10px" }}>Ipg cod: {ipg_cod}</div>
                <div style={{ marginBottom: "10px" }}>
                  <div>
                    <b>Descricao Completa:</b>
                  </div>
                  <div style={{ textAlign: "justify" }}>
                    {descricao_completa}
                  </div>
                </div>
              </div>
            </div>
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  );
};

const BtnSalvar = inject("LicitacaoStores")(
  observer(props => {
    const { editarItem } = props.LicitacaoStores;
    const { descMinimo, minimo, descMaximo, licitacao_id, id } = props;
    return (
      <SaveButton
        onClick={() =>
          editarItem(descMinimo, minimo, descMaximo, licitacao_id, id)
        }
        type="submit"
        className="icon-scale-xs"
      >
        SALVAR
      </SaveButton>
    );
  })
);

//
// const CompanyInfo = inject("StoreConfiguracoesGerais", "StoreConfiguracaoEmpresas")(
//   observer(props => {const {onChange, adminValidation, verificaAdmin, validarAdmin} = props.StoreConfiguracaoEmpresas;
//
//
//     const { toggleModal } = props;
//
//     const handleSubmit = (e, validate) => {
//       e.preventDefault();
//       if (!validate) return validarAdmin("adicionarEmpresa");
//       return verificaAdmin("adicionarEmpresa", toggleModal);
//     };
//
//     if (adminValidation)
//       return (
//         <UserForm onSubmit={e => handleSubmit(e, true)}>
//           <h5 style={{ textAlign: "center" }}>
//             {MaquinaDeEstadosStore.getEmailUser()}
//           </h5>
//           <UsuariosInputConstumizado
//             titulo={"Digite a senha do administrador"}
//             id={"inputAdminPw"}
//             placeholder={"senha..."}
//             type={"password"}
//             onChange={onChange}
//           />
//           <RedefinirSenha onClick={() => validarAdmin("fecharModal")}>
//             Voltar
//           </RedefinirSenha>
//           <SaveButton type="submit" className="icon-scale-xs">
//             SALVAR
//           </SaveButton>
//         </UserForm>
//       );
//
//     return (
//       <UserForm onSubmit={handleSubmit}>
//         <FormGroup>
//           <SelectUsersCustomizado
//             id="usuarios_id"
//             onChange={onChange}
//             defaultValue={[0]}
//             users={usuarios}
//             titulo="Selecione os usuários autorizados"
//           />
//           <EmpresaInputConstumizado
//             titulo={"Razão social"}
//             id={"razao_social"}
//             placeholder={"Insira a razão social da empresa.."}
//             type={"text"}
//             onChange={onChange}
//           />
//
//           <EmpresaInputConstumizado
//             titulo={"Nome fantasia"}
//             id={"nome_fantasia"}
//             placeholder="Insira o nome fantasia da empresa.."
//             type={"text"}
//             onChange={onChange}
//           />
//
//           <CnpjInputCustomizado
//             titulo="CNPJ"
//             id="cnpj"
//             placeholder="Insira seu CNPJ"
//             onChange={onChange}
//           />
//
//           <TelefoneInputCustomizado
//             titulo="Telefone"
//             id="phone"
//             onChange={onChange}
//             // defaultValue={phone}
//             placeholder="Insira seu telefone"
//           />
//         </FormGroup>
//         <SaveButton type="submit" className="icon-scale-xs">
//           SALVAR
//         </SaveButton>
//       </UserForm>
//     );
//   })
// );

export default ModalEditarItem;
