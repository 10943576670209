import styled from "styled-components";

export const UserForm = styled.form`
  height: 100%;
  overflow: auto;
  select {
    overflow: auto;
  }
`;

export const SaveButton = styled.button`
  border-radius: 7px;
  border: none;
  padding: 3px 15px;
  background-color: #45994b;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  box-shadow: 1px 1px 7px 0px #00000080;

  :focus {
    outline: 0;
  }
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 7px 0px #00000040;
  white-space: normal;
`;

export const RedefinirSenha = styled.small`
  color: #3a566e;
  cursor: pointer;
`;
