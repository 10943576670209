import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Printer } from 'react-feather'
import { Container, ContainerMenu, TabLista } from './styles'
import SubHeader from '../../Prospection/SubHeader/SubHeader'
import SectionTitle from '../../Prospection/SubHeader/SectionTitle'
import Declaracao from './Declaracao'
import DadosEmpresa from './DadosEmpresa'

const Configuracoes = inject('ImprimirPropostaStore')(
  observer(({ ImprimirPropostaStore }) => {
    const {
      validation,
      toggleModalImprimir,
      salvarDados,
      handleSelectedImage,
      buscarDados,
      getDeclaracao,
      declaracao,
      salvarDeclaracao
    } = ImprimirPropostaStore
    return (
      <ConfiguracaoGeral
        salvarDeclaracao={salvarDeclaracao}
        declaracao={declaracao}
        getDeclaracao={getDeclaracao}
        validation={validation}
        toggleModal={toggleModalImprimir}
        salvarDados={salvarDados}
        handleSelectedImage={handleSelectedImage}
        buscarDados={buscarDados}
        {...ImprimirPropostaStore}
      />
    )
  })
)

const ConfiguracaoGeral = props => {
  const [state, setState] = useState('dados')
  return (
    <>
      <SubHeader>
        <SectionTitle title="CONFIGURAÇÃO DA PROPOSTA" icon={<Printer />} />
      </SubHeader>
      <ContainerMenu>
        <TabLista active={state === 'dados'} onClick={() => setState('dados')}>
          DADOS DA EMPRESA
        </TabLista>
        <TabLista
          active={state === 'descricao'}
          onClick={() => setState('descricao')}
        >
          DECLARAÇÃO DA PROPOSTA
        </TabLista>
      </ContainerMenu>
      <Container>
        {state === 'dados' && <DadosEmpresa props={props} />}
        {state === 'descricao' && <Declaracao props={props} />}
      </Container>
    </>
  )
}

export default Configuracoes
