import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: stretch;
  flex-basis: auto;
`;

export const Div = styled.div`
  color: #f44337;
  font-size: 13px;
`;

export const DivReturnoLogin = styled.div`
  color: #0291C6;
  font-size: 13px; 
  margin-top: 10px;
`;

export const DivReCaptcha = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const Box = styled.div`
  height: auto;
  width: 600px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 10px;
  background: #ffff;

  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 15px -6px rgba(0, 0, 0, 0.75);
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  flex-basis: auto;
`;
