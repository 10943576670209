import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { CardMsg } from "../../globalStyles";
import { Award } from "react-feather";
import TagsInput from "react-tagsinput";
import { ConfiguracaoEditaisContext } from "./stores/store";

const PalavraChaveClassificacao = observer(() => {
  const {
    handleChangeInput_palavra_classificao,
    handleChange_palavra_classificao,
    tag_palavra_classificao,
    tags_palavra_classificao
  } = useContext(ConfiguracaoEditaisContext);
  return (
    <CardMsg minHeight={"15vh"} flexDirection={"column"} height="150px">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", textAlign: "center" }}>
          <Award color={"green"} />
          &nbsp;&nbsp;
        </div>
        <div style={{ padding: "0px 10px 0px 10px", textAlign: "start" }}>
          <div>PALAVRAS-CHAVE CLASSIFICAÇÃO</div>
          <div style={{ fontSize: "10px" }}>
            Definir as palavras-chave que serão utilizadas para filtrar os
            editais.
          </div>
        </div>
      </div>
      <TagsInput
        classNameRemove={"react-tagsinput-remove"}
        className="tagsinputblul"
        inputProps={{
          /* className: 'palavras-tagsinput', */ placeholder:
            "Adicionar palavra"
        }}
        value={tags_palavra_classificao.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
        onChange={e => handleChange_palavra_classificao(e)}
        inputValue={tag_palavra_classificao}
        onChangeInput={e => handleChangeInput_palavra_classificao(e)}
      />
    </CardMsg>
  );
});

export default PalavraChaveClassificacao;
