import React, { useState } from 'react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/newModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardBody, CardHeader } from '../../../globalStyles'
import { inject, observer } from 'mobx-react'
import { FormControl } from 'react-bootstrap'
import { FiltroPeriodoContainer, SingleOption, ContainerFiltro } from './styles'
import { Filter } from 'react-feather'
import { DatePicker } from './DatePicker/DatePicker'

function ModalFiltros({ observacao, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  return (
    <ModalProvider monitoramento>
      <div
        onClick={() => setIsModalOpen(true)}
        className="icon-scale"
        data-tip="FILTROS"
        data-type="light"
        data-effect="solid"
        data-place="bottom"
        style={{ width: '50px', display: 'flex', justifyContent: 'center' }}
      >
        <FontAwesomeIcon
          icon="filter"
          style={{ color: '#fff', fontSize: '16px' }}
        />
      </div>

      {isModalOpen && (
        <ModalLateral
          icon="filter"
          title={'FILTROS'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
          left
          overflowVisible
        >
          <FiltroPortais />
          <FiltroPeriodo toggleModal={setIsModalOpen} />
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const FiltroPortais = inject('MonitoramentoStore')(
  observer(({ MonitoramentoStore }) => {
    const { portal, changePortal, todosPortais } = MonitoramentoStore
    return (
      <Card style={{ color: '#3a566e' }}>
        <CardHeader>PORTAIS</CardHeader>
        <div
          className="d-flex flex-column"
          style={{
            alignItems: 'flex-start',
            margin: '0.5rem 0.5rem 0',
            fontSize: '13px'
          }}
        >
          <b>Selecione os portais que deseja filtrar as mensagens.</b>
          <small>
            Utilize <i>ALT</i>, <i>CTRL</i> e <i>SHIFT</i> para facilitar a
            seleção.
          </small>
        </div>
        <CardBody linha={true}>
          <FormControl
            defaultValue={portal}
            onChange={changePortal}
            name="portais"
            id="portais-select"
            componentClass="select"
            placeholder="select"
            multiple
          >
            {todosPortais.map(port => (
              <option value={port.id} selected={portal.includes(port.id)}>
                {port.nome}
              </option>
            ))}
          </FormControl>
        </CardBody>
      </Card>
    )
  })
)

const FiltroPeriodo = inject('MonitoramentoStore')(
  observer(({ MonitoramentoStore, toggleModal }) => {
    const {
      periodoSelecionado,
      dataInicial,
      dataFinal,
      showDatePicker,
      handleSelect,
      dateShowing,
      qualData,
      handlePeriodo,
      consultaApi
    } = MonitoramentoStore

    const handleSubmit = () => {
      // MonitoramentoStore.radio_licita = "todas";
      consultaApi()
      toggleModal(false)
    }

    return (
      <ContainerFiltro>
        <FiltroPeriodoContainer>
          <div className="title-container">
            <Filter size="45px" className="mb-3" />
            <h5>FILTRO POR PERÍODO</h5>
          </div>
          <div className="options-container">
            <SingleOption
              className="icon-scale-xs"
              selected={periodoSelecionado === 'TODAS'}
              onClick={() => handlePeriodo('TODAS')}
            >
              TODAS
            </SingleOption>
            <SingleOption
              className="icon-scale-xs"
              selected={periodoSelecionado === 'DIA'}
              onClick={() => handlePeriodo('DIA')}
            >
              DIA
            </SingleOption>
            <SingleOption
              className="icon-scale-xs"
              selected={periodoSelecionado === 'SEMANA'}
              onClick={() => handlePeriodo('SEMANA')}
            >
              SEMANA
            </SingleOption>
            <SingleOption
              className="icon-scale-xs"
              selected={periodoSelecionado === 'MES'}
              onClick={() => handlePeriodo('MES')}
            >
              MÊS
            </SingleOption>
            <SingleOption
              className="icon-scale-xs"
              selected={periodoSelecionado === 'ESPECÍFICA'}
              onClick={() => handlePeriodo('ESPECÍFICA')}
            >
              POR DATA
            </SingleOption>
          </div>
          <div className="submit-container">
            <button
              onClick={handleSubmit}
              id="aplicar-filtro-monitoramento"
              className="icon-scale-xs"
            >
              APLICAR
            </button>
          </div>
        </FiltroPeriodoContainer>
        <div
          className="date-input-container"
          style={{ opacity: periodoSelecionado !== 'ESPECÍFICA' ? '0.5' : 1 }}
        >
          <div className="date-input">
            <div className="box">
              <FontAwesomeIcon icon="calendar-alt" />
            </div>
            <input
              type="text"
              name="dataInicial"
              onClick={showDatePicker}
              value={dataInicial}
              className="input"
              disabled={periodoSelecionado !== 'ESPECÍFICA'}
            />
          </div>
          <div className="date-input">
            <div className="box">
              <FontAwesomeIcon icon="calendar-alt" />
            </div>
            <input
              type="text"
              name="dataFinal"
              value={dataFinal}
              className="input"
              disabled={periodoSelecionado !== 'ESPECÍFICA'}
            />
          </div>
        </div>
        <DatePicker
          showing={dateShowing}
          onClick={showDatePicker}
          qualData={qualData}
          handleSelect={handleSelect}
        />
      </ContainerFiltro>
    )
  })
)

export default ModalFiltros
