import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

export const DoubleSwitch = withStyles({
  switchBase: {
    color: "rgb(71,153,16)",
    "&$checked": {
      color: "#3f51b5"
    },
    "&$checked + $track": {
      backgroundColor: "#3f51b5",
      opacity: 0.38
    },
    "& + $track": {
      opacity: 0.38,
      backgroundColor: "rgb(71,153,16)"
    }
  },
  checked: {},
  track: {}
})(Switch);

export const ColorLogoAzulEscruto = "#0A3E5D";
export const ColorLogoAzulPadrao = "#1E7AA7";
export const ColorLogoAzulClaro = "#EFF5F8";

export const ColorWarning = "#FF8800";
export const ColorWarningLight = "#ffbb33";

export const ColorDangerDark = "#6d0000";
export const ColorDanger = "#CC0000";
export const ColorDangerLight = "#ff4444";

export const ColorSuccessDark = "#004821";
export const ColorSuccess = "#007E33";

export const ColorInfo = "#0099CC";
export const ColorInfoLight = "#33b5e5";

export const ColorPrimarDark = "#0A3E5D";
export const ColorPrimary = "#286cd6";
export const ColorPrimaryLight = "#4285F4";

export const ColorDefalut = "#00695c";

export const ColorSecondary = "#9933CC";
export const ColorSecondaryLight = "#aa66cc";

export const ColorBackgrounLigth = "#E8E8E8";

export const Container = styled.div`
  height: auto;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
`;

export const WrapperCol = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => (props.background ? props.background : "white")};
  border-radius: 15px;
  height: auto;
  padding: 20px;
  width: 100%;
  flex-direction: column;

  :hover {
    background-color: ${props =>
      props.hoverBgColor ? props.hoverBgColor : "#fff"};
  }
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const Badge = styled.span`
  color: ${props => (props.color === undefined ? "white" : props.color)};
  background-color: ${props =>
    props.background === undefined ? "#286cd6" : props.background};
  font-size: ${props =>
    props.fontSize === undefined ? "12px" : props.fontSize};
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-right: ${props =>
    props.marginRight === undefined ? "4px" : props.marginRight};
  margin-left: ${props =>
    props.marginLeft === undefined ? "4px" : props.marginLeft};
  height: fit-content;
`;

export const Tr = styled.tr`
  text-align: center;
  font-weight: normal;
  :hover {
    font-size: 13px;
    background-color: #e1e3e4;
  }
`;

export const BollEmpresa = styled.div`
  border-radius: 10%;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  height: ${props => props.height};
  width: auto;

  background-color: ${props => props.background};

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.color};
  }

  :hover {
    height: 28px;
    width: 28px;
    font-size: 18px;
    background-color: ${props =>
      props.background === ColorDanger
        ? ColorDangerDark
        : props.background === ColorSuccess
        ? ColorSuccessDark
        : props.background === ColorPrimary
        ? ColorPrimarDark
        : null};
  }
`;

export const Boll = styled.div`
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  height: ${props => props.height};
  width: ${props => props.width};

  background-color: ${props => props.background};

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.color};
  }

  :hover {
    height: 28px;
    width: 28px;
    font-size: 18px;
    background-color: ${props =>
      props.background === ColorDanger
        ? ColorDangerDark
        : props.background === ColorSuccess
        ? ColorSuccessDark
        : props.background === ColorPrimary
        ? ColorPrimarDark
        : null};
  }
`;
