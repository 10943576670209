import React, { useRef, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import * as S from './Style'

const PerfilSelection = inject('BoletimStore')(
  observer(({ BoletimStore }) => {
    const { profiles, fetchEditaisSelection, profilesId, setIdProfile } = BoletimStore
    return (
      <PerfilSelectionComStore profiles={profiles} setIdProfile={setIdProfile} profilesId={profilesId} fetchEditaisSelection={fetchEditaisSelection} />
    )
  })
)

const PerfilSelectionComStore = ({ profiles, fetchEditaisSelection, profilesId, setIdProfile }) => {
  const [width, setWidth] = useState(0)

  const ref = useRef()

  const handleChange = e => {
    fetchEditaisSelection(e.target.value)
  }

  useEffect(() => {
    const wid = ref.current.offsetWidth / 2
    if (wid !== width) setWidth(wid)
  }, [profiles])

  console.log(profiles, profiles.length)

  return (
    <>
      {profiles.length >= 6 || window.innerWidth < 1000 ? (
        <S.Container
          ref={ref}
          left={`calc(50% - ${width}px)`}
          margin="10px"
        >
          <select onChange={handleChange}>
            {profiles.map(profile => (
              <option value={profile.id} selected={profile.activated}>
                {profile.nome_perfil} &#x28;{profile.qtd_edital}{' '}
                {profile.qtd_edital === 0 ? 'edital' : 'editais'}&#x29;
              </option>
            ))}
          </select>
        </S.Container>
      ) : (
        <S.Container ref={ref} left={`calc(50% - ${width}px)`}>
          {profiles.map(perfil => {
            return (
              <S.SinglePerfil
                key={perfil.id}
                active={perfil.activated}
                onClick={() => fetchEditaisSelection(perfil.id, perfil)}
              >
                {perfil.nome_perfil}
                <div className="counter">{perfil.qtd_edital}</div>
              </S.SinglePerfil>
            )
          })}
        </S.Container>
      )}
    </>
  )
}

export default PerfilSelection
