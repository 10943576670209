import React from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Calendar, Hexagon } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

const FiltroTipoData = () => {
  const { onChangeTipoDate, state: { tipoDate } } = useFiltroEdital()
  const currencies = [
    {
      value: 'disputa',
      label: 'Data de disputa',
    },
    {
      value: 'abertura',
      label: 'Data de abertura',
    },
    {
      value: 'publicacao',
      label: 'Data de publicação',
    },
    {
      value: 'classificacao',
      label: 'Data de Classificação',
    },
  ];
  return (
    <CardFiltroSmall>
      <CardHeard>
        <Hexagon />
        <CardTitulo>
          <div>TIPO DE DATA</div>
          <p>Selecione tipo de data: disputa ou publicação</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <TextField
          id="standard-multiline-flexible"
          label="Tipo de data"
          select
          variant="outlined"
          required
          margin="normal"
          value={tipoDate}
          fullWidth
          onChange={(e) => onChangeTipoDate(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Calendar />
              </InputAdornment>
            ),
          }}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>


      </CardBody>
    </CardFiltroSmall>
  )
}

export default FiltroTipoData
