import { observable, action } from 'mobx'
import Api from './Conexao/conexao'
import ModalLoadingStore from './ModalLoadingStore'
import { AlertaVerde, AlertaVermelho } from './Funcoes/FuncoesGerais'
import LicitacoesPaginadaStore from './LicitacoesPaginadaStore'
import MaquinaDeEstadosStore from './MaquinaDeEstadosStore'
import FunilStore from './FunilStore'

export const checkPathname = path => window.location.pathname === path

class LicitacoesExclusao {
  @observable todosPortais = [
    { id: 5, name: "ComprasNet" },
    { id: 6, name: "Licitações-e" },
    { id: 9, name: "Compras Públicas" },
    { id: 877, name: "Portal BLL" },
    { id: 930, name: "Portal BNC" }
  ]
  @observable portais = this.todosPortais.map(el => el.id)

  @observable listaExcluidas = []
  @observable totalExcluidas = 0
  @observable filtroPortal = "todos";


  @action portalHandleChange = ({ target: { value } }) => {
    this.filtroPortal = value;
    if (value === "todos") {
      this.portais = this.todosPortais.map(item => item.id);
    } else {
      this.portais = [Number(value)];
    }
  };
  @action consultaExcluidas = async () => {
    const { getIdEmpresa } = MaquinaDeEstadosStore

    ModalLoadingStore.handleOpenModal()
    try {
      const res = await Api.get(`/licitacoes/licita_lixeira/${getIdEmpresa()}`)
      const { licitacoes, licitacoes_total } = res.data
      this.listaExcluidas = licitacoes
      this.totalExcluidas = licitacoes_total
    } catch (err) {
      AlertaVermelho('Não foi possível listar suas licitações excluídas', 3000)
    } finally {
      ModalLoadingStore.handleCloseModal()
    }
  }

  @action excluirLicitacao = async id => {
    ModalLoadingStore.handleOpenModal()
    try {
      const { licitacoesFilter } = LicitacoesPaginadaStore
      await Api.get(`/licitacoes/delete_licitacao/${id}`)
      const newLicitacoes = licitacoesFilter.filter(lic => lic.id !== id)

      if (checkPathname('/licitacoes')) {
        LicitacoesPaginadaStore.licitacoes = newLicitacoes
        LicitacoesPaginadaStore.licitacoesFilter = newLicitacoes
      }
      if (checkPathname('/funil')) await FunilStore.consultaApi()
      AlertaVerde('Licitação excluída com sucesso.', 3000)
    } catch (err) {
      AlertaVermelho('Erro ao excluír licitação', 3000)
    } finally {
      ModalLoadingStore.handleCloseModal()
    }
  }

  @action restaurarLicitacao = async id => {
    ModalLoadingStore.handleOpenModal()
    try {
      const res = await Api.get(`/licitacoes/restore_licitacao/${id}`)
      this.listaExcluidas = this.listaExcluidas.filter(lic => lic.id !== id)
      AlertaVerde('Licitação restaurada com sucesso', 3000)
    } catch (err) {
      AlertaVermelho('Erro ao restaurar licitação.', 3000)
    } finally {
      ModalLoadingStore.handleCloseModal()
    }
  }
}

export default new LicitacoesExclusao()
