import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Circle } from "react-feather";
import { ReactTooltip } from "../../../globalStyles";

@inject("LicitacoesPaginadaStore")
@observer
class CheckMonitorar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      animation: false
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      AddlicitacaoMonitorada,
      /* DelllicitacaoMonitorada, */
      togleModal
    } = this.props.LicitacoesPaginadaStore;
    const { check } = this.props.licitacao;
    const { licitacao } = this.props;
    return (
      <div>
        {check ? (
          <div>
            <div
              onClick={togleModal}
              style={{
                padding: "2px 5px",
                borderRadius: "5px",
                background: "#268ccf",
                color: "white",
                fontSize: "10px"
              }}
              data-tip="VER LISTA"
              data-place="left"
              data-type="info"
              data-effect="solid"
            >
              A CONFIRMAR
            </div>{" "}
          </div>
        ) : (
          <div onClick={() => AddlicitacaoMonitorada(licitacao)}>
            <Circle />
          </div>
        )}
      </div>
    );
  }
}

export default CheckMonitorar;
