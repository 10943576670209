import React from "react";

const IconsContratar = ({ contrato }) => {
  const renderIcons = id => {
    let editais,
      proposta,
      monitoramento = false;
    switch (id) {
      case 47: {
        editais = true;
        break;
      }
      case 52: {
        editais = true;
        proposta = true;
        break;
      }
      case 49: {
        monitoramento = true;
        break;
      }
      case 50: {
        editais = true;
        monitoramento = true;
        break;
      }
      case 51: {
        editais = true;
        monitoramento = true;
        proposta = true;
        break;
      }
      case 34: {
        editais = true;
        monitoramento = true;
        proposta = true;
        break;
      }
      default:
    }

    return {
      editais,
      proposta,
      monitoramento
    };
  };

  const { editais, proposta, monitoramento } = renderIcons(contrato);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="348"
      height="45"
      viewBox="0 0 348 45"
    >
      {/* EDITAIS */}
      <g id="Grupo_47" data-name="Grupo 47" transform="translate(-568 -445)">
        <g
          id="Elipse_1"
          data-name="Elipse 1"
          transform="translate(568 445)"
          fill={editais ? "#5e9cd0" : "#e5e7e8"}
          stroke="#fff"
          stroke-width="0"
        >
          <ellipse cx="22" cy="21.5" rx="22" ry="21.5" stroke="none" />
          <ellipse cx="22" cy="21.5" rx="21.5" ry="21" fill="none" />
        </g>
        <path
          id="Icon_awesome-paste"
          data-name="Icon awesome-paste"
          d="M5.537,7.959A2.425,2.425,0,0,1,7.959,5.537h5.883V2.422A1.038,1.038,0,0,0,12.8,1.384H9.317a2.766,2.766,0,0,0-4.792,0H1.038A1.038,1.038,0,0,0,0,2.422V16.956a1.038,1.038,0,0,0,1.038,1.038h4.5ZM6.921,1.73A1.038,1.038,0,1,1,5.883,2.768,1.038,1.038,0,0,1,6.921,1.73ZM14.88,12.458h4.5v8.651a1.038,1.038,0,0,1-1.038,1.038H7.959a1.038,1.038,0,0,1-1.038-1.038V7.959A1.038,1.038,0,0,1,7.959,6.921h5.883v4.5A1.041,1.041,0,0,0,14.88,12.458Zm4.5-1.646v.262H15.226V6.921h.262a1.038,1.038,0,0,1,.734.3l2.852,2.852A1.038,1.038,0,0,1,19.379,10.811Z"
          transform="translate(580.572 454.64)"
          fill={editais ? "#fff" : "#a3a3a3"}
        />
        <g
          id="Caminho_15"
          data-name="Caminho 15"
          transform="translate(620 445)"
          fill={editais ? "#5e9cd0" : "#e5e7e8"}
        >
          <path
            d="M 21.5 42.5 C 18.6648006439209 42.5 15.91466999053955 41.94483184814453 13.32600975036621 40.84991836547852 C 10.82540035247803 39.79225158691406 8.579520225524902 38.27801132202148 6.650760173797607 36.34923934936523 C 4.72199010848999 34.42047882080078 3.207750082015991 32.17459869384766 2.150079965591431 29.67399024963379 C 1.055170059204102 27.08532905578613 0.5 24.3351993560791 0.5 21.5 C 0.5 18.6648006439209 1.055170059204102 15.91466999053955 2.150079965591431 13.32600975036621 C 3.207750082015991 10.82540035247803 4.72199010848999 8.579520225524902 6.650760173797607 6.650760173797607 C 8.579520225524902 4.72199010848999 10.82540035247803 3.207750082015991 13.32600975036621 2.150079965591431 C 15.91466999053955 1.055170059204102 18.6648006439209 0.5 21.5 0.5 C 24.3351993560791 0.5 27.08532905578613 1.055170059204102 29.67399024963379 2.150079965591431 C 32.17459869384766 3.207750082015991 34.42047882080078 4.72199010848999 36.34923934936523 6.650760173797607 C 38.27801132202148 8.579520225524902 39.79225158691406 10.82540035247803 40.84991836547852 13.32600975036621 C 41.94483184814453 15.91466999053955 42.5 18.6648006439209 42.5 21.5 C 42.5 24.3351993560791 41.94483184814453 27.08532905578613 40.84991836547852 29.67399024963379 C 39.79225158691406 32.17459869384766 38.27801132202148 34.42047882080078 36.34923934936523 36.34923934936523 C 34.42047882080078 38.27801132202148 32.17459869384766 39.79225158691406 29.67399024963379 40.84991836547852 C 27.08532905578613 41.94483184814453 24.3351993560791 42.5 21.5 42.5 Z"
            stroke="none"
          />
          <path
            d="M 21.5 1 C 18.73208045959473 1 16.0474796295166 1.541881561279297 13.52078056335449 2.610580444335938 C 11.07978057861328 3.643039703369141 8.887321472167969 5.121299743652344 7.004310607910156 7.004310607910156 C 5.121299743652344 8.887321472167969 3.643039703369141 11.07978057861328 2.610580444335938 13.52078056335449 C 1.541881561279297 16.0474796295166 1 18.73208045959473 1 21.5 C 1 24.26791954040527 1.541881561279297 26.9525203704834 2.610580444335938 29.47922134399414 C 3.643039703369141 31.92021942138672 5.121299743652344 34.11267852783203 7.004310607910156 35.99568939208984 C 8.887321472167969 37.87870025634766 11.07978057861328 39.35696029663086 13.52078056335449 40.38941955566406 C 16.0474796295166 41.4581184387207 18.73208045959473 42 21.5 42 C 24.26791954040527 42 26.9525203704834 41.4581184387207 29.47922134399414 40.38941955566406 C 31.92021942138672 39.35696029663086 34.11267852783203 37.87870025634766 35.99568939208984 35.99568939208984 C 37.87870025634766 34.11267852783203 39.35696029663086 31.92021942138672 40.38941955566406 29.47922134399414 C 41.4581184387207 26.9525203704834 42 24.26791954040527 42 21.5 C 42 18.73208045959473 41.4581184387207 16.0474796295166 40.38941955566406 13.52078056335449 C 39.35696029663086 11.07978057861328 37.87870025634766 8.887321472167969 35.99568939208984 7.004310607910156 C 34.11267852783203 5.121299743652344 31.92021942138672 3.643039703369141 29.47922134399414 2.610580444335938 C 26.9525203704834 1.541881561279297 24.26791954040527 1 21.5 1 M 21.5 0 C 33.37411880493164 0 43 9.625881195068359 43 21.5 C 43 33.37411880493164 33.37411880493164 43 21.5 43 C 9.625881195068359 43 0 33.37411880493164 0 21.5 C 0 9.625881195068359 9.625881195068359 0 21.5 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <path
          id="Icon_awesome-search-plus"
          data-name="Icon awesome-search-plus"
          d="M13.54,8.551V9.977a.536.536,0,0,1-.534.534H10.511v2.494a.536.536,0,0,1-.534.534H8.551a.536.536,0,0,1-.534-.534V10.511H5.523a.536.536,0,0,1-.534-.534V8.551a.536.536,0,0,1,.534-.534H8.017V5.523a.536.536,0,0,1,.534-.534H9.977a.536.536,0,0,1,.534.534V8.017h2.494A.536.536,0,0,1,13.54,8.551Zm8.952,12.68-1.26,1.26a1.065,1.065,0,0,1-1.51,0l-4.445-4.441a1.068,1.068,0,0,1-.312-.757v-.726a9.26,9.26,0,1,1,1.6-1.6h.726a1.068,1.068,0,0,1,.757.312l4.441,4.441A1.078,1.078,0,0,1,22.492,21.232ZM15.321,9.264a6.057,6.057,0,1,0-6.057,6.057A6.052,6.052,0,0,0,15.321,9.264Z"
          transform="translate(629.192 454.64)"
          fill={editais ? "#fff" : "#a3a3a3"}
        />
        {/* MONITORAMENTO */}
        <g
          id="Elipse_3"
          data-name="Elipse 3"
          transform="translate(721 446)"
          fill={monitoramento ? "#5e9cd0" : "#e5e7e8"}
          stroke="#fff"
          stroke-width="0"
        >
          <ellipse cx="21.5" cy="22" rx="21.5" ry="22" stroke="none" />
          <ellipse cx="21.5" cy="22" rx="21" ry="21.5" fill="none" />
        </g>
        <path
          id="Icon_awesome-gavel"
          data-name="Icon awesome-gavel"
          d="M24.03,9.487,22.953,8.41a1.142,1.142,0,0,0-1.615,0l-.269.269L15.685,3.3l.269-.269a1.142,1.142,0,0,0,0-1.615L14.877.334a1.142,1.142,0,0,0-1.615,0L7.34,6.257a1.142,1.142,0,0,0,0,1.615L8.417,8.949a1.142,1.142,0,0,0,1.615,0l.269-.269,1.884,1.884L8.329,14.42l-.269-.269a1.523,1.523,0,0,0-2.154,0l-5.46,5.46a1.523,1.523,0,0,0,0,2.154L2.6,23.918a1.523,1.523,0,0,0,2.154,0l5.46-5.46a1.523,1.523,0,0,0,0-2.154l-.269-.269L13.8,12.179l1.884,1.884-.269.269a1.142,1.142,0,0,0,0,1.615l1.077,1.077a1.142,1.142,0,0,0,1.615,0L24.03,11.1A1.142,1.142,0,0,0,24.03,9.487Z"
          transform="translate(731.866 455.745)"
          fill={monitoramento ? "#fff" : "#a3a3a3"}
        />
        <g
          id="Elipse_4"
          data-name="Elipse 4"
          transform="translate(873 445)"
          fill={monitoramento ? "#5e9cd0" : "#e5e7e8"}
          stroke="#fff"
          stroke-width="0"
        >
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="21" fill="none" />
        </g>
        <g
          id="Grupo_4"
          data-name="Grupo 4"
          transform="translate(878.457 456.64)"
        >
          <path
            id="Icon_awesome-comments"
            data-name="Icon awesome-comments"
            d="M9.014,5.717C9.014,3.8,7,2.25,4.507,2.25S0,3.8,0,5.717A2.94,2.94,0,0,0,.823,7.71,4.877,4.877,0,0,1,.048,8.891a.172.172,0,0,0-.033.189.17.17,0,0,0,.158.1A3.815,3.815,0,0,0,2.1,8.642a5.513,5.513,0,0,0,2.412.542C7,9.184,9.014,7.632,9.014,5.717Zm2.643,4.767a2.935,2.935,0,0,0,.823-1.993,3.6,3.6,0,0,0-2.8-3.209,3.22,3.22,0,0,1,.028.436c0,2.295-2.334,4.16-5.2,4.16a6.5,6.5,0,0,1-.687-.041,4.684,4.684,0,0,0,4.154,2.121,5.483,5.483,0,0,0,2.412-.542,3.815,3.815,0,0,0,1.922.542.171.171,0,0,0,.158-.1.174.174,0,0,0-.033-.189A4.825,4.825,0,0,1,11.657,10.484Z"
            transform="translate(0 11.125)"
            fill={monitoramento ? "#fff" : "#a3a3a3"}
          />
          <path
            id="Icon_awesome-tv"
            data-name="Icon awesome-tv"
            d="M19.319,0H1.566A1.567,1.567,0,0,0,0,1.566V12.009a1.567,1.567,0,0,0,1.566,1.566h8v1.044H4.343a1.044,1.044,0,1,0,0,2.088H16.874a1.044,1.044,0,0,0,0-2.088H11.653V13.575h7.665a1.567,1.567,0,0,0,1.566-1.566V1.566A1.567,1.567,0,0,0,19.319,0ZM18.8,11.487H2.088v-9.4H18.8Z"
            transform="translate(8.246)"
            fill={monitoramento ? "#fff" : "#a3a3a3"}
          />
        </g>
        <g
          id="Caminho_14"
          data-name="Caminho 14"
          transform="translate(772 445)"
          fill={monitoramento ? "#5e9cd0" : "#e5e7e8"}
        >
          <path
            d="M 21.5 42.5 C 18.6648006439209 42.5 15.91466999053955 41.94483184814453 13.32600975036621 40.84991836547852 C 10.82540035247803 39.79225158691406 8.579520225524902 38.27801132202148 6.650760173797607 36.34923934936523 C 4.72199010848999 34.42047882080078 3.207750082015991 32.17459869384766 2.150079965591431 29.67399024963379 C 1.055170059204102 27.08532905578613 0.5 24.3351993560791 0.5 21.5 C 0.5 18.6648006439209 1.055170059204102 15.91466999053955 2.150079965591431 13.32600975036621 C 3.207750082015991 10.82540035247803 4.72199010848999 8.579520225524902 6.650760173797607 6.650760173797607 C 8.579520225524902 4.72199010848999 10.82540035247803 3.207750082015991 13.32600975036621 2.150079965591431 C 15.91466999053955 1.055170059204102 18.6648006439209 0.5 21.5 0.5 C 24.3351993560791 0.5 27.08532905578613 1.055170059204102 29.67399024963379 2.150079965591431 C 32.17459869384766 3.207750082015991 34.42047882080078 4.72199010848999 36.34923934936523 6.650760173797607 C 38.27801132202148 8.579520225524902 39.79225158691406 10.82540035247803 40.84991836547852 13.32600975036621 C 41.94483184814453 15.91466999053955 42.5 18.6648006439209 42.5 21.5 C 42.5 24.3351993560791 41.94483184814453 27.08532905578613 40.84991836547852 29.67399024963379 C 39.79225158691406 32.17459869384766 38.27801132202148 34.42047882080078 36.34923934936523 36.34923934936523 C 34.42047882080078 38.27801132202148 32.17459869384766 39.79225158691406 29.67399024963379 40.84991836547852 C 27.08532905578613 41.94483184814453 24.3351993560791 42.5 21.5 42.5 Z"
            stroke="none"
          />
          <path
            d="M 21.5 1 C 18.73208045959473 1 16.0474796295166 1.541881561279297 13.52078056335449 2.610580444335938 C 11.07978057861328 3.643039703369141 8.887321472167969 5.121299743652344 7.004310607910156 7.004310607910156 C 5.121299743652344 8.887321472167969 3.643039703369141 11.07978057861328 2.610580444335938 13.52078056335449 C 1.541881561279297 16.0474796295166 1 18.73208045959473 1 21.5 C 1 24.26791954040527 1.541881561279297 26.9525203704834 2.610580444335938 29.47922134399414 C 3.643039703369141 31.92021942138672 5.121299743652344 34.11267852783203 7.004310607910156 35.99568939208984 C 8.887321472167969 37.87870025634766 11.07978057861328 39.35696029663086 13.52078056335449 40.38941955566406 C 16.0474796295166 41.4581184387207 18.73208045959473 42 21.5 42 C 24.26791954040527 42 26.9525203704834 41.4581184387207 29.47922134399414 40.38941955566406 C 31.92021942138672 39.35696029663086 34.11267852783203 37.87870025634766 35.99568939208984 35.99568939208984 C 37.87870025634766 34.11267852783203 39.35696029663086 31.92021942138672 40.38941955566406 29.47922134399414 C 41.4581184387207 26.9525203704834 42 24.26791954040527 42 21.5 C 42 18.73208045959473 41.4581184387207 16.0474796295166 40.38941955566406 13.52078056335449 C 39.35696029663086 11.07978057861328 37.87870025634766 8.887321472167969 35.99568939208984 7.004310607910156 C 34.11267852783203 5.121299743652344 31.92021942138672 3.643039703369141 29.47922134399414 2.610580444335938 C 26.9525203704834 1.541881561279297 24.26791954040527 1 21.5 1 M 21.5 0 C 33.37411880493164 0 43 9.625881195068359 43 21.5 C 43 33.37411880493164 33.37411880493164 43 21.5 43 C 9.625881195068359 43 0 33.37411880493164 0 21.5 C 0 9.625881195068359 9.625881195068359 0 21.5 0 Z"
            stroke="none"
            fill="none"
          />
        </g>
        <g
          id="Grupo_5"
          data-name="Grupo 5"
          transform="translate(779.793 455.745)"
        >
          <path
            id="Icon_awesome-tv-2"
            data-name="Icon awesome-tv"
            d="M20.143,0H1.633A1.634,1.634,0,0,0,0,1.633V12.521a1.634,1.634,0,0,0,1.633,1.633H9.973v1.089H4.529a1.089,1.089,0,0,0,0,2.178H17.594a1.089,1.089,0,0,0,0-2.178H12.15V14.154h7.992a1.634,1.634,0,0,0,1.633-1.633V1.633A1.634,1.634,0,0,0,20.143,0ZM19.6,11.977H2.178v-9.8H19.6Z"
            transform="translate(4.482 0)"
            fill={monitoramento ? "#fff" : "#a3a3a3"}
          />
          <path
            id="Icon_awesome-gavel-2"
            data-name="Icon awesome-gavel"
            d="M11.622,4.588,11.1,4.068a.552.552,0,0,0-.781,0l-.13.13-2.6-2.6.13-.13a.552.552,0,0,0,0-.781L7.2.162a.552.552,0,0,0-.781,0L3.55,3.026a.552.552,0,0,0,0,.781l.521.521a.552.552,0,0,0,.781,0l.13-.13.911.911L4.028,6.974l-.13-.13a.737.737,0,0,0-1.042,0L.216,9.485a.737.737,0,0,0,0,1.042l1.042,1.042a.737.737,0,0,0,1.042,0L4.94,8.927a.737.737,0,0,0,0-1.042l-.13-.13L6.675,5.89l.911.911-.13.13a.552.552,0,0,0,0,.781l.521.521a.552.552,0,0,0,.781,0L11.622,5.37A.552.552,0,0,0,11.622,4.588Z"
            transform="translate(0 14.136)"
            fill={monitoramento ? "#fff" : "#a3a3a3"}
          />
        </g>
        <g
          id="Elipse_6"
          data-name="Elipse 6"
          transform="translate(822 445)"
          fill={monitoramento ? "#5e9cd0" : "#e5e7e8"}
          stroke="#fff"
          stroke-width="0"
        >
          <ellipse cx="22" cy="21.5" rx="22" ry="21.5" stroke="none" />
          <ellipse cx="22" cy="21.5" rx="21.5" ry="21" fill="none" />
        </g>
        <path
          id="Icon_awesome-comments-2"
          data-name="Icon awesome-comments"
          d="M18.034,9.186C18.034,5.354,14,2.25,9.017,2.25S0,5.354,0,9.186a5.881,5.881,0,0,0,1.647,3.988A9.757,9.757,0,0,1,.1,15.537a.345.345,0,0,0-.065.377.34.34,0,0,0,.316.208,7.633,7.633,0,0,0,3.845-1.084,11.031,11.031,0,0,0,4.825,1.084C14,16.122,18.034,13.018,18.034,9.186Zm5.289,9.537a5.873,5.873,0,0,0,1.647-3.988c0-2.9-2.319-5.384-5.605-6.42a6.442,6.442,0,0,1,.056.871c0,4.591-4.669,8.323-10.4,8.323a13,13,0,0,1-1.374-.082c1.366,2.493,4.573,4.244,8.31,4.244a10.97,10.97,0,0,0,4.825-1.084,7.633,7.633,0,0,0,3.845,1.084.342.342,0,0,0,.316-.208.347.347,0,0,0-.065-.377A9.653,9.653,0,0,1,23.322,18.723Z"
          transform="translate(831.408 454.6)"
          fill={monitoramento ? "#fff" : "#a3a3a3"}
        />
        {/* PROPOSTA */}
        <g
          id="Elipse_7"
          data-name="Elipse 7"
          transform="translate(671 445)"
          fill={proposta ? "#5e9cd0" : "#e5e7e8"}
          stroke="#fff"
          stroke-width="0"
        >
          <ellipse cx="21" cy="21.5" rx="21" ry="21.5" stroke="none" />
          <ellipse cx="21" cy="21.5" rx="20.5" ry="21" fill="none" />
        </g>
        <path
          id="Icon_awesome-file-upload"
          data-name="Icon awesome-file-upload"
          d="M9.868,5.991V0H1.057A1.055,1.055,0,0,0,0,1.057V21.5a1.055,1.055,0,0,0,1.057,1.057h14.8A1.055,1.055,0,0,0,16.916,21.5V7.048H10.925A1.06,1.06,0,0,1,9.868,5.991Zm2.871,9.516H9.868v3.524a.7.7,0,0,1-.7.7H7.753a.7.7,0,0,1-.7-.7V15.507H4.177a.7.7,0,0,1-.5-1.205l4.248-4.216a.751.751,0,0,1,1.059,0L13.235,14.3A.7.7,0,0,1,12.739,15.507ZM16.608,4.626,12.3.308A1.056,1.056,0,0,0,11.546,0h-.269V5.639h5.639V5.37A1.054,1.054,0,0,0,16.608,4.626Z"
          transform="translate(683.337 454.64)"
          fill={proposta ? "#fff" : "#a3a3a3"}
        />
      </g>
    </svg>
  );
};

export default IconsContratar;
