import React from 'react';
import {Container, Overflow} from "./styles";

const SubHeader = ({ children, status}) => {
  return (
    <>
      <Container>
        {children}
      </Container>
      {!status && <Overflow />}
    </>
  )
}

export default SubHeader
