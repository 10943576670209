export const columnsItens = [
  {
    name: 'COD',
    sortable: true,
    selector: 'codigo',
    width: '80px'
  },
  {
    name: 'GRUPO',
    selector: 'grupo',
    sortable: true,
    center: true,
    grow: 1,
    style: {
      fontWeight: 'bold',
      marginRight: '9px',
    },
  },
  {
    name: 'DESCRIÇAO COMPLETA',
    selector: 'descricao_completa',
    sortable: true,
    grow: 5,
    wrap: true,
  },
  {
    name: 'QTD',
    selector: 'quantidade',
    sortable: true,
    center: true,
    grow: 1,
  },
  {
    name: 'UNID.',
    selector: 'unidade_fornecimento',
    sortable: true,
    grow: 0.3,
    wrap: true,
  },
  {
    name: 'TIPO',
    selector: 'tipo',
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'MARG. PREF.',
    selector: 'margem_de_preferencia',
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'DECRE. 7174',
    selector: 'decreto7174',
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'TRA. DIFER.',
    selector: 'tratamento_diferenciado',
    sortable: true,
    wrap: true,
    grow: 0.3,
  }
];