import { action, observable } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class FunilStore {
  @observable todosPortais = [
    { id: 5, name: "ComprasNet" },
    { id: 6, name: "Licitações-e" },
    { id: 9, name: "Compras Públicas" },
    { id: 877, name: "Portal BLL" },
    { id: 930, name: "Portal BNC" }
  ];

  @observable portais = this.todosPortais.map(item => item.id);
  // QUANTIDADE DE LICITAÇÕES
  @observable licitacoes_total = 0;

  @observable carregando = false;

  @observable portaisData = {};

  @observable filtroAno = "todos";

  @observable filtroPortal = "todos";

  // Lista de anos disponíveis para filtro de licitações, este anos são anos que existem licitações
  @observable anos = [];

  @action
  anoHandleChange = ({ target: { value } }) => {
    this.filtroAno = value;
  };

  @action
  portalHandleChange = ({ target: { value } }) => {
    this.filtroPortal = value;
    if (value === "todos") {
      this.portais = this.todosPortais.map(item => item.id);
    } else {
      this.portais = [Number(value)];
    }
  };

  updateAnos = () => {
    const anosSet = new Set();
    const keys = ["agendados", "andamentos", "realizados", "atas", "anulados"];
    for (const portalData in this.portaisData) {
      for (const key of keys) {
        const portal = this.portaisData[portalData];
        if (portal === undefined) continue;
        const licitacoes = portal[key];
        if (licitacoes === undefined) continue;
        for (const licitacao of licitacoes) {
          const date = new Date(licitacao.data_disputa);
          anosSet.add(date.getFullYear());
        }
      }
    }
    this.anos = Array.from(anosSet)
      .sort()
      .map(ano => ano.toString());
    if (this.filtroAno !== "todos" && !anosSet.has(Number(this.filtroAno))) {
      this.filtroAno = "todos";
    }
  };

  @action consultaApi = () => {
    ModalLoadingStore.handleOpenModal();
    this.carregando = true;
    Api.get(`licitacoes/funil_novo/${MaquinaDeEstadosStore.getIdEmpresa()}`)
      .then(({ data }) => {
        const {
          total_licitacoes,
          licitacoes_e,
          comprasnet,
          compras_publicas,
          bll,
          bnc
        } = data;

        this.licitacoes_total = total_licitacoes;

        this.portaisData = {
          6: licitacoes_e,
          5: comprasnet,
          9: compras_publicas,
          877: bll,
          930: bnc
        };
        this.updateAnos();
      })
      .finally(() => {
        this.carregando = false;
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action limparVariaveis = () => {
    this.portaisData = {};
    this.carregando = false;
    this.licitacoes_total = 0;
    this.filtroAno = "todos";
    this.filtroPortal = "todos";
  };
}

const funilStore = new FunilStore();
export default funilStore;
