import React, { Component } from "react";
// // import "../../components/Login/node_modules/react-tagsinput/react-tagsinput.css";
// import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { ToolTipStyle } from "../../Components/Side/SideStyle";

class ToolTip extends Component {
  render() {
    return (
      <div>
        <ToolTipStyle 
          className="" 
          data-tip={this.props.frase} 
          data-effect="solid" 
          data-place="right"
          data-type="warning"
          >
          <FontAwesomeIcon icon="exclamation" />
        </ToolTipStyle>
        {/* <ReactTooltip
          multiline={"true"}
          type="warning"
          effect="solid"
          place={this.props.place}
        /> */}
      </div>
    );
  }
}

export default ToolTip;
