import React, { useContext } from 'react'
import { ThumbsUp } from 'react-feather'

import RoundBadge from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'
import { SettingsContext } from '../Settings/Context'

const ManualQualifyButton = ({ edital, statusEdict, typeSearch }) => {
  const { setEdital, statusDirectEdict, setSelected } = useStatusEdital()
  const { openModalQualificar } = useContext(ModalContext)
  const { toggleQualifiedManual, qualifiedManual } = useContext(SettingsContext)

  const status = () => {
    return {
      id: edital.id,
      msg_status: "'",
      palavras_usadas: '',
      prioridade: 'media',
      status: 'qualificado'
    }
  }

  const qualify = () => {
    const newEdict = { ...edital, selecionado: status(), status: 'qualificado' }
    if (edital.selecionado.id) setSelected(true)
    setEdital(newEdict)
    toggleQualifiedManual(newEdict.id)
    statusDirectEdict(newEdict, 'qualificado', edital)
  }

  const showModalQualify = () => {
    const newEdict = { ...edital, selecionado: status() }
    setEdital(newEdict)
    openModalQualificar()
  }

  return (
    <>
      <RoundBadge
        icon={<ThumbsUp />}
        background={
          edital.status === 'qualificado' || qualifiedManual.includes(edital.id)
            ? '#58c340'
            : '#b9dbb6'
        }
        onClick={
          statusEdict.qualificado &&
          !statusEdict.qualificado[1] &&
          !statusEdict.qualificado[2]
            ? qualify
            : showModalQualify
        }
      />
    </>
  )
}

export default ManualQualifyButton
