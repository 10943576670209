import { observable, action } from "mobx";
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore';
import Api from "../../../Stores/Conexao/conexao";
import { tratarDadosEditais } from "../Components/tratarDadosEditais";
import { FormatDateData, ToastInfo } from "../../../Stores/Funcoes/FuncoesGerais";
import DetalhesEditalStore from '../../../Components/Modules/ModalDetalhes/store'
import BuscaManualStore from '../ManualSearch/Store'
class DownloadEditaisStore {
  @observable isFetching = false;

  @observable interval = null;

  @observable editais = [];
  @action changeEditais = (id, status) => (this.editais = this.editais.map(ed => ed.id === id ? { ...ed, status } : ed));
  @observable total_paginas = 0
  @observable total_registros = 0

  @observable errorDownload = false;

  @observable ordenacao = "data_disputa";
  @observable crescente = true;

  @observable listaSelecionados = [];
  @observable fetchingSelecionados = false;
  @observable fetchingTodos = false;
  @action handleSelection = (id) => {
    const included = this.listaSelecionados.includes(id);
    if (included)
      this.listaSelecionados = this.listaSelecionados.filter(el => el !== id);
    else
      this.listaSelecionados = [...this.listaSelecionados, id];
  }


  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: (portais) => this.portal.portais = portais,
  }

  @observable filtros = {
    dmin: null,
    dmax: null,
    qualData: 'inicial',
    listaDownload: 'todos',
    changeList: ({ target: { value } }) => this.filtros.listaDownload = value,
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString("pt-br");
      this.filtros.dmax = new Date().toLocaleDateString("pt-br");
    },
    changeDate: (range) => {
      const { qualData } = this.filtros;

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString("pt-br");
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString("pt-br");

      return (this.filtros.qualData = qualData === "inicial" ? "final" : "inicial");
    },
    onInputChange: (e) => {
      this.filtros[e.target.name] = e.target.value;
    }
  }

  @observable pagination = {
    page: 1,
    changePage: (page) => this.pagination.page = page
  }


  @observable selected = [];
  @action changeSelected = (arr) => {
    this.selected = arr;
  }


  getDataFiltro = (data, page, id) => {
    const { date: { dmax }, status, comprador, perfil, estados, palavra, modalidade, tipoDate, portais } = data
    let query = `/portal/editais_download/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    query += `&data=${dmax}`;
    query += `&idPerfil=${id}`;
    query += `&pagina=${page || this.pagination.page}`;
    query += `&status=${status}`;
    query += `&tipo_data=${tipoDate}`;
    query += `&portal=${portais}`;
    query += `&estados=${estados}`;
    query += `&palavras=${palavra}`;
    query += `&modalidade=${modalidade}`;
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`;
    query += `&crescente=${this.crescente}`;
    query += `&comprador=${comprador}`;
    query += `&config=${perfil || MaquinaDeEstadosStore.perfil[0].id}`;
    return query;
  }

  getData = (id, data, page) => {
    const { date: { dmax }, tipoDate } = data
    let query = `/portal/editais_download/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    query += `&data=${dmax || new Date().toLocaleDateString("pt-br")}`;
    query += `&pagina=${page || this.pagination.page}`;
    query += `&status=${this.filtros.listaDownload}`;
    query += `&perfil=${id}`
    query += `&tipo_data=${tipoDate}`;
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`;
    query += `&crescente=${this.crescente}`;
    
    return query;
  }

  @action fetchEditaisNovo = async (data, id, type = '', currentPage, nextPage) => {
    this.isFetching = true;
    try {
      const res = await Api.get(this.getDataFiltro(data, null, id))
      const { editais, total_registros } = res.data;
      if (type === "modalDetail") {
        DetalhesEditalStore.tratarEditaisAdicionados(tratarDadosEditais(editais), nextPage)
        if (currentPage && currentPage > 0) {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[0].id)
        } else {
          DetalhesEditalStore.setIdValue(tratarDadosEditais(res.data.editais)[9].id)
        }
      }
      this.editais = tratarDadosEditais(editais);
      this.total_paginas = Math.ceil(total_registros / 10);
      this.total_registros = total_registros
    } catch (err) {
    } finally { 
      this.isFetching = false 
      BuscaManualStore.setDisableNext(false)
      BuscaManualStore.setDisablePrevious(false)
    };
  }

  @action fetchEditais = async (perfil_id = null, data = undefined, idPerfil = null, type = '', page = null, id = null) => {
    this.isFetching = true;
    if (perfil_id) this.pagination.changePage(1)
    try {
      const res = await Api.get(this.getData(perfil_id, data))
      const { editais, total_registros } = res.data;
      this.editais = tratarDadosEditais(editais);
      this.total_paginas = Math.ceil(total_registros / 10);
      this.total_registros = total_registros
    } catch (err) {
    } finally { 

      this.isFetching = false 
    };
  }



  getDataFiltroTodos = (data, page) => {
    const { date: { dmax }, status, comprador, perfil, estados, palavra, modalidade, tipoDate, portais } = data
    let query = `/portal/download_editais_zip/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    query += `&data=${dmax}`;
    query += `&pagina=${page || this.pagination.page}`;
    query += `&status=${status}`;
    query += `&tipo_data=${tipoDate}`;
    query += `&portal=${portais}`;
    query += `&estados=${estados}`;
    query += `&palavras=${palavra}`;
    query += `&modalidade=${modalidade}`;
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`;
    query += `&crescente=${this.crescente}`;
    query += `&comprador=${comprador}`;
    query += `&config=${perfil || MaquinaDeEstadosStore.perfil[0].id}`;
    return query;
  }

  @action downloadTodosNovo = async (data) => {
    this.isFetching = true;
    try {
      const res = await Api.get(this.getDataFiltroTodos(data));
      ToastInfo(res.data.message)
      // const { editais, total_registros } = res.data;
      // this.editais = tratarDadosEditais(editais);
      // this.total_paginas = Math.ceil(total_registros / 10);
      // this.total_registros = total_registros
    } catch (err) {
    } finally { this.isFetching = false };
  }


  @action downloadTodos = async () => {
    const downloadTodosQueryString = (() => {
      const { dmin, dmax } = this.filtros;
      let string = `/portal/download_editais_zip/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`;
      string += `&status=${this.filtros.listaDownload}`;
      string += `&dmax=${dmax}&dmin=${dmin}`;
      return string;
    })();

    this.fetchingTodos = true;
    try {
      const res = await Api.get(downloadTodosQueryString);
      const { message } = res.data;
      this.interval = setInterval(async () => {
        try {
          const res = await Api.get(`/portal/historico_download/?fornecedor_pk=${MaquinaDeEstadosStore.getIdEmpresa()}`);
          if (res.data.message) this.provideUrl(res.data.message.url)
        } catch (err) {
        }
      }, 10000)
    } catch (err) {
    }
  }

  @action provideUrl = (url) => {
    // this.urlTodos = url;
    // window.open(url, '_parent');
    // clearInterval(this.interval);
    // this.fetchingTodos = false;
    this.downloadTodosConcluido = url;
  }

  @observable downloadTodosConcluido = false;

  @action downloadSelecionados = async () => {
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore;
    if (!this.listaSelecionados.length) return;
    const date = FormatDateData(new Date());
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia;
    const fileName = `${this.listaSelecionados.length}_Editais_${empresaName}_${date}.zip`;
    const dados = [...this.listaSelecionados];

    this.fetchingSelecionados = true;
    try {
      const res = await Api.post(
        "portal/download_zip/",
        { dados, fornecedor_pk: id_fornecedor },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => {
        this.listaSelecionados = [];
      }, 2500);
    } catch (err) {
    } finally {
      this.fetchingSelecionados = false;
    }
  }


  @action downloadSelecionadosNovo = async (listaId) => {
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore;
    if (!listaId.length) return;
    const date = FormatDateData(new Date());
    const empresaName = empresas.find(empresa => Number(empresa.id) === Number(id_fornecedor)).nome_fantasia;
    const fileName = `${listaId.length}_Editais_${empresaName}_${date}.zip`;
    const dados = [...listaId];
    try {
      const res = await Api.post(
        "portal/download_zip/", { dados, fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa() },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (err) {
    } finally {
    }
  }



  fetchMore = async (page, data) => {
    try {
      const res = await Api.get(this.getDataFiltro(data, page));
      return res.data.editais;
    } catch (err) {
    };
  }


  trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.fetchEditais();
  }

  limparVariaveis = () => {
    this.isFetching = false;

    this.perfis = [];
    this.editais = [];
    this.total_paginas = 0
    this.total_registros = 0


    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: (portais) => this.portal.portais = portais,
    }

    this.filtros = {
      dmin: new Date().toLocaleDateString("pt-br"),
      dmax: new Date().toLocaleDateString("pt-br"),
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString("pt-br");
        this.filtros.dmax = new Date().toLocaleDateString("pt-br");
      },
      changeDate: (range) => {
        const { qualData } = this.filtros;

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString("pt-br");
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString("pt-br");

        return (this.filtros.qualData = qualData === "inicial" ? "final" : "inicial");
      },
      onInputChange: (e) => {
        this.filtros[e.target.name] = e.target.value;
      }
    }

    this.pagination = {
      page: 1,
      changePage: (page) => this.pagination.page = page
    }
  }
}

export default new DownloadEditaisStore();
