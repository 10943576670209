import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";

class CadastroStores {
  @observable validacao = false;
  @observable first_name = "";
  @observable last_name = "";
  @observable email_cobranca = "";
  @observable password = "";
  @observable repeat_password = "";
  @observable razao_social = "";
  @observable nome_fantasia = "";
  @observable cnpj = "";
  @observable phone = "";
  @observable isVerified = false;

  @observable cpnjinvalido = false;
  @observable statusNome = false;
  @observable statusSobrenome = false;
  @observable statusEmail = false;
  @observable statusSenha = false;
  @observable statusRSenha = false;
  @observable statusRazao = false;
  @observable statusFantasia = false;
  @observable statusCNPJ = false;
  @observable statusTelefone = false;
  @observable statusBtn = true;
  @observable statusCadastro = 2;

  @action onChange = evento => {
    const campo = evento.target.name;
    this[campo] = evento.target.value;

    this.cpnjinvalido = false;
    this.statusCadastro = 2;
    this.statusNome = false;
    this.statusSobrenome = false;
    this.statusEmail = false;
    this.statusSenha = false;
    this.statusRSenha = false;
    this.statusRazao = false;
    this.statusFantasia = false;
    this.statusCNPJ = false;
    this.statusTelefone = false;
    this.statusBtn = true;
    this.validacao_cadastro();
  };

  @action validacao_cadastro() {
    if (this.first_name === "") {
      this.statusNome = true;
      return false;
    } else if (this.last_name === "") {
      this.statusSobrenome = true;
      return false;
    } else if (this.email_cobranca === "") {
      this.statusEmail = true;
      return false;
    } else if (
      !this.email_cobranca.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      this.statusEmail = true;
      return false;
    } else if (this.password === "") {
      this.statusSenha = true;
      return false;
    } else if (this.repeat_password === "") {
      this.statusRSenha = true;
      return false;
    } else if (this.repeat_password !== this.password) {
      this.statusRSenha = true;
      this.statusSenha = true;
      return false;
    } else if (this.razao_social === "") {
      this.statusRazao = true;
      return false;
    } else if (this.nome_fantasia === "") {
      this.statusFantasia = true;
      return false;
    } else if (this.cnpj === "") {
      this.statusCNPJ = true;
      return false;
    } else if (!this.cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)) {
      this.statusCNPJ = true;
      return false;
    } else if (this.phone === "") {
      this.statusTelefone = true;
      return false;
    } else if (
      !this.statusNome &&
      !this.statusSobrenome &&
      !this.statusEmail &&
      !this.statusSenha &&
      !this.statusRSenha &&
      !this.statusRazao &&
      !this.statusFantasia &&
      !this.statusCNPJ &&
      !this.statusTelefone
    )
      this.statusBtn = false;
    return true;
  }

  @action cadastrar = (event, props) => {
    this.error = "";
    event.preventDefault();
    const data = new FormData();
    data.set("first_name", this.first_name);
    data.set("last_name", this.last_name);
    data.set("username", this.email_cobranca);
    data.set("email_cobranca", this.email_cobranca);
    data.set("password", this.password);
    data.set("repeat_password", this.repeat_password);
    data.set("razao_social", this.razao_social);
    data.set("nome_fantasia", this.nome_fantasia);
    data.set("cnpj", this.cnpj);
    data.set("phone", this.phone);
    Api.post("cadastro_inical/", data, {})
      .then(response => {
        this.statusCadastro = true;
      })
      .catch(error => {

        if (error.response.data === "Usuario com email ou cnpj ja cadastrado") {
          //Se der erro no codigo de cima (como tava dando na linha que tirei fora, esse erro nao existe e buga todo React)
          this.statusCadastro = false;
          this.statusEmail = true;
          this.error = error.response.data;
        }
        if (error.response.data === "CNPJ Inválido") {
          //Se der erro no codigo de cima (como tava dando na linha que tirei fora, esse erro nao existe e buga todo React)
          this.cpnjinvalido = true;
          this.error = error.response.data;
        }
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  @observable error = "";

  @action limparVariaveis = () => {
    this.first_name = "";
    this.last_name = "";
    this.email_cobranca = "";
    this.password = "";
    this.repeat_password = "";
    this.razao_social = "";
    this.nome_fantasia = "";
    this.cnpj = "";
    this.phone = "";
    this.isVerified = false;
    this.cpnjinvalido = false;
    this.statusNome = false;
    this.statusSobrenome = false;
    this.statusEmail = false;
    this.statusSenha = false;
    this.statusRSenha = false;
    this.statusRazao = false;
    this.statusFantasia = false;
    this.statusCNPJ = false;
    this.statusTelefone = false;
    this.statusBtn = true;
    this.statusCadastro = 2;
  };
}

const cadastroStores = new CadastroStores();
export default cadastroStores;
