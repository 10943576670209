import React from 'react'
import { InputGroup, FormGroup, InputIcone } from './styles'

const InputCustomizadoIcone = ({
  placeholder,
  icone,
  onChange,
  value,
  name,
  onCLick,
  disabled,
  noHeight

}) => {
  return (
    <FormGroup>
      <InputGroup noHeight={noHeight}>
        <InputIcone>{icone}</InputIcone>
        <input
          disabled={disabled}
          placeholder={placeholder}
          type="text"
          className="form-control"
          name={name}
          onChange={onChange}
          value={value}
          onClick={onCLick}
        />
      </InputGroup>
    </FormGroup>
  )
}

export default InputCustomizadoIcone
