import React from 'react';
import { Download, ExternalLink, Layers } from "react-feather";
import { BottonLink, ContainerHeader, WrapperLicitacao, WrapperLink } from "./styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es';
import { ConsoleHelper } from '../../../../../utils/ConsoleHelper';

const HeaderDetalhes = ({ edital, downloadQuebra, isFetching }) => {
  const { licitacao, link, download, quebraCaptcha, modalidade } = edital;

  ConsoleHelper(edital, "console")
  const handleDownload = () => {
    if (quebraCaptcha) downloadQuebra(edital);
    else window.open(download, "_blank")
  }

  return (
    <ContainerHeader>
      <WrapperLink>
        <BottonLink color={'#1D90CC'} onClick={() => window.open(link, "_blank")}>
          <ExternalLink />
          <div>LINK EDITAL</div>
        </BottonLink>
        {download && modalidade !== "Cotação Eletrônica" && <BottonLink color={'#189F2D'} onClick={isFetching ? () => { } : handleDownload}>
          {isFetching ? <FontAwesomeIcon icon="spinner" /> :
            <>
              <Download />
              <div>BAIXAR EDITAL</div>
            </>}
        </BottonLink>}
      </WrapperLink>

      <WrapperLicitacao>
        <div className="icon">
          <Layers color="#fff" />
        </div>
        <div className="title">
          LICITAÇÃO <b>{licitacao}</b>
        </div>
      </WrapperLicitacao>

    </ContainerHeader >
  );
};

export default HeaderDetalhes;
