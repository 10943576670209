import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { Div } from "../../style/styleCadastro";

const Cnpj = ({ errors, touched, name, handleChange, handleBlur }) => {
    return (
        <>
            <InputMask
                mask={"99.999.999/9999-99"}
                onChange={handleChange}
                onBlur={handleBlur}
            >
                {() => (
                    <TextField
                        error={touched[name] && errors[name]}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="dense"
                        name={name}
                        type="text"
                        fullWidth
                        label="CNPJ"
                    />
                )}
            </InputMask>
            {errors[name] && touched[name] && <Div>{errors[name]}</Div>}
        </>
    )
}

export default Cnpj;