import React, { useState } from "react";
import {
  CardBodyMsg,
  CardMsg,
  CardWarpMsg,
  CardWarpPaginacao
} from "../StyleLicitacao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../../Stores/Funcoes/Pagination";
import { FormControl } from "react-bootstrap";

const statusMsg = verificada => {
  return (
    <span
      style={{
        borderRadius: "10px",
        padding: "2px 10px 2px 10px",
        background: verificada ? "#009a39" : "#CC0000",
        marginLeft: "-10px",
        color: "white",
        width: "100%",
        fontSize: "10px",
        textAlign: "center"
      }}
    >
      {verificada ? "VERIFICADA" : "NÃO VERIFICADA"}
    </span>
  );
};

const TabelaMsg = ({ mensagens_atuais }) => {
  const [mensagens, setMsg] = useState(mensagens_atuais);
  const [pagina, setPagina] = useState(1);
  const [numeroPaginas, setNumeroPaginas] = useState(5);

  const onPageChanged = data => {
    let msg = mensagens_atuais;
    let { currentPage, pageLimit } = data;
    currentPage = currentPage === 0 ? 1 : currentPage;
    const offset = (currentPage - 1) * pageLimit;
    let dados = msg.slice(offset, offset + pageLimit);
    setMsg(dados);
    setPagina(currentPage);
  };

  const handleTab = e => {
    setNumeroPaginas(Number(e.target.value));
  };

  return (
    <>
      {mensagens.map(({ texto, remetente, data_hora, verificada }) => {
        return (
          <CardWarpMsg>
            <CardMsg>
              <div
                style={{
                  display: "flex",
                  width: "3%",
                  minWidth: "33px",
                  justifyContent: "end",
                  alignItems: "center"
                }}
              >
                <FontAwesomeIcon
                  size={"2x"}
                  icon="comments"
                  color={verificada ? "#009a39" : "#CC0000"}
                />
              </div>
              <CardBodyMsg flexDirection={"column"} width={"97%"}>
                <CardBodyMsg>
                  <CardBodyMsg paddingBottom={"10px"}>
                    <div>
                      <b>Origem:</b> {remetente}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ paddingRight: "25px" }}>
                        {statusMsg(verificada)}
                      </div>
                      <div>
                        <b>{data_hora}</b>
                      </div>
                    </div>
                  </CardBodyMsg>
                </CardBodyMsg>
                <CardBodyMsg>
                  <div>
                    <b>Mensagem:</b> {texto}
                  </div>
                </CardBodyMsg>
              </CardBodyMsg>
            </CardMsg>
          </CardWarpMsg>
        );
      })}

      {mensagens_atuais.length > 0 && (
        <CardWarpPaginacao>
          <Pagination
            totalRecords={mensagens_atuais.length}
            pageLimit={numeroPaginas}
            pageNeighbours={1}
            defaultCurrent={pagina}
            onPageChanged={onPageChanged}
            key={numeroPaginas + 1}
            flex="none"
          />
          <div
            style={{
              display: "flex",
              marginLeft: "50px",
              fontSize: "12px",
              alignItems: "center"
            }}
          >
            {mensagens_atuais.length > 5 && (
              <>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <FormControl
                    style={{ fontSize: "12px", marginBottom: "14px" }}
                    id="fornecedor"
                    name="fornecedor"
                    componentClass="select"
                    defaultValue={numeroPaginas}
                    onChange={e => handleTab(e)}
                  >
                    <option key={5} value={5}>
                      5
                    </option>
                    <option key={10} value={10}>
                      10
                    </option>
                    <option key={20} value={20}>
                      20
                    </option>
                    <option key={50} value={50}>
                      50
                    </option>
                    <option key={100} value={100}>
                      100
                    </option>
                  </FormControl>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "-10px" }}>
                  Itens por página
                </div>
              </>
            )}
          </div>
        </CardWarpPaginacao>
      )}
    </>
  );
};
export default TabelaMsg;
