import styled from 'styled-components';

export const CustomInput = styled.div`
  position: relative;
  margin: 5px 0;
  text-align: center;
  overflow: unset !important;
  margin-right: ${props => props.mRight ? '18px' : 0};
  width: ${props => props.width || 'auto'};
  transition: 150ms;

  span {
    position: absolute;
    background-color: white;
    font-size: 11px;
    left: 12px;
    top: -8px;
    padding: 0 5px;
    color: #7e7e7e;
    z-index: 1;
    transition: 150ms;
  }

  input:disabled {
    background: #efefef;
  }

  input,
  .phone-input,
  textarea,
  .cnpj-input,
  .select-portal,
  .select-empresas,
  .select-users {
    width: 100%;
    height: ${props => props.height ? props.height : '30px'};
    border: 1px solid #9d9d9d;
    border-radius: 7px;
    padding: 3px 8px;
    font-size: 12px;
    transition: 200ms;
    border-color: ${props => props.validationValues ? '#9d9d9d' : 'rgba(202,0,14,0.56)'};

    option:checked {
      background-color: #3a566e;
    }

    :focus {
      outline: none;
      border-color: #3a566e8f;
      /* border-width: 1.5px; */
      box-shadow: 1px 1px 3px #717070;
    }
  }

  :focus-within {
    span {
      font-weight: 700;
    }
  }

  textarea {
    resize: none;
  }

  .select-empresas,
  .select-users {
    padding-top: 12px;
  }
`;
