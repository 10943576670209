import {
  BadgeDanger,
  BadgeSuccess,
  SideBox,
  SideBoxFalse,
  TagLink
} from './SideStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import ToolTip from '../../Stores/Funcoes/ToolTip'
import MUToolTip from '@material-ui/core/Tooltip'

const handleBadgeSuccess = qtd => {
  return qtd ? <BadgeSuccess>{qtd}</BadgeSuccess> : null
}

const handleBadgeDanger = qtd => {
  return qtd ? <BadgeDanger>{qtd}</BadgeDanger> : null
}

const handleIco = (ico, icoTV) => {
  return icoTV ? (
    <>
      <FontAwesomeIcon
        icon={icoTV}
        style={{
          fontSize: '12px',
          marginRight: '-10px',
          marginBottom: '-30px'
        }}
      />
      <FontAwesomeIcon icon={ico} style={{ fontSize: '1.6rem' }} />
    </>
  ) : (
    <FontAwesomeIcon icon={ico} style={{ fontSize: '1.6rem' }} />
  )
}

export const handleMsg = mensagem => {
  return <ToolTip frase={mensagem} place={'right'} />
}

export const SidebarIcon = ({
  url,
  title,
  ico,
  acesso,
  qtdSuccess,
  qtdDanger,
  dataExpiracao,
  icoTV,
  path
}) => {
  if (acesso) {
    return (
      <MUToolTip title={title} placement="right">
        <TagLink>
          <Link to={url}>
            <SideBox
              className={path === url ? 'side-button-active' : ''}
            >
              {handleIco(ico, icoTV)}
              {handleBadgeSuccess(qtdSuccess)}
              {handleBadgeDanger(qtdDanger)}
            </SideBox>
          </Link>
        </TagLink>
      </MUToolTip>
    )
  } else {
    return (
      <MUToolTip title={title} placement="right-end">
        <SideBoxFalse>
          {handleIco(ico, icoTV)}
          {handleMsg('Módulo bloqueado, contatar setor comercial.')}
        </SideBoxFalse>
      </MUToolTip>
    )
  }
}

export default SidebarIcon
