import styled from "styled-components";

export const PortaisContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  user-select: none;
  /* margin: 0 10px; */

  /*  @media (max-width: 1200px) {
    font-size: 14px;
    width: 220px; */
`;

export const PortalContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px 8px;
  padding-right: 15px;
  border-bottom: 1px solid #9e9e9e;
  :last-child {
    border-bottom: none !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  :first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  transition: 300ms;

  :hover {
    background: #b8bec3;

    span {
      color: ${props => (props.active ? "#f5fbff" : "#e6e5e5")};
    }
  }

  span {
    font-size: 14px;
    color: ${props => (props.active ? "#486277" : "#bdbdbd")};
    font-weight: 400;
  }

  img {
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px #000;
    filter: ${props => (props.active ? "none" : "grayscale(95%)")};
    -webkit-filter: ${props => (props.active ? "none" : "grayscale(95%)")};
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
`;
