import React from 'react';
import styled from 'styled-components';
import { AlertCircle } from 'react-feather';

const Container = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  font-size: 12px;
  border: 1px solid #b53838;
  width: 65%;
  background: #ff000014;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-weight: 500;
  font-style: italic;
  justify-content: center;

  svg {
    color: #b53838;
    width: 18px;
    margin-right: 8px;
  }
`;

const Aviso = ({ title }) => {
  return (
    <Container>
      <AlertCircle />
      {title}
    </Container>
  )
}

export default Aviso
