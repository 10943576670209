import React, { useContext, useEffect } from 'react'
import { FileText } from 'react-feather'
import { API } from '../api'
import BadegRedondo from '../../../../../ReusableComponents/Badeg/badegRedondo'
import { ToastError } from '../../../../../../Stores/Funcoes/FuncoesGerais'
import { PropostaEspecificaContext } from '../../PropostaEspecifica/context'

const Carregar = ({ edital }) => {
  const { numero, comprador: { codigo }, proposta: { enviada } } = edital;

  const { buscarManualmente, modalCarregarItens, openModal } = useContext(PropostaEspecificaContext);

  if (enviada) return (
    <BadegRedondo icon={<FileText />} onClick={() => ToastError('Essa proposta já foi enviada.')} background={'#cacaca'} repetido={true} />
  )

  return (
    <BadegRedondo
      icon={<FileText />}
      onClick={() => openModal({ numero, uasg: codigo })}
      // onClick={() => buscarManualmente({ url: API.buscaManual(numero, codigo) })}
      background={'#1D7C98'}
    />
  )
}

export default Carregar
