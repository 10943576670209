import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  position: relative;
  width: 65px;
  height: 53px;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.blocked ? '#ebebeb7a' : props.active ? '#FFF !important' : '#fff'};
  background: ${props => (props.active ? '#4b7dab' : '#3a566e')};
  transition: 200ms;
  cursor: ${({ blocked }) => (blocked ? 'default' : 'pointer')};
  margin: 0.25rem 0;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }

  :hover {
    background-color: ${({ blocked }) => (blocked ? 'none' : '#eef0f3c9')};
    color: ${props => (props.blocked ? '#ebebeb7a' : '#1879a3')};
    -webkit-box-shadow: ${({ blocked }) =>
      blocked ? 'none' : '1px 2px 5px 0px rgba(0,0,0,0.4)'};
    -moz-box-shadow: ${({ blocked }) =>
      blocked ? 'none' : '1px 2px 5px 0px rgba(0,0,0,0.4)'};
    box-shadow: ${({ blocked }) =>
      blocked ? 'none' : '1px 2px 5px 0px rgba(0,0,0,0.4)'};
  }
`

export const SecondaryMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 0;
  background: #4b7dab;
  left: 65px;
  top: 10px;
  bottom: 10px;
  box-shadow: 1px 0px 4px 0px #0a0a0a87;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  animation: 600ms slideRight ease-in-out forwards;

  @keyframes slideRight {
    from {
      width: 0;
    }
    to {
      width: 200px;
    }
  }

  .content {
    opacity: 0;
    animation: 600ms fadeIn ease-in-out forwards 600ms;
    height: 100%;
    user-select: none;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 150px;
      border-bottom: 1px solid #324554;

      svg {
        width: 50px;
        height: 50px;
      }

      div.title {
        text-align: center;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      height: calc(100% - 150px);
      justify-content: space-evenly;

      div.item-disable {
        text-align: center;
        width: 30%;
        margin: 0 35%;
        color: #7db0c5;

        .item-blocked {
          height: 18px;
          width: 18px;
          color: white;
          background-color: ${props =>
            props.background === undefined
              ? 'rgba(255,169,27,0.95)'
              : props.background};
          font-size: 12px;
          z-index: 99999;
          padding-right: 0.15em;
          padding-left: 0.15em;
          border-radius: 8rem;
          font-weight: normal;
          //display: inline-block;
          padding: 0.25em 0.4em;
          line-height: 1;
          text-align: center;
          white-space: nowrap;
          margin-left: 55%;
        }
        div.title {
          font-size: 65%;
          margin-top: 8px;
        }
        div.icone {
          width: 40%;
          margin: 0 30%;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: ${window.innerHeight / 40}px 0;
        transition: color, transform 150ms ease-out;
        cursor: pointer;
        color: inherit;

        @media (max-height: 800px) {
          flex-direction: row;

          svg {
            margin-right: 10px;
          }
        }

        @media (max-height: 690px) {
          margin: 0;
        }

        :hover {
          transform: scale(1.03);
          text-decoration: none;
          color: #96dbf0;

          .title {
            font-weight: 600;
          }
        }
        .title {
          transition: 150ms ease-out;
          font-size: 65%;
          margin-top: 8px;
        }
      }
    }
    @media (max-height: 760px) {
      font-size: 84%;
      .header {
        height: 115px;
        svg {
          width: 35px;
          height: 35px;
        }
      }
      .body {
        height: calc(100% - 115px);
      }
    }
  }
`
