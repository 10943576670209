import React, { Component } from 'react'

import * as S from '../Style'

class EmpresaInputConstumizado extends Component {
  render() {
    const {
      id,
      name,
      type,
      placeholder,
      defaultValue,
      value,
      onChange,
      titulo
    } = this.props
    return (
      <S.CustomInput>
        <span>{titulo}</span>
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </S.CustomInput>
    )
  }
}

export default EmpresaInputConstumizado
