import React, { useContext } from 'react'
import BuscaManual from './buscaManual/BuscaManual';
import Filtro from './filtro/Filtro';
import Tabela from './tabela/Tabela';
import DetalhesEdital from './detalhes/DetahesEdital';
import { PropostaEspecificaContext } from '../PropostaEspecifica/context';
import Spinner from '../../../../../UsefulComponents/Spinner';

const ListaDeLicitacoes = () => {

  const { edital, loading } = useContext(PropostaEspecificaContext);

  return (
    <>
      <BuscaManual />
      {loading
        ? <Spinner height="calc(100vh - 220px)" />
        : edital
          ? <DetalhesEdital />
          : <> <Filtro /> <Tabela /> </>
      }
    </>
  )
}

export default ListaDeLicitacoes;
