import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { ThumbsUp } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'

import { TextInput } from '../../../../UsefulComponents/Inputs'
import { SelectInput } from '../../../../UsefulComponents/Inputs'
import { ModalContext } from '../context/modal'
import { useStatusEdital } from '../context'

import * as S from './style'

const ModalQualify = inject('MaquinaDeEstadosStore')(
  observer(({ MaquinaDeEstadosStore }) => {
    const { status, usuarios } = MaquinaDeEstadosStore
    return <ModalQualifyEdict status={status} users={usuarios} />
  })
)

const ModalQualifyEdict = ({ status, users }) => {
  const {
    statusEdital,
    changePriority,
    onChangeUser,
    edital,
    userList,
    changeDisapprovalMessage
  } = useStatusEdital()
  const { closeModal, modalQualificar, } = useContext(ModalContext)
  const [userId, setUserID] = useState(users.length && users[0].id)

  let bulletinStatus
  if (status) bulletinStatus = status.qualificado

  return ReactDOM.createPortal(
    <>
      {modalQualificar && (
        <S.Overlay>
          <S.Container>
            <div className="header">
              <div className="icon background-qualificado">
                <ThumbsUp />
              </div>
              <div className="title color-qualificado">QUALIFICAR</div>
            </div>
            <div className="body">
              {bulletinStatus[2] && (
                <div className="select-user">
                  <div className="title">
                    SELECIONE UM USUÁRIO PARA ENCAMINHAR O EDITAL
                  </div>
                  <SelectInput
                    value={userId ? userId : users[0].id}
                    icon={<FontAwesomeIcon icon="users" />}
                    onChange={e => setUserID(Number(e.target.value))}
                  >
                    {users.map(user => (
                      <option key={user.id} value={user.id}>
                        {`${user.username}`}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              )}
              {bulletinStatus[1] && (
                <div className="nivel-prioridade">
                  <div className="title">NÍVEL DE PRIORIDADE</div>
                  <SelectInput
                    icon={<FontAwesomeIcon icon="bookmark" />}
                    onChange={e => changePriority(e.target.value)}
                    value={edital.selecionado.prioridade}
                  >
                    <option value="alta">Alta</option>
                    <option value="media">Média</option>
                    <option value="baixa">Baixa</option>
                  </SelectInput>
                </div>
              )}
              {/* <div className="nivel-prioridade">
                <div className="title">MENSAGEM</div>
                <TextInput
                  rows={10}
                  icon={<FontAwesomeIcon icon="messages" />}
                  value={edital.selecionado.msg_status}
                  onChange={e => changeDisapprovalMessage(e.target.value)}
                />
              </div> */}
            </div>
            <div className="footer">
              <div
                className="confirmar"
                onClick={() => statusEdital('qualificado', userId ? userId : users[0].id)}
              >
                CONFIRMAR
              </div>
              <div className="fechar" onClick={closeModal}>
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalQualify
