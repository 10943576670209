import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
  height: calc(100% - 48px);
  background: #e0e0e0;
`;

export const BotoesContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  min-height: 58px;
  font-size: 12px;
  box-shadow: 1px 1px 4px #000000ba;
  background: #fff;
  cursor: ${props => props.loading ? 'not-allowed' : 'pointer'};
  padding: 10px 25%;
  user-select: none;
  
    

  button {
    border:none;
    background: none;
    width: 50%;
    padding: 10px;
    font-weight: 500;
    box-shadow: 1px 1px 4px #000000;
    :disabled {
      opacity: 0.6;
    }
  }

  #action-submit {
    background: green;
    color: #fff;
    border-right: 1px solid #828282;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  #action-previous {
    background: #c5bfbf;
    color: black;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  #action-close {
    background: #f5220dc9;
    color: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .close-s {
    position: absolute;
    right: 13px;
    bottom: 15px;
    color: #949494;
    transition: 300ms;

    :hover { 
      color: #f94e4e;
    }
  }


  #action-save {
    background: #3a566e;
    color: #fff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
`;