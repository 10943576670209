import { observable, action } from 'mobx'
import Api from '../../../Stores/Conexao/conexao'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import React from 'react'
import ImprimirPropostaStore from '../ImprimirProposta/store'
import {
  AlertaVerde,
  ToastSuccess,
  ToastError,
  ToastWarning
} from '../../../Stores/Funcoes/FuncoesGerais'
import ItensPropostaStore from './contexto/Itens/store'

const columns = [
  { name: 'GRUPO/LOTE', selector: 'grupo', center: true, grow: 1, wrap: true },
  { name: 'ITEM', selector: 'item', center: true, width: '50px', wrap: true },
  {
    name: 'OBJETO',
    selector: 'descricao_completa',
    grow: 5,
    minWidth: '250px',
    wrap: true
  },
  { name: 'MARCA', selector: 'marca', sortable: true, grow: 2, wrap: true },
  { name: 'FORNECEDOR', selector: 'fornecedor', sortable: true, grow: 2 },
  {
    name: 'MODELO',
    selector: 'modelo_versao',
    sortable: true,
    grow: 2,
    wrap: true
  },
  { name: 'FABRIC', selector: 'fabricante', wrap: true, center: true, grow: 2 },
  {
    name: 'QTD. EST.',
    selector: 'qtd_estimada',
    wrap: true,
    center: true,
    width: '70px'
  },
  {
    name: 'DESC.',
    selector: 'desconto',
    wrap: true,
    center: true,
    width: '70px'
  },
  {
    name: 'QTD. OFERT.',
    selector: 'qtd_ofertada',
    wrap: true,
    center: true,
    width: '100px'
  },
  {
    name: 'R$ UNIT.',
    selector: 'valor_unit',
    wrap: true,
    center: true,
    grow: 2
  },
  {
    name: 'ALQ.',
    selector: 'aliquota',
    wrap: true,
    center: true,
    width: '60px'
  },
  { name: 'R$ TOTAL', selector: 'valor_total', wrap: true, center: true },
  {
    name: 'R$ TOTAL EQ',
    selector: 'valor_total_equal',
    wrap: true,
    center: true,
    grow: 2
  },
  {
    name: '7174 PPB',
    selector: 'ppb',
    wrap: true,
    center: true,
    width: '50px',
    cell: ({ ppb }) => <div>{ppb ? 'SIM' : ppb == null ? '-' : 'NÃO'}</div>
  },
  {
    name: '7174 TP',
    selector: 'tp',
    wrap: true,
    center: true,
    width: '50px',
    cell: ({ tp }) => <div>{tp ? 'SIM' : tp == null ? '-' : 'NÃO'}</div>
  }
]

class cadastrarPropostaStore {
  @observable statusActive = 'todos'
  @action setActive = type => (this.statusActive = type)
  @observable todos = []
  @observable boletim = []
  @observable aprovado = []
  @observable qualificados = []
  @observable quantidaStatus = []
  @observable totalRegistro = 0
  @observable total_paginas = 0
  @observable colunas = 0
  @observable itensSelecionados = []
  @observable modalConfirmar = false
  @observable idProposta = null

  @action toggleModalConfirmar = data => {
    if (!this.modalConfirmar) {
      const keys = Object.keys(data[0])
      this.itensSelecionados = data
      this.handleColuna(keys)
    }
    this.modalConfirmar = !this.modalConfirmar
  }

  @action salvarDados = async dados => {
    const data = {
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      rows: dados
    }
    this.itensSelecionados = data
    try {
      const res = await Api.post(`/portal/proposta_cadastra_item/`, data)
      this.idProposta = res.data.proposta
      ToastSuccess('Dados salvos')
      // AlertaVerde('Dados salvos')
    } catch (err) {}
  }

  @action handleColuna = keys => {
    const newColuna = columns
    let dados = newColuna.filter(item => {
      if (keys.indexOf(item.selector) !== -1) {
        return item
      }
    })
    this.colunas = dados
  }

  @action buscarManualmente = async data => {
    const { numero, uasg } = data
    if (!numero || !uasg) return ToastError('Preencha todos os campos.')
    const fornecedorId = MaquinaDeEstadosStore.getIdEmpresa()
    const queryString = `/portal/proposta_itens/?fornecedor=${fornecedorId}&uasg=${uasg}&numero=${numero}`
    try {
      const res = await Api.get(queryString)
      const { edital, rows } = res.data
      if (!edital) return ToastError('Edital não encontrado.')
      if (edital.proposta.enviada)
        return ToastError('Essa proposta já foi enviada.')
      ItensPropostaStore.fetchItensManual(edital, rows)
    } catch (err) {
      ToastError('Edital não encontrado.')
    }
  }

  @action confirmarProposta = async () => {
    // CALL TO API
    this.toggleModalConfirmar()
    ImprimirPropostaStore.toggleModalImprimir()
  }

  @observable modalDocumentos = false
  @action toggleModalDocumentos = check => {
    if (check && !this.modalDocumentos) return
    this.modalDocumentos = !this.modalDocumentos
  }

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }
  @observable isFetching = false

  @action fetchCurrent = () => {
    this.fetchEditais(this.statusActive)
  }

  @action fetchEditais = async (requisito, page) => {
    let pagina = page ? page : this.pagination.page
    this.isFetching = true
    let query = `/portal/proposta_boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}&status=${requisito}&pagina=${pagina}`
    try {
      const res = await Api.get(query)
      const { editais, total_registros, ...rest } = res.data
      this.quantidaStatus = rest
      this.total_paginas = Math.ceil(total_registros / 10)
      switch (requisito) {
        case 'todos':
          this.todos = editais
          break
        case 'boletim':
          this.boletim = editais
          break
        case 'qualificado':
          this.qualificados = editais
          break
        case 'aprovado':
          this.aprovado = editais
          break
        default:
      }
    } catch (err) {
    } finally {
      this.isFetching = false
    }
  }
}
const CadastrarPropostaStore = new cadastrarPropostaStore()
export default CadastrarPropostaStore
