import React from "react";
import {Loader} from "react-feather";
import {CardMD, CardMDBarra, CardMDBody, CardMDHearder, Titulo} from "../styleDashboard";
import {BadagSincro, ContainerMsg} from "./styleLicitacao";

const Mensagem = ({item}) => {
  const {numero_pregao,portal, uasg, dia, mes} = item;
  return(
    <ContainerMsg>
      <div style={{marginLeft: '10px'}}>{numero_pregao}</div>
      <div>{portal}</div>
      <div>UASG: {uasg}</div>
      <BadagSincro>
        <div>{dia}</div>
        <span>{mes}</span>
      </BadagSincro>
    </ContainerMsg>
  )
};

export const Sincronizadas = ({data}) => {
  const {lic_sincronizadas} = data;
  return (
    <CardMD color={'#0D2466'}>
      <CardMDHearder>
        <div style={{marginRight: '15px'}}><Loader size={'35px'}/></div>
        <Titulo width={'100%'}>
          <h5>LICITAÇÕES SINCRONIZADAS</h5>
          <span> Ùltimas licitações sincronizadas</span>
        </Titulo>
      </CardMDHearder>
      <CardMDBarra/>
      <CardMDBody>
        <div style={{display: 'flex', flexDirection: 'column', width: '98%'}}>
          {lic_sincronizadas.map((item) => {
            return(
              <Mensagem item={item}/>
            )
          })}
        </div>
      </CardMDBody>
    </CardMD>
  )
};
