import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from 'styled-components';
import { useFiltroEdital } from "./context/FiltroEditaisContext";

export const Container = styled.div`
  height: 100%;
  padding: 0 15px;
  color: ${props => props.active ? '#fff' : '#3b576f'};
  background: ${props => props.active ? 'transparent' : '#ffffffeb'};
  font-weight: 500;
  font-size: 13px;
  border-left: 1px solid ${props => props.active ? '#fff' : '#ab8a4e'};
  border-right: 1px solid ${props => props.active ? '#fff' : '#ab8a4e'};
  box-shadow: ${props => props.active ? 'none' : '1px 1px 3px #0000004f'};
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    transition: 250ms;
    :hover {
      color: #ffe095;
    }
  }

  svg {
    margin-right: 5px;
  }
`;

const BotaoFiltrar = ({ onClick }) => {
  const { showFiltro, state: { filtro } } = useFiltroEdital()

  return (
    <Container
      onClick={onClick ? onClick : () => showFiltro(!filtro)}
      active={filtro}
    >
      <FontAwesomeIcon icon="filter" color="#67baec" />
      <span>FILTROS</span>
    </Container>
  );
};

export default BotaoFiltrar;
