import React, { useEffect, useState } from 'react'
import { CardBody, CardFiltro, CardHeard, CardTitulo } from './styles'
import { Layers } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import InputMultiploPortais from "./inputPortais";

const CardPortais = ({ status }) => {
  const [ selected, setSelected ] = useState(true);
  const { onChangePortais, selectTodosPortais, state: { portais } } = useFiltroEdital()

  const handleSelectAll = () => {
    selectTodosPortais(!selected)
    setSelected(!selected)
  }

  const handleChange = (e) => {
    onChangePortais([...e.target.options].filter(op => op.selected).map(op => Number(op.value)))
  }

  return (
    <CardFiltro>
      <CardHeard>
        <Layers />
        <CardTitulo>
          <div>FILTRO PORTAIS</div>
          <p>Selecione os portais para serem filtrados</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <InputMultiploPortais
          status={status}
          type={'id'}
          selected={portais}
          onCLick={handleSelectAll}
          onChange={handleChange}
          icone={<Layers />}
          data={MaquinaDeEstadosStore.portais}
        />
      </CardBody>
    </CardFiltro>
  )
}

export default CardPortais
