import React from 'react'
import { ModalProvider } from '../Pages/Prospection/StatusNotice/context/modal'
import { StatusEditalProvider } from '../Pages/Prospection/StatusNotice/context'
import { FiltroProvider } from '../Pages/Prospection/Filter/context/FiltroEditaisContext'
import {SettingsContextProvider} from '../Pages/Prospection/Settings/Context/index.js'
import PropostaProviders from '../Components/Modules/Proposta2/providers'

const ContextoProviders = ({ children }) => {
  return (
    <PropostaProviders>
      <SettingsContextProvider>
        <StatusEditalProvider>
          <FiltroProvider>
            <ModalProvider>{children}</ModalProvider>
          </FiltroProvider>
        </StatusEditalProvider>
      </SettingsContextProvider>
    </PropostaProviders>
  )
}

export default ContextoProviders
