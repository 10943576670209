import styled from 'styled-components'

export const Container = styled.div`
  margin: 5px;
  min-height: calc(100vh - 164px);
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #464646;
  animation: 1s fadeIn ease-in-out;
  position: relative;
  margin-top: -1px;

  @media (max-height: 780px) {
    min-height: calc(100vh - 145px);

    .modal-body {
      min-height: calc(100vh - 150px) !important;
      height: calc(100vh - 150px) !important;
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: #296188;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;

      span {
        font-weight: 600;
        font-size: 90%;
      }
      svg {
        margin-right: 10px;
      }
    }
    button.close-button {
      position: absolute;
      right: -5px;
      top: -5px;
      background: #bd1c1c;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: 1px 1px 7px 1px #101010;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  .modal-body {
    display: flex;
    min-height: calc(100vh - 165px);
    width: 100%;
    padding: 20px 40px;
    align-content: space-between;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 165px);
    overflow: auto;

    .section-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        flex-direction: column;

        .main-title {
          font-weight: 700;
          font-size: 110%;
        }

        .sub-title {
          font-size: 80%;
          color: #aaaab3;
        }
      }

      .upload-company-image {
        display: flex;
        flex-direction: column;
        align-items: center;

        .wrapper-image {
          padding: 6px;
          border: 1px solid #e2e2e2;
          border-radius: 12px;
          position: relative;

          svg#remover-avatar-empresa {
            color: #e82e2e;
            position: absolute;
            bottom: -5px;
            left: -12px;
            width: 20px;
          }

          .spinner {
            animation: 2s rotateZ infinite;
            width: 50px;
            height: 50px;
            padding: 6px;
            font-size: 1rem;
            color: #131e2557;
          }

          @keyframes rotateZ {
            100% {
              transform: rotate(360deg);
            }
          }

          img {
            width: 50px;
            height: 50px;
          }
        }
        .limit-size {
          font-size: 11px;
          font-family: sans-serif;
          color: #8a8888;
        }
      }
    }

    .section-cabecalho,
    .section-rodape {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 700;
        font-size: 75%;
        margin-bottom: 7px;
      }

      .input-razao-cnpj,
      .input-est-mun-fone-cel,
      .logradouro,
      .logradouro2,
      .logradouro3,
      .input-nome-rep-legal {
        display: flex;
      }

      .imprimir-dados-textarea {
        display: flex;
        flex-direction: column;

        div:first-child {
          margin-bottom: 0px;
        }
      }
    }

    .section-cabecalho {
      .title {
        display: flex;
        align-items: center;

        .checkbox-desabilitar-cabecalho {
          margin-left: 50px;
          display: flex;
          align-items: center;

          input {
            margin-right: 5px;
          }
          span {
            color: #8a8a8a;
            font-weight: 500;
          }
        }
      }

      .inputs {
        display: flex;
        flex-direction: column;
        opacity: 0;
        animation: 1s fadeIn forwards;
      }
    }

    .section-rodape {
      margin-top: 20px;
    }

    .botoes-container {
      display: flex;
      width: 50%;
      margin: 20px auto 0px;
      font-size: 13px;

      button {
        border: none;
        background: none;
        width: 50%;
        padding: 10px;
        font-weight: 500;
      }

      #salvar-configuracoes {
        background: green;
        color: #fff;
        border-right: 1px solid #828282;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      #limpar-configuracoes {
        background: #c5bfbf;
        color: black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(128, 187, 224)),
        color-stop(0.72, rgb(80, 132, 166)),
        color-stop(0.86, rgb(95, 163, 204))
      );
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* min-height: 65px; */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 9px #4848488a;
  margin-top: 12px;
  font-size: 82%;
`

export const WrapperInput = styled.div`
  display: flex;
  height: 100%;
  min-width: 150px;
  align-items: center;
  justify-items: center;
  border-left: 1px solid #c1c1c1;
  margin-left: 5px;
  user-select: none;
  div {
    margin-top: 10px;
    margin-left: 15px;
    label {
      margin-left: 5px;
    }
  }
`

export const ContainerMenu = styled.div`
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin-left: 30px;
`

export const TabLista = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  color: #fff;
  height: 23px;
  margin: 0 6px;
  background: #409ac3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 120px;
  width: 250px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  opacity: ${props => (props.active ? 1 : '0.6')};
  cursor: pointer;
  transition: 100ms;
  user-select: none;
  text-transform: uppercase;

  .active {
    background: #c34069;
  }

  :hover {
    height: 25px;
    opacity: ${props => (props.active ? 1 : '0.8')};
  }

  .counter {
    position: absolute;
    right: -4px;
    top: -5.5px;
    border-radius: 7px;
    padding: 0px 4px;
    background: ${props => (props.active ? '#f9ab19' : '#fff')};
    color: black;
    min-width: 30px;
    font-size: 11px;
    font-weight: 700;
    box-shadow: 0px 0px 5px #3e3c3c;
    transition: 400ms;
    user-select: none;
  }
`
