import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import $ from "jquery";
import ModalLoadingStore from "./ModalLoadingStore";
import "../Debug";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class LicitacoesStore {
  @observable
  licitacoes = [];
  @observable
  licitacoes_atual = [];
  @observable
  licitacoes_total = 0;
  @observable
  numero_licitacoes_agendados = 0;
  @observable
  numero_licitacoes_andamento = 0;
  @observable
  numero_licitacoes_ata = 0;
  @observable
  numero_licitacoes_canceladas = 0;
  @observable
  numero_licitacoes_realizadas = 0;
  @observable
  tipo_licitacao = "todas";
  @observable
  numero_licitacoes_atual = 0;
  @observable
  carregando = false;
  @observable
  carregar_local = false;
  @observable
  pagina_atual = 1;

  @action
  consulta_api = () => {
    if (!this.carregar_local) ModalLoadingStore.handleOpenModal();
    this.carregando = true;
    Api.get(
      `licitacoes/licita/${MaquinaDeEstadosStore.getIdEmpresa()}`,
      {}
    )
      .then(response => {
        this.licitacoes = response.data.licitacoes;
        this.licitacoes_total = response.data.licitacoes_total;
        this.numero_licitacoes_agendados =
          response.data.numero_licitacoes_agendados;
        this.numero_licitacoes_andamento =
          response.data.numero_licitacoes_andamento;
        this.numero_licitacoes_ata = response.data.numero_licitacoes_ata;
        this.numero_licitacoes_canceladas =
          response.data.numero_licitacoes_canceladas;
        this.numero_licitacoes_realizadas =
          response.data.numero_licitacoes_realizadas;
        if (!this.carregar_local) {
          this.pagina_atual = 1;
          this.numero_licitacoes_atual = this.licitacoes_total;
          //this.licitacoes_atual = this.licitacoes;
          //this.onPageChanged({currentPage:1,pageLimit:15});
          this.muda_tipo_licitacao("todas");
        }
        $("#" + this.tipo_licitacao).addClass("active");
        this.carregar_local = true;
        this.carregando = false;
      })
      .catch(error => {
        this.licitacoes = [];
        this.carregando = false;
        this.carregar_local = true;
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action
  muda_tipo_licitacao(tipo) {
    $("#" + this.tipo_licitacao).removeClass("active");
    this.tipo_licitacao = tipo;
    let numero_lc_atual = 0;
    if (tipo === "todas") {
      numero_lc_atual = this.licitacoes_total;
      $("#" + tipo).addClass("active");
    } else if (tipo === "andamento") {
      numero_lc_atual = this.numero_licitacoes_andamento;
      $("#" + tipo).addClass("active");
    } else if (tipo === "agendados") {
      numero_lc_atual = this.numero_licitacoes_agendados;
      $("#" + tipo).addClass("active");
    } else if (tipo === "realizadas") {
      numero_lc_atual = this.numero_licitacoes_realizadas;
      $("#" + tipo).addClass("active");
    } else if (tipo === "cancelados") {
      numero_lc_atual = this.numero_licitacoes_canceladas;
      $("#" + tipo).addClass("active");
    } else if (tipo === "ata") {
      numero_lc_atual = this.numero_licitacoes_ata;
      $("#" + tipo).addClass("active");
    }
    this.numero_licitacoes_atual = numero_lc_atual;
    this.pagina_atual = 1;
  }
  @action
  onPageChanged = data => {
    let licitacoes = this.licitacoes;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    let licitacoesatuais = licitacoes.slice(offset, offset + pageLimit);
    if (this.tipo_licitacao === "todas")
      licitacoesatuais = licitacoes.slice(offset, offset + pageLimit);
    else if (this.tipo_licitacao === "andamento")
      licitacoesatuais = licitacoes
        .filter(lic => lic.situacao.cor === "#3c8dbc")
        .slice(offset, offset + pageLimit);
    else if (this.tipo_licitacao === "agendados")
      licitacoesatuais = licitacoes
        .filter(lic => lic.situacao.cor === "#228B22")
        .slice(offset, offset + pageLimit);
    else if (this.tipo_licitacao === "realizadas")
      licitacoesatuais = licitacoes
        .filter(lic => lic.situacao.cor === "#FFA500")
        .slice(offset, offset + pageLimit);
    else if (this.tipo_licitacao === "cancelados")
      licitacoesatuais = licitacoes
        .filter(lic => lic.situacao.cor === "#FF4500")
        .slice(offset, offset + pageLimit);
    else if (this.tipo_licitacao === "ata")
      licitacoesatuais = licitacoes
        .filter(lic => lic.situacao.cor === "#7CCD7C")
        .slice(offset, offset + pageLimit);
    this.licitacoes_atual = licitacoesatuais;
    this.pagina_atual = currentPage;
  };
  @action
  formatDate(data) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).format(new Date(data));
  }

  @action limparVariaveis = () => {
    this.licitacoes = [];
    this.licitacoes_atual = [];
    this.licitacoes_total = 0;
    this.numero_licitacoes_agendados = 0;
    this.numero_licitacoes_andamento = 0;
    this.numero_licitacoes_ata = 0;
    this.numero_licitacoes_canceladas = 0;
    this.numero_licitacoes_realizadas = 0;
    this.tipo_licitacao = "todas";
    this.numero_licitacoes_atual = 0;
    this.carregando = false;
    this.carregar_local = false;
    this.pagina_atual = 1;
  };
}

const licitacoesStore = new LicitacoesStore();
export default licitacoesStore;
