import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Trash2 } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Pagination } from '@material-ui/lab';
import Spinner from '../../../UsefulComponents/Spinner';
import SubHeader from '../SubHeader/SubHeader';
import SectionTitle from '../SubHeader/SectionTitle';
import { Body, Container, ContainerPagination, ContainerTable } from '../Style';
import { columnsReprovados } from './Column';
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import { sortEditais } from '../Components/sortEditais';
import StatusModal from '../StatusNotice';
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext';
import BotaoFiltrar from "../Filter/botaoFiltro";
import FiltroQualificados from "../Filter/filtroQualificados";

const Reprovados = inject(
  'ReprovadosStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(
    ({ ReprovadosStore, MaquinaDeEstadosStore, DetalhesEditalStore }) => {
      const {
        isFetching,
        fetchEdital,
        editais,
        total_registros,
        total_paginas,
        pagination,
        crescente,
        dataIsFiltered,
        fetchEditais
      } = ReprovadosStore
      const { id_fornecedor, getStatus } = MaquinaDeEstadosStore
      const { toggleModal } = DetalhesEditalStore
      return (
        <ReprovadosComStore
          toggleModal={toggleModal}
          data={{ isFetching, fetchEdital }}
          crescente={crescente}
          idEmpresa={id_fornecedor}
          editais={editais}
          total_registros={total_registros}
          total_paginas={total_paginas}
          pagination={pagination}
          getStatus={getStatus}
          dataIsFiltered={dataIsFiltered}
          fetchEditais={fetchEditais}
        />
      )
    }
  )
)

const ReprovadosComStore = ({
  data,
  idEmpresa,
  editais,
  total_paginas,
  pagination,
  crescente,
  toggleModal,
  getStatus,
  dataIsFiltered,
  fetchEditais
}) => {
  const { isFetching, fetchEdital } = data;
  const { state, openFiltro } = useFiltroEdital()
  
  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (state.filtro) openFiltro(false)
    if (idEmpresa) fetchEditais()
  }, [idEmpresa])

  const handlePagina = (event, value) => {
    if (value === pagination.page) return
    pagination.changePage(value)
    dataIsFiltered ? fetchEdital(state) : fetchEditais()
  }

  if (isFetching) return <Spinner />
  
  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="REPROVADOS" icon={<Trash2 />} />
        <BotaoFiltrar/>
      </SubHeader>
      <Body>
        <ContainerTable filter={editais.length}>
          <FiltroQualificados status={'reprovado'} />
        {!state.filtro && (
        <>
          <DataTable
            className="tabela-reprovados"
            fixedHeader
            allowOverflow={true}
            overflowY={true}
            noHeader
            responsive={true}
            columns={columnsReprovados}
            onRowClicked={edital => toggleModal(edital.id, state)}
            data={editais}
            onSort={sortEditais}
            defaultSortAsc={crescente}
            sortServer={true}
            striped={true}
            highlightOnHover
            noDataComponent={
              <ExclamationWarning
                title="NENHUM EDITAL ENCONTRADO"
                height="70vh"
              />
            }
          />
          <ContainerPagination>
            <div>
              <Pagination
                count={total_paginas}
                color="primary"
                shape="rounded"
                page={pagination.page}
                onChange={(event, value) => handlePagina(event, value)}
                showFirstButton
                showLastButton
              />
            </div>
          </ContainerPagination>
          </>
      )}
        </ContainerTable>
      </Body>
      <StatusModal />
    </Container>
  )
}

export default Reprovados
