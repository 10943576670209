import styled from "styled-components";

// const changeBackground = color => {
//   switch (color) {
//     case "#4BA22C":
//       return "#3dc30d1c";
//     case "#CE4747":
//       return "#ff7e7e1f";
//     case "#dad6d6":
//       return "#f7f7f7";
//     default:
//       return "#fdfdfd";
//   }
// };

export const SingleMessageContainer = styled.div`
  display: flex;
  background-color: ${props => (props.verificada ? "#f3f3f3" : "white")};
  margin: 0.5rem;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  background-blend-mode: lighten;
  /* height: 100%; */
  min-height: 100px;
  align-content: stretch;
  cursor: pointer;
  position: relative;
  animation: fadeSlide 0.4s ease-in;

  @keyframes fadeSlide {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .icon-container {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4%;
    min-width: 50px;
    height: 100%;

    .icon-mess {
      font-size: 1.5rem;
      color: ${props => (props.verificada ? "#B6B6B6" : "#ce4747")};
    }
  }

  .message-container {
    display: flex;
    min-height: 100px;
    flex-direction: column;
    width: 70%;
    min-width: 70%;
    /* border-right: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8; */
    height: 100%;
    padding: 15px;
    overflow: auto;
    align-self: flex-start;
    /* flex-grow: 1; */

    .container-title {
      display: flex;
      width: 100%;

      justify-content: space-between;
      font-size: ${props => props.titleSize || "0.7"}rem;
      color: #575757;
      margin-bottom: 10px;
      align-self: flex-start;
    }

    .message-body {
      font-size: ${props => props.bodySize || "0.9"}rem !important;
      font-weight: 500;
      height: 100%;
      align-items: center;
      word-wrap: break-word;
    }

    .TagMarkRed {
      display: inline;
      /*display: inline-block;*/
      padding: 0px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;

      padding-right: 5px;
      padding-left: 5px;
      border-radius: 10rem;
      font-size: ${props => props.bodySize || "0.9"}rem !important;
      color: #fff;
      background-color: #cb0000;
    }

    .TagMarkSucesses {
      display: inline;
      /*display: inline-block;*/
      padding: 0px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;

      padding-right: 5px;
      padding-left: 5px;
      border-radius: 10rem;
      font-size: ${props => props.bodySize || "0.9"}rem !important;
      color: #fff;
      background-color: #15a300;
    }
  }

  .company-container {
    align-self: flex-start;
    padding: 15px;
    height: 100%;
    /* min-width: 180px; */
    /* width: auto; */
    min-height: 100px;
    flex-grow: 0;
    width: 13%;

    .container-title {
      display: flex;
      font-size: ${props => props.titleSize || "0.7"}rem;
      margin-bottom: 10px;
      color: #969696;
    }
    .message-body {
      font-size: ${props => props.bodySize + 0.15 || "1.15"}rem;
      font-weight: 700;
      text-shadow: #3a566e1c 1px 1px 3px;
      word-wrap: break-word;
    }
  }

  .data-container {
    align-self: auto;
    height: 100%;
    width: 13%;
    /* min-width: 180px; */
    /* width: auto; */
    flex-grow: 0;
    padding: 15px;

    .container-title {
      display: flex;
      font-size: ${props => props.titleSize || "0.6"}rem;
      color: #969696;
      justify-content: center;
      text-align: center;
    }
    .message-body {
      font-size: ${props => props.bodySize || "0.7"}rem;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }
  }
`;

export const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #d8d8d8;
  margin-left: -15px;
`;

export const ButtonOcorrencia = styled.button`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  color: #28a745;
  border-color: #28a745;
  background-color: transparent;
  background-image: none;

  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;

  cursor: pointer;
  -webkit-appearance: button;
  //
  :hover {
    background-color: #28a745;
    color: white;
  }
  :focus {
    background-color: #28a745;
    color: white;
  }
`;

export const DadosMsg = styled.span`
  padding: 0.25rem 0.5rem;
  font-size: ${props => props.size};
`;

export const ContainerOcorrencia = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${props => props.justifyContent};
`;

export const MenuOcorrencia = styled.div`
  width: 100%;
  padding: 15px;
`;

export const ContainerOcorrenciaRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;
