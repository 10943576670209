import styled from 'styled-components';

export const Overlay = styled.div`
  z-index: 3123213;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000000c9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #0000008c;
  position: relative;
  padding-top: 80px;
  animation: 1s fadeIn ease-in-out;

  .header {
    display: flex;
    align-items: center;
    width: 80px;
    flex-direction: column;
    position: absolute;
    right: calc(50% - 40px);
    justify-content: center;
    top: -35px;

    .icon {
      border-radius: 50px;
      height: 70px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props => props.color};
      color: #fff;
      box-shadow: 1px 1px 5px #000000d6;
      svg {
        height: 45px;
        width: 45px;
      }
    }
    .title {
      margin-top: 10px;
      color: #4ea04e;
      text-align: center;
      font-weight: 500;
    }
  }

  .body {
    padding: 20px;

    .select-user, .nivel-prioridade {
      margin-bottom: 30px;
    }

    .title {
      margin-top: auto;
      font-size: 70%;
      font-weight: 700;
      text-align: left;
      color: #989898;
      margin-bottom: 10px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 30px;

    .confirmar {
      width: 50%;
      border-bottom-left-radius: 15px;
      background: #32a532;
      padding: 10px;
      color: #fff;
      font-weight: 500;
      user-select: none;
      transition: 200ms;
      cursor: ${props => props.isFetching ? 'not-allowed' : 'pointer'};
      opacity:  ${props => props.isFetching ? '0.6' : '1'};

      :hover {
        background: ${props => props.isFetching ? '#32a532' : '#2c6b2c'};
      }
    }

    .fechar {
      width: 50%;
      border-bottom-right-radius: 15px;
      font-weight: 500;
      background: #c7c7c7;
      padding: 10px;
      user-select: none;
      transition: 200ms;
      cursor: ${props => props.isFetching ? 'not-allowed' : 'pointer'};
      opacity:  ${props => props.isFetching ? '0.6' : '1'};

      :hover {
        background: ${props => props.isFetching ? '#c7c7c7' : '#797979'};
        color: ${props => props.isFetching ? '#000' : '#c7c7c7'};
      }
    }
  }
`
