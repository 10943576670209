import React, { useContext, useRef } from 'react';
import { FileText } from 'react-feather';

import SubHeader from '../SubMenu/SubMenu';
import SectionTitle from '../SubMenu/SectionTitle';
import PropostaEspecifica from './PropostaEspecifica/PropostaEspecifica';
import ListaDeLicitacoes from './ListaDeLicitacoes/ListaDeLicitacoes';
import Spinner from '../../../../UsefulComponents/Spinner';
import ModalCarregar from './ListaDeLicitacoes/detalhes/modalCarregar/ModalCarregar';
import { PropostaEspecificaContext } from './PropostaEspecifica/context';
import { Container } from './styles';

const clearProposlaData = (ref, companyId, cleanData) => {
  if (!ref) return ref = companyId
  if (ref.current !== companyId) {
    ref = companyId
    cleanData()
  }
}

const CadastrarProposta = ({ companyId }) => {

  const { edital, loading, selectedItems, cleanData } = useContext(PropostaEspecificaContext);
  const ref = useRef()

  clearProposlaData(ref.current, companyId, cleanData)
  
  return (
    <Container>
      <SubHeader status={true} overflow>
        <SectionTitle title="CADASTRAR PROPOSTA" icon={<FileText />} />
        {edital &&
          <div className="edital-info-prop">
            {`ITENS SELECIONADOS: ${selectedItems.length}`}
            &nbsp; &nbsp;&nbsp; &nbsp;
            {`EDITAL: ${edital.numero}`} &nbsp; &nbsp;&nbsp; &nbsp;
            {`UASG: ${edital.comprador.codigo}`}
          </div>
        }
      </SubHeader>
      {loading ? <Spinner /> : edital ? <PropostaEspecifica /> : <ListaDeLicitacoes />}
      <ModalCarregar />
    </Container>
  )
}

export default CadastrarProposta
