import React from "react";
import MaquinaDeEstadosStore from "../Stores/MaquinaDeEstadosStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonitoramentoStore from "../Stores/MonitoramentoStore";
import { getEmpresaId } from "../Services/auth";
import LicitacaoStores from "../Stores/LicitacaoStores";
import PainelAvisoStores from "../Stores/PainelAvisoStores";

export default class TemplateModalAlert extends React.Component {
  redireciona = () => {
    const {
      id_licitacao,
      id_fornecedor,
      type
      /* portal */
    } = this.props.customFields;
    MonitoramentoStore.flagFirebase();
    if (
      Number(MaquinaDeEstadosStore.getIdEmpresa()) !== Number(id_fornecedor)
    ) {
    } else {
      if (type !== "situacao") {
        MaquinaDeEstadosStore.redireciona_monitoramento();

        setTimeout(function () {
          MonitoramentoStore.consulta_especifica(id_licitacao);
        }, 1000);
        PainelAvisoStores.consulta_api(parseInt(getEmpresaId()));
      } else {
        MaquinaDeEstadosStore.redireciona_licitacao();
        LicitacaoStores.consulta_api(id_licitacao);
      }
    }
  };

  render() {
    const {
      customFields: { empresa, licitacao_cod, portal, situacao, tipostring }
    } = this.props;

    return (
      <div
        className={this.props.classNames}
        id={this.props.id}
        style={this.props.styles}
      >
        <div
          className="s-alert-box-inner"
          onClick={this.redireciona}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: tipostring === "situacao" ? "20px" : 0
            }}
          >
            <span>
              <FontAwesomeIcon
                icon={"comments"}
                style={{
                  fontSize: "20px",
                  color: "rgba(235,244,245,0.49)",
                  marginRight: "10px"
                }}
              />
              <b>{this.props.message}</b>
            </span>
          </div>

          {tipostring === "situacao" && (
            <div>
              <small style={{ fontSize: "12px" }}>SITUAÇÃO:</small> {situacao}
            </div>
          )}

          {licitacao_cod && (
            <div>
              <small style={{ fontSize: "12px" }}>LICITACÃO:</small>{" "}
              {licitacao_cod}
            </div>
          )}
          {empresa && (
            <div>
              <small style={{ fontSize: "12px" }}>EMPRESA:</small> {empresa}
            </div>
          )}
          {empresa && (
            <div>
              <small style={{ fontSize: "12px" }}>PORTAL:</small> {portal}
            </div>
          )}
        </div>
        <span className="s-alert-close" onClick={this.props.handleClose} />
      </div>
    );
  }
}
