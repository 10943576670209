import React from 'react'
import { format } from 'date-fns'
import { Check } from 'react-feather'

import { tratarPrioridade } from '../../Components/tratarPrioridade'
import ButtonsDisqualify from '../../StatusNotice/disqualifyButton'
import maquinadeestadosstore from '../../../../Stores/MaquinaDeEstadosStore'

import { ReactTooltip } from '../../../../globalStyles'

const formattedDate = data => {
  return format(new Date(data), 'dd/MM/yyyy')
}
export const columnsAprovados = [
  {
    name: 'NUM.',
    selector: 'numero',
    width: '100px',
    wrap: true,
    cell: ({ licitacao, selecionado: { download_edital } }) => {
  
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReactTooltip />
            <b>{licitacao}</b>
            {download_edital &&
              <Check
                style={{ width: '15px', marginLeft: '2px', color: 'green' }}
                data-tip="DOWNLOAD JÁ EFETUADO"
                data-type="success"
                data-place="right"
                data-effect="solid"
              />}
          </div>
        )
      }
  },
  {
    name: 'OBJETO',
    selector: 'objeto',
    grow: 5,
    wrap: true
  },
  {
    name: 'DATA DISPUTA',
    selector: 'disputa',
    sortable: true,
    center: true,
    grow: 1,
    style: {
      fontWeight: 'bold',
      marginRight: '9px'
    },
    cell: ({ disputa }) => {
      return formattedDate(disputa)
    }
  },
  {
    name: 'ORGÃO',
    selector: 'orgao',
    sortable: true,
    grow: 3,
    wrap: true
  },

  {
    name: 'PORTAL',
    selector: 'portal',
    sortable: true,
    grow: 1,
    cell: ({ portal }) => <div className="hover-portal-tabela">{portal}</div>
  },

  {
    name: 'UF',
    selector: 'uf',
    sortable: true,
    grow: 0.3,
    wrap: true
  },

  {
    name: 'PRIORIDADE',
    selector: 'prioridade',
    sortable: true,
    wrap: true,
    center: true,
    grow: 0.7,
    cell: ({ prioridade }) => tratarPrioridade(prioridade)
  },
  {
    name: 'STATUS',
    selector: 'status',
    wrap: true,
    center: true,
    grow: 0.3,
    cell: edital => (
      <ButtonsDisqualify
        edital={edital}
        status={maquinadeestadosstore.status.reprovado[2]}
      />
    )
  }
]
