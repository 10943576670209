import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {format} from 'date-fns'
import {Bookmark} from 'react-feather';
import DataTable from 'react-data-table-component';
import {Pagination} from '@material-ui/lab';

import {columnsAprovados} from './Column';
import {useFiltroEdital} from '../Filter/context/FiltroEditaisContext';
import {ExclamationWarning} from '../../../Components/Notifications/ExclamationWarning';
import {sortEditais} from '../Components/sortEditais';
import Spinner from '../../../UsefulComponents/Spinner';
import SubHeader from '../SubHeader/SubHeader';
import SectionTitle from '../SubHeader/SectionTitle';
import BotaoFiltrar from "../Filter/botaoFiltro";
import StatusModal from '../StatusNotice';
import FiltroQualificados from "../Filter/filtroQualificados";

import {Body, Container, ContainerPagination, ContainerTable} from '../Style';

const Aprovados = inject(
  'AprovadosStore',
  'MaquinaDeEstadosStore',
  'DetalhesEditalStore'
)(
  observer(({AprovadosStore, MaquinaDeEstadosStore, DetalhesEditalStore}) => {
    // NECESSÁRIO CHAMAR AS VARIÁVEIS QUE VÃO SER OBSERVADAS AQUI.
    const {
      isFetching,
      fetchEdital,
      editais,
      total_registros,
      total_paginas,
      pagination,
      crescente,
      fetchEditais,
      dataIsFiltered
    } = AprovadosStore
    const {toggleModal} = DetalhesEditalStore
    const {id_fornecedor, getStatus} = MaquinaDeEstadosStore
    return (
      <AprovadosComStore
        toggleModal={toggleModal}
        data={{isFetching, fetchEdital}}
        idEmpresa={id_fornecedor}
        editais={editais}
        total_registros={total_registros}
        total_paginas={total_paginas}
        pagination={pagination}
        crescente={crescente}
        getStatus={getStatus}
        fetchEditais={fetchEditais}
        dataIsFiltered={dataIsFiltered}
      />
    )
  })
)

const AprovadosComStore = ({
   data,
   idEmpresa,
   editais,
   total_paginas,
   pagination,
   crescente,
   toggleModal,
   getStatus,
   fetchEditais,
   dataIsFiltered
 }) => {
  const {isFetching, fetchEdital,} = data
  const { state, openFiltro } = useFiltroEdital()

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (state.filtro) openFiltro(false)
    if (idEmpresa) fetchEditais()
  }, [idEmpresa])


  const handlePagina = (event, value) => {
    if (value === pagination.page) return
    pagination.changePage(value)
    dataIsFiltered ? fetchEdital(state) : fetchEditais()
  }

  if (isFetching) return <Spinner/>
  
  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="APROVADOS" icon={<Bookmark/>}/>
        <BotaoFiltrar/>
      </SubHeader>
      <Body>
        <ContainerTable filter={editais.length}>
          <FiltroQualificados status={'aprovado'}/>
          {!state.filtro && (
            <>
              <DataTable
                className="tabela-padrao"
                fixedHeader
                allowOverflow={true}
                overflowY={true}
                noHeader
                responsive={true}
                columns={columnsAprovados}
                onRowClicked={edital => toggleModal(edital.id, state)}
                data={editais}
                onSort={sortEditais}
                sortServer={true}
                defaultSortAsc={crescente}
                highlightOnHover
                striped={true}
                noDataComponent={
                  <ExclamationWarning
                    title="NENHUM EDITAL ENCONTRADO"
                    height="75vh"
                  />
                }
              />
              <ContainerPagination>
                <div>
                  <Pagination
                    count={total_paginas}
                    color="primary"
                    shape="rounded"
                    page={pagination.page}
                    onChange={(event, value) => handlePagina(event, value)}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </ContainerPagination>
            </>
          )}
        </ContainerTable>
      </Body>
      <StatusModal/>
    </Container>
  )
}

export default Aprovados
