import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: #fafafa;
`

export const ContainerSearch = styled.div`
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
  /* overflow: auto; */
  overflow: inherit;

  h3 {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    color: #bb0e0e;
  }

  #limpar-busca-manual {
    background: #d20808ab;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    box-shadow: 1px 1px 4px #00000080;
    font-size: 15px;
    padding: 5px 13px;

    svg {
      margin-right: 7px;
    }
  }

  .width-pattern {
    width: 1050px;
  }

  @media screen and (max-width: 3000px) {
    .width-pattern {
      width: 1635px;
    }
  }

  @media screen and (max-width: 1779px) {
    .width-pattern {
      width: 1110px;
    }
  }

  @media screen and (max-width: 1214px) {
    .width-pattern {
      width: 545px;
    }
  }
`

export const CardFiltroData = styled.div`
  min-width: 545px;
  width: 545px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  padding: 0;
  border: 1px solid #ababab;
  margin: 10px;
`

export const CardFiltro = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 545px;
  width: 545px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  padding: 0;
  border: 1px solid #ababab;
  margin: 10px;
`
export const CardFiltroSmall = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 545px;
  width: 545px;
  min-height: 170px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  padding: 0;
  border: 1px solid #ababab;
  margin: 10px;
`

export const CardHeard = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  height: 45px;
  width: 100%;
  color: white;
  padding: 2px 15px;
  border-radius: 9px 9px 0px 0px;
  border: 1px solid #348cb5;
  background-color: #348cb5;

  .palavras {
    font-size: 12px;
    padding: 3px 10px;
    border: 0.5px solid white;
    border-radius: 20px;
    margin-left: 122px;
    white-space: nowrap;

    transition: 500ms;

    :hover {
      cursor: pointer;
      transform: scale(1.05);
      background: #568240;
      color: #ffffff;
    }
  }
`
export const CardTitulo = styled.div`
  height: 100%;
  width: 65%;
  margin-left: 10px;
  div {
    font-weight: bold;
    font-size: 14px;
  }
  p {
    color: #ececec;
    font-size: 12px;
  }
`

export const InputData = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: #19395f;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  height: 85%;
  height: ${props => props.height && props.height};
  flex-direction: column;

  #date-selection {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      font-style: italic;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }

    div.date-picker-input {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }

    div.date-picker-selection {
      div.rdr-DateRange {
        border-radius: 20px !important;
        box-shadow: 1px 1px 6px #00000054;
        background: #fff;
        margin-top: 30px;
        span {
          font-style: normal;
          font-weight: normal;
          margin-bottom: 0;
        }
        .rdr-Calendar {
          background: transparent !important;
        }
      }
    }
  }

  .alerta-limite-maximo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 15px;
    font-size: 14px;
    color: #8a8a8a;

    svg {
      margin-right: 10px;
    }
  }

  .botoes-container {
    display: flex;
    /* border-radius: 15px; */
    width: 80%;
    margin: auto;

    button {
      border: none;
      background: none;
      width: 50%;
      padding: 10px;
      font-weight: 500;
    }

    #filtrar-boletim {
      background: green;
      color: #fff;
      border-right: 1px solid #828282;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    #fechar-modal-filtro-boletim {
      background: #c5bfbf;
      color: black;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .MuiChip-deleteIconSmall {
    color: rgba(255, 255, 255, 0.7);
  }
`

export const FormControl = styled.div`
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  height: 34px;
  padding-left: 35px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 95%;
`

export const FormGroup = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  display: flex;
`

export const InputGroup = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  border-collapse: separate;
  box-sizing: border-box;

  .form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    height: 34px;
    padding-left: 35px;
    padding-right: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  :focus {
    .input {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
      outline: 0;
    }
  }

  select,
  input {
    font-size: 80%;
  }
`

export const InputIcone = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
  width: 40px;
  margin-right: -30px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 04px;
  color: #224140;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;

  svg {
    width: 17px;
    height: 17px;
  }
`