import React, { useState } from "react";
import {
  CardBodyMsg,
  CardMsg,
  CardWarpMsg,
  CardWarpPaginacao
} from "../StyleLicitacao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../../Stores/Funcoes/Pagination";
import { FormControl } from "react-bootstrap";

const TabelaLinhaTempo = ({ tracks }) => {
  const [linhaTempo, setLinhaTempo] = useState(tracks);
  const [pagina, setPagina] = useState(1);
  const [numeroPaginas, setNumeroPaginas] = useState(5);

  const onPageChanged = data => {
    let newTracks = tracks;
    let { currentPage, pageLimit } = data;
    currentPage = currentPage === 0 ? 1 : currentPage;
    const offset = (currentPage - 1) * pageLimit;
    let dados = newTracks.slice(offset, offset + pageLimit);
    setLinhaTempo(dados);
    setPagina(currentPage);
  };

  const handleTab = e => {
    setNumeroPaginas(Number(e.target.value));
  };

  return (
    <>
      {linhaTempo.map(({ title, description, created_at, user_name }) => {
        return (
          <CardWarpMsg>
            <CardMsg>
              <CardBodyMsg>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center"
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <FontAwesomeIcon size={"2x"} icon="chart-line" />
                  </div>
                  <div style={{ padding: "10px" }}>{title}</div>
                  <div style={{ padding: "10px" }}>{description}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: 'column'
                  }}
                >
                  <b>{user_name}</b>
                  {created_at}
                </div>
              </CardBodyMsg>
            </CardMsg>
          </CardWarpMsg>
        );
      })}

      {tracks.length > 5 && (
        <CardWarpPaginacao>
          <Pagination
            totalRecords={tracks.length}
            pageLimit={numeroPaginas}
            pageNeighbours={1}
            defaultCurrent={pagina}
            onPageChanged={onPageChanged}
            key={numeroPaginas + 1}
            flex="none"
          />
          <div
            style={{
              display: "flex",
              marginLeft: "50px",
              fontSize: "12px",
              alignItems: "center"
            }}
          >
            {tracks.length > 5 && (
              <>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <FormControl
                    style={{ fontSize: "12px", marginBottom: "10px" }}
                    id="fornecedor"
                    name="fornecedor"
                    componentClass="select"
                    defaultValue={numeroPaginas}
                    onChange={e => handleTab(e)}
                  >
                    <option key={5} value={5}>
                      5
                    </option>
                    <option key={10} value={10}>
                      10
                    </option>
                    <option key={20} value={20}>
                      20
                    </option>
                    <option key={50} value={50}>
                      50
                    </option>
                    <option key={100} value={100}>
                      100
                    </option>
                  </FormControl>
                </div>
                <div style={{ marginLeft: "10px", marginTop: "-10px" }}>
                  Itens por página
                </div>
              </>
            )}
          </div>
        </CardWarpPaginacao>
      )}
    </>
  );
};
export default TabelaLinhaTempo;
