import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 99.9%;
  height: 100%;
  background: #d3d3d3;
  align-content: stretch;
  flex: 1;
  padding: 3px 10px;
  overflow-x: hidden;
`;

export const Collumn = styled.div`
  display: flex;
  align-content: stretch;
  min-width: 360px;
  width: 31%;
  flex-direction: column;
  flex: 1;
  margin: 5px;
  h2 {
    font-size: 19px;
  }
  h5 {
    font-size: 15px;
  }
  span {
    font-size: 11px;
  }

  @media only screen and (max-width: 1200px) {
    width: 45%;
  }
  @media only screen and (max-width: 850px) {
    width: 96%;
  }
`;

export const Titulo = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : "60%")};
  height: 100%;
  justify-content: center;

  h5 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0px;
  }
  span {
    font-size: 13px;
    font-weight: 200;
    /* margin-top: -5px; */
    font-style: normal;
    color: #6d6d6d;
  }
  p{
   font-size: 9px;
   font-weight: 200;
   margin: 0;
    font-style: normal;
    color: #6d6d6d;
  }
`;

export const CardMD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${props => props.color};
  background: white;
  box-shadow: 0px 2px 4px 1px #00000060;
  border-radius: 7px;
  margin: 6px;
  align-items: center;
  flex: 1;
  animation: slide-in-fwd-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(800px);
      transform: translateZ(-1400px) translateY(800px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
`;

export const CardMDHearder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 65px;
  padding-left: 15px;
  height: ${props => (props.height ? props.height : "auto")};
`;

export const CardMDBarra = styled.div`
  display: flex;
  width: 100%;
  background: #969696;
  height: 1px;
  background: ${props => props.color};
`;

export const CardMDBody = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  margin: 10px;
  flex: 1;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
