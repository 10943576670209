import {observable, action} from "mobx";
import Api from "./Conexao/conexao";
import ConfiguracoesGeraisStores from "./ConfiguracoesGeraisStores";
import {
  AlertaError,
  AlertaSuccess,
  AlertaVermelho
} from "./Funcoes/FuncoesGerais";
import "../Debug";
import {statusConexao} from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

let status = false;

class ConfiguracoesCredenciaisStores {
  @observable
  key = 0;
  @observable
  portal = 5;
  @observable
  login = "";
  @observable
  password = "";
  @observable
  repeat_password = "";
  @observable
  fornecedor = "";
  @observable
  fornecedorCredencial = "";
  @observable
  status = false;
  @observable
  validacaoExcluir = false;
  @observable
  senha = "";
  @observable
  status_modal = false;
  @observable
  ModalSincronizar = false;
  @observable
  contratosEmpresaSelecionada = [];
  @observable
  sync = false;

  @action
  atualiza_dados = (login, password) => {
    this.login = login;
    this.password = password;
    this.repeat_password = password;
  };

  @action
  toggleModal = () => {
    this.status_modal = !this.status_modal;
  };

  @action
  limpa_campos = () => {
    this.portal = 5;
    this.login = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedor = 0;
  };

  @action
  onChange = e => {
    const {name, id, value, checked} = e.target;
    if (name === "sync") return (this.sync = checked);
    // Adição de J antes do login se portal for licitações E
    if (this.portal === "6" && name === "login")
      return (this.login = value[0] === "J" ? value : "J" + value);

    const campo = name || id;
    this[campo] = value;
  };

  @action
  busca_usuarios = id => {
    Api.get("get_credencial/" + id + "/")
      .then(response => {
        this.key = 1;
        this.key = 2;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  @action
  busca_dados = (login, password) => {
    this.login = login;
    this.password = password;
    this.repeat_password = password;
  };

  //  Funcoes para editar credenciais
  @action
  salva_credencial = async (portal_id, toggleModal) => {
    const {fornecedor, login, password, sync} = this;
    const data = {
      portal: portal_id,
      fornecedor_pk: fornecedor,
      login,
      password,
      sync
    };

    try {
      await Api.post("/cadastra_credencial/", data);
      ConfiguracoesGeraisStores.consulta_api();
      this.status = false;
      AlertaSuccess("Credenciais editadas com sucesso!");
      this.limpa_campos();
      toggleModal(false);
    } catch (err) {
      if (err.response.data.error) AlertaVermelho(err.response.data.error);
      else AlertaVermelho("Erro ao editar credencial, tente mais tarde");
    }
  };

  @action
  editar_credencial_validacao_admin = (portal_id, funcaoFecharModal) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);

    Api.post("/login/", login, {})
      .then(response => {
        this.salva_credencial(portal_id, funcaoFecharModal);
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  //------------------------------

  @action
  validaCredencial = () => {
    Api.post(
      "/valida_credencial/",
      {
        portal: "5",
        fornecedor: this.fornecedor,
        login: this.login,
        password: this.password
      },
      {}
    )
      .then(response => {
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });
  };

  //  Funcoes para salvar credenciais
  @action
  adiciona_credencial = async closeModal => {
    let {portal, fornecedor, login, password, sync} = this;
    login = portal === "6" ? login.slice(1) : login;
    const data = {portal, fornecedor_pk: fornecedor, login, password, sync};
    try {
      await Api.post("/cadastra_credencial/", data);
      const sinc = this.contratosEmpresaSelecionada
        .slice(2)
        .some(el => el.status === true);
      if (sinc && Number(portal) === 5) this.ModalSincronizar = this.fornecedor;
      this.status = false;
      this.limpa_campos();
      closeModal(false);
    } catch (err) {
      if (err.response.data.error) AlertaVermelho(err.response.data.error);
      else
        AlertaVermelho("Erro ao cadastrar nova credencial, tente mais tarde");
    }
  };

  @action
  adicionar_credencial_validacao_admin = async closeModal => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);
    const res = await Api.post("/contratos_empresa/", {
      fornecedor: this.fornecedor
    });
    this.contratosEmpresaSelecionada = res.data.contratos;
    Api.post("/login/", login, {})
      .then(response => {
        this.adiciona_credencial(closeModal);
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  excluir_credencial = (id, toggleModal) => {
    Api.post("cad_credencial/" + id + "/delete/")
      .then(response => {
        AlertaSuccess("Crendecial excluída com sucesso!");
        toggleModal(false);
        ConfiguracoesGeraisStores.consulta_api();
        this.key = 1;
        this.key = 2;
        this.key = 3;
        this.validacaoExcluir = false;
      })
      .catch(error => {
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
    this.status = false;
  };

  @action
  modal_validacao_senha_excluir = () => {
    this.status = true;
    this.validacaoExcluir = true;
  };

  @action
  excluir_credencial_validacao_admin = (id, toggleModal) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);
    Api.post("/login/", login, {})
      .then(response => {
        this.excluir_credencial(id, toggleModal);
        this.status = false;
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });

    this.senha = "";
  };

  @action
  validandoInputs = () => {
    while (status === false) {
      if (this.fornecedor === null) {
        AlertaError("Campos empresas em branco!");
        return false;
      } else if (this.login === "") {
        AlertaError("Campos de login em branco!");
        return false;
      } else if (
        this.password !== this.repeat_password ||
        this.password === ""
      ) {
        AlertaError("Campos de password incorretas!");
        return false;
      }
      if (
        this.login !== "" &&
        this.password !== "" &&
        this.repeat_password !== ""
      ) {
        return true;
      }
    }
  };

  @action
  retornarDaValidacao = () => (this.status = false);

  @action
  modal_validacao_senha = () => {
    if (this.validandoInputs()) {
      this.status = true;
    }
  };

  @action
  fecharModal = () => {
    this.status = false;
  };

  @action limparVariaveis = () => {
    this.key = 0;
    this.portal = 5;
    this.login = "";
    this.password = "";
    this.repeat_password = "";
    this.fornecedor = "";
    this.fornecedorCredencial = "";
    this.status = false;
    this.validacaoExcluir = false;
    this.senha = "";
    this.status_modal = false;
    this.ModalSincronizar = false;
    this.contratosEmpresaSelecionada = [];
    this.sync = false;
  }

}

const configuracoesCredenciaisStores = new ConfiguracoesCredenciaisStores();
export default configuracoesCredenciaisStores;
