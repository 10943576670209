import React from 'react'
import {
  ItemContainer,
  FilterContainer,
  ListaCompradores,
  SearchInput,
  ListaBody,
  SelecionarPortal,
  FilterTypeExclusaoInclusao
} from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es'
import { ConfiguracaoEditaisContext } from '../stores/store'
import { inject, observer } from 'mobx-react'
import { FormControl } from 'react-bootstrap'
import { ModalSaveButton3, DoubleSwitch } from '../../../globalStyles'
import $ from 'jquery'
import maquinadeestadosstore from '../../../Stores/MaquinaDeEstadosStore'

@inject('CompradoresStore')
@observer
class FiltroCompradores extends React.Component {
  constructor(props) {
    super(props)
    this.comp = 0
    this.ListaRef = React.createRef()
    this.state = {
      searchFilter: '',
      selected: [],
      state: null,
      scrollPos: null,
      showingNumber: 500
    }
  }

  static contextType = ConfiguracaoEditaisContext

  componentDidMount() {
    const list = document.getElementById('select-sd')
    list.addEventListener('scroll', this.listenToScroll)
  }

  componentDidUpdate() {
    const list = document.getElementById('select-sd')
    if (list) {
      list.removeEventListener('scroll', this.listenToScroll)
      list.addEventListener('scroll', this.listenToScroll)
    }
  }

  componentWillUnmount() {
    const list = document.getElementById('select-sd')
    if (list) list.removeEventListener('scroll', this.listenToScroll)
    this.props.CompradoresStore.listaAtual = []
    this.props.CompradoresStore.selectedPortal = ''
  }

  listenToScroll = () => {
    const { showingNumber } = this.state
    const list = document.getElementById('select-sd')
    const winScroll = list.scrollTop
    const height = list.scrollHeight - list.clientHeight

    const scrolled = winScroll - height
    this.setState({ scrollPos: scrolled })

    if (scrolled === 0) this.setState({ showingNumber: showingNumber + 500 })
  }

  filterBuyers = () => {
    const { searchFilter, state, showingNumber } = this.state

    const {
      listaAtual,
      selectedPortal,
      listaDeFiltrados,
      listaDeExcluidos
    } = this.props.CompradoresStore

    const listaEdicao = listaDeFiltrados.some(
      el => String(el.portal) === selectedPortal
    )
      ? listaDeFiltrados
      : listaDeExcluidos.some(el => String(el.portal) === selectedPortal)
        ? listaDeExcluidos
        : []

    const compradoresFiltrados = listaEdicao.filter(
      el => el.portal === selectedPortal
    )

    let newArr = listaAtual

    if (state) {
      newArr = newArr.filter(el => el.endereco === state)
      if (showingNumber !== 500) this.setState({ showingNumber: 500 })
    }

    if (searchFilter.length > 4) {
      newArr = newArr.filter(buyer =>
        buyer.nome.toLowerCase().includes(searchFilter.toLocaleLowerCase()) ||
        (buyer.codigo && buyer.codigo.includes(searchFilter))
      )
      if (showingNumber !== 500) this.setState({ showingNumber: 500 })
    } else {
      newArr = newArr.slice(0, showingNumber)
    }

    // Verifica se existe filtro por estado e refaz a lista

    // Retira os filtrados da lista
    newArr = newArr.filter(
      el => !compradoresFiltrados.map(al => al.id).includes(el.id)
    )

    //Inicializa uma váriavel local no componente, para poder ser usada com o valor do novo array gerado depois dos filtros;
    this.compradoresArr = newArr

    // Mostra o número de exibidos (apenas para teste)
    this.comp = newArr.length

    return newArr.map(comp => (
      <option key={comp.id} value={comp.id} title={comp.nome}>
        {`${comp.nome} ${(comp.codigo && !comp.nome.includes('UASG')) ? `- ${comp.codigo}` : ''}`}
      </option>
    ))
  }

  handleClick = exclusao => {
    const { filtrarCompradores } = this.props.CompradoresStore
    const { selected } = this.state

    filtrarCompradores(selected, exclusao)

    this.setState({ selected: [] })
  }

  filterByState = ({ target: { value } }) => this.setState({ state: value })

  handleSave = () => {
    const { toggleModal } = this.props
    const { salva_api, novoPerfil } = this.context
    const { salvarCompradores } = this.props.CompradoresStore
    salvarCompradores()
    toggleModal(false)
  }

  render() {
    const {
      changePortal,
      isFetching,
      selectedPortal,
      listaDeFiltrados,
      listaDeExcluidos,
      switchIncludeExclude,
      unused
    } = this.props.CompradoresStore
    const { todosEstados } = this.context
    const exclusao = listaDeFiltrados.some(
      el => String(el.portal) === selectedPortal
    )
      ? false
      : listaDeExcluidos.some(el => String(el.portal) === selectedPortal)
        ? true
        : unused

    const listaEdicao = exclusao ? listaDeExcluidos : listaDeFiltrados
    const compradoresFiltrados = listaEdicao.filter(
      comp => String(comp.portal) === selectedPortal
    )

    const { selected, state } = this.state
    return (
      <ItemContainer>
        <div className="cont-body">
          <SelecionarPortal insideModal>
            <b className="label">SELECIONE O PORTAL: </b>
            <select
              onChange={changePortal}
              defaultValue={0}
              id="select-portal-filtro-compradores"
            >
              <option value={0} selected disabled>
                PORTAL
              </option>
              {maquinadeestadosstore.portais.map(portal => {
                return <option value={portal.id}>{portal.nome}</option>
              })}
            </select>
          </SelecionarPortal>
          <FilterTypeExclusaoInclusao>
            <span>TIPO DE FILTRO</span>
            <div className="d-flex align-items-center">
              <small>INCLUSÃO</small>
              <DoubleSwitch
                checked={exclusao}
                onChange={() => switchIncludeExclude(exclusao)}
                value="notificacao"
                color="primary"
              />
              <small>EXCLUSÃO</small>
            </div>
          </FilterTypeExclusaoInclusao>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem'
            }}
          >
            <div
              style={{ display: 'flex', width: '30%', marginRight: '0.3rem' }}
            >
              <strong style={{ margin: '0.1rem 0.3rem 0 0' }}>UF: </strong>
              <FormControl
                name="selected"
                onChange={this.filterByState}
                componentClass="select"
                value={state}
                style={{ height: '28px', padding: 0, minWidth: '80px' }}
              >
                <option value="">TODOS</option>
                {todosEstados.map(es => (
                  <option value={es.sigla}>{es.sigla}</option>
                ))}
              </FormControl>
            </div>
            <SearchInput
              type="text"
              onChange={e => this.setState({ searchFilter: e.target.value })}
              placeholder="Pesquisar"
            />
          </div>

          {/* {this.comp} */}
          <FilterContainer>
            <ListaCompradores>
              <div className="lista-header compradores">COMPRADORES - UASG</div>
              <ListaBody
                screenSize={window.innerWidth < 1500 ? 'md' : 'lg'}
                ref={this.ListaRef}
                id="buyers-list"
              >
                {isFetching ? (
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                    <select
                      multiple
                      onChange={() =>
                        this.setState({ selected: $('#select-sd').val() })
                      }
                      style={{ height: '100%', border: 'none' }}
                      id="select-sd"
                    >
                      {this.filterBuyers()}
                    </select>
                  )}
              </ListaBody>
            </ListaCompradores>
            <button
              id="exchange-buyers"
              disabled={!selected.length}
              onClick={() => this.handleClick(exclusao)}
            >
              <FontAwesomeIcon icon="exchange-alt" />
            </button>
            <ListaCompradores filterEd>
              <div className="lista-header filtrados">
                {exclusao ? 'EXCLUÍDOS' : 'FILTRADOS'}
              </div>
              <ListaBody
                screenSize={window.innerWidth < 1500 ? 'md' : 'lg'}
                id="filtered-list"
              >
                <select
                  multiple
                  onChange={() =>
                    this.setState({ selected: $('#filtered-sd').val() })
                  }
                  style={{ height: '100%', border: 'none' }}
                  id="filtered-sd"
                >
                  {compradoresFiltrados.map(comp => (
                    <option key={comp.id} value={comp.id} title={comp.nome}>
                      {comp.nome}
                    </option>
                  ))}
                </select>
              </ListaBody>
            </ListaCompradores>
          </FilterContainer>
          <ModalSaveButton3
            marginTop="15px"
            className="icon-scale-xs"
            onClick={this.handleSave}
          >
            APLICAR
          </ModalSaveButton3>
        </div>
      </ItemContainer>
    )
  }
}

export default FiltroCompradores
