import styled from 'styled-components'

export const Overlay = styled.div`
  z-index: 3123213;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000000c9;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #0000008c;
  position: relative;
  padding-top: 80px;
  animation: 1s fadeIn ease-in-out;

  .header {
    display: flex;
    align-items: center;
    width: 80px;
    flex-direction: column;
    position: absolute;
    right: calc(50% - 40px);
    justify-content: center;
    top: -35px;

    .background-reprovado {
      background-color: #ec4242;
    }
    .color-reprovado {
      color: #ec4242;
    }

    .background-qualificado {
      background-color: #32a532;
    }
    .color-qualificado {
      color: #32a532;
    }

    .background-aprovado {
      background-color: #2076a0;
    }
    .color-aprovado {
      color: #2076a0;
    }

    .icon {
      border-radius: 50px;
      height: 70px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      box-shadow: 1px 1px 5px #000000d6;
      svg {
        height: 45px;
        width: 45px;
      }
    }
    .title {
      margin-top: 10px;
      text-align: center;
      font-weight: 500;
    }
  }

  .body {
    padding: 20px;

    .select-user,
    .nivel-prioridade {
      margin-bottom: 30px;
    }

    .title {
      margin-top: auto;
      font-size: 70%;
      font-weight: 700;
      text-align: left;
      color: #989898;
      margin-bottom: 10px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 30px;

    .confirmar {
      width: 50%;
      border-bottom-left-radius: 15px;
      background: #32a532;
      padding: 10px;
      color: #fff;
      font-weight: 500;
      user-select: none;
      transition: 200ms;
      cursor: ${props => (props.isFetching ? 'not-allowed' : 'pointer')};
      opacity: ${props => (props.isFetching ? '0.6' : '1')};

      :hover {
        background: ${props => (props.isFetching ? '#32a532' : '#2c6b2c')};
      }
    }

    .fechar {
      width: 50%;
      border-bottom-right-radius: 15px;
      font-weight: 500;
      background: #c7c7c7;
      padding: 10px;
      user-select: none;
      transition: 200ms;
      cursor: ${props => (props.isFetching ? 'not-allowed' : 'pointer')};
      opacity: ${props => (props.isFetching ? '0.6' : '1')};

      :hover {
        background: ${props => (props.isFetching ? '#c7c7c7' : '#797979')};
        color: ${props => (props.isFetching ? '#000' : '#c7c7c7')};
      }
    }
  }
`

export const ContainerFooter = styled.div`
  position: absolute;
  right: 10px;
  z-index: 99999;
  background: #37516b;
  margin-top: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;

  span.navigation-tip {
    align-items: flex-start;
    font-weight: 300;
    font-size: 76%;
    position: absolute;
    left: 11px;
    bottom: 8px;
    width: 20%;
    color: #d8d8d8;
    text-shadow: 1px 1px 2px black;

    i {
      font-weight: 800;
    }
  }

  .action-buttons {
    display: flex;

    button {
      display: flex;
      align-items: center;
      border: none;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      justify-content: center;
      box-shadow: 1px 1px 8px black;

      span {
        color: #fff;
        font-size: 75%;
      }
    }

    svg {
      color: #fff;
    }

    .qualificar {
      button {
        background: #4da04d;
        transition: 150ms ease-in-out;
      }

      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }

      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;
      width: 64px;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }

    .aprovar {
      button {
        background: #4d85a0;
        transition: 150ms ease-in-out;
      }

      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }

      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;
      width: 64px;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }

    .desqualificar {
      button {
        background: #ec4242;
        transition: 150ms ease-in-out;
      }
      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }
      width: 64px;
      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }
  }

  .status {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    bottom: 10px;
    width: 200px;
    height: 70px;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 6px #1d1d1db0;
    opacity: 0;
    bottom: -55px;
    animation: 500ms slideTopStatus ease-in forwards;

    @keyframes slideTopStatus {
      from {
        opacity: 0;
        bottom: -55px;
      }
      to {
        opacity: 1;
        bottom: 10px;
      }
    }

    .simbolo {
      position: absolute;
      right: calc(50% - 25px);
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      top: -25px;
      border-radius: 50%;
      align-items: center;
      color: #fff;
      box-shadow: 1px 1px 5px #000000d6;
      opacity: 0;
      animation: 500ms fadeIn ease-in 400ms forwards;
    }

    .qualificado {
      background: #4ea04e;
    }
    .desqualificado {
      background: #ec4343;
    }
    .aprovado {
      background: #2076a0;
    }
  }

  .title {
    margin-top: auto;
    font-size: 70%;
    text-align: center;
    color: #8c8c8c;
  }

  .message {
    font-size: 80%;
    text-align: center;
    margin-bottom: 7px;
    font-weight: 700;
    text-transform: uppercase;
  }
`
