import React from 'react'
import { Div } from '../style/styleCadastro'
import { TextField } from '@material-ui/core'

const MUInput = ({ name, errors, touched, handleChange, focus, type, label, onBlur }) => {

  return (
    <>
      <TextField
        error={errors[name] && touched[name]}
        InputLabelProps={{ shrink: true }}
        autoFocus={focus}
        variant="outlined"
        margin="dense"
        onChange={handleChange}
        onBlur={onBlur}
        name={name}
        type={type}
        fullWidth
        label={label}
      />
      {errors[name] && touched[name] && <Div>{errors[name]}</Div>}
    </>
  )
}


export default MUInput
