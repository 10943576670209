import { observable, action } from "mobx";
import Api from "../../../Stores/Conexao/conexao";
import BoletimStore from "../../../Pages/Prospection/DailyBulletin/Store";
import BuscaManualStore from "../../../Pages/Prospection/ManualSearch/Store";
import DownloadEditaisStore from "../../../Pages/Prospection/DownloadList/store";
import QualificadosStore from "../../../Pages/Prospection/Qualified/Store";
import ReprovadosStore from "../../../Pages/Prospection/Failed/Store";
import AprovadosStore from "../../../Pages/Prospection/Approved/Store";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";
import {
  FormatDateData,
  AlertaError
} from "../../../Stores/Funcoes/FuncoesGerais";

class DetalhesEditalStore {
  @observable isFetching = false;
  @observable fetchingMore = false;
  @observable itens = [];
  @observable id = null;
  @observable words = [];
  @observable matchWords = [];

  @action setIdValue = value => {
    this.id = value;
  };

  @action setWords = value => {
    value ? (this.words = value.split(",")) : (this.words = value);
  };

  @action setArrayWords = value => {
    this.words = value;
  };

  @action consultarItens = async () => {
    this.matchWords = [];
    this.isFetching = true;
    try {
      let res = await Api.post("/portal/itens_edital/", {
        id: this.id,
        config_id: BoletimStore.idProfile
      });
      this.itens = res.data.itens_edital;
      const wordsArray = res.data.description
        .replace(/\(|\)/g, " ")
        .replace(/-/g, " ")
        .replace(/,/g, " ")
        .replace(/:/g, " ")
        .replace(/\./g, " ")
        .split(" ");
      this.words.map(word => {
        wordsArray.map(item => {
          if (
            item
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ===
            word
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) {
            if (!this.matchWords.includes(word)) {
              this.matchWords = [...this.matchWords, word];
            }
          }
        });
      });
      this.words.map(word => {
        res.data.itens_edital.map(item => {
          let descriptionArray = item.descricao
            .replace(/,/g, "")
            .replace(/:/g, "")
            .replace(/\./g, "")
            .split(" ");
          let fullDescriptionArray = item.descricao_completa
            .replace(/,/g, "")
            .replace(/:/g, "")
            .replace(/\./g, "")
            .split(" ");
          descriptionArray.map(wordDescription => {
            if (
              wordDescription
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") ===
              word
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) {
              if (!this.matchWords.includes(word)) {
                this.matchWords = [...this.matchWords, word];
              }
            }
          });
          fullDescriptionArray.map(wordDescription => {
            if (
              wordDescription
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") ===
              word
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) {
              if (!this.matchWords.includes(word)) {
                this.matchWords = [...this.matchWords, word];
              }
            }
          });
        });
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.isFetching = false;
    }
  };

  @action setId = id => {
    const index = this.editais.map(ed => ed.id).indexOf(id);
    if (this.page * 10 < index) this.page++;
    if (this.page * 10 > index + 10) this.page--;
    this.id = id;
  };

  @action toggleModal = (id = null, state) => {
    this.id = id;
    this.getDataFromStore();
    this.contextState = state;
  };

  @observable contextState = {};

  @observable editais = [];
  @observable total_registros = 0;
  @observable page = 1;
  @action setPage = page => (this.page = page);

  @observable next = false;
  @observable previous = false;
  @observable atual = 0;

  @observable user = {
    id: null,
    changeUser: ({ target: { value } }) => (this.user.id = Number(value))
  };

  @observable prioridade = "media";
  @action mudarPrioridade = ({ target: { value } }) =>
    (this.prioridade = value);

  @observable modalQualificar = false;
  @observable modalAprovar = false;
  @observable modalReprovar = false;

  @action toggleModalQualificar = () => {
    const { prioridade, usuarios } = this.editais.find(ed => ed.id === this.id);
    if (usuarios.length) this.user.id = usuarios[0].id;
    this.prioridade = prioridade || "media";
    this.modalQualificar = !this.modalQualificar;
  };

  @action toggleModalAprovar = () => {
    const { prioridade, usuarios } = this.editais.find(ed => ed.id === this.id);
    if (usuarios.length) this.user.id = usuarios[0].id;
    this.prioridade = prioridade || "media";
    this.modalAprovar = !this.modalAprovar;
  };

  @action toggleModalReprovar = () =>
    (this.modalReprovar = !this.modalReprovar);

  @action mudarStatus = async status => {
    this.isFetching = true;
    const boletim = this.editais.find(ed => ed.id === this.id).selecionado.id;
    const url = `/portal/${boletim ? "boletim" : "edital"}_status/`;
    const config = {
      edital: this.id,
      status,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      usuarios: [this.user.id || MaquinaDeEstadosStore.usuarioLogado.id],
      prioridade: this.prioridade,
      msg_status: ""
    };

    if (this.msg_reprovar) config.msg_status = this.msg_reprovar;

    try {
      let res = await Api.post(url, config);
      this.editais = this.editais.map(ed => {
        if (ed.id === this.id)
          return {
            ...ed,
            status: res.data.status,
            selecionado: res.data
          };
        return ed;
      });
      // this.getStore().changeEditais(this.id, res.data.status);
      BoletimStore.changeEditais(this.id, status);
      QualificadosStore.changeEditais(this.id, status);
      AprovadosStore.changeEditais(this.id, status);
      ReprovadosStore.changeEditais(this.id, status);
      // this.closeConfirmationModal(status)
    } catch {
      AlertaError("Não foi possível executar essa ação, tente mais tarde.");
    } finally {
      this.isFetching = false;
      this.msg_reprovar = "";
    }
  };

  @action closeConfirmationModal = status => {
    switch (status) {
      case "reprovado":
        this.toggleModalReprovar();
        break;
      case "qualificado":
        this.toggleModalQualificar();
        break;
      case "aprovado":
        this.toggleModalAprovar();
        break;
      default: {
        this.modalAprovar = false;
        this.modalReprovar = false;
        this.modalQualificar = false;
      }
    }
    this.prioridade = "media";
  };

  @observable msg_reprovar = "";
  @action onChangeMessage = value => {
    this.msg_reprovar = value;
  };

  @action fetchMore = async type => {
    this.fetchingMore = true;
    const store = this.getStore();
    if (this.contextState.pregao || this.contextState.comprador) {
      store.pagination.changePage(this.page + type);
      await store.fetchMore(this.page + type, this.contextState, type);
    } else {
      if (window.location.pathname.includes("/prospeccao/pesquisa")) {
        BuscaManualStore.pagination.changePage(this.page + type);
        await BuscaManualStore.fetchEditais(
          this.page + type,
          this.contextState,
          "modalDetail",
          type
        );
      } else if (window.location.pathname.includes("/prospeccao/download")) {
        DownloadEditaisStore.pagination.changePage(this.page + type);
        await DownloadEditaisStore.fetchEditaisNovo(
          this.contextState,
          null,
          "modalDetail",
          type,
          this.page + type
        );
      } else {
        console.log(this.contextState, store, "this.contextState");
        await store.pagination.changePage(this.page + type);
        BoletimStore.dataIsFiltered
          ? await store.fetchEdital(
              this.contextState,
              "modalDetail",
              this.page + type,
              type
            )
          : await store.fetchEditais(
              store.idProfile,
              undefined,
              null,
              "modalDetail",
              this.page + type,
              type
            );
      }
    }

    this.page += type;

    this.fetchingMore = false;
  };

  @action tratarEditaisAdicionados = (editais, page) => {
    let editaisAtuais = [...this.editais];
    editais.forEach((ed, index) => {
      if (!editaisAtuais.find(edet => ed.id === edet.id)) {
        editaisAtuais[(page - 1) * 10 + index] = ed;
      }
    });
    this.editais = [...editaisAtuais];
  };

  getDataFromStore = () => {
    const {
      editais,
      total_registros,
      pagination: { page },
      fetchMore
    } = this.getStore();
    let array = [];
    for (let i = 0; i < total_registros; i++) {
      const nec = i >= (page - 1) * 10 && i < (page - 1) * 10 + 10;
      if (nec) {
        array[i] = editais[i - (page - 1) * 10];
      } else {
        array[i] = {
          id: null
        };
      }
    }
    this.editais = [...array];
    this.total_registros = total_registros;
    this.page = page;
    this.fetchEditais = fetchMore;
  };

  @action getStore = () => {
    let store;
    switch (window.location.pathname) {
      case "/prospeccao/qualificados":
        store = QualificadosStore;
        break;
      case "/prospeccao/boletim":
        store = BoletimStore;
        break;
      case "/prospeccao/aprovados":
        store = AprovadosStore;
        break;
      case "/prospeccao/reprovados":
        store = ReprovadosStore;
        break;
      case "/prospeccao/download":
        store = DownloadEditaisStore;
        break;
      default:
        if (window.location.pathname.includes("/prospeccao/pesquisa"))
          store = BuscaManualStore;
    }
    return store;
  };

  @observable fetchingCaptchaDownload = false;
  @action downloadQuebra = async edital => {
    const { id, licitacao } = edital;
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore;

    const date = FormatDateData(new Date());
    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia;
    const fileName = `Edital_${licitacao}_${empresaName}_${date}.zip`;
    const dados = [id];

    this.fetchingCaptchaDownload = true;
    try {
      const res = await Api.post(
        "portal/download_zip/",
        {
          dados,
          fornecedor_pk: id_fornecedor
        },
        {
          responseType: "blob"
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
    } finally {
      this.fetchingCaptchaDownload = false;
    }
  };

  trocouDeEmpresa = () => {
    this.limparVariaves();
  };

  limparVariaveis = () => {
    this.isFetching = false;
    this.fetchingMore = false;
    this.id = null;

    this.editais = [];
    this.total_registros = 0;
    this.page = 1;

    this.next = false;
    this.previous = false;
    this.atual = 0;

    this.modalQualificar = false;
    this.qualificado = false;

    this.modalReprovar = false;
    this.desqualificado = false;
  };
}

export default new DetalhesEditalStore();
