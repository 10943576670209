import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es';
import useOnClickOutside from '../../UsefulComponents/useClickOutside';
import { useRef } from 'react';
import Logout from '../../Pages/Login/Logout';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 8px;
  border: ${props => props.active ? "1px solid #d8d8d8" : "1px solid transparent"};
  background: ${props => props.active ? "#ececec" : "transparent"};
  transition: 300ms ease-in-out;

  :hover {
    border: 1px solid #d8d8d8;
  }

  svg {
    font-size: 20px;
    color: #4f8ba5;
    margin-right: 15px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #9c9c9c;
    user-select: none;
  }

  .menu-list {
    position: absolute;
    top:34px;
    left: 0;
    background: #4db4d6;
    text-align: right;
    z-index: 1;
    color: white;
    transition: 400ms;
    height: ${props => props.active ? '20px' : 0};
    width: 100%;
    pointer-events: ${props => props.active ? 'inherit' : ' none'};
    border-radius: 6px;
    box-shadow: 1px 1px 4px #808080;
    font-size: 13px;
    padding: 0 8px;
    font-weight: 500;

    .list-item {
      transition: 700ms ease-in-out;
      opacity: ${props => props.active ? '1' : 0};
    }
  }
`;

const MenuUsuario = inject("MaquinaDeEstadosStore")(observer(({ MaquinaDeEstadosStore }) => {
  const { usuarioLogado: user } = MaquinaDeEstadosStore;
  const name = user ? `${user.first_name} ${user.last_name}` : "";

  return <MenuComStore name={name} />
}));

export const MenuComStore = ({ name }) => {
  const ref = useRef();
  const [active, setActive] = useState(false);
  useOnClickOutside(ref, () => setActive(false));

  return (
    <Container onClick={setActive} active={active} ref={ref}>
      <FontAwesomeIcon icon="user-tie" />
      <span>{name}</span>

      <div className="menu-list">
        <Logout />
      </div>
    </Container>
  )
}


export default MenuUsuario
