import React, { useEffect, useRef, useState } from "react";
import { ChevronsUp } from "react-feather";

const ContainerLayout = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  position: "relative"
};

const BackToTopButtonLayout = {
  position: "fixed",
  bottom: "-5px",
  border: "none",
  borderRadius: "7px",
  boxShadow: "#7b7b7b 1px 1px 5px 1px",
  color: "#fff",
  background: "#3a566e"
};

export const InfinityLoader = props => {
  const { data, changeData, isFetching } = props;
  const ref = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);
  // const [arraySize, setArraySize] = useState(0);

  const getListContainer = () =>
    ref.current ? ref.current.parentElement : null;

  useEffect(() => {
    getListContainer().addEventListener("scroll", listenToScroll);
    return () => {
      if (getListContainer())
        getListContainer().removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const listenToScroll = async () => {
    const list = getListContainer();
    const winScroll = list.scrollTop;
    const height = list.scrollHeight - list.clientHeight;
    const scrolled = winScroll - height;
    setScrollPos(winScroll);

    if (scrolled >= 0 && !isFetching) {
      changeData();
    }
  };

  return (
    <div style={ContainerLayout} ref={ref}>
      {/* SPINNER */}
      {isFetching && (
        <div class="lds-ellipsis py-">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {/* BOTAO DE VOLTAR AO TOPO */}
      {scrollPos > 120 && (
        <button
          style={BackToTopButtonLayout}
          onClick={() =>
            getListContainer().scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <ChevronsUp size="17px" />
        </button>
      )}
    </div>
  );
};

export default InfinityLoader;
