import React from 'react'

const columns = [
  { name: 'GRUPO/LOTE', selector: 'grupo', center: true, grow: 1, wrap: true },
  { name: 'ITEM', selector: 'item', center: true, width: '50px', wrap: true },
  {
    name: 'OBJETO',
    selector: 'descricao_completa',
    grow: 5,
    minWidth: '250px',
    wrap: true
  },
  {
    name: 'MARCA',
    selector: 'marca',
    center: true,
    sortable: true,
    grow: 2,
    wrap: true
  },
  { name: 'FORNECEDOR', selector: 'fornecedor', sortable: true, grow: 2 },
  {
    name: 'MODELO',
    selector: 'modelo_versao',
    center: true,
    sortable: true,
    grow: 2,
    wrap: true
  },
  { name: 'FABRIC', selector: 'fabricante', wrap: true, center: true, grow: 2 },
  {
    name: 'QTD. EST.',
    selector: 'qtd_estimada',
    wrap: true,
    center: true,
    width: '70px'
  },
  {
    name: 'DESC.',
    selector: 'desconto',
    wrap: true,
    center: true,
    width: '70px'
  },
  {
    name: 'QTD. OFERT.',
    selector: 'qtd_ofertada',
    wrap: true,
    center: true,
    width: '100px'
  },
  {
    name: 'R$ UNIT.',
    selector: 'valor_unit',
    wrap: true,
    center: true,
    grow: 2
  },
  {
    name: 'ALQ.',
    selector: 'aliquota',
    wrap: true,
    center: true,
    width: '60px'
  },
  { name: 'R$ TOTAL', selector: 'valor_total', wrap: true, center: true },
  {
    name: 'R$ TOTAL EQ',
    selector: 'valor_total_equal',
    wrap: true,
    center: true,
    grow: 2
  },
  {
    name: '7174 PPB',
    selector: 'ppb',
    wrap: true,
    center: true,
    width: '50px',
    cell: ({ ppb }) => <div>{ppb ? 'SIM' : ppb == null ? '-' : 'NÃO'}</div>
  },
  {
    name: '7174 TP',
    selector: 'tp',
    wrap: true,
    center: true,
    width: '50px',
    cell: ({ tp }) => <div>{tp ? 'SIM' : tp == null ? '-' : 'NÃO'}</div>
  }
]

export const getColumns = keys =>
  columns.filter(col => keys.includes(col.selector))

// export const columns = [
//   {
//     name: 'GRUPO/LOTE',
//     selector: 'grupo',
//     center: true,
//     grow: 1,
//     wrap: true,
//   },
//   {
//     name: 'ITEM',
//     center: true,
//     selector: 'item',
//     // width: '300px',
//     grow: 0,
//     wrap: true,
//   },
//   {
//     name: 'OBJETO',
//     selector: 'descricao_detalhada',
//     grow: 5,
//     wrap: true,
//   },
//   {
//     name: 'MARCA',
//     selector: 'marca',
//     sortable: true,
//     grow: 2,
//     wrap: true,
//   },

//   {
//     name: 'FORNECEDOR',
//     selector: 'fornecedor',
//     sortable: true,
//     grow: 2,
//     // cell: ({ portal }) => (<div className="hover-portal-tabela">{portal}</div>)
//   },
//   {
//     name: 'MODELO',
//     selector: 'modelo',
//     sortable: true,
//     grow: 2,
//     wrap: true,
//   },
//   {
//     name: 'STATUS',
//     selector: 'status',
//     wrap: true,
//     center: true,
//     grow: 2
//   },
//   {
//     name: 'QUANTIDADE',
//     selector: 'quantidade',
//     wrap: true,
//     center: true,
//     grow: 2
//   },
//   {
//     name: 'R$ UNIT.',
//     selector: 'unitario',
//     wrap: true,
//     center: true,
//     grow: 2
//   },
//   {
//     name: 'R$ TOTAL',
//     selector: 'total',
//     wrap: true,
//     center: true,
//     grow: 2
//   },
//   {
//     name: 'R$ TOTAL',
//     selector: 'total',
//     wrap: true,
//     center: true,
//     grow: 2
//   },
//   {
//     name: 'R$ TOTAL',
//     selector: 'total',
//     wrap: true,
//     center: true,
//     grow: 2
//   }

// ];
