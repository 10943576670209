import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #3a566e;
  padding: 0 30px;
  height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .edital-info-prop {
    user-select: text;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }

  .selected-items {
    background-color: #5790a9;
    border-radius: 50%;
    display: inline-block;
    // width: 21px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    color: white;
    cursor: pointer;
  }
`;

const Overflow = styled(Container)`
  position: absolute;
  top: 48px;
  width: 100%;
  background: #3a566e;
`;

const SubHeader = ({ children, status }) => {
  return (
    <>
      <Container>
        {children}
      </Container>
      {!status && <Overflow />}
    </>
  )
}

export default SubHeader
