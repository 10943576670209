import MaquinaDeEstadosStore from "../../../../../Stores/MaquinaDeEstadosStore";

export const API = {
  filter: (status, page) => `${global.url}portal/proposta_boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}&status=${status}&pagina=${page}`,
  buscaManual: (numero, uasg) => `${global.url}portal/proposta_itens/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}&uasg=${uasg}&numero=${numero}&tipo=json`,
  salvarItensEditados: () => `${global.url}portal/proposta_cadastra_item/`,
  buscarDadosPdf: () => `${global.url}portal/proposta_conf/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`,
  salvarDadosPdf: () => `${global.url}portal/proposta_conf/`,
  enviarLogo: () => `${global.url}portal/proposta_logo/`,
  enviarAssinatura: () => `${global.url}portal/proposta_assinatura/`,
  buscarDocumentos: (edital) => `${global.url}portal/proposta_documentos/?edital=${edital}&fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`,
  enviarDocumento: (doc) => `${global.url}portal/proposta_doc_${doc}/`
}