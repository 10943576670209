import { useContext, useEffect, useState } from "react";
import { DocumentosContext } from "../enviarDocumentos/context";
import { PropostaEspecificaContext } from "../../context";
import maquinadeestadosstore from "../../../../../../../Stores/MaquinaDeEstadosStore";
import Api from "../../../../../../../Stores/Conexao/conexao";
import { DadosPdfContext } from "../dadosPdf/context";
import ItensPropostaStore from "../../../../../../../Pages/Proposal/CadastrarProposta/contexto/Itens/store";
import { ToastError } from "../../../../../../../Stores/Funcoes/FuncoesGerais";
import { registerCallbackOnMessage } from "../../../../../../../Stores/FirebaseStore";
const useEnviarProposta = () => {
  const {
    filesProposta,
    filesHabilitacao,
    setStatus,
    setDocsProposta
  } = useContext(DocumentosContext);
  const { pdfFile, setPdfStatus, setPdf } = useContext(DadosPdfContext);
  const {
    edital,
    idProposta,
    setEnviandoProposta,
    enviandoProposta,
    setPropostaEnviada,
    setErrorMessage
  } = useContext(PropostaEspecificaContext);

  // Tratando o push recebido pelo firebase;
  useEffect(() => {
    return registerCallbackOnMessage(({ data }) => {
      if (!window.location.pathname.includes("proposta/cadastrar")) return;
      handleMessage(data);
    });
  }, []);

  const handleMessage = data => {
    let { tipostring, nome_arquivo, enviado, descricao_tipo, error } = data;
    enviado = enviado === "true"; // correção porque o backend mandou string ao invés de boolean

    if (error) {
      // setPropostaEnviada(false)
      setErrorMessage(error);
      return;
    }

    if (!tipostring.includes("doc_") && tipostring !== "spreadsheet") return;

    switch (tipostring) {
      case "doc_proposta":
        if (nome_arquivo.includes("Proposta_")) {
          // TRANTANDO PDF
          setPdfStatus({ loading: false, enviado });
        } else {
          setStatus(nome_arquivo, { loading: false, enviado }, "proposta");
          // if (enviado) setFilesProposta(filesProposta.filter(file => file.name !== nome_arquivo));
        }
        break;
      case "doc_habilitacao":
        setStatus(nome_arquivo, { loading: false, enviado }, "habilitacao");
        // if (enviado) setFilesHabilitacao(filesHabilitacao.filter(file => file.name !== nome_arquivo));
        break;
      case "spreadsheet":
        setEnviandoProposta(false);
        if (descricao_tipo === "Proposta Enviada") setPropostaEnviada(true);
        break;
      default:
        return;
    }
  };

  //////////

  const enviarDocumento = async (type, file) => {
    const data = new FormData();
    data.append("edital", edital.id);
    data.append("fornecedor", maquinadeestadosstore.getIdEmpresa());
    for (let i = 0; i < file.length; i++) {
      data.append(`anexo_${type}`, file[i]);
    }

    try {
      await Api.post(`/portal/proposta_doc_${type}/`, data);
    } catch (err) {}
  };

  const enviarProposta = () => {
    let interval = setInterval(() => {
      let enviado;

      setPdf(previ => {
        enviado = previ.enviado;
        return previ;
      });

      setDocsProposta(prev => {
        if (!enviado) enviado = prev.some(doc => doc.enviado);
        return prev;
      });

      if (enviado) {
        clearInterval(interval);
        // Api.post('/portal/proposta_envia_item/', { proposta: idProposta })
        setEnviandoProposta(true);
      }
    }, 3000);
  };

  const enviar = () => {
    const proposal = filesProposta.map(item => item).concat([pdfFile]);

    if (filesProposta.length || pdfFile) {
      enviarDocumento("proposta", proposal);
    }
    if (filesHabilitacao.length) {
      enviarDocumento("habilitacao", [...filesHabilitacao]);
    }
    ItensPropostaStore.setAttachedDocuments([
      ...filesProposta,
      ...filesHabilitacao,
      pdfFile
    ]);
    setPdfStatus({ loading: true, enviado: false });
    enviarProposta();
  };

  return { enviar };
};

export default useEnviarProposta;
