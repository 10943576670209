import React from 'react'

import ModalFail from './modals/modalFail'
import ModalQualify from './modals/modalQualify'
import ModalConfirmation from './modals/modalConfirmation'
import ModalEdictApproval from './modals/modalApproval'

const StatusModal = () => {
  return (
    <>
      <ModalEdictApproval />
      <ModalFail />
      <ModalQualify />
      <ModalConfirmation />
    </>
  )
}

export default StatusModal
