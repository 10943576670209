import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ConfiguracaoEditaisContext } from "./stores/store";
import { CardMsg } from "../../globalStyles";
import { Mail } from "react-feather";
import TagsInput from "react-tagsinput";

const NotificacoesEmailEdital = observer(() => {
  const {
    tags_notificao_email,
    handleChangeInput_notificao_email,
    tag_notificao_email,
    handleChange_notificao_email
  } = useContext(ConfiguracaoEditaisContext);
  return (
    <CardMsg
      minHeight={"7vh"}
      flexDirection={"row"}
      margin={"0px 0px 10px 0px"}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Mail />
        &nbsp;&nbsp;
        <div style={{ padding: "0px 10px 0px 10px" }}>
          NOTIFICAÇÕES POR E-MAIL
        </div>
      </div>
      <div>
        <TagsInput
          classNameRemove={"react-tagsinput-remove"}
          className="tagsinputemail"
          inputProps={{ placeholder: "Adicionar email" }}
          value={tags_notificao_email}
          onChange={e => handleChange_notificao_email(e)}
          inputValue={tag_notificao_email}
          onChangeInput={e => handleChangeInput_notificao_email(e)}
        />
      </div>
    </CardMsg>
  );
});

export default NotificacoesEmailEdital;
