import React from "react";
import AlertaVerbalStore from "../Stores/AlertaVerbalStore";
import AlertaVerbalFemininoStore from "../Stores/AlertaVerbalFemininoStore";
import { playChain } from "./AlertasSistema";

export const SomSitema = () => {
  return (
    <>
      <audio id="audio1">
        <source src="../static/audio/beep.mp3" type="audio/mpeg"></source>
      </audio>
      <audio id="audio2">
        <source src="../static/audio/sob.mp3" type="audio/mpeg"></source>
      </audio>
      <audio id="audio3">
        <source src="../static/audio/somz.mp3" type="audio/mpeg"></source>
      </audio>
      <audio id="audio4">
        <source src="../static/audio/somAviso.mp3" type="audio/mpeg"></source>
      </audio>
      <audio id="audio5">
        <source src="../static/audio/whatsapp.mp3" type="audio/mpeg"></source>
      </audio>
      <audio id="avisos">
        <source src="../static/audio/avisos.mp3" type="audio/mpeg"></source>
      </audio>
    </>
  );
};

// let playInterval;
// const playingList = [];

// const playAudio = id => {
//   document.getElementById(id).play();
// };

export const playSom = (som, tipostring, portal, novaSituacao) => {
  switch (som) {
    case "Nenhum":
      break;
    case "ALERTA 1":
      playChain(["audio1"]);
      // document.getElementById("audio1").play();
      break;
    case "ALERTA 2":
      playChain(["audio2"]);
      // document.getElementById("audio2").play();
      break;
    case "ALERTA 3":
      playChain(["audio3"]);
      // document.getElementById("audio3").play();
      break;
    case "ALERTA 4":
      playChain(["audio4"]);
      // document.getElementById("audio4").play();
      break;
    case "ALERTA 5":
      playChain(["audio5"]);
      // document.getElementById("audio5").play();
      break;
    case "VOCAL MASCULINO":
      AlertaVerbalStore.handlePlayChain(
        tipostring,
        portal,
        novaSituacao.replace(/\//g, "-")
      );
      break;
    case "VOCAL FEMININO":
      AlertaVerbalFemininoStore.handlePlayChain(
        tipostring,
        portal,
        novaSituacao.replace(/\//g, "-")
      );
      break;
    default:
      document.getElementById("avisos").play();
  }
};
