import React from 'react'
import { inject, observer } from 'mobx-react'

import SinglePlano from './SinglePlano'

import * as S from '../../globalStyles'
 
export const Financeiro = inject('MaquinaDeEstadosStore')(
  observer(props => {
    const { empresas } = props.MaquinaDeEstadosStore

    return (
      <>
        <S.ComponentHeader
          height="8vh"
          style={{ justifyContent: 'space-between' }}
        >
          <S.TabButton ul={true}>PLANOS</S.TabButton>
        </S.ComponentHeader>
        <S.ComponentHeaderOverflow height="8vh" />
        <S.ContentBody
          bRadius
          height="calc(92vh - 80px)"
          style={{ overflow: 'auto' }}
        >
          {empresas.map((empresa, i) => (
            <SinglePlano empresa={empresa} last={empresas.length - 1 === i} />
          ))}
        </S.ContentBody>
      </>
    )
  })
)

export default Financeiro
