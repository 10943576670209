import React, { useState } from "react";

import maquinadeestadosstore from "../Stores/MaquinaDeEstadosStore";

import * as S from "./Style";
import { defaultsHeadersAxios } from "../Stores/Conexao/conexao";

const logo = "/static/Imagens/logo_wavecode.png";

const SplashScreen = () => {
  return (
    <S.Container>
      <img src={logo} alt={"logo wavecode"} />
    </S.Container>
  );
};

export default SplashScreen;
