import styled from "styled-components";

export const BadgeMsg = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => props.background};
  border-radius: 100%;
  display: flex;
  text-align: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

export const CardMsg = styled.div`
  width: ${props => (props.width ? props.width : "30%")};
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #8c8c8c;
  text-align: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  b {
    font-size: 18px;
    color: white;
  }
  span {
    margin-top: 5px;
    margin-bottom: -3px;
    font-size: 10px;
  }
  div {
    font-size: 14px;
    color: ${props => props.background};
  }
`;

export const CardBarra = styled.div`
  width: 1px;
  height: 100%;
  background-color: #0f6674;
`;

export const CardHoraMsg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  min-width: 130px;
  height: 100%;
  border-right: 1px solid;
  justify-items: center;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: #8c8c8c;
  div {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const CardTextoMsg = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #8c8c8c;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 10px;
  padding: 10px;

  p {
    text-transform: none;
  }
`;

export const ContainerMsg = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  width: 98%;
  color: ${props => props.color};
  background: white;
  box-shadow: 0px 2px 5px 1px #00000030;
  height: auto;
  margin: 5px;
  align-items: center;
  font-size: 13px;
`;
