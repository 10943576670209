import React from "react";
import { Award, Bookmark, Edit, Star, Trash } from "react-feather";
import { Editais } from "./Editais";
export const DashBoardEditais = ({ data }) => {
  const {
    editais,
    editais_selecionados,
    editais_qualificados,
    editais_aprovados,
    editais_reprovados
  } = data;
  return (
    <>
      <Editais
        color={"#086f6c"}
        title={"EDITAIS ENCONTRADOS"}
        subTitle={"Editais capturados pela plataforma"}
        icon={<Edit />}
        number={editais}
        size={'10px'}
      />

      <Editais
        color={"#36A2EB"}
        title={"NOVAS OPORTUNIDADES"}
        subTitle={"Novas oportunidades"}
        icon={<Bookmark />}
        number={editais_selecionados}
      />
      <Editais
        color={"orange"}
        title={"Qualificados"}
        subTitle={"Editais pré-qualificados"}
        icon={<Star />}
        number={editais_qualificados}
      />
      <Editais
        color={"green"}
        title={"Aprovados"}
        subTitle={"Editais aprovados"}
        icon={<Award />}
        number={editais_aprovados}
      />
      <Editais
        color={'red'}
        title={'Reprovados ou Vencidos'}
        subTitle={'Editais reprovados ou vencidos'}
        icon={<Trash/>}
        number={editais_reprovados}
      />
    </>
  );
};
