import React, { useState, useRef, useContext } from 'react';
import { WrapperInputs, Container, Wrapper, WrapperCarregarItens } from "./styles";
import InputCustomizadoIcone from "../../../../../ReusableComponents/Inputs/InputsGrupo";
import { Bookmark, Clipboard, Hexagon, Layers } from "react-feather";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputSelect from '../../../../../ReusableComponents/Inputs/InputSelect';
import { API } from '../api';
import { PropostaEspecificaContext } from '../../PropostaEspecifica/context';
import { ToastError } from "../../../../../../Stores/Funcoes/FuncoesGerais";


const BuscaManual = () => {

  const { buscarManualmente, loading } = useContext(PropostaEspecificaContext);

  const [licitacao, setLicitacao] = useState('');
  const [uasg, setUasg] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(e.nativeEvent)

    if (e.nativeEvent.data === '0') {
      switch (name) {
        case 'licitacao':
          setLicitacao(value);
          break;
        case 'uasg':
          setUasg(value);
          break;
        default: return;
      }
    }

    if (
      (!Number(e.nativeEvent.data))
      && e.nativeEvent.inputType !== "insertFromPaste"
      && e.nativeEvent.inputType !== "deleteContentBackward"
      && e.nativeEvent.inputType !== "deleteContentForward"
    ) {
      return
    }

    switch (name) {
      case 'licitacao':
        setLicitacao(value);
        break;
      case 'uasg':
        setUasg(value);
        break;
      default: return;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (licitacao != '' && uasg != '')
      buscarManualmente({ url: API.buscaManual(licitacao, uasg) }, true)
    else
      ToastError("Campos como Nº Licitação e UASG são obrigatórios.");
  }

  return (
    <Container>
      <Wrapper>
        <WrapperInputs>
          <form onSubmit={handleSubmit}>
            <InputSelect
              noHeight
              name={'portal'}
              placeholder={'ComprasNet'}
              disabled
              icone={<Bookmark />}
            />

            <InputCustomizadoIcone
              noHeight
              placeholder={'Nº Licitação'}
              name={'licitacao'}
              value={licitacao}
              onChange={handleChange}
              icone={<Layers />}
            />

            <InputCustomizadoIcone
              noHeight
              name={'uasg'}
              placeholder={'UASG'}
              value={uasg}
              onChange={handleChange}
              icone={<Hexagon />}
            />
          </form>
        </WrapperInputs>
        {loading ?
          <WrapperCarregarItens >
            <FontAwesomeIcon icon="spinner" spin style={{ fontSize: '120%' }} />
          </WrapperCarregarItens> :
          <WrapperCarregarItens onClick={handleSubmit}>
            <Clipboard />
            CARREGAR ITENS
          </WrapperCarregarItens>
        }

      </Wrapper>

    </Container>
  );
};

export default BuscaManual;


