import { observable, action } from 'mobx'

import Api from '../../../../Stores/Conexao/conexao'
import { tratarDadosEditais } from '../../Components/tratarDadosEditais'

import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'
import DetalhesEditalStore from '../../../../Components/Modules/ModalDetalhes/store'
import BuscaManualStore from '../../ManualSearch/Store/index'

class BoletimStore {
  @observable isFetching = false
  @observable idProfile = null
  @observable perfis = []
  @observable profiles = []
  @observable selectedPerfil = 0
  @observable editais = []

  @action changeEditais = (id, status) =>
  (this.editais = this.editais.map(ed =>
    ed.id === id ? { ...ed, status } : ed
  ))
  @observable total_paginas = 0
  @observable total_registros = 0

  @observable portal = {
    portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
    changePortais: portais => (this.portal.portais = portais)
  }

  @observable ordenacao = 'data_disputa'
  @observable crescente = true

  @observable filtros = {
    dmin: new Date().toLocaleDateString('pt-br'),
    dmax: new Date().toLocaleDateString('pt-br'),
    qualData: 'inicial',
    resetDate: () => {
      this.filtros.dmin = new Date().toLocaleDateString('pt-br')
      this.filtros.dmax = new Date().toLocaleDateString('pt-br')
    },
    changeDate: range => {
      const { qualData } = this.filtros

      this.filtros.dmin = new Date(range.startDate).toLocaleDateString('pt-br')
      this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

      return (this.filtros.qualData =
        qualData === 'inicial' ? 'final' : 'inicial')
    },
    onInputChange: e => {
      this.filtros[e.target.name] = e.target.value
    }
  }

  @action inputChange = value => {
    this.filtros.dmin = value
    this.filtros.dmax = value
  }

  @observable dataIsFiltered = false

  @action setDataIsFiltered = value => {
    this.dataIsFiltered = value
  }

  @observable pagination = {
    page: 1,
    changePage: page => (this.pagination.page = page)
  }

  getData = (id, page) => {
    const { dmin, dmax } = this.filtros
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${page || this.pagination.page}`
    query += `&status=todos`
    query += `&perfil=${id}`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    return query
  }

  getDataFiltro = data => {
    const {
      date: { dmin, dmax },
      estados,
      palavra,
      modalidade,
      tipoDate,
      portais
    } = data
    this.filtros.dmin = dmin
    this.filtros.dmax = dmax
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmax=${dmax}&dmin=${dmin}`
    query += `&pagina=${this.pagination.page}`
    query += `&status=todos`
    query += `&tipo_data=${tipoDate}`
    query += `&portal=${portais}`
    query += `&estados=${estados}`
    query += `&palavras=${palavra}`
    query += `&modalidade=${modalidade}`
    query += `&perfil=${this.profiles.length
      ? this.profiles.find(item => item.activated).id
      : null
      }`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    return query
  }

  getDataFilterNotDate = data => {
    const { estados, palavra, modalidade, tipoDate, portais } = data
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&pagina=${this.pagination.page}`
    query += `&status=todos`
    query += `&portal=${portais}`
    query += `&estados=${estados}`
    query += `&palavras=${palavra}`
    query += `&modalidade=${modalidade}`
    query += `&date_type=${tipoDate}`
    query += `&perfil=${this.profiles.length
      ? this.profiles.find(item => item.activated).id
      : null
      }`
    query += `&ordenacao=${this.ordenacao || 'data_disputa'}`
    query += `&crescente=${this.crescente}`
    return query
  }

  @action fetchEditaisNovoSimple = async (data, id) => {
    const param = this.getDataFiltro(data)
    this.idProfile = id
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const {
        config_perfis,
        editais,
        // palavras_config,
        // palavras_usadas,
        // total_paginas,
        total_registros
      } = res.data
      this.profiles = config_perfis
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      )
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
    } finally {
      this.isFetching = false
    }
  }

  @observable profilesId = []
  @observable idProfile = null
  @action setIdProfile = value => (this.idProfile = value)

  @action fetchEditaisNovo = async (id, data, type) => {
    const param = this.getDataFilterNotDate(data)
    this.isFetching = true
    try {
      const res = await Api.get(param)
      const { config_perfis, editais, total_registros } = res.data
      this.profiles = config_perfis
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      )
      this.profilesId = res.data.config_perfis.map(profile => profile.id)
      if (type) this.idProfile = this.profilesId[0]
    } catch (err) {
    } finally {
      this.isFetching = false
    }
  }

  @action fetchEdital = async (data, type = '', page = null, id = null) => {
    console.log(data, 'DATA')

    const {
      date: { dmin, dmax },
      pregao,
      pregaoUASG: uasg,
      tipoDate
    } = data



    // console.log(dateMax, dateMin, dateMaxSomeFiveDays)

    this.isFetching = true
    let query = `/portal/boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}`
    query += `&dmin=${dmin}`
    query += `&dmax=${dmax}`
    query += `&pagina=${this.pagination.page}`
    query += `&date_type=${tipoDate}`
    query += `&uasg=${uasg}`
    query += `&edital=${pregao}`
    const queryPattern =
      '&estados=AC,AL,AP,AM,BA,CE,DF,ES,GO,MA,MT,MS,MG,PA,PB,PR,PE,PI,RJ,RN,RS,RO,RR,SC,SP,SE,TO&status=todos&ordenacao=data_disputa&crescente=true&usuario=null'
    query += queryPattern
    try {
      const res = await Api.get(query)
      const { editais, total_registros, config_perfis } = res.data
      this.editais = tratarDadosEditais(editais)
      if (type === 'modalDetail') {
        DetalhesEditalStore.tratarEditaisAdicionados(
          tratarDadosEditais(editais),
          page
        )
        if (id && id > 0) {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[0].id
          )
        } else {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[9].id
          )
        }
      }
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      )
      this.profiles = config_perfis
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
    } finally {
      BuscaManualStore.setDisableNext(false)
      BuscaManualStore.setDisablePrevious(false)
      this.isFetching = false
    }
  }

  @action fetchEditais = async (
    perfil_id = null,
    perfil = undefined,
    idPerfil = null,
    type = '',
    page = null,
    id = null
  ) => {
    this.selectedPerfil = perfil
    this.idProfile = perfil_id
    this.isFetching = true
    try {
      const res = await Api.get(this.getData(perfil_id))
      if (perfil_id) this.pagination.changePage(1)
      const { config_perfis, editais, total_registros } = res.data
      this.profiles = config_perfis
      if (type === 'modalDetail') {
        DetalhesEditalStore.tratarEditaisAdicionados(
          tratarDadosEditais(editais),
          page
        )
        if (id && id > 0) {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[0].id
          )
        } else {
          DetalhesEditalStore.setIdValue(
            tratarDadosEditais(res.data.editais)[9].id
          )
        }
      }
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      )
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
    } finally {
      BuscaManualStore.setDisableNext(false)
      BuscaManualStore.setDisablePrevious(false)
      this.isFetching = false
    }
  }

  @action fetchEditaisSelection = async (id, ids) => {
    this.selectedPerfil = id
    this.isFetching = true
    if (id) this.pagination.changePage(1)
    try {
      const res = await Api.get(this.getData(id))
      const { config_perfis, editais, total_registros } = res.data
      this.profiles = config_perfis
      DetalhesEditalStore.setWords(
        config_perfis.find(item => item.activated).palavras_chave
      )
      this.editais = tratarDadosEditais(editais)
      this.total_paginas = Math.ceil(total_registros / 10)
      this.total_registros = total_registros
    } catch (err) {
    } finally {
      this.isFetching = false
    }
  }

  fetchMore = async page => {
    try {
      const res = await Api.get(this.getData(null, page))
      return res.data.editais
    } catch (err) { }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis()
    this.fetchEditais()
  }

  limparVariaveis = () => {
    this.isFetching = false

    this.profiles = []
    this.editais = []
    this.total_paginas = 0
    this.total_registros = 0

    this.portal = {
      portais: MaquinaDeEstadosStore.portais.map(portal => portal.id),
      changePortais: portais => (this.portal.portais = portais)
    }

    this.filtros = {
      dmin: new Date().toLocaleDateString('pt-br'),
      dmax: new Date().toLocaleDateString('pt-br'),
      qualData: 'inicial',
      resetDate: () => {
        this.filtros.dmin = new Date().toLocaleDateString('pt-br')
        this.filtros.dmax = new Date().toLocaleDateString('pt-br')
      },
      changeDate: range => {
        const { qualData } = this.filtros

        this.filtros.dmin = new Date(range.startDate).toLocaleDateString(
          'pt-br'
        )
        this.filtros.dmax = new Date(range.endDate).toLocaleDateString('pt-br')

        return (this.filtros.qualData =
          qualData === 'inicial' ? 'final' : 'inicial')
      },
      onInputChange: e => {
        this.filtros[e.target.name] = e.target.value
      }
    }

    this.pagination = {
      page: 1,
      changePage: page => (this.pagination.page = page)
    }
  }
}

export default new BoletimStore()
