import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 70px);
  flex-direction: column;
`

export const Bottoms = styled.div`
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    width: 200px;
    height: 37px;
    border: none;
    box-shadow: 1px 1px 4px #000000a3;

    :disabled {
      opacity: 0.5;
    }
  }

  button.salvar {
    background: #12b123;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  button.limpar {
    background: #da1c1ad6;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  @media (max-height: 768px) {
    height: 17%;
  }

  @media (max-width: 800px) {
    width: 35vh;

    button {
      height: 29px;
      font-size: 10px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  border-radius: 15px;
  box-shadow: 1px 1px 6px #00000052;
  margin: 10px 20px;
  height: 100%;
  overflow: hidden;

  .options {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  min-width: 260px;
  height: 100%;
  background: #527b9e;

  .header {
    height: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 12px;
    justify-content: center;
    background: #3a566e;

    .title {
      margin-bottom: 10px;
    }
  }

  .body {
    color: #fff;
    overflow: overlay;
    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      border: 57px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
    }
    ::-webkit-scrollbar-track {
      background: #3a566e;
      border: 45px none #ffffff;
      border-radius: 36px;
    }
    /* ::-webkit-scrollbar-track:hover {
      background: #666666;
    } */
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .item {
      display: flex;
      margin-bottom: 20px;
      cursor: pointer;

      .content {
        margin-left: 10px;
        line-height: 1;
      }

      .title {
        font-size: 15px;
        margin-bottom: 5px;
      }

      .description {
        font-size: 10px;
        line-height: 1.2;
      }
    }

    .item:hover {
      color: #cecece;
    }
  }

  @media (max-height: 800px) {
    .header {
      height: 32%;
    }
  }
`

export const ListItem = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  transition: 350ms ease-in-out;
  padding: 10px 15px;
  color: '#fff';
  align-items: center;
  background: ${({ active }) => (active ? '#6291b9' : 'transparent')};
   .icon {
    width: 20%;
  }

  .content {
    margin-left: 3%;
    width: 77%;
    line-height: 1;
  }

  .title {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .description {
    font-size: 9px;
    line-height: 1.2;
    font-size: 11px;
    color: #00000099;
    color: ${({ active }) => (!active ? '#ffffff96' : '#ffffffe6')};
    font-weight: 500;
    opacity: 0;
    animation: 1s fadeIn ease-in-out forwards;
  }

  :hover {
    color: #fff;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 1px 1px 6px #00000029;
  height: 88%;
  position: relative;
  width: 100%;

  .header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid gray;
    height: 58px;
    justify-content: center;

    .icon svg {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }

    .title {
      font-size: 95%;
      font-weight: 700;
    }

    .subtitle {
      font-size: 80%;
      margin-top: -3px;
    }
  }

  .body {
    height: calc(100% - 58px);
    position: relative;
    width: 100%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const BlankSpace = styled.div`
  height: 13px;
  width: 13px;
`

export const Botons = styled.div`
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    width: 200px;
    height: 37px;
    border: none;
    box-shadow: 1px 1px 4px #000000a3;

    :disabled {
      opacity: 0.5;
    }
  }

  button.salvar {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  button.limpar {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  @media (max-height: 768px) {
    height: 17%;
  }

  @media (max-width: 800px) {
    width: 35vh;

    button {
      height: 29px;
      font-size: 10px;
    }
  }
`
export const Alert = styled.div`
  position: absolute;
  right: 1vw;
  top: 20px;
  background-color: #0492c7;
  color: #fff;
  width: 335px;
  text-align: center;
  border-radius: 10px;

  @media (max-width: 800px) {
    top: 73px;
  }
`
