import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CardMsg } from '../../../globalStyles'
import { Filter } from 'react-feather'
import { MaterialServicosContainer } from '../styles'
import { ConfiguracaoEditaisContext } from '../stores/store'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 290,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FiltroMateraisServico = observer(() => {
  const classes = useStyles();

  const { changeMateriaisServicos, materiaisServicos } = useContext(
    ConfiguracaoEditaisContext
  )

  let value = materiaisServicos + ''
  
  return (
    <CardMsg
      display="block"
      height="auto"
      flexDirection={'row'}
      margin={'10px 0'}
      justifyContent="space-between"
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
        <Filter />
        &nbsp;&nbsp;
        <div className="d-flex flex-column">
          <div style={{ padding: '0px 10px 0px 10px' }}>
            FILTRO MATERIAIS/SERVIÇOS
          </div>
          <small style={{ fontSize: '10px', marginLeft: '10px' }}>
            Filtrar editais por materiais e/ou serviços.
          </small>
        </div>
      </div>
      <MaterialServicosContainer>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Filtro materiais ou serviços</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={value}
            onChange={changeMateriaisServicos}
            label="Filtro materiais ou serviços"
          >
            <MenuItem value="M">Materiais</MenuItem>
            <MenuItem value="S">Serviços</MenuItem>
            <MenuItem value="M,S">Materiais ou Serviços</MenuItem>
          </Select>
        </FormControl>
      </MaterialServicosContainer>
    </CardMsg>
  )
})

export default FiltroMateraisServico
