import styled from 'styled-components'

export const Container = styled.div`
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
  overflow: auto;
  overflow: inherit;

  h3 {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    color: #bb0e0e;
  }

  #limpar-busca-manual {
    background: #d20808ab;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    box-shadow: 1px 1px 4px #00000080;
    font-size: 15px;
    padding: 5px 13px;

    svg {
      margin-right: 7px;
    }
  }

  .width-pattern {
    width: 1050px;
  }

  @media screen and (max-width: 3000px) {
    .width-pattern {
      width: 1635px;
    }
  }

  @media screen and (max-width: 1779px) {
    .width-pattern {
      width: 1110px;
    }
  }

  @media screen and (max-width: 1214px) {
    .width-pattern {
      width: 545px;
    }
  }
`

export const ImportarPerfil = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 545px;
  margin: 10px;
  color: #3b576e;

  h5 {
    margin-bottom: 0;
  }

  .input-wrap {
    width: 200px;
    max-width: 200px;
    margin-right: auto;
    margin-left: 30px;
  }

`

export const Body = styled.div`
  display: flex;
  height: calc(100vh - 135px);

  @media (max-height: 780px) {
    height: calc(100vh - 115px);
  }
`

export const ContainerTable = styled.div`
  height: ${props => props.height || '100%'};
  width: 100%;
  background: #fff;
  color: #000;
  z-index: 1;
  border-radius: 10px;
  box-shadow: #00000063 1px 1px 6px;
  overflow: hidden;
  display: flex;
  align-items: ${({ filter }) => (filter ? 'end' : 'center')};
  justify-content: ${({ filter }) => (filter ? 'space-between' : 'center')};
  flex-direction: column;
  
  :hover {
    cursor: pointer;
  }

  .rdt_TableHeadRow {
    box-shadow: 1px 1px 9px #949494;
    border: none;
  }

  .rdt_TableCol_Sortable {
    font-weight: bold;
    color: black;
  }
  .rdt_TableRow {
    min-height: unset;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .tabela-padrao {
    opacity: 0;
    animation: 400ms fadeIn ease-out forwards;
    margin-bottom: 0;
  }

  .tabela-reprovados {
    .rdt_TableRow div:nth-child(2) {
      margin-right: 0;
    }
    .rdt_TableBody::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    .rdt_TableBody::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f5f5f5;
    }

    .rdt_TableBody::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(128, 187, 224)),
        color-stop(0.72, rgb(80, 132, 166)),
        color-stop(0.86, rgb(95, 163, 204))
      );
    }
  }

  .rdt_TableBody {
    max-height: ${props => props.tableHeight || `calc(100vh - 257px)`};
    overflow: auto;

    @media (max-height: 780px) {
      max-height: calc(100vh - 216px);
    }
  }

  .rdt_TableCell:nth-child(5) {
    padding-left: 18px;
  }

  .rdt_TableCell:last-child {
    padding-right: calc(48px / 6);
  }

  .hover-portal-tabela {
    transition: 200ms linear;
  }

  .hover-portal-tabela:hover {
    overflow: initial;
    background: #7eac3b;
    color: white;
    z-index: 1;
    padding: 0 4px;
    border-radius: 3px;
    box-shadow: 1px 1px 3px #5f5f5f;
  }

  div.action-buttons {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 50px;
    width: 62%;
  }

  .action-buttons {
    display: flex;
    /* border-radius: 15px; */
    width: 50%;
    margin-top: 70px;
    margin-bottom: 40px;
    animation: 600ms fadeIn ease-in forwards;

    button {
      border: none;
      background: none;
      width: 50%;
      padding: 10px;
      font-weight: 500;
    }

    #filtrar-editais {
      background: green;
      color: #fff;
      border-right: 1px solid #828282;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    #fechar-modal-filtro-editais {
      background: #c5bfbf;
      color: black;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`

export const DateComponent = styled.input``

export const ButtonSearch = styled.button`
  background-color: #194f5c;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-left: 6px;
`

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px;
  min-height: 50px;
  justify-content: center;
  box-shadow: 1px 1px 9px #4e4e4e;

  @media (max-height: 780px) {
    padding: 10px;
  }

  .pagination {
    display: flex;
    width: 70%;
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
  }

  .MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff;
    background-color: #5b8eb1;
  }
`
