import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #4646464f;
  padding: 20px;
  overflow: auto;
  animation: 1s fadeIn ease-in-out;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
                      left bottom,
                      left top,
                      color-stop(0.44, rgb(128, 187, 224)),
                      color-stop(0.72, rgb(80, 132, 166)),
                      color-stop(0.86, rgb(95, 163, 204)   ));
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #0000004d;
  box-shadow: 1px 1px 3px #0000003d;
  padding: ${props => props.padding || 0};
  margin-bottom: 20px;

  b {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    color: #4a4a4a;
  }

  .company {
    display: flex;
    align-items: center;

    img {
      width: 120px;
    }
    .info {
      margin-left: 50px;
      display: flex;
      flex-direction: column;

      .title { 
        margin-bottom: 8px;
        font-weight: 600;
        color: #3b576f;
      };
      p {
        white-space: nowrap;
        margin-bottom: 5px;
      }
    }
    
  }
`;

export const SectionTitle = styled.b`
  color: #3b576f;
  margin-bottom: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
`;

export const DocumentContainer = styled.div`
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color || '#296188'};
  margin-bottom: 10px;
  position: relative;

  svg {
    position: absolute;
    right: 9px;
    top: 9px;
  }

  svg.check {
    color: #78a734;
    top: 5px;
    right: 5px;
  }

  :last-child {
    margin-bottom: 0
  }
`;