import { observable, action } from 'mobx'

import MaquinaDeEstadosStore, {
  EDITAIS,
  BUSCA_DE_EDITAIS,
  LICITACOES,
  MONITORAMENTO,
  OCORRENCIAS_DO_DIA,
  PAINEL_MONITORAMENTO,
  CONF_BUSCA_DE_EDITAIS,
  CONF_MONITORAMENTO,
  CONF_GERAL,
  FINANCEIRO,
  FUNIL,
  AGENDA,
  DASHBOARD
} from './MaquinaDeEstadosStore'
import DashboardStores from './DashboardStores'

import { defaultsHeadersAxios } from './Conexao/conexao'
import '../Debug'
import Api from './Conexao/conexao'
import { getEmpresaId, getToken } from '../Services/auth'
import { AtualizaDadosEmpresa } from './StateStores'
import ModalLoadingStore from './ModalLoadingStore'
import SideStores from './SideStores'
import OcorrenciasDoDiaStores from './OcorrenciasDoDiaStores'
import ConfiguracoesMonitoramentoStores from './ConfiguracoesMonitoramentoStores'

class MenuStores {
  constructor() {
    defaultsHeadersAxios(getToken())
  }
  @observable
  titulo_pagina = ''
  @observable
  simbolo_pagina = 'gavel'
  @observable
  fullScreen = false
  @observable
  empresas = []
  @observable
  size = ''
  @observable
  flag_mudar_empresa = '666'
  @observable
  tirar_duplicacao = 0
  @observable
  body_tirar_duplicacao = 0
  @observable
  id_li_tirar_duplicacao = 0
  @observable
  cnpj_monitoramento = ''
  @observable
  razao_social_monitoramento = ''
  @observable
  id_fornecedor = getEmpresaId()
  @observable
  possui_contrato = false
  @observable
  dt_atual_servidor = '0'
  @observable
  isFetting = true

  @action
  loadtTitleIcon = (icon, title) => {
    this.simbolo_pagina = icon
    this.titulo_pagina = title
  }

  @action
  consultaTitle = () => {
    let url = window.location.pathname
    const newUrl = '/'.concat(url.split('/')[1])

    switch (newUrl) {
      case '/prospeccao': {
        this.loadtTitleIcon('layer-group', 'PROSPECÇÃO')
        break
      }
      case '/proposta': {
        this.loadtTitleIcon('file-upload', 'PROPOSTA')
        break
      }
      case EDITAIS: {
        this.loadtTitleIcon('bookmark', 'EDITAIS SELECIONADOS')
        break
      }
      case BUSCA_DE_EDITAIS: {
        this.loadtTitleIcon('search', 'BUSCA DE EDITAIS')
        break
      }
      case LICITACOES: {
        this.loadtTitleIcon('gavel', 'LICITAÇÕES')
        break
      }
      case PAINEL_MONITORAMENTO: {
        this.loadtTitleIcon('tv', 'LICITAÇÕES DO DIA')
        break
      }
      case MONITORAMENTO: {
        this.loadtTitleIcon('comments', 'MONITORAMENTO')
        break
      }
      case OCORRENCIAS_DO_DIA: {
        this.loadtTitleIcon('tv', 'OCORRÊNCIAS DO DIA')
        break
      }
      case CONF_BUSCA_DE_EDITAIS: {
        this.loadtTitleIcon('cog', 'CONFIGURAÇÕES DA BUSCA DE EDITAIS')
        break
      }
      case CONF_MONITORAMENTO: {
        this.loadtTitleIcon('cog', 'CONFIGURAÇÕES DO MONITORAMENTO')
        break
      }
      case CONF_GERAL: {
        this.loadtTitleIcon('key', 'CONFIGURAÇÃO GERAL')
        break
      }
      case FINANCEIRO: {
        this.loadtTitleIcon('hand-holding-usd', 'FINANCEIRO')
        break
      }
      case FUNIL: {
        this.loadtTitleIcon('gavel', 'LICITAÇÕES')
        break
      }
      case AGENDA: {
        this.loadtTitleIcon('calendar-check', 'AGENDA DE LICITAÇÕES')
        break
      }
      case '/links': {
        this.loadtTitleIcon('unlink', 'LINKS DE DOWNLOAD')
        break
      }
      case '/links': {
        this.loadtTitleIcon('unlink', 'LINKS PARA DOWNLOAD')
        break
      }
      case DASHBOARD: {
        this.loadtTitleIcon('tachometer-alt', 'DASHBOARD')
        break
      }
      default:
        return
    }
  }

  @action mudou_empresa = async id_fornecedor => {
    this.id_fornecedor = id_fornecedor
    const {
      setIdEmpresa,
      setContratos,
      verificaContrato,
      updateCredenciais
    } = MaquinaDeEstadosStore
    ModalLoadingStore.handleOpenModal()
    await this.atualizaEmpresaDefault(id_fornecedor)
    setIdEmpresa(id_fornecedor)
    setContratos(id_fornecedor)
    updateCredenciais(id_fornecedor)
    MaquinaDeEstadosStore.checkComnoFree(id_fornecedor)

    // Atualia pagina atual
    await AtualizaDadosEmpresa()
    verificaContrato()
    await ConfiguracoesMonitoramentoStores.consulta_api()
    await SideStores.consulta_notificacoes()
    DashboardStores.consulta_dashboard()
    ModalLoadingStore.handleCloseModal()
  }

  @action atualizaEmpresaDefault = async id => {
    try {
      const response = await Api.post(
        'atualiza_empresa/',
        { fornecedor: id },
        {}
      )
      MaquinaDeEstadosStore.setCnpjEmpresa(response.data.cnpj)
    } catch (e) {}
  }

  @action consulta_api = () => {
    // Api.get("empresas/", {})
    //   .then(response => {
    //     this.empresas = response.data.empresas;
    //   })
    //   .catch(() => {});
  }

  // *****************FIREBASE*****************

  @action
  acrescentar_um = () => {
    this.tirar_duplicacao += 1
  }
  @action
  gerar_random_flag_mudar_empresa = () => {
    this.flag_mudar_empresa = Math.random()
  }
  @action
  estabilizar_flag_mudar_empresa = () => {
    this.flag_mudar_empresa = '666'
  }
  @action
  nomeEmpresaNovo = () =>
    this.empresas.find(item => item.id === this.id_fornecedor).nome_fantasia

  @action
  nomeEmpresa = () => {
    return (this.id_fornecedor = MaquinaDeEstadosStore.getIdEmpresa())
  }

  @action
  handleTela = () => {
    this.handleFullScreen()
    if (!this.fullScreen) {
      OcorrenciasDoDiaStores.som = true
    }
  }

  handleFullScreen = () => {
    if (this.fullScreen) {
      return (this.fullScreen = false)
    }

    const docElm = document.documentElement

    if (docElm.requestFullscreen) {
      docElm.requestFullscreen()
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen()
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen()
    }
    this.fullScreen = true
  }

  @action limparVariaveis = () => {
    this.titulo_pagina = ''
    this.simbolo_pagina = 'gavel'
    this.fullScreen = false
    this.empresas = []
    this.size = ''
    this.flag_mudar_empresa = '666'
    this.tirar_duplicacao = 0
    this.body_tirar_duplicacao = 0
    this.id_li_tirar_duplicacao = 0
    this.random_tirar_duplicacao = 0
    this.cnpj_monitoramento = ''
    this.razao_social_monitoramento = ''
    this.id_fornecedor = getEmpresaId()
    this.possui_contrato = false
    this.dt_atual_servidor = '0'
    this.isFetting = true
  }
}

const menuStores = new MenuStores()
export default menuStores
