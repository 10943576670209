import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/newModal'
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from './styles'
import { FormGroup } from 'react-bootstrap'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import EmpresaInputConstumizado from '../InputsCustomizados/EmpresaInputConstumizado'
import UsuariosInputConstumizado from '../InputsCustomizados/UsuariosInputConstumizado'
import { Edit } from 'react-feather'
import SelectUsersCustomizado from '../InputsCustomizados/SelectUsersCustomizado'
import CnpjInputCustomizado from '../InputsCustomizados/CnpjInputCustomizado'
import TelefoneInputCustomizado from '../InputsCustomizados/TelefoneInputCustomizado'
// import { ModalSaveButton } from '../../../../globalStyles';

function ModalEditCompany({ empresa, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  return (
    <ModalProvider>
      <Edit
        className="icon-scale"
        color="#3A566E"
        data-tip="EDITAR EMPRESA"
        data-place="bottom"
        data-type="info"
        data-effect="solid"
        onClick={() => setIsModalOpen(true)}
      />

      {isModalOpen && (
        <ModalLateral
          icon="building"
          title={'EDITAR EMPRESA'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <CompanyHooks empresa={empresa} toggleModal={setIsModalOpen} />
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const CompanyHooks = inject(
  'ConfiguracoesGeraisStores',
  'ConfiguracoesGeraisModalEmpresaStores',
  'MaquinaDeEstadosStore',
  'MenuStores',
  'StoreConfiguracoesGerais',
  'StoreConfiguracaoEmpresas'
)(
  observer(props => {
    const {
      onChange,
      adminValidation,
      verificaAdmin,
      validarAdmin,
      popularEmpresa
    } = props.StoreConfiguracaoEmpresas
    const { usuarios, empresas } = props.StoreConfiguracoesGerais
    return (
      <CompanyInfo
        onChange={onChange}
        adminValidation={adminValidation}
        verificaAdmin={verificaAdmin}
        popularEmpresa={popularEmpresa}
        validarAdmin={validarAdmin}
        usuarios={usuarios}
        empresas={empresas}
        {...props}
      />
    )
  })
)

const CompanyInfo = props => {
  // const {
  //   key,
  //   onChange,
  //   adminValidation,
  //   modal_validacao_senha,
  //   editar_empresa_validacao_admin
  // } = props.ConfiguracoesGeraisModalEmpresaStores;
  const {
    onChange,
    adminValidation,
    verificaAdmin,
    validarAdmin,
    popularEmpresa,
    usuarios,
    empresas
  } = props
  const { toggleModal, empresa } = props
  const { cnpj, nome_fantasia, phone, razao_social, id } = empresa
  const usuariosCadastrados = empresas
    .find(em => em.id === id)
    .usuarios.map(us => us.id)

  useEffect(() => {
    popularEmpresa(empresa)
    return () => {
      popularEmpresa('closeModal')
    }
  }, [])

  const handleSubmit = (e, validate) => {
    e.preventDefault()

    if (!validate) {
      return validarAdmin('editarEmpresa')
    }
    return verificaAdmin('editarEmpresa', toggleModal, id)
  }

  if (adminValidation)
    return (
      <UserForm onSubmit={e => handleSubmit(e, true)}>
        <h5 style={{ textAlign: 'center' }}>
          {MaquinaDeEstadosStore.getEmailUser()}
        </h5>
        <UsuariosInputConstumizado
          titulo={'Digite a senha do administrador'}
          id={'inputAdminPw'}
          placeholder={'senha...'}
          type={'password'}
          onChange={onChange}
        />
        <RedefinirSenha onClick={() => validarAdmin('fecharModal')}>
          Voltar
        </RedefinirSenha>
        {/* Voltar para a tela anterior */}
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  return (
    <UserForm onSubmit={handleSubmit}>
      <FormGroup>
        <SelectUsersCustomizado
          id="usuarios_id"
          onChange={onChange}
          defaultValue={usuariosCadastrados}
          users={usuarios}
          titulo="Selecione os usuários autorizados"
        />
        <EmpresaInputConstumizado
          titulo={'Razão social'}
          id={'razao_social'}
          defaultValue={razao_social}
          placeholder={'Insira a razão social da empresa..'}
          type={'text'}
          onChange={onChange}
        />

        <EmpresaInputConstumizado
          titulo={'Nome fantasia'}
          id={'nome_fantasia'}
          defaultValue={nome_fantasia}
          placeholder="Insira o nome fantasia da empresa.."
          type={'text'}
          onChange={onChange}
        />

        <CnpjInputCustomizado
          titulo="CNPJ"
          id="cnpj"
          placeholder="Insira seu CNPJ"
          defaultValue={cnpj}
          onChange={onChange}
        />

        <TelefoneInputCustomizado
          titulo="Telefone"
          id="phone"
          onChange={onChange}
          defaultValue={phone}
          placeholder="Insira seu telefone"
        />
      </FormGroup>
      <SaveButton type="submit" className="icon-scale-xs">
        SALVAR
      </SaveButton>
    </UserForm>
  )
}

export default ModalEditCompany
