import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  /* margin-top: 4px; */
  font-size: 12px;
  opacity: 0;
  animation: 600ms fadeIn ease-in forwards;


 .react-grid-checkbox{
    background: #fff;
    border-radius: 5px;
    border: 2px solid #a20b0b;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    text-align: center;
 }
 

  .react-grid-Container{
    height: 100%;
    width: 100%;
    /* left: -6px; */
    overflow: hidden;
  }
  .react-grid-Main{
    border: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

  }
  .react-grid-Grid{
    margin: 0;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    /* left: -20px; */
    overflow: auto;
  }

  .react-grid-Cell__value{
    text-align: center;
    color: #4a4a4a; 
  }
  .react-grid-HeaderCell {
    font-size: 100%;
    //border-left: 1px solid #949494 !important;
    box-shadow: 1px 1px 2px #000000a3;
    border-bottom: 0;
    background: #3a566e;
    color: #f3f3f3; 
  }

  .react-grid-Viewport{
    top: 40px !important;
    /* left: -20px; */
  }
  .react-grid-HeaderCell{
    border-right: 1px solid #949494 !important;
    text-align: center;
    
    
  }
  .react-grid-HeaderRow{
    border-bottom: 1px solid #3a566e;
    overflow: hidden !important;

    width: 100% !important;
  }
  .react-grid-Canvas {
    overflow: auto !important;
    width: auto !important;
    background: #e0e0e0;
    /* left: -10px; */

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(linear,
                        left bottom,
                        left top,
                        color-stop(0.44, rgb(128, 187, 224)),
                        color-stop(0.72, rgb(80, 132, 166)),
                        color-stop(0.86, rgb(95, 163, 204)   ));
    }
  }
`;

export const InputValorUnitario = styled.input`
  font-size: 90%;
  height: 98%;
  width: 98%;

  :focus {
    background: #eee;
    outline: 1px solid grey;
  }
`;

export const ContainerMiniModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  height: 300px;
  width: 500px;
  z-index: 2222;
  background: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  padding-bottom: 18px;
`;


export const InputValorDescricao = styled.textarea`
  font-size: 90%;
  height: 98%;
  width: 98%;
  border: none;
  font-size: 12px;
  :active{
     border: none;
       background: #7a3333;
  }
  :focus {
    background: #eee;
    outline: 1px solid grey;
      border: none;
  }
`;