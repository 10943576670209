import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 0 15px;
  color: ${props => props.active ? '#3b576f' : '#fff'};
  background: ${props => props.active ? '#ffffffeb' : 'transparent'};
  font-weight: 500;
  font-size: 13px;
  border-left: 1px solid ${props => props.active ? '#ab8a4e' : '#fff'};
  border-right: 1px solid ${props => props.active ? '#ab8a4e' : '#fff'};
  box-shadow: ${props => props.active ? '1px 1px 3px #0000004f' : 'none'};
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    transition: 250ms;
    :hover {
      color: #ffe095;
    }
  }

  svg {
    margin-right: 5px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  #date-selection {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    span { 
      font-style: italic;
      font-weight: 500;
      text-align: center; 
      margin-bottom: 20px;
    }

    div.date-picker-input {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }

    div.date-picker-selection {
      div.rdr-DateRange {
        border-radius: 20px !important;
        box-shadow: 1px 1px 6px #00000054;
        background: #fff;
        margin-top: 30px;
        span {
          font-style: normal;
          font-weight: normal;
          margin-bottom: 0;
        }
        .rdr-Calendar {
        background: transparent !important;
        }
      }
    }
  }

  .alerta-limite-maximo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 15px;
    font-size: 14px;
    color: #8a8a8a;

    svg {
      margin-right: 10px;
    }
  }

  .botoes-container {
    display: flex;
    /* border-radius: 15px; */
    width: 80%;
    margin: auto;

    button {
      border:none;
      background: none;
      width: 50%;
      padding: 10px;
      font-weight: 500;
    }

    #filtrar-boletim {
      background: green;
      color: #fff;
      border-right: 1px solid #828282;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    #fechar-modal-filtro-boletim {
      background: #c5bfbf;
      color: black;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

export const CustomFiltroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  i {
    font-weight: 600;
    margin: 20px auto;
  }
`;
