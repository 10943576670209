import React from 'react'
import { format } from 'date-fns'
import { Check } from 'react-feather'

import ButtonQualify from '../../StatusNotice/buttonQualify'
import NumeroEdital from '../../../../Components/ReusableComponents/TableCells/NumeroEdital'
import maquinadeestadosstore from '../../../../Stores/MaquinaDeEstadosStore'

import { ReactTooltip } from '../../../../globalStyles'

const formattedDate = data => {
  return format(new Date(data), 'dd/MM/yyyy')
}
export const columnsReprovados = [
  {
    name: 'NUM.',
    selector: 'numero',
    width: '100px',
    wrap: true,
    cell: ({ licitacao, selecionado: { download_edital } }) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ReactTooltip />
          <NumeroEdital numero={licitacao} />
          {download_edital && (
            <Check
              style={{ width: '15px', marginLeft: '2px', color: 'green' }}
              data-tip="DOWNLOAD JÁ EFETUADO"
              data-type="success"
              data-place="right"
              data-effect="solid"
            />
          )}
        </div>
      )
    }
  },
  {
    name: 'OBJETO',
    selector: 'objeto',
    // sortable: true,
    grow: 5,
    wrap: true
  },
  {
    name: 'DATA DISPUTA',
    selector: 'disputa',
    sortable: true,
    center: true,
    grow: 1,
    style: {
      fontWeight: 'bold',
      marginRight: '9px'
    },
    cell: ({ disputa }) => {
      return formattedDate(disputa)
    }
  },
  {
    name: 'ORGÃO',
    selector: 'orgao',
    sortable: true,
    grow: 3,
    wrap: true
  },

  {
    name: 'PORTAL',
    selector: 'portal',
    sortable: true,
    grow: 1,
    cell: ({ portal }) => <div className="hover-portal-tabela">{portal}</div>
  },

  {
    name: 'UF',
    selector: 'uf',
    sortable: true,
    grow: 0.3,
    wrap: true
  },

  {
    name: 'STATUS',
    selector: 'status',
    wrap: true,
    center: true,
    grow: 0.3,
    cell: edital => (
      <ButtonQualify edital={edital} status={maquinadeestadosstore.status}/>
    )
  }
]
