import React from 'react'
import { WrapperBadge } from './style'

const RoundBadge = ({
  icon,
  background,
  onClick,
  repetido,
  autoMargin,
  disabled
}) => {
  if (repetido)
    return (
      <WrapperBadge
        disabled={disabled}
        color={disabled ? '#cacaca' : background}
        onClick={onClick}
        data-tip="ESTE EDITAL TAMBÉM SE ENCONTRA NO SEU BOLETIM"
        data-place="left"
        data-type="warning"
        data-effect="solid"
      >
        {icon}
      </WrapperBadge>
    )
  return (
    <WrapperBadge
      disabled={disabled}
      color={disabled ? ' #cacaca' : background}
      onClick={onClick}
      autoMargin={autoMargin}
    >
      {icon}
    </WrapperBadge>
  )
}

export default RoundBadge
