import React from 'react'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { ReactTooltip } from '../../../globalStyles'

export const SelectCompany = inject(
  'OcorrenciasDoDiaStores',
  'MenuStores'
)(
  observer(({ OcorrenciasDoDiaStores, MenuStores }) => {
    const { idEmpresa, mudaEmpresa } = OcorrenciasDoDiaStores
    const { empresas } = MenuStores
    const menorTela = window.innerWidth < 1400
    return (
      <div style={{ width: '60%' }}>
        <ReactTooltip />
        <FormGroup style={{ margin: 0 }}>
          <InputGroup>
            <InputGroup.Addon
              className="text-center"
              style={{
                border: '1px solid #B5B5B5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f5f5f5',
                width: '50px',
                height: menorTela ? '35px' : '38px',
                marginRight: '-8px',
                borderRadius: '5px'
              }}
            >
              <FontAwesomeIcon
                style={{ marginLeft: '-5px', color: '#363636' }}
                icon="filter"
              />
            </InputGroup.Addon>

            <FormControl
              name="selected"
              onChange={mudaEmpresa}
              componentClass="select"
              value={idEmpresa}
              style={{
                height: menorTela ? '35px' : '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <option value="todas">TODAS AS EMPRESAS</option>
              {empresas.map(empresa => (
                <option value={empresa.id}>{empresa.nome_fantasia}</option>
              ))}
            </FormControl>
          </InputGroup>
        </FormGroup>
      </div>
    )
  })
)
