import styled from "styled-components";

export const ContainerChat = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    position: absolute;
    margin-right: -10px;
    z-index: 88888;
     width: 400px;
    height: 500px;
     right: 0;
      bottom: 0;
      box-shadow: 0px 2px 5px 1px #6b6b6b69;
    border-radius: 8px;

    animation: ${props => (props.status ? "0.5s slide-in-br" : "slide-out-br")};
@keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}

}


`;

export const WrapContainer = styled.div`
  height: 100%;
  margin: 1% 1% 5px;
  background: #ffffff9e;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px 1px #6b6b6b69;
  border-radius: 8px;
`;

export const WrapContainerTabela = styled.div`
  height: ${props => props.height};
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

export const TabelaContainer = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  min-height: 40px;
  border-bottom: solid 1px #3a566e;
  align-items: center;
  background: ${props => props.background};
  opacity: ${props => (props.color === "#bfbfbf" ? "80%" : "100%")};
  color: #fff;
  cursor: pointer;
  text-align: left;

  .important {
    position: absolute;
    left: -3px;
  }
`;

export const FlagColor = styled.span`
  background-color: ${props => (props.blink ? "#fbe21a" : props.background)};
  width: 7px !important;
  height: 7px;
  border-radius: 50%;
  margin: 0 1rem;
  @media (max-width: 1230px) {
    margin: 0 0.7rem;
  }
  animation: ${props => (props.blink ? "1.5s blk infinite" : "none")};

  @keyframes blk {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const changeBackground = color => {
  switch (color) {
    case "#4BA22C":
      return "#3dc30d08";
    case "#CE4747":
      return "#ff7e7e08";
    case "#dad6d6":
      return "#f7f7f7";
    default:
      return;
  }
};

const changeHoverBackground = color => {
  switch (color) {
    case "#4BA22C":
      return "#cbdac3";
    case "#CE4747":
      return "#b92c2c24";
    case "#dad6d6":
      return "#e0e0e0";
    default:
      return;
  }
};

export const SingleMessageContainer = styled.div`
  display: flex;
  background-color: ${props => changeBackground(props.background)};
  margin: 0 5px;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  background-blend-mode: lighten;
  margin-bottom: 2px;
  transition: 0.5s;
  position: relative;
  cursor: pointer;

  :hover {
    background-color: ${props => changeHoverBackground(props.background)};
    /* border: 1px solid; */
    box-shadow: 1px 1px 3px 0.5px #0000008f;
  }

  span {
    font-weight: 600;
  }
  /* min-height: 70px; */
`;

export const ToolTipActions = styled.div`
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  position: absolute;
  border: 1px solid #a2a2a2;
  border-radius: 5px;
  background-color: #263848d1;
  color: #fff;
  box-shadow: 1px 1px 11px 2px #00000033;
  padding: 5px;
  -webkit-animation: 300ms fadeInZu ease-in;
  animation: 300ms fadeInZu ease-in;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 9px;
  color: #fffffff0;
  opacity: 0;
  animation-fill-mode: forwards;
  transition: 300ms;

  :hover {
    color: #346f25;
    background: #ffffffe8;
  }

  @keyframes fadeInZu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const GavelContainer = styled.div`
  display: flex;
  width: 300px;
  margin: 20px auto 0 10px;
  align-items: center;

  @media (max-width: 1500px) and (min-width: 1200px) {
    margin-bottom: 10px;
  }

  #gavel-monitoramento {
    color: #6caade;
    font-size: 2rem;
    margin: 0 1rem;

    @media (max-width: 1500px) and (min-width: 1200px) {
      font-size: 1.5rem;
      margin: 0 0.7rem;
    }
  }

  #gavel-title {
    font-size: 0.95rem;
    color: #f9f9f9;

    @media (max-width: 1500px) and (min-width: 1200px) {
      font-size: 0.85rem;
    }
  }

  .info-licitacao {
    width: 18px;
    margin-left: 12px;
    color: #b9b9b9;

    @media (max-width: 1500px) and (min-width: 1200px) {
      margin-left: 9px;
      width: 16px;
    }
  }

  .info-licitacao:hover {
    color: #a1bfd8;
  }
`;

export const LicitacaoActions = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  /* padding-bottom: 0.8rem; */
  position: absolute;
  top: -55px;
  background-color: #ebebeb;
  width: 560px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  height: 50px;
  padding: 1rem;
  box-shadow: -2px -4px 3px 0.1px #0000004d;

  ::after {
    position: absolute;
    left: -50px;
    content: "";
    height: 100%;
    border-bottom: 25px solid #ebebeb;
    border-left: 25px solid #0000;
    border-top: 25px solid #0000;
    border-right: 25px solid #ebebeb;
  }
  @media (max-width: 1500px) and (min-width: 1200px) {
    width: 520px;
  }
  @media (max-width: 1260px) {
    position: static;
    box-shadow: none;
    border-bottom: 1px solid #c3c3c3;
    width: 100%;

    ::after {
      content: none;
    }
  }

  #icon-action {
    height: 50px;
    display: flex;
    align-items: center;
    width: 210px;
    margin-top: 3px;
    justify-content: space-between;
    @media (max-width: 1500px) and (min-width: 1200px) {
      width: 185px;
    }
  }

  span.text-header {
    font-size: 0.65rem;

    @media (max-width: 1500px) and (min-width: 1200px) {
      font-size: 0.58rem;
    }
  }
`;

export const AnimacaoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #3a566e52;
  /* margin: 1rem 1rem 0 0.75rem; */
  border-radius: 10px;
`;

export const PortalLogo = styled.img`
  border-radius: 50%;
  box-shadow: 0px 1px 8px 1px ${props => props.boxColor || "#00000094"};
  width: 20px;
  height: 20px;
  filter: ${props => (props.greyscale ? "filter: grayscale(100%)" : "none")};
  -webkit-filter: ${props =>
    props.greyscale ? "filter: grayscale(100%)" : "none"};
`;

export const Animacao = styled.div`
  animation: scale-down-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    both;
  animation-play-state: running;

  @keyframes scale-down-center {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.5);
    }
  }
`;
