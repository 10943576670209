import React from 'react'

import { ContentContainer } from './Style/styles'

const ComponentContent = ({ icon, title, subtitle, children }) => {
  console.log(icon, title, subtitle, children)
  return (
    <ContentContainer>
      <div className="header">
        <div className="icon">{icon}</div>
        <div className="d-flex flex-column">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
      </div>
      <div className="body">{children}</div>
    </ContentContainer>
  )
}

export default ComponentContent
