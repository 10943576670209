import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import { inject, observer } from "mobx-react";
import { Box, Logo } from "./styleSide";
import TextField from "@material-ui/core/TextField";
import LoginStores from "../../Stores/LoginStores";
import FormHelperText from "@material-ui/core/FormHelperText";

@inject("LoginStores")
@observer
class RecuperarSenhaUsuario extends Component {
  componentDidMount() {
    this.props.LoginStores.msg_email = "";
    MaquinaDeEstadosStore._props = this.props;
  }

  render() {
    return (
      <Box
        animationPlayState={false}
        animation={true}
        width={"450px"}
        height={"410px"}
      >
        <Logo>
          <img src="/static/Imagens/logo_wavecode.png" alt="logo-wavecode" />
          <h4>Recuperar Senha</h4>
        </Logo>
        <TextField
          error={!LoginStores.statusEmail}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          variant="outlined"
          type="email"
          required
          fullWidth
          id="email"
          label="Email para recuperação de Senha"
          name="email"
          autoComplete="email"
          // autoFocus
          onChange={this.props.LoginStores.onChange}
        />
        <FormHelperText
          style={{ marginBottom: "10px", color: this.props.LoginStores.color }}
          id="component-helper-text"
        >
          {this.props.LoginStores.msg_email}
        </FormHelperText>

        <Button
          style={{ fontSize: "13px" }}
          onClick={e => this.props.LoginStores.recuperar_senha(e)}
          size={"sm"}
          bsClass="btn"
          bsStyle="primary"
          active={true}
        >
          ENVIAR
        </Button>
        <br />
        <Link to={"/"} title={"Login"}>
          <div
            style={{ fontSize: "13px" }}
            onClick={this.props.handleRedirecionaLogin}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-left" /> Voltar para tela de
            login
          </div>
        </Link>
      </Box>
    );
  }
}

export default RecuperarSenhaUsuario;
