import React, { Component } from 'react'
import moment from 'moment'
import brl from 'moment/locale/pt-br'
import { DateRange } from 'react-date-range'

export class DatePicker extends Component {
  render() {
    const { qualData, handleSelect, onClick } = this.props
    moment.locale('pt-BR', brl)
    return (
      <>
        {this.props.showing ? (
          <div
            style={{
              minWidth: '620px',
              borderRadius: '15px',
              position: 'absolute',
              zIndex: '99990',
              padding: '20px',
              left: '30px',
              boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              backgroundColor: 'white'
            }}
          >
            <h5
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 0,
                color: '#3a566e'
              }}
            >
              Selecione a data{' '}
              {qualData === 'inicial' ? (
                <span className="ml-1 date-picker-final">inicial</span>
              ) : (
                <div className="ml-1 date-picker-final" id="fi">
                  final
                </div>
              )}{' '}
            </h5>
            <DateRange style={{}} onChange={handleSelect} />
            <div
              style={Xstyle}
              onClick={onClick}
              className="badge badge-pill h3 badge-danger "
            >
              X
            </div>
          </div>
        ) : null}
      </>
    )
  }
}

const Xstyle = {
  position: 'absolute',
  zIndex: '99999',
  cursor: 'pointer',
  top: 0,
  right: 0,
  borderRadius: 0,
  borderTopRightRadius: '15px',
  borderBottomLeftRadius: '8px',
  width: '40px',
  height: '27px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '800',
  background: 'linear-gradient(45deg, #79171785, #e74c3c)',
  boxShadow: '1px 0px 3px 0px #000000bf'
}
