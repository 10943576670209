import styled from 'styled-components';

export const WrapperBadge = styled.button`
    border-radius: 50%;
    margin: ${props => props.autoMargin ? 'auto' : '20px 0'};
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: ${props => props.color && props.color};
    color: white;
    opacity:  ${props => props.disabled && 0.5};
    box-shadow: 1px 1px 3px 0px #6f6f6f;
    padding: 0;
    margin-left: 5px;
    padding-bottom: 2px;
    transition: 125ms linear ;
      :hover {
        cursor: pointer;
        transform: ${props => !props.disabled && 'scale(1.07)'}
      }
      :focus{
        outline: 0;
      }
      :active{
      border: 0;
        transform: ${props => !props.disabled && 'scale(1.06)'};
      }
`;

export const WrapperBadegHorizontal = styled.div`
    border-radius: 7px;
    border: 0;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    min-width: 70px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: ${props => props.size};
    background: ${props => props.color && props.color};
    color: white;

`;


export const WrapperBadegHorizontalIcone = styled.div`
    border-radius: 5px;
    border: 0;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    min-width: 70px;
    font-size: 10px;
    padding: 5px 10px;
    background: ${props => props.color && props.color};
    color: white;

    svg{
    width: 30px;

    }

     transition: 150ms ease-in;
      :hover {
        cursor: pointer;
        transform: scale(1.07);
      }
       :active{
        background: #0f6674;
      }
`;
