import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import { AlertaVerde, AlertaVermelho } from "./Funcoes/FuncoesGerais";
import $ from "jquery";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class MonitoramentoStorePaginada {
  @observable
  licitacoes = [];
  @observable
  licitacao = "primeira";
  @observable
  primeira_vez = "1";
  @observable
  itens = [];
  @observable
  palavras = [];
  @observable
  naoverificadas = [];
  @observable
  verificadas = [];
  @observable
  modalidade = "";
  @observable
  coduasg = 0;
  @observable
  estado = "";
  @observable
  comprador_nome = "";
  @observable
  portal_nome = "";
  @observable
  licitacao_codigo = "";
  @observable
  monitorar_configuracao = "";
  @observable
  radio_licita = "todas";
  @observable
  id_fornecedor = "";
  @observable
  observacao = "";
  @observable
  monitorar = "";
  @observable
  mensagens = "";
  @observable
  mensagens_atuais = "";
  @observable
  numero_mensagens_atual = 0;

  @observable
  esquerda = false;
  @observable
  direita = false;
  @observable
  carregar_local = false;
  @observable
  carregar_apenas_esquerda = false;
  @observable
  pagina_mensagem_atual = 1;

  @action
  muda_primeira = () => {
    this.licitacao = "primeira";
    this.primeira_vez = "1";
  };
  @action
  compara(a, b) {
    a = new Date(a.data_ultima_msg);
    b = new Date(b.data_ultima_msg);
    return a > b ? -1 : a < b ? 1 : 0;
  }
  @action
  consulta_api = () => {
    if (!this.carregar_local) {
      ModalLoadingStore.handleOpenModal();
    }
    this.esquerda = true;
    Api.post("monitoramento/consulta_licitacoes_monitoramento_alterado/", {
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        this.licitacoes = response.data.licitacoes;
        var ordenado = this.licitacoes.slice().sort(this.compara);
        this.licitacoes = ordenado;

        if (this.primeira_vez === "1") {
          this.licitacao = localStorage.getItem("licitacao_monitorada");
          this.primeira_vez = "2";
        }

        if (this.licitacao === "primeira") {
          this.licitacao = ordenado[0].id;
        }
        if (!this.carregar_apenas_esquerda && !this.direita) {
          this.consulta_especifica(this.licitacao);
        }
        this.carregar_apenas_esquerda = false;

        this.esquerda = false;
        this.carregar_local = true;
      })
      .catch(error => {
        this.licitacoes = [];
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };
  @action
  consulta_especifica = lic => {
    if (!this.carregar_local) {
      ModalLoadingStore.handleOpenModal();
    }
    this.direita = true;
    this.licitacao = lic; //para que o problema de concorrencia carregue sempre a licitacao correta, e nao dar problema de request sobrepondo de acordo com tempo de resposta...

    this.mensagens = [];
    this.mensagens_atuais = [];
    this.numero_mensagens_atual = 0;
    this.naoverificadas = [];
    this.verificadas = [];
    this.itens = [];
    this.palavras = [];
    this.modalidade = "";
    this.coduasg = 0;
    this.estado = "";
    this.comprador_nome = "";
    this.portal_nome = "";
    this.licitacao_codigo = "";
    this.monitorar_configuracao = "";
    this.id_fornecedor = "";
    this.observacao = "";
    this.monitorar = "";
    Api.post(
      "monitoramento/consulta_licitacao_especifica_monitoramento_alterado/",
      {
        licitacao: lic,
        tiposave: "NADA",
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      }
    )
      .then(response => {
        this.licitacao = response.data.licitacao;
        this.itens = response.data.itens;
        this.palavras = response.data.palavras;
        this.naoverificadas = response.data.naoverificadas;
        this.verificadas = response.data.verificadas;
        this.modalidade = response.data.modalidade;
        this.coduasg = response.data.coduasg;
        this.estado = response.data.estado;
        this.comprador_nome = response.data.comprador_nome;
        this.portal_nome = response.data.portal_nome;
        this.licitacao_codigo = response.data.licitacao_codigo;
        this.observacao = response.data.observacao;
        this.monitorar = response.data.monitorar;

        this.pagina_mensagem_atual = 1;
        this.mensagens = this.naoverificadas.concat(this.verificadas);
        this.mensagens_atuais = this.mensagens;
        this.numero_mensagens_atual = this.mensagens.length;

        this.id_fornecedor = response.data.id_fornecedor;
        localStorage.setItem("licitacao_monitorada", lic);
        this.palavras = this.palavras.filter(function(p) {
          return p !== "wavecode3";
        });
        this.palavras = this.palavras.filter(function(p) {
          return p !== "wavecode";
        });
        this.direita = false;
        this.carregar_local = true;
      })
      .catch(error => {
        this.direita = false;
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };
  @action
  muda_estado = event => {
    this.radio_licita = event.target.value;
  };
  @action
  marcar_msgs_como_verificadas(e, tipo) {
    e.preventDefault();
    $("#" + this.licitacao).removeClass("bg-danger");
    $("#cod" + this.licitacao).removeClass("text-danger");
    $("#uasg" + this.licitacao).removeClass("text-danger");
    $("#data" + this.licitacao).removeClass("text-danger");
    $("#" + this.licitacao).removeClass("bg-success");
    $("#cod" + this.licitacao).removeClass("text-success");
    $("#uasg" + this.licitacao).removeClass("text-success");
    $("#data" + this.licitacao).removeClass("text-success");
    if (localStorage.getItem("token") === null) {
    } else {
      this.direita = true;
      Api.post(
        "monitoramento/marcar_msg_verificadas_alterado/",
        JSON.stringify({
          licitacao: this.licitacao,
          tiposave: tipo,
          monitorar: "teste",
          observacao: "obs teste",
          fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
        })
      )
        .then(response => {
          this.direita = false;
          //this.consulta_api();
          this.consulta_especifica(this.licitacao);
          this.carregar_apenas_esquerda = true;
          this.consulta_api();
          AlertaVerde("Sucesso Ao Marcar Mensagens Como Verificadas!");
        })
        .catch(error => {
          AlertaVermelho(
            "Não Foi Possível Marcar as Mensagens como Verificadas!"
          );
        });
    }
  }
  @action
  salva_dois_campos(e, tipo, id, pala, obs, t) {
    e.preventDefault();
    t.toggleModal();
    if (localStorage.getItem("token") === null) {
    } else {
      Api.post("monitoramento/salvar_chaves_observacoes/", {
        licitacao: id,
        tiposave: tipo,
        monitorar: pala,
        observacao: obs
      })
        .then(response => {
          AlertaVerde("Sucesso Ao Salvar as Informações!");
          this.consulta_especifica(id);
        })
        .catch(error => AlertaVermelho("Falhou ao Salvar as Configurações!"));
    }
  }
  @action
  onPageChanged = data => {
    let mensagens = this.mensagens;
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    let mensagensatuais = mensagens.slice(offset, offset + pageLimit);
    this.mensagens_atuais = mensagensatuais;
    this.pagina_mensagem_atual = currentPage;
  };

  @action limparVariaveis = () => {
    this.licitacoes = [];
    this.licitacao = "primeira";
    this.primeira_vez = "1";
    this.itens = [];
    this.palavras = [];
    this.naoverificadas = [];
    this.verificadas = [];
    this.modalidade = "";
    this.coduasg = 0;
    this.estado = "";
    this.comprador_nome = "";
    this.portal_nome = "";
    this.licitacao_codigo = "";
    this.monitorar_configuracao = "";
    this.radio_licita = "todas";
    this.id_fornecedor = "";
    this.observacao = "";
    this.monitorar = "";
    this.mensagens = "";
    this.mensagens_atuais = "";
    this.numero_mensagens_atual = 0;

    this.esquerda = false;
    this.direita = false;
    this.carregar_local = false;
    this.carregar_apenas_esquerda = false;
    this.pagina_mensagem_atual = 1;
  };
}
const monitoramentoStore = new MonitoramentoStorePaginada();
export default monitoramentoStore;
