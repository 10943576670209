import React from 'react'
import { ContainerBody } from './styles'
import DateDisplay from './dateDisplay'
import {
  getHour,
  FormatDateData,
  FormatDateHora
} from '../../../../../Stores/Funcoes/FuncoesGerais'
import DetalhesEditalStore from '../../store'

const markKeyword = (words, listKeyword) => {
  if (typeof words !== 'string') return words

  if (!listKeyword) return

  const list = listKeyword.map(word =>
    word
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase())

  return words
    .replace(/-/g, " ")
    .replace(/\(|\)/g, " ")
    .replace(/,/g, " ")
    .split(' ')
    .map(word =>
      list.includes(
        word
          .replace(/,/g, "")
          .replace(/:/g, "")
          .replace(/\./g, "")
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ""))
        ? (
          <span>
            <mark
              style={{
                backgroundColor: '#0190FF',
                color: 'white',
                padding: '1px 0',
                borderRadius: '3px'
              }}
            >
              {word}
            </mark>{' '}
          </span>
        ) : (
          word + ' '
        )
    )
}

const BodyDetalhes = ({
  edital: {
    uf,
    objeto,
    orgao,
    portal,
    uasg,
    disputa,
    abertura,
    createdAt,
    modalidade,
    tipo_item,
    selecionado: { palavras_usadas, msg_status }
  }
}) => {
  const tratamentoPalavras = () => {
    if (!palavras_usadas) return false
    const palavras = palavras_usadas.split(',')
    return palavras
  }
  

  const dataAbertura = {
    day: abertura ? FormatDateData(abertura) : '',
    hour: abertura ? FormatDateHora(abertura) : ''
  }
  
  const dataDisputa = {
    day: disputa ? FormatDateData(disputa) : '',
    hour: disputa ? FormatDateHora(disputa) : ''
  }
  
  const dataPublicacao = {
    day: createdAt ? FormatDateData(createdAt) : '',
    hour: createdAt ? FormatDateHora(createdAt) : ''
  }

  const formatKeyword =
    DetalhesEditalStore.words &&
    DetalhesEditalStore.words.length
      ? DetalhesEditalStore.words
        .map(key => key.toUpperCase())
      : null
  
  return (
    <>
      <ContainerBody>
        <div className="dados">
          <div className="info">
            <div className="item">
              <b>ORGÃO: </b>
              {orgao}
            </div>
            <div className="item">
              <b>PORTAL: </b>
              {portal}
            </div>
            <div className="item mb-0">
              <div className="item mr-3">
                <b>UASG: </b>
                {uasg}
              </div>
              <div className="item">
                <b>UF: </b>
                {uf}
              </div>
            </div>
            <div className="item">
              <b>MODALIDADE: </b>
              {modalidade}
            </div>
          </div>
          <div style={{display: 'flex', width: '135px', padding: '20px', marginLeft: 'auto', }}>
            <DateDisplay title="PUBLICAÇÃO" date={dataPublicacao} />
          </div>
          <div className="data">
            <DateDisplay title="ABERTURA" date={dataAbertura} />
            {getHour}
            <DateDisplay title="DISPUTA" date={dataDisputa} />
          </div>
        </div>
        <div className="descricao">
          <b>OBJETO:</b>
          <div>
            {DetalhesEditalStore.words && DetalhesEditalStore.words.length
              ? markKeyword(objeto, formatKeyword)
              : objeto}
          </div>
        </div>
        <div className="palavras">
          {DetalhesEditalStore.words && DetalhesEditalStore.words.length ?
            <>
              <b>PALAVRAS UTILIZADAS PARA CAPTURAR EDITAL:</b>
              {DetalhesEditalStore.matchWords.map((palavra, index) => (
                <span key={index}>{palavra}</span>
              ))}
            </>
            : <b style={{ textDecoration: 'underline' }}>
              PERFIL NÃO POSSUI PALAVRAS CONFIGURADAS
            </b>}
        </div>
        <div className="mensagem">
          <b>MENSAGEM: </b>
          <span> {msg_status}</span>
        </div>
        <div className="tipo-item d-flex mt-3">
          <b>TIPO DE EDITAL:</b>
          <span className="ml-2">{tipo_item}</span>
        </div>
      </ContainerBody>
    </>
  )
}

export default BodyDetalhes
