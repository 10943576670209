import { CheckCircle, Map } from "react-feather";
import { CardMsg } from "../../globalStyles";
import React from "react";
import { BlankSpace } from "./styles";
import styled from 'styled-components'

const statusEstado = (sigla, estados) => {
  if (estados) {
    return estados.indexOf(sigla) > -1;
  }
};

const CustomStyledComponent = styled(CardMsg)`
  margin-right: 5px;
  min-width: 800px;
`

export const MonitorarEstados = ({ todosEstados, estados, changeState }) => {
  const todosMarcados = estados.length === 27;
  return (
    <CustomStyledComponent
      minHeight={"275px"}
      height={"275px"}
      maxHeight="275px"
      noMarginBottom
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "30%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
          <div style={{ display: "flex", textAlign: "center" }}>
            <Map />
            &nbsp;&nbsp;
          </div>
          <div style={{ padding: "0px 10px 0px 10px" }}>
            SELECIONE OS ESTADOS
            <div style={{ fontSize: "10px" }}>
              Selecionar os estados que deseja receber notificações de novas
              oportunidades.
            </div>
          </div>
        </div>
        <div
          // name={todosMarcados ? 'NENHUM' : 'TODOS'}
          className="icon-scale-xs"
          style={{ display: "flex", flexGrow: "0.5", alignItems: "center" }}
          onClick={() => changeState(todosMarcados ? "NENHUM" : "TODOS")}
        >
          {todosMarcados && <CheckCircle size={"13px"} />}
          <small>&nbsp;TODOS</small>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          overflow: "auto"
        }}
      >
        <div
          style={{
            display: "flex",
            height: "90%",
            flexDirection: "column",
            flexWrap: "wrap",
            marginLeft: "2vh"
          }}
        >
          {todosEstados.map(item => {
            let dados = statusEstado(item.sigla, estados);
            return (
              <div
                onClick={() => changeState(item.sigla)}
                className="icon-scale-xs"
                style={{
                  display: "flex",
                  margin: "0px 10px 0px 20px",
                  flexWrap: "wrap",
                  alignItems: "center",
                  color: dados ? "rgb(58, 86, 110)" : "#757474",
                  fontWeight: dados && "bold"
                }}
              >
                {dados ? <CheckCircle size={"13px"} /> : <BlankSpace />}&nbsp;{" "}
                {item.nome}
              </div>
            );
          })}
        </div>
      </div>
    </CustomStyledComponent>
  );
};
