import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  user-select: none;

  .icon {
    background: #257c90;
     border-radius: 0px 0px 5px 5px;

    svg {
      color: #fff;
      padding: 5px;
      width: 33px;
      height: 33px;
    }
  }

  .title {
    align-self: flex-end;
    color: #fff;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export {Container}
