import React, {useState} from 'react'
import {
  UserCard,
  UserAvatar,
  UserName,
  UserEmail,
  ActionContainer
} from "../Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactTooltip } from "../../../globalStyles";
import { Trash, Smartphone, Mail } from "react-feather";
import ModalEditUser from "../modalLateral/ModalEditUser";
import { ToastError, ToastDark } from "../../../Stores/Funcoes/FuncoesGerais";
import { inject, observer } from "mobx-react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StoreConfiguracoesGerais from "../../../Stores/StoreConfiguracoesGerais"

export const Usuario = ({ user }) => {
  const { first_name, last_name, phone, username } = user
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    StoreConfiguracoesGerais.excluir_usuario(id)
  };
  
  const deleteUser = (id) => () => {
    setId(id)
    handleClickOpen()
  }
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cuidado!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir o usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Não
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <ReactTooltip effect="solid" />
      <UserCard>
        <div style={{ width: '9%' }}>
          <UserAvatar>
            <FontAwesomeIcon icon="user" size="2x" color="#3a566eb5" />
          </UserAvatar>
        </div>
        <div style={{ display: 'flex', width: '80%', alignItems: 'center' }}>
          <UserName>{`${first_name} ${last_name}`}</UserName>
          <UserEmail>
            <Mail style={{ width: '22px' }} className="mr-1" /> {username}
          </UserEmail>
          <UserEmail>
            <Smartphone style={{ width: '22px' }} className="mr-1" />
            {phone}
          </UserEmail>
        </div>
        <ActionContainer>
          <ModalEditUser user={user} />
          <DeletarUsuarioButton user={user} deleteUser={deleteUser} />
        </ActionContainer>
      </UserCard>
    </>
  )
}

const DeletarUsuarioButton = inject('StoreConfiguracoesGerais')(
  observer(({ StoreConfiguracoesGerais: { excluir_usuario, loggedInUser }, user, deleteUser }) => {
    if (user.is_superuser)
      return (
        <Trash
          className="icon-scale"
          data-effect="solid"
          color="#981212"
          data-tip="USUÁRIO ADM NÂO PODE SER EXCLUIDO "
          data-place="bottom"
          data-type="error"
        />
      )
    
    if (user.id === loggedInUser) 
      return (
        <Trash
          className="icon-scale"
          data-effect="solid"
          color="#981212"
          data-tip="Você não pode autoexcluir"
          data-place="bottom"
          data-type="error"
        />
      )
    
    return (
      <Trash
        className="icon-scale"
        data-effect="solid"
        color="#981212"
        data-tip="EXCLUIR USUÁRIO"
        data-place="bottom"
        data-type="error"
        onClick={deleteUser(user.id)}
      />
    )
  })
)

export default Usuario
