import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import { AlertaAzul, AlertaError } from "./Funcoes/FuncoesGerais";
import $ from "jquery";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class ModalSincronizarPelosEditaisStores {
  @observable
  licitacoes = [];
  @observable
  editais = [];
  @observable
  existe = [];
  @observable
  isActive = false;
  @observable
  marcar = true;

  @action
  consulta_api = () => {
    ModalLoadingStore.handleOpenModal();
    Api.get("licitacoes/buscar_editais_sincronizar/")
      .then(response => {
        this.editais = response.data.editais;
        this.isActive = true;
      })
      .catch(error => {
        AlertaError(
          "Não Foi Possível Verificar as Licitações Para Sincronização"
        );
      })
      .fonally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action
  envia_sincronizacao = () => {
    ModalLoadingStore.handleOpenModal();
    let dadus = this.editais;
    let x = 0;

    $("input[type=checkbox][name='sinc[]']").each(function() {
      if ($(this).is(":checked")) {
        dadus[x]["user"] = localStorage.getItem("user_id");
        dadus[x]["fornecedor"] = MaquinaDeEstadosStore.getIdEmpresa();
        x = x + 1;
      } else {
        dadus.removeValue("prg_cod", $(this).val()); //era numero_pregao
      }
    });

    Api.post("licitacoes/sincronizar_editais/", dadus)
      .then(response => {
        AlertaAzul("Sincronização Realizada Com Sucesso!");
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
    this.isActive = false;
  };

  @action
  toogleSelecionar = () => {
    if (this.marcar) {
      $(".marcar").each(function() {
        $(this).prop("checked", true);
      });
      this.marcar = false;
      document.getElementById("acao").innerHTML = "Desmarcar Todos";
      document.getElementById("acao").className = "btn btn-danger";
    } else {
      $(".marcar").each(function() {
        $(this).prop("checked", false);
      });
      this.marcar = true;
      document.getElementById("acao").innerHTML = "Marcar Todos";
      document.getElementById("acao").className = "btn btn-primary";
    }
  };

  @action
  handleCloseModal = () => {
    this.isActive = false;
  };

  @action
  handleOpenModal = () => {
    this.consulta_api();
  };

  @action limparVariaveis = () => {
    this.licitacoes = [];
    this.editais = [];
    this.existe = [];
    this.isActive = false;
    this.marcar = true;
  };
}

const modalSincronizarPelosEditaisStores = new ModalSincronizarPelosEditaisStores();
export default modalSincronizarPelosEditaisStores;
