import React from "react"
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DoubleSwitch } from '../../../../Themes/StyleSistema'
import { ModalItensLicitacao } from '../../ModalIntesLicitacao'
import { ModalWord } from '../../modalLateral/ModalKeyWord'
import { ModalNotes } from '../../modalLateral/ModalNotes'
import * as Style from './style'
import { LicitacaoActions } from '../../styleMonitoramento'

export const HeaderChat = inject(
  'MonitoramentoStore'
)(
  observer(({ MonitoramentoStore }) => {
    const {
      marcar_msgs_como_verificadas,
      licitacoes,
      selectLicitacao,
      palavras,
      modalCnpj,
      tags_especifica,
      changePalavrasEspecificas,
      salvarObservacoes,
      licitacao,
      changeObservacao,
      observacaoOnchange,
      toggleModalCnpj,
      todasMsg,
      msgComOcorrencia,
      onChangeFiltro
    } = MonitoramentoStore
    return (
      <HeaderChatProp
        markMessagesAsVerified={marcar_msgs_como_verificadas}
        bids={licitacoes}
        selectBid={selectLicitacao}
        words={palavras}
        modalCNPJ={modalCnpj}
        specificTags={tags_especifica}
        changeSpecificWords={changePalavrasEspecificas}
        saveNotes={salvarObservacoes}
        bidding={licitacao}
        changeNote={changeObservacao}
        onChangeNote={observacaoOnchange}
        toggleModalCNPJ={toggleModalCnpj}
        allMessages={todasMsg}
        messageWithOccurrence={msgComOcorrencia}
        onChangeFilter={onChangeFiltro}
      />
    )
  })
)

const HeaderChatProp = ({
  markMessagesAsVerified,
  bids,
  selectBid,
  words,
  modalCNPJ,
  specificTags,
  changeSpecificWords,
  saveNotes,
  bidding,
  changeNote,
  onChangeNote,
  toggleModalCNPJ,
  allMessages,
  messageWithOccurrence,
  onChangeFilter
}) => {
  const selectedBid = bids.find(
    bidding => bidding.id === selectBid
  )

  const {
    observacao,
    licitacao: id,
    itens: items,
    msg_total: totalNumberOfMessages
  } = bidding

  const allVerified = selectedBid
    ? selectedBid.numero_msg_nao_verificadas === 0
    : false

  return (
    <LicitacaoActions>
      <div
        style={{
          display: 'flex',
          fontSize: '1.5rem',
          color: '#3A566E',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div id="icon-action">
          <FontAwesomeIcon
            className={`icon-scale ${allVerified ? '' : 'blink-envelope'
              }`}
            icon={
              allVerified ? 'envelope-open-text' : 'envelope'
            }
            style={{
              color: allVerified ? '#a5ada5' : '#0dd00d',
              marginTop: '5px'
            }}
            onClick={
              allVerified
                ? () => { }
                : () => markMessagesAsVerified(id)
            }
            data-tip={
              allVerified
                ? 'JÁ VERIFICADAS'
                : 'MARCAR MENSAGENS COMO VERIFICADAS'
            }
            data-type={allVerified ? 'light' : 'success'}
            data-place="left"
            data-effect="solid"
          />
          <ModalWord
            tag={words}
            state={{
              specificTags,
              changeSpecificWords,
              saveEspecificas: () => saveNotes(id),
              modalCNPJ,
              toggleModalCNPJ
            }}
          />
          <ModalNotes
            observacao={observacao}
            state={{
              changeNote,
              onChangeNote,
              id,
              saveNotes
            }}
          />
          <ModalItensLicitacao itens={items} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <DoubleSwitch
            checked={allMessages}
            onChange={e => onChangeFilter(e)}
            value="todas"
          />

          <span
            className="text-header"
            style={{ color: '#808080ba', marginTop: '-6px' }}
          >
            {`${allMessages ? 'NOVAS' : 'TODAS'} MENSAGENS`}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <DoubleSwitch
            checked={messageWithOccurrence}
            onChange={e => onChangeFilter(e)}
            value="com_ocorrencia"
            color="primary"
          />
          <span
            className="text-header"
            style={{
              color: '#808080ba',
              marginTop: '-6px'
            }}
          >
            COM OCORRÊNCIAS
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: '#3A566E',
            fontSize: '1.1rem',
            textAlign: 'center'
          }}
        >
          <span
            style={{
              fontWeight: '700',
              fontSize: '1.3rem'
            }}
          >
            {totalNumberOfMessages}
          </span>
          <span className="text-header" style={{ opacity: '0.7' }}>
            MENSAGENS
          </span>
        </div>
      </div>
    </LicitacaoActions>
  )
}