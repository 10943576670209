import React, { useEffect, useState } from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Award, Hexagon, User } from 'react-feather'
import { makeStyles } from '@material-ui/core/styles';
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const FiltroStatus = () => {

  const classes = useStyles();
  const {
    onChangeStatus,
    onChangePerfil,
    state: { status, perfil, perfis },
    getInitialProfileData } = useFiltroEdital()

  useEffect(() => {
    if (perfis.length) {
      onChangePerfil(perfis[0].id)
    } else {
      getInitialProfileData();
      // MaquinaDeEstadosStore.fetchPerfil()
    }

  }, [perfis]);


  const currencies = [
    {
      value: 'boletim',
      label: 'Boletim',
    },
    {
      value: 'qualificado',
      label: 'Qualificado',
    },
    {
      value: 'aprovado',
      label: 'Aprovado',
    },
    {
      value: 'todos',
      label: 'Classificados'
    }
  ];
  return (
    <CardFiltroSmall>
      <CardHeard>
        <Hexagon />
        <CardTitulo>
          <div>FILTRO STATUS</div>
          <p>Selecione os status para serem filtrados</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        {perfis &&
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              label="Filtrar por status"
              select
              variant="outlined"
              required
              value={status}
              onChange={(e) => onChangeStatus(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Award />
                  </InputAdornment>
                ),
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={status !== "boletim"}
              label="Filtrar por perfil"
              select
              variant="outlined"
              required
              value={perfil}
              onChange={(e) => onChangePerfil(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <User />
                  </InputAdornment>
                ),
              }}
            >
              {perfis.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome_perfil}
                </MenuItem>
              ))}
            </TextField>
          </form>
        }
      </CardBody>
    </CardFiltroSmall>
  )
}

export default FiltroStatus
