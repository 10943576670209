import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Pagination } from '@material-ui/lab'

import { ListaDeLicitacoesContext } from '../context'
import { columnsCadastar } from './estrutura'
import Api from '../../../../../../Stores/Conexao/conexao'

import Spinner from '../../../../../../UsefulComponents/Spinner'
import { Body, ContainerTabela, ContainerPagination } from '../../styles'
import { ExclamationWarning } from '../../../../../Notifications/ExclamationWarning'
import MaquinaDeEstadosStore from '../../../../../../Stores/MaquinaDeEstadosStore'


const Tabela = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    status,
    pagina,
    quantidade: { total_registros },
    mudarPagina,
    mudarQuantidade
  } = useContext(ListaDeLicitacoesContext)

  useEffect(() => {
    if (data) {
      const { aprovado, qualificado, todos, boletim, total_registros } = data
      const quantidade = {
        aprovado,
        qualificado,
        todos,
        boletim,
        total_registros
      }
      mudarQuantidade(quantidade)
    }
  }, [data])

  const loadProposals = async () => {
    setLoading(true)
    try {
      const res = await Api.get(`portal/proposta_boletins/?fornecedor=${MaquinaDeEstadosStore.getIdEmpresa()}&status=${status}&pagina=${pagina}`)
      setData(res.data)

    } catch (err) {} finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadProposals()
  },[status, pagina])

  const total_paginas = Math.ceil(total_registros / 10)

  return (
    <Body height="calc(100vh - 240px)">
      <ContainerTabela>
        {!loading ? (
          <>
            <DataTable
              className="tabela-proposta-carregar"
              fixedHeader
              allowOverflow={true}
              overflowY={true}
              noHeader
              responsive={true}
              columns={columnsCadastar}
              data={(data && data.editais) || []}
              striped={true}
              highlightOnHover
              noDataComponent={
                <ExclamationWarning
                  title="NENHUM EDITAL ENCONTRADO"
                  height="auto"
                />
              }
            />
            <ContainerPagination>
              <div>
                <Pagination
                  count={total_paginas}
                  color="primary"
                  shape="rounded"
                  page={pagina}
                  onChange={mudarPagina}
                  showFirstButton
                  showLastButton
                ></Pagination>
              </div>
            </ContainerPagination>
          </>
        ) : (
          <Spinner />
        )}
      </ContainerTabela>
    </Body>
  )
}

export default Tabela
