import React, { useContext } from 'react'
import { ThumbsDown } from 'react-feather'

import RoundBadge from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'

const ButtonsDisqualify = ({ edital, status }) => {
  const { setEdital, statusDirectEdict } = useStatusEdital()
  const { openModalReprovar } = useContext(ModalContext)

  const showModalFail = () => {
    setEdital(edital)
    openModalReprovar()
  }

  const fail = () => {
    statusDirectEdict(edital, 'reprovado', edital)
  }

  return (
    <RoundBadge
      icon={<ThumbsDown />}
      background={edital.status === 'reprovado' ? '#ec4242' : '#ecc5ae'}
      onClick={status ? showModalFail : fail}
    />
  )
}

export default ButtonsDisqualify
