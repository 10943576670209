import styled from 'styled-components'

export const MenuStatusLicitacoes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap-reverse;
  margin-top: -1rem;
`

export const ContainerDataTable = styled.div`
  flex-flow: row wrap;
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const ContainerMenuAcoes = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 100px; */
  align-items: center;
`

export const SizeLicitacoes = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.1rem;
  text-align: center;

  span.contador-numero {
    font-weight: 700;
    color: #fff;
    height: 27px;
    font-size: 17px;
  }

  span.contador-mensagem {
    color: #b6b6b6;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
  }
`

export const SecondaryMenuLeftContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`

export const PortaisSelectContainer = styled.div`
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-animation: 500ms fadeIn;
  animation: 500ms fadeIn;
  border-radius: 7px;
  color: #3a566e;
  background: #ffffff;
  box-shadow: 1px 1px 7px 1px #0c0c0c;
  font-weight: 600;
  margin-bottom: 50px;
`

export const PortalOption = styled.div`
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
  }

  input:checkbox {
  }
`

export const NavegacaoContainer = styled.div`
  background: linear-gradient(180deg, #02000094 0%, #000102c2 100%);
  border: 2px solid #312f2fe0;
  box-sizing: border-box;
  box-shadow: 0px 1px 12px 0px #0000009e;
  border-radius: 8px;
  padding: 7px 5px 4px 5px;
  /* margin-top: -35px; */
  display: flex;
  align-items: center;
  position: absolute;
  right: calc(50% - 92px);
  top: ${props => props.top || '20px'};
`

export const SingleNavigationItem = styled.div`
  margin: 0 10px;

  a {
    color: ${props => (props.active ? '#9AD2EA' : '#fff')};
    transition: 100ms;

    svg {
      font-size: 24px;
    }
  }

  a:hover {
    color: #b8d0ea;
  }
`
