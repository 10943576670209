import React, { useContext } from 'react'
import DataTable from 'react-data-table-component'

import { sliceString } from '../../../../../../../../Services/funcoes'
import { ExclamationWarning } from '../../../../../../../Notifications/ExclamationWarning'
import { PropostaEspecificaContext } from '../../../context'
import { getColumns } from './estrutura'

import * as S from './style'

const TabelaItens = () => {
  const { rows } = useContext(PropostaEspecificaContext)

  const tratamentoTotalEqualizado = rows.map(item => {
    return item.valor_total_equal == null
      ? item.valor_total
      : item.valor_total_equal
  })
  const totalEqualizado = tratamentoTotalEqualizado.reduce(
    (acc, current) =>
      acc + Number(current.replace(/\./g, '').replace(',', '.')),
    0
  )

  const result = rows.reduce(
    (acc, current) =>
      acc + Number(current.valor_total.replace(/\./g, '').replace(',', '.')),
    0
  )

  const columns = () => {
    let columns = rows
      .filter(rw => rw.checked)
      .map(
        ({
          checked,
          item_edital_id,
          descricao,
          descricao_completa,
          unidade_fornecimento,
          ...rest
        }) => rest
      )

    const keys = Object.keys(columns[0])

    columns = keys.map((k, i) => ({
      name: sliceString(k.toUpperCase(), 10),
      selector: k,
      center: true,
      grow: 1,
      wrap: true
    }))

    return columns
  }

  return (
    <S.ContainerTable>
      <DataTable
        className="table-items-confirmation-proposal"
        fixedHeader
        allowOverflow={true}
        overflowY={true}
        noHeader
        responsive={true}
        columns={getColumns(Object.keys(rows[0]))}
        data={rows.filter(item => item.checked)}
        striped={true}
        highlightOnHover
        noDataComponent={
          <ExclamationWarning title="NENHUM ITEM CADASTRADO" height="70vh" />
        }
      />
      <S.ContainerValue>
        <div className="amount green">
          TOTAL R${' '}
          {result.toLocaleString('pt-br', { minimumFractionDigits: 4 })}
        </div>
        <div className="amount blue">
          TOTAL EQL R${' '}
          {totalEqualizado.toLocaleString('pt-br', {
            minimumFractionDigits: 4
          })}
        </div>
      </S.ContainerValue>
    </S.ContainerTable>
  )
}

export default TabelaItens
