import React from 'react'
import { inject, observer } from 'mobx-react'
import { ThumbsDown, ThumbsUp, Bookmark } from 'react-feather'

import maquinadeestadosstore from '../../../../../Stores/MaquinaDeEstadosStore'
import { MODULO_BOLETIM } from '../../../../../Services/contratos'
import { AlertaVermelho } from '../../../../../Stores/Funcoes/FuncoesGerais'

import * as S from './styles'

const FooterDetalhes = inject(
  'DetalhesEditalStore',
  'MaquinaDeEstadosStore'
)(
  observer(({ DetalhesEditalStore, MaquinaDeEstadosStore }) => {
    const { status: statusQualifications } = MaquinaDeEstadosStore
    const {
      editais,
      id,
      toggleModalQualificar,
      toggleModalReprovar,
      toggleModalAprovar,
      mudarStatus
    } = DetalhesEditalStore
    const edital = editais.find(edital => edital.id === id)
    const { status, licitacao, usuarios } = edital

    const path = window.location.pathname

    if (!maquinadeestadosstore.getContrato(MODULO_BOLETIM)) return null

    const alert = () => {
      AlertaVermelho("Aprovação desabilitada, veja suas configurações")
    }

    const aprove = () => {
      statusQualifications.aprovado[1] || statusQualifications.aprovado[2]
        ? aproveModal()
        : mudarStatus('aprovado')
    }

    const qualify = () => {
      mudarStatus('qualificado')
    }

    const disapprove = () => {
      mudarStatus('reprovado')
    }

    const qualifyModal = () => {
      toggleModalQualificar()
    }

    const aproveModal = () => {
      toggleModalAprovar()
    }

    const disapproveModal = () => {
      toggleModalReprovar()
    }

    return (
      <S.ContainerFooter>
        <span className="navigation-tip">
          Utilize as teclas <i>{`<- ->  TAB, ENTER e DELETE  `}</i> para
          facilitar a navegação
        </span>
        <div className="action-buttons">
          {status === 'qualificado' && (
            <div
              className="aprovar"
              onClick={
                statusQualifications.aprovado[0]
                  ? aprove
                  : alert
              }
            >
              <button>
                <Bookmark />
              </button>
              <span>APROVAR</span>
            </div>
          )}
          {(status === 'neutro' || status === 'reprovado' || !status) && (
            <div
              className="qualificar"
              onClick={
                !statusQualifications.qualificado[1] &&
                !statusQualifications.qualificado[2]
                  ? qualify
                  : qualifyModal
              }
            >
              <button>
                <ThumbsUp />
              </button>
              <span>QUALIFICAR</span>
            </div>
          )}
          {status !== 'reprovado' && path === '/prospeccao/qualificados' && (
            <div
              className="desqualificar"
              onClick={
                statusQualifications.reprovado[1] ? disapproveModal : disapprove
              }
            >
              <button>
                <ThumbsDown />
              </button>
              <span>REPROVAR</span>
            </div>
          )}
          {status !== 'reprovado' && path === '/prospeccao/aprovados' && (
            <div
              className="desqualificar"
              onClick={
                statusQualifications.reprovado[2] ? disapproveModal : disapprove
              }
            >
              <button>
                <ThumbsDown />
              </button>
              <span>REPROVAR</span>
            </div>
          )}
          {status !== 'reprovado' && path === '/prospeccao/boletim' && (
            <div
              className="desqualificar"
              onClick={
                statusQualifications.reprovado[0] ? disapproveModal : disapprove
              }
            >
              <button>
                <ThumbsDown />
              </button>
              <span>REPROVAR</span>
            </div>
          )}
        </div>
        {status === 'qualificado' && (
          <div className="status">
            {true ? (
              <div className="simbolo qualificado">
                <ThumbsUp />
              </div>
            ) : null}
            {usuarios.length ? (
              <div className="user">
                {usuarios[0].first_name} {usuarios[0].last_name}
              </div>
            ) : null}
            <div className="title-user">{`EDITAL: ${licitacao}`}</div>
            <div className="message">{`Qualificado`}</div>
          </div>
        )}
        {status === 'aprovado' && (
          <div className="status">
            {true ? (
              <div className="simbolo aprovado">
                <Bookmark />
              </div>
            ) : null}
            {usuarios.length ? (
              <div className="user">
                {usuarios[0].first_name} {usuarios[0].last_name}
              </div>
            ) : null}
            <div className="title-user">{`EDITAL: ${licitacao}`}</div>
            <div className="message">{`Aprovado`}</div>
          </div>
        )}
        {status === 'reprovado' && (
          <div className="status">
            {true ? (
              <div className="simbolo desqualificado">
                <ThumbsDown />
              </div>
            ) : null}
            {usuarios.length ? (
              <div className="user">
                {usuarios[0].first_name} {usuarios[0].last_name}
              </div>
            ) : null}
            <div className="title-user">{`EDITAL: ${licitacao}`}</div>
            <div className="message">{`Reprovado`}</div>
          </div>
        )}
      </S.ContainerFooter>
    )
  })
)

export default FooterDetalhes

// import ModalQualificar from "../../modalConfirmacao/ModalQualificar";
// import ModalReprovar from "../../modalConfirmacao/ModalReprovar";
// import ModalAprovar from "../../modalConfirmacao/ModalAprovar";
