import React from "react";
import { CardMsg } from "../../globalStyles";
import { Mail } from "react-feather";
import TagsInput from "react-tagsinput";
import { inject, observer } from "mobx-react";
import { ButtonMobile } from "../../globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificacoesEmail = inject("ConfiguracoesMonitoramentoStores")(
  observer(({ ConfiguracoesMonitoramentoStores }) => {
    const {
      tags_notificao_email,
      handleChange_notificao_email,
      tag_notificao_email,
      handleMonitoramentoAviso,
      statusAviso,
      handleChangeInput_notificao_email
    } = ConfiguracoesMonitoramentoStores;
    return (
      <CardMsg margin={"0px 0px 10px 0px"} height="initial" overflow="initial">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", textAlign: "center" }}>
            <Mail />
            &nbsp;&nbsp;
          </div>
          <div style={{ padding: "0px 10px 0px 10px", textAlign: "start" }}>
            <div>NOTIFICAÇÕES POR E-MAIL</div>
            <div style={{ fontSize: "10px" }}>
              Sempre que novas oportunidades forem econtradas
            </div>
          </div>
        </div>

        <TagsInput
          classNameRemove={"react-tagsinput-remove"}
          className="tagsinputblul"
          inputProps={{ placeholder: "Adicione seu email" }}
          value={tags_notificao_email}
          onChange={e => handleChange_notificao_email(e)}
          inputValue={tag_notificao_email}
          onChangeInput={e => handleChangeInput_notificao_email(e)}
        />

        <ButtonMobile
          className="icon-scale"
          background={statusAviso}
          onClick={handleMonitoramentoAviso}
        >
          {statusAviso ? (
            <FontAwesomeIcon
              icon="bell"
              data-tip="DESATIVAR ALERTAS NA TELA"
              data-type="success"
              data-effect="solid"
              data-place="left"
            />
          ) : (
              <FontAwesomeIcon
                icon={"bell-slash"}
                data-tip={"ATIVAR ALERTAS NA TELA"}
                data-type="info"
                data-effect="solid"
                data-place="left"
              />
            )}
        </ButtonMobile>
      </CardMsg>
    );
  })
);

export default NotificacoesEmail;
