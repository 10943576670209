import React, { useState } from 'react';
import styled from 'styled-components';
import useInterval from './useInterval';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  animation: 1s fadeIn ease-in-out forwards;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 6em;
    height: 6em;
  }
  .loader {
    margin: 10px auto;
    transition: 2s;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: ${props => handleColor(props.cor, 0.2)};
    border-right: ${props => handleColor(props.cor, 0.2)};
    border-bottom: ${props => handleColor(props.cor, 0.2)};
    border-left: ${props => handleColor(props.cor, 1)};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const handleColor = (color, opacity) => {
  let cor;
  switch (color) {
    case 'green':
      cor = '132, 181, 57';
      break;
    case 'yellow':
      cor = "244, 204, 6";
      break;
    case 'red':
      cor = "253, 38, 12";
      break;
    case 'blue':
      cor = "1, 172, 225"
      break;
    default:
      cor = "148, 233, 243"
  }
  return `0.6em solid rgba(${cor}, ${opacity})`;
}

const SpinnerMenor = () => {
  const [cor, setCor] = useState(null);
  const cores = ["green", "red", "yellow", "blue"];
  useInterval(() => setCor(cores[Math.floor(Math.random() * cores.length)]), 1000);
  return (
    <Container cor={cor}>
      <div className="loader"></div>
      {/*       
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
    </Container>
  )
}

export default SpinnerMenor
