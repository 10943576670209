import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import SideStores from "./SideStores";
import { AlertaVerde, AlertError, AlertSuccess } from "./Funcoes/FuncoesGerais";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import $ from "jquery";
import { ConsoleHelper } from "../utils/ConsoleHelper";
import { columnsDownload } from "../Pages/Prospection/DownloadList/contexto/tabelaDownload";

class MonitoramentoStore {
  // FILTROS
  @observable
  periodoSelecionado = "TODAS";
  @observable
  dateShowing = false;
  @observable
  qualData = "inicial";
  @observable
  dataInicial = new Date().toLocaleDateString("pt-br");
  @observable
  dataFinal = new Date().toLocaleDateString("pt-br");
  @observable
  periodo = "";

  @observable qtdNovasMensagens = 0;

  @observable
  todosPortais = [
    { nome: "ComprasNet", id: 5 },
    {
      nome: "Licitações-e",
      id: 6
    },
    {
      nome: "Compras Públicas",
      id: 9
    },
    {
      nome: "Portal BLL",
      id: 877
    },
    {
      nome: "Portal BNC",
      id: 930
    }
  ];
  @observable
  portal = this.todosPortais.map(p => p.id);

  // FIM DOS FILTROS

  // PAGINAÇÃO
  @observable paginaAtual = 1;
  @observable fetchingMessages = false;

  @action changeData = async () => {
    if (this.fetchingMessages) return;
    if (this.licitacao.msg_total <= this.mensagens.length) return;
    const ocorrencias = this.msgComOcorrencia ? "&ocorrencias=true" : "";
    // Aumentar a página atual
    this.paginaAtual++;
    this.fetchingMessages = true;
    try {
      // Fazer api call e receber o array com as mensagens.
      const res = await Api.get(
        `/monitoramento/bidding_message/?licitacao=${this.licitacao.licitacao}&page=${this.paginaAtual}${ocorrencias}`
      );
      this.mensagens = [...this.mensagens, ...res.data.msg];
    } catch (err) {
      ConsoleHelper("err", err);
    } finally {
      this.fetchingMessages = false;
    }
    // this.mensagens = arr; // Atualizar os dados que sao passados pros componentes;
  };

  // FIM DE PAGINAÇÃO

  @observable
  observacaoOnchange = "";
  @observable
  radio_licita = "todas";
  @observable
  verificada = false;
  @observable
  isFetching = false;
  @observable
  selectLicitacao = 0;
  @observable
  licitacoes = [];
  @observable
  licitacao = [];
  @observable
  palavras = [];
  @observable
  tags_especifica = [];
  @observable
  tag_especifica = "";

  // ORDENAÇÃO DAS LICITAÇÕES
  @observable ordemDecrescente = true;
  @observable sortBy = "";

  @observable
  todasMsg = false;

  @observable fetchingLicitacoes = false;

  @observable
  novaMsg = false;
  @observable
  mensagensOriginal = [];
  @observable
  novasMensagens = [];
  @observable
  carregando = false;

  @observable
  mensagens = [];
  @observable msgComOcorrencia = false;
  @observable msgSemOcorrencia = false;
  @observable monitoramento = false;

  // MODAL BLOCK CNPJ
  @observable modalCnpj = false;

  // CHAT
  @observable status = true;
  @observable isFetting = false;
  @observable flagConsulta = true;

  limparVariaveisFiltro = () => {
    this.msgSemOcorrencia = false;
    this.msgComOcorrencia = false;
    this.novaMsg = false;
    this.todasMsg = false;
  };

  @action
  flagFirebase = () => {
    this.flagConsulta = false;
    setTimeout(() => {
      this.flagConsulta = true;
    }, 3000);
  };

  @action
  saveBidManually = async (codeUASG, tradingFloor) => {
    try {
      const res = await Api.post("licitacoes/cadastrar_licitacao_manual/", {
        pregao: tradingFloor,
        uasg: codeUASG
      });
      AlertSuccess(res.data.message);
    } catch (error) {
      AlertError(error.response.data.error);
      ConsoleHelper(error);
    } finally {
      return true;
    }
  };

  @action
  changePortal = () => (this.portal = $("#portais-select").val());

  @action
  showDatePicker = () => (this.dateShowing = !this.dateShowing);

  @action
  handleSelect = range => {
    this.dataInicial = new Date(range.startDate).toLocaleDateString("pt-br");
    this.dataFinal = new Date(range.endDate).toLocaleDateString("pt-br");
    if (this.qualData === "final")
      setTimeout(() => {
        this.dateShowing = false;
        this.qualData = "inicial";
      }, 250);
    else this.qualData = "final";
  };

  @action
  handlePeriodo = periodo => {
    this.periodoSelecionado = periodo;
  };

  @action
  toggleModalCnpj = () => {
    this.modalCnpj = !this.modalCnpj;
  };

  @action
  statusVerificada = () => {
    this.monitoramento = true;
  };

  @action
  onChangeFiltro = ({ target: { value, checked } }) => {
    this.loanding();
    switch (value) {
      case "todas":
        this.todasMsg = !this.todasMsg;
        if (!this.todasMsg) {
          this.mensagens = this.mensagensOriginal;
          this.vericarFiltro();
        } else {
          this.mensagens = this.novasMensagens;
          this.vericarFiltro();
        }
        this.msgComOcorrencia = !this.msgComOcorrencia;
        break;

      case "com_ocorrencia":
        this.msgComOcorrencia = !this.msgComOcorrencia;
        checked
          ? this.consultaMensagensComOcorrencia()
          : this.consulta_especifica(this.selectLicitacao);
        break;

      case "sem_ocorrencia":
        this.msgSemOcorrencia = !this.msgSemOcorrencia;
        if (this.msgSemOcorrencia) {
          this.msgComOcorrencia = false;
          this.filtroMensagensSemOcorrencia();
        }
        this.vericarTodasMsg();
        break;

      default:
    }
  };

  consultaMensagensComOcorrencia = async () => {
    const idLicitacao = `?licitacao=${this.selectLicitacao}`;
    this.paginaAtual = 1;
    const page = `&page=${this.paginaAtual}`;
    const ocorrencias = "&ocorrencias=true";
    this.isFetching = false;
    try {
      const res = await Api.get(
        `/monitoramento/bidding_message/${idLicitacao}${page}${ocorrencias}`
      );

      const {
        observacao,
        palavra_chave,
        msg,
        comprador,
        modalidade,
        estado,
        uasg,
        numero_licitacao,
        msg_nao_verificadas,
        portal,
        palavras,
        itens,
        page_total,
        msg_total
      } = res.data;

      this.licitacao = {
        licitacao: this.selectLicitacao,
        comprador_nome: comprador,
        modalidade,
        coduasg: uasg,
        observacao,
        monitorar: palavra_chave,
        licitacao_codigo: numero_licitacao,
        portal_nome: portal,
        itens,
        palavras,
        msg,
        naoverificadas: msg.filter(el => !el.verificada),
        msg_nao_verificadas,
        verificada: msg.filter(el => el.verificada),
        msg_total,
        page_total,
        estado
      };

      const newMonitorar = palavra_chave === "wavecode3" ? null : palavra_chave;
      this.tags_especifica =
        newMonitorar != null ? palavra_chave.split(",") : [];
      this.verificada = this.licitacao.naoverificadas.length === 0;
      this.observacaoOnchange = String(observacao).replace(
        /wavecode3|wavecode2|wavecode|null/g,
        ""
      );
      // };
      let index = palavras.indexOf("");
      this.palavras = palavras.splice(index + 1);
      if (this.palavras[0] === "wavecode3") {
        this.palavras.splice(0, 1);
      }
      this.selectLicitacao = this.licitacao.licitacao;
      this.isFetching = true;

      this.novasMensagens = this.licitacao.naoverificadas;

      this.mensagens = msg;
      this.mensagensOriginal = msg;
    } catch (e) {
      ConsoleHelper("err", e);
    } finally {
      this.status = true;
    }
  };

  vericarFiltro = () => {
    if (this.msgComOcorrencia) {
      this.filtroMensagensOcorrencia();
    }
    if (this.msgSemOcorrencia) {
      this.filtroMensagensSemOcorrencia();
    }
  };

  vericarTodasMsg = () => {
    if (!this.msgSemOcorrencia && !this.msgComOcorrencia && !this.todasMsg) {
      this.mensagens = this.mensagensOriginal;
    }
    if (!this.msgSemOcorrencia && !this.msgComOcorrencia && this.todasMsg) {
      this.mensagens = this.novasMensagens;
    }
  };

  loanding = () => {
    this.carregando = true;
    setTimeout(() => (this.carregando = false), 900);
  };

  @action
  onChangeFiltroMsg = () => {
    this.loanding();
    if (!this.msgOcorrencia && !this.novaMsg) {
      this.todasMsg = true;
      this.mensagens = this.mensagensOriginal;
    }
    if (this.msgOcorrencia || this.novaMsg) {
      this.todasMsg = false;
    }
  };

  @action
  onChangeFiltroOcorrencia = () => {
    this.msgOcorrencia = !this.msgOcorrencia;
    this.onChangeFiltro();
    if (this.msgOcorrencia) {
      this.filtroMensagensOcorrencia();
    }
  };

  @action
  onChangeFiltroNova = () => {
    this.novaMsg = !this.novaMsg;
  };

  filtroMensagensSemOcorrencia = () => {
    let msg = [];
    if (!this.todasMsg) {
      msg = this.mensagensOriginal;
    } else {
      msg = this.novasMensagens;
    }
    let palavras = this.palavras;
    this.mensagens = msg.filter(item => {
      let novo = [];
      palavras.forEach(pl => {
        novo.push(item.texto.toUpperCase().indexOf(pl.toUpperCase()) !== -1);
      });
      return novo.every(item => item === false);
    });
  };

  filtroMensagensOcorrencia = () => {
    let msg = [];
    if (!this.todasMsg) {
      msg = this.mensagensOriginal;
    } else {
      msg = this.novasMensagens;
    }
    let palavras = this.palavras;

    this.mensagens = msg.filter(item => {
      let novo = [];
      palavras.forEach(pl => {
        novo.push(item.texto.toUpperCase().indexOf(pl.toUpperCase()) !== -1);
      });
      return novo.some(item => item === true);
    });
  };

  filtroNovasMensagensOcorrencia = () => {
    let palavras = this.palavras;
    let msg = this.novasMensagens;
    this.mensagens = msg.filter(item => {
      let novo = [];
      palavras.forEach(pl => {
        novo.push(item.texto.indexOf(pl) !== -1);
      });
      return novo.some(item => item === true);
    });
  };

  @action
  consultaApi = async tabChange => {
    if (!tabChange) ModalLoadingStore.handleOpenModal();
    else this.fetchingLicitacoes = true;
    const { dataInicial, dataFinal, periodoSelecionado, portal } = this;

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("fornecedor_pk", MaquinaDeEstadosStore.getIdEmpresa());
    urlSearchParams.set("portal", portal.join());

    if (periodoSelecionado === "ESPECÍFICA") {
      urlSearchParams.set("data_inicio", dataInicial);
      urlSearchParams.set("data_fim", dataFinal);
    } else if (periodoSelecionado !== "TODAS") {
      urlSearchParams.set("periodo", periodoSelecionado.toLowerCase());
    }

    try {
      const res = await Api.get(
        `/monitoramento/licitacoes_monitoradas/?${urlSearchParams.toString()}`
      );
      const { licitacoes } = res.data;
      this.sortBy = ""; // Resetando o sort
      this.licitacoes = licitacoes;
      this.checkNewMessages(licitacoes);
      if (!this.monitoramento) {
        this.selectLicitacao = !this.verificada
          ? licitacoes[0].id
          : this.selectLicitacao;
        if (!this.verificada) this.consulta_especifica(this.selectLicitacao);
      }
    } catch (err) {
      ConsoleHelper(err);
    } finally {
      if (!tabChange) ModalLoadingStore.handleCloseModal();
      else this.fetchingLicitacoes = false;
    }
  };

  removerItensEmBrancosPalavras = () => {
    return;
  };

  checkNewMessages = licitacoes => {
    const atual = licitacoes.find(lic => lic.id === this.licitacao.licitacao);
    if (!atual) return;
    const novas =
      atual.numero_msg_nao_verificadas - this.licitacao.msg_nao_verificadas;
    this.qtdNovasMensagens = novas;
  };

  @action atualizaAtual = () => {
    const { licitacao } = this.licitacao;
    this.qtdNovasMensagens = 0;
    this.consulta_especifica(licitacao);
  };

  removerItensEmBrancosPalavras = () => {
    return;
  };

  @action
  consulta_especifica = async lic => {
    if (this.status) {
      if (lic) this.selectLicitacao = Number(lic);
      this.status = false;
      this.limparVariaveisFiltro();

      this.observacao = "";
      this.observacaoOnchange = "";
      // this.tags_especifica = [];
      this.isFetching = false;
      this.verificada = false;
      try {
        this.paginaAtual = 1;
        const res = await Api.get(
          `/monitoramento/bidding_message/?licitacao=${this.selectLicitacao}&page=${this.paginaAtual}`
        );

        const {
          observacao,
          palavra_chave,
          msg,
          comprador,
          modalidade,
          estado,
          uasg,
          numero_licitacao,
          msg_nao_verificadas,
          portal,
          palavras,
          itens,
          page_total,
          msg_total
        } = res.data;

        this.licitacao = {
          licitacao: this.selectLicitacao,
          comprador_nome: comprador,
          modalidade,
          coduasg: uasg,
          observacao,
          monitorar: palavra_chave,
          licitacao_codigo: numero_licitacao,
          portal_nome: portal,
          itens,
          palavras,
          msg,
          naoverificadas: msg.filter(el => !el.verificada),
          msg_nao_verificadas,
          verificada: msg.filter(el => el.verificada),
          msg_total,
          page_total,
          estado
        };
        const newMonitorar =
          palavra_chave === "wavecode3" ? null : palavra_chave;
        this.tags_especifica =
          newMonitorar != null ? palavra_chave.split(",") : [];
        this.verificada = this.licitacao.naoverificadas.length === 0;
        this.observacaoOnchange = String(observacao).replace(
          /wavecode3|wavecode2|wavecode|null/g,
          ""
        );
        // };
        let index = palavras.indexOf("");
        this.palavras = palavras.splice(index + 1);
        if (this.palavras[0] === "wavecode3") {
          this.palavras.splice(0, 1);
        }
        this.selectLicitacao = this.licitacao.licitacao;
        this.isFetching = true;

        this.novasMensagens = this.licitacao.naoverificadas;

        this.mensagens = msg;
        this.mensagensOriginal = msg;

        // this.mensagens = this.licitacao.naoverificadas
        //   .concat(verificadas)
        //   .sort(this.sortMessages);
        // this.mensagensOriginal = naoverificadas
        //   .concat(verificadas)
        //   .sort(this.sortMessages);
      } catch (e) {
        ConsoleHelper("err", e);
      } finally {
        this.status = true;
      }
    }
  };

  sortMessages = (a, b) => {
    const replace = time => {
      const arr = time.split(" ");
      const dia = arr[0].slice(0, 2);
      const mes = arr[0].slice(3, 6);
      const ano = arr[0].slice(5, 10);
      return new Date(mes + dia + ano + " " + arr[1]);
    };
    return replace(b.data_hora) - replace(a.data_hora);
  };

  replaceTime = time => {
    const arr = time.split(" ");
    const dia = arr[0].slice(0, 2);
    const mes = arr[0].slice(3, 6);
    const ano = arr[0].slice(5, 10);
    return new Date(mes + dia + ano + " " + arr[1]);
  };

  @action
  consultaFirebase = async () => {
    const { dataInicial, dataFinal, periodoSelecionado, portal } = this;

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("fornecedor_pk", MaquinaDeEstadosStore.getIdEmpresa());
    urlSearchParams.set("portal", portal.join());

    if (periodoSelecionado === "ESPECÍFICA") {
      urlSearchParams.set("data_inicio", dataInicial);
      urlSearchParams.set("data_fim", dataFinal);
    } else if (periodoSelecionado !== "TODAS") {
      urlSearchParams.set("periodo", periodoSelecionado.toLowerCase());
    }

    try {
      const res = await Api.get(
        `/monitoramento/licitacoes_monitoradas/?${urlSearchParams.toString()}`
      );
      const { licitacoes } = res.data;
      this.sortBy = ""; // Resetando o sort
      this.licitacoes = licitacoes;
      this.checkNewMessages(licitacoes);
    } catch (err) {
      ConsoleHelper(err);
    } finally {
    }
  };

  @action
  marcarTodasMensagens = async () => {
    try {
      await Api.post("monitoramento/marcar_msg_todas_verificadas/", {
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      await this.consultaApi();
      await SideStores.consulta_notificacoes();
    } catch (e) {}
  };

  @action
  changePalavrasEspecificas = palavra => {
    const arrayPalavras = this.tags_especifica || [];
    this.tags_especifica = arrayPalavras.includes(palavra)
      ? arrayPalavras.filter(p => palavra !== p)
      : [...arrayPalavras, palavra];
  };

  @action
  salvarObservacoes = async (id, deletar) => {
    this.verificada = false;
    let palavra =
      this.tags_especifica.toString() && this.tags_especifica.toString();
    try {
      await Api.post("monitoramento/salvar_chaves_observacoes_alterado/", {
        licitacao: id,
        tiposave: "palavras",
        monitorar: palavra,
        observacao: deletar ? "" : this.observacaoOnchange,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      AlertaVerde("Sucesso ao salvar as informações!", 3000);
      this.consulta_especifica(id);
    } catch (e) {}
    // this.handleChangeInput_especifica('')
  };

  @action reverseLicitacoes = () => {
    this.licitacoes = this.licitacoes.reverse();
    this.ordemDecrescente = !this.ordemDecrescente;
  };

  @action sortByParam = param => {
    let licitas = this.licitacoes.map(licit => ({
      ...licit,
      uasg: Number(licit.uasg) || "",
      edital: Number(licit.edital)
    }));
    if (param === "data_ultima_msg") {
      licitas = licitas.map(el => ({
        ...el,
        data_ultima_msg: this.replaceTime(el.data_ultima_msg)
      }));
      this.licitacoes = licitas
        .sort((a, b) => b[param] - a[param])
        .map(el => ({
          ...el,
          data_ultima_msg: el.data_ultima_msg.toLocaleString()
        }));
    } else {
      this.licitacoes = licitas.sort((a, b) => b[param] - a[param]);
    }

    this.sortBy = param;
    this.ordemDecrescente = true;
  };

  @action verificaMensagem = ({ target }) => {
    const value = Number(target.getAttribute("value"));
    this.mensagens = this.mensagens.map(el =>
      el.id === value ? { ...el, verificada: true } : el
    );
    Api.post(
      "monitoramento/mensagem_verificada/",
      {
        mensagem_id: value
      },
      {}
    )
      .then(response => {})
      .catch(err => ConsoleHelper("err", err));
  };

  @action
  marcar_msgs_como_verificadas = async id => {
    try {
      await Api.post(
        "monitoramento/marcar_msg_verificadas_alterado/",
        JSON.stringify({
          licitacao: id,
          tiposave: "verificar",
          monitorar: "teste",
          observacao: "obs teste",
          fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
        })
      );
      this.verificada = true;
      // await this.consulta_api();
      this.mensagens = this.mensagens.map(msg => ({
        ...msg,
        verificada: true
      }));
      this.licitacoes = this.licitacoes.map(el =>
        el.id === id
          ? { ...el, numero_msg_nao_verificadas: 0, contem_palavra_chave: 0 }
          : el
      );
      await SideStores.consulta_notificacoes();
    } catch (e) {
      ConsoleHelper("err", e);
    } finally {
    }
  };

  @action muda_estado = async event => {
    const value = event.currentTarget.attributes.value.value;
    this.radio_licita = value;
  };

  @action
  handleChange_especifica = tags => {
    this.tags_especifica = tags;
  };

  @action
  handleChangeInput_especifica = tag => {
    this.tag_especifica = tag;
  };

  @action changeObservacao = obs => {
    this.observacaoOnchange = obs;
  };

  @action
  changeSelectedLicita = id => {
    this.selectLicitacao = id;
  };

  @action
  trueMonitorado = () => {
    this.monitorados = true;
  };

  @action
  falseMonitorado = () => {
    this.monitorados = false;
  };

  @action
  limpar_dados = () => {
    this.tags_especifica = [];
    this.tag_especifica = [];
  };

  @action limparVariaveis = () => {
    this.periodoSelecionado = "TODAS";
    this.dateShowing = false;
    this.qualData = "inicial";
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.periodo = "";

    this.todosPortais = [
      { nome: "ComprasNet", id: 5 },
      {
        nome: "Licitações-e",
        id: 6
      },
      {
        nome: "Compras Públicas",
        id: 9
      },
      {
        nome: "Portal BLL",
        id: 877
      },
      {
        nome: "Portal BNC",
        id: 930
      }
    ];
    this.portal = this.todosPortais.map(p => p.id);

    // FIM DOS FILTROS

    this.observacaoOnchange = "";
    this.radio_licita = "todas";
    this.verificada = false;
    this.isFetching = false;
    this.selectLicitacao = 0;
    this.licitacoes = [];
    this.licitacao = [];
    this.palavras = [];
    this.tags_especifica = [];
    this.tag_especifica = "";

    this.todasMsg = false;

    this.fetchingLicitacoes = false;

    this.novaMsg = false;
    this.mensagensOriginal = [];
    this.novasMensagens = [];
    this.carregando = false;

    this.mensagens = [];
    this.msgComOcorrencia = false;
    this.msgSemOcorrencia = false;
    this.monitoramento = false;

    // MODAL BLOCK CNPJ
    this.modalCnpj = false;
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consultaApi();
  };
}

const monitoramentoStore = new MonitoramentoStore();
export default monitoramentoStore;
