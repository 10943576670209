import React from "react";
import {Activity} from "react-feather";
import {Line} from "react-chartjs-2";
import {CardMD, CardMDBarra, CardMDBody, CardMDHearder, Titulo} from "../styleDashboard";


const DadosGraficos = (label, corPrincipal, corSecundaria, data ) => {
  return{

    fill: true,
    label: label,
    lineTension: 0.5,
    backgroundColor: corPrincipal,
    borderColor: corSecundaria,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: corPrincipal,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: corPrincipal,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 10,
    data: data
  }
};


export const EditaisGraficos = ({data}) => {
  const {historico_editais} = data;

  const GetDados = (atributo) =>{
    return(
      [historico_editais[6][atributo], historico_editais[5][atributo], historico_editais[4][atributo], historico_editais[3][atributo], historico_editais[2][atributo], historico_editais[1][atributo], historico_editais[0][atributo]]
    )
  }
  const Encontrado = GetDados('editais')
  const Aprovados = GetDados('aprovados');
  const Novas = GetDados('novas_oportunidades');
  const Qualificados = GetDados('qualificados');


  const dataGrafico = () => {
    return {
      key: 0,
      labels: [historico_editais[6].dia, historico_editais[5].dia, historico_editais[4].dia, historico_editais[3].dia, historico_editais[2].dia, historico_editais[1].dia, historico_editais[0].dia],
      datasets: [
        DadosGraficos("CAPTURADOS", 'rgba(78,41,150,0.20)', 'rgb(78,41,150)', Encontrado),
        DadosGraficos("NOVAS OPORT.", 'rgba(8,124,173,0.28)', 'rgb(8,124,173)', Novas),
        DadosGraficos("QUALIFICADOS", 'rgba(240,142,7,0.28)', 'rgb(240,142,7)', Qualificados),
        DadosGraficos("APROVADOS", 'rgba(99,156,35,0.29)', 'rgb(99,156,35)', Aprovados),

      ],

    }
  };
  return (
      <CardMD color={'#139cab'}>
        <CardMDHearder>
          <div style={{marginRight: '15px'}}>
            <Activity size={'35px'}/>
          </div>
          <Titulo width={'100%'}>
            <h5>HISTÓRICO EDITAIS ENCONTRADOS</h5>
            <span>Histórico dos últimos 7 dias</span>
          </Titulo>
        </CardMDHearder>
        <CardMDBarra/>
        <CardMDBody>
          <Line data={dataGrafico()}  options={{legend: {display: true}, scales: {yAxes: [{ticks: {beginAtZero: true}}]}}}/>
        </CardMDBody>
      </CardMD>
  )
};
