import React from 'react'
import DataTable from 'react-data-table-component'

import { ExclamationWarning } from '../../../Notifications/ExclamationWarning'

import { columnsLicitacao, rowLicitacao } from './customTabela'

const SampleExpandedComponent = ({ data }) => {
  const {
    decreto7174,
    margem_de_preferencia,
    unidade_fornecimento,
    tratamento_diferenciado,
    descricao_completa,
    quantidade
  } = data;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '15px',
        borderBottom: '0.5px solid #9494946e', //#8c8c8c
        background: '#eeeeee'
      }}
    >
      <div
        style={{
          background: 'white',
          width: '100%',
          fontSize: '12px',
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '#00000021 0px 2px 4px 0.1px' //0px 2px 4px 0.1px #00000073
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px'
          }}
        >
          <div>
            QTD. <b>{quantidade}</b>
          </div>
          <div>
            UNID. DE FORN. <b>{unidade_fornecimento}</b>
          </div>
          <div>
            TRATAMENTO DIF.{' '}
            <b style={{ whiteSpace: 'nowrap' }}>{tratamento_diferenciado}</b>
          </div>
          <div>
            MARGEM DE PREF. <b>{margem_de_preferencia}</b>
          </div>
          <div>
            DESC. 7174 <b>{decreto7174}</b>
          </div>
        </div>
        <div>
          <b>DESCRIÇÃO COMPLETA:</b>
        </div>
        <div>{descricao_completa}</div>
      </div>
    </div>
  )
}

const TabelaItemLicitacao = ({ data }) => {
  return (
    <div style={{ display: 'flex', padding: '10px', overflowY: 'hidden' }}>
      <div
        style={{
          flexFlow: 'row wrap',
          background: 'white',
          borderRadius: '10px',
          width: '100%',
          height: '73vh',
          marginLeft: '10px',
          boxShadow: '0px 2px 4px 0.1px #00000073'
        }}
      >
        <DataTable
          fixedHeader
          className="tabelalo"
          fixedHeaderScrollHeight="86vh"
          subHeader
          allowOverflow={true}
          overflowY={true}
          striped
          pointerOnHover
          columns={columnsLicitacao}
          expandableRowsComponent={<SampleExpandedComponent data={data} />}
          expandableRows
          expandOnRowClicked
          highlightOnHover
          customTheme={rowLicitacao}
          data={data}
          pagination
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100]}
          paginationComponentOptions={{
            rowsPerPageText: 'Licitações por pagina:',
            rangeSeparatorText: 'até'
          }}
          noDataComponent={
            <ExclamationWarning
              title="Nenhum item encontrado nessa licitação"
              height="58vh"
            />
          }
        />
      </div>
    </div>
  )
}

export default TabelaItemLicitacao
