import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { inject, observer } from 'mobx-react'
import { Trash2 } from 'react-feather'

import ImprimirPropostaStore from '../store'
import ImageCropper from '../../../../UsefulComponents/ImageCropper/ImageCropper'
import { CustomInput } from './stylesInputs'
import { FileInput } from '../../../../Components/Modules/Proposta2/CadastrarProposta/PropostaEspecifica/fluxoDeEnvio/dadosPdf/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InserLogoEmpresa, AssinaturaImg } from '../styles'

const Rodape = inject('ImprimirPropostaStore')(
  observer(({ ImprimirPropostaStore, noTitle }) => {
    const { onChange, handleAssinatura } = ImprimirPropostaStore // não remover
    return (
      <RodapeComponent
        onChange={onChange}
        handleAssinatura={handleAssinatura}
        noTitle={noTitle}
        {...ImprimirPropostaStore}
      />
    )
  })
)

const RodapeComponent = props => {
  const {
    nome,
    rg,
    cpf,
    dadosBancarios,
    observacoes,
    validade,
    // handleAssinatura,
    loadingAssinatura,
    onChange,
    assinatura,
    noTitle
  } = props

  const validationValue = type => {
    return type ? true : false
  }

  const [image, setImage] = useState(false)

  const handleCrop = e => {
    setImage(e.target.files[0])
    e.target.value = null
    // handleAssinatura();
  }

  return (
    <div className="section-rodape">
      {!noTitle && <div className="title">RODAPÉ</div>}
      <div className="input-nome-rep-legal">
        <CustomInput
          mRight
          width="40%"
          validationValues={validationValue(true)}
        >
          <span>Nome do representante legal</span>
          <input
            id="input-nome-rep-legal"
            name="nome"
            type="text"
            placeholder=""
            value={nome}
            onChange={e => onChange(e)}
          />
        </CustomInput>
        <CustomInput
          width="30%"
          mRight
          validationValues={validationValue(true)}
        >
          <span>RG</span>
          <input
            id="input-imprimir-rg"
            name="rg"
            type="text"
            placeholder=""
            value={rg}
            onChange={e => onChange(e)}
          />
        </CustomInput>
        <CustomInput width="30%" validationValues={validationValue(true)}>
          <span>CPF</span>
          <InputMask
            mask="999.999.999-99"
            id="input-imprimir-cpf"
            name="cpf"
            type="text"
            placeholder=""
            value={cpf}
            onChange={e => onChange(e)}
          />
        </CustomInput>
      </div>
      <div className="imprimir-dados-textarea">
        <CustomInput
          width="100%"
          height={'80px'}
          validationValues={validationValue(true)}
        >
          <span>Dados bancários</span>
          <textarea
            rows="4"
            id="input-dados-bancarios"
            name="dadosBancarios"
            type="text"
            placeholder=""
            value={dadosBancarios}
            onChange={e => onChange(e)}
          />
        </CustomInput>
        <CustomInput
          width="100%"
          height={'80px'}
          validationValues={validationValue(true)}
        >
          <span>Observações gerais</span>
          <textarea
            rows="4"
            id="input-obs-gerais"
            name="observacoes"
            type="text"
            placeholder=""
            value={observacoes}
            onChange={e => onChange(e)}
          />
        </CustomInput>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <CustomInput width="30%" validationValues={validationValue(true)}>
            <span>Data de validade:</span>
            <input
              id="input-imprimir-validade"
              name="validade"
              type="text"
              placeholder="Data de validade conforme o edital"
              value={validade}
              onChange={e => onChange(e)}
            />
          </CustomInput>
          {loadingAssinatura && (
            <FontAwesomeIcon
              style={{
                marginLeft: 'auto',
                marginRight: '20px',
                fontSize: '30px'
              }}
              icon="spinner"
              spin
            />
          )}
          {!loadingAssinatura && assinatura && (
            <AssinaturaImg>
              <Trash2
                id="remover-assinatura"
                className="icon-scale-xs"
                data-tip="REMOVER ASSINATURA"
                data-type="error"
                data-place="top"
                data-effect="solid"
                onClick={ImprimirPropostaStore.excluirAssinatura}
              />
              <img src={assinatura} id="assinatura-pdf" alt="assinatura-pdf" />
            </AssinaturaImg>
          )}
          <InserLogoEmpresa
            htmlFor="enviar-assinatura"
            className="icon-scale-xs"
          >
            INSERIR ASSINATURA
          </InserLogoEmpresa>
          <FileInput
            type="file"
            id="enviar-assinatura"
            accept="image/*"
            onChange={handleCrop}
          />
        </div>
        <div style={{ marginLeft: 'auto', color: '#8a8888', fontSize: '80%' }}>
          Insira aqui sua assinatura no formato de imagem png/jpg
        </div>
        {image && (
          <ImageCropper image={image} closeModal={() => setImage(false)} />
        )}
      </div>
    </div>
  )
}

export default Rodape
