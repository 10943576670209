import React from "react";
import styled from "styled-components";

export const ButtonText = styled.div`
  color: #b6b6b6;
  font-size: 14px;
  font-weight: 500;
`;

export const Monitorar = ({ onClick, color, children }) => (
  <div onClick={onClick} className="d-flex flex-column align-items-center">
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-scale"
      data-tip="ESCOLHER LICITAÇÕES PARA MONITORAR"
      data-type="warning"
      data-place="left"
      data-effect="solid"
    >
      <path
        d="M13.5 0.6875C6.04336 0.6875 0 5.59707 0 11.6562C0 14.2719 1.12852 16.666 3.00586 18.5486C2.34668 21.2064 0.142383 23.5742 0.116016 23.6006C0 23.7219 -0.0316406 23.9012 0.0369141 24.0594C0.105469 24.2176 0.253125 24.3125 0.421875 24.3125C3.91816 24.3125 6.53906 22.6355 7.83633 21.602C9.56074 22.2506 11.475 22.625 13.5 22.625C20.9566 22.625 27 17.7154 27 11.6562C27 5.59707 20.9566 0.6875 13.5 0.6875ZM6.75 13.3438C5.8166 13.3438 5.0625 12.5896 5.0625 11.6562C5.0625 10.7229 5.8166 9.96875 6.75 9.96875C7.6834 9.96875 8.4375 10.7229 8.4375 11.6562C8.4375 12.5896 7.6834 13.3438 6.75 13.3438ZM13.5 13.3438C12.5666 13.3438 11.8125 12.5896 11.8125 11.6562C11.8125 10.7229 12.5666 9.96875 13.5 9.96875C14.4334 9.96875 15.1875 10.7229 15.1875 11.6562C15.1875 12.5896 14.4334 13.3438 13.5 13.3438ZM20.25 13.3438C19.3166 13.3438 18.5625 12.5896 18.5625 11.6562C18.5625 10.7229 19.3166 9.96875 20.25 9.96875C21.1834 9.96875 21.9375 10.7229 21.9375 11.6562C21.9375 12.5896 21.1834 13.3438 20.25 13.3438Z"
        fill={color || "#FFF"}
      />
    </svg>
    <ButtonText>Monitorar</ButtonText>
    {children}
  </div>
);

export const Portais = ({ onClick, color, children }) => (
  <div
    onClick={onClick}
    className="d-flex flex-column align-items-center mx-3"
    style={{ position: "relative" }}
  >
    <svg
      className="icon-scale"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 0.84375L0.84375 8.4375L13.5 16.0312L26.1562 8.4375L13.5 0.84375Z"
        fill={color || "white"}
      />
      <path
        d="M13.5 23.0622L3.42225 17.0151L0.84375 18.5625L13.5 26.1562L26.1562 18.5625L23.5778 17.0151L13.5 23.0622Z"
        fill={color || "white"}
      />
      <path
        d="M13.5 17.9997L3.42225 11.9526L0.84375 13.5L13.5 21.0937L26.1562 13.5L23.5778 11.9526L13.5 17.9997Z"
        fill={color || "white"}
      />
    </svg>

    <ButtonText>Portais</ButtonText>
    {children}
  </div>
);
