import styled, { keyframes } from 'styled-components'

export const colors = {
  firstColor: '#3A566E',
  secondColor: '#fff',
  thirdColor: '#E5E5E5'
}

export const ModalOverlay = styled.div`
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  overflow: hidden;
  opacity: 0;
  z-index: ${props => props.zIndex || 99999};
  animation: 1s fadeInModal forwards;

  @keyframes fadeInModal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const ModalHead = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 20vh;
  color: white;
  background: #3a566e;
  z-index: 999999;
`

export const ModalHeadTitle = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10vh;
  justify-content: space-between;
  color: white;
`

export const ModalBoby = styled.div`
  padding: 20px;
  height: 80vh;
`

const animationStart = keyframes({
  from: {
    opacity: 0,
    right: -500
  },
  to: {
    opacity: 1,
    right: 0
  }
})

const animationExit = keyframes({
  from: {
    opacity: 1,
    right: 0
  },
  to: {
    opacity: 0,
    right: -500
  }
})
const animationLeftStart = keyframes({
  from: {
    opacity: 0,
    left: -500
  },
  to: {
    opacity: 1,
    left: 0
  }
})

const animationLeftExit = keyframes({
  from: {
    opacity: 1,
    left: 0
  },
  to: {
    opacity: 0,
    left: -500
  }
})

const handleAnimation = (left, animation) => {
  if (left) return animation ? animationLeftExit : animationLeftStart
  return animation ? animationExit : animationStart
}

export const MoldaContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${props => (props.left ? 'unset' : '0')};
  left: ${props => (props.left ? '0' : 'unset')};
  background: #e5e5e5;
  width: ${props => props.width || `500px`};
  height: 100vh;
  box-shadow: 0px -4px 6px 1px #0000004a;
  overflow: ${props => (props.overflowVisible ? 'visible' : 'hidden')};
  animation: ${props => handleAnimation(props.left, props.animation)} 1s;
  z-index: 199999;
`

export const ModalContainerLeft = styled(MoldaContainer)`
  right: unset;
  left: 0;
  animation: ${props =>
      props.animation ? animationLeftExit : animationLeftStart}
    1s;
`

///Modal Sincronizar

export const MoldaContainerSincronizar = styled.div`
  position: absolute;
  top: 22vh;
  color: white;
  /* right: 35vw; */
  background:  #1b2a38;
  width: ${props => (props.width ? props.width : '500px')};
  height: 45vh;
  border-radius: 50px 0px 50px 0px;
  box-shadow: 0px -4px 6px 1px #0000004a;
  overflow: hidden;
  /* // animation: ${props =>
    props.animation ? animationExit : animationStart} 1s; */
  z-index: 199999;
`

export const BarraModalSincronizar = styled.div`
  position: relative;

  background: #4bccff;
  width: 150%;
  margin-top: 50px;
  height: 3px;
  -webkit-animation: slide-out-right 3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    infinite both;
  animation: slide-out-right 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite
    both;

  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(-600px);
      transform: translateX(-600px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 1;
    }
  }
`

export const ModalContainer = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  position: absolute;
  left: ${props => (props.left ? props.left : '80px')};
  right: ${props => (props.right ? props.right : '80px')};
  bottom: ${props => props.bottom || '30px'};
  top: ${props => props.top || '30px'};
  background-color: #f5f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0px 1px 8px 1px #00000091;
  max-height: ${props => props.maxHeight || 'unset'};
`

export const ModalHeader = styled.div`
  background-color: #436177;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ContainerHeaderBG = styled.div`
    background-color: #eaeaea;
    height: auto;/* ${props => props.height || '30%'}; */
    padding: ${props => props.padding || '2rem'};
`

export const HeaderBG = styled.div`
  background-color: transparent;
  /* padding: 1.5rem; */
  color: #000;
  height: 70%;
  font-size: 13px;

  @media (max-width: 1200px) {
    font-size: 11px;
  }
`

export const DataSpan = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
`

export const DateHeader = styled.b`
  font-size: 16px;
  text-align: center;
  margin-bottom: 6px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;

  @media (max-width: 1400px) {
    width: 300px;
  }
  @media (max-width: 1100px) {
    width: 200px;
  }
`

export const ModalTitle = styled.h5`
  margin: 0;
  color: #fff;
  margin-left: 0.8rem;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`

export const VerticalLine = styled.div`
  height: 100%;
  border-left: 1.5px solid #000;
  margin: 0 30px;

  @media (max-width: 1200px) {
    margin: 0 15px;
  }
`

export const VerticalLine2 = styled.div`
  height: 130px;
  border-left: 1.3px solid #000;
  margin: 0 3rem;
  top: 0;
  margin-top: -25px;
`

export const BodyMessage = styled.h5`
  text-align: center;
  margin: 2rem;
  white-space: normal;
  color: #436077;
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem;

  button {
    font-size: 12px;
    height: 28px;
    display: flex;
    align-items: center;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    min-width: 90px;
    width: fit-content;
    justify-content: center;
    padding-top: 2px;
    margin-right: 10px;
    font-weight: 600;
  }

  .modal-confirm {
    background-color: #436077;
    color: #fff;
    /* transition: background-color 500ms; */

    :hover {
      background-color: #4a9256;
    }
  }

  .modal-back {
    color: #3a566e;

    :hover {
      color: #fff;
      background-color: #a95a5a;
    }
  }

  .baixar-novamente {
    color: #3a566e;

    :hover {
      color: #fff;
      background-color: #52947b;
    }
  }
`

export const ButtonImprimir = styled.button`
  border: none;
  padding: 5px 15px;
  border-radius: 7px;
  background: #436077;
  color: #fff;
  font-weight: 600;
  box-shadow: 1px 1px 6px 2px #909090;
  margin-bottom: 5px;
  transition: 200ms;

  :hover {
    background: #eaeaea;
    color: #436077;
  }
  :focus {
    outline: none;
  }
`
