import { CardMsg } from "../../globalStyles";
import { Calendar } from "react-feather";
import { CardSemana } from "./CardSemana";
import { CardHora } from "./CardHora";
import React from "react";

export const AgendamentoDeEnvio = ({
  weekDays,
  handleDay,
  hours,
  handleHour
}) => {
  return (
    <CardMsg minHeight={"150px"} maxHeight={"150px"} justifyContent={"space-between"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "50%",
          marginRight: "10px"
        }}
      >
        <div style={{ display: "flex", textAlign: "center" }}>
          <Calendar />
          &nbsp;&nbsp;
        </div>
        <div style={{ padding: "0px 10px 0px 10px" }}>
          AGENDAMENTO DE ENVIO
          <div style={{ fontSize: "10px" }}>
            Selecionar dias e horários que deseja receber notificações de novas
            oportunidades.
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "600px",
          flexDirection: "column",
          overflow: "auto",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ display: "flex", width: "420px" }}>
          <CardSemana
            sigla={"SEG"}
            dia={"SEGUNDA"}
            handleDay={handleDay}
            weekDays={weekDays}
          />
          <CardSemana
            sigla={"TER"}
            dia={"TERÇA"}
            handleDay={handleDay}
            weekDays={weekDays}
          />
          <CardSemana
            sigla={"QUA"}
            dia={"QUARTA"}
            handleDay={handleDay}
            weekDays={weekDays}
          />
          <CardSemana
            sigla={"QUI"}
            dia={"QUINTA"}
            handleDay={handleDay}
            weekDays={weekDays}
          />
          <CardSemana
            sigla={"SEX"}
            dia={"SEXTA"}
            handleDay={handleDay}
            weekDays={weekDays}
          />
        </div>

        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            width: "400px"
          }}
        >
          {/* <CardHora hora={"7"} hours={hours} handleHour={handleHour} /> */}
          <CardHora hora={"9"} hours={hours} handleHour={handleHour} />
          {/* <CardHora hora={"11"} hours={hours} handleHour={handleHour} /> */}
          <CardHora hora={"13"} hours={hours} handleHour={handleHour} />
          {/* <CardHora hora={"15"} hours={hours} handleHour={handleHour} /> */}
          <CardHora hora={"17"} hours={hours} handleHour={handleHour} />
          {/* <CardHora hora={"19"} hours={hours} handleHour={handleHour} /> */}
          <CardHora hora={"22"} hours={hours} handleHour={handleHour} />
        </div>
      </div>
    </CardMsg>
  );
};
