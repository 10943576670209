import { hot } from "react-hot-loader/root";
import React, { Component, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Snackbar } from "@material-ui/core";
import Menu from "./Components/Menu/Menu";
import Notify from "./Components/Menu/Notify/Notify";
import { ToastContainer } from "react-toastify";
import Side from "./Components/Side/Side";

import ConfiguracaoMonitoramento from "./Pages/ConfigurationMonitoring/ConfiguracoesMonitoramento";
import OcorrenciaDoDia from "./Pages/OccurrencesPanel/OcorrenciasDoDia";
import Funil from "./Components/System/Funil/Funil";
import Monitoramento from "./Pages/Monitoring/Monitoramento";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-datasheet/lib/react-datasheet.css";
import NewAlert from "react-s-alert";
import Licitacao from "./Components/System/Licitacao2/Licitacao";
import MaquinaDeEstadosStore from "./Stores/MaquinaDeEstadosStore";
import LicitacoesPaginada from "./Pages/Bids/LicitacoesPaginada";
import Financeiro from "./Pages/Financial/Financeiro";
import DownloadLinks from "./Pages/DownloadLinks";

import "./css/responsive.css";

import TemplateAlert from "./Themes/TemplateAlert";

// Verificar ausência de internet
import ModalStatusConexao from "./StatusConexao/ModalStatusConexao";

import PainelMonitoramento from "./Pages/BiddingPanel/PainelMonitoramento";

import { isAuthenticated } from "./Services/auth";
import {
  MODULO_EDITAIS,
  MODULO_BOLETIM,
  MODULO_MONITORAMENTO,
  MODULO_LICITACOES,
  MODULO_PROPOSTA
} from "./Services/contratos";
import TesteCartao from "./Components/Finances/TesteCartao";
import ModalCartao from "./Components/Finances/ModalCartao";
import Agenda from "./Components/System/Agenda";
import * as moment from "moment";
import ModalLoading from "./Components/Menu/Modal/ModalLoading";
import { SomSitema } from "./som/SomSistema";
import ConfiguracoesGerais from "./Pages/GeneralConfiguration";
import ConfiguracaoEditais from "./Pages/ConfigurationSearchForNotices/ConfiguracaoEditais";
import IndexDashboard from "./Pages/Dashboard/indexDashboard";
import ExcluirLicitacoes from "./Pages/Bids/ExcluirLicitacoes/ExcluirLicitacoes";
import GoogleAnalytics from "./GoogleAnalytics";

// ## ROTAS NOVAS ##
import BoletimDiario from "./Pages/Prospection/DailyBulletin/DailyBulletin";
import Qualificados from "./Pages/Prospection/Qualified/Qualified";
import Aprovados from "./Pages/Prospection/Approved/Approved";
import Reprovados from "./Pages/Prospection/Failed/Failed";
import Download from "./Pages/Prospection/DownloadList/DownloadList";
import BuscaManual from "./Pages/Prospection/ManualSearch/ManualSearch";
import ModalDetalhesNovo from "./Components/Modules/ModalDetalhes/ModalDetalhes";

import GerenciarProposta from "./Pages/Proposal/GerenciarProposta/GerenciarProposta";

import { MainBody } from "./globalStyles";
import CadastrarProposta from "./Components/Modules/Proposta2/CadastrarProposta/CadastrarProposta";
import PDFMaker from "./Pages/Proposal/ImprimirProposta/contexto/pdf/MyDocument";
import Configuracoes from "./Pages/Proposal/ConfiguracoesProposta/Configuracoes";
import AvisoCredencial from "./UsefulComponents/EditarCredencial/AvisoCredencial";
import ModalVerificarNavegador from "./UsefulComponents/VerificarNavegador/ModalVerificarNavegador";

import SplashScreen from "./splash";

import Setting from "./Pages/Prospection/Settings/Setting";
import { SettingsContextProvider } from "./Pages/Prospection/Settings/Context";
import { Alert } from "@material-ui/lab";

moment.locale("pt-BR");
const PrivateRoute = ({
  component: Component,
  restricted,
  history,
  ...rest
}) => {
  let permission;
  if (typeof restricted === "string") {
    const { getContrato, id_fornecedor } = MaquinaDeEstadosStore;
    if (!id_fornecedor) return true;
    permission = getContrato(restricted, id_fornecedor);
  }
  if (typeof restricted === "number" || typeof restricted === "boolean") {
    permission = restricted;
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() === true && permission ? (
          <Component {...props} history={props.history} />
        ) : (
          <Redirect
            to={{
              pathname: "/configuracaogeral",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

@inject(
  "ModalSincronizarStores",
  "MenuStores",
  "MaquinaDeEstadosStore",
  "StatusConexaoStores",
  "LoginStores",
  "EditaisStores",
  "OcorrenciasDoDiaStores"
)
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { firebaseOn: 20 };
  }

  intervalId = 0;

  verifica_autenticado() {
    if (localStorage.getItem("token") === null) {
      MaquinaDeEstadosStore.redireciona_login();
    }
  }

  async componentDidMount() {
    this.intervalId = setInterval(() => {
      const {
        isActive,
        handleOpenModal,
        handleCloseModal
      } = this.props.StatusConexaoStores;
      window.addEventListener("offline", function(e) {
        handleOpenModal();
      });
      if (!navigator.onLine) handleOpenModal();
      if (navigator.onLine && isActive) handleCloseModal();
    }, 3000);

    if (!localStorage.getItem("token")) {
      return this.props.MaquinaDeEstadosStore.redireciona_login();
    }
    this.props.MaquinaDeEstadosStore.consultaEmpresas();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    MaquinaDeEstadosStore._props = this.props;
  }

  render() {
    const vertical = "top";
    const horizontal = "center";
    const { email } = this.props.LoginStores;
    const { id_fornecedor, getContrato } = this.props.MaquinaDeEstadosStore;

    if (window.location.pathname === "/static") {
      window.location.pathname = "/static/static";
    }

    if (!email && !id_fornecedor) return <SplashScreen />
    return (
      <SettingsContextProvider>
        <div
          className='wrapper blur-container'
          style={{
            minHeight: "100%",
            width: "100%",
            backgroundColor: "#f9f9f9"
          }}
        >
          {!MaquinaDeEstadosStore.isPushWorking && (
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={true}>
              <Alert severity='error'>
                Sua conexão com nosso servidor foi perdida Atualize F5 a página
                para reconectar ou verifique se as notificações do navegador
                estão ativadas, caso o problema persista entre em contato com
                nossa equipe de suporte.
              </Alert>
            </Snackbar>
          )}
          <Notify />
          <ToastContainer />
          {this.props.OcorrenciasDoDiaStores.som && <SomSitema></SomSitema>}
          <NewAlert
            stack={{ limit: 5, spacing: 0 }}
            contentTemplate={TemplateAlert}
          />
          <ModalLoading />
          <ModalDetalhesNovo />

          {this.props.MenuStores.fullScreen ? (
            <div style={{ width: "100%", flexDirection: "column" }}>
              <Switch>
                <Route
                  exact
                  path='/ocorrenciasdodia'
                  component={OcorrenciaDoDia}
                />
                <Route
                  exact
                  path='/painel_monitoramento'
                  component={PainelMonitoramento}
                />
              </Switch>
            </div>
          ) : (
            <>
              <Side path={this.props.location.pathname} />
              <ModalStatusConexao />
              <div
                className='row-bg'
                id='content content-app'
                style={{
                  minHeight: "100%",
                  marginLeft: "65px",
                  width: "calc(100vw - 65px)",
                  overflow: "hidden"
                }}
              >
                <Menu />
                <MainBody>
                  <Switch>
                    <PrivateRoute
                      exact
                      path='/dashboard'
                      restricted={true}
                      component={IndexDashboard}
                    />
                    <PrivateRoute
                      path='/prospeccao/boletim'
                      restricted={MODULO_BOLETIM}
                      component={BoletimDiario}
                    />
                    <PrivateRoute
                      path='/prospeccao/qualificados'
                      restricted={MODULO_BOLETIM}
                      component={Qualificados}
                    />
                    <PrivateRoute
                      path='/prospeccao/aprovados'
                      restricted={MODULO_BOLETIM}
                      component={Aprovados}
                    />
                    <PrivateRoute
                      path='/prospeccao/reprovados'
                      restricted={MODULO_BOLETIM}
                      component={Reprovados}
                    />
                    <PrivateRoute
                      path='/prospeccao/download'
                      restricted={MODULO_BOLETIM}
                      component={Download}
                    />
                    <PrivateRoute
                      path='/prospeccao/pesquisa/:id'
                      restricted={MODULO_EDITAIS}
                      component={BuscaManual}
                    />
                    <PrivateRoute
                      path='/prospeccao/configuracoes'
                      restricted={MODULO_BOLETIM}
                      component={ConfiguracaoEditais}
                    />
                    <PrivateRoute
                      path='/prospeccao/demais-configuracoes'
                      restricted={MODULO_BOLETIM}
                      component={Setting}
                    />
                    <PrivateRoute
                      path='/proposta/cadastrar'
                      restricted={MODULO_PROPOSTA}
                      component={props => (
                        <CadastrarProposta
                          {...props}
                          companyId={MaquinaDeEstadosStore.getIdEmpresa()}
                        />
                      )}
                    />
                    <PrivateRoute
                      path='/proposta/gerenciar'
                      restricted={MODULO_PROPOSTA}
                      component={GerenciarProposta}
                    />
                    {/* <PrivateRoute
                        path="/proposta/exportar"
                        restricted={MODULO_BOLETIM}
                        component={ExportarProposta}
                      /> */}
                    <PrivateRoute
                      path='/proposta/configuracoes'
                      restricted={MODULO_PROPOSTA}
                      component={Configuracoes}
                    />
                    <PrivateRoute
                      path='/proposta/pdf'
                      restricted={MODULO_BOLETIM}
                      component={PDFMaker}
                    />
                    <PrivateRoute
                      exact
                      path='/configuracoesbuscadeeditais'
                      restricted={MODULO_BOLETIM}
                      component={ConfiguracaoEditais}
                    />
                    <PrivateRoute
                      exact
                      path='/licitacoes'
                      restricted={MODULO_LICITACOES}
                      component={LicitacoesPaginada}
                    />
                    <PrivateRoute
                      exact
                      path='/licitacao'
                      restricted={MODULO_LICITACOES}
                      component={Licitacao}
                    />
                    <PrivateRoute
                      exact
                      path='/funil'
                      restricted={MODULO_LICITACOES}
                      component={Funil}
                    />

                    <PrivateRoute
                      exact
                      path='/agenda'
                      restricted={MODULO_LICITACOES}
                      component={Agenda}
                    />

                    <PrivateRoute
                      exact
                      path='/licitacoes/excluir'
                      restricted={MODULO_LICITACOES}
                      component={ExcluirLicitacoes}
                    />

                    <PrivateRoute
                      exact
                      path='/monitoramento'
                      restricted={MODULO_MONITORAMENTO}
                      component={Monitoramento}
                    />
                    <PrivateRoute
                      exact
                      path='/ocorrenciasdodia'
                      restricted={MODULO_MONITORAMENTO}
                      component={OcorrenciaDoDia}
                    />
                    <PrivateRoute
                      exact
                      path='/painel_monitoramento'
                      restricted={MODULO_LICITACOES}
                      component={PainelMonitoramento}
                    />
                    <PrivateRoute
                      exact
                      path='/configuracoesmonitoramento'
                      restricted={MODULO_MONITORAMENTO}
                      component={ConfiguracaoMonitoramento}
                    />
                    <Route
                      exact
                      path='/configuracaogeral'
                      component={ConfiguracoesGerais}
                    />
                    <Route exact path='/links' component={DownloadLinks} />
                    <Route exact path='/financeiro' component={Financeiro} />

                    <Route exact path='/testeCartao' component={TesteCartao} />
                    <Route exact path='/modalCartao' component={ModalCartao} />
                  </Switch>

                  <GoogleAnalytics />
                  <AvisoCredencial />
                  <ModalVerificarNavegador />
                </MainBody>
              </div>
            </>
          )}
        </div>
      </SettingsContextProvider>
    );
  }
}

export default hot(App);
