import React, { useState } from 'react'
import { CardBody, CardFiltro, CardHeard, CardTitulo } from './styles'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import { FormControl, FormGroup } from 'react-bootstrap';
import { Layers } from 'react-feather';
import styled from 'styled-components';
import { useEffect } from 'react';

const CheckContainer = styled.div`
  margin-bottom: 10px;
`;

const FiltroMeEpp = () => {
  const { state: { meEpp }, onChangeMeEpp } = useFiltroEdital();

  const isMe = Array.isArray(meEpp) && meEpp.some(e => e.includes('Tipo'))

  const [selected, setSelected] = useState(isMe ? 'FILTRAR ME/EPP' : meEpp[0]);
  const [tipo, setTipo] = useState(isMe ? meEpp : []);

  const handleSelection = (e) => {
    setSelected(e.target.value);
  }

  const handleCheck = (e) => {
    const { checked, name } = e.target;

    if (!checked || tipo.includes(name))
      setTipo(tipo.filter(el => el !== name))
    else
      setTipo([...tipo, name]);
  }

  useEffect(() => {
    if (selected === 'FILTRAR ME/EPP')
      onChangeMeEpp(tipo);
    else onChangeMeEpp([selected])
  }, [tipo, selected])

  useEffect(() => {
    const shouldBe = isMe ? 'FILTRAR ME/EPP' : meEpp[0];
    if (selected !== shouldBe) {
      setSelected(shouldBe || 'FILTRAR ME/EPP');
    }
    isMe && setTipo(meEpp)
  }, [meEpp])



  return (
    <CardFiltro>
      <CardHeard>
        <Layers />
        <CardTitulo>
          <div>FILTRO ME/EPP</div>
          <p>Selecione o tipo de tratamento</p>
        </CardTitulo>
      </CardHeard>
      <CardBody style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <FormGroup style={{ minWidth: "20%" }}>
          <FormControl
            name="filtro-me-epp"
            componentClass="select"
            onChange={handleSelection}
            defaultValue={selected}
            style={{ margin: 0 }}
          >
            <option
              key={1}
              id={1}
              value={"Todos"}
              selected={selected === "Todos"}
            >
              TODOS EDITAIS
            </option>

            <option
              key={2}
              id={2}
              value={"Excluir"}
              selected={selected === "Excluir"}
            >
              EXCLUIR ME/EPP
            </option>

            <option
              key={3}
              id={3}
              value={"FILTRAR ME/EPP"}
              selected={selected === "FILTRAR ME/EPP"}
            >
              FILTRAR ME/EPP
            </option>
          </FormControl>
        </FormGroup>

        {selected === 'FILTRAR ME/EPP' &&
          <CheckContainer>
            <input type="checkbox" name="Tipo I" id="tipo-1" onChange={handleCheck} checked={tipo.includes('Tipo I')} />
              &nbsp;
              <label htmlFor="tipo1">Tipo I</label>
              &nbsp;&nbsp;&nbsp;
              <input type="checkbox" name="Tipo II" id="tipo-2" onChange={handleCheck} checked={tipo.includes('Tipo II')} />
              &nbsp;
              <label htmlFor="tipo2">Tipo II</label>
              &nbsp;&nbsp;&nbsp;
              <input type="checkbox" name="Tipo III" id="tipo-3" onChange={handleCheck} checked={tipo.includes('Tipo III')} />
              &nbsp;
              <label htmlFor="tipo3">Tipo III</label>
          </CheckContainer>
        }
      </CardBody>
    </CardFiltro>
  )
}

export default FiltroMeEpp;
