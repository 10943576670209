import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { ModalHead } from "../Modals/ModalHead";
import { BobyModal } from "../Modals/styleModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";

const master = "/static/Imagens/cartao_master_branco.png";
const hiper = "/static/Imagens/bandeira_hiper.png";
const visa = "/static/Imagens/bandeira_visa.png";
const american = "/static/Imagens/bandeira_american.png";
const diners = "/static/Imagens/bandeira_diners.png";
const master2 = "/static/Imagens/bandeira_master.png";
const cartao = "/static/Imagens/modelo_cartao.png";
const verso_cartao = "/static/Imagens/verso_cartao.png";

@inject("FinanceiroStores")
@observer
class ModalCartao extends Component {
  constructor() {
    super();
    this.state = {
      cardName: "",
      cardNumber: "",
      validade: "",
      cvv: "",
      codCartao: false,
      cpf: "",
      submit: false,
      errorFront: "",
      cardNumberError: false,
      cpfError: false,
      cardNameError: false,
      validadeError: false,
      cvvError: false
    };
  }

  handleBlur = e => {
    const { cardName, cardNumber, validade, cvv, cpf } = this.state;
    const error = () => {
      switch (e.target.name) {
        case "cardNumber":
          return cardNumber.length < 18 ? "Verifique o número do cartao" : "";
        case "validade":
          return validade.length < 5 ? "Verifique a data de validade" : "";
        case "cardName":
          return cardName.length < 4 ? "Nome muito curto" : "";
        case "cpf":
          return cpf.length < 14 ? "CPF inválido" : "";
        case "cvv":
          return cvv.length < 3 ? "Verifique CVV" : "";
        default:
          return;
      }
    };
    if (!!error()) this.setState({ [e.target.name + "Error"]: true });
    else this.setState({ [e.target.name + "Error"]: false });
    this.setState({ errorFront: error() });
  };

  handleContratar = () => {
    let cpf = this.state.cpf.split("-").join("");
    let cartao = this.state.cardNumber.split("-").join("");
    let mes = this.state.validade.split("/")[0];
    let ano = this.state.validade.split("/")[1];
    const { id: id_combo } = this.props;
    this.props.FinanceiroStores.contratar_cartao(
      id_combo,
      cartao,
      ano,
      mes,
      this.state.cvv,
      this.state.cardName,
      cpf
    );
  };

  handleCloseModal = () => {
    this.props.close();
  };

  handleOpenModal = () => {
    // this.props.FinanceiroStores.handleOpenModal()
  };

  cleanState = () => {
    this.setState({
      cardName: "",
      cardNumber: "",
      validade: "",
      cvv: "",
      codCartao: false,
      cpf: "",
      errorFront: "",
      cardNumberError: false,
      cpfError: false,
      cardNameError: false,
      validadeError: false,
      cvvError: false
    });
  };

  handleBandeira = () => {
    let cvv = this.state.cardNumber.substr(0, 1);
    switch (cvv) {
      case "3":
        return american;
      case "4":
        return visa;
      case "5":
        return master;
      case "6":
        return hiper;
      default:
        return;
    }
  };

  handleInput = ({ target: { name, value } }) => {
    name === "cvv" ? this.activeFlip() : this.disableFlip();
    this.setState({
      [name]: value
    });
  };

  contratarDisabled = () => {
    const { cardName, cardNumber, validade, cvv, cpf } = this.state;
    const { errorFront } = this.state;

    if (!errorFront && cvv && cpf && validade && cardNumber && cardName)
      return false;
    else return true;
  };

  activeFlip = () => {
    $(".card").addClass("flipped");
  };

  disableFlip = () => {
    $(".card").removeClass("flipped");
  };

  handleBack = e => {
    e.preventDefault();
    this.cleanState();
    this.props.mudarForma();
    this.props.FinanceiroStores.limparErroCartao();
  };

  render() {
    const {
      erroCartao,
      isSubmitting,
      modalCartao,
      fecharModalCartao
    } = this.props.FinanceiroStores;
    const {
      errorFront,
      cvvError,
      cardNameError,
      cardNumberError,
      validadeError,
      cpfError
    } = this.state;

    return (
      <>
        <style type="text/css">
          {`
                        .container {

                            position: relative;
                            perspective: 1000px;
                        }
                        .card {
                            border: none;
                            width: 350px;
                            position: absolute;
                            left: 0;
                            transition: transform 1s;
                            transform-style: preserve-3d;
                        
                        }
                        .card div {
                            display: block;
                            height: 100%;
                            width: 100%;
                            backface-visibility: hidden;
                        }

                        .card .back {
                            transform: rotateY( 180deg );
                        }
                        .card.flipped {
                            transform: rotateY( 180deg );
                        }                       
                    `}
        </style>

        {/* <BtnComprar onClick={() => this.props.FinanceiroStores.handleOpenModal()}>
                    <FontAwesomeIcon icon="shopping-cart" size="lg" style={{ marginLeft: '-3px' }} />
                </BtnComprar> */}
        <Modal
          className="Modal_Load"
          overlayClassName="Overlay_Load"
          isOpen={modalCartao}
        >
          {ModalHead(fecharModalCartao, "Contratar Plano", "box")}
          <BobyModal style={{ overflow: "hidden", padding: "30px" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ width: "350px", position: "relative" }}>
                <section className="container">
                  <div className="card" onClick={() => this.activeFlip()}>
                    <div className="front">
                      <img src={cartao} alt="credit-card" width={"350px"} />
                      <div
                        style={{
                          position: "absolute",
                          marginTop: "-110px",
                          fontSize: "22px",
                          color: "white",
                          fontFamily: "Rockwell",
                          marginLeft: "10px",
                          textTransform: "uppercase",
                          letterSpacing: "4px",
                          padding: "10px"
                        }}
                      >
                        <span> {this.state.cardNumber}</span>
                        <div
                          style={{
                            fontSize: "12px",
                            letterSpacing: "1px",
                            marginBottom: "10px"
                          }}
                        >
                          {" "}
                          val.
                          {this.state.validade}
                        </div>
                        <span>
                          <div
                            style={{
                              position: "absolute",
                              left: "260px",
                              top: "60px"
                            }}
                          >
                            {" "}
                            <img
                              src={this.handleBandeira()}
                              alt="bandeira-cartao"
                              width={"60px"}
                            />
                          </div>
                          <div
                            style={{
                              paddingTop: "10px",
                              fontSize: "15px",
                              letterSpacing: "2px",
                              maxWidth: "270px"
                            }}
                          >
                            {" "}
                            {this.state.cardName}
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="back">
                      <img
                        src={verso_cartao}
                        alt="credit-card-back"
                        width={"350px"}
                      />
                      <div
                        style={{
                          position: "absolute",
                          left: "260px",
                          top: "75px"
                        }}
                      >
                        {this.state.cvv}
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  style={{
                    display: "flex",
                    width: "350px",
                    height: "30px",
                    justifyContent: "space-between",
                    position: "absolute",
                    bottom: "0.8rem"
                  }}
                >
                  <img src={visa} alt="credit-visa" />
                  <img src={master2} alt="credit-master" />
                  <img src={american} alt="credit-american" />
                  <img src={diners} alt="credit-diners" />
                  <img src={hiper} alt="credit-hiper" />
                </div>
              </div>

              <div style={{ width: "350px", marginLeft: "50px" }}>
                <InputMask
                  onClick={() => this.disableFlip()}
                  mask={"9999-9999-9999-9999"}
                  maskChar={null}
                  value={this.state.cardNumber}
                  onChange={e => this.handleInput(e)}
                  onBlur={this.handleBlur}
                >
                  {() => (
                    <TextField
                      error={cardNumberError}
                      id="outlined-full-width"
                      label="Numero do cartão"
                      style={{ margin: 8 }}
                      name={"cardNumber"}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                </InputMask>
                <InputMask
                  onClick={() => this.disableFlip()}
                  mask={"999-999-999-99"}
                  maskChar={null}
                  value={this.state.cpf}
                  onChange={e => this.handleInput(e)}
                  onBlur={this.handleBlur}
                >
                  {() => (
                    <TextField
                      error={cpfError}
                      id="outlined-full-width"
                      label="CPF Titular"
                      style={{ margin: 8 }}
                      name={"cpf"}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                    />
                  )}
                </InputMask>

                <TextField
                  onClick={() => this.disableFlip()}
                  label="Nome do cartão"
                  style={{ margin: 8 }}
                  fullWidth
                  name={"cardName"}
                  error={cardNameError}
                  value={this.state.name}
                  onChange={e => this.handleInput(e)}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={this.handleBlur}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "8px",
                    width: "100%"
                  }}
                >
                  <InputMask
                    onClick={() => this.disableFlip()}
                    mask={"99/99"}
                    maskChar={null}
                    value={this.state.validade}
                    onChange={e => this.handleInput(e)}
                    onBlur={this.handleBlur}
                  >
                    {() => (
                      <TextField
                        label="Validade"
                        style={{ marginRight: "15px" }}
                        name={"validade"}
                        error={validadeError}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    onClick={() => this.activeFlip()}
                    mask={"999"}
                    maskChar={null}
                    value={this.state.cvv}
                    onChange={e => this.handleInput(e)}
                    onBlur={this.handleBlur}
                  >
                    {() => (
                      <TextField
                        label="CVV"
                        name={"cvv"}
                        id={"cvv"}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        error={cvvError}
                        required
                      />
                    )}
                  </InputMask>
                </div>
                <div style={{ marginLeft: "8px" }}>
                  <small style={{ color: "red" }}>
                    {errorFront || erroCartao}
                  </small>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
                marginBottom: "-0.85rem"
              }}
            >
              <small
                style={{ alignSelf: "flex-end" }}
                onClick={this.handleBack}
              >
                <FontAwesomeIcon icon="arrow-left" /> Mudar forma de pagamento
              </small>

              <div style={{ marginTop: "5px", marginRight: "-0.5rem" }}>
                <Button
                  onClick={this.handleContratar}
                  disabled={this.contratarDisabled() || isSubmitting}
                  className="btn-sm btn-success"
                >
                  CONTRATAR
                </Button>
              </div>
            </div>
          </BobyModal>
        </Modal>
      </>
    );
  }
}

export default ModalCartao;
