import styled from 'styled-components';

export const BodyContainer = styled.div`
  // display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 20px);
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background: #fff;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0px 0px 8px #000000b3;
  overflow: auto;
  opacity: 0;
  animation: 600ms fadeIn ease-in forwards 300ms;

  div.filters { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation: 600ms fadeIn ease-in forwards;
    overflow: auto;
    padding-bottom: 10px;

    div.calendar {
      padding: 0 50px;
      .title {
        font-style: italic;
        font-weight: 500;
        text-align: center; 
        margin-bottom: 20px;
        color: #3b576f;
        margin-top: 20px;
      }
      .inputs {
        display: flex;
        justify-content: space-between;
        margin: 15px 0px; 
      }
    }

    .inputs-pesquisa {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      padding: 0 50px;

      div {
        margin-bottom: 20px;
      }

      .react-tagsinput2 > span:first-child {
        /* margin-left: 40px; */
      }
    }

     
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  
  }
  .react-tagsinput2 {
    margin-left: 40px;
    margin-bottom: 0 !important;
  }
  .react-tagsinput-input {
    padding: 0;
    font-family: inherit;
    font-size: 16px;
    padding: 2px !important;
    margin-bottom: 0;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3a566e;
      opacity: 52%;
      font-weight: 300;
    }
  }

  .react-tagsinput-tag {
    box-shadow: 1px 1px 6px #0000008f;
    border: none;
    color: #fff;
    background: #3a566e;
    margin-bottom: 0;
  }
  
  div.action-buttons {
    display: flex;

  }

  .action-buttons {
    display: flex;
    /* border-radius: 15px; */
    width: 50%;
    margin-top: 70px;
    margin-bottom: 40px;
    animation: 600ms fadeIn ease-in forwards;

    button {
      border:none;
      background: none;
      width: 50%;
      padding: 10px;
      font-weight: 500;
    }

    #filtrar-editais {
      background: green;
      color: #fff;
      border-right: 1px solid #828282;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    #fechar-modal-filtro-editais {
      background: #c5bfbf;
      color: black;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  } 
`;

export const FilterButton = styled.div`
  height: 100%;
  padding: 0 15px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${props => props.open ? '#d2dbde78' : 'transparent'};
  transition: background 300ms;

  svg {
    margin-right: 5px;
  }

`;

export const CodLicitacaoBadge = styled.div`
    width: auto;
    height: 13px;
    background: #bfbfbf1c;
    position: absolute;
    font-size: 78%;
    font-weight: 600;
    text-align: right;
    padding: 0 3px;
    color: #777777;
    top: 0px;
    left: 0px;
    min-width: 44px;
    box-shadow: 1px 1px 2px #0000003d;
    transition: 500ms;
    cursor: pointer;

    :hover { 
      background: #568240;
      color: #ffffff;
    }
`;