import React, { useEffect, useState } from 'react'
import { ContainerInput, WrapperInput } from './styles'

const textoParticipar =
  'Senhor fornecedor, assinale SIM para a declaração abaixo, caso seja Microempresa, Empresa de Pequeno Porte ou Cooperativa e deseje usufruir do tratamento estabelecido nos artigos 42 ao 49 da Lei complementar 123 de 14 de dezembro de 2006 para esse pregão eletrônico, caso contrário assinale NÃO.\n' +
  ' \n' +
  'Declaro, sob as penas da Lei, que não ultrapassei o limite de faturamento e cumpro os requisitos estabelecidos no Art. 3º da Lei Complementar nº 123, de 14 de dezembro de 2006, sendo apto a usufruir do tratamento favorecido estabelecido nos artigos 42 ao 49 da referida Lei Complementa'
const textoCiente =
  'Declaro que estou ciente e concordo com as condições contidas no edital e seus anexos, bem como de que cumpro plenamente os requisitos de habilitação definidos no edital'
const textoSuperveniente =
  'Declaro sob as penas da lei, que até a presente data inexistem fatos impeditivos para a minha habilitação no presente processo licitatório, ciente da obrigatoriedade de declarar ocorrências posteriores.'
const textoInfantil =
  'Declaro para fins do disposto no inciso V do art. 27 da Lei nº 8.666, de 21 de junho de 1993, acrescido pela Lei nº 9.854, de 27 de outubro de 1999, que não emprego menor de 18 (dezoito) anos em trabalho noturno, perigoso ou insalubre e não emprego menor de 16 (dezesseis) anos, salvo menor, a partir de 14 (quatorze) anos, na condição de aprendiz, nos termos do inciso XXXIII, do art. 7º da Constituição Federal.'
const textoProp_independente =
  'Declaro que a proposta apresentada para essa licitação foi elaborada de maneira independente, de acordo com o que é estabelecido na Instrução Normativa Nº 2 de 16 de setembro de 2009 da SLTI/MP.\n' +
  'Clique aqui para detalhamento dessa declaração.'
const textoTrab_forcado =
  'Declaro que não possuo, em minha cadeia produtiva, empregados executando trabalho degradante ou forçado, observando o disposto nos incisos III e IV do art.1º e no inciso III do art.5º da Constituição Federal.'
const textoAcessibilidade =
  'Declaro que, conforme disposto no art. 93 da Lei nº 8.213, de 24 de julho de 1991, estou ciente do cumprimento da reserva de cargos prevista em lei para pessoa com deficiência ou para reabilitado da Previdência Social e que, se aplicado ao número de funcionários da minha empresa, atendo às regras de acessibilidade previstas na legislação.'
const textoCota_aprendizagem =
  'Declaro sob as penas da Lei, que cumpro a cota de aprendizagem nos termos estabelecidos no art. 429 da CLT.\t'

const Declaracao = ({ props }) => {
  const [participar, setParticipar] = useState(true)
  const [ciente, setCiente] = useState(true)
  const [superveniente, setSuperveniente] = useState(true)
  const [infantil, setInfantil] = useState(true)
  const [trab_forcado, setTrabForcado] = useState(true)
  const [acessibilidade, setAcessibilidade] = useState(true)
  const [cota_aprendizagem, setAprendizagem] = useState(true)
  const [prop_independente, setIndependente] = useState(true)

  useEffect(() => {
    props.getDeclaracao()
  }, [])

  useEffect(() => {
    const {
      ciente,
      prop_independente,
      trab_forcado,
      acessibilidade,
      cota_aprendizagem,
      infantil,
      participar,
      superveniente
    } = props.declaracao
    setParticipar(participar)
    setCiente(ciente)
    setSuperveniente(superveniente)
    setInfantil(infantil)
    setTrabForcado(trab_forcado)
    setAcessibilidade(acessibilidade)
    setAprendizagem(cota_aprendizagem)
    setIndependente(prop_independente)
  }, [props.declaracao])

  const salvarDescricao = () => {
    const dados = {
      fornecedor: props.declaracao.fornecedor,
      ciente,
      prop_independente,
      trab_forcado,
      acessibilidade,
      cota_aprendizagem,
      infantil,
      participar,
      superveniente
    }
    props.salvarDeclaracao(dados)
  }

  return (
    <div className="modal-body">
      <div className="section-header">
        <div className="title">
          <div className="main-title">DECLARAÇÃO DA PROPOSTA</div>
          <div className="sub-title">
            Os itens, cujo campo de proposta estiver em branco, não serão
            cadastrados, podendo ser encaiminhado posteriormente
          </div>
        </div>
      </div>

      <ContainerInput>
        <div className={'texto'}>{textoParticipar}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={participar === true}
              value={participar}
              onClick={() => setParticipar(true)}
            />
            <label htmlFor={participar}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={participar === false}
              value={participar}
              onClick={() => setParticipar(false)}
            />
            <label htmlFor={participar}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoCiente}</div>
        <WrapperInput>
          <div>
            <input type="radio" checked={true} value={ciente} disabled />
            <label htmlFor={ciente}>SIM</label>
          </div>
          <div>
            <input type="radio" checked={false} value={false} disabled />
            <label htmlFor={ciente}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoSuperveniente}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={superveniente === true}
              value={superveniente}
              onClick={() => setSuperveniente(true)}
            />
            <label htmlFor={superveniente}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={superveniente === false}
              value={superveniente}
              onClick={() => setSuperveniente(false)}
            />
            <label htmlFor={superveniente}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoInfantil}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={infantil === true}
              value={infantil}
              onClick={() => setInfantil(true)}
            />
            <label htmlFor={textoInfantil}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={infantil === false}
              value={infantil}
              onClick={() => setInfantil(false)}
            />
            <label htmlFor={textoInfantil}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoTrab_forcado}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={trab_forcado === true}
              value={trab_forcado}
              onClick={() => setTrabForcado(true)}
            />
            <label htmlFor={textoTrab_forcado}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              label={textoTrab_forcado}
              checked={trab_forcado === false}
              value={trab_forcado}
              onClick={() => setTrabForcado(false)}
            />
            <label htmlFor={textoTrab_forcado}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoAcessibilidade}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={acessibilidade === true}
              value={acessibilidade}
              onClick={() => setAcessibilidade(true)}
            />
            <label htmlFor={textoAcessibilidade}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={acessibilidade === false}
              value={acessibilidade}
              onClick={() => setAcessibilidade(false)}
            />
            <label htmlFor={textoAcessibilidade}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoCota_aprendizagem}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={cota_aprendizagem === true}
              value={cota_aprendizagem}
              onClick={() => setAprendizagem(true)}
            />
            <label htmlFor={textoCota_aprendizagem}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={cota_aprendizagem === false}
              value={cota_aprendizagem}
              onClick={() => setAprendizagem(false)}
            />
            <label htmlFor={textoCota_aprendizagem}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <ContainerInput>
        <div className={'texto'}>{textoProp_independente}</div>
        <WrapperInput>
          <div>
            <input
              type="radio"
              checked={prop_independente === true}
              value={prop_independente}
              onClick={() => setIndependente(true)}
            />
            <label htmlFor={textoProp_independente}>SIM</label>
          </div>
          <div>
            <input
              type="radio"
              checked={prop_independente === false}
              value={prop_independente}
              onClick={() => setIndependente(false)}
            />
            <label htmlFor={textoProp_independente}>NÃO</label>
          </div>
        </WrapperInput>
      </ContainerInput>

      <div className="botoes-container">
        <button
          id="salvar-configuracoes"
          type="submit"
          onClick={() => salvarDescricao()}
        >
          SALVAR
        </button>
        <button id="limpar-configuracoes">VOLTAR</button>
      </div>
    </div>
  )
}

export default Declaracao
