import React from "react";
import { inject, observer } from "mobx-react";
import ReactDOM from "react-dom";

import Navegacao from "./Navegacao";
import Detalhes from "./Detalhes";
import ModalQualificar from "./modalConfirmacao/ModalQualificar";
import ModalReprovar from "./modalConfirmacao/ModalReprovar";
import ModalAprovar from "./modalConfirmacao/ModalAprovar";

import * as S from "./styles";

const ModalDetalhes = inject("DetalhesEditalStore")(
  observer(({ DetalhesEditalStore }) => {
    const { id } = DetalhesEditalStore;

    if (!id) return null;
    return <ModalDetalhesComStore />;
  })
);

const ModalDetalhesComStore = () => {
  return ReactDOM.createPortal(
    <S.Container>
      <Navegacao />
      <Detalhes />
      <ModalQualificar />
      <ModalReprovar />
      <ModalAprovar />
    </S.Container>,
    document.querySelector("body")
  );
};

export default ModalDetalhes;
