import React from "react";
import {MessageSquare} from "react-feather";
import {CardMD, CardMDBarra, CardMDBody, CardMDHearder, Titulo} from "../styleDashboard";
import {BadgeMsg, CardBarra, CardMsg} from "./styleMensagens";

export const StatusMsg = ({data}) => {
  const {msg_recebidas, msg_lidas, msg_ocorrencias} = data;
  return (
    <CardMD color={'#0D2466'}>

      <CardMDHearder>
        <div style={{marginRight: '15px'}}><MessageSquare size={'35px'}/></div>
        <Titulo>
          <h5>STATUS MENSAGENS</h5>
          <span>Mensagens recebidas hoje</span>
        </Titulo>
      </CardMDHearder>
      <CardMDBarra/>
      <CardMDBody>

        <CardMsg background={'#399300'}>
          <BadgeMsg background={'#399300'}>
            <b>{msg_recebidas}</b>
          </BadgeMsg>
          <span>Mensagens</span>
          <div>RECEBIDAS</div>
        </CardMsg>


        <CardBarra/>
        <CardMsg width={'37%'} background={'#c0263b'}>
          <BadgeMsg background={'#c0263b'}><b>{msg_ocorrencias}</b></BadgeMsg>
          <span>Mensagens com</span>
          <div>OCORRÊNCIAS</div>
        </CardMsg>

        <CardBarra/>

        <CardMsg>
          <BadgeMsg background={'#959595'}><b>{msg_lidas}</b></BadgeMsg>
          <span>Mensagens</span>
          <div>LIDAS</div>
        </CardMsg>

      </CardMDBody>
    </CardMD>
  )
};
