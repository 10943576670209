import { CardPainel, CardPainelTitle, ContaneirPainel } from "./styles";
import { Clock } from "react-feather";
import { SituacaoLicita } from "../../globalStyles";
import React from "react";
import { getHour } from "../../Stores/Funcoes/FuncoesGerais.js";

export const CardPainelLicitacoes = ({ licitacoes, fontSize }) => {
  const licitacoesOrdenadas = licitacoes
    .slice()
    .sort((a, b) => new Date(b.data_disputa) - new Date(a.data_disputa))
    .reverse();

  return (
    <div className="scroll-black" style={{ overflow: "auto", height: "77vh" }}>
      {licitacoesOrdenadas.map(
        ({
          codigo,
          portal,
          nome_empresa,
          updated_at,
          data_disputa,
          comprador: { nome, codigo: uasg },
          situacao: { descricao, cor }
        }) => {
          return (
            <ContaneirPainel>
              <CardPainel width={"17%"} fontSize={fontSize}>
                <CardPainelTitle>Empresa</CardPainelTitle>
                <div style={{ margin: 'auto', fontWeight: 500 }}>{nome_empresa}</div>
              </CardPainel>

              <CardPainel width={"37%"} fontSize={fontSize}>
                <CardPainelTitle>Comprador</CardPainelTitle>
                <div style={{ margin: 'auto', fontSize: '80%' }}>{nome}</div>
              </CardPainel>

              <CardPainel width={"10%"} fontSize={fontSize}>
                <CardPainelTitle>Portal</CardPainelTitle>
                <div style={{ margin: 'auto' }}>{portal}</div>
              </CardPainel>

              <CardPainel
                width={"10%"}
                textAlign={"center"}
                fontSize={fontSize}
              >
                <CardPainelTitle>Licitação</CardPainelTitle>
                <div style={{ margin: 'auto' }} className="uasg-licitacao">
                  <b>{codigo}</b>
                  {uasg &&
                    <span>UASG: {uasg}</span>
                  }
                </div>
              </CardPainel>

              <CardPainel
                width={"10%"}
                textAlign={"center"}
                fontSize={fontSize}
              >
                <CardPainelTitle>Disputa</CardPainelTitle>
                <div className="d-flex align-items-center justify-content-center m-auto">
                  <Clock size={"16px"} style={{ marginRight: "6px" }} />
                  <b>{getHour(data_disputa)}</b>
                </div>
              </CardPainel>

              <CardPainel
                width={"17%"}
                textAlign={"center"}
                fontSize={fontSize}
              >
                <CardPainelTitle style={{ marginBottom: 0 }}>
                  Atualizado
                </CardPainelTitle>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ fontSize: "14px" }}
                >
                  <b>{updated_at}</b>
                </div>
                <SituacaoLicita
                  background={cor}
                  fontSize={"70%"}
                  style={{ color: "white" }}
                  data-tip={descricao}
                  data-type="dark"
                  data-place="bottom"
                  data-effect="solid"
                >
                  {descricao}
                </SituacaoLicita>
              </CardPainel>
            </ContaneirPainel>
          );
        }
      )}
    </div>
  );
};
