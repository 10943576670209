import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaquinaDeEstadosStore from '../../Stores/MaquinaDeEstadosStore'
import { ReactTooltip } from '../../globalStyles'
import SelecinarLicitacoesParaMonitorar from './SelecinarLicitacoesParaMonitorar'
import PortalSelect from './SelecaoPortal/PortalSelect'
import {
  ContainerMenu,
  ContainerMenuAcoes,
  SizeLicitacoes,
  SecondaryMenuLeftContainer,
  PortaisSelectContainer,
  NavegacaoContainer,
  SingleNavigationItem
} from './styleLicitacoes'
import { SelectInput, TextInput } from '../../UsefulComponents/Inputs'
import { Portais } from './BotoesMenu'
import { PortaisSelector } from '../../Components/Sistema/PortaisSelector/PortaisSelector'
export const MenuLicitacoes = ({
  totalAmountOfBids,
  licitacaoMonitorada,
  tipo_licitacao,
  licitacaoHandleChange,
  portalHandleChange,
  portal,
  licitacoesFilter,
  Search,
  onClick,
  fim_progresso_carregano_lic
}) => {
  return (
    <ContainerMenu>
      <MenuFiltroSituacao
        tipo_licitacao={tipo_licitacao}
        licitacaoHandleChange={licitacaoHandleChange}
        portal={portal}
        portalHandleChange={portalHandleChange}
      />

      <PesquisaLicitacoes onClick={onClick} Search={Search} />

      <MenuAcoes
        totalAmountOfBids={totalAmountOfBids}
        licitacoesFilter={licitacoesFilter}
        fim_progresso_carregano_lic={fim_progresso_carregano_lic}
        licitacaoMonitorada={licitacaoMonitorada}
      />
    </ContainerMenu>
  )
}

export const MenuFiltroSituacao = ({
  tipo_licitacao,
  portal,
  licitacaoHandleChange,
  portalHandleChange
}) => {
  return (
    <SecondaryMenuLeftContainer
      height="8vh"
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 0,
        gap: '1rem'
      }}
    >
      <ReactTooltip />
      <SelectInput
        id="licitacao-select-type"
        onChange={licitacaoHandleChange}
        value={tipo_licitacao}
        width="200px"
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0615 0H0.938588C0.105855 0 -0.31434 1.01039 0.275699 1.60043L7.49999 8.82582V16.875C7.49999 17.1809 7.64924 17.4676 7.89987 17.643L11.0249 19.8298C11.6414 20.2613 12.5 19.8239 12.5 19.0617V8.82582L19.7245 1.60043C20.3133 1.01156 19.896 0 19.0615 0Z"
              fill="#3A566E"
            />
          </svg>
        }
      >
        <option value="todas">TODAS LICITAÇÕES</option>
        <option value="agendados">AGENDADOS</option>
        <option value="andamento">ANDAMENTO</option>
        <option value="suspenso">SUSPENSO</option>
        <option value="realizadas">REALIZADAS</option>
        <option value="cancelados">CANCELADOS</option>
        <option value="ata">ATA HOMOLOGADO</option>
      </SelectInput>
      <PortaisSelector value={portal} onChange={portalHandleChange} />
    </SecondaryMenuLeftContainer>
  )
}

const checkPathname = path => path === window.location.pathname

export const MenuAcoes = ({ totalAmountOfBids }) => {
  return (
    <>
      <NavegacaoContainer>
        <SingleNavigationItem active={checkPathname('/agenda')}>
          <Link to="/agenda" title="AGENDA">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="calendar-check"
              data-tip="AGENDA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/funil')}>
          <Link to="/funil" title="FUNIL">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="filter"
              data-tip="FUNIL"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/licitacoes')}>
          <Link to="/licitacoes" title="LIST">
            {/* <ReactTooltip /> */}
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="list"
              data-tip="LISTA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/licitacoes/excluir')}>
          <Link to="/licitacoes/excluir">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="trash"
              data-tip="LIXEIRA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
      </NavegacaoContainer>
      <ContainerMenuAcoes>
        <SizeLicitacoes>
          <span className="contador-numero">{totalAmountOfBids}</span>
          <span className="contador-mensagem">Licitações</span>
        </SizeLicitacoes>
      </ContainerMenuAcoes>
    </>
  )
}

export const PesquisaLicitacoes = ({ Search, onClick }) => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '1',
        right: 'calc(50% - 150px)',
        bottom: '-10px'
      }}
    >
      <TextInput
        width="300px"
        id="pesquisa-licitacao"
        placeholder="Pesquisa por UASG ou código"
        onChange={e => Search(e.target.value)}
        icon={
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2939 12.5786H13.3848L13.0703 12.2642C14.191 10.9663 14.8656 9.27959 14.8656 7.43282C14.8656 3.32762 11.538 0 7.43282 0C3.32762 0 0 3.32762 0 7.43282C0 11.538 3.32762 14.8656 7.43282 14.8656C9.27959 14.8656 10.9663 14.191 12.2642 13.076L12.5786 13.3905V14.2939L18.2962 20L20 18.2962L14.2939 12.5786ZM7.43282 12.5786C4.5912 12.5786 2.28702 10.2744 2.28702 7.43282C2.28702 4.5912 4.5912 2.28702 7.43282 2.28702C10.2744 2.28702 12.5786 4.5912 12.5786 7.43282C12.5786 10.2744 10.2744 12.5786 7.43282 12.5786Z"
                fill="#3A566E"
              />
            </svg>
          </div>
        }
      />
    </div>
  )
}
