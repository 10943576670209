import MaquinaDeEstadosStore from './MaquinaDeEstadosStore'
import ConfiguracoesMonitoramentoStores from './ConfiguracoesMonitoramentoStores'
// import ConfiguracoesGeraisStores from "./ConfiguracoesGeraisStores";
import StoreConfiguracoesGerais from './StoreConfiguracoesGerais'
// import ConfiguracoesBuscaEditaisStores from "./ConfiguracoesBuscaEditaisStores";
import EditaisStores from './EditaisStores'
import MonitoramentoStore from './MonitoramentoStore'
import LicitacoesPaginadaStore from './LicitacoesPaginadaStore'
import OcorrenciasDoDiaStores from './OcorrenciasDoDiaStores'
import PainelAvisoStores from './PainelAvisoStores'
import BuscaDeEditaisStores from './BuscaDeEditaisStores'
import DashboardStores from './DashboardStores'

import BoletimStore from '../Pages/Prospection/DailyBulletin/Store'
import ImprimirPropostaStore from '../Pages/Proposal/ImprimirProposta/store'

export const DASHBOARD = '/dashboard'

export const BOLETIM_DIARIO = '/prospeccao/boletim'

export const EDITAIS = '/editais'
export const BUSCA_DE_EDITAIS = '/buscadeeditais'
export const CONFIGURAR_PROPOSTA = '/proposta/configuracoes'

export const LICITACOES = '/licitacoes'
export const LICITACAO = '/licitacao'
export const FUNIL = '/funil'
export const AGENDA = '/agenda'

export const PAINEL_MONITORAMENTO = '/painel_monitoramento'
export const MONITORAMENTO = '/monitoramento'

export const OCORRENCIAS_DO_DIA = '/ocorrenciasdodia'

export const CONF_BUSCA_DE_EDITAIS = '/configuracoesbuscadeeditais'
export const CONF_MONITORAMENTO = '/configuracoesmonitoramento'
export const CONF_GERAL = '/configuracaogeral'

export const FINANCAS = '/financas'

export const CADASTRO = '/cadastro'
export const RECUPERAR_SENHA = '/recuperar_senha'

//COMPONENETE NECESSÁRIO POR ULTILIZARMOS O MOBX; --'

export const AtualizaDadosEmpresa = async () => {
  switch (window.location.pathname) {
    case BOLETIM_DIARIO:
      BoletimStore.trocouDeEmpresa()
      break
    case CONFIGURAR_PROPOSTA:
      ImprimirPropostaStore.trocouDeEmpresa()
      break
    case DASHBOARD:
      DashboardStores.trocouDeEmpresa()
      break
    case EDITAIS:
      EditaisStores.trocouDeEmpresa()
      break
    case BUSCA_DE_EDITAIS:
      BuscaDeEditaisStores.trocouDeEmpresa()
      break
    case LICITACOES:
      LicitacoesPaginadaStore.trocouDeEmpresa()
      break
    case LICITACAO:
      MaquinaDeEstadosStore._props.history.push('/licitacoes')
      LicitacoesPaginadaStore.trocouDeEmpresa()
      break
    case FUNIL:
      MaquinaDeEstadosStore._props.history.push('/licitacoes')
      LicitacoesPaginadaStore.trocouDeEmpresa()
      break
    case AGENDA:
      MaquinaDeEstadosStore._props.history.push('/licitacoes')
      LicitacoesPaginadaStore.trocouDeEmpresa()
      break
    case PAINEL_MONITORAMENTO:
      PainelAvisoStores.trocouDeEmpresa()
      break
    case MONITORAMENTO:
      MonitoramentoStore.trocouDeEmpresa()
      break
    case OCORRENCIAS_DO_DIA:
      OcorrenciasDoDiaStores.trocouDeEmpresa()
      break
    // case CONF_BUSCA_DE_EDITAIS:
    //   ConfiguracoesBuscaEditaisStores.trocouDeEmpresa();
    //   break;
    case CONF_MONITORAMENTO:
      ConfiguracoesMonitoramentoStores.trocouDeEmpresa()
      break
    case CONF_GERAL:
      StoreConfiguracoesGerais.trocouDeEmpresa()
      break
    default:
      return
  }

  // await ConfiguracoesBuscaEditaisStores.consulta_api();
  // await ConfiguracoesMonitoramentoStores.consulta_api();
  // await ConfiguracoesGeraisStores.consulta_api();
  // await MaquinaDeEstadosStore.redireciona_pagina_atual();
}
