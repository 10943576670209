import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import FormLogin from "./FormLogin";
import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import ModalStatusConexao from "../../StatusConexao/ModalStatusConexao";
import ListaDeUsuariosSuporte from "./ListaDeUsuariosSuporte";
import {TOKEN_KEY} from "../../Services/auth";

@inject("LoginStores")
@observer
class Login extends Component {

  
  componentDidMount() {
    MaquinaDeEstadosStore._props = this.props;
    if (localStorage.getItem(TOKEN_KEY)) this.props.LoginStores.redireciona_editais(MaquinaDeEstadosStore._props)
  }

  render() {
    const { listaUsuariosAtiva, listaUsuarios } = this.props.LoginStores;

    return (
      <>
        <FormLogin {...this.props} />
        <ModalStatusConexao />
        {listaUsuariosAtiva && <ListaDeUsuariosSuporte listaDeUsuarios={listaUsuarios} />}
      </>
    );
  }
}

export default Login;
