import { observable, action } from "mobx";

class StoreFiltroModalidades {
  // FILTROS MODALIDADE
  @observable modalFiltrosModalidade = false;

  @action toggleModalFiltrosModalidade = () =>
    (this.modalFiltrosModalidade = !this.modalFiltrosModalidade);
}

const storeFiltroModalidades = new StoreFiltroModalidades();
export default storeFiltroModalidades;