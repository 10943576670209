import React, { useContext } from 'react'
import { ThumbsDown, ThumbsUp } from 'react-feather'

import BadgeRedondo from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'

const ButtonsStatus = ({ edital, status }) => {
  const { setEdital, statusDirectEdict } = useStatusEdital()
  const { openModalQualificar, openModalReprovar } = useContext(ModalContext)

  const showModalQualify = () => {
    setEdital(edital)
    openModalQualificar()
  }

  const showModalFail = () => {
    setEdital(edital)
    openModalReprovar()
  }

  const qualify = () => {
    statusDirectEdict(edital, 'qualificado', edital)
  }

  const fail = () => {
    statusDirectEdict(edital, 'reprovado', edital)
  }

  return (
    <>
      <BadgeRedondo
        icon={<ThumbsUp />}
        background={edital.status === 'qualificado' ? '#58c340' : '#b9dbb6'}
        onClick={
          status.qualificado && !status.qualificado[1] && !status.qualificado[2]
            ? qualify
            : showModalQualify
        }
      />

      <BadgeRedondo
        icon={<ThumbsDown />}
        background={
          status.reprovado && edital.status === 'reprovado'
            ? '#ec4242'
            : '#ecc5ae'
        }
        onClick={status.reprovado && status.reprovado[0] ? showModalFail : fail}
      />
    </>
  )
}

export default ButtonsStatus
