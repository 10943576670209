import React, { useReducer } from 'react';


const initialState = {
  licitacoes: [],
  status: 'todos',
  pagina: 1,
  quantidade: {
    total_registros: 0,
    aprovado: 0,
    qualificado: 0,
    boletim: 0,
    todos: 0
  }
};

const actions = {
  MUDAR_LICITACOES: 'MUDAR_LICITACOES',
  MUDAR_STATUS: 'MUDAR_STATUS',
  MUDAR_PAGINA: 'MUDAR_PAGINA',
  MUDAR_QUANTIDADE: 'MUDAR_QUANTIDADE'
}

function reducer(state, action) {
  switch (action.type) {
    case actions.MUDAR_LICITACOES:
      return { ...state, licitacoes: action.payload };
    case actions.MUDAR_STATUS:
      return { ...state, status: action.payload };
    case actions.MUDAR_PAGINA:
      return { ...state, pagina: action.payload };
    case actions.MUDAR_QUANTIDADE:
      return { ...state, quantidade: action.payload };

    default:
      return state;
  }
}

const ListaDeLicitacoesContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    mudarLicitacoes: (licitacoes) => dispatch({ type: actions.MUDAR_LICITACOES, payload: licitacoes }),
    mudarStatus: (status) => dispatch({ type: actions.MUDAR_STATUS, payload: status }),
    mudarPagina: (e, pagina) => dispatch({ type: actions.MUDAR_PAGINA, payload: pagina }),
    mudarQuantidade: quantidade => dispatch({ type: actions.MUDAR_QUANTIDADE, payload: quantidade })
  }

  return (
    <ListaDeLicitacoesContext.Provider value={value}>
      {children}
    </ListaDeLicitacoesContext.Provider>
  )
};

const ListaDeLicitacoesProvider = Provider;

export { ListaDeLicitacoesContext }
export default ListaDeLicitacoesProvider;