import { action, observable } from "mobx";
import Api from "./Conexao/conexao";

class CompradoresStore {
  @observable unused = false;
  @observable isFetching = false;
  @observable selectedPortal = "";

  @observable listaDeCompradores = [];
  @observable listaAtual = [];

  @observable listaDeFiltrados = [];
  @observable listaDeExcluidos = [];

  @observable responseFiltrados = [];
  @observable responseExcluidos = [];

  @action changePortal = async ({ target: { value } }) => {
    const { listaDeCompradores } = this;
    this.selectedPortal = value;
    const compradoresCarregados = listaDeCompradores
      .map(co => co.portal)
      .includes(value);
    if (!compradoresCarregados) await this.getCompradores(value);
    this.listaAtual = this.listaDeCompradores.find(
      li => li.portal === value
    ).compradores;
  };

  @action getCompradores = async portalId => {
    this.isFetching = true;
    try {
      const res = await Api.get(`/portal/comprador/?portal=${portalId}`);
      this.listaDeCompradores = [...this.listaDeCompradores, res.data];
    } catch (err) {
    } finally {
      this.isFetching = false;
    }
  };

  @action switchIncludeExclude = exclusao => {
    if (exclusao) {
      this.listaDeFiltrados = [
        ...this.listaDeFiltrados,
        ...this.listaDeExcluidos.filter(el => el.portal === this.selectedPortal)
      ];
      this.listaDeExcluidos = this.listaDeExcluidos.filter(
        el => el.portal !== this.selectedPortal
      );
    } else {
      this.listaDeExcluidos = [
        ...this.listaDeExcluidos,
        ...this.listaDeFiltrados.filter(el => el.portal === this.selectedPortal)
      ];
      this.listaDeFiltrados = this.listaDeFiltrados.filter(
        el => el.portal !== this.selectedPortal
      );
    }
    // Alteração necessária para que o usuário possa modificar entre excluir/incluir mesmo sem nenhum comprador na lista.
    if (
      !this.listaDeFiltrados.filter(li => li.portal === this.selectedPortal)
        .length &&
      !this.listaDeExcluidos.filter(li => li.portal === this.selectedPortal)
        .length
    )
      this.unused = !this.unused;
  };

  @action filtrarCompradores = (selected, exclusao) => {
    // Array dos selecionados
    let lista = exclusao ? this.listaDeExcluidos : this.listaDeFiltrados;
    const remover = selected.every(el =>
      lista.map(ele => String(ele.id)).includes(el)
    );
    const listaAtualFiltrada = this.listaAtual
      .filter(el => selected.includes(String(el.id)))
      .map(el => ({ ...el, portal: this.selectedPortal }));

    if (remover) {
      if (exclusao) {
        this.listaDeExcluidos = lista.filter(
          el => !selected.includes(String(el.id))
        );
      } else {
        this.listaDeFiltrados = lista.filter(
          el => !selected.includes(String(el.id))
        );
      }
    } else {
      if (exclusao) {
        this.listaDeExcluidos = [
          ...this.listaDeExcluidos,
          ...listaAtualFiltrada
        ];
      } else {
        this.listaDeFiltrados = [
          ...this.listaDeFiltrados,
          ...listaAtualFiltrada
        ];
      }
    }
  };

  @action salvarCompradores = () => {
    this.responseExcluidos = this.listaDeExcluidos;
    this.responseFiltrados = this.listaDeFiltrados;
  };

  @action limparVariaveis = () => {
    this.unused = false;
    this.isFetching = false;
    this.selectedPortal = "";

    this.listaDeCompradores = [];
    this.listaAtual = [];

    this.listaDeFiltrados = [];
    this.listaDeExcluidos = [];

    this.responseFiltrados = [];
    this.responseExcluidos = [];
  };
}

const compradoresStore = new CompradoresStore();
export default compradoresStore;
