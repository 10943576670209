import React, { Component } from "react";
import {
  Grid,
  Col,
  Panel,
  Row,
  Button,
  ListGroup,
  ListGroupItem
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.es";
import { Link } from "react-router-dom";
import $ from "jquery";
import { inject, observer } from "mobx-react";

@inject("ModalNotificacoesStores")
@observer
class Notify extends Component {
  componentDidMount() {
    $("#notify-app").click(function(event) {
      if (event.target.id === "data-fechar") {
        $("#notify-app").addClass("h-notify");
      }
    });
  }

  render() {
    const { ModalNotificacoesStores } = this.props;
    return (
      <Col
        md={12}
        id="notify-app"
        className="notify-app animated fadeInDownBig h-notify "
      >
        <style type="text/css">
          {`
                                    .notify-app {
                                        position: absolute;
                                        right: 210px;
                                        top: 65px;
                                        z-index: 9999999; 
                                        width: 30%;
                                    }
                                    .h-notify {
                                        display: none;
                                    }
                                    .panel-s {
                                        -webkit-box-shadow: 0px 1px 10px 0px rgba(207,207,207,1);
                                        -moz-box-shadow: 0px 1px 10px 0px rgba(207,207,207,1);
                                        box-shadow: 0px 1px 10px 0px rgba(207,207,207,1);
                                        border: 1px solid silver;
                                
                                    }
                                    .list-group-item-heading{font-size: 15px;margin-top: -5px}
                                    .list-group-item-text{font-size: 15px;margin-bottom: -10px}
                                `}
        </style>
        <Panel className="custom-panel panel-s">
          <Panel.Heading>
            <Panel.Title>
              <Row>
                <Col md={8}>
                  <h6
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                  >
                    <FontAwesomeIcon icon="bell" /> NOTIFICAÇÕES
                  </h6>
                </Col>
                <Col md={2} style={{ paddingTop: "5px" }}>
                  <Link to="/notificacoes" title="notificacoes">
                    <Button
                      bsClass="btn btn-sm btn-outline"
                      bsStyle="primary"
                      className=""
                    >
                      VER TODAS NOTIFICACOES
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <hr style={{ marginTop: "5px" }} />
            <Grid fluid={true} style={{ height: "100%" }}>
              <Row>
                <Col
                  md={12}
                  style={{ marginBottom: "10px", marginLeft: "5px" }}
                >
                  <ListGroup>
                    {ModalNotificacoesStores.notificacoes
                      .slice(0, 10)
                      .map((item, i) => {
                        return (
                          <ListGroupItem
                            className=""
                            bsStyle="info"
                            key={i}
                            header={ModalNotificacoesStores.formatandoData(
                              item
                            )}
                          >
                            {item.verb}
                          </ListGroupItem>
                        );
                      })}
                  </ListGroup>
                </Col>
              </Row>
            </Grid>
          </Panel.Body>
        </Panel>
        <span
          id="data-fechar"
          style={{
            marginLeft: "527px",
            marginTop: "-25px",
            position: "absolute"
          }}
          className="badge badge-pill h3 badge-danger "
        >
          X
        </span>
      </Col>
    );
  }
}

export default Notify;
