import React from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { User } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const FiltroComprador = () => {
  const { onChangeComprador, state } = useFiltroEdital()

  return (
    <CardFiltroSmall>
      <CardHeard>
        <User />
        <CardTitulo>
          <div>COMPRADOR</div>
          <p>Filtrar por nome ou código do comprador</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <TextField
          id="standard-multiline-flexible"
          label="Nome ou código do comprador"
          variant="outlined"
          required
          margin="normal"
          // placeholder={'Nome ou código do comprador'}
          value={state.comprador}
          fullWidth
          onChange={(e) => onChangeComprador(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <User />
              </InputAdornment>
            ),
          }}
        >
        </TextField>

      </CardBody>
    </CardFiltroSmall>
  )
}

export default FiltroComprador