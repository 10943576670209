import { CONTRATO } from "./contratos";

export const TOKEN_KEY = "token";
export const USER = "user";
export const CNPJ = "cnpj";
export const ID_EMPRESA = "id_fornecedor";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER);
  localStorage.removeItem(CONTRATO);
};

export const setUser = ({
  user_id,
  fornecedor_id,
  cnpj,
  is_superuser,
  is_staff
}) => {
  let defaultUser = {
    user: { id: user_id, is_staff: is_staff, super: is_superuser },
    empresa: { id: fornecedor_id, cnpj: cnpj }
  };
  localStorage.setItem(USER, JSON.stringify(defaultUser));
};

export const setUserUpdateEmpresaID = fornecedor_id => {
  const data = JSON.parse(localStorage.getItem(USER));
  data["empresa"]["id"] = fornecedor_id;
  localStorage.setItem(USER, JSON.stringify(data));
};

export const getEmpresaId = () => {
  const data = JSON.parse(localStorage.getItem(USER));
  if (data) {
    return data["empresa"]["id"];
  }
};

export const getUser = () => {
  const data = JSON.parse(localStorage.getItem(USER));
  if (data) {
    return data["user"]["id"];
  }
};

export const setCNPJ = cnpj => {
  localStorage.setItem(CNPJ, cnpj);
};

export const getCNPJ = () => localStorage.getItem(CNPJ);

export const setIdEmpresa = id => {
  localStorage.setItem(ID_EMPRESA, id);
};

export const getIdEmpresa = () => localStorage.getItem(ID_EMPRESA);
