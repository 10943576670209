import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import { AlertaError, AlertaSuccess } from "./Funcoes/FuncoesGerais";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import LoginStores from "./LoginStores";
import LicitacoesPaginadaStore from "./LicitacoesPaginadaStore";

class ModalSincronizarStores {
  @observable
  modalSincronizarLicitacoes = false;
  @observable
  fim_progresso_carregano_lic = false;
  @observable
  licitacoes = [];
  @observable
  licitacoes_marcadas = [];
  @observable
  modalPreparandoSincronizacao = false;
  @observable
  loading = false;
  @observable
  progresso_carregando_lic = 0;
  @observable
  progressoLicitacoesSincronizadas = 0;
  @observable
  msgStatusSincronizacao = false;

  @action
  consulta_api = () => {
    this.handleModalCarregando();
    this.handleBarradeProgresso(this.progresso_carregando_lic);
    Api.post("portal/sincronizar_editais_novo/", {
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        let { dados } = response.data;
        this.licitacoes = dados;
        this.clouseBarraProgresso();
        AlertaSuccess("Licitacões Carregadas com Sucesso!");
        this.ModalSincronizarLicitacoes();
      })
      .catch(error => {
        this.clouseBarraProgresso();
        AlertaError(
          "Erro na sincronização, verifique suas credenciais.",
          error
        );
        if (!statusConexao()) {
          StatusConexaoStores.handleOpenModal();
        }
      });
  };

  //abre o primeiro modal e barra de carregamento
  handleModalCarregando = () => {
    this.modalPreparandoSincronizacao = !this.modalPreparandoSincronizacao;
  };

  //carrega barra de progresso no modal preparando sincronização
  handleBarradeProgresso = () => {
    setTimeout(() => {
      if (this.progresso_carregando_lic < 90) {
        setTimeout(() => {
          this.progresso_carregando_lic =
            this.progresso_carregando_lic +
            Math.floor(Math.random() * (10 - 5)) +
            5;
          this.handleBarradeProgresso();
        }, 1000);
      }
    }, 2000);
  };

  //fehcar modal barra de progresso
  clouseBarraProgresso = () => {
    this.progresso_carregando_lic = 100;
    setTimeout(() => {
      this.handleModalCarregando();
      this.progresso_carregando_lic = 0;
    }, 600);
  };

  //abre e fecha modal sincronizar licitacoes
  @action
  ModalSincronizarLicitacoes = () => {
    this.modalSincronizarLicitacoes = !this.modalSincronizarLicitacoes;
  };

  @action
  CheckLicitacao = prg_cod => {
    this.licitacoes = this.licitacoes.map(licitacao => {
      if (licitacao.prg_cod === prg_cod) {
        return { ...licitacao, status: !licitacao.status };
      }
      return licitacao;
    });
    this.licitacoes_marcadas = this.licitacoes.filter(item => {
      return item.status === true;
    });
  };

  incremeteProgresso = () => {
    let dados = this.licitacoes_marcadas.length;
    const percentual = 100 / dados;
    this.progressoLicitacoesSincronizadas = Math.round(
      this.progressoLicitacoesSincronizadas + percentual
    );
  };

  @action
  sincronizar = () => {
    this.msgStatusSincronizacao = false;
    if (this.licitacoes_marcadas.length > 0) {
      this.loading = true;
      for (let i = 0; i < this.licitacoes_marcadas.length; i++) {
        this.licitacoes = this.licitacoes.map(licitacao => {
          if (this.licitacoes_marcadas[i].prg_cod === licitacao.prg_cod) {
            return { ...licitacao, spinner: true };
          }
          return licitacao;
        });

        Api.post("licitacoes/cadastrar_licitacoes_novo/", {
          fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
          user_pk: LoginStores.user_id
            ? LoginStores.user_id
            : MaquinaDeEstadosStore.getIdUser(),
          dados: this.licitacoes_marcadas[i]
        })
          .then(response => {
            this.incremeteProgresso();
            if (this.licitacoes_marcadas.length - 1 === i) {
              this.loading = false;
              LicitacoesPaginadaStore.consulta_api_sem_loading();
              this.progressoLicitacoesSincronizadas = 100;
              setTimeout(() => {
                this.progressoLicitacoesSincronizadas = 0;
                this.msgStatusSincronizacao = true;
              }, 1500);
            }

            const { dados_certo, dados_erro } = response.data;
            this.licitacoes = this.licitacoes.map(licitacao => {
              if (dados_erro.indexOf(licitacao.prg_cod) !== -1) {
                return {
                  ...licitacao,
                  status: false,
                  message: "TENTE NOVAMENTE",
                  spinner: false
                };
              }
              if (dados_certo.indexOf(licitacao.prg_cod) !== -1) {
                return {
                  ...licitacao,
                  cadastrado: true,
                  status: false,
                  message: "",
                  spinner: false
                };
              }
              return { ...licitacao, status: false };
            });
          })
          .catch(error => {
            this.licitacoes = this.licitacoes.map(licitacao => {
              return { ...licitacao, status: false };
            });
            AlertaError("Portal Instavel tente mais tarde");
            this.loading = false;
          });
      }
    } else {
      AlertaError("Selecione uma licitação para sincronizar");
    }
  };

  @action
  handleStatusTodosChecks = tipe => {
    this.licitacoes = this.licitacoes.map(licitacao => {
      if (!licitacao.cadastrado) {
        return { ...licitacao, status: tipe };
      }
      return licitacao;
    });
    this.licitacoes_marcadas = this.licitacoes.filter(item => {
      return item.status === true;
    });
  };

  @action limparVariaveis = () => {
    this.modalSincronizarLicitacoes = false;
    this.fim_progresso_carregano_lic = false;
    this.licitacoes = [];
    this.licitacoes_marcadas = [];
    this.modalPreparandoSincronizacao = false;
    this.loading = false;
    this.progresso_carregando_lic = 0;
    this.progressoLicitacoesSincronizadas = 0;
    this.msgStatusSincronizacao = false;
  };
}

const modalSincronizarStores = new ModalSincronizarStores();
export default modalSincronizarStores;
