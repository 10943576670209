import React from "react";
import "react-datasheet/lib/react-datasheet.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "mobx-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginStores from "./Stores/LoginStores";
import CadastroStores from "./Stores/CadastroStores";
import ModalNotificacoesStores from "./Stores/ModalNotificacoesStores";
import LicitacoesStore from "./Stores/LicitacoesStore";
import MonitoramentoStore from "./Stores/MonitoramentoStore";
import ModalLoadingStore from "./Stores/ModalLoadingStore";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-s-alert/dist/s-alert-css-effects/scale.css";
import "react-s-alert/dist/s-alert-css-effects/bouncyflip.css";
import "react-s-alert/dist/s-alert-css-effects/flip.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "react-s-alert/dist/s-alert-css-effects/genie.css";
import "react-s-alert/dist/s-alert-default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free";
import "./Themes/Icons";
import "./Themes/style.css";
import MenuStores from "./Stores/MenuStores";
import Login from "./Pages/Login/Login";
import Cadastro from "./Pages/Register/Cadastro";
import BuscaDeEditaisStores from "./Stores/BuscaDeEditaisStores";
import OcorrenciasDoDiaStores from "./Stores/OcorrenciasDoDiaStores";
import ConfiguracoesBuscaEditaisStores from "./Stores/ConfiguracoesBuscaEditaisStores";

import ConfiguracoesMonitoramentoStores from "./Stores/ConfiguracoesMonitoramentoStores";
import ConfiguracoesGeraisStores from "./Stores/ConfiguracoesGeraisStores";
import ConfiguracoesGeraisModalStores from "./Stores/ConfiguracoesGeraisModalStores";
import ConfiguracoesGeraisModalEmpresaStores from "./Stores/ConfiguracoesGeraisModalEmpresaStores";
import ConfiguracoesCredenciaisStores from "./Stores/ConfiguracoesCredenciaisStores";
import EditaisStores from "./Stores/EditaisStores";
import CollapseEditaisSelecionadosStores from "./Stores/CollapseEditaisSelecionadosStores";
import { initPushNotification } from "./Stores/FirebaseStore";
import LicitacaoStores from "./Stores/LicitacaoStores";
import PaginaNaoEncontrada from "./Stores/Funcoes/PaginaNaoEncontrada";
import FunilStore from "./Stores/FunilStore";
import ModalSincronizarStores from "./Stores/ModalSincronizarStores";
import ModalSincronizarPelosEditaisStores from "./Stores/ModalSincronizarPelosEditaisStores";
import Redefinir_Senha from "./Pages/Login/Redefinir_Senha";
import MaquinaDeEstadosStore from "./Stores/MaquinaDeEstadosStore";
import LicitacoesPaginadaStore from "./Stores/LicitacoesPaginadaStore";
import LicitacoesExclusao from "./Stores/LicitacoesExclusao";
import StoreConfiguracoesGerais from "./Stores/StoreConfiguracoesGerais";
import RecuperarSenhaUsuario from "./Pages/Login/RecuperarSenhaUsuario";
import MonitoramentoStorePaginada from "./Stores/MonitoramentoStorePaginada";
import StoreConfiguracaoEmpresas from "./Stores/StoreConfiguracaoEmpresas";
import StoreConfiguracaoCredenciais from "./Stores/StoreConfiguracaoCredenciais";
import CompradoresStore from "./Stores/CompradoresStore";
import StoreSelecaoPortais from "./Stores/StoreSelecaoPortais";
import StoreProposta from "./Stores/StoreProposta";
import StoreFiltroModalidades from "./Stores/StoreFiltroModalidades";

// ## START NEW STORES ##
// prospeccao
import DetalhesEditalStore from "./Components/Modules/ModalDetalhes/store";
import BoletimStore from "./Pages/Prospection/DailyBulletin/Store";
import QualificadosStore from "./Pages/Prospection/Qualified/Store";
import AprovadosStore from "./Pages/Prospection/Approved/Store";
import ReprovadosStore from "./Pages/Prospection/Failed/Store";
import BuscaManualStore from "./Pages/Prospection/ManualSearch/Store";
import DownloadEditaisStore from "./Pages/Prospection/DownloadList/store";
// proposta
import PropostaStore from "./Pages/Proposal/store";
import CadastrarPropostaStore from "./Pages/Proposal/CadastrarProposta/store";

import GerenciarPropostaStore from "./Pages/Proposal/GerenciarProposta/store";
import ExportarPropostaStore from "./Pages/Proposal/ExportarProposta/store";
import ImprimirPropostaStore from "./Pages/Proposal/ImprimirProposta/store";
import ItensPropostaStore from "./Pages/Proposal/CadastrarProposta/contexto/Itens/store";

// ## END ##

import AlertaVerbalStore from "./Stores/AlertaVerbalStore";
import AlertaVerbalFemininoStore from "./Stores/AlertaVerbalFemininoStore";

import AtivarConta from "./Components/System/AtivarConta/AtivarConta";
import StatusConexaoStores from "./Stores/StatusConexaoStores";
import PainelAvisoStores from "./Stores/PainelAvisoStores";

import BuscaDeEditaisLicitacaoStores from "./Stores/BuscaDeEditaisLicitacaoStores";
import SideStores from "./Stores/SideStores";
import FinanceiroStores from "./Stores/FinanceiroStores";
import ModalLateralStore from "./Stores/ModalLateralStore";
import NewEditaisStores from "./Stores/NewEditaisStores";
import * as moment from "moment";
import DashboardStores from "./Stores/DashboardStores";
import CadastroProvider from "./Pages/Register/context";
import ContextoProviders from "./Providers";
import { initChannel, isMainTab } from "./Stores/MainTab";
const stores = {
  MaquinaDeEstadosStore,
  FinanceiroStores,
  SideStores,
  LoginStores,
  CadastroStores,
  BuscaDeEditaisLicitacaoStores,
  ModalNotificacoesStores,
  MenuStores,
  LicitacoesStore,
  MonitoramentoStore,
  ModalLoadingStore,
  BuscaDeEditaisStores,
  OcorrenciasDoDiaStores,
  ConfiguracoesBuscaEditaisStores,
  ConfiguracoesMonitoramentoStores,
  ConfiguracoesGeraisStores,
  ConfiguracoesGeraisModalStores,
  ConfiguracoesGeraisModalEmpresaStores,
  ConfiguracoesCredenciaisStores,
  EditaisStores,
  CollapseEditaisSelecionadosStores,
  LicitacaoStores,
  LicitacoesExclusao,
  FunilStore,
  ModalSincronizarStores,
  ModalSincronizarPelosEditaisStores,
  LicitacoesPaginadaStore,
  MonitoramentoStorePaginada,
  StatusConexaoStores,
  PainelAvisoStores,
  ModalLateralStore,
  StoreConfiguracoesGerais,
  StoreConfiguracaoEmpresas,
  StoreConfiguracaoCredenciais,
  CompradoresStore,
  StoreSelecaoPortais,
  StoreFiltroModalidades,
  NewEditaisStores,
  DashboardStores,
  StoreProposta,
  AlertaVerbalStore,
  AlertaVerbalFemininoStore,
  // COMEÇA NOVOS STORES
  DetalhesEditalStore,
  BoletimStore,
  BuscaManualStore,
  QualificadosStore,
  ReprovadosStore,
  DownloadEditaisStore,
  AprovadosStore,
  PropostaStore,
  CadastrarPropostaStore,
  ImprimirPropostaStore,
  ItensPropostaStore,
  GerenciarPropostaStore,
  ExportarPropostaStore
};
moment.locale("pt-BR");

const container = document.getElementById("root");

ReactDOM.render(
  <Provider {...stores}>
    <CadastroProvider>
      <ContextoProviders>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Login} />
            {/* <Route exact path="/painel_monitoramento" component={PainelMonitoramento} />*/}
            <Route exact path='/ativarconta' component={AtivarConta} />
            <Route exact path='/404' component={PaginaNaoEncontrada} />
            <Route exact path='/static' component={PaginaNaoEncontrada} />
            <Route exact path='/redefinir' component={PaginaNaoEncontrada} />

            <Route path='/cadastro' component={Cadastro} />

            <Route path='*/static' component={PaginaNaoEncontrada} />

            <Route
              exact
              path='/recuperar_senha'
              component={RecuperarSenhaUsuario}
            />
            <Route path='/redefinir_senha' component={Redefinir_Senha} />

            <Route render={props => <App {...props} />} />
          </Switch>
        </BrowserRouter>
      </ContextoProviders>
    </CadastroProvider>
  </Provider>,
  container
);
initChannel();
if (window.location.pathname !== "/") initPushNotification()
