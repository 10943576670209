import React from "react";
import { format, } from 'date-fns';
import { FileText } from "react-feather";
import BadegRedondo from "../../../../../ReusableComponents/Badeg/badegRedondo";
import { CustomAlert, ToastError } from "../../../../../../Stores/Funcoes/FuncoesGerais";
import Carregar from "./Carregar";

const formattedDate = (data) => {
  return (format(new Date(data), 'dd/MM/yyyy'))
};

export const columnsCadastar = [
  {
    name: 'NUM.',
    selector: 'numero',
    width: '100px',
    wrap: true,
    cell: ({ numero }) => (<b>{numero}</b>)
  },
  {
    name: 'OBJETO',
    selector: 'descricao',
    sortable: true,
    grow: 5,
    wrap: true,
  },

  {
    name: 'DISPUTA',
    selector: 'data_disputa',
    sortable: true,
    grow: 1,
    wrap: true,
    style: { fontWeight: 'bold', },
    cell: ({ data_disputa }) => { return formattedDate(data_disputa) }
  },

  {
    name: 'ORGÃO',
    selector: 'comprador.nome',
    sortable: true,
    grow: 2,
    wrap: true,
  },

  {
    name: 'PORTAL',
    selector: 'portal.nome',
    sortable: true,
    grow: 1,
  },

  {
    name: 'CARREGAR ITENS',
    selector: 'portal',
    center: true,
    grow: 1,
    cell: (edital) => { return <Carregar edital={edital} /> }
  },

];


// const Badeg = ({ edital }) => {
//   if (edital.proposta.enviada) {
//     return <BadegRedondo icon={<FileText />} onClick={() => ToastError('Essa proposta já foi enviada.')} background={'#cacaca'} repetido={true} />
//   }
//   return <BadegRedondo icon={<FileText />} onClick={() => { }} background={'#1D7C98'} />
// }



