import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  SingleSwitch,
  BodySwitch
} from '../../globalStyles'
import { inject, observer } from 'mobx-react'
import { Info } from 'react-feather'

const CardMonitoramento = inject('ConfiguracoesMonitoramentoStores')(
  observer(
    ({
      ConfiguracoesMonitoramentoStores,
      nameVariavel,
      title,
      subTitle,
      Icon,
      cor
    }) => {
      const {
        [nameVariavel]: { monitoramento, notificacao, som, palavra_importante },
        handleMonitorar,
        handleNotificacao,
        handleSom,
        infoShowing,
        toggleInfoShowing
      } = ConfiguracoesMonitoramentoStores
      return (
        <Card width={'24%'} cor={cor}>
          <CardHeader fontWeight={'Light'} minHeight={'30%'}>
            <div>
              <div>
                {Icon}&nbsp;&nbsp;{title}
              </div>
              <div
                style={{
                  color: '#777',
                  marginTop: '10px',
                  fontSize: window.innerWidth > 1500 ? '15px' : '12px'
                }}
              >
                {subTitle}
              </div>
            </div>
            {nameVariavel === 'empresa' && (
              <>
                <Info
                  className="tag-info"
                  onMouseEnter={toggleInfoShowing}
                  onMouseLeave={toggleInfoShowing}
                />
                {infoShowing && (
                  <div className="company-words-used">
                    <b className="mb-1">
                      Palavras utilizadas para monitorar empresa
                    </b>
                    {(palavra_importante || '').split(',').map(pal => {
                      return <span>{pal}</span>
                    })}
                  </div>
                )}
              </>
            )}
          </CardHeader>
          <CardBody
            flexDirection={'column'}
            justifyContent={'space-around'}
            height={'34vh'}
          >
            <BodySwitch>
              <SingleSwitch
                checked={monitoramento}
                onChange={() => handleMonitorar([nameVariavel], !monitoramento)}
                value="monitorar"
                color="#50a476"
              />
              <div>MONITORAR</div>
            </BodySwitch>
            <BodySwitch>
              <SingleSwitch
                checked={notificacao}
                onChange={() => handleNotificacao([nameVariavel], !notificacao)}
                value="notificacao"
                color="#50a476"
              />
              <div>NOTIFICAR POR EMAIL</div>
            </BodySwitch>

            <div
              style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <select
                style={{ width: '200px' }}
                className="form-control"
                value={som}
                onChange={e => handleSom([nameVariavel], e.target.value)}
              >
                <option>Nenhum</option>
                <option>ALERTA 1</option>
                <option>ALERTA 2</option>
                <option>ALERTA 3</option>
                <option>ALERTA 4</option>
                <option>ALERTA 5</option>
                <option>VOCAL MASCULINO</option>
                <option>VOCAL FEMININO</option>
              </select>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: '-5%'
              }}
            >
              ALERTA SONORO
            </div>
          </CardBody>
        </Card>
      )
    }
  )
)

export default CardMonitoramento
