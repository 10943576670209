import React from 'react';
import BadegHorizontal from "../../../Components/ReusableComponents/Badeg/badegHorizontal";

export const tratarPrioridade = (prioridade) => {
  let name, color;
  if (!prioridade) return '';
  switch (prioridade.toLowerCase()) {
    case 'alta':
      name = 'ALTA';
      color = '#b54a4a';
      break;
    case 'media':
      name = 'MÉDIA';
      color = "#daa300";
      break;
    case 'baixa':
      name = 'BAIXA';
      color = "#58c340";
      break;
    default:
      name = 'ALTA';
      color = '#b54a4a';
  }
  return <BadegHorizontal texto={name} background={color} />
}