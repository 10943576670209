import React, { Component } from 'react'
import moment from 'moment'
import Toolbar from 'react-big-calendar/lib/Toolbar'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { List } from 'react-feather'

import MenuStores from '../../../Stores/MenuStores'
import { ExclamationWarning } from '../../Notifications/ExclamationWarning'
import { setLicitacaoId } from '../../../Services/licitacao'

import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  TabButton,
  ReactTooltip
} from '../../../globalStyles'
import {
  NavegacaoContainer,
  SingleNavigationItem
} from '../../../Pages/Bids/styleLicitacoes'

const localizer = momentLocalizer(moment)

const messages = {
  allDay: 'Dia todo',
  previous: 'Voltar',
  next: 'Próximo',
  today: 'Hoje',
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Hora',
  event: 'Evento',

  showMore: total => `+ ${total} Evento(s)`,
  noEventsInRange: (
    <ExclamationWarning title="Não há licitações agendadas" height="65vh" />
  )
}

const eventStyleGetter = event => {
  let style = {
    fontSize: '12px',
    backgroundColor: event.descricao,
    borderRadius: '10px',
    color: 'white',
    border: '0px',
    alignItens: 'center',
    padding: '2px',
    margin: '4px',
    display: 'flex'
  }
  return {
    style: style
  }
}

@inject('LicitacoesPaginadaStore', 'LicitacaoStores')
@observer
class Agenda extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
  }

  componentDidMount() {
    this.props.LicitacoesPaginadaStore.Agenda()
    MenuStores.consultaTitle()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight })

  redireciona(id) {
    setLicitacaoId(id)
    this.props.LicitacaoStores.licitacao_vista(id)
    this.props.LicitacaoStores.pagina_atual = '1'
    this.props.history.push('/licitacao')
  }

  render() {
    const { width } = this.state
    const { agenda } = this.props.LicitacoesPaginadaStore
    const newAgenda = agenda.map(item => {
      return {
        ...item,
        title: (
          <div
            style={{ width: '95%', cursor: 'pointer' }}
            onClick={() => this.redireciona(item.id)}
          >
            {item.title}
          </div>
        )
      }
    })

    return (
      <>
        <ComponentHeader
          height="8vh"
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 0
          }}
        />
        <ComponentHeaderOverflow height="8vh" />
        <ContentBody
          bRadius
          style={{ height: 'auto', marginBottom: 0 }}
          background={'white'}
        >
          <Calendar
            style={{
              height: width > 1400 ? '74vh' : '71.3vh',
              margin: '10px',
              marginBottom: '1rem'
            }}
            min={new Date(2019, 0, 1, 0, 0)} // 8.00 AM
            max={new Date(2019, 0, 1, 23, 0)} // Max will be 6.00 PM!
            localizer={localizer}
            showMultiDayTimes={true}
            onSelectEvent={e => this.redireciona(e.id)}
            onSelectSlot={e => {
              this.redireciona(e.id)
            }}
            components={{
              toolbar: CustomToolbar
            }}
            messages={messages}
            defaultDate={moment().toDate()}
            events={newAgenda}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            defaultView="month"
            tooltipAccessor={e => e.desc}
            step={15}
          />
        </ContentBody>
      </>
    )
  }
}

export default Agenda

const checkPathname = path => path === window.location.pathname

class CustomToolbar extends Toolbar {
  constructor(props) {
    super(props)
    this.state = { status: 'TODAY' }
  }

  handleStatusNavigate = dados => {
    this.setState({ status: dados })
    this.navigate(dados)
  }

  handleStatusViews = dados => {
    this.setState({ status: dados })
    this.views(dados)
  }

  views = action => {
    this.props.onView(action)
  }
  navigate = action => {
    this.props.onNavigate(action)
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '-5vh',
          width: '97%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ReactTooltip />
        <div
          style={{
            display: 'flex',
            width: '320px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <TabButton
            onClick={() => this.handleStatusNavigate('TODAY')}
            ul={this.state.status === 'TODAY'}
            value="todas"
            style={{ fontSize: '0.9rem' }}
          >
            HOJE
          </TabButton>
          <TabButton
            onClick={() => this.handleStatusNavigate('PREV')}
            value="prev"
            ul={this.state.status === 'PREV'}
            style={{ fontSize: '0.9rem' }}
          >
            ANTERIOR
          </TabButton>
          <TabButton
            onClick={() => this.handleStatusNavigate('NEXT')}
            value="somente"
            ul={this.state.status === 'NEXT'}
            style={{ fontSize: '0.9rem' }}
          >
            PRÓXIMO
          </TabButton>
        </div>
        <NavegacaoContainer top="-24px">
          <SingleNavigationItem active={checkPathname('/agenda')}>
            <Link to="/agenda">
              <FontAwesomeIcon
                className="icon-scale icon-header"
                icon="calendar-check"
                data-tip="AGENDA"
                data-effect="solid"
                data-type="warning"
                data-place="bottom"
              />
            </Link>
          </SingleNavigationItem>
          <SingleNavigationItem active={checkPathname('/funil')}>
            <Link to="/funil">
              <FontAwesomeIcon
                className="icon-scale icon-header"
                icon="filter"
                data-tip="FUNIL"
                data-effect="solid"
                data-type="warning"
                data-place="bottom"
              />
            </Link>
          </SingleNavigationItem>
          <SingleNavigationItem active={checkPathname('/licitacoes')}>
            <Link to="/licitacoes">
              <FontAwesomeIcon
                className="icon-scale icon-header"
                icon="list"
                data-tip="LISTA"
                data-effect="solid"
                data-type="warning"
                data-place="bottom"
              />
            </Link>
          </SingleNavigationItem>
          <SingleNavigationItem active={checkPathname('/licitacoes/excluir')}>
            <Link to="/licitacoes/excluir">
              <FontAwesomeIcon
                className="icon-scale icon-header"
                icon="trash"
                data-tip="LIXEIRA"
                data-effect="solid"
                data-type="warning"
                data-place="bottom"
              />
            </Link>
          </SingleNavigationItem>
        </NavegacaoContainer>

        <span
          style={{
            color: 'white',
            position: 'absolute',
            right: 'calc(50% - 42.725px)',
            bottom: '-30px',
            background: '#b7b7b7',
            borderRadius: '7px',
            padding: '0 5px',
            boxShadow: '1px 1px 5px #000',
            fontWeight: 600
          }}
          className="rbc-toolbar-label"
        >
          {this.props.label}
        </span>

        <div
          style={{
            display: 'flex',
            width: '200px',
            justifyContent: 'space-between'
          }}
        >
          <TabButton
            onClick={() => this.handleStatusViews('day')}
            value="agenda"
            ul={this.state.status === 'day'}
            style={{ fontSize: '0.9rem' }}
          >
            DIA
          </TabButton>
          <TabButton
            onClick={() => this.handleStatusViews('week')}
            value="agenda"
            ul={this.state.status === 'week'}
            style={{ fontSize: '0.9rem' }}
          >
            SEMANA
          </TabButton>

          <TabButton
            onClick={() => this.handleStatusViews('month')}
            value="agenda"
            ul={this.state.status === 'month'}
            style={{ fontSize: '0.9rem' }}
          >
            MÊS
          </TabButton>

          <TabButton
            onClick={() => this.handleStatusViews('agenda')}
            value="agenda"
            ul={this.state.status === 'agenda'}
            style={{ fontSize: '0.9rem' }}
          >
            AGENDA
          </TabButton>
        </div>
      </div>
    )
  }
}
