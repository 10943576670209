import styled from 'styled-components';

const Container = styled.div`
  background: #3a566e;
  padding: 0 30px;
  height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const Overflow = styled(Container)`
  position: absolute;
  top: 48px;
  width: 100%;
  background: #3a566e;
`;

export {Container, Overflow}
