import styled from 'styled-components'

export const ListaLicitacoes = styled.div`
  display: flex;
  flex-direction: column;
  height: 102.5%;
  width: 360px;
  background-color: #517695;
  border-radius: 7px;
  top: -2.5%;
  left: 0px;
  position: absolute;
  border-bottom-right-radius: 0;
  box-shadow: 0px 2px 4px 0.1px #00000073;

  @media (max-width: 1200px) {
    width: 300px;
  }

  .lista-header {
    display: flex;
    color: #ffffff73;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #ffffff4f;
    font-size: 12px;
    text-align: left;
    min-height: 50px;
    padding-left: 32px;
    position: relative;

    @media (max-width: 1200px) {
      font-size: 11px;
    }
  }
`

export const TabContainer = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 1200px) {
    width: 300px;
  }
`

export const MessageList = styled.div`
  display: flex;
  height: 100%;
  margin-left: 350px;
  padding: 0;

  @media (max-width: 1200px) {
    margin-left: 295px;
  }
`

export const ListaDeMensagens = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  animation: 1s fadeInHu;
  overflow-x: hidden;
  overflow-y: auto;

  @keyframes fadeInHu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const AlertNovasMensagens = styled.div`
  position: absolute;
  top: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background: #6caade;
  padding: 5px 10px;
  left: 40%;
  /* right: 0; */
  /* width: 100px; */
  border-radius: 4px;
  box-shadow: 1px 1px 6px 1px #2f2f2f;
  z-index: 10;
`

export const MessageListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 101%;

  @media (max-width: 1260px) {
    height: calc(100% - 50px);
  }
`

export const WrapperCol = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => (props.background ? props.background : 'white')};
  border-radius: 15px;
  height: auto;
  padding: 20px;
  width: 100%;
  flex-direction: column;

  :hover {
    background-color: ${props =>
      props.hoverBgColor ? props.hoverBgColor : '#fff'};
  }
`

export const DivFlex = styled.div`
  width: ${props => (!props.width ? 'auto' : props.width)};
  display: flex;
  flex-direction: ${props =>
    !props.flexDirection ? 'row' : props.flexDirection};
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  align-content: center;

  button {
    width: ${props => (!props.width ? 'auto' : props.width)};
    font-size: 11px;
    padding: 5px;
  }

  a {
    width: ${props => (!props.width ? 'auto' : props.width)};
    font-size: 11px;
    padding: 5px;
  }

  table {
    width: 100%;
    font-size: 70%;
  }

  thead {
    color: rgba(9, 41, 64, 0.89);
  }
`
