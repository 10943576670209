import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index.es';
import { Container } from './styles';
import { useFiltroEdital } from "../../Filter/context/FiltroEditaisContext";

const Filtros = () => {
  const { showFiltro, state: { filtro } } = useFiltroEdital()
  return (
    <Container onClick={showFiltro} active={filtro}>
      <FontAwesomeIcon icon="filter" color="#67baec" />
      <span>FILTRAR</span>
    </Container>
  )
}

export default Filtros
