import styled from "styled-components";

export const Item = styled.div`
  display: flex;
  width: 100%;
  color: ${props => props.color};
  background: white;
  box-shadow: 0 2px 4px 1px #00000060;
  border-radius: 7px;
  margin: 5px;
  align-items: center;
  flex: 1;
  animation: slide-in-fwd-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-fwd-top {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(-800px);
      transform: translateZ(-1400px) translateY(-800px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
`;

export const CardIcone = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  height: 100%;
  justify-content: center;
`;
export const Icone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: white;
  background: ${props => props.background};
  margin-right: 10px;
`;

export const Number = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  width: 20%;
  font-size: 30px;
  margin-top: 10px;
  color: ${props => props.color}

  h2{
    font-size: ${props => props.fontSize && '10px'};
    color: ${props => props.color};
  }
  b{
   font-size: ${props => props.fontSize && '19px'};
  }
`;

export const CardBarra = styled.div`
  width: 1.5px;
  height: 85%;
  background: ${props => props.background};
  margin-right: 10px;
  margin-left: 3px;
`;

export const CardNull = styled.div`
  display: flex;
  flex-direction: column;
  color: #046a8f;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 100%;
  margin: 20px;
  div{
  font-size: 13px;
  color: #7c8084;
  }
`;
