import React, { useState } from 'react'
import { CardBody, CardFiltro, CardHeard, CardTitulo } from './styles'
import { Map } from 'react-feather'
import InputMultiploSelect from '../../../Components/ReusableComponents/Inputs/InputMultiploSelect'
import { useFiltroEdital } from "./context/FiltroEditaisContext";

const estados = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  {
    nome: 'Amapá',
    sigla: 'AP'
  },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  {
    nome: 'Ceará',
    sigla: 'CE'
  },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  {
    nome: 'Goiás',
    sigla: 'GO'
  },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  {
    nome: 'Mato Grosso do Sul',
    sigla: 'MS'
  },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  {
    nome: 'Paraíba',
    sigla: 'PB'
  },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  {
    nome: 'Piauí',
    sigla: 'PI'
  },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  {
    nome: 'Rio Grande do Sul',
    sigla: 'RS'
  },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  {
    nome: 'Santa Catarina',
    sigla: 'SC'
  },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' }
]

const CardEstados = () => {
  const [selected, setSelected] = useState(true);
  const { onChangeEstados, selectTodosEstados, state: { estados: estadosSelecionados } } = useFiltroEdital()

  const handleAllSelectEstados = () => {
    selectTodosEstados(!selected)
    setSelected(!selected)
  }

  return (
    <CardFiltro>
      <CardHeard>
        <Map />
        <CardTitulo>
          <div>FILTRO ESTADOS</div>
          <p>Selecione os estados para serem filtrados</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <InputMultiploSelect
          type={'sigla'}
          selected={estadosSelecionados}
          icone={<Map />}
          onCLick={handleAllSelectEstados}
          data={estados}
          onChange={(e) => onChangeEstados(e.target.options)}
        />
      </CardBody>
    </CardFiltro>
  )
}

export default CardEstados
