import React, { Component } from "react";
import { Button, Col, Grid, Image, Jumbotron, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";

class AtivarConta extends Component {
  componentDidMount() {
    MaquinaDeEstadosStore.instancia_props(this.props);
  }

  redireciona_login = () => {
    MaquinaDeEstadosStore.redireciona_login();
  };
  render() {
    return (
      <Grid>
        <style type="text/css">
          {`
                        .um {
                            background-image: -webkit-linear-gradient(45deg, #8089ff 0, #54ceff 51%, #8089ff 100%);
	                        background-image: linear-gradient(45deg, #8089ff 0, #54ceff 51%, #8089ff 100%);
                        }
                    `}
        </style>
        <Row className="show-grid">
          <Col style={{ marginLeft: "8%", marginTop: "19%", width: "70%" }}>
            <Jumbotron
              style={{
                background: "#C6E2FF",
                textAlign: "center",
                marginRight: "-150px"
              }}
            >
              <Image
                src="/static/Imagens/logotipo_2017.png"
                style={{ width: "20%" }}
              />
              <br />
              <br />
              <br />

              <h2>CONTA ATIVADA COM SUCESSO!</h2>
              <br />
              <p>
                Sua conta foi ativada com sucesso, click no botão a baixo para
                fazer login.
              </p>
              <p>
                <br />

                <Link to={"/"} title={"Login"}>
                  <Row className="row-logo">
                    <Col md={12} className="text-center ">
                      <Button
                        onClick={() => this.redireciona_login()}
                        bsStyle="info"
                        className=""
                        style={{ fontSize: "12px", marginTop: "10px" }}
                      >
                        Fazer Login
                      </Button>
                    </Col>
                  </Row>
                </Link>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default AtivarConta;
