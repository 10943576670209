import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import ModalAddCompany from "./modalLateral/ModalAddCompany";
import ModalAddUser from "./modalLateral/ModalAddUser";
import Usuario from "./Usuarios/Usuario";
import Empresa from "./Empresas/Empresa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ReactTooltip,
  ComponentHeader,
  TabButton,
  ComponentHeaderOverflow,
  ContentBody
} from "../../globalStyles";
import { TabContainer } from "./Style";

@inject("StoreConfiguracoesGerais", "MaquinaDeEstadosStore")
@observer
class ConfiguracoesGerais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "usuarios"
    };
  }

  componentDidMount() {
    const { popularStore } = this.props.StoreConfiguracoesGerais;
    const { usuarioLogado } = this.props.MaquinaDeEstadosStore;
    if (usuarioLogado) popularStore();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  changeTab = tab => this.setState({ activeTab: tab });

  render() {
    const { activeTab } = this.state;
    const { empresas, usuarios } = this.props.StoreConfiguracoesGerais;
    const { usuarioLogado } = this.props.MaquinaDeEstadosStore;

    if (!usuarioLogado) return null;
    const { is_superuser } = usuarioLogado;

    return (
      <>
        <ReactTooltip effect="solid" />
        <ComponentHeader height="8vh">
          <TabContainer>
            <TabButton
              ul={activeTab === "usuarios"}
              value="usuarios"
              onClick={() => this.changeTab("usuarios")}
            >
              <FontAwesomeIcon icon="users" className="mr-2" />
              USUÁRIOS
            </TabButton>
            {is_superuser && (
              <TabButton
                ul={activeTab === "empresas"}
                value="empresas"
                id="config-geral-empresas"
                onClick={() => this.changeTab("empresas")}
              >
                <FontAwesomeIcon icon="window-restore" className="mr-2" />
                EMPRESAS
              </TabButton>
            )}
          </TabContainer>
        </ComponentHeader>
        <ComponentHeaderOverflow height="8vh" />
        <div style={{ position: 'relative' }} id="container-configuracao-geral">
          <ContentBody bRadius id={`body-${activeTab}`} height="calc(92vh - 80px)" style={{ overflow: 'auto' }}>
            {activeTab === "usuarios" && (
              <>
                {is_superuser ? (
                  <>
                    <ModalAddUser empresas={empresas} />
                    {usuarios.map(user => (
                      <Usuario user={user} key={user.id} />
                    ))}
                  </>
                ) : (
                    <Usuario user={usuarioLogado} />
                  )}
              </>
            )}
            {activeTab === "empresas" && (
              <>
                <ModalAddCompany />
                {empresas.map(emp => (
                  <Empresa empresa={emp} />
                ))}
              </>
            )}
          </ContentBody>
        </div>
      </>
    );
  }
}

export default ConfiguracoesGerais;
