import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FileText, ThumbsUp, ThumbsDown } from 'react-feather'
import { PropostaEspecificaContext } from '../../context'
import { DadosPdfContext } from '../dadosPdf/context'
import { DocumentosContext } from '../enviarDocumentos/context'
import PassoDeEnvio from './PassoDeEnvio'
import * as S from './styles'
import ItensPropostaStore from '../../../../../../../Pages/Proposal/CadastrarProposta/contexto/Itens/store'

const incrementValue = 1
const EnviarProposta = () => {
  const {
    rows,
    enviandoProposta,
    progress,
    propostaEnviada,
    setProgress,
    errorMessage
  } = useContext(PropostaEspecificaContext)
  const { docsHabilitacao, docsProposta } = useContext(DocumentosContext)
  const { pdf, pdfFile } = useContext(DadosPdfContext)

  const numeroDeItens = rows.filter(ed => ed.checked).length
  const envioCompleto =
    propostaEnviada &&
    ItensPropostaStore.attachedDocuments.every(doc => doc.enviado)

  console.log(ItensPropostaStore.attachedDocuments.every(doc => doc.enviado), ItensPropostaStore.attachedDocuments)

  const docsPropostaString = JSON.stringify(docsProposta)
  const docsHabilitacaoString = JSON.stringify(docsHabilitacao)
  const pdfString = JSON.stringify(pdf)

  const docsPropostaMemo = useMemo(() => docsProposta, [docsPropostaString])
  const docsHabilitacaoMemo = useMemo(() => docsHabilitacao, [
    docsHabilitacaoString
  ])
  const pdfMemo = useMemo(() => pdf, [pdfString])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (progress >= 99) return
      setProgress(progress + incrementValue)
    }, 6000)
    if (progress >= 99) clearTimeout(timeoutId)
    return () => {
      clearTimeout(timeoutId)
    }
  })

  console.log(progress)

  const comp = useMemo(() => {
    return (
      <S.Container>
        {envioCompleto ? (
          <S.EnvioCompleto>
            <div className="wrapper">
              <ThumbsUp />
            </div>
            <span className="title">PROPOSTA CADASTRADA COM SUCESSO! POR FAVOR VERIFIQUE SE SUA PROPOSTA FOI CADASTRADA NO PORTAL</span>
          </S.EnvioCompleto>
        ) : Boolean(errorMessage) ? (
          <S.SendError>
            <div className="wrapper">
              <ThumbsDown />
            </div>
            <span className="title">{errorMessage}</span>
          </S.SendError>
        ) : (
          <>
            <S.StatusContainer>
              <S.ProgressBar progress={progress}>{progress}%</S.ProgressBar>
            </S.StatusContainer>
            {!!docsProposta.length && (
              <PassoDeEnvio
                title="DOCUMENTOS"
                subtitle="ENVIANDO ANEXOS DA PROPOSTA
                "
                icon={<FileText />}
                color="#3b576f"
                files={docsPropostaMemo}
              />
            )}
            {!!docsHabilitacao.length && (
              <PassoDeEnvio
                title="HABILITAÇÃO"
                subtitle="ENVIANDO ANEXOS DE HABILITAÇÃO"
                icon={<FileText />}
                color="#267c90"
                files={docsHabilitacaoMemo}
              />
            )}
            {!!pdf && !!pdfFile && (
              <PassoDeEnvio
                title="PDF PROPOSTA"
                subtitle="ENVIANDO PDF COM A PROPOSTA"
                icon={<FontAwesomeIcon icon="file-pdf" />}
                color="#ec7575"
                files={[pdf]}
              />
            )}
            <PassoDeEnvio
              title="ITENS PROPOSTA"
              subtitle="ENVIANDO ITENS DA PROPOSTA"
              icon={<FileText />}
              color="#7fb236"
              files={[
                {
                  name: `${numeroDeItens} ITENS`,
                  loading: enviandoProposta,
                  enviado: propostaEnviada
                }
              ]}
            />
          </>
        )}
      </S.Container>
    )
  }, [
    docsHabilitacaoMemo,
    docsPropostaMemo,
    pdfMemo,
    propostaEnviada,
    enviandoProposta,
    progress
  ])

  return comp
}

export default EnviarProposta
