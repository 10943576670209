import React from 'react'
import TextField from '@material-ui/core/TextField'
import DataTable from 'react-data-table-component'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { List, ExternalLink } from 'react-feather'

import { columnsEdital, rowEdital } from './customItensTabela'
import { ExclamationWarning } from '../../../../Components/Notifications/ExclamationWarning'

export const TabelaItemEdital = ({ itens }) => {
  const [filterText, setFilterText] = React.useState('')

  if (!itens) return null
  
  const filteredItems = itens.filter(item =>
    item.descricao_completa
      ? item.descricao_completa.toUpperCase().includes(filterText.toUpperCase())
      : item.descricao.toUpperCase().includes(filterText.toUpperCase())
  )
  
  const subHeaderComponentMemo = React.useMemo(
    () => <TableHeader onFilter={value => setFilterText(value)} />,
    []
  )

  return (
    <div style={{ display: 'flex', padding: '20px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '10px',
          width: '92vw',
          height: '80vh',
          boxShadow: '0px 1px 8px 1px #00000091'
        }}
      >
        <DataTable
          fixedHeader
          className="tabelalo"
          fixedHeaderScrollHeight="92vh"
          allowOverflow={true}
          overflowY={true}
          striped
          pointerOnHover
          columns={columnsEdital}
          highlightOnHover
          customTheme={rowEdital}
          data={filteredItems}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationComponentOptions={{
            rowsPerPageText: 'Licitações por pagina:',
            rangeSeparatorText: 'até'
          }}
          noDataComponent={
            <ExclamationWarning title="Nenhum item cadastrado" height="65vh" />
          }
        />
      </div>
    </div>
  )
}

const TableHeader = ({ onFilter }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
      }}
    >
      {/*<ReactTooltip/>*/}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          fontSize: '17px'
        }}
      >
        <List style={{ marginRight: '10px' }} />
        <b>RELAÇÃO DE ITENS</b>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ExternalLink
          className="icon-scale"
          data-tip="VER NO PORTAL"
          data-type="info"
          data-effect="solid"
          data-place="top"
          style={{ marginRight: '10px' }}
        />
        {PesquisaItensEdital(onFilter)}
      </div>
    </div>
  )
}

const PesquisaItensEdital = onFilter => {
  return (
    <TextField
      style={{ width: '400px' }}
      id="search"
      type="search"
      placeholder="Pesquisar..."
      variant="outlined"
      margin="dense"
      label="Pesquisar..."
      onChange={e => onFilter(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FontAwesomeIcon icon="search" color="#d7d7d7" />
          </InputAdornment>
        )
      }}
    />
  )
}
