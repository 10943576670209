import maquinadeestadosstore from '../Stores/MaquinaDeEstadosStore'

export const getPortalName = id => {
  if (!id) return

  if (
    maquinadeestadosstore.portais.find(
      portal => Number(portal.id) === Number(id)
    )
  ) {
    return maquinadeestadosstore.portais.find(
      portal => Number(portal.id) === Number(id)
    ).nome
  }
  return 'Portal não encontrado'
}

export const getPortalId = name => {
  console.log('name', name.toLowerCase())
  switch (name.toLowerCase()) {
    case "compras públicas":
      return 9
    case 'comprasnet':
      return 5
    case 'licitações-e':
      return 6
    default:
      return null
  }
}
