import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  height: 300px;
  width: 500px;
  z-index: 2222;
  background: white;
  border-radius: 5px;
  border: 1px solid #d4d4d4;

`;


export const InputValorDescricao= styled.textarea`
  font-size: 90%;
  height: 98%;
  width: 98%;
  border: none;
  font-size: 12px;
  :active{
     border: none;
       background: #7a3333;
  }
  :focus {
    background: #eee;
    outline: 1px solid grey;
      border: none;
  }
`;
