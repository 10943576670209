import styled from "styled-components";

export const CardMDBodyContainer1 = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const CardMDBodyContainer2 = styled.div`
  display: flex;
  width: 60%;
  height: auto;
  margin: 0px;
  align-items: center;
`;

export const CardMDTextBody = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 10px;
  color: ${props => props.color};
  h3{
   width: 50px;
  margin: 0px;
  font-size: 20px;
  margin-right: 10px;
  }

  div{
  font-size: 13px;
  color: #a7a7a7;

  }
`;

export const CardBarra = styled.div`
  display: flex;
  margin-top: 5px;
  width: 1px;
  height: 80%;
  background: ${props => props.background};
  margin-right: 10px;
  margin-left: 3px;
`;

export const ContainerAgenda = styled.div`
  display: flex;
  flex-direction: column;
  width: 65px;
  height: 70px;
  background: #0d2466;
  color: white;
  align-items: center;
  border-radius: 7px;
  border-color: #0D2466;
  padding: 1px;
  margin: 15px;

  @media only screen and (max-height: 800px) {
     width: 55px;
    height: 60px;
  }
`;

export const Container1 = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 19px;
  background: white;
  border-radius: 6px 6px 0px 0px;

`;

export const Barra1 = styled.div`
    position: relative;
    width: 2px;
    height: 15px;
    margin-top: -25px;
    margin-left: -15px;
    background: #0D2466;
`;

export const Barra2 = styled.div`
    position: relative;
    width: 2px;
    height: 15px;
    margin-top: -15px;
    margin-left: 15px;
    background: #0D2466;
`;

export const Badge = styled.div`
    position: absolute;
    width: 25px;
    height: 25px;
    color: white;
    text-align: center;
    margin-top: -10px;
    margin-left: 40px;
    border-radius: 50px;
    background: #ffb411;
    font-size: 12px;
    div{
    margin-top: 4px;
    }
`;

export const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  background: #0D2466;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin-top: 10px;
  padding: 2px;

  border-radius: 10px;
  font-size: 17px;
  span{
  font-size: 8px;
  }
`;


export const ContainerMsg = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${props => props.color};
  background: white;
  box-shadow: 0px 2px 5px 1px #00000030;
  border-radius: 2px;
  height: 100%;
  margin: 5px;
  align-items: center;
  font-size: 11px;

  @media only screen and (min-height: 900px) {
   min-height: 50px;
  }


`;

export const BadagSincro = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 34px;
  color: white;
  align-items: center;
  background-color: #2c588f;
    border-radius: 10px 0px 0px 10px;
       text-align: center;

        justify-items: center;
        justify-content: center;
    div{
    font-style: italic;
    font-size: 13px;
    width: 100%;

    }
    span{
       font-size: 7px;
    }
`;
