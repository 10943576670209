import React from 'react'
import { CardMsg, SingleSwitch, BodySwitch } from '../../globalStyles'
import TagsInput from 'react-tagsinput'
import { IconPlavrasCheveColor } from '../../Icons/iconCustom'
import { inject, observer } from 'mobx-react'

const MonitorarPalavraChave = inject('ConfiguracoesMonitoramentoStores')(
  observer(({ ConfiguracoesMonitoramentoStores }) => {
    const {
      tags_importante,
      handleChange_importante,
      tag_importante,
      handleChangeInput_importante,
      palavra_importante,
      handleNotificacao,
      handleMonitorar,
      handleSom
    } = ConfiguracoesMonitoramentoStores
    const { monitoramento, notificacao, som } = palavra_importante

    return (
      <CardMsg
        height={'150px'}
        padding={'0px'}
        justifyContent={'space-between'}
        margin={'10px 0px 10px 0px'}
        cor="#d41a1a"
      >
        <div
          style={{
            display: 'flex',
            width: '63%',
            height: '100%',
            padding: '20px',
            borderRight: '1px solid #b5b5b5'
          }}
        >
          <div>
            <div>
              <IconPlavrasCheveColor />
              &nbsp;&nbsp; PALAVRAS-CHAVE IMPORTANTES
            </div>
            <TagsInput
              classNameRemove={'react-tagsinput-remove'}
              className="tagsinputblul red"
              inputProps={{ placeholder: 'Adicionar palavra' }}
              value={tags_importante}
              onChange={e => handleChange_importante(e)}
              inputValue={tag_importante}
              onChangeInput={e => handleChangeInput_importante(e)}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            padding: '15px',
            width: '37%',
            justifyContent: 'space-between'
          }}
        >
          <BodySwitch
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '40%',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SingleSwitch
              checked={monitoramento}
              onChange={() =>
                handleMonitorar('palavra_importante', !monitoramento)
              }
              value="monitorar"
              color="#50a476"
            />
            <div>MONITORAR</div>
          </BodySwitch>

          <BodySwitch
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <select
              value={som}
              onChange={e => handleSom('palavra_importante', e.target.value)}
              className="form-control"
            >
              <option>Nenhum</option>
              <option>ALERTA 1</option>
              <option>ALERTA 2</option>
              <option>ALERTA 3</option>
              <option>ALERTA 4</option>
              <option>ALERTA 5</option>
              <option>VOCAL MASCULINO</option>
              <option>VOCAL FEMININO</option>
            </select>
            <div>ALERTA SONORO</div>
          </BodySwitch>

          <BodySwitch
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SingleSwitch
              checked={notificacao}
              onChange={() =>
                handleNotificacao('palavra_importante', !notificacao)
              }
              value="notificacao"
              color="#50a476"
            />
            <div>NOTIFICAR POR EMAIL</div>
          </BodySwitch>
        </div>
      </CardMsg>
    )
  })
)

export default MonitorarPalavraChave
