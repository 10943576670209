import {observable, action} from "mobx";
import $ from "jquery";
import Api from "./Conexao/conexao";
import ConfiguracoesGeraisStores from "./ConfiguracoesGeraisStores";
import {AlertaError, AlertaSuccess} from "./Funcoes/FuncoesGerais";
import MenuStores from "./MenuStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

class ConfiguracoesGeraisModalEmpresaStores {
  @observable
  usuarios = [];
  @observable
  usuarios_id = [];
  @observable
  key = 0;
  @observable
  cnpj = "";
  @observable
  nome_fantasia = "";
  @observable
  phone = "";
  @observable
  razao_social = "";
  @observable
  usuarios_empresa = [];
  @observable
  adminValidation = false;
  @observable
  isActive = false;
  @observable
  senha = "";
  @observable
  validarExcluir = false;

  @action
  busca_usuarios = id => {
    this.usuarios_id = [];
    Api.get("get_empresa/" + id + "/")
      .then(response => {
        this.usuarios = response.data.usuarios;
        this.usuarios.forEach(item => {
          if ((this.usuarios_id.length === 0) | (this.usuarios_id.length > 0)) {
            this.usuarios_id.push(item.id);
          }
        });
        this.key = 1;
        this.key = 2;
      })
      .catch(error => {
      });
  };

  @action
  limpa_campos = () => {
    this.cnpj = "";
    this.nome_fantasia = "";
    this.phone = "";
    this.razao_social = "";
    this.usuarios_empresa = [];
  };

  @action
  onChange = evento => {
    const campo = evento.target.name || evento.target.id;
    this[campo] = evento.target.value;
    // this.usuarios_id = $('#usuarios_id').val();
  };

  @action
  busca_dados = (cnpj, nome_fantasia, phone, razao_social) => {
    if (!this.cnpj) this.cnpj = cnpj;
    if (!this.nome_fantasia) this.nome_fantasia = nome_fantasia;
    if (!this.phone) this.phone = phone;
    if (!this.razao_social) this.razao_social = razao_social;
  };

  @action
  excluir_empresa = () => {
    Api.post("cad_empresa/" + this.validarExcluir + "/delete/")
      .then(response => {
        AlertaSuccess("Empresa excluída com sucesso!");
        ConfiguracoesGeraisStores.consulta_api();
        MenuStores.consulta_api();
        this.key = 1;
        this.key = 2;
      })
      .catch(error => {
      })
      .finally(() => {
        this.validarExcluir = false;
      });
  };

  @action
  excluir_empresa_validacao_admin = () => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);
    Api.post("/login/", login, {})
      .then(response => {
        this.excluir_empresa();
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  adicionar_empresa = funcaoFecharModal => {
    const data = new FormData();
    this.usuarios_empresa.forEach(item => {
      data.append("usuarios[]", item);
    });
    data.append("razao_social", this.razao_social);
    data.set("nome_fantasia", this.nome_fantasia);
    data.set("cnpj", this.cnpj);
    data.set("phone", this.phone);

    Api.post("cad_empresa_nova/", data, {})
      .then(response => {
        ConfiguracoesGeraisStores.consulta_api();
        this.adminValidation = false;
        funcaoFecharModal();
        AlertaSuccess("Empresa adicionada com sucesso!");
        MenuStores.consulta_api();
      })
      .catch(error => {
        AlertaError(error.response.data.error);
      });
  };

  @action
  adicionar_empresa_validacao_admin = funcaoFecharModal => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);
    Api.post("/login/", login, {})
      .then(response => {
        this.adicionar_empresa(funcaoFecharModal);
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  salva_empresa = (id, funcaoFecharModal) => {
    const data = new FormData();
    this.usuarios_empresa.forEach(item => {
      data.append("usuarios[]", item);
    });
    data.append("razao_social", this.razao_social);
    data.set("nome_fantasia", this.nome_fantasia);
    data.set("cnpj", this.cnpj);
    data.set("phone", this.phone);
    Api.post("cad_empresa/" + id + "/", data, {})
      .then(response => {
        AlertaSuccess("Empresa editada com sucesso!");
        ConfiguracoesGeraisStores.consulta_api();
        MenuStores.consulta_api();
        this.adminValidation = false;
        funcaoFecharModal();
      })
      .catch(error => {
      });
  };

  @action
  editar_empresa_validacao_admin = (id, funcaoFecharModa) => {
    const email = MaquinaDeEstadosStore.getEmailUser();
    const login = new FormData();
    login.set("tipo", "login");
    login.set("username", email);
    login.set("password", this.senha);

    Api.post("/login/", login, {})
      .then(response => {
        this.salva_empresa(id, funcaoFecharModa);
      })
      .catch(error => {
        AlertaError("Senha invalida!");
      });
    this.senha = "";
  };

  @action
  validandoInputs = () => {
    this.adminValidation = false;
    this.usuarios_empresa = $("#usuarios_id").val();
    if (!this.adminValidation) {
      if (this.usuarios_empresa.length === 0) {
        AlertaError("Selecione um usuario!");
        return false;
      } else if (this.razao_social === "") {
        AlertaError("Campo razão social em branco!");
        return false;
      } else if (this.nome_fantasia === "") {
        AlertaError("Campo nome fantasia em branco!");
        return false;
      } else if (this.cnpj === "") {
        AlertaError("Campo CNPJ em branco!");
        return false;
      } else if (!this.cnpj.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)) {
        AlertaError("Campo de CNPJ inválido");
        return false;
      } else if (this.phone === "") {
        AlertaError("Campo de telefone em branco!");
        return false;
      }
      if (
        this.usuarios_empresa.length === 0 &&
        this.razao_social === "" &&
        this.nome_fantasia === "" &&
        this.cnpj === "" &&
        this.phone === ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  @action
  fecharModal = () => {
    this.adminValidation = false;
  };

  @action
  modal_validacao_senha_excluir = id => {
    if (MaquinaDeEstadosStore.getIdEmpresa() === Number(id))
      AlertaError(
        "Nào é possível excluir a empresa que está atualmente selecionada."
      );
    else this.validarExcluir = id;
  };

  @action
  modal_validacao_senha = fechar => {
    if (fechar) return (this.adminValidation = false);
    if (this.validandoInputs()) {
      this.usuarios_empresa = $("#usuarios_id").val();
      this.adminValidation = true;
    } else {
    }
  };

  @action limparVariaveis = () => {
    this.usuarios = [];
    this.usuarios_id = [];
    this.key = 0;
    this.cnpj = "";
    this.nome_fantasia = "";
    this.phone = "";
    this.razao_social = "";
    this.usuarios_empresa = [];
    this.adminValidation = false;
    this.isActive = false;
    this.senha = "";
    this.validarExcluir = false;
  }
}

const configuracoesGeraisModalEmpresaStores = new ConfiguracoesGeraisModalEmpresaStores();
export default configuracoesGeraisModalEmpresaStores;
