import React, { useRef, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { LogOut } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ModalBoby,
  ModalHead,
  ModalHeadTitle,
  ModalOverlay,
  MoldaContainer
} from "./sytleModal";

const Context = React.createContext();

export function ModalProvider({ children, height }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <div style={{ height: height || '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </div>
  );
}

export function ModalLateral({
  onClose,
  animation,
  handleModal,
  title,
  icon,
  doobleIcon,
  children,
  left,
  overflowVisible,
  ...props
}) {
  const modalNode = useContext(Context);
  return modalNode
    ? ReactDOM.createPortal(
      <ModalOverlay
        id="modal"
        className="modal-lateral"
          /* onClick={handleModal} */ onMouseDown={handleModal}
      >
        <MoldaContainer
          animation={animation}
          left={left}
          overflowVisible={overflowVisible}
          width={props.width}
        >
          <ModalHead {...props}>
            <LogOut
              className="icon-scale"
              color={"white"}
              onClick={onClose}
              style={{ alignSelf: left ? "flex-end" : "flex-start" }}
            />
            <ModalHeadTitle>
              <FontAwesomeIcon
                size={"2x"}
                icon={icon}
                style={{ marginBottom: "0.7rem" }}
              />
              {doobleIcon}
              <div>
                <h5>{title}</h5>
              </div>
            </ModalHeadTitle>
          </ModalHead>
          <ModalBoby style={{ display: "flex", flexDirection: "column" }}>
            {children}
          </ModalBoby>
        </MoldaContainer>
      </ModalOverlay>,
      document.getElementById("root")
    )
    : null;
}

export const closeModal = (setAnimation, setIsModalOpen) => {
  setAnimation(true);
  setTimeout(() => {
    setIsModalOpen(false);
    setAnimation(false);
  }, 850);
};

export const closeModalOutSide = (e, setAnimation, setIsModalOpen) => {
  const id = e.target.id;
  const wizard = Array.from(e.target.classList).includes('modal-lateral');
  if (wizard) return;

  if (id === "modal") {
    closeModal(setAnimation, setIsModalOpen);
  }
};
