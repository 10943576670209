import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle, Info } from "react-feather";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import PainelMensagens from "./PainelMensagens";
import { ExclamationWarning } from "../../Components/Notifications/ExclamationWarning";
import ModalFiltros from "./modalLateral/ModalFiltros";
import ModalConfirmacao from "../../Components/newModal/modalConfirmacao";
import { setLicitacaoId } from "../../Services/licitacao";
import LicitacaoStores from "../../Stores/LicitacaoStores";
import { HeaderChat } from "./chat";

import {
  FlagColor,
  TabelaContainer,
  PortalLogo,
  GavelContainer
} from "./styleMonitoramento";
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  TabButton,
  ContentBody,
  ReactTooltip
} from "../../globalStyles";
import {
  ListaLicitacoes,
  TabContainer,
  MessageList,
  AlertNovasMensagens,
  MessageListWrapper
} from "./styles";
import LogoComprasNet from "../../Images/compras_net.png";
import LogoBb from "../../Images/bb_logo.jpeg";
import LogoPuplica from "../../Images/compras_publica.jpeg";
import LogoBNC from "../../Images/logoBNC.png";
import LogoBLL from "../../Images/logoBLL.png";

@inject("MonitoramentoStore", "MenuStores", "MaquinaDeEstadosStore")
@observer
class Monitoramento extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectLicitacao: 0,
      status: false,
      portals: [],
      openModal: false,
      codeUASG: "",
      tradingFloor: "",
      loading: false
    };
  }

  componentDidMount() {
    const { consultaApi, flagConsulta } = this.props.MonitoramentoStore;
    const { setPortaisContratados } = this.props.MaquinaDeEstadosStore;
    this.portals = setPortaisContratados();
    flagConsulta && consultaApi();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleStatus = () => {
    this.setState({ status: !this.state.status });
  };

  statusCor = item => {
    if (item.numero_msg_nao_verificadas > 0 && item.contem_palavra_chave === 0)
      return "#41d641";
    if (
      item.numero_msg_nao_verificadas > 0 &&
      item.contem_palavra_chave === 999
    )
      return "#de3636";
    if (
      item.numero_msg_nao_verificadas === 0 &&
      item.contem_palavra_chave === 0
    )
      return "#bfbfbf";
  };

  handleSort = () => {
    const { radio_licita, licitacoes } = this.props.MonitoramentoStore;
    switch (radio_licita) {
      case "ocorrencias":
        return licitacoes.filter(
          lic => lic.contem_palavra_chave && lic.numero_msg_nao_verificadas
        );
      case "novas":
        return licitacoes.filter(lic => lic.numero_msg_nao_verificadas);
      default:
        return licitacoes;
    }
  };

  handleSelectLicitacao = id => {
    if (this.props.MonitoramentoStore.status) {
      this.props.MonitoramentoStore.selectLicitacao = id;
      this.props.MonitoramentoStore.consulta_especifica(id);
    }
  };

  handleListaEditais = () => {
    const {
      selectLicitacao,
      /* licitacoes, */
      fetchingLicitacoes
      /*       radio_licita */
    } = this.props.MonitoramentoStore;

    const handleLogo = portal => {
      switch (portal) {
        case 9:
          return LogoPuplica;
        case 5:
          return LogoComprasNet;
        case 6:
          return LogoBb;
        case 877:
          return LogoBLL;
        case 930:
          return LogoBNC;
        default:
          return;
      }
    };

    const handlePortalName = portal => {
      switch (portal) {
        case 9:
          return "Compras Públicas";
        case 5:
          return "ComprasNet";
        case 6:
          return "Licitações-e";
        case 877:
          return "Portal BLL";
        case 930:
          return "Portal BNC";
        default:
          return;
      }
    };

    if (fetchingLicitacoes)
      return (
        <div
          style={{
            display: "flex",
            height: "80vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div class='lds-ring'></div>
        </div>
      );

    return this.handleSort().map((item, i) => {
      const uasgTrim = (item.uasg || "").trim();
      const uasgNumber = parseInt(uasgTrim, 10);
      return (
        <TabelaContainer
          id={`monitoramento_licitacao_${i}`}
          key={item.id}
          color={this.statusCor(item)}
          background={selectLicitacao === item.id && "rgba(224,224,224,0.31)"}
          onClick={() => this.handleSelectLicitacao(item.id)}
        >
          {item.citou_empresa ? (
            <FlagColor
              background={this.statusCor(item)}
              blink={item.citou_empresa}
              data-tip='EMPRESA MENCIONADA'
              data-type='warning'
              data-effect='solid'
              data-place='right'
            />
          ) : (
            <FlagColor background={this.statusCor(item)} />
          )}

          <div
            title={item.codigo}
            style={{
              width: "80px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              boxSizing: "border-box",
              marginRight: "0.5rem"
            }}
          >
            {item.codigo || ""}
          </div>
          <div
            title={!isNaN(uasgNumber) && uasgNumber != 0 ? uasgTrim : ""}
            style={{
              width: "20%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              boxSizing: "border-box",
              marginRight: "0.5rem"
            }}
          >
            {!isNaN(uasgNumber) && uasgNumber != 0 ? uasgTrim : ""}
          </div>
          <div style={{ width: "50%" }}> {item.data_ultima_msg} </div>
          <div style={{ width: "10%" }}>
            <PortalLogo
              src={handleLogo(item.portal)}
              alt='portal-logo'
              data-place='right'
              data-type='success'
              data-tip={handlePortalName(item.portal)}
              data-effect='solid'
            />
          </div>
        </TabelaContainer>
      );
    });
  };

  handleOrdenacao = param => {
    const {
      sortBy,
      sortByParam,
      reverseLicitacoes
    } = this.props.MonitoramentoStore;
    if (sortBy === param) return reverseLicitacoes();
    return sortByParam(param);
  };

  handleRedirect = id => {
    setLicitacaoId(id);
    LicitacaoStores.licitacao_vista(id);
    LicitacaoStores.pagina_atual = "1";
    this.props.history.push("/licitacao");
  };

  closeModalToAddBidding = () => {
    this.setState({ openModal: false });
    this.setState({ codeUASG: "", tradingFloor: "" });
  };

  openModalToAddBidding = () => {
    this.setState({ openModal: true });
  };

  setCodeUASG = value => {
    this.setState({ codeUASG: value });
  };

  setTradingFloor = value => {
    this.setState({ tradingFloor: value });
  };

  saveBidding = async () => {
    this.setState({ loading: true });
    const res = await this.props.MonitoramentoStore.saveBidManually(
      this.state.codeUASG,
      this.state.tradingFloor
    );

    if (res) {
      this.setState({
        loading: false,
        openModal: false,
        tradingFloor: "",
        codeUASG: ""
      });
    }
  };

  render() {
    const {
      selectLicitacao,
      ordemDecrescente,
      marcarTodasMensagens,
      sortBy
    } = this.props.MonitoramentoStore;

    const {
      licitacoes,
      qtdNovasMensagens,
      atualizaAtual,
      muda_estado,
      radio_licita,
      marcar_msgs_como_verificadas
    } = this.props.MonitoramentoStore;
    const { licitacao, palavras } = this.props.MonitoramentoStore;

    const licitacaoSelecionada = licitacoes.find(
      li => li.id === selectLicitacao
    );
    const todasLicitacoesVerificadas = licitacoes.every(
      li => li.numero_msg_nao_verificadas === 0
    );
    const todasVerificadas = licitacaoSelecionada
      ? licitacaoSelecionada.numero_msg_nao_verificadas === 0
      : false;

    const {
      licitacao_codigo,
      comprador_nome,
      modalidade,
      estado,
      portal_nome,
      licitacao: id
    } = licitacao;

    return (
      <>
        <Dialog
          open={this.state.openModal}
          onClose={this.closeModalToAddBidding}
        >
          <DialogTitle>{"Adicionar licitação do ComprasNet"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              label='Número da Licitação'
              type='text'
              fullWidth
              variant='standard'
              onChange={e => this.setTradingFloor(e.target.value)}
              value={this.state.tradingFloor}
            />
            <TextField
              margin='dense'
              label='Código da UASG'
              type='text'
              fullWidth
              variant='standard'
              onChange={e => this.setCodeUASG(e.target.value)}
              value={this.state.codeUASG}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModalToAddBidding}>Fechar</Button>
            {this.state.loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={this.saveBidding}
                disabled={!this.state.tradingFloor || !this.state.codeUASG}
                autoFocus
              >
                Salvar
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <ReactTooltip />

        <ComponentHeader
          height='8vh'
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <TabContainer>
            <TabButton
              onClick={muda_estado}
              ul={radio_licita === "todas"}
              value='todas'
              smallFont
            >
              TODAS
            </TabButton>
            <TabButton
              onClick={muda_estado}
              value='novas'
              ul={radio_licita === "novas"}
              smallFont
            >
              NOVAS
            </TabButton>
            <TabButton
              onClick={muda_estado}
              value='ocorrencias'
              ul={radio_licita === "ocorrencias"}
              smallFont
            >
              COM OCORRÊNCIA
            </TabButton>
            <ModalConfirmacao
              title='VERIFICAR TODAS AS LICITAÇÕES'
              message='Tem certeza que deseja verificar todas as mensagens de TODAS AS LICITAÇÕES?'
              confirmAction={marcarTodasMensagens}
              disabled={todasLicitacoesVerificadas}
              button={
                <CheckCircle
                  color={todasLicitacoesVerificadas ? "#e0e0e0" : "#71bd68"}
                  className='icon-scale'
                  data-tip={
                    todasLicitacoesVerificadas
                      ? "TODAS MENSAGENS JÁ VERIFICADAS"
                      : "MARCAR TODAS AS MENSAGENS COMO LIDAS"
                  }
                  data-place='right'
                  data-effect='solid'
                  data-type={todasLicitacoesVerificadas ? "light" : "success"}
                />
              }
            />
          </TabContainer>
          <GavelContainer>
            <FontAwesomeIcon icon='gavel' id='gavel-monitoramento' />

            <b
              data-tip
              data-for='registerTip'
              id='gavel-title'
              onClick={() => this.handleRedirect(id)}
              className='icon-scale-xs'
            >
              {`LICITAÇÃO - ${licitacao_codigo || ""}`}
            </b>
            <ReactTooltip
              type='warning'
              id='registerTip'
              place='bottom'
              effect='solid'
            >
              IR PARA LICITAÇÃO
            </ReactTooltip>
            <Info
              style={{ width: "18px", marginLeft: "12px", cursor: "pointer" }}
              className='info-licitacao icon-scale-xs'
              data-tip
              data-for='info-licitacoes'
            />
            <ReactTooltip
              id='info-licitacoes'
              type='success'
              place='right'
              effect='solid'
            >
              <div>{`PORTAL: ${portal_nome || ""}`}</div>
              <div>{`COMPRADOR: ${comprador_nome || ""}`}</div>
              <div>{`MODALIDADE: ${modalidade || ""} - UF: ${estado ||
                ""}`}</div>
            </ReactTooltip>
          </GavelContainer>
        </ComponentHeader>
        <ComponentHeaderOverflow height='8vh' />
        <ContentBody
          bRadius
          style={{ height: "83.2vh", marginBottom: 0 }}
          padding='0.5rem 1rem 0.5rem'
        >
          <ListaLicitacoes>
            <div className='lista-header'>
              <div style={{ width: "80px" }}>
                <b>
                  NÚMERO
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === "edital"
                        ? "chevron-up"
                        : "chevron-down"
                    }
                    onClick={() => this.handleOrdenacao("edital")}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </b>
              </div>
              <div style={{ width: "20%" }}>
                <b>
                  UASG
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === "uasg"
                        ? "chevron-up"
                        : "chevron-down"
                    }
                    onClick={() => this.handleOrdenacao("uasg")}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </b>
              </div>
              <div style={{ width: "35%" }}>
                <b style={{ display: "flex", alignItems: "center" }}>
                  DATA{" "}
                  <FontAwesomeIcon
                    icon={
                      ordemDecrescente && sortBy === "data_ultima_msg"
                        ? "chevron-up"
                        : "chevron-down"
                    }
                    onClick={() => this.handleOrdenacao("data_ultima_msg")}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </b>
              </div>
              <ModalFiltros />
            </div>
            <div
              className='custom-scroll-blue'
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "0.8rem",
                overflow: "auto"
              }}
            >
              {this.handleListaEditais()}
            </div>
          </ListaLicitacoes>

          {/* PAINEL LATERAL  */}
          <MessageList>
            {licitacoes.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative"
                }}
              >
                <HeaderChat />

                {/* CONTENT BODY */}
                <MessageListWrapper>
                  {qtdNovasMensagens > 0 && (
                    <AlertNovasMensagens
                      onClick={atualizaAtual}
                      className='icon-scale-xs'
                    >{`${qtdNovasMensagens} novas mensagens.`}</AlertNovasMensagens>
                  )}
                  <PainelMensagens history={this.props.history} />
                </MessageListWrapper>
              </div>
            ) : (
              <ExclamationWarning
                title='Nenhuma licitação encontrada'
                width='100%'
              />
            )}
          </MessageList>
        </ContentBody>
      </>
    );
  }
}

export default Monitoramento;
