import React, { useEffect, useState } from 'react'
import 'react-infinite-calendar/styles.css' // only needs to be imported once
import { Calendar } from 'react-feather'
import { format, addDays, isAfter } from 'date-fns'

import { useFiltroEdital } from './context/FiltroEditaisContext'
import { DatePicker } from '../SubHeader/Filtros/DatePicker'
import { ToastWarning } from '../../../Stores/Funcoes/FuncoesGerais'

import {
  CardBody,
  CardFiltroData,
  CardHeard,
  CardTitulo,
  InputData
} from './styles'

const CardData = () => {
  const { onChangeDate } = useFiltroEdital()
  const [state, setState] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    const dataAtual = format(new Date(), 'dd/MM/yyyy')
    const dados = `${dataAtual} até ${dataAtual}`
    setState(dados)
  }, [])

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const handleData = e => {

    const dateMax = addDays(e.startDate._d, 5)

    // if (isAfter(e.endDate._d, dateMax)) {
    //   console.log("IS AFTER")
    //   return
    // }

    // setStartDate(e.startDate._d)
    // setEndDate(e.endDate._d)

    const data = {
      dmin: format(e.startDate._d, 'dd/MM/yyyy'),
      dmax: format(e.endDate._d, 'dd/MM/yyyy')
    }
    onChangeDate(data)
    const dados = `${data.dmin} até ${data.dmax}`
    setState(dados)
  }

  return (
    <CardFiltroData>
      <CardHeard>
        <Calendar />
        <CardTitulo>
          <div>FILTRO POR INTERVALO DE DATA</div>
          <p>Selecione o dia ou período que deseja pesquisar</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <InputData>{state}</InputData>
        <DatePicker
          handleSelect={e => handleData(e)}
          showing={true}
          startDate={startDate}
          endDate={endDate}
        />
      </CardBody>
    </CardFiltroData>
  )
}

export default CardData
