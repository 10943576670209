import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {
  NavegacaoContainer,
  SingleNavigationItem,
  SizeLicitacoes
} from '../../../Pages/Bids/styleLicitacoes'

export const checkPathname = path => path === window.location.pathname

export const MenuAcoesFunil = ({ licitacoes_total }) => {
  return (
    <>
      <NavegacaoContainer>
        <SingleNavigationItem active={checkPathname('/agenda')}>
          <Link to="/agenda">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="calendar-check"
              data-tip="AGENDA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/funil')}>
          <Link to="/funil">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="filter"
              data-tip="FUNIL"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/licitacoes')}>
          <Link to="/licitacoes">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="list"
              data-tip="LISTA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
        <SingleNavigationItem active={checkPathname('/licitacoes/excluir')}>
          <Link to="/licitacoes/excluir">
            <FontAwesomeIcon
              className="icon-scale icon-header"
              icon="trash"
              data-tip="LIXEIRA"
              data-effect="solid"
              data-type="warning"
              data-place="bottom"
            />
          </Link>
        </SingleNavigationItem>
      </NavegacaoContainer>

      <div className="d-flex">
        <SizeLicitacoes>
          <span className="contador-numero">{licitacoes_total}</span>
          <span className="contador-mensagem">Licitações</span>
        </SizeLicitacoes>
      </div>
    </>
  )
}
