import React, { useContext } from 'react'

import TabelaItens from './tabela/TabelaItens'
import { PropostaEspecificaContext } from '../../context'
import { DadosPdfContext } from '../dadosPdf/context'
import { DocumentosContext } from '../enviarDocumentos/context'

import * as S from './styles'

const ConfirmarProposta = () => {
  const { edital } = useContext(PropostaEspecificaContext)
  const dadosPdf = useContext(DadosPdfContext)

  const {
    descricao,
    comprador: { nome }
  } = edital

  const { docsProposta, docsHabilitacao } = useContext(DocumentosContext)

  let docs = [...docsProposta]
  if (dadosPdf.pdf) docs = [...docsProposta, dadosPdf.pdf]

  return (
    <S.Container>
      <S.SectionTitle>DADOS DA LICITAÇÃO</S.SectionTitle>
      <S.SectionContainer padding="15px">
        <b>{nome}</b>
        <p>{descricao}</p>
      </S.SectionContainer>
      <S.SectionTitle>DADOS DA EMPRESA</S.SectionTitle>
      <S.SectionContainer padding="15px">
        <div className="company">
          <img
            src={
              dadosPdf.logo
                ? dadosPdf.logo
                : '/static/Imagens/logo_wavecode.png'
            }
            alt="company-logo"
          />
          <div className="info">
            <span className="title">{dadosPdf.razaoSocial}</span>
            {!!dadosPdf.rua && (
              <p>{`${dadosPdf.rua}, ${dadosPdf.numero}${
                dadosPdf.complemento ? `, ${dadosPdf.complemento} ` : ' '
              }- ${dadosPdf.bairro}, ${dadosPdf.cidade} - ${dadosPdf.uf}, ${
                dadosPdf.cep
              }`}</p>
            )}
            <p>
              Email: {dadosPdf.email}&emsp;|&emsp;Site: {dadosPdf.site}
              &emsp;&emsp;&emsp;&emsp;{dadosPdf.telefone}
              {`${dadosPdf.celular ? ` | ${dadosPdf.celular}` : ''}`}
            </p>
            <div className="d-flex">
              <p>CNPJ: {dadosPdf.cnpj}</p>
              {dadosPdf.inscEstadual && (
                <p>&emsp;|&emsp;IE: {dadosPdf.inscEstadual}</p>
              )}
              {dadosPdf.inscMunicipal && (
                <p>&emsp;|&emsp;IM: {dadosPdf.inscMunicipal}</p>
              )}
            </div>
          </div>
        </div>
      </S.SectionContainer>
      <S.SectionTitle>ITENS DA PROPOSTA</S.SectionTitle>
      <S.SectionContainer padding="15px">
        <TabelaItens />
      </S.SectionContainer>
      <div className="d-flex">
        <div
          style={{
            width: '50%',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <S.SectionTitle>DOCUMENTOS DA PROPOSTA</S.SectionTitle>
          <S.SectionContainer padding="15px">
            {docs.map(doc => (
              <S.DocumentContainer key={doc.name}>
                {doc.name}
              </S.DocumentContainer>
            ))}
          </S.SectionContainer>
        </div>
        {!!docsHabilitacao.length && (
          <div
            style={{
              width: '50%',
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <S.SectionTitle>DOCUMENTOS DA HABILITAÇÃO</S.SectionTitle>
            <S.SectionContainer padding="15px">
              {docsHabilitacao.map(doc => (
                <S.DocumentContainer key={doc.name}>
                  {doc.name}
                </S.DocumentContainer>
              ))}
            </S.SectionContainer>
          </div>
        )}
      </div>
    </S.Container>
  )
}

export default ConfirmarProposta
