import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { ConfiguracaoEditaisContext } from '../stores/store'
import { Check, Edit, Trash } from 'react-feather'
import { InputPerfilName } from '../styles'
import { AlertaError } from '../../../Stores/Funcoes/FuncoesGerais'
import ModalConfirmacao from '../../../Components/newModal/modalConfirmacao'

const Nome = observer(() => {
  const context = useContext(ConfiguracaoEditaisContext)
  const [nome, setNome] = useState(context.nomePerfil)
  const [editing, isEditing] = useState(false)

  useEffect(() => {
    setNome(context.nomePerfil)
  }, [context.nomePerfil])

  const atualizarNome = e => {
    e.preventDefault()
    // Validação
    if (!nome) return AlertaError('Preencha o nome do perfil')
    if (nome.length > 15)
      return AlertaError('O número máximo de caracteres é 15!')
    context.changeNomePerfil(nome)
    isEditing(false)
  }
  if (editing)
    return (
      <div style={divStyle}>
        <form onSubmit={atualizarNome}>
          <InputPerfilName
            id="name-perfi"
            type="text"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
          <Check
            onClick={atualizarNome}
            style={confirmButton}
            className="icon-scale-xs"
            data-tip="CONFIRMAR"
            data-place="bottom"
            data-type="success"
            data-effect="solid"
          />
        </form>
      </div>
    )
  return (
    <div style={divStyle}>
      {context.nomePerfil}

      <Edit
        onClick={() => isEditing(true)}
        style={editButton}
        className="icon-scale-xs"
        data-tip="EDITAR NOME"
        data-place="bottom"
        data-type="warning"
        data-effect="solid"
      />
      {context.id && (
        <ModalConfirmacao
          button={
            <Trash
              style={deleteButton}
              className="icon-scale-xs"
              data-tip="DELETAR PERFIL"
              data-place="bottom"
              data-type="error"
              data-effect="solid"
            />
          }
          width="700px"
          title="EXCLUIR PERFIL"
          message={
            <span
              style={{ textShadow: 'none' }}
            >{`Tem certeza que deseja excluir o perfil ${context.nomePerfil}?`}</span>
          }
          confirmAction={context.deletarPerfil}
        />
      )}
    </div>
  )
})

export default Nome

const divStyle = {
  textAlign: 'center',
  fontSize: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  marginBottom: '10px',
  height: '50px',
  borderRadius: '10px',
  // boxShadow: "1px 1px 6px 0.1px #171717",
  // background: "#e8e8e8",
  // color: "#3a566e",
  backgroundColor: '#5a7d9c',
  color: '#f0f8ff',
  boxShadow: '#a0a0a0 1px 1px 6px 0.1px',
  textShadow: '1px 1px 2px #0e0e0e'
}

const editButton = {
  marginLeft: '10px'
}

const deleteButton = {
  marginLeft: '10px',
  color: '#ff8593',
  marginBottom: '6px'
}

const confirmButton = {
  marginLeft: '10px',
  width: '35px',
  height: '35px'
}
