import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import * as S from '../../globalStyles'

const linksSoftwaresComplementares = [
  {
    logo:
      'https://wavecode-plataforma-img.s3.sa-east-1.amazonaws.com/anydesk_icon.png',
    title: 'AnyDesk',
    subtitle: 'Software que utilizamos diariamente para suporte remoto.',
    link: 'https://anydesk.com/pt/downloads/'
  },
  {
    logo:
      'https://wavecode-plataforma-img.s3.sa-east-1.amazonaws.com/java_ico.png',
    title: 'Java Atualizado',
    subtitle:
      'Versão atualizada para que nossas soluções tenham desempenho total.',
    link: 'https://www.java.com/pt_BR/download/'
  },
  {
    logo:
      'https://wavecode-plataforma-img.s3.sa-east-1.amazonaws.com/icone_lances_comprasnet.png',
    title: 'Lance Automático',
    subtitle: 'Central de lances automáticos',
    link:
      'https://wavecode.com.br/wavecode.zip'
  },
  {
    logo:
      'https://wavecode-plataforma-img.s3.sa-east-1.amazonaws.com/mozilla_icon.png',
    title: 'Mozilla Firefox',
    subtitle: 'Necessário para o sistema de automação do Licitações-E.',
    link:
      'https://www.mozilla.org/pt-BR/firefox/download/thanks/'
  },
]

function DownloadLinks() {
  return (
      <div style={{ height: '50%' }}>
        <div
          style={{
            padding: '20px',
            borderTop: ' 1px solid #eeeeee',
            color: 'rgb(63, 63, 63)',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h2">
            SOFTWARES COMPLEMENTARES
          </Typography>
        </div>
        <S.ComponentHeader
          height="6vh"
          style={{
            justifyContent: 'space-between',
            backgroundColor: 'rgb(79, 115, 145)',
            marginBottom: '10%'
          }}
        />

        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          {linksSoftwaresComplementares.map(elem => (
            <SimpleCard {...elem} />
          ))}
        </div>
      </div>
  )
}

export default DownloadLinks

const useStyles = makeStyles({
  root: {
    maxWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

function SimpleCard({ logo, title, subtitle, link }) {
  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  return (
    <Card className={classes.root}>
      <CardContent>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
        >
          <img src={logo} alt width="20%" />
          &nbsp;&nbsp;
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        </div>

        <Typography className={classes.pos} color="textSecondary">
          {subtitle}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <a href={link} target="_blank">
          <Button size="small">LINK PARA DOWNLOAD</Button>
        </a>
      </CardActions>
    </Card>
  )
}
