import React from 'react';
import CadastrarPropostaProvider from '../CadastrarProposta/context';
import PropostaEspecificaProvider from '../CadastrarProposta/PropostaEspecifica/context';
import ListaDeLicitacoesProvider from '../CadastrarProposta/ListaDeLicitacoes/context';
import FluxoDeEnvioProvider from '../CadastrarProposta/PropostaEspecifica/fluxoDeEnvio/context';
import DadosPdfProvider from '../CadastrarProposta/PropostaEspecifica/fluxoDeEnvio/dadosPdf/context';
import DocumentosProvider from '../CadastrarProposta/PropostaEspecifica/fluxoDeEnvio/enviarDocumentos/context';



const PropostaProviders = ({ children }) => (
  <CadastrarPropostaProvider>
    <PropostaEspecificaProvider>
      <ListaDeLicitacoesProvider>
        <FluxoDeEnvioProvider>
          <DadosPdfProvider>
            <DocumentosProvider>
              {children}
            </DocumentosProvider>
          </DadosPdfProvider>
        </FluxoDeEnvioProvider>
      </ListaDeLicitacoesProvider>
    </PropostaEspecificaProvider>
  </CadastrarPropostaProvider>
)

export default PropostaProviders;