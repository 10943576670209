import React from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Code, Hexagon } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const FiltroPregao = () => {
  const { onChangePregao, onChangePregaoUASG, state } = useFiltroEdital()
  return (
    <CardFiltroSmall>
      <CardHeard>
        <Code />
        <CardTitulo>
          <div>PREGÃO</div>
          <p>Filtrar por nome ou código do pregão</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <TextField
          id="standard-multiline-flexible"
          label="Código"
          variant="outlined"
          required
          margin="normal"
          // placeholder="Código do pregão"
          value={state.pregao}
          fullWidth
          onChange={(e) => onChangePregao(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Code />
              </InputAdornment>
            ),
          }}
        >
        </TextField>
        <TextField
          id="standard-multiline-flexible"
          label="UASG"
          variant="outlined"
          required
          margin="normal"
          // placeholder="Código do pregão"
          value={state.pregaoUASG}
          fullWidth
          onChange={(e) => onChangePregaoUASG(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Hexagon />
              </InputAdornment>
            ),
          }}
        >
        </TextField>

      </CardBody>
    </CardFiltroSmall>
  )
}

export default FiltroPregao