import React from 'react';
import { InputValorUnitario } from "./styles";

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    }
  }
  componentDidMount() {
    const a = document.getElementById("input-valor-unitario-tabela-itens-proposta");
    if (a) {
      let value = this.state.value.toString().replace(/\./g, '');
      a.focus();
      this.setState({ value: value.slice(0, -1) })
    }

  }
  getValue() {
    const { desconto, valor_unit } = this.props.rowData;
    if (desconto !== null && desconto !== undefined)
      return { valor_unit }
    return { valor_unit: this.state.value }
  }

  handleChange = (e) => {
    let { value } = e.target;
    const lastInput = value.slice(-1);

    if (isNaN(lastInput) && lastInput !== ',') value = value.slice(0, -1);

    this.setState({ value })
  }

  getInputNode() {
    // If applicable, should return back the primary html input node that is used to edit the data
    // Otherwise return null
    // If value is an input node, then this node will be focussed on when the editor opens
  }

  disableContainerStyles() {
    // Optional method
    // If set to true, the EditorContainer will not apply default styling to the editor
  }

  render() {
    if (this.props.rowData.desconto === null || this.props.rowData.desconto === undefined)
      return (<InputValorUnitario
        id="input-valor-unitario-tabela-itens-proposta"
        type="text"
        onChange={this.handleChange}
        value={this.state.value}
      />)
    return null
  }
}

export default CustomEditor;
