import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'

import { columns } from './Column'
import { sortEditais } from '../Components/sortEditais'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

import * as S from './Style'

const TabelaBoletim = ({
  toggleModal,
  crescente,
  editais,
  total_paginas,
  page,
  handlePagina,
  totalEditaisProcurados,
}) => {
  const { state } = useFiltroEdital()

  return (
    <>
      {editais.length ? ( 
        <>
          <DataTable
            className="tabela-padrao"
            fixedHeader
            allowOverflow={true}
            overflowY={true}
            noHeader
            responsive={true}
            columns={columns}
            onSort={(c, d) => sortEditais(c, d, state)}
            onRowClicked={edital => toggleModal(edital.id, state)}
            defaultSortAsc={crescente}
            sortServer={true}
            data={editais}
            striped={true}
            highlightOnHover
            noDataComponent={
              <S.NoticeContainerThatHasNoNotice>
                <ExclamationWarning
                  title="NENHUM EDITAL ENCONTRADO"
                  height="70vh"
                />
              </S.NoticeContainerThatHasNoNotice>
            }
          />
          <S.ContainerPagination>
            <div>
              <Pagination
                count={total_paginas}
                color="primary"
                shape="rounded"
                page={page}
                onChange={(event, value) => handlePagina(event, value, state)}
                showFirstButton
                showLastButton
              />
            </div>
          </S.ContainerPagination>
        </>) : (
          <LinkConfigBoletim totalEditaisProcurados={totalEditaisProcurados} />
        )}
    </>
  )
}

const LinkConfigBoletim = ({ totalEditaisProcurados }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
        zIndex: '10000000'
      }}
    >
      <span style={{ color: '#3C5870' }}>
        <b>
          Procuramos em {totalEditaisProcurados} editais encontrados no dia e
          não identificamos oportunidades de acordo com as
        </b>
      </span>
      &nbsp;
      <b>
        <Link
          style={{ textDecoration: 'underline' }}
          to="/prospeccao/configuracoes"
        >
          configurações
        </Link>
      </b>
      .
    </div>
  )
}

export default TabelaBoletim
