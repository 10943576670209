import React from "react";

export const MessageCount = ({ total, notVerified }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#e4e4e4",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <div style={{ fontSize: "1rem" }}>{total}</div>
        <div style={{ fontSize: "0.65rem" }}>MENSAGENS</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#00c43f",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <div style={{ fontSize: "1rem" }}>{notVerified}</div>
        <div style={{ fontSize: "0.65rem" }}>NÃO VERIFICADAS</div>
      </div>
    </>
  );
};
