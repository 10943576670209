import { observable, action } from "mobx";
import Api from "../../../Stores/Conexao/conexao";
import maquinadeestadosstore from "../../../Stores/MaquinaDeEstadosStore";

class gerenciarPropostaStore {
  @observable propostas = [];
  @observable total_paginas = 0;
  // TEST DUMMY DATA
  // [
  //   {
  //     proposta: 1,
  //     licitacao: 23123,
  //     orgao: 'Universidade Federal do Maranhão',
  //     disputa: "2019-10-18T02:00:00",
  //     status: 'ENVIADA',
  //     data_envio: "2019-10-18T02:00:00",
  //     id: 232
  //   },
  //   {
  //     proposta: 1,
  //     licitacao: 23123,
  //     orgao: 'Universidade Federal do Maranhão',
  //     disputa: "2019-10-18T02:00:00",
  //     status: 'NÃO ENVIADA',
  //     data_envio: "2020-06-26T08:30:00",
  //     id: 233
  //   },
  //   {
  //     proposta: 1,
  //     licitacao: 23123,
  //     orgao: 'Universidade Federal do Maranhão',
  //     disputa: "2019-10-18T02:00:00",
  //     status: 'ENVIADA',
  //     data_envio: "2019-10-18T02:00:00",
  //     id: 234
  //   }
  // ]


  @observable pagination = {
    page: 1,
    changePage: (page) => this.pagination.page = page
  }

  @action fetchPropostas = async () => {

    try {
      const res = await Api.get(`/portal/propostas/?status=todos&fornecedor=${maquinadeestadosstore.getIdEmpresa()}&pagina=${this.pagination.page}`);
      const { total_registros, editais } = res.data;
      this.total_registros = total_registros;
      this.total_paginas = Math.ceil(total_registros / 10);
      this.propostas = this.tratarDados(editais);
    }
    catch (err) { }
  }

  tratarDados = (editais) => {
    return editais.map(edital => {
      const { proposta: { updated_at, status, id: idProposta }, numero, comprador: { nome, codigo }, data_disputa, id } = edital;
      return {
        proposta: idProposta,
        licitacao: numero,
        orgao: nome,
        disputa: data_disputa,
        status,
        data_envio: updated_at,
        id,
        uasg: codigo
      }
    })
  }


}

const GerenciarPropostaStore = new gerenciarPropostaStore();

export default GerenciarPropostaStore;
