import axios from 'axios'
import '../../Debug'
import statusConexaoStores from '../StatusConexaoStores'

export const Api = axios.create({
  baseURL: global.url,
  contentType: 'application/x-www-form-urlencoded',
})

Api.interceptors.response.use(
  function (res) {
    return res
  },
  function (err) {
    if (err.response && err.response.status === 401) {
      window.location.pathname = '/'
      localStorage.removeItem('token')
    }
    return Promise.reject(err)
  }
)

export default Api

export const defaultsHeadersAxios = token => {
  Api.defaults.headers.common['Authorization'] = token
}
