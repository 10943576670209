import React, { Component } from 'react'
import Moment from 'moment'
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  DataAtual,
  FormatDateHora
} from '../../Stores/Funcoes/FuncoesGerais'
import { CardPainelLicitacoes } from './CardPainelLicitacoes'
import { Airplay, ZoomIn, ZoomOut } from 'react-feather'
import { DateAndTime } from '../OccurrencesPanel/Header/DateAndTime'
import { ExclamationWarning } from '../../Components/Notifications/ExclamationWarning'

import {
  ComponentHeader,
  ComponentHeaderOverflow,
  ContentBody,
  ReactTooltip
} from '../../globalStyles'

@inject('PainelAvisoStores', 'MenuStores', 'MaquinaDeEstadosStore')
@observer
class PainelMonitoramento extends Component {
  constructor(props) {
    super(props)
    this.state = {
      zoom: 0,
      icon: true,
      timeNow: this.timeNow(),
      empresa: '',
      som: false,
      sizeLicitacoes: 0,
      licitacoes: [],
      barraProgresso: 0,
      blink: false
    }
  }

  compareArrays = () => {
    let array1 = this.props.PainelAvisoStores.licitacoes
    let array2 = this.state.licitacoes
    return (
      array1.every(e => array2.includes(e)) &&
      array2.every(e => array1.includes(e))
    )
  }

  componentDidMount() {
    const { consulta_api_toda_licitacoes } = this.props.PainelAvisoStores
    const { id_fornecedor } = this.props.MaquinaDeEstadosStore
    if (id_fornecedor) consulta_api_toda_licitacoes()

    this.inicializaArrayLicitacoes()

    setInterval(() => {
      this.setState({ timeNow: this.timeNow(), blink: !this.state.blink })
    }, 1000)
  }

  inicializaArrayLicitacoes() {
    setTimeout(() => {
      this.setState({ licitacoes: this.props.PainelAvisoStores.licitacoes })
    }, 2000)
  }

  handleTela = e => {
    this.props.MenuStores.handleTela()
  }

  timeNow = () => {
    return Moment().format('LTS')
  }

  hangleZoomIncremente = () => {
    if (this.state.zoom !== 100) {
      this.setState({ zoom: this.state.zoom + 5 })
    }
  }

  hangleZoomDecremente = () => {
    if (this.state.zoom !== 0) {
      this.setState({ zoom: this.state.zoom - 5 })
    }
  }

  handleData = data => {
    return FormatDateHora(data)
  }

  render() {
    const {
      licitacoes,
      consulta_api,
      som,
      handleSom
    } = this.props.PainelAvisoStores
    const { empresas, handleTela, fullScreen } = this.props.MenuStores
    const { id_fornecedor } = this.props.MaquinaDeEstadosStore
    const { blink } = this.state

    return (
      <>
        <audio id="avisos">
          <source src="../static/audio/avisos.mp3" type="audio/mpeg" />
        </audio>

        <ReactTooltip />
        <ComponentHeader
          height="8vh"
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 0
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              padding: '0.8rem'
            }}
          >
            {id_fornecedor && (
              <MenuFiltroSituacao
                idEmpresa={id_fornecedor}
                empresas={empresas}
                consulta_api={consulta_api}
              />
            )}

            <DateAndTime date={DataAtual} time={this.timeNow} blink={blink} />
          </div>
          <Usability
            fullScreen={fullScreen}
            som={som}
            handleSom={handleSom}
            handleTela={handleTela}
            hangleZoomIncremente={() => this.hangleZoomIncremente()}
            hangleZoomDecremente={() => this.hangleZoomDecremente()}
          />
        </ComponentHeader>
        <ComponentHeaderOverflow height="8vh" />
        <ContentBody
          bRadius
          style={{
            height: fullScreen ? '90vh' : '83vh',
            marginBottom: 0
          }}
        >
          {licitacoes.length > 0 ? (
            <CardPainelLicitacoes
              licitacoes={licitacoes}
              fontSize={this.state.zoom}
            />
          ) : (
            <ExclamationWarning
              title="Nenhuma licitação encontrada"
              height={fullScreen ? '86.6vh' : '79.6vh'}
            />
          )}
        </ContentBody>
      </>
    )
  }
}

export default PainelMonitoramento

export const Usability = ({
  handleTela,
  hangleZoomIncremente,
  hangleZoomDecremente,
  som,
  handleSom,
  fullScreen
}) => {
  return (
    <div
      style={{
        display: 'flex',
        color: '#fff',
        marginLeft: 'auto',
        alignItems: 'center',
        width: '160px',
        justifyContent: 'space-between'
      }}
    >
      <ReactTooltip />
      <div className="d-flex justify-content-between" style={{ width: '55%' }}>
        <ZoomIn
          size={34}
          data-tip="AUMENTAR FONTE"
          data-type="info"
          data-place="bottom"
          data-effect="solid"
          className="icon-scale"
          onClick={hangleZoomIncremente}
          id="zoom-increase-painel-licitacao"
        />
        <ZoomOut
          size={34}
          data-tip="DIMINUIR FONTE"
          data-type="info"
          data-place="bottom"
          data-effect="solid"
          className="icon-scale"
          id="zoom-decrease-painel-licitacao"
          onClick={hangleZoomDecremente}
        />
      </div>

      <Airplay
        style={{ margin: '10px' }}
        size={35}
        data-tip="TELA CHEIA"
        data-type="warning"
        data-place="bottom"
        data-effect="solid"
        className="icon-scale"
        onClick={handleTela}
        id="fullscreen-painel-licitacao"
      />
      {/* {fullScreen && (
        <div
          onClick={() => handleSom()}
          style={{ fontSize: "24px", width: "40px" }}
          data-tip={som ? "DESATIVAR SOM" : "ATIVAR SOM"}
          data-type="info"
          data-place="bottom"
          data-effect="solid"
          className="icon-scale"
        >
          <FontAwesomeIcon
            id="bell"
            style={{ color: som ? "#ffd311" : "white" }}
            icon={som ? "bell" : "bell-slash"}
          />
        </div>
      )} */}
    </div>
  )
}

export const MenuFiltroSituacao = ({ empresas, consulta_api, idEmpresa }) => {
  return (
    <div style={{ width: '25%' }}>
      <ReactTooltip />
      <FormGroup style={{ margin: 0 }}>
        <InputGroup>
          <InputGroup.Addon
            className="text-center"
            style={{
              border: '1px solid #B5B5B5',
              backgroundColor: '#f5f5f5',
              width: '50px',
              height: '38px',
              marginRight: '-8px',
              borderRadius: '5px',
              paddingTop: '6px'
            }}
          >
            <FontAwesomeIcon
              style={{ marginLeft: '-5px', color: '#363636' }}
              icon="filter"
            />
          </InputGroup.Addon>
          <FormControl
            name="selected"
            id="sel-empresa-painel-licitacao"
            onChange={e => consulta_api(e.target.value)}
            componentClass="select"
            defaultValue={idEmpresa}
          >
            {empresas.map(({ id, nome_fantasia }) => {
              return (
                <option key={id} value={id}>
                  {nome_fantasia}
                </option>
              )
            })}
            <option value={'todas'}>TODAS AS EMPRESAS</option>
          </FormControl>
        </InputGroup>
      </FormGroup>
    </div>
  )
}
