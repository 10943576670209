
export const checkNavigatorVersion = (() => {
  navigator.sayswho = (function () {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  })();

  const regex = /[0-9]/g

  if (navigator.sayswho.includes('Firefox')) {
    if (Number(navigator.sayswho.substr(navigator.sayswho.search(regex), navigator.sayswho.length - 1)) < 70) {
      return true;
    }
  }
  
  if (navigator.sayswho.includes('Chrome')) {
    if (Number(navigator.sayswho.substr(navigator.sayswho.search(regex), navigator.sayswho.length - 1)) < 60) {
      return true;
    }
  }
  return false;
})();

export const checkOS = () => {
  let OSName = "Sistema Operacional Desconhecido";
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  return OSName;
}
// const VersaoAntiga = () => (
//   <div style={{ margin: '10px' }}>
//     <h6>VERSÃO ANTIGA</h6>
//     <span style={{ fontSize: '13px', color: '#bbbbbb' }}>É necessário atualizar seu navegador para usufruir de todas as funcionalidades do sistema.</span>
//   </div>
// )
