import React, { useContext, useState } from 'react'
import { Layers } from 'react-feather';
import { PropostaEspecificaContext } from '../../PropostaEspecifica/context';
import Info from './info/Info';
import Itens from './itens/Itens';
import * as S from './styles';

const DetahesEdital = () => {
  const licitacao = 'licitacao';
  const itens = 'itens';

  const [tab, setTab] = useState(licitacao);

  const { edital: { numero } } = useContext(PropostaEspecificaContext);

  return (
    <S.Container>
      <S.TabContainer>
        <S.Tab
          active={tab === licitacao}
          onClick={() => setTab(licitacao)}
        >
          LICITAÇÃO
        </S.Tab>
        <S.Tab
          active={tab === itens}
          onClick={() => setTab(itens)}
        >
          ITENS
        </S.Tab>
      </S.TabContainer>
      <S.Header>
        <S.Wrapper>
          <div className="icon">
            <Layers color="#fff" />
          </div>
          <div className="title">
            LICITAÇÃO <b>{numero}</b>
          </div>
        </S.Wrapper>

      </S.Header>

      <S.Divider />

      {tab === licitacao && <Info />}
      {tab === itens && <Itens />}
    </S.Container>
  )
}

export default DetahesEdital
