import React from 'react';
import { inject, observer } from 'mobx-react';
import { WrapperDownload } from "../style/style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonDownload = inject("DownloadEditaisStore")(observer(({ DownloadEditaisStore, id }) => {
    const { listaSelecionados, handleSelection } = DownloadEditaisStore;
    const selected = listaSelecionados.includes(id);

    return (
        <WrapperDownload state={selected} onClick={() => handleSelection(id)}>
            <FontAwesomeIcon icon="cloud-download-alt" size={'2x'} />
        </WrapperDownload>
    );
}));

export default ButtonDownload;
