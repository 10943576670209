import React, { useContext, useState, useEffect } from 'react'
import { Bookmark, ThumbsDown } from 'react-feather'

import RoundBadge from '../../../Components/ReusableComponents/Badeg/badegRedondo'
import { ModalContext } from './context/modal'
import { useStatusEdital } from './context'
import { AlertaVermelho } from '../../../Stores/Funcoes/FuncoesGerais'

const ButtonsApproveDisqualify = ({ edital, status }) => {
  const { setEdital, statusDirectEdict } = useStatusEdital()
  const { openModalAprovar, openModalReprovar } = useContext(ModalContext)

  const showModalApprove = () => {
    setEdital(edital)
    openModalAprovar()
  }

  const showModalFail = () => {
    setEdital(edital)
    openModalReprovar()
  }

  const fail = () => {
    statusDirectEdict(edital, 'reprovado', edital)
  }

  const alert = () => {
    AlertaVermelho('Aprovação desativada, veja suas configurações!')
  }

  const aprove = () => {
    statusDirectEdict(edital, 'aprovado', edital)
  }

  return (
    <>
      <RoundBadge
        icon={<Bookmark />}
        background={edital.status === 'aprovado' ? '#2076a0' : '#a3bad2'}
        onClick={
          status.aprovado && !status.aprovado[0]
            ? alert
            : status.aprovado[1] || status.aprovado[2]
            ? showModalApprove
            : aprove
        }
      />
      <RoundBadge
        icon={<ThumbsDown />}
        background={edital.status === 'reprovado' ? '#ec4242' : '#ecc5ae'}
        onClick={status.reprovado && status.reprovado[1] ? showModalFail : fail}
      />
    </>
  )
}

export default ButtonsApproveDisqualify
