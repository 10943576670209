import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 20px 30px;
  background: transparent;
  overflow: auto;
  animation: 1s fadeIn ease-in-out;
`

const StatusContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  div {
    margin: 0 20px;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    width: 210px;
    color: #737373;
  }

  .status {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    width: 275px;
  }
`

const Status = styled.div`
  color: grey;
`

const ProgressBar = styled.div`
  background: green;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 600px);
  height: 30px;
  margin: auto !important;
  border-radius: 12px;
  /* background: #7fb234; */
  box-shadow: 1px 1px 4px #00000075;
  color: white;
  transition: 1s;
  background: ${props =>
    `linear-gradient(to right, #7fb234 0%, #7fb234 ${props.progress}%, #86868638 ${props.progress}%, #868686 100%)`};
`

const PassoContainer = styled.div`
  display: flex;
  /* border: ${props => `1px solid ${props.color}`}; */
  padding: 20px;
  border-radius: 10px;
  margin: 5px 4px;
  background: white;
  opacity: ${props => (props.active ? '1' : '0.5')};
  box-shadow: ${props => `1px 1px 7px ${props.color}8f`};

  .identifier {
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: center;
    border-right: ${props => `1px solid ${props.color}`};
    padding-right: 15px;

    svg {
      color: ${props => props.color};
      width: 32px;
      height: 32px;
      margin-bottom: 7px;
    }

    .title {
      color: ${props => props.color};
      font-weight: 600;
      font-size: 14px;
    }
    .subtitle {
      color: grey;
      font-size: 11px;
      white-space: nowrap;
      animation: 2s blink ease-in-out infinite;

      @keyframes blink {
        0% {
          color: transparent;
        }
        50% {
          color: grey;
        }
        100% {
          color: transparent;
        }
      }
    }
  }

  .files {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    width: calc(100% - 350px);
    border-right: ${props => `1px solid ${props.color}`};
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;

    svg {
      width: 40px;
      height: 40px;      
    }
    svg.done {
      color: #78a734;
    }

    svg.spinner1 {
      color: #999999;
      width: 38px;
      height: 38px;  
    }

    .circle {
      border-radius: 50%;
      border: ${props => `3px solid ${props.color}`};
      width: 35px;
      height: 35px;
    }
  }
`

const SpinnerGeral = styled.div`
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #21191933;
  border-right: 0.5em solid #21191933;
  border-bottom: 0.5em solid #21191933;
  /* border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2); */
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const EnvioCompleto = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: 700ms fadeIn forwards;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px #00000066;
  margin: 5px;

  .wrapper {
    background: #78a734;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px #000000ba;

    svg {
      width: 50px;
      height: 50px;
      color: #fff;
    }
  }

  .title {
    font-weight: 700;
    margin-top: 15px;
    font-size: 21px;
    width: 257px;
    text-align: center;
    color: #6f6f6f;
  }
`

const SendError = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: 700ms fadeIn forwards;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px #00000066;
  margin: 5px;

  .wrapper {
    background: #c1070a;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 3px #000000ba;

    svg {
      width: 50px;
      height: 50px;
      color: #fff;
    }
  }

  .title {
    font-weight: 700;
    margin-top: 15px;
    font-size: 21px;
    width: 700px;
    text-align: center;
    color: #c1070a;
  }
`

export {
  Container,
  StatusContainer,
  Status,
  SendError,
  ProgressBar,
  PassoContainer,
  SpinnerGeral,
  EnvioCompleto
}
