import styled from "styled-components";

export const PortalSelectContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  padding: 10px;

  svg {
    margin-right: 16px;
  }

  span {
    margin-right: 50px;
    font-weight: 400;
    color: #212529;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const PortalContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    color: ${props => (props.selected ? "#3a566e" : "#9e9e9e")};
  }

  img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    box-shadow: 1px 1px 6px 1px #4a4a4a;
    margin-right: 8px;
    filter: ${props => (!props.selected ? "grayscale(90%)" : "none")};
  }
`;
