import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useMemo } from 'react'
import { Check } from 'react-feather'
import { PropostaEspecificaContext } from '../../context'
import { DocumentContainer } from '../confirmarProposta/styles'
import { PassoContainer, SpinnerGeral } from './styles'

const PassoDeEnvio = ({ title, subtitle, icon, color, files }) => {
  const { progress, setProgress } = useContext(PropostaEspecificaContext)

  const enviado = useMemo(() => files.every(file => file.enviado), [files])
  const carregando = useMemo(() => files.some(file => file.loading), [files])

  useEffect(() => {
    if (enviado) {      
      setProgress(progress + 33)
    }
  }, [enviado])

  return (
    <PassoContainer color={color} active={!enviado}>
      <div className="identifier">
        {icon}
        <span className="title">{title}</span>
        <span className="subtitle">{carregando ? subtitle : ''}</span>
      </div>
      <div className="files">
        {files.map((file, i) => {
          const { name, loading, enviado } = file
          return (
            <DocumentContainer color="#8e8e8e" key={i}>
              {name}
              {loading ? (
                <FontAwesomeIcon icon="spinner" spin />
              ) : enviado ? (
                <Check className="check" />
              ) : (
                ''
              )}
            </DocumentContainer>
          )
        })}
      </div>
      <div className="status">
        {enviado ? (
          <Check className="done" />
        ) : carregando ? (
          <SpinnerGeral />
        ) : (
          <div className="circle" />
        )}
      </div>
    </PassoContainer>
  )
}

export default PassoDeEnvio
