import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { DivReCaptcha } from "../../../style/styleCadastro"

const ReCaptcha = ({ setState }) => {
    return (
        <DivReCaptcha>
            <ReCAPTCHA
                sitekey="6Ldcx7wUAAAAAJlsaiJ3zOnmwD2UGZG5pt5bPOFI"
                onChange={() => setState({ submitEnabled: true })}
            />
        </DivReCaptcha>
    )
}

export default ReCaptcha;