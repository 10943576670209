import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ToastError } from '../../../Stores/Funcoes/FuncoesGerais'

import * as S from './style/style'

export const BtnDownload = ({ number, onClick }) => {
  return (
    <S.WrapperBtnDownload>
      <S.BadgeDownload>
        <b>{number}</b>
      </S.BadgeDownload>
      <S.ButtonDownload
        onClick={
          number ? onClick : () => ToastError('Selecione pelo menos 1 edital')
        }
      >
        <FontAwesomeIcon
          icon="cloud-download-alt"
          color="#449e58"
          style={{ marginRight: '10px' }}
        />
        <div style={{ whiteSpace: 'nowrap', fontSize: '13px' }}>
          BAIXAR SELEC.
        </div>
      </S.ButtonDownload>
    </S.WrapperBtnDownload>
  )
}

export const BtnDownloadTodos = ({ number, onClick }) => {
  return (
    <S.WrapperBtnDownload>
      <S.BadgeDownload>
        <b>{number}</b>
      </S.BadgeDownload>
      <S.ButtonDownload
        onClick={
          number ? onClick : () => ToastError('Nenhum edital para baixar.')
        }
      >
        <FontAwesomeIcon
          icon="cloud-download-alt"
          color="#ff9c00"
          style={{ marginRight: '10px' }}
        />
        <div style={{ whiteSpace: 'nowrap', fontSize: '13px' }}>
          BAIXAR TODOS
        </div>
      </S.ButtonDownload>
    </S.WrapperBtnDownload>
  )
}
