import React from "react";

const statusDay = (weekDays, sigla) => {
  if (weekDays) {
    let dados = weekDays.toString();
    return dados.indexOf(sigla) > -1;
  }
};

export const CardSemana = ({ sigla, dia, handleDay, weekDays }) => {
  let status = statusDay(weekDays, sigla);
  return (
    <div
      onClick={() => handleDay(sigla)}
      className="icon-scale"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "5px",
        border: "1.4px solid #3A566E",
        borderRadius: "3px",
        width: "70px",
        height: "50px",
        textAlign: "center",
        background: status && "#3A566E"
      }}
    >
      <div style={{ color: status ? "white" : "#3A566E", fontSize: "16px" }}>
        <b>{sigla}</b>
      </div>
      <div style={{ color: status ? "white" : "#808080", fontSize: "10px" }}>
        {dia}
      </div>
    </div>
  );
};
