import styled from 'styled-components'

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  font-size: 80%;

  .dados {
    display: flex;

    .info {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        margin-bottom: 10px;

        b {
          margin-right: 10px;
        }
      }
    }

    .data {
      /* margin-left: auto; */
      display: flex;
      width: 270px;
      justify-content: space-between;
      padding: 20px;
      border-left: 1px solid #cacaca;
    }
  }

  .palavras {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
      border-radius: 5px;
      padding: 2px 5px;
      color: white;
      background-color: #0090ff;
      margin: 2px 0 2px 5px;
    }
  }
  .mensagem{
  margin-top: 15px;
  
  }
  .descricao {
    width: 100%;
    display: flex;
    flex-direction: column;

    b {
      margin-bottom: 5px;
    }
  }
`

export const ContainerFooter = styled.div`
  background: #37516b;
  position: relative;
  margin-top: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;

  span.navigation-tip {
    align-items: flex-start;
    font-weight: 300;
    font-size: 76%;
    position: absolute;
    left: 11px;
    bottom: 8px;
    width: 20%;
    color: #d8d8d8;
    text-shadow: 1px 1px 2px black;

    i {
      font-weight: 800;
    }
  }

  .action-buttons {
    display: flex;

    button {
      display: flex;
      align-items: center;
      border: none;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      justify-content: center;
      box-shadow: 1px 1px 8px black;

      span {
        color: #fff;
        font-size: 75%;
      }
    }

    svg {
      color: #fff;
    }

    .qualificar {
      button {
        background: #4da04d;
        transition: 150ms ease-in-out;
      }

      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }

      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;
      width: 64px;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }

    .aprovar {
      button {
        background: #4d85a0;
        transition: 150ms ease-in-out;
      }

      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }

      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;
      width: 64px;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }

    .desqualificar {
      button {
        background: #ec4242;
        transition: 150ms ease-in-out;
      }
      span {
        margin-top: 7px;
        font-size: 11px;
        transition: 150ms ease-in-out;
      }
      width: 64px;
      margin: 10px 75px;
      font-size: 75%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 150ms;

      :hover {
        button {
          transform: scale(1.05);
        }
        span {
          font-weight: 700;
        }
      }
    }
  }

  .status {
    display: flex;
    padding-top: 27px;
    flex-direction: column;
    position: absolute;
    right: 30px;
    bottom: 10px;
    width: 200px;
    height: 70px;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 6px #1d1d1db0;
    opacity: 0;
    bottom: -55px;
    animation: 500ms slideTopStatus ease-in forwards;

    @keyframes slideTopStatus {
      from {
        opacity: 0;
        bottom: -55px;
      }
      to {
        opacity: 1;
        bottom: 10px;
      }
    }

    .simbolo {
      position: absolute;
      right: calc(50% - 25px);
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      top: -25px;
      border-radius: 50%;
      align-items: center;
      color: #fff;
      box-shadow: 1px 1px 5px #000000d6;
      opacity: 0;
      animation: 500ms fadeIn ease-in 400ms forwards;
    }

    .qualificado {
      background: #4ea04e;
    }
    .desqualificado {
      background: #ec4343;
    }
    .aprovado {
      background: #2076a0;
    }
  }

  .user {
    text-align: center;
    font-size: 10px;
    position: sticky;
    font-weight: 800;
  }

  .title {
    font-weight: 900;
    font-size: 10px;
    text-align: center;
    margin-top: 32px;
  }
  
  .title-user {
    font-weight: 900;
    font-size: 10px;
    text-align: center;
  }

  .message {
    font-size: 10px;
    text-align: center;
    margin-bottom: 7px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
`

export const BottonLink = styled.div`
  display: flex;
  width: 130px;
  height: 25px;
  border-radius: 7px;
  border: 1px solid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 11px;
  font-weight: bold;
  border-color: ${props => props.color && props.color};
  color: ${props => props.color && props.color};
  margin-left: 10px;
  transition: 200ms;
  user-select: none;

  svg {
    margin-bottom: 3px;
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  svg.fa-spinner {
    /* font-size: 18px;
    color: black;
    margin: 0 !important; */
    animation: 2s rotateSpinner linear infinite;
  }

  @keyframes rotateSpinner {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const WrapperLink = styled.div`
  display: flex;
  padding: 20px;
  padding-top: 35px;
  justify-content: start;
  align-items: center;
  width: 33%;
`

export const WrapperLicitacao = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;

  .user {
    margin-top: 22px;
    text-align: center;
    font-size: 10px;
    position: sticky;
    font-weight: 800;
  }

  .icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    box-shadow: 1px 1px 4px 1px #00000087;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .title {
    font-weight: 900;
    font-size: 10px;
    text-align: center;
    margin-top: 32px;
  }

  .title-user {
    font-weight: 900;
    font-size: 10px;
    text-align: center;
  }
`
