import React, { useEffect, useState } from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Key } from 'react-feather'
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { styled } from '@material-ui/core/styles';
import { useFiltroEdital } from "./context/FiltroEditaisContext";

const MyChip = styled(Chip)({
  backgroundColor: (props) => props.backgroundcolor,
  color: '#fff',
})

const CardPalavrasBuscaManual = () => {
  const { onChangePalavra, onChangePalavraExclusao, state: { palavra, palavraExclusao } } = useFiltroEdital()

  const handleKeyDown = event => {
    switch (event.key) {
      case ',':
      case 'Tab':
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.value.length > 0) {
          const newTags = [...palavra, event.target.value].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          onChangePalavra(newTags)
        }
        break
      }
      default:
    }
  }

  const handleKeyDownExclusao = event => {
    switch (event.key) {
      case ',':
      case 'Tab':
      case 'Enter': {
        event.preventDefault()
        event.stopPropagation()
        if (event.target.value.length > 0) {
          const newTags = [...palavraExclusao, event.target.value].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          onChangePalavraExclusao(newTags)
        }
        break
      }
      default:
    }
  }

  return (
    <CardFiltroSmall>
      <CardHeard>
        <Key />
        <CardTitulo>
          <div>FILTRO POR PALAVRA</div>
          <p>Filtrar por palavras-chave</p>
        </CardTitulo>
      </CardHeard>
      <CardBody height={'100%'}>
        <Autocomplete
          multiple
          freeSolo
          fullWidth
          id="tags-outlined"
          options={[]}
          value={palavra}
          onChange={(event, newValue) => onChangePalavra(newValue)}
          filterSelectedOptions
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip color="primary" size="small" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Palavras de classificação"
                placeholder="palavras..."
                required
                size="small"
                margin="normal"
                fullWidth
              />
            )
          }}
        />
        <Autocomplete
          multiple
          freeSolo
          fullWidth
          id="tags-outlined"
          options={[]}
          value={palavraExclusao}
          onChange={(event, newValue) => onChangePalavraExclusao(newValue)}
          filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <MyChip backgroundcolor="#d2051c" size="small" label={option} {...getTagProps({ index })} />
              ))
            }
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDownExclusao
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Palavras de exclusão"
                placeholder="palavras..."
                required
                size="small"
                margin="normal"
                fullWidth
              />
            )
          }}
        />
      </CardBody>
    </CardFiltroSmall>
  )
}

export default CardPalavrasBuscaManual
