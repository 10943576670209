import styled from 'styled-components';

export const Overlay = styled.div`
  z-index: 3123213;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: #000000c9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #4646464f;
  animation: 1s fadeIn ease-in-out;
  height: 100%;
  margin: 10px;

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;

    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-weight: 600;
      /* font-size: 130%; */
      color: #8a8a8a;
      padding: 10px;
      margin-top: 20px;
      opacity: 0;
      animation: 1500ms fadeIn forwards;
      margin-bottom: 5px;

      .status { 
        display: flex;
        flex-direction: column;
        align-items: center;

        .proposta-enviada {
          display: flex;
          flex-direction: column;
          color: green;
          font-size: 80%;
          align-items: center;

          span.title {
            font-size: 130%;
            opacity: 0;
            animation: 2s fadeIn forwards;
          }

          /* svg {
            margin-bottom: 0;
            margin-right: 7px;
            width: 20px
          } */

        }

        svg {
          font-size: 30px;
          margin-bottom: 10px;
        }

        .botoes-container-after {
          display: flex;
          flex-direction: column;

           a.redirect-proposta {
            color: grey;
            font-size: 65%;
            font-weight: 400;
          }

          a:hover {
            text-decoration: none;
            color: #296188d1;
          }

          button.close-modal {
            background: #389848;
            min-width: 100px;
            border: none;
            font-weight: 500;
            box-shadow: 1px 1px 2px #313131;
            padding: 5px 10px;
            border-radius: 3px;
            color: #fff;
            font-size: 80%;
            margin: 15px 0;
          }          
        }       
      }

      svg { 
        margin-right: 10px;
        margin-bottom: -2px;
      }

      .pdf {
        color: black;
        font-weight: 400;
        font-size: 13px;

        .title {
          margin-bottom: 10px;
          color: #848484;
          width: 257px;
        }

        .botoes-pdf {
          display: flex;
          align-items: center;
          justify-content: space-around;

          button {
            box-shadow: 1px 1px 3px black;
            border-radius: 3px;
            border: none;
            color: #fff !important;

            a { color: #fff };

            a:hover {
              color: #fff;
              text-decoration: none;
            }
          }

          button.enviar-pdf {
            background: #389848;
          }
          button.baixar-pdf {
            background: #297788
          }
        }
      }
    }
    
    .body-documentos {
      display: flex;
      margin-top: 20px;
      height: 100%;
    }

    .botoes-container {
      display: flex;
      width: 50%;
      margin: 25px auto;
      font-size: 12px;
      margin-bottom: 30px;
      border-radius: 15px;
      box-shadow: 1px 1px 4px #000000ba;
      background: ${props => props.loading ? '#e8e8e8' : 'transparent'};
      cursor: ${props => props.loading ? 'not-allowed' : 'pointer'};
      user-select: none;

      button {
        border:none;
        background: none;
        width: 50%;
        padding: 10px;
        font-weight: 500;
      }


      #editar-proposta {
        background: green;
        color: #fff;
        border-right: 1px solid #828282;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        :disabled {
          opacity: 0.6;
        }
      }
      #voltar-lista {
        background: #c5bfbf;
        color: black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
`;

export const ContainerDocumentos = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 10px 0 20px;
  user-select: none;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #0000005e;
  margin-right: ${props => props.left ? '10px' : '20px'};
  margin-left: ${props => props.left ? '20px' : '10px'};
  height: 100%;

  .header { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: ${props => props.headerColor || '#296188'};
    height: 30px;
    padding: 0 15px;
    border: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
    position: relative;
    font-size: 70%;

    span {
      font-weight: 600;
      font-size: 90%;
    }
    svg {
      margin-right: 10px;
      width: 17px;
    }
  }

  .selecionar-arquivo {
    width: 100;
    padding: 14px 12px;
    border-bottom: 1px solid #c1bdbd;
    display: flex;

    button {
      border: none;
      display: flex;
      align-items: center;
      margin-left: auto;
      border-radius: 8px;
      font-weight: 500;
      font-size: 10px;
      background: #5dbb5d;
      color: #fff;
      box-shadow: 1px 1px 4px #000000c9;
    }

    button:disabled {
      opacity: 0.6;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    height: calc(100% - 76px);
    overflow: auto;

    /* .item {
      display: flex;
      align-items: center;
      height: 35px;
      min-height: 35px;
      margin-bottom: 10px;
      box-shadow: 1px 1px 6px 0px #b1b1b1;
      border: 1px solid #b1b1b1;
      font-size: 10px;
      color: grey;

      .name {
        margin-left: 8px;
        font-weight: 500;
      }

      .date {
        margin-left: auto;
      }

      .close-button {
        margin: 0 8px;
        svg { width: 16px; color: red;}
      }
    } */
  }
`;

export const SingleDocument = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  min-height: 35px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 6px 0px #b1b1b1;
  border: 1px solid #b1b1b1;
  font-size: 10px;
  color: grey;
  background: ${props => props.enviado ? '#e8e8e8' : '#fff'};
  opacity: ${props => props.loading ? '0.6' : '1'};

  .name {
    margin-left: 8px;
    font-weight: 500;
  }

  .date {
    margin-left: auto;
  }

  .enviado {
    color: #28a745;
  }

  .document-error {
    margin: auto;
    color: red;
  }

  .close-button {
    margin: 0 8px;
    svg { width: 16px; color: red;}
  }

  .loading-document-spinner  {
    margin: 0 8px;
    svg { font-size: 150%; color: grey }
  }

  .pdf-download { 
    color: #389848;
    margin-left: 5px;
    width: 20px;
  }
`;

export const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 7px;
  width: 100%;
  cursor: pointer;
  box-shadow: -1px 2px 5px 1px #6b6b6b59;
  color: #3a566e;
  background-color: #fff;
  font-size: 9px;
  margin: 0;

  span {
    font-size: 9px
  }

  svg {
    width: 19px;
  }
`;

export const DocumentSelectText = styled.label`
  font-weight: 400;
  font-size: 11px;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;

  @media (max-height: 835px) {
    font-size: 9px;
  }
`;