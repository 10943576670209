import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ModalContainer, ModalHead, ModalTitle } from './styles/styles'
import { List } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalOverlay } from '../../Components/newModal/sytleModal'
import { TabelaItemEdital } from './modalItens/customTabela/TabelaItemEdital'

export const ModalItensLicitacao = ({ itens }) => {
  const [active, setActive] = useState(false)

  return (
    <>
      <FontAwesomeIcon
        className="icon-scale"
        icon="list"
        onClick={() => setActive(!active)}
        data-tip="LISTA DE ITENS"
        data-place="bottom"
        data-type="info"
        data-effect="solid"
      />
      {active && (
        <ModalOverlay>
          <ModalContainer
            width="auto"
            left="unset"
            height="auto"
            right="unset"
            bottom="unset"
            top="unset"
          >
            <ModalHead>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <List color="#fff" />
                <ModalTitle>Itens Edital</ModalTitle>
              </div>
              <FontAwesomeIcon
                className="icon-scale"
                icon="times"
                color="#fff"
                style={{ cursor: 'pointer', opacity: '0.9', fontSize: '15px' }}
                onClick={() => setActive(!active)}
              />
            </ModalHead>
            <TabelaItemEdital itens={itens} />
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}
