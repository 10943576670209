import { situacao } from "../BiddingPanel/styles";
import React from "react";
import { BolaVerdePiscando } from "../../Components/Menu/styles";
import MaquinaDeEstadosStore from "../../Stores/MaquinaDeEstadosStore";
import CheckMonitorar from "./ModalMonitorarLicitacoes/CheckMonitorar";
import LicitacoesExclusao from "../../Stores/LicitacoesExclusao";
import { Trash2 } from "react-feather";
import { FormatDateHora, FormatDateData } from "../../Stores/Funcoes/FuncoesGerais";

export const rowTheme = {
  title: {
    height: "0px"
  },

  rows: {
    borderWidth: "0px",
    borderTopStyle: null,
    height: "70px",
    backgroundColor: "transparent",
    padding: "0px",
    minHeight: "70px"
  },
  cells: {
    borderTopStyle: null,
    // cellPadding: '20px',
    height: "20px",
    padding: "0px"
  }
};

export const columnsLicitacao = [
  {
    name: "PORTAL",
    selector: "portal",
    sortable: true,
    left: true,
    grow: 1,
    maxWidth: "120px"
  },

  {
    name: "Nº PREGAO",
    selector: "codigo",
    sortable: true,
    left: true,
    grow: 0,
    maxWidth: "80px"
  },

  {
    name: "COMPRADOR",
    selector: "comprador.nome",
    sortable: true,
    left: true,
    minWidth: "80px",
    maxWidth: window.innerWidth < 1200 ? "297.5px" : "auto",
    grow: 1
  },

  {
    name: "DATA DISPUTA",
    selector: "data_disputa",
    sortable: true,
    left: true,
    grow: 0.5,
    cell: ({ data_disputa }) => {
      if (!data_disputa) return '';
      if (typeof data_disputa === "string") return data_disputa;
      return `${FormatDateData(data_disputa)} ${FormatDateHora(data_disputa).slice(0, 5)}`
    }
  },
  {
    name: "SITUAÇÃO",
    selector: "situacao.descricao",
    sortable: true,
    center: true,
    grow: 0.8,
    cell: row => (
      <span
        style={{
          borderRadius: "10px",
          padding: "2px 10px 2px 10px",
          background: situacao(row.situacao.cor),
          marginLeft: "-10px",
          color: "white",
          width: "70%",
          textAlign: "center"
        }}
      >
        {row.situacao.descricao}
      </span>
    )
  },

  // {
  //   name: "MONITORAR",
  //   selector: "monitorar_chat",
  //   sortable: true,
  //   center: true,
  //   grow: 0.2,
  //   cell: row => {
  //     const { monitorar_chat, situacao: { descricao } } = row;
  //     const { comboIdEmpresaFree } = MaquinaDeEstadosStore;

  //     if (descricao === "ATA - Homologado" || descricao === "Homologada")
  //       return null;
  //     if (monitorar_chat)
  //       return <BolaVerdePiscando color={"#008100"} />
  //     if (comboIdEmpresaFree)
  //       return <CheckMonitorar licitacao={row} />
  //     return <BolaVerdePiscando animation={true} color={"#a5a5a5"} />

  //   }
  // },
  {
    name: "EXCLUIR",
    selector: "id",
    sortable: false,
    center: true,
    grow: 0.1,
    cell: row => (
      <button
        className="icon-scale-xs"
        data-tip="EXCLUIR LICITAÇÃO"
        data-place="left"
        data-type="error"
        data-effect="solid"
        style={{
          padding: 0,
          background: "transparent",
          border: "none"
        }}
        onClick={() => LicitacoesExclusao.excluirLicitacao(row.id)}
      >
        <Trash2 color="#f00" />
      </button>
    )
  }
];
