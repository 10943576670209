import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { rowTheme, columnsLicitacao } from "./EstruturaTabela";
import { Portais } from "../BotoesMenu";
import { checkPathname } from "../../../Stores/LicitacoesExclusao";
import { ExclamationWarning } from "../../../Components/Notifications/ExclamationWarning";
import { PortaisSelector } from "../../../Components/Sistema/PortaisSelector/PortaisSelector";

import {
  PortaisSelectContainer,
  NavegacaoContainer,
  SingleNavigationItem,
  ContainerDataTable,
  SizeLicitacoes
} from "../styleLicitacoes";
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  TabButton,
  ReactTooltip,
  ContentBodyLicitacoesTrack
} from "../../../globalStyles";

@inject("LicitacoesExclusao")
@observer
class ExcluirLicitacoes extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      portaisShowing: false,
      countBids: 0
    };
  }

  componentDidMount() {
    this.props.LicitacoesExclusao.consultaExcluidas();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ portaisShowing: false });
    }
  }

  handlePortals = () => {
    const {
      todosPortais,
      portais,
      listaExcluidas
    } = this.props.LicitacoesExclusao;
    return listaExcluidas.filter(lic => {
      if (todosPortais.some(portal => portal.id === lic.portal_id)) {
        return portais.includes(lic.portal_id);
      } else {
        return true;
      }
    });
  };

  render() {
    // const { listaExcluidas, totalExcluidas } = this.props.LicitacoesExclusao
    // const { portaisShowing } = this.state
    return (
      <>
        <ComponentHeader
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <ReactTooltip />
          <div className='d-flex position-relative align-items-center'>
            <TabButton ul={true}>LICITAÇÕES EXCLUÍDAS</TabButton>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "1rem"
              }}
            >
              <div style={{ color: "white", fontSize: "0.9rem" }}>
                Selecione um portal
              </div>
              <PortaisSelector
                value={this.props.LicitacoesExclusao.filtroPortal}
                onChange={value =>
                  this.props.LicitacoesExclusao.portalHandleChange(value)
                }
              />
            </div>
          </div>
          <NavegacaoContainer top='10px'>
            <SingleNavigationItem active={checkPathname("/agenda")}>
              <Link to='/agenda'>
                <FontAwesomeIcon
                  className='icon-scale icon-header'
                  icon='calendar-check'
                  data-tip='AGENDA'
                  data-effect='solid'
                  data-type='warning'
                  data-place='bottom'
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname("/funil")}>
              <Link to='/funil'>
                <FontAwesomeIcon
                  className='icon-scale icon-header'
                  icon='filter'
                  data-tip='FUNIL'
                  data-effect='solid'
                  data-type='warning'
                  data-place='bottom'
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname("/licitacoes")}>
              <Link to='/licitacoes'>
                <FontAwesomeIcon
                  className='icon-scale icon-header'
                  icon='list'
                  data-tip='LISTA'
                  data-effect='solid'
                  data-type='warning'
                  data-place='bottom'
                />
              </Link>
            </SingleNavigationItem>
            <SingleNavigationItem active={checkPathname("/licitacoes/excluir")}>
              <Link to='/licitacoes/excluir'>
                <FontAwesomeIcon
                  className='icon-scale icon-header'
                  icon='trash'
                  data-tip='LIXEIRA'
                  data-effect='solid'
                  data-type='warning'
                  data-place='bottom'
                />
              </Link>
            </SingleNavigationItem>
          </NavegacaoContainer>

          <SizeLicitacoes>
            <span className='contador-numero'>
              {this.handlePortals().length}
            </span>
            <span className='contador-mensagem'>Excluídas</span>
          </SizeLicitacoes>
        </ComponentHeader>
        <ComponentHeaderOverflow />
        <ContentBodyLicitacoesTrack height='80vh'>
          <div style={{ display: "flex", overflowY: "hidden" }}>
            <ContainerDataTable>
              <DataTable
                fixedHeader
                className='tabelalo'
                fixedHeaderScrollHeight='80vh'
                subHeader
                allowOverflow={true}
                overflowY={true}
                striped
                id='tabela-principal-licitacoes'
                pointerOnHover
                columns={columnsLicitacao}
                highlightOnHover
                customTheme={rowTheme}
                data={this.handlePortals()}
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  overflowY: "hidden"
                }}
                noDataComponent={
                  <ExclamationWarning
                    title='Nenhuma licitação excluída'
                    height='75vh'
                  />
                }
              />
            </ContainerDataTable>
          </div>
        </ContentBodyLicitacoesTrack>
      </>
    );
  }
}

export default ExcluirLicitacoes;

// export const ExcluirLicitacoes = inject("LicitacoesExclusao")(
//   observer(props => {
//     const { listaExcluidas } = props.LicitacoesExclusao;

//     return <div>a</div>;
//   })
// );
