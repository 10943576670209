import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxios from 'axios-hooks'
import React, { useContext, useEffect } from 'react'
import { Trash2 } from 'react-feather'
import { ToastDark, ToastError } from '../../../../../../../Stores/Funcoes/FuncoesGerais'
import maquinadeestadosstore from '../../../../../../../Stores/MaquinaDeEstadosStore'
import { API } from '../../../ListaDeLicitacoes/api'
import Cabecalho from './Cabecalho'
import { DadosPdfContext } from './context'
import Rodape from './Rodape'
import { FileInput, InserLogoEmpresa, Container } from './styles'

const DadosGerarProposta = () => {
  const { logo, setLogo } = useContext(DadosPdfContext);

  const [{ data, loading, error }, postLogo] = useAxios(
    {
      method: 'POST',
      url: API.enviarLogo(),
    },
    {
      manual: true,
      useCache: false
    }
  );

  const [{ data: excluirData, loading: excluirLoading, error: excluirError, response }, excluirLogo] = useAxios(
    {
      method: 'DELETE',
      url: API.enviarLogo(),
      data: { logo }
    },
    {
      manual: true,
      useCache: false
    }
  );

  useEffect(() => {
    if (data) setLogo(data.logo);
    if (error) ToastError('Erro ao enviar imagem');
  }, [data, error])

  useEffect(() => {
    if (response && response.status === 204) setLogo('');
    else if (response || excluirError) ToastError('Erro ao excluir imagem');
  }, [response, excluirError])


  const handleLogo = ({ target }) => {
    const img = target.files[0];
    if (!img) return;
    if (img && img.size > 3000000) {
      ToastDark('Tamanho máximo permitido de 3mb.');
      return target.value = null;
    }
    document.getElementById('company-logo').style.width = '50px';
    document.getElementById('company-logo').style.height = '50px';
    // CALL TO API
    const formData = new FormData;
    formData.append('logo', img);
    formData.append('fornecedor', maquinadeestadosstore.getIdEmpresa());
    postLogo({ data: formData });
    target.value = null;
  }


  return (
    <Container>
      <div className="section-header">
        <div className="title">
          <div className="main-title">
            CONFIGURAÇÕES DA PROPOSTA
          </div>
          <div className="sub-title">
            Configure a impressão da proposta comercial
          </div>
        </div>
        <div className="upload-company-image">
          <div className="wrapper-image">
            {logo &&
              <Trash2
                id="remover-avatar-empresa"
                className="icon-scale-xs"
                data-tip="REMOVER LOGO"
                data-type="error"
                data-place="top"
                data-effect="solid"
                onClick={excluirLogo}
              />
            }
            {(loading || excluirLoading) ? <FontAwesomeIcon icon="spinner" className="spinner" /> :
              <img src={logo ? logo : "/static/Imagens/logo_wavecode.png"}
                id="company-logo" alt="company-logo" />}
          </div>
          <InserLogoEmpresa htmlFor="enviar-logo-empresa" className="icon-scale-xs">
            INSERIR LOGO EMPRESA
          </InserLogoEmpresa>
          <FileInput
            type="file"
            id="enviar-logo-empresa"
            accept="image/*"
            onChange={handleLogo}
          />
        </div>
      </div>
      <Cabecalho />
      <Rodape />
    </Container>
  )
}

export default DadosGerarProposta
