import {observable, action} from "mobx";
import {getEmpresaId} from "../Services/auth";
import Api from "./Conexao/conexao";
import {compareDataNew} from "../Services/funcoes";
import ModalLoadingStore from "./ModalLoadingStore";
import {AlertaVerde, AlertaVermelho} from "./Funcoes/FuncoesGerais";
import MenuStores from "./MenuStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";

// @inject('MenuStores')
// @observer

class FinanceiroStores {
  @observable
  modulos = [];
  @observable
  contratos = [];
  @observable
  boletos = [];
  @observable
  userEmail = "";
  @observable
  isFetting = false;
  @observable
  isFettingBoletos = false;
  @observable
  ModulosSelecionados = [];
  @observable
  ModulosDoComboAtual = [];
  @observable
  Upgrademodulos = null;
  @observable
  combos = [];
  @observable
  soma = 0;
  @observable
  diasRestantes = 0;
  @observable
  modalContratarActive = false;
  @observable
  isSubmitting = false;
  @observable
  pagamentoConfirmado = false;
  @observable
  modalCartao = false;

  @observable
  erroCartao = "";

  // @observable saldoContrato = 0;
  // @observable boletoAberto = '';
  // @observable renovacao = null;
  // @observable renovacaoDisabled = false;

  @action
  handleCloseModal = () => {
    this.modalContratarActive = false;
  };

  @action
  abrirModalCartao = () => (this.modalCartao = true);
  @action
  fecharModalCartao = () => (this.modalCartao = false);

  @action
  limparErroCartao = () => (this.erroCartao = "");

  @action
  handleOpenModal = () => {
    this.modalContratarActive = true;
  };

  somas = () => {
    this.soma = this.ModulosSelecionados.reduce((acc, atual) => {
      if (atual === 47) {
        let dados = this.modulos.find(item => {
          return item.id === 47;
        });
        return acc + Number(dados.value);
      }
      if (atual === 48) {
        let dados = this.modulos.find(item => {
          return item.id === 48;
        });
        return acc + Number(dados.value);
      }
      if (atual === 49) {
        let dados = this.modulos.find(item => {
          return item.id === 49;
        });
        return acc + Number(dados.value);
      } else return acc;
    }, 0);
  };

  resetVariavel = () => {
    this.modulos = [];
    this.combos = [];
    this.ModulosSelecionados = [];
    this.Upgrademodulos = null;
  };

  @action
  consulta_modulos = () => {
    this.resetVariavel();
    Api.get("consultar_modulos/", {})
      .then(({data: {modulos, combos}}) => {
        this.modulos = modulos;
        this.combos = combos;
      })
      .catch(error => {
      });
  };

  @action
  async consulta_empresa() {
    this.isFetting = false;
    const response = await Api.post("cont_empresa/", {
      id_fornecedor: getEmpresaId()
    });
    this.contratos = await response.data.contract;
    this.renovacao = response.data.contract[0].renovacao;
    this.diasRestantes = await compareDataNew(
      response.data.contract[0].ends_in
    );
    this.isFetting = true;
    this.setModulosAtuais();
  }

  setModulosAtuais = () => {
    if (this.contratos[0].combo_id === 47) this.ModulosDoComboAtual = [47];
    if (this.contratos[0].combo_id === 52) this.ModulosDoComboAtual = [47, 48];
    if (this.contratos[0].combo_id === 49) this.ModulosDoComboAtual = [49];
  };

  @action
  cancelar_plano = id => {
    ModalLoadingStore.handleOpenModal();
    Api.post("cancelar_plano_superlogica/", {id_contrato: id})
      .then(response => {
        this.consulta_empresa();
        this.consulta_modulos();
        this.consulta_boletos();
        AlertaVerde("Seu plano em espera foi cancelado");
      })
      .catch(error => AlertaVermelho(error.response.data.error))
      .finally(() => ModalLoadingStore.handleCloseModal());
  };

  @action
  closePagamentoConfirmado = () => (this.pagamentoConfirmado = false);

  @action
  changeEmail = email => {
    ModalLoadingStore.handleOpenModal();
    Api.post(
      "alterar_email_alterado/",
      {email, fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()},
      {}
    )
      .then(response => {
        AlertaVerde(response.data.resposta);
      })
      .catch(error => {
        if (error.response.status === 500)
          return AlertaVermelho(
            "Sistema com instabilidade, tente novamente mais tarde."
          );
        AlertaVermelho(error.response.data.error);
      })
      .finally(() => ModalLoadingStore.handleCloseModal());
  };

  @action
  atualizarPlano = () => {
    const id_fornecedor = getEmpresaId().toString();
    this.pagamentoConfirmado = false;
    MenuStores.mudou_empresa(id_fornecedor);
    this.consulta_empresa();
    this.consulta_modulos();
    this.consulta_boletos();
  };

  @action
  contratar_cartao = (id_combo, cartao, ano, mes, cod_seguranca, nome, cpf) => {
    // ModalLoadingStore.handleOpenModal();
    this.isSubmitting = true;
    Api.post(
      "contratar_plano_cartao/",
      {
        id_combo,
        id_fornecedor: getEmpresaId().toString(),
        cartao,
        ano,
        mes,
        cod_seguranca,
        nome,
        cpf
      },
      {}
    )
      .then(response => {
        AlertaVerde(response.data.resposta);
        this.erroCartao = "";
        // this.consulta_modulos();
        this.fecharModalCartao();
        this.pagamentoConfirmado = true;
      })
      .catch(error => {
        if (error.response.status === 500) {
          this.erroCartao =
            "Sistema com instabilidade, tente novamente mais tarde.";
          return AlertaVermelho(
            "Sistema com instabilidade, tente novamente mais tarde."
          );
        }
        AlertaVermelho(error.response.data.error);
        this.erroCartao = error.response.data.error;
      })
      .finally(() => (this.isSubmitting = false));
  };

  @action
  contratar_modulo = (id, renovacaoAutomatica) => {
    ModalLoadingStore.handleOpenModal();
    Api.post(
      "contratar_plano_superlogica/",
      {
        id_combo: id.toString(),
        id_fornecedor: getEmpresaId().toString(),
        renovacao: renovacaoAutomatica
      },
      {}
    )
      .then(response => {
        this.consulta_empresa();
        this.consulta_modulos();
        this.consulta_boletos();
        this.Upgrademodulos = false;
        ModalLoadingStore.handleCloseModal();
        AlertaVerde("SUCESSO! Esperando pagamento de boleto");
      })
      .catch(error => {
        ModalLoadingStore.handleCloseModal();
        AlertaVermelho(error.response.data.error);
      });
  };

  @action
  consulta_boletos = () => {
    this.isFettingBoletos = false;
    Api.post("listar_cobranca/", {
      id_fornecedor: getEmpresaId(),
      status: "todos"
    })
      .then(({data: {results}}) => {
        this.boletos = results;
        this.isFettingBoletos = true;
        this.userEmail = results[0].email;
      })
      .catch(error => {
      });
  };

  verificarPlano = () => {
    let contrato = this.ModulosSelecionados.concat(this.ModulosDoComboAtual);
    if (
      contrato.indexOf(47) !== -1 &&
      contrato.indexOf(48) !== -1 &&
      contrato.indexOf(49) !== -1
    ) {
      this.Upgrademodulos = this.combos.find(item => {
        return item.id === 51;
      });
      this.somas();
    } else if (contrato.indexOf(47) !== -1 && contrato.indexOf(48) !== -1) {
      this.somas();
      this.Upgrademodulos = this.combos.find(item => {
        return item.id === 52;
      });
    } else if (contrato.indexOf(49) !== -1 && contrato.indexOf(47) !== -1) {
      this.somas();
      this.Upgrademodulos = this.combos.find(item => {
        return item.id === 50;
      });
    } else if (contrato.indexOf(47) !== -1) {
      this.somas();
      this.Upgrademodulos = this.modulos.find(item => {
        return item.id === 47;
      });
    } else if (contrato.indexOf(49) !== -1) {
      this.somas();
      this.Upgrademodulos = this.modulos.find(item => {
        return item.id === 49;
      });
    } else if (this.ModulosSelecionados.length === 0) {
      this.Upgrademodulos = null;
    }
  };

  @action
  handleBoleto = () => this.boletos.find(boleto => !boleto.dt_liquidacao);

  adicionarTypePlano = id => {
    const arr = [...this.ModulosSelecionados, id];
    if (id === 48 && !arr.includes(47)) arr.push(47);
    this.ModulosSelecionados = arr;
  };

  removerTypePlano = id => {
    let arr = [...this.ModulosSelecionados].filter(el => el !== id);
    if (id === 47 && arr.includes(48)) arr = arr.filter(el => el !== 48);
    if (this.contratos[0].combo_id === 47 && id === 48)
      arr = arr.filter(el => el !== 48 && el !== 47);
    this.ModulosSelecionados = arr;
  };

  @action
  handleChange = (id, status) => {
    status ? this.removerTypePlano(id) : this.adicionarTypePlano(id);
    this.verificarPlano();
  };

  @action limparVariaveis = () => {
    this.modulos = [];
    this.contratos = [];
    this.boletos = [];
    this.userEmail = "";
    this.isFetting = false;
    this.isFettingBoletos = false;
    this.ModulosSelecionados = [];
    this.ModulosDoComboAtual = [];
    this.Upgrademodulos = null;
    this.combos = [];
    this.soma = 0;
    this.diasRestantes = 0;
    this.modalContratarActive = false;
    this.isSubmitting = false;
    this.pagamentoConfirmado = false;
    this.modalCartao = false;
    this.erroCartao = "";
  }

}

const financeiroStores = new FinanceiroStores();
export default financeiroStores;
