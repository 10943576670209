import styled from 'styled-components'

export const WrapperDetalhesDownload = styled.div`
  display: flex;
  width: 95%;
  /* height: 80%; */
`

export const TitleHeard = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #205283;
  margin-bottom: 10px;
`

export const InfoHeard = styled.div`
  font-size: 12px;
  color: #838383;
  font-style: italic;
`

export const ButtonClose = styled.div`
  font-size: 16px !important;
  margin: 15px;
  color: white;
  background: #3a566e;
  padding: 5px 47px;
  border-radius: 10px;
  -webkit-transition: 100ms;
  transition: 100ms;

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`

export const WrapperInfoDownload = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 85%;
  border-radius: 10px;
  border: 1px solid #eff2ef;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;

  .btnDownload {
    display: flex;
    justify-content: space-between;
    font-size: 1vw !important;
    color: #1a77d2;
    background: white;
    padding: 5px 22px;
    border-radius: 10px;
    border: 1px solid #1a77d2;
    transition: 100ms;

    :hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  .cardInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 300px;
    color: ${props => props.status && 'white'};
    height: 50%;
    flex-direction: column;

    p {
      text-align: center;
      margin-top: 20px;
      color: ${props => props.status && 'white'};
    }

    div {
      font-weight: bold;
      font-size: 25px;
      margin-top: 10px;
    }
  }

  .cardList {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
  }

  .spinner {
    position: relative;
    /* left: 70px;
    top: 60px; */
  }

  .badge {
    position: relative;
    left: 70px;
    color: black;
    top: 60px;
    background: white;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #8d8d8d;
  }
`
export const WrapperCard = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  height: 50%;
  flex-grow: 1;
  padding: 20px;
`

export const Card = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7vw;
  margin: 15px;
  border-radius: 5px;
  background: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;

  span {
    font-size: 1vw;
  }

  div {
    font-size: 1vw;
    font-weight: bold;
  }
`

export const WrapperList = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: auto;
`

export const CardList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #8a8a8a;
`

export const WrapperBtnDownload = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  position: relative;
`

export const BadgeDownload = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-width: 50px;
  right: -25px;
  padding: 4px 5px;
  background: white;
  top: -9px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #8c8c8c;
`

export const ButtonDownload = styled.div`
  display: flex;
  width: 100%;
  background: transparent;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  margin-left: 10px;
  padding: 6px 20px;
  font-weight: 500;
  border-radius: 10px;
  border: 2px solid #999999;
  color: #205283;
  transition: 100ms;

  :hover {
    border-color: #326f22;
    cursor: pointer;
    color: white;
    background: #316e21;
  }

  svg {
    width: 27px !important;
    height: 27px !important;
  }
`

export const WrapperDownload = styled.button`
  border: 0;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  color: ${props => (props.state ? '#159bc2' : '#b4b4b4')};
  transition: 100ms;
  
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  
  :focus {
    outline: 0;
  }
  
  :active {
    border: 0;
    transform: scale(1.1);
  }
`

export const ContainerPreparing = styled.div`
  display: flex;
  opacity: 0;
  animation: 500ms fadeIn ease-in-out forwards;

  .container-spinner {
    padding: 10px;
    background: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    box-shadow: 1px 1px 5px #464646;
  }

  div.preparando {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2px 9px;
    padding-left: 20px;
    margin-left: 15px;
    background: #f5f5f561;
    border-radius: 10px;
  }

  svg.fa-spinner {
    font-size: 18px;
    color: black;
    margin: 0 !important;
    animation: 2s rotateSpinner linear infinite;
  }

  @keyframes rotateSpinner {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
