import React from 'react'
import Calendar from 'react-calendar';
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import { format } from "date-fns";
import { CardBody, CardFiltroData, CardHeard, CardTitulo, InputData } from "./styles";

export const FiltroDataSimples = () => {
    const { onChangeDate, state: { date } } = useFiltroEdital()

    const handleData = (e) => {
        const dataTransf = format(e, 'dd/MM/yyyy')
        const data = {
            dmin: dataTransf,
            dmax: dataTransf,
            obj: e
        }
        onChangeDate(data)
    }

    return (

        <CardFiltroData>
            <CardHeard>
                <CardTitulo>
                    <div>FILTRO DE DATA</div>
                    <p>Selecione o dia que deseja pesquisar</p>
                </CardTitulo>
            </CardHeard>
            <CardBody>                
                <div>
                    <Calendar
                        onChange={(e) => handleData(e)}
                        value={date.obj}
                    />
                </div>
            </CardBody>
        </CardFiltroData>

    )
}
