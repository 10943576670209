import React from 'react'

import * as S from './Style'

const SubHeader = ({ children, overflow }) => {
  return (
    <>
      <S.ContainerSubHeader>{children}</S.ContainerSubHeader>
      {overflow && <S.Overflow />}
    </>
  )
}

export default SubHeader
