import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import ModalLoadingStore from "./ModalLoadingStore";
import $ from "jquery";
import "../Debug";
import {
  AlertaInfo,
  AlertaSuccess,
  AlertaVermelho,
  AlertaAzul,
  AlertaDownload
} from "./Funcoes/FuncoesGerais";
import SideStores from "./SideStores";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import BuscaDeEditaisStores from "./BuscaDeEditaisStores";
// import MenuStores from "./MenuStores";
import { FormatDateData } from "./Funcoes/FuncoesGerais";
import LoginStores from "./LoginStores";
import { createContext } from "react";
import BuscaDeEditaisLicitacaoStores from "./BuscaDeEditaisLicitacaoStores";

class EditaisStores {
  @observable modalHistoricoDownloadsActive = false;
  @observable fetchingHistorico = false;
  @observable historicoDownloads = undefined;

  @observable dataInicial = new Date().toLocaleDateString("pt-br");
  @observable dataFinal = new Date().toLocaleDateString("pt-br");
  @observable qualData = "inicial";

  @observable fetchingDownload = false;
  @observable allChecked = false;

  // Download de todos
  @observable linkDisponivel = false;
  @observable dataPesquisaDownload = undefined;
  @observable requestGerarLink = false;
  @observable requestInterval = undefined;
  @observable downloadsNoZip = undefined;
  @observable horaCriacao = undefined;

  // Download unitario
  @observable downloadUnitarioFetching = false;

  @observable pesquisaDataType = "abertura";
  @observable pesquisaDataTypeFavoritos = "ativos";
  @observable dataTypeFavoritosME = false;

  // FAVORITOS
  @observable favoritosAtivo = true;
  @observable favoritosVencido = true;

  @observable dataTypeFavoritosVencido = true;
  @observable jaBaixados = null;

  // Filtro Materiais/Serviços
  @observable tipo_item = ["M", "S"];

  // Portal
  @observable todosPortais = [...MaquinaDeEstadosStore.portais];

  @observable firstFetch = false;

  @observable portal = this.todosPortais.map(el => el.id);

  @observable
  baixarNovamente = false;
  @observable
  statusDataType = true;
  @observable
  showing = false;
  @observable
  pagina_atual = 1;
  @observable
  editais = [];
  @observable
  editais_total = 0;
  //ordenação
  @observable
  ordenar = "disputa";
  @observable
  ordenarAbertura = false;

  // Perfis
  @observable configPerfis = [];
  @observable perfilAtivado = null;

  // Modal mostrar detalhes

  @observable
  modalDetalhesActive = false;
  @observable
  detalhesEdital = null;

  // Modal proposta
  @observable
  modalPropostaActive = false;
  @observable
  dadosProposta = null;

  @observable
  id_excluir = 0;
  @observable
  lista_download = [];
  @observable
  lista_corrida = [];
  @observable
  cont_download_em_andamento = 0;
  @observable
  downloadHashList = [];

  @observable
  favorito = false;
  @observable
  currentEditais = [];
  @observable
  currentPage = null;
  @observable
  totalPages = null;
  @observable
  numero_editais_totais = 0;
  @observable
  mudar_key = "";
  @observable
  pagina_visualizada = "";
  @observable
  itens_edital = [];
  @observable
  flagStatus = true;
  @observable
  statusAtulizar = true;
  @observable
  styleData = "abertura";
  @observable
  filtroMe = false;
  @observable
  flagConsultar = 1;
  @observable
  Tag = null;
  @observable
  isFetching = false;
  @observable
  isActiveEditar = false;

  @observable
  btnFiltrar = false;
  @observable
  btnFiltrarDia = true;
  @observable
  btnFavorito = false;
  @observable
  btnExcluido = false;

  @observable
  editalID = 0;

  @action changeMaterialServico = ({ target: { value } }) => {
    this.tipo_item = this.tipo_item.includes(value)
      ? this.tipo_item.filter(el => el !== value)
      : [...this.tipo_item, value];
  };

  @action
  sincronizar_Licit_E = id => {
    this.editais = this.editais.map(item => {
      return id === item.id ? { ...item, sincronizado: true } : item;
    });

    Api.post("licitacoes/cadastrar_licitacao_manual/", {
      id_edital: id,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      user_pk: LoginStores.user_id
        ? LoginStores.user_id
        : MaquinaDeEstadosStore.getIdUser()
    })
      .then(response => {
        AlertaSuccess("Edital adicinado as licitações com sucesso!");
      })
      .catch(error => {
      });
  };

  @action changePortal = ({ target: { value } }) => {
    this.portal = $("#portais-select")
      .val()
      .map(el => Number(el));
  };

  @action changePerfil = async id => {
    this.perfilAtivado = id;
    await this.filtrarEditais();
    SideStores.consulta_notificacoes();
  };

  // Ações modal detalhes
  @action
  mostrarDetalhes = async edital => {
    const { id } = edital;
    ModalLoadingStore.handleOpenModal();
    try {
      const res = await Api.post("portal/itens_edital/", { id });
      this.detalhesEdital = {
        ...edital,
        dados_singulares: res.data.itens_edital.reverse()
      };
      if (this.modalPropostaActive) this.modalPropostaActive = false;
      this.modalDetalhesActive = true;
    } catch (err) {
      // AlertaVermelho(err.data)
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  closeModalDetalhes = () => (this.modalDetalhesActive = false);

  // Ações modal proposta
  @action
  mostrarProposta = async edital => {
    if (this.modalDetalhesActive) this.modalDetalhesActive = false;
    this.dadosProposta = edital;
    this.modalPropostaActive = true;

  };

  @action
  closeModalProposta = () => (this.modalPropostaActive = false);

  @action
  resetarFiltros = () => {
    this.btnExcluido = false;
    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.pagina_atual = 1;
    this.fecharDate();
  };

  @action
  handelBtnExcluidos = () => {
    this.allChecked = false;
    this.btnExcluido = true;
    this.btnFiltrar = false;
    this.btnFiltrarDia = false;
    this.btnFavorito = false;
    this.pagina_atual = 1;
    this.portal = this.todosPortais.map(p => p.id);
    this.fecharDate();
    this.listar_excluidos();
  };

  @action
  handelBtnFiltrar = () => {
    this.allChecked = false;
    this.btnFiltrar = true;
    this.btnFiltrarDia = false;
    this.btnFavorito = false;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.fecharDate();
    this.portal = this.todosPortais.map(el => el.id);
    this.filtrarEditais();
  };

  // @action
  // handelBtnFiltrarME = () => {
  //   this.btnFiltrar = true;
  //   this.btnFiltrarDia = false;
  //   this.btnFavorito = false;
  //   this.btnExcluido = false;
  //   this.pagina_atual = 1;
  //   this.fecharDate();
  //   this.filtrarEditaisME();
  // };

  @action
  handelBtnFiltrarDia = () => {
    this.allChecked = false;
    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.fecharDate();
    this.limparfiltro();
  };

  @action
  handelBtnFiltraFavorito = () => {
    this.allChecked = false;
    this.btnFiltrar = false;
    this.btnFiltrarDia = false;
    this.btnFavorito = true;
    this.btnExcluido = false;
    this.pagina_atual = 1;
    this.portal = this.todosPortais.map(p => p.id);
    this.listar_favoritos();
  };

  @action filtrarEditais = async () => {
    if (this.btnFavorito) return;
    this.isFetching = true;
    this.baixarNovamente = false;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 1;
    if (!this.portal.length)
      return AlertaInfo(
        "Você precisa selecionar ao menos 1 portal para realizar a pesquisa."
      );
    ModalLoadingStore.handleOpenModal();
    await this.getDownloadLink(); // Verifica se há link de download de todos disponível para aquela empresa.
    try {
      const res = await Api.post(
        "portal/editais_selecionado/",
        this.loadData()
      );
      const { config_perfis } = res.data;
      this.perfilAtivado = config_perfis.length
        ? config_perfis.find(el => el.activated).id
        : null;
      this.configPerfis = config_perfis;
      this.editais_total = res.data.total_registros;
      this.editais = res.data.editais;
      this.jaBaixados = res.data.total_download;
      this.firstTime = false;
      this.Tag = res.data.palavras_usadas
        ? String(res.data.palavras_usadas)
        : "";
      this.baixarNovamente = res.data.editais.reduce((acc, atual) => {
        return atual.edital_selecionado[0].download_edital ? acc + 1 : acc;
      }, 0);
    } catch (e) {
      this.editais = [];
    } finally {
      this.isFetching = false;
      this.styleData = this.pesquisaDataType;
      this.flagConsultar = 1;
      if (this.firstFetch) this.firstFetch = false;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action getDownloadLink = async () => {
    const { getIdEmpresa } = MaquinaDeEstadosStore;
    const queryString = `?fornecedor_pk=${getIdEmpresa()}`;

    try {
      const res = await Api.get(`/portal/historico_download/${queryString}`);
      if (res.data.message) {
        const {
          url,
          data_pesquisa,
          quantidade,
          hora_criacao
        } = res.data.message;
        if (quantidade === 0) throw "Nenhum edital novo";
        this.linkDisponivel = url;
        this.dataPesquisaDownload = data_pesquisa;
        this.requestGerarLink = false;
        this.downloadsNoZip = quantidade;
        this.horaCriacao = hora_criacao;
      }
      if (res.data.message === false) {
        this.linkDisponivel = false;
        this.dataPesquisaDownload = undefined;
        this.downloadsNoZip = undefined;
        this.horaCriacao = undefined;
      }
    } catch (err) {
    } finally {
    }
  };

  @action handleCloseModal = () => {
    this.isActiveEditar = false;
  };

  @action handleOpenModal = () => {
    this.isActiveEditar = true;
  };

  @action onChangeDataSimples = data => {
    this.dataSimples = data;
  };

  @action handleOrdenacao = () => {
    this.ordenar = this.ordenar === "disputa" ? "abertura" : "disputa";
    if (this.btnFavorito) return;
    this.filtroMe ? this.filtrarEditaisME() : this.filtrarEditais();
  };

  @action handleStatusFavoritos = ({ target: { value, checked } }) => {
    this.pagina_atual = 1;
    this[value] = checked;
  };

  @action handleStatusPesquisa = ({ target: { value, checked } }) => {
    switch (value) {
      case "abertura":
        this.statusDataType = !this.statusDataType;
        this.pesquisaDataType = checked ? "abertura" : "disputa";
        break;
      case "disputa":
        this.statusDataType = !this.statusDataType;
        this.pesquisaDataType = checked ? "disputa" : "abertura";
        break;
      case "me":
        this.filtroMe = !this.filtroMe;
        break;
      default:
    }
  };

  @action consultaEdital = () => {
    this.handelBtnFiltrar();
  };

  @action onChangeData = ({ target: { value, name } }) => {
    this[name] = value;
  };

  @action fixPaginaAtual = () => {
    if (this.pagina_atual === 0) this.pagina_atual = 1;
  };

  @action loadData = () => {
    this.fixPaginaAtual();
    if (!this.tipo_item.length) this.tipo_item = ["M", "S"];
    return {
      tipo: this.pesquisaDataType,
      dmin: this.dataInicial,
      dmax: this.dataFinal,
      pagina: this.pagina_atual,
      ordenar: this.ordenar,
      fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
      portal: this.portal,
      tipo_item: this.tipo_item,
      empresa_me: this.filtroMe,
      perfil_ativado: Number(this.perfilAtivado) || null
    };
  };

  @action listar_favoritos = async () => {
    this.isFetching = true;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 2;

    let ativo = this.pesquisaDataTypeFavoritos === "ativos";
    let vencido = this.pesquisaDataTypeFavoritos === "vencidos";
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/qualificados/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa(),
        ativo: this.favoritosAtivo,
        vencido: this.favoritosVencido,
        empresa_me: this.filtroMe,
        portal: this.portal
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
    } finally {
      this.isFetching = false;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action listar_excluidos = async () => {
    this.editais = [];
    this.isFetching = true;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 4;
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/listar_excluidos/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
    } finally {
      this.isFetching = false;
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action onPageChanged = data => {
    const { currentPage } = data;
    this.lista_download = [];
    this.fixPaginaAtual();
    if (this.pagina_atual !== currentPage) {
      this.pagina_atual = currentPage;
      this.handleConsulta(currentPage);
      this.allChecked = false;
    }
  };

  @action handleConsulta = () => {
    if (this.firstFetch) return;
    if (this.flagConsultar === 1) this.filtrarEditais();
    if (this.flagConsultar === 2 && !this.isFetching) this.listar_favoritos();
    if (this.flagConsultar === 3 && !this.isFetching) this.filtrarEditaisME();
    if (this.flagConsultar === 4 && !this.isFetching) this.listar_excluidos();
  };

  @action excluirEdital = id => {
    Api.post("portal/alterar_excluidos/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      tipo: true
    })
      .then(response => {
        AlertaSuccess("Edital excluído!");
        this.handleConsulta();

        SideStores.consulta_notificacoes();
        // SideStores.consulta_notificacoes_update()
      })
      .catch(error => {
        AlertaVermelho("Não foi possível excluir o edital");
      });
  };

  @action restaurarEdital = async id => {
    this.isFetching = true;
    try {
      await Api.post("portal/alterar_excluidos/", {
        id_edital: id,
        fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
        tipo: false
      });
      await this.listar_excluidos();
      AlertaSuccess("Edital Restaurado!");
    } catch (error) {
    } finally {
      this.isFetching = false;
    }
  };

  @action listar_excluidos = async () => {
    this.editais = [];
    this.isFetching = true;
    this.styleData = this.pesquisaDataType;
    this.flagConsultar = 4;
    ModalLoadingStore.handleOpenModal();
    try {
      const response = await Api.post("portal/listar_excluidos/", {
        pagina: this.pagina_atual,
        fornecedor_pk: MaquinaDeEstadosStore.getIdEmpresa()
      });
      this.editais_total = response.data.total_registros;
      this.editais = response.data.editais;
    } catch (error) {
    } finally {
      this.isFetching = false;

      ModalLoadingStore.handleCloseModal();
    }
  };

  @action excluirPermanente = id => {
    Api.post("portal/excluir_edital_selecionado/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaSuccess("Edital excluído permanentemente!");
        this.handleConsulta();
        SideStores.consulta_notificacoes();
        // SideStores.consulta_notificacoes_update()
      })
      .catch(error => {
        AlertaVermelho("Não foi possível excluir o edital");
      });
  };

  @action excluir_lixeira() {
    let id = MaquinaDeEstadosStore.getIdEmpresa();
    Api.get("portal/limpar_lixeira/" + id, {})
      .then(response => {
        AlertaSuccess("A Lixeira foi limpa com sucesso!");
        this.handleConsulta();
        SideStores.consulta_notificacoes();
      })
      .catch(error => {
        AlertaVermelho("Não foi possível excluir o edital");
      });
  }

  @action consultaApiEditalID = id => {
    Api.post("portal/itens_edital/", { id: id })
      .then(response => {
        this.itens_edital = response.data.itens_edital;
      })
  };

  @action favoritar = (id, buscaManual) => {
    Api.post("portal/qualifica_edital/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      id_perfil: buscaManual ? null : this.perfilAtivado
    })
      .then(res => {
        if (buscaManual) {
          const { editais } = BuscaDeEditaisStores;
          const { editais: editaisLicitacao } = BuscaDeEditaisLicitacaoStores;
          if (editais) {
            BuscaDeEditaisStores.editais = BuscaDeEditaisStores.editais.map(
              ed => (ed.id === id ? { ...res.data.message } : ed)
            );
          }
          if (editaisLicitacao)
            BuscaDeEditaisLicitacaoStores.editais = editaisLicitacao.map(ed =>
              ed.id === id ? { ...res.data.message } : ed
            );
        } else {
          this.handleConsulta();
        }
        AlertaSuccess("Adicionado aos favoritos com sucesso");
      })
      .catch(error => {
        AlertaVermelho("Não foi possível favoritar o edital.");
      });
  };

  @action desfavoritar = id => {
    Api.post("portal/tirar_favorito/", {
      id_edital: id,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa()
    })
      .then(response => {
        AlertaInfo("Removido dos favoritos!");
        this.handleConsulta();
      })
      .catch(error => {
        AlertaVermelho("Não foi possível desfavoritar o edital");
      });
  };

  @action abreDate = () => {
    this.showing = true;
  };

  @action fecharDate = () => {
    this.showing = false;
    if (this.qualData === "final") this.qualData = "inicial";
  };

  @action handleSelect = range => {
    this.dataInicial = new Date(range.startDate).toLocaleDateString("pt-br");
    this.dataFinal = new Date(range.endDate).toLocaleDateString("pt-br");
    if (this.qualData === "final")
      setTimeout(() => {
        this.fecharDate();
        this.qualData = "inicial";
        this.filtrarEditais();
      }, 250);
    else this.qualData = "final";
  };

  @action limparfiltro = () => {
    this.favorito = false;
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.portal = this.todosPortais.map(el => el.id);
    this.filtrarEditais();
  };

  // funções para download

  @action adicionar_excluir_edital_lista_download(
    { target: { checked } },
    listItem
  ) {
    let lista = [...this.lista_download];
    if (checked) {
      const incluiEdital = lista.map(item => item.id).includes(listItem.id);
      if (!incluiEdital) lista.push(listItem);
    } else {
      lista = lista.filter(item => item.id !== listItem.id);
    }
    this.lista_download = lista;
  }

  @action adicionarEditalLista = id => {
    const incluiEdital = this.lista_download.includes(id);
    if (incluiEdital)
      this.lista_download = this.lista_download.filter(item => item !== id);
    else this.lista_download.push(id);
  };

  statusPortal = nome_portal => {
    if (nome_portal === "Licitações-e" || nome_portal === "ComprasNet") {
      return true;
    }
    return false;
  };

  @action marcar_todos_editais = checked => {
    checked
      ? (this.lista_download = [...this.editais]
        .filter(edital => {
          return this.statusPortal(edital.nome_portal) === true && edital.id;
        })
        .map(edital => edital.id))
      : (this.lista_download = []);
  };

  @action download_lista = async () => {
    const { empresas, id_fornecedor } = MaquinaDeEstadosStore;

    if (!this.lista_download.length)
      return AlertaAzul("Selecione os editais que deseja baixar.");

    const empresaName = empresas.find(
      empresa => Number(empresa.id) === Number(id_fornecedor)
    ).nome_fantasia;
    const fornecedor_pk = MaquinaDeEstadosStore.getIdEmpresa();
    const date = FormatDateData(new Date());
    const nome_arquivo = `${this.lista_download.length}_Editais_${empresaName}_${date}.zip`; // Nome da empresa _ data

    const dados = [...this.lista_download];
    this.fetchingDownload = true;
    try {
      const res = await Api.post(
        "portal/download_zip/",
        { dados, fornecedor_pk },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nome_arquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => {
        this.lista_download = [];
      }, 2500);
    } catch (err) {
    } finally {
      this.fetchingDownload = false;
    }
  };

  @action download_todos_dia = async bool => {
    const fornecedor = `?fornecedor_pk=${MaquinaDeEstadosStore.getIdEmpresa()}`;
    const empresa_me = `&empresa_me=${this.filtroMe}`;
    const publicacao = `&publicacao=${this.styleData === "abertura"}`;
    const data = `&data=${this.dataInicial}`;
    const download = `&download=${bool}`;

    try {
      const res = await Api.get(
        `/portal/download_zip_todos/${fornecedor +
        empresa_me +
        publicacao +
        data +
        download}`
      );
      if (res.data.message) {
        AlertaDownload(
          "Seus arquivos estão sendo processados e em breve estarão disponíveis para download"
        );
        this.linkDisponivel = false;
        this.dataPesquisaDownload = undefined;
        this.requestGerarLink = true;
        this.requestInterval = setInterval(() => {
          if (this.linkDisponivel) return clearInterval(this.requestInterval);
          this.getDownloadLink();
        }, 20000);
      }
    } catch (err) {
      AlertaVermelho("Erro ao baixar os editais, tente novamente mais tarde!");
    }
  };

  @action downloadUnitario = async edital => {
    const { id, numero, nome_portal } = edital;
    const dados = [id];
    const fornecedor_pk = MaquinaDeEstadosStore.getIdEmpresa();
    const nome_arquivo = `Edital_${numero}_${nome_portal}.zip`;

    this.downloadUnitarioFetching = id;
    try {
      const res = await Api.post(
        "portal/download_zip/",
        { dados, fornecedor_pk },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nome_arquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      AlertaVermelho("Não foi possível fazer o download do edital");
    } finally {
      this.downloadUnitarioFetching = false;
    }
  };

  @action buscarHistorico = async () => {
    try {
      this.fetchingHistorico = true;
      const res = await Api.get("/portal/historico_download/");
      this.historicoDownloads = res.data.message;
    } catch (err) {
    } finally {
      this.fetchingHistorico = false;
    }
  };

  @action limparVariaveis = () => {
    this.modalHistoricoDownloadsActive = false;
    this.dataInicial = new Date().toLocaleDateString("pt-br");
    this.dataFinal = new Date().toLocaleDateString("pt-br");
    this.qualData = "inicial";

    this.pesquisaDataType = "abertura";
    this.pesquisaDataTypeFavoritos = "ativos";
    this.dataTypeFavoritosME = false;
    this.dataTypeFavoritosAtivo = true;
    this.dataTypeFavoritosVencido = true;
    this.jaBaixados = null;

    this.tipo_item = ["M", "S"];

    // Perfis
    this.configPerfis = [];
    this.perfilAtivado = null;

    // Portal

    this.portal = this.todosPortais.map(el => el.id);

    this.baixarNovamente = false;
    this.statusDataType = true;
    this.showing = false;
    this.pagina_atual = 1;
    this.editais = [];
    this.editais_total = 0;
    //ordenação
    this.ordenar = "disputa";
    this.ordenarAbertura = false;

    // Download de todos
    this.linkDisponivel = false;
    this.dataPesquisaDownload = undefined;
    this.requestGerarLink = false;
    this.requestInterval = undefined;
    this.downloadsNoZip = undefined;
    this.horaCriacao = undefined;

    // Modal mostrar detalhes

    this.modalDetalhesActive = false;
    this.detalhesEdital = null;

    // Modal proposta
    this.modalPropostaActive = false;
    this.dadosProposta = null;

    this.id_excluir = 0;
    this.lista_download = [];
    this.lista_corrida = [];
    this.cont_download_em_andamento = 0;
    this.downloadHashList = [];

    this.favorito = false;
    this.currentEditais = [];
    this.currentPage = null;
    this.totalPages = null;
    this.numero_editais_totais = 0;
    this.mudar_key = "";
    this.pagina_visualizada = "";
    this.itens_edital = [];
    this.flagStatus = true;
    this.statusAtulizar = true;
    this.styleData = "abertura";
    this.filtroMe = false;
    this.flagConsultar = 1;
    this.Tag = null;
    this.isFetching = false;
    this.isActiveEditar = false;
    this.btnFiltrar = false;
    this.btnFiltrarDia = true;
    this.btnFavorito = false;
    this.btnExcluido = false;
    this.editalID = 0;
    this.allChecked = false;
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.filtrarEditais();
  };
}

const editaisStores = new EditaisStores();

export const EditaisStoresContext = createContext(editaisStores);
export default editaisStores;
