import styled from 'styled-components'

export const Format = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const TableLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 50%;
  box-shadow: 1px 1px 5px #00000057;
  border-radius: 8px;
  margin: 0 1%;

  .header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid gray;
    background: #3a566e;
    color: #fff !important;
    height: 40px !important;
    border-radius: 8px 8px 0 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;

    .item {
      width: 50%;
    }

    p {
      text-align: center;
      font-size: 13px;
      margin: 19px 0 0 0;
      color: black;
    }
  }
`

export const Table = styled.div`
  width: 50%;
  height: 100%;
  background: #fff;
  box-shadow: 1px 1px 5px #00000075;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: initial;
  position: relative;
  margin: 0 1%;

  .header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid gray;
    background: #3a566e;
    border-radius: 8px 8px 0 0;
    height: 40px !important;
    color: #fff !important;
  }

  .body {
    display: flex;
    flex-direction: column;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;

    .item {
      width: 50%;
    }

    p {
      text-align: center;
      font-size: 13px;
      margin: 19px 0 0 0;
      color: black;
    }
  }
`