import {
  AlertaMsgVerificada,
  CustomAlertNotification,
  ToastSuccess,
  ToastError
} from "./Funcoes/FuncoesGerais";
import MenuStores from "./MenuStores";
import ConfiguracoesMonitoramentoStores from "./ConfiguracoesMonitoramentoStores";
import OcorrenciasDoDiaStores from "./OcorrenciasDoDiaStores";
import EditaisStores from "./EditaisStores";
import SideStores from "./SideStores";
import {
  getMessaging,
  deleteToken,
  onMessage,
  getToken
} from "firebase/messaging";
import "../Debug";
import { getMonitoramento } from "../Services/notificacao";
import MonitoramentoStore from "./MonitoramentoStore";
import { playSom } from "../som/SomSistema";
import Alert from "react-s-alert";
import { getPortalName } from "../Services/portais";
import DownloadEditaisStore from "../Pages/Prospection/DownloadList/store";
import painelAvisoStores from "./PainelAvisoStores";
import ItensPropostaStore from "../Pages/Proposal/CadastrarProposta/contexto/Itens/store";
import GerenciarPropostaStore from "../Pages/Proposal/GerenciarProposta/store";
import { CustomToast } from "../UsefulComponents/CustomToasts";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import { ConsoleHelper } from "../utils/ConsoleHelper";
import { firebaseApp } from "../firebase";
import { isMainTab } from "./MainTab";
import firebaseConfig from "../firebaseConfig.json";
import Api from "./Conexao/conexao";
import resolve from "resolve";

const playAudio = (audio, portal, situacao, alerta_sonoro) => {
  if (OcorrenciasDoDiaStores.som) {
    isMainTab().then(mainTab => {
      if (mainTab) playSom(audio, portal, situacao, alerta_sonoro);
    });
  }
};

let timeoutId = null;
let empresa = [];

const resetTimeoutMessage = () => {
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    MaquinaDeEstadosStore.setIsPushWorking(false);
  }, 120000);
};

const handleMensagem = payload => {
  MonitoramentoStore.verificada = false;
  const { configuracao_empresas } = ConfiguracoesMonitoramentoStores;
  if (payload.data === undefined) return;
  const {
    tipostring,
    url,
    descricao_tipo,
    fornecedor_nome,
    licitacao_codigo,
    licitacao_id,
    fornecedor_id,
    portal_id,
    portal,
    situacao,
    alerta_sonoro,
    credencial_id,
    monitoramento
  } = payload.data;

  ConsoleHelper("test type string: ", payload.data);

  if (tipostring) {
    MaquinaDeEstadosStore.setIsPushWorking(true);
    resetTimeoutMessage();
  }

  if (tipostring === "download_vazio") {
    clearInterval(EditaisStores.requestInterval);
    EditaisStores.linkDisponivel = false;
    EditaisStores.dataPesquisaDownload = undefined;
    EditaisStores.downloadsNoZip = undefined;
    EditaisStores.horaCriacao = undefined;
    EditaisStores.requestGerarLink = false;
    DownloadEditaisStore.errorDownload = true;
    const message =
      "Os editais ainda estão sendo processados, tente mais tarde.";
    return ToastError("Nenhum edital encontrado.");
    // return AlertaDownloadVazio(message);
  }

  // Corrigir bug que acontece quando usamos mesmo CNPJ em produção e em Dev
  if (
    fornecedor_id &&
    !MaquinaDeEstadosStore.empresas.find(em => em.id == fornecedor_id)
  )
    return;

  const newEmpresa = configuracao_empresas.find(item => {
    return Number(item.fornecedor_id) === Number(fornecedor_id);
  });

  const config = {
    empresa: fornecedor_nome,
    licitacao_cod: licitacao_codigo,
    id_licitacao: licitacao_id,
    id_fornecedor: fornecedor_id,
    type: tipostring,
    descricao_tipo,
    portal,
    situacao,
    tipostring
  };

  if (tipostring === "verificada_monitoramento")
    return AlertaMsgVerificada("Mensagens verificadas");

  // if (tipostring === 'alerta_push') {
  //   return ()
  // }

  if (tipostring === "spreadsheet") {
    switch (window.location.pathname) {
      case "/proposta/gerenciar":
        GerenciarPropostaStore.fetchPropostas();
        break;
      case "/proposta/cadastrar":
        ItensPropostaStore.propostaEnviada = true;
        ItensPropostaStore.aguardandoResposta = false;
        break;
      default:
        break;
    }

    return ToastSuccess(descricao_tipo);
  }

  if (tipostring === "credencial") {
    MaquinaDeEstadosStore.empresas = MaquinaDeEstadosStore.empresas.map(em => {
      if (em.credenciais.find(cred => cred.id == credencial_id)) {
        return {
          ...em,
          credenciais: em.credenciais.map(cr =>
            cr.id == credencial_id ? { ...cr, expired: true } : cr
          )
        };
      }
      return em;
    });
    MaquinaDeEstadosStore.updateCredenciais();
  }

  if (tipostring === "doc_proposta") {
    const { enviado, nome_arquivo, error } = payload.data;
    if (enviado) {
      ItensPropostaStore.attachedDocuments = ItensPropostaStore.attachedDocuments.map(
        el =>
          el.name === nome_arquivo
            ? { ...el, loading: false, enviado: true }
            : el
      );
      ToastSuccess(`📋 Documento ${nome_arquivo} enviado com sucesso.`);
    } else
      ToastError(`Erro ao enviar o documento ${nome_arquivo}. ${error || ""}`);
  }

  if (tipostring === "doc_habilitacao") {
    const { enviado, nome_arquivo, error } = payload.data;
    if (enviado) {
      ItensPropostaStore.attachedDocuments = ItensPropostaStore.attachedDocuments.map(
        el =>
          el.name === nome_arquivo
            ? { ...el, loading: false, enviado: true }
            : el
      );
      ToastSuccess(`📋 Documento ${nome_arquivo} enviado com sucesso.`);
    } else
      ToastError(`Erro ao enviar o documento ${nome_arquivo}. ${error || ""}`);
  }
  if (tipostring === "verificada") {
    console.log("verificada");
    // playChain(["atencao", "encontramos-mensagens-importantes", "comprasnet"]);
    SideStores.consulta_notificacoes();
    if (window.location.pathname === "/monitoramento") return;
    AlertaMsgVerificada("Mensagem Verificada");
    MonitoramentoStore.consulta_especifica(licitacao_id);
    return OcorrenciasDoDiaStores.consultaApi(true);
  }

  if (
    tipostring === "situacao" &&
    window.location.pathname === "/painel_monitoramento"
  )
    painelAvisoStores.consulta_api(fornecedor_id);

  if (tipostring === "todas_verificada") {
    AlertaMsgVerificada(
      `Todas as mensagens foram verificadas para ${fornecedor_nome}`
    );
    SideStores.consulta_notificacoes();
    console.log("todas_verificada");
    return OcorrenciasDoDiaStores.consultaApi(true);
  }

  if (tipostring === "download" && !MenuStores.fullScreen) {
    return DownloadEditaisStore.provideUrl(url);
  }

  if (getMonitoramento() && portal_id && OcorrenciasDoDiaStores.som) {
    playAudio(
      alerta_sonoro,
      tipostring,
      getPortalName(portal_id).toLowerCase(),
      situacao.trim()
    );
  }

  if (!MenuStores.fullScreen) {
    if (
      Number(MaquinaDeEstadosStore.getIdEmpresa()) !== Number(fornecedor_id)
    ) {
      if (newEmpresa && newEmpresa.monitoramento) {
        if (empresa.indexOf(fornecedor_id) === -1) {
          ToastSuccess(`Alerta de notificação para ${fornecedor_nome}`);
          empresa.push(fornecedor_id);
        }
      }
    } else {
      if (getMonitoramento() === "true") {
        const {
          mensagem: { monitoramento: mensagemMonitoramento },
          especifica: { monitoramento: especificaMonitoramento },
          empresa: { monitoramento: empresaMonitoramento },
          palavra_importante: { monitoramento: palavraimportanteMonitoramento },
          situacao: { monitoramento: situacaoMonitoramento }
        } = ConfiguracoesMonitoramentoStores;

        switch (tipostring) {
          case "mensagem": {
            if (mensagemMonitoramento)
              MaquinaDeEstadosStore.reloadSearchNewNotifications();

            CustomToast(config, { className: "toast-mensagem" }, () =>
              MaquinaDeEstadosStore.redirect(licitacao_id)
            );
            break;
          }
          case "importante": {
            if (palavraimportanteMonitoramento) {
              MaquinaDeEstadosStore.reloadSearchNewNotifications();
              OcorrenciasDoDiaStores.consultaApi(true);
            }

            CustomToast(config, { className: "toast-importante" }, () =>
              MaquinaDeEstadosStore.redirect(licitacao_id)
            );
            break;
          }
          case "especifica": {
            if (especificaMonitoramento)
              MaquinaDeEstadosStore.reloadSearchNewNotifications();

            CustomToast(config, { className: "toast-importante" }, () =>
              MaquinaDeEstadosStore.redirect(licitacao_id)
            );
            break;
          }
          case "empresa": {
            if (empresaMonitoramento)
              MaquinaDeEstadosStore.reloadSearchNewNotifications();
            OcorrenciasDoDiaStores.consultaApi(true);

            CustomToast(config, { className: "toast-importante" }, () =>
              MaquinaDeEstadosStore.redirect(licitacao_id)
            );
            break;
          }
          case "situacao": {
            if (situacaoMonitoramento)
              MaquinaDeEstadosStore.reloadSearchNewNotifications();
            CustomToast(config, { className: "toast-situacao" });
            break;
          }
          default: {
            CustomAlertNotification(config, descricao_tipo);
          }
        }
      }

      SideStores.consulta_notificacoes();
      MonitoramentoStore.consultaFirebase();
    }
  }
};

export const removeServiceWorkers = () => {
  if (navigator.serviceWorker) {
    return navigator.serviceWorker
      .getRegistrations()
      .then(async registrations => {
        for (const registration of registrations) {
          await registration.unregister().catch(error => console.log(error));
        }
      });
  }
};

let serviceWorkerInterval = null;
export const enableServiceWorkerPing = () => {
  if (serviceWorkerInterval !== null) return;
  serviceWorkerInterval = setInterval(() => {
    try {
      const controller = navigator.serviceWorker.controller;
      if (controller) {
        const channel = new MessageChannel();
        channel.port2.addEventListener("message", event => {
          ConsoleHelper(event.data);
          channel.port2.close();
        });
        channel.port2.start();
        controller.postMessage(
          {
            type: "ping"
          },
          [channel.port1]
        );
      } else {
        ConsoleHelper("Service worker not active");
      }
    } catch (error) {
      console.error(error);
    }
  }, 5000);
};

const disableServiceWorkerPing = () => {
  if (serviceWorkerInterval === null) return;
  clearInterval(serviceWorkerInterval);
  serviceWorkerInterval = null;
};

export const startServiceWorkers = async () => {
  if (navigator.serviceWorker) {
    const registration = await navigator.serviceWorker.getRegistration("/");
    if (registration) {
      await registration.update();
    } else {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        {
          scope: "/"
        }
      );
      await new Promise((resolve, reject) => {
        let serviceWorker;

        if (registration.installing) {
          serviceWorker = registration.installing;
        } else if (registration.waiting) {
          serviceWorker = registration.waiting;
        } else if (registration.active) {
          serviceWorker = registration.active;
        }

        if (serviceWorker) {
          serviceWorker.addEventListener("statechange", function(e) {
            if (e.target.state === "activated") {
              resolve();
            }
          });
          if (serviceWorker.state === "activated") {
            resolve();
          }
        } else {
          reject("Service worker not found");
        }
      });
    }

    const messaging = getMessaging(firebaseApp);
    try {
      await getToken(messaging, {
        vapidKey: firebaseConfig.vapidKey
      });
    } catch (error) {
      if (
        "message" in error &&
        error.message.includes("no active Service Worker")
      ) {
        window.location.reload();
        await new Promise(resolve => setTimeout(() => resolve(), 10000));
      } else {
        throw error;
      }
    }

    enableServiceWorkerPing();
  }
};

let initPushNotificationPromise = null;

const registerPushNotification = async () => {
  try {
    const messaging = getMessaging(firebaseApp);
    const token = await getToken(messaging, {
      vapidKey: firebaseConfig.vapidKey
    });

    registerCallbackOnMessage(handleMensagem);
    const response = await Api.post("monitoramento/cadastra_topico/", {
      token
    });
    if (response.status === 200) {
      MaquinaDeEstadosStore.setIsPushWorking(true);
    } else {
      MaquinaDeEstadosStore.setIsPushWorking(false);
    }
  } catch (error) {
    MaquinaDeEstadosStore.setIsPushWorking(false);
    console.error(error);
  }
};

export const registerCallbackOnMessage = callback => {
  const messaging = getMessaging(firebaseApp);
  const broadcastChannel = new BroadcastChannel("firebase-notification");
  let currentIndex = 0;
  const maxIndex = 10;
  const messageIds = [];
  const handlePayload = payload => {
    if (payload.data === undefined) return;
    if (payload.messageId in messageIds) return;
    messageIds[currentIndex] = payload.messageId;
    currentIndex = (currentIndex + 1) % maxIndex;
    callback(payload);
  };

  broadcastChannel.onmessage = event => {
    if (event.data === undefined) return;
    handlePayload(event.data);
  };
  const unsubscribe = onMessage(messaging, payload => {
    handlePayload(payload);
  });

  return () => {
    broadcastChannel.close();
    unsubscribe();
  };
};

const getInitPushNotificationPromise = async (retry = 3) => {
  try {
    await startServiceWorkers();
  } catch (error) {
    console.error(error);
    if (retry > 0) {
      return getInitPushNotificationPromise(retry - 1);
    } else {
      throw error;
    }
  }
  await registerPushNotification();
  resetTimeoutMessage();
};

export const initPushNotification = () => {
  if (initPushNotificationPromise) return initPushNotificationPromise;
  initPushNotificationPromise = getInitPushNotificationPromise().catch(
    () => (initPushNotificationPromise = null)
  );
  return initPushNotificationPromise;
};

export const removeFirebaseIndexedDB = async () => {
  if (window.indexedDB) {
    const dbs = await window.indexedDB.databases();
    for (const db of dbs) {
      if (db.name.match(/firebase/g)) {
        try {
          await window.indexedDB.deleteDatabase(db.name);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

export const unregisterFirebaseToken = async () => {
  try {
    const messaging = getMessaging(firebaseApp);
    await deleteToken(messaging).catch(error => {
      const messaging = getMessaging(firebaseApp);
      return deleteToken(messaging);
    });
    disableServiceWorkerPing();

    await removeServiceWorkers();
    await removeFirebaseIndexedDB();
  } catch (error) {
    console.error(error);
  }
};
