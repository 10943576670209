import styled from 'styled-components'

export const Container = styled.div`
  opacity: 0;
  animation: 700ms fadeIn ease-in-out forwards;
  overflow: auto;
  overflow: inherit;

  h3 {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    color: #bb0e0e;
  }

  #limpar-busca-manual {
    background: #d20808ab;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    box-shadow: 1px 1px 4px #00000080;
    font-size: 15px;
    padding: 5px 13px;

    svg {
      margin-right: 7px;
    }
  }

  .width-pattern {
    width: 1050px;
  }

  @media screen and (max-width: 3000px) {
    .width-pattern {
      width: 1635px;
    }
  }

  @media screen and (max-width: 1779px) {
    .width-pattern {
      width: 1110px;
    }
  }

  @media screen and (max-width: 1214px) {
    .width-pattern {
      width: 545px;
    }
  }
`

export const Body = styled.div`
  display: flex;
  height: calc(100vh - 135px);

  @media (max-height: 780px) {
    height: calc(100vh - 115px);
  }
`
