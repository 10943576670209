import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/newModal'
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from './styles'
import { FormGroup } from 'react-bootstrap'
import UsuariosInputConstumizado from '../InputsCustomizados/UsuariosInputConstumizado'
import { Edit } from 'react-feather'
import TelefoneInputCustomizado from '../InputsCustomizados/TelefoneInputCustomizado'
import SelectEmpresasCustomizado from '../InputsCustomizados/SelectEmpresasCustomizado'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import { AlertaError } from '../../../Stores/Funcoes/FuncoesGerais'

function ModalEditUser({ user }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  return (
    <ModalProvider>
      <Edit
        className="icon-scale"
        data-effect="solid"
        color="#3A566E"
        data-tip="EDITAR USUÁRIO"
        data-place="bottom"
        data-type="info"
        onClick={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <ModalLateral
          icon="users"
          title={'EDITAR USUÁRIO'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <UserStore toggleModal={setIsModalOpen} user={user} />
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const UserStore = inject('StoreConfiguracoesGerais')(
  observer(({ StoreConfiguracoesGerais, toggleModal, user }) => {
    const {
      empresas,
      usuarios,
      popularUser,
      onChange,
      onChangeRedefinir,
      toggleRedefinir,
      redefinirObject,
      redefinirSenha,
      validarAdmin,
      permissao,
      adminValidation,
      verificaAdmin
    } = StoreConfiguracoesGerais
    const { changePassword } = redefinirObject
    return (
      <UserInfo
        empresas={empresas}
        usuarios={usuarios}
        user={user}
        onChange={onChange}
        popularUser={popularUser}
        onChangeRedefinir={onChangeRedefinir}
        toggleRedefinir={toggleRedefinir}
        changePassword={changePassword}
        redefinirObject={redefinirObject}
        redefinirSenha={redefinirSenha}
        validarAdmin={validarAdmin}
        permissao={permissao}
        adminValidation={adminValidation}
        verificaAdmin={verificaAdmin}
        toggleModal={toggleModal}
      />
    )
  })
)

const UserInfo = props => {
  // const { onChange, key, modal_validacao_senha, adminValidation, email_usuario_admin, add_usuario_validacao_admin } = state;
  const {
    empresas,
    usuarios,
    popularUser,
    onChange,
    onChangeRedefinir,
    toggleRedefinir,
    changePassword,
    redefinirObject,
    redefinirSenha,
    validarAdmin,
    permissao,
    adminValidation,
    verificaAdmin,
    user,
    toggleModal
  } = props
  const { first_name, last_name, phone, id, username } = user

  const { is_superuser } = MaquinaDeEstadosStore.usuarioLogado

  const { oldPassword, newPassword } = redefinirObject

  useEffect(() => {
    popularUser(user)
    return () => {
      popularUser('closeModal')
    }
  }, [])

  const empresasDisponiveis = usuarios
    .find(user => user.id === id)
    .empresas.map(em => em.id)

  const isUnicoAdmin = usuarios.filter(user => user.is_superuser).length === 1

  const [mudancaEmail, setMudanca] = useState(false)

  const handleSubmit = (e, validate) => {
    e.preventDefault()

    if (changePassword) {
      const newPass = document.getElementById('redefinir-senha-nova').value
      const repeatPass = document.getElementById('redefinir-senha-nova-repetir')
        .value
      if (newPass !== repeatPass)
        return AlertaError(
          'Os campos NOVA SENHA e REPETIR SENHA precisam contar o mesmo valor!'
        )
      return redefinirSenha(toggleModal, id)
    }
    if (!validate) {
      if (username !== document.getElementById('email_cobranca').value)
        setMudanca(true)
      else setMudanca(false)
      return validarAdmin('editarUsuario')
    }
    return verificaAdmin('editarUsuario', toggleModal, id)
  }

  if (changePassword)
    return (
      <UserForm onSubmit={handleSubmit}>
        <UsuariosInputConstumizado
          titulo="Senha antiga"
          id="redefinir-senha-antiga"
          name="oldPassword"
          defaultValue={oldPassword}
          placeholder={'Insira sua senha antiga...'}
          type={'password'}
          onChange={onChangeRedefinir}
        />

        <UsuariosInputConstumizado
          titulo="Nova senha"
          id="redefinir-senha-nova"
          name="newPassword"
          defaultValue={newPassword}
          placeholder={'Insira a nova senha...'}
          type={'password'}
          onChange={onChangeRedefinir}
        />

        <UsuariosInputConstumizado
          titulo={'Repetir senha'}
          id="redefinir-senha-nova-repetir"
          name="repeatPassword"
          defaultValue={newPassword}
          placeholder={'Repita a nova senha...'}
          type={'password'}
          onChange={onChangeRedefinir}
        />
        <RedefinirSenha onClick={toggleRedefinir}>Voltar</RedefinirSenha>
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  if (adminValidation)
    return (
      <UserForm onSubmit={e => handleSubmit(e, true)}>
        <h5 style={{ textAlign: 'center' }}>
          {MaquinaDeEstadosStore.getEmailUser()}
        </h5>
        {mudancaEmail && (
          <h6 style={{ color: '#3a566e', margin: '2rem 0 ' }}>
            Seu email será atualizado e enviaremos uma nova senha de acesso.
          </h6>
        )}
        <UsuariosInputConstumizado
          titulo={'Digite a senha do administrador'}
          id={'inputAdminPw'}
          placeholder={'senha...'}
          type={'password'}
          onChange={onChange}
        />
        <RedefinirSenha onClick={() => validarAdmin('fecharModal')}>
          Voltar
        </RedefinirSenha>
        {/* Voltar para a tela anterior */}
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  return (
    <>
      <UserForm onSubmit={handleSubmit}>
        <FormGroup>
          <SelectEmpresasCustomizado
            id="empresas_cliente"
            onChange={onChange}
            defaultValue={empresasDisponiveis}
            empresas={empresas}
            titulo="Selecione as Empresas"
            disabled={!is_superuser}
          />
          <UsuariosInputConstumizado
            titulo={'Primeiro Nome'}
            id={'first_name'}
            defaultValue={first_name}
            placeholder={'Insira seu primeiro nome..'}
            type={'text'}
            onChange={onChange}
          />

          <UsuariosInputConstumizado
            titulo={'Último Nome'}
            id={'last_name'}
            defaultValue={last_name}
            placeholder={'Insira seu sobrenome..'}
            type={'text'}
            onChange={onChange}
          />

          <UsuariosInputConstumizado
            titulo={'E-mail'}
            id={'email_cobranca'}
            defaultValue={username}
            placeholder={'Insira um email válido..'}
            type={'email'}
            onChange={onChange}
          />
          <TelefoneInputCustomizado
            titulo="Telefone"
            id="phone"
            onChange={onChange}
            defaultValue={phone}
            placeholder="Insira seu telefone"
          />
          {is_superuser && (
            <div className="d-flex justify-content-around">
              <div>
                <input
                  type="radio"
                  name="permissao"
                  value="analista"
                  onChange={onChange}
                  checked={permissao === 'analista'}
                  disabled={user.is_superuser && isUnicoAdmin}
                />
                <small className="ml-2">Analista</small>
              </div>
              <div>
                <input
                  type="radio"
                  name="permissao"
                  value="administrador"
                  onChange={onChange}
                  checked={permissao === 'administrador'}
                />
                <small className="ml-2">Administrador</small>
              </div>
            </div>
          )}
        </FormGroup>
        <RedefinirSenha onClick={toggleRedefinir}>
          Redefinir senha
        </RedefinirSenha>
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    </>
  )
}

export default ModalEditUser
