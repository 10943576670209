import React from "react";

const statusHour = (hours, hora) => {
  if (hours) {
    return hours.indexOf(hora) > -1;
  }
};

export const CardHora = ({ hora, hours, handleHour }) => {
  let status = statusHour(hours, hora);
  return (
    <div
      onClick={() => handleHour(hora)}
      className="icon-scale"
      style={{ margin: "5px 10px" }}
    >
      <div style={{ color: status ? "#3A566E" : "#9b9b9b", fontSize: "22px" }}>
        <b>{hora}</b>
      </div>
      <div style={{ color: status ? "#3A566E" : "#9B9B9B", fontSize: "10px" }}>
        HORAS
      </div>
    </div>
  );
};
