import React from 'react'
import { Calendar } from 'react-feather'
import {
  CardMD,
  CardMDBarra,
  CardMDBody,
  CardMDHearder,
  Titulo
} from '../styleDashboard'
import {
  Badge,
  Barra1,
  Barra2,
  Container1,
  Container2,
  ContainerAgenda
} from './styleLicitacao'

export const Agenda = ({ item }) => {
  const { dia, mes, qtd } = item
  return (
    <ContainerAgenda>
      <Container1>
        <Badge>
          <div>{qtd}</div>
        </Badge>
      </Container1>
      <Barra1 />
      <Barra2 />
      <Container2>
        <div>{dia}</div>
        <span>{mes}</span>
      </Container2>
    </ContainerAgenda>
  )
}

export const AgendaLicitacoes = ({ data }) => {
  const { lic_semana } = data
  return (
    <CardMD color={'#0D2466'}>
      <CardMDHearder>
        <div style={{ marginRight: '15px' }}>
          <Calendar size={'35px'} />
        </div>
        <Titulo>
          <h5>LICITAÇÕES DA SEMANA</h5>
          <span>Licitações agendadas</span>
        </Titulo>
      </CardMDHearder>
      <CardMDBarra />
      <CardMDBody>
        {lic_semana.map((item, index) => (
          <Agenda item={item} key={index} />
        ))}
      </CardMDBody>
    </CardMD>
  )
}
