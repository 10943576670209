import styled from 'styled-components';
import { BotoesContainer } from '../info/styles';


export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 223;
  background: #000;
  background: #000000c9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 479px;
  background: #fff;
  box-shadow: 1px 1px 3px 0px #000;
  border-radius: 10px;
  opacity: 0;
  animation: 1s fadeIn ease-in forwards;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 30px;
  width: 100%;
  background: #28597b;
  text-transform: uppercase;
  align-items: center;
  color: white;
  font-size: 85%;
  font-weight: 500;

  svg {
    margin: 0px 10px;
    width: 20px;
    align-self: center;
    color: #ffffff;
  }

  button.close-button {
    position: absolute;
    right: -5px;
    top: -5px;
    background: #bd1c1c;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 1px 1px 7px 1px #101010;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
`;

export const Title = styled.div`
  h4 {
    text-align: center;
    color: #28597a;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const SelecionarOpcao = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: ${props => props.selected ? "#a8ecec82" : "#efefef"};
  cursor: pointer;
  box-shadow: 1px 1px 3px #0000006e;
  margin: 20px;
  align-items: center;
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.bgColor};
  width: 57px;
  height: 75px;
  background: #589ebf;
  border-radius: 3px;
  box-shadow: 1px 1px 3px #0000009c;
  margin-left: 5px;
  margin-right: 20px;

  .option-title {
    color: #fff;
    font-size: 10px;
    padding: 3px 12px;
    width: 122%;
    background: ${props => props.titleBgColor};
    border-radius: 3px;
    font-weight: 500;
    box-shadow: 1px 1px 3px #0000005c;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;

  b {
    font-weight: 700;
    font-size: 17px;
  }

  .passo-download {
    display: flex;
    margin: 5px 0px;
    align-items: center;
    b {
      color: #4c4c4c;
      font-weight: 700;
      font-size: 13px;
    }
    button {
      border: none;
      background: green;
      color: white;
      margin-left: 20px;
      font-size: 11px;
      border-radius: 3px;
      box-shadow: 1px 1px 3px #00000082;
    }
  }
  
  

  span {
    font-size: 13px;
    color: #808080;
    
    a {
      color: #007bff !important;
      svg {
        width: 14px;
        margin-bottom: 5px;
      }
    }
  }
`;

export const ContainerSelection = styled.div`
  margin-right: 10px;
  margin-left: auto;
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 3px #0000008f;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  .selection {
    transition: background-color 300ms ease-in;
    width: 18px;
    height: 18px;
    background: ${props => props.selected ? '#43c159' : 'transparent'};
    border-radius: 50%;
  }
`;

export const ActionButtons = styled(BotoesContainer)`
  margin-bottom: 20px;
`;