import React, { useContext, useEffect, useReducer, useState } from 'react';
import { PropostaEspecificaContext } from '../../context';
import { DadosPdfContext } from '../dadosPdf/context';
import { FluxoDeEnvioContext } from '../context';

const initialState = {
  filesProposta: [],
  filesHabilitacao: []
};

const actions = {
  SET_DOCS_PROPOSTA: 'SET_DOCS_PROPOSTA',
  SET_DOCS_HABILITACAO: 'SET_DOCS_HABILITACAO',
  SET_FILES_PROPOSTA: 'SET_FILES_PROPOSTA',
  SET_FILES_HABILITACAO: 'SET_FILES_HABILITACAO',
  CLEAN_DATA: 'CLEAN_DATA'
}

function reducer(state, action) {
  switch (action.type) {
    case actions.SET_DOCS_PROPOSTA:
      return { ...state, docsProposta: action.payload };
    case actions.SET_DOCS_HABILITACAO:
      return { ...state, docsHabilitacao: action.payload };
    case actions.SET_FILES_PROPOSTA:
      return { ...state, filesProposta: action.payload };
    case actions.SET_FILES_HABILITACAO:
      return { ...state, filesHabilitacao: action.payload };
    case actions.CLEAN_DATA:
      return initialState;
    default:
      return state;
  }
}

const DocumentosContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [docsProposta, setDocsProposta] = useState([]);
  const [docsHabilitacao, setDocsHabilitacao] = useState([]);


  const { cleanData: cleanFluxoEnvio } = useContext(FluxoDeEnvioContext);
  const { cleanData: cleanPdf } = useContext(DadosPdfContext);
  const { cleanData: cleanProposta } = useContext(PropostaEspecificaContext);

  const value = {
    ...state,
    docsProposta,
    docsHabilitacao,
    setDocsProposta,
    setDocsHabilitacao,
    setFilesHabilitacao: (files) => dispatch({ type: actions.SET_FILES_HABILITACAO, payload: files }),
    setFilesProposta: (files) => dispatch({ type: actions.SET_FILES_PROPOSTA, payload: files }),
    setStatus: (fileName, status, type) => {
      switch (type) {
        case 'proposta':
          setDocsProposta(docs => docs.map(doc => doc.name === fileName ? { ...doc, ...status } : doc));
          break;
        case 'habilitacao':
          setDocsHabilitacao(docs => docs.map(doc => doc.name === fileName ? { ...doc, ...status } : doc));
          break;
        default: return;
      }
    },
    cleanData: () => {
      dispatch({ type: actions.CLEAN_DATA });
      setDocsProposta([]);
      setDocsHabilitacao([]);
      cleanFluxoEnvio();
      cleanPdf();
      cleanProposta();
    }
  }



  return (
    <DocumentosContext.Provider value={value}>
      {children}
    </DocumentosContext.Provider>
  )
};

const DocumentosProvider = Provider;

export { DocumentosContext }
export default DocumentosProvider;