import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import $ from "jquery";
import { AlertaError, AlertaSuccess, ToastSuccess } from "./Funcoes/FuncoesGerais";
import { statusConexao } from "../StatusConexao/StatusConexao";
import StatusConexaoStores from "./StatusConexaoStores";

class StoreConfiguracoesGerais {
  @observable usuarios = []
  @observable empresas = []
  @observable fornecedores = []

  // VALIDAR SENHA DE ADMINISTRADOR
  @observable inputAdminPw = ''
  @observable adminValidation = false

  // USER INFO
  @observable email_cobranca = ''
  @observable first_name = ''
  @observable last_name = ''
  @observable phone = ''
  @observable permissao = null

  // REDEFINIR PASSWORD
  @observable redefinirObject = {
    changePassword: false,
    oldPassword: '',
    newPassword: ''
  }

  // ACTIONS
  @action popularStore = () => {
    const { empresas, usuarios, dadosUser } = MaquinaDeEstadosStore
    this.empresas = empresas
    this.usuarios = usuarios
    this.loggedInUser = dadosUser.user.id
  }

  @action popularUser = user => {
    if (user === 'closeModal') return this.limparDadosUsuario()
    this.email_cobranca = user.username
    this.fornecedores = user.empresas.map(em => em.id)
    this.first_name = user.first_name
    this.last_name = user.last_name
    this.phone = user.phone
    this.permissao = user.is_superuser ? 'administrador' : 'analista'
  }

  @action onChange = e => {
    if (e.target.name === 'permissao') return (this.permissao = e.target.value)
    const field = e.target.id
    this[field] = e.target.value
  }

  @action testePush = () => {
    ToastSuccess('aaa')
  }

  @action onChangeRedefinir = e => {
    const field = e.target.name
    this.redefinirObject[field] = e.target.value
  }

  @action toggleRedefinir = () => {
    this.redefinirObject.changePassword = !this.redefinirObject.changePassword
  }

  @action validarAdmin = tipo => {
    if (tipo === 'fecharModal') return (this.adminValidation = false)
    this.fornecedores = $('#empresas_cliente').val()
    if (this.validarInputs(tipo)) {
      this.adminValidation = true
    }
  }

  @action verificaAdmin = async (action, toggleModal, id) => {
    const data = {
      user_id: MaquinaDeEstadosStore.getIdUser(),
      password: this.inputAdminPw
    }

    try {
      await Api.post('/verifica_password', data, {})
      this[action](toggleModal, id)
      this.adminValidation = false
    } catch (err) {
      AlertaError('Senha inválida!')
    } finally {
      this.inputAdminPw = ''
    }
  }

  @action adicionarUsuario = async toggleModal => {
    const data = new FormData()
    this.fornecedores.forEach(item => {
      data.append('fornecedores[]', item)
    })
    data.append('username', this.email_cobranca)
    data.set('email_cobranca', this.email_cobranca)
    data.set('last_name', this.last_name)
    data.set('first_name', this.first_name)
    data.set('phone', this.phone)
    data.set('password', this.password)
    data.set('repeat_password', this.repeat_password)
    data.set('permissao', this.permissao)

    Api.post('cad_usuario/', data, {})
      .then(res => {
        AlertaSuccess('Uma senha temporária foi enviada ao seu email.', 15000)
        AlertaSuccess('Usuário criado com sucesso!', 15000)
        this.adicionarAoStore(res.data.message)
        this.limparDadosUsuario()
        toggleModal(false)
      })
      .catch(error => {
        if ((error.response.status = 401))
          return AlertaError('Email já cadastrado')
        AlertaError('Erro ao criar usúario!')
      })
  }

  @action editarUsuario = (toggleModal, id) => {
    const data = new FormData()
    this.fornecedores.forEach(item => {
      data.append('fornecedores[]', item)
    })
    data.append('username', this.email_cobranca)
    data.set('email_cobranca', this.email_cobranca)
    data.set('last_name', this.last_name)
    data.set('first_name', this.first_name)
    data.set('phone', this.phone)
    data.set('permissao', this.permissao)
    data.set('password', this.inputAdminPw)

    Api.post('cad_usuario/' + id + '/', data, {})
      .then(res => {
        AlertaSuccess('Usuário editado com sucesso!')
        this.atualizarNoStore(res.data.message)
        this.limparDadosUsuario()
        toggleModal(false)
      })
      .catch(error => {
        if ((error.response.status = 401))
          AlertaError('Erro ao editar usúario!')
      })
  }

  @action
  excluir_usuario = id => {
    if (Number(localStorage.getItem('user_id')) === Number(id)) {
      AlertaError(
        'Nao é possível excluir o usuário utilizado para a ação de exclusão !'
      )
    } else {
      Api.post('cad_usuario/' + id + '/delete/')
        .then(() => {
          AlertaSuccess('Usuario excluido com sucesso!')
          this.excluirUsuarioNoStore(id)
        })
        .catch(() => {
          if (!statusConexao()) StatusConexaoStores.handleOpenModal()
        })
    }
  }

  @action redefinirSenha = async (toggleModal, id) => {
    const { oldPassword, newPassword } = this.redefinirObject

    const data = new FormData()
    data.set('old_password', oldPassword)
    data.set('new_password1', newPassword)
    data.set('new_password2', newPassword)

    let res = await Api.post(`/password_change/${id}/`, data, {})
    res = res.data
    if (res.sucess) {
      AlertaSuccess(res.sucess);
      this.redefinirObject.changePassword = false;
      toggleModal(false);
      this.redefinirObject.newPassword = "";
    } else if (res.error)
      if(res.error.old_password)
      AlertaError(res.error.old_password[0]);
      else if(res.error.new_password2) AlertaError(res.error.new_password2[0]);
      else AlertaError("Falha ao mudar senha", 4000);
  };

  adicionarAoStore = user => {
    const { empresas, usuarios } = MaquinaDeEstadosStore
    // Adicionar o novo usuário ao array de empresas.
    MaquinaDeEstadosStore.empresas = empresas.map(empresa =>
      this.fornecedores.includes(String(empresa.id))
        ? { ...empresa, usuarios: [...empresa.usuarios, user] }
        : empresa
    )

    MaquinaDeEstadosStore.usuarios = [
      ...usuarios,
      {
        ...user,
        email: user.email,
        empresas: this.fornecedores.map(forn => ({
          id: String(forn)
        }))
      }
    ]
    this.popularStore()
  }

  atualizarNoStore = user => {
    const { empresas, usuarios, usuarioLogado } = MaquinaDeEstadosStore
    // Editar o novo usuário no array de empresas
    MaquinaDeEstadosStore.empresas = empresas.map(empresa =>
      this.fornecedores.includes(String(empresa.id))
        ? {
            ...empresa,
            usuarios: empresa.usuarios.map(el => el.id).includes(user.id)
              ? empresa.usuarios.map(el =>
                  user.id === el.id ? { ...el, ...user } : el
                )
              : [...empresa.usuarios, user]
          }
        : {
            ...empresa,
            usuarios: empresa.usuarios.filter(use => user.id !== use.id)
          }
    )

    MaquinaDeEstadosStore.usuarios = usuarios.map(us =>
      us.id === user.id
        ? {
            ...us,
            ...user,
            empresas: empresas.filter(em =>
              this.fornecedores.includes(String(em.id))
            )
          }
        : us
    )
    if (usuarioLogado.id === user.id)
      MaquinaDeEstadosStore.usuarioLogado = { ...usuarioLogado, ...user }
    this.popularStore()
  }

  excluirUsuarioNoStore = id => {
    const { empresas, usuarios } = MaquinaDeEstadosStore

    // Excluir usuário no array de empresas
    MaquinaDeEstadosStore.empresas = empresas.map(empresa => {
      return {
        ...empresa,
        usuarios: empresa.usuarios.filter(use => id !== use.id)
      }
    })

    MaquinaDeEstadosStore.usuarios = usuarios.filter(user => user.id !== id)
    this.popularStore()
  }

  limparDadosUsuario = () => {
    this.email_cobranca = ''
    this.first_name = ''
    this.last_name = ''
    this.phone = ''
    this.fornecedores = []
    this.permissao = ''
  }

  validarInputs = () => {
    if (this.empresas.length && !this.fornecedores.length) {
      AlertaError('Selecione ao menos uma empresa!')
      return false
    }
    if (!this.permissao) {
      AlertaError('Selecione o tipo de permissão para o usuário')
      return false
    }
    if (!this.first_name) {
      AlertaError('Campo NOME em branco!')
      return false
    }
    if (!this.last_name) {
      AlertaError('Campo SOBRENOME em branco!')
      return false
    }
    if (!this.email_cobranca) {
      AlertaError('Campo EMAIL em branco!')
      return false
    }
    if (!this.email_cobranca.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      AlertaError('EMAIL inválido!')
      return false
    }
    return true
  }

  @action trocouDeEmpresa = () => {
    this.popularStore()
  }

  @action limparVariaveis = () => {
    this.usuarios = []
    this.empresas = []
    this.fornecedores = []

    // VALIDAR SENHA DE ADMINISTRADOR
    this.inputAdminPw = ''
    this.adminValidation = false

    // USER INFO
    this.email_cobranca = ''
    this.first_name = ''
    this.last_name = ''
    this.phone = ''
    this.permissao = null

    // REDEFINIR PASSWORD
    this.redefinirObject = {
      changePassword: false,
      oldPassword: '',
      newPassword: ''
    }
  }
}

const storeConfiguracoesGerais = new StoreConfiguracoesGerais()
export default storeConfiguracoesGerais
