import React, {useEffect} from 'react'
import InputMask from 'react-input-mask'
import {inject, observer} from 'mobx-react'

import {CustomInput} from './stylesInputs'

const Cabecalho = inject('ImprimirPropostaStore')(
  observer(({ImprimirPropostaStore}) => {
    const {
      onChange,
      razaoSocial,
      cnpj,
      inscEstadual,
      inscMunicipal,
      telefone,
      celular,
      rua,
      numero,
      bairro,
      cabecalhoDesabilitado,
      changeCabecalhoDesabilitado,
      cidade,
      uf,
      complemento,
      cep,
      email,
      site
    } = ImprimirPropostaStore
    return (
      <CabecalhoComponents
        onChange={onChange}
        ImprimirPropostaStore={ImprimirPropostaStore}
        cidade={cidade}
        uf={uf}
        complemento={complemento}
        cep={cep}
        email={email}
        site={site}
        razaoSocial={razaoSocial}
        cnpj={cnpj}
        inscEstadual={inscEstadual}
        inscMunicipal={inscMunicipal}
        telefone={telefone}
        celular={celular}
        rua={rua}
        numero={numero}
        bairro={bairro}
        cabecalhoDesabilitado={cabecalhoDesabilitado}
        changeCabecalhoDesabilitado={changeCabecalhoDesabilitado}
      />
    )
  })
)

const CabecalhoComponents = ({
   onChange,
   razaoSocial,
   cnpj,
   inscEstadual,
   inscMunicipal,
   telefone,
   celular,
   rua,
   numero,
   bairro,
   cabecalhoDesabilitado,
   changeCabecalhoDesabilitado,
   cidade,
   uf,
   complemento,
   cep,
   email,
   site
 }) => {  
  useEffect(() => {
    cabecalhoDesabilitado = JSON.parse(
      localStorage.getItem('proposta-cabecalho-desabilitado')
    )
  }, [])

  const validationValue = type => {
    return type ? true : false
  }

  return (
    <div className="section-cabecalho">
      <div className="title">
        CABEÇALHO
        <div className="checkbox-desabilitar-cabecalho">
          <input
            type="checkbox"
            onChange={changeCabecalhoDesabilitado}
            checked={cabecalhoDesabilitado}
          />
          <span>Desabilitar cabeçalho</span>
        </div>
      </div>

      {!cabecalhoDesabilitado && (
        <div className="inputs">
          <div className="input-razao-cnpj">
            <CustomInput
              mRight
              width="70%"
              validationValues={validationValue(true)}
            >
              <span>Razão social</span>
              <input
                id="imprimir-razao-social"
                name="razaoSocial"
                type="text"
                placeholder=""
                value={razaoSocial}
                onChange={e => onChange(e)}
                disabled
              />
            </CustomInput>
            <CustomInput width="30%" validationValues={validationValue(true)}>
              <span>CNPJ</span>
              <InputMask
                mask="99.999.999/9999-99"
                id="imprimir-cnpj"
                name="cnpj"
                type="text"
                placeholder=""
                value={cnpj}
                onChange={e => onChange(e)}
                disabled
              />
            </CustomInput>
          </div>
          <div className="input-est-mun-fone-cel">
            <CustomInput
              width="25%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Inscr. Estadual</span>
              <input
                id="inscr-estadual"
                name="inscEstadual"
                type="text"
                placeholder=""
                value={inscEstadual}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput
              width="25%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Inscr. Municipal</span>
              <input
                id="inscr-municipal"
                name="inscMunicipal"
                type="text"
                placeholder=""
                value={inscMunicipal}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput
              width="25%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Telefone</span>
              <InputMask
                maskChar={null}
                mask="+55 (99) *******************"
                id="input-imprimir-tel"
                name="telefone"
                type="text"
                placeholder=""
                value={telefone}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput width="25%" validationValues={validationValue(true)}>
              <span>Celular</span>
              <InputMask
                maskChar={null}
                mask="+55 (99) 99999-99999999"
                id="input-imprimir-cel"
                name="celular"
                type="text"
                placeholder=""
                value={celular}
                onChange={e => onChange(e)}
              />
            </CustomInput>
          </div>
          <div className="logradouro">
            <CustomInput
              width="50%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Rua</span>
              <input
                id="input-logradouro-rua"
                name="rua"
                type="text"
                placeholder=""
                value={rua}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput
              width="15%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Numero</span>
              <input
                id="input-logradouro-numero"
                name="numero"
                type="text"
                placeholder=""
                value={numero}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput width="35%" validationValues={validationValue(true)}>
              <span>Bairro</span>
              <input
                id="input-logradouro-bairro"
                name="bairro"
                type="text"
                placeholder=""
                value={bairro}
                onChange={e => onChange(e)}
              />
            </CustomInput>
          </div>
          <div className="logradouro2">
            <CustomInput
              width="35%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Cidade</span>
              <input
                id="input-logradouro-cidade"
                name="cidade"
                type="text"
                placeholder=""
                value={cidade}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput
              width="15%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>UF</span>
              <input
                id="input-logradouro-uf"
                name="uf"
                type="text"
                placeholder=""
                disabled
                value={uf}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput width="50%" validationValues={validationValue(true)}>
              <span>Complemento</span>
              <input
                id="input-logradouro-complemento"
                name="complemento"
                type="text"
                placeholder=""
                value={complemento}
                onChange={e => onChange(e)}
              />
            </CustomInput>
          </div>
          <div className="logradouro3">
            <CustomInput
              width="28%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>CEP</span>
              <InputMask
                mask="99999-999"
                id="input-logradouro-cep"
                name="cep"
                type="text"
                placeholder=""
                value={cep}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput
              width="36%"
              mRight
              validationValues={validationValue(true)}
            >
              <span>Email</span>
              <input
                id="input-logradouro-email"
                name="email"
                type="text"
                placeholder=""
                value={email}
                onChange={e => onChange(e)}
              />
            </CustomInput>
            <CustomInput width="36%" validationValues={validationValue(true)}>
              <span>Site</span>
              <input
                id="input-logradouro-site"
                name="site"
                type="text"
                placeholder=""
                value={site}
                onChange={e => onChange(e)}
              />
            </CustomInput>
          </div>
        </div>
      )}
    </div>
  )
}
export default Cabecalho
