import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { CardMsg } from "../../../globalStyles";
import { Layers } from "react-feather";
import ModalSelecaoPortais from "../modalLateral/ModalSelecaoPortais";
import { ConfiguracaoEditaisContext } from "../stores/store";

const SelecaoDePortal = observer(() => {
  return (
    <CardMsg
      height="auto"
      flexDirection="row"
      margin="0px 0px 10px 0px"
      justifyContent="space-between"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Layers />
        &nbsp;&nbsp;
        <div className="d-flex flex-column">
          <div style={{ padding: "0px 10px 0px 10px" }}>PORTAIS</div>
          <small style={{ fontSize: "10px", marginLeft: "10px" }}>
            Definir os portais que deseja automatizar a busca de editais.
          </small>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <ModalSelecaoPortais />
      </div>
    </CardMsg>
  );
});

export default SelecaoDePortal;
