import React from "react";
import { Button } from '@material-ui/core';

const Buttoon = () => {
    return (
        <Button
            variant="contained"
            type="submit"
            style={{
                margin: "5px 30%",
                width: "40%",
                backgroundColor: "#0290C5",
                color: "#FFF"
            }}
        >
            CADASTRAR
        </Button>
    )
}

export default Buttoon;