export const tratarDadosEditais = data => {
  return data.map(edital => {
    const {
      descricao,
      data_disputa,
      data_abertura,
      created_at,
      comprador,
      portal,
      uf,
      id,
      numero,
      edital_text,
      url_download,
      edital_selecionado,
      modalidade,
      tipo_item
    } = edital
    return {
      objeto: descricao,
      disputa: data_disputa,
      orgao: comprador.nome,
      portal: portal.nome,
      uasg: comprador.codigo,
      uf,
      licitacao: numero,
      id: id,
      link: edital_text,
      download: url_download,
      quebraCaptcha: portal.edital_download,
      abertura: data_abertura,
      createdAt: created_at,
      selecionado: edital_selecionado,
      status: edital_selecionado.status,
      prioridade: edital_selecionado.prioridade,
      modalidade: modalidade.descricao,
      usuarios: edital_selecionado.usuarios || [],
      tipo_item
    }
  })
}
