import React, { useContext, useEffect } from 'react';
import InputMask from "react-input-mask";
import { DadosPdfContext } from './context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trash2 } from 'react-feather';
import { InputCustomizado, FileInput, InserLogoEmpresa, AssinaturaImg } from './styles';
import { API } from '../../../ListaDeLicitacoes/api';
import maquinadeestadosstore from '../../../../../../../Stores/MaquinaDeEstadosStore';
import useAxios from 'axios-hooks';
import { ToastDark, ToastError } from '../../../../../../../Stores/Funcoes/FuncoesGerais';


const Rodape = () => {
  const { nome, rg, cpf, dadosBancarios, observacoes, validade, onChange, assinatura, setAssinatura } = useContext(DadosPdfContext);

  const [{ data, loading, error }, postSignature] = useAxios(
    {
      method: 'POST',
      url: API.enviarAssinatura(),
    },
    {
      manual: true,
      useCache: false
    }
  );

  const [{ data: excluirData, loading: excluirLoading, error: excluirError, response }, excluirAssinatura] = useAxios(
    {
      method: 'DELETE',
      url: API.enviarAssinatura(),
      data: { assinatura }
    },
    {
      manual: true,
      useCache: false
    }
  );

  useEffect(() => {
    if (data) setAssinatura(data.assinatura);
    if (error) ToastError('Erro ao enviar imagem');
  }, [data, error])

  useEffect(() => {
    if (response && response.status === 204) setAssinatura('');
    else if (response || excluirError) ToastError('Erro ao excluir imagem');
  }, [response, excluirError])


  const handleAssinatura = ({ target }) => {
    const img = target.files[0];
    if (!img) return;
    if (img && img.size > 5000000) {
      ToastDark('Tamanho máximo permitido de 5mb.');
      return target.value = null;
    }
    document.getElementById('company-logo').style.width = '50px';
    document.getElementById('company-logo').style.height = '50px';
    // CALL TO API
    const formData = new FormData;
    formData.append('assinatura', img);
    formData.append('fornecedor', maquinadeestadosstore.getIdEmpresa());
    postSignature({ data: formData });
    target.value = null;
  }


  const validationValue = (type) => {
    return type ? true : false
  }

  return (
    <div className="section-rodape">
      <div className="title">
        RODAPÉ
      </div>
      <div className="input-nome-rep-legal">
        <InputCustomizado mRight width="40%" validationValues={validationValue(true)}>
          <span>Nome do representante legal</span>
          <input
            id="input-nome-rep-legal"
            name="nome"
            type="text"
            placeholder=""
            value={nome}
            onChange={onChange}
          />
        </InputCustomizado>
        <InputCustomizado width="30%" mRight validationValues={validationValue(true)}>
          <span>RG</span>
          <input
            id="input-imprimir-rg"
            name="rg"
            type="text"
            placeholder=""
            value={rg}
            onChange={onChange}
          />
        </InputCustomizado>
        <InputCustomizado width="30%" validationValues={validationValue(true)}>
          <span>CPF</span>
          <InputMask
            mask="999.999.999-99"
            id="input-imprimir-cpf"
            name="cpf"
            type="text"
            placeholder=""
            value={cpf}
            onChange={onChange}
          />
        </InputCustomizado>
      </div>
      <div className="imprimir-dados-textarea" >
        <InputCustomizado width="100%" height={'80px'} validationValues={validationValue(true)}>
          <span>Dados bancários</span>
          <textarea
            rows="4"
            id="input-dados-bancarios"
            name="dadosBancarios"
            type="text"
            placeholder=""
            value={dadosBancarios}
            onChange={onChange}
          />
        </InputCustomizado>
        <InputCustomizado width="100%" height={'80px'} validationValues={validationValue(true)}>
          <span>Observações gerais</span>
          <textarea
            rows="4"
            id="input-obs-gerais"
            name="observacoes"
            type="text"
            placeholder=""
            value={observacoes}
            onChange={onChange}
          />
        </InputCustomizado>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InputCustomizado width="30%" validationValues={validationValue(true)}>
            <span>Data de validade:</span>
            <input
              id="input-imprimir-validade"
              name="validade"
              type="text"
              placeholder="Data de validade conforme o edital"
              value={validade}
              onChange={onChange}
            />
          </InputCustomizado>
          {(loading || excluirLoading) && <FontAwesomeIcon style={{ marginLeft: 'auto', marginRight: '20px', fontSize: '30px' }} icon="spinner" spin />}
          {assinatura &&
            <AssinaturaImg>
              <Trash2
                id="remover-assinatura"
                className="icon-scale-xs"
                data-tip="REMOVER ASSINATURA"
                data-type="error"
                data-place="top"
                data-effect="solid"
                onClick={excluirAssinatura}
              />
              <img src={assinatura} id="assinatura-pdf" alt="assinatura-pdf" />
            </AssinaturaImg>
          }
          <InserLogoEmpresa htmlFor="enviar-assinatura" className="icon-scale-xs">INSERIR ASSINATURA</InserLogoEmpresa>
          <FileInput
            type="file"
            id="enviar-assinatura"
            accept="image/*"
            onChange={handleAssinatura}
          />
        </div>
        <div style={{ marginLeft: 'auto', color: '#8a8888', fontSize: '80%' }}>Insira aqui sua assinatura no formato de imagem png/jpg</div>
      </div>
    </div>
  );
};

export default Rodape

