import React from 'react'
import { FormGroup, InputGroup, InputIcone } from "./styles";


const InputMultiploPortais = ({
  icone,
  data = [],
  onChange,
  value,
  status,
  name,
  onCLick,
  placeholder,
  disabled,
  selected,
  type
}) => {
  return (
    <FormGroup>
      <InputGroup>
        <InputIcone onClick={onCLick}>{icone}</InputIcone>

        <select
          className="form-control"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          multiple
        >
          {data.map(key => {
            if (status) {
              if (key.edital_download) {
                return (
                  <option selected={selected.includes(key.id)} key={key.id} value={key[type]}>
                    {key.nome}
                  </option>
                )
              }
            } else {
              return (
                <option selected={selected.includes(key.id)} key={key.id} value={key[type]}>
                  {key.nome}
                </option>
              )
            }

          })}
        </select>
      </InputGroup>
    </FormGroup>
  )
}

export default InputMultiploPortais
