import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckSquare,
  faPen,
  faTimes,
  faRetweet,
  faBell,
  faConciergeBell,
  faLock,
  faCalendarAlt,
  faGavel,
  faHome,
  faComments,
  faTv,
  faCog,
  faCogs,
  faSearchPlus,
  faPaste,
  faKey,
  faGlobe,
  faBriefcase,
  faMap,
  faEye,
  faDownload,
  faPlus,
  faList,
  faFilter,
  faUsers,
  faArrowsAltH,
  faTrash,
  faEdit,
  faEnvelope,
  faPhone,
  faPlay,
  faCheck,
  faCalendar,
  faSave,
  faSpinner,
  faClock,
  faStar,
  faExclamationCircle,
  faAt,
  faMapMarkerAlt,
  faEraser,
  faTimesCircle,
  faCheckCircle,
  faCode,
  faDoorOpen,
  faSync,
  faAngleUp,
  faAddressCard,
  faEyeSlash,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faQuestionCircle,
  faExclamation,
  faIndustry,
  faUserPlus,
  faUnlink,
  faUserEdit,
  faBan,
  faExternalLinkSquareAlt,
  faHandHoldingUsd,
  faUpload,
  faLayerGroup,
  faCodeBranch,
  faWifi,
  faBellSlash,
  faAngleDown,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faAlignJustify,
  faTrashAlt,
  faExclamationTriangle,
  faPlusCircle,
  faBookmark,
  faSearch,
  faChalkboard,
  faFileUpload,
  faFileAlt,
  faFilePdf,
  faShoppingCart,
  faCircleNotch,
  faHistory,
  faBarcode,
  faBox,
  faDotCircle,
  faCircle,
  faArrowLeft,
  faBars,
  faArrowCircleRight,
  faBroom,
  faExchangeAlt,
  faCalendarCheck,
  faFile,
  faFileWord,
  faSignOutAlt,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faChartLine,
  faFileSignature,
  faEnvelopeOpenText,
  faWindowRestore,
  faUser,
  faUserTie,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBuilding,
  faCommentDots,
  faStickyNote,
  faBullhorn,
  faTachometerAlt,
  faHeadset
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckSquare,
  faPen,
  faTimes,
  faRetweet,
  faBell,
  faConciergeBell,
  faGavel,
  faCalendarAlt,
  faHome,
  faComments,
  faTv,
  faCog,
  faCogs,
  faSearchPlus,
  faPaste,
  faLock,
  faKey,
  faGlobe,
  faBriefcase,
  faMap,
  faEye,
  faDownload,
  faPlus,
  faList,
  faFilter,
  faUsers,
  faArrowsAltH,
  faTrash,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faPhone,
  faPlay,
  faCheck,
  faCalendar,
  faSave,
  faSpinner,
  faClock,
  faStar,
  faExclamationCircle,
  faAt,
  faMapMarkerAlt,
  faEraser,
  faTimesCircle,
  faCheckCircle,
  faCode,
  faDoorOpen,
  faSync,
  faAngleUp,
  faAddressCard,
  faEyeSlash,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faQuestionCircle,
  faExclamation,
  faIndustry,
  faUserPlus,
  faUnlink,
  faUserEdit,
  faBan,
  faExternalLinkSquareAlt,
  faHandHoldingUsd,
  faUpload,
  faLayerGroup,
  faCodeBranch,
  faWifi,
  faBellSlash,
  faAngleDown,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faAlignJustify,
  faTrashAlt,
  faExclamationTriangle,
  faPlusCircle,
  faBookmark,
  faSearch,
  faChalkboard,
  faFileUpload,
  faFileAlt,
  faShoppingCart,
  faCircleNotch,
  faHistory,
  faBarcode,
  faBox,
  faDotCircle,
  faCircle,
  faArrowLeft,
  faBars,
  faArrowCircleRight,
  faBroom,
  faExchangeAlt,
  faCalendarCheck,
  faFile,
  faFilePdf,
  faFileWord,
  faSignOutAlt,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faChartLine,
  faFileSignature,
  faWindowRestore,
  faUser,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBuilding,
  faCommentDots,
  faStickyNote,
  faBullhorn,
  faTachometerAlt,
  faUserTie,
  faHeadset
);
