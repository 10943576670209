import React from 'react'
import { FormGroup, InputGroup, InputIcone } from './styles'

const InputSelect = ({
  icone,
  data = [],
  onChange,
  value,
  name,
  onCLick,
  placeholder,
  disabled,
  noHeight
}) => {
  return (
    <FormGroup>
      <InputGroup noHeight={noHeight}>
        <InputIcone>{icone}</InputIcone>

        <select
          className="form-control"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          style={{ marginLeft: '25px' }}
        >
          {disabled && <option>ComprasNet</option>}
          {/* <option value={'todas'}>todas</option> */}
          {data.map(key => (
            <option key={key} value={key}>
              {' '}
              {key}
            </option>
          ))}
        </select>
      </InputGroup>
    </FormGroup>
  )
}

export default InputSelect
