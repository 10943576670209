import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import "../../../Debug";
import LicitacaoStores from "../../../Stores/LicitacaoStores";
import MaquinaDeEstadosStore from "../../../Stores/MaquinaDeEstadosStore";
import Api from "../../../Stores/Conexao/conexao";
import { getLicitacaoId } from "../../../Services/licitacao";
import {
  ComponentHeader,
  ComponentHeaderOverflow,
  TabButton,
  ContentBody,
  VerticalLine,
  ReactTooltip
} from "../../../globalStyles";
import { TabContainer, DateContainer, LicitacaoInfo } from "./StyleLicitacao";
import { Calendar, Clock, List } from "react-feather";
import TabelaMsg from "./tabelaMensagens/TabelaMsg";
import TabelaItemLicitacao from "./tabelaItens/TabelaItem";
import TabelaLinhaTempo from "./tabelaLinhaTempo/TabelaLinhaTempo";
import EditaisStores from "../../../Stores/EditaisStores";
import { Link } from "react-router-dom";
import { EditaisStoresContext } from "../../../Stores/EditaisStores";
import {
  FormatDateData,
  FormatDateHora
} from "../../../Stores/Funcoes/FuncoesGerais";

@inject("LicitacaoStores", "EditaisStores")
@observer
class Licitacao extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: 0,
      ipg: [],
      width: 0,
      height: 0
    };
  }

  componentDidMount() {
    this.props.LicitacaoStores.consulta_api(getLicitacaoId());
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight });

  LoadIpg() {
    const data = new FormData();
    data.set(
      "id_edital_selecionado",
      this.props.edital.edital_selecionado[0].id
    );
    data.set("id_fornecedor", MaquinaDeEstadosStore.getIdEmpresa());
    Api.post("portal/itens_cadastrados/", data, {})
      .then(response => {
        this.setState({ itens_cadastrados: response.data.itens_cadastrados });
        let ipgCod = response.data.itens_cadastrados;
        let ipg = [];
        ipgCod.map(item => {
          return ipg.push(item.ipg_cod);
        });
        this.setState({ ipg: ipg });
      })
      .catch(error => { });
  }

  handleTab = e => {
    if (this.state.key === 2) {
      this.setState({ key: this.state.key - 1 });
      setTimeout(() => {
        this.setState({ key: this.state.key + 1 });
      }, 200);
      LicitacaoStores.onChange(e);
    } else {
      this.setState({ key: this.state.key + 1 });
      setTimeout(() => {
        this.setState({ key: this.state.key - 1 });
      }, 200);
      LicitacaoStores.onChange(e);
    }
  };

  spinner = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div class="lds-ring-white">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );

  render() {
    const {
      licitacao,
      edital,
      portal,
      cor,
      descricao,
      mensagens_atuais,
      itens_atuais,
      tracks
    } = this.props.LicitacaoStores;
    const {
      downloadUnitario,
      downloadUnitarioFetching
    } = this.props.EditaisStores;

    const { edital_text, url_download /* , data_disputa */ } = edital;
    // const data_envio = edital_selecionado[0] ? edital_selecionado[0].data_cadastro_edital ? formatData(edital_selecionado[0].data_cadastro_edital) : null : null;

    const { key, width } = this.state;

    const { data_disputa } = licitacao;

    const {
      nome_comprador,
      data_abertura,
      uasg,
      uf,
      portal_id,
      edital_selecionado
    } = edital;

    return (
      <>
        <ReactTooltip />
        <ComponentHeader
          height="8vh"
          style={{ justifyContent: "space-between" }}
        >
          <TabContainer>
            <TabButton onClick={() => this.setState({ key: 0 })} ul={key === 0}>
              <FontAwesomeIcon icon="list" style={{ marginRight: "7px" }} />
              LISTA DE ITENS
            </TabButton>
            <TabButton onClick={() => this.setState({ key: 1 })} ul={key === 1}>
              <FontAwesomeIcon icon="comments" style={{ marginRight: "7px" }} />
              MENSAGENS
            </TabButton>
            <TabButton onClick={() => this.setState({ key: 2 })} ul={key === 2}>
              <FontAwesomeIcon
                icon="chart-line"
                style={{ marginRight: "7px" }}
              />
              LINHA DO TEMPO
            </TabButton>
          </TabContainer>
          <Link
            to="/licitacoes"
            style={{
              marginRight: "auto",
              marginLeft: "2%",
              alignSelf: "center"
            }}
          >
            <List
              color="#fff"
              data-tip="RETORNAR À LISTA"
              data-place="right"
              data-effect="solid"
              data-type="warning"
              className="icon-scale"
            />
          </Link>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#fff",
              fontSize: width < 1200 ? "1.35rem" : "1.7rem",
              width: width < 1200 ? "150px" : "180px",
              marginRight: "10%",
              alignSelf: "center"
            }}
          >
            {edital_selecionado && edital_selecionado.length && portal_id === 5 ? <FontAwesomeIcon
              icon="file-signature"
              className="icon-scale"
              onClick={() => EditaisStores.mostrarProposta(edital)}
              data-tip="EDITAR PROPOSTA"
              data-effect="solid"
              data-place="bottom"
              data-type="info"
            /> : null}

            {downloadUnitarioFetching ? (
              this.spinner()
            ) : (
                <FontAwesomeIcon
                  icon="cloud-download-alt"
                  className="icon-scale"
                  data-tip="DOWNLOAD EDITAL"
                  data-effect="solid"
                  data-place="bottom"
                  data-type="info"
                  onClick={
                    portal_id === 5 || portal_id === 6
                      ? () => downloadUnitario(edital)
                      : () => window.open(url_download, "_blank")
                  }
                />
              )}
            <FontAwesomeIcon
              icon="search-plus"
              className="icon-scale"
              data-tip="VISUALISAR EDITAL"
              data-effect="solid"
              data-place="bottom"
              data-type="info"
              onClick={() => window.open(edital_text, "_blank")}
            />
          </div>
        </ComponentHeader>
        <ComponentHeaderOverflow height="8vh" />
        <ContentBody bRadius style={{ height: "78.5vh", paddingLeft: 0 }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "75%",
                position: "relative",
                height: "76.5vh",
                overflow: "auto"
              }}
            >
              {key === 0 && <TabelaItemLicitacao data={itens_atuais} />}
              {key === 1 && <TabelaMsg mensagens_atuais={mensagens_atuais} />}
              {key === 2 && <TabelaLinhaTempo tracks={tracks} />}
            </div>
            <VerticalLine
              shape="1px solid #3A566E"
              marginTotal="0.2rem"
              style={{ marginBottom: "1rem" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                width: "25%",
                height: "76.5vh",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center"
                }}
              >
                <b
                  style={{
                    color: "#396488",
                    fontSize: width > 1400 ? "1.2rem" : "1rem",
                    whiteSpace: "nowrap",
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <FontAwesomeIcon
                    icon="gavel"
                    style={{ marginRight: "1rem", fontSize: '25px' }}
                  />{" "}
                  {`LICITAÇÃO - ${licitacao.codigo || ""}`}
                </b>
                <span
                  style={{
                    color: "#fff",
                    backgroundColor: cor,
                    textAlign: "center",
                    borderRadius: "20px",
                    padding: "0.2rem 0.4rem",
                    margin: width < 1200 ? "0.5rem" : "1rem",
                    fontSize: width > 1400 ? "1rem" : "0.8rem",
                    fontWeight: 600
                  }}
                >
                  {descricao}
                </span>
              </div>
              <DateContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px"
                  }}
                >
                  <b style={{ fontSize: "0.9em", marginBottom: "6px" }}>
                    ABERTURA
                  </b>
                  <div className="date-box">
                    <Calendar
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px"
                      }}
                    />{" "}
                    {data_abertura && FormatDateData(data_abertura)}
                  </div>
                  <div className="hour-box">
                    <Clock
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px"
                      }}
                    />{" "}
                    {data_abertura && FormatDateHora(data_abertura)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                    marginRight: "10px"
                  }}
                >
                  <b style={{ fontSize: "0.9em", marginBottom: "6px" }}>
                    DISPUTA
                  </b>
                  <div className="date-box">
                    <Calendar
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px"
                      }}
                    />{" "}
                    {data_disputa && FormatDateData(data_disputa)}
                  </div>
                  <div className="hour-box">
                    <Clock
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px"
                      }}
                    />{" "}
                    {data_disputa && FormatDateHora(data_disputa)}
                  </div>
                </div>
              </DateContainer>
              <LicitacaoInfo className="info-licitacao-scroll">
                <span>
                  PORTAL: <b>{portal}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  COMPRADOR: <b>{nome_comprador}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  RESPONSÁVEL: <b>{licitacao.user}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  EQUALIZAÇÃO ICMS:{" "}
                  <b>{licitacao.equalizacao_icms ? "SIM" : "NÃO"}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  UASG: <b>{uasg}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  SRP: <b>{licitacao.srp ? "SIM" : "NÃO"}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  ESTADO: <b>{uf}</b>
                </span>
                <hr style={{ margin: "0.6rem" }} />
                <span>
                  DESCRIÇÃO:{" "}
                  <b>
                    {edital.descricao && edital.descricao /* .substr(16) */}
                  </b>
                </span>
              </LicitacaoInfo>
            </div>
          </div>
        </ContentBody>
      </>
    );
  }
}

export default Licitacao;
