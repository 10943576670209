import React, { createContext, useContext, useEffect, useReducer } from 'react'

import Api from '../../../../Stores/Conexao/conexao'
import MaquinaDeEstadosStore from '../../../../Stores/MaquinaDeEstadosStore'
import QualificadosStore from '../../Qualified/Store'
import AprovadosStore from '../../Approved/Store'
import BoletimStore from '../../DailyBulletin/Store'
import ReprovadosStore from '../../Failed/Store'
import DetalhesEditalStore from '../../../../Components/Modules/ModalDetalhes/store'
import { AlertaError } from "../../../../Stores/Funcoes/FuncoesGerais";
import BuscaManualStore from '../../ManualSearch/Store'
import { ModalContext } from './modal'
import { ConsoleHelper } from '../../../../utils/ConsoleHelper'

const StatusEditalContext = createContext(null)

const initialState = {
  statusEdital: 'neutro',
  edital: {},
  userList: [],
  selected: false
}

export const ActionTypes = {
  GET_EDITAL: 'GET_EDITAL',
  GET_PRIORIDADE: 'GET_PRIORIDADE',
  GET_STATUS: 'GET_STATUS',
  GET_MENSAGEM_REPROVAR: 'GET_MENSAGEM_REPROVAR',
  GET_USER_LIST: 'GET_USER_LIST',
  GET_USER: 'GET_USER',
  GET_SELECTED: 'GET_SELECTED'
}

const Action = {
  edital: {},
  user: [],
  userList: [],
  msg_status: '',
  type: ActionTypes,
  selected: false
}

function reducer(state = initialState, action = Action) {
  switch (action.type) {
    case ActionTypes.GET_EDITAL:
      return { ...state, edital: action }
    case ActionTypes.GET_USER_LIST:
      return { ...state, userList: action.userList }
    case ActionTypes.GET_SELECTED:
      return { ...state, selected: action.selected }

    case ActionTypes.GET_USER:
      return {
        ...state,
        edital: {
          ...state.edital,
          selecionado: {
            ...state.edital.selecionado,
            usuarios: [action.user]
          }
        }
      }

    case ActionTypes.GET_STATUS:
      return {
        ...state,
        edital: {
          ...state.edital,
          status: action.status,
          selecionado: {
            ...state.edital.selecionado,
            status: action.status
          }
        }
      }

    case ActionTypes.GET_PRIORIDADE:
      return {
        ...state,
        edital: {
          ...state.edital,
          prioridade: action.prioridade,
          selecionado: {
            ...state.edital.selecionado,
            prioridade: action.prioridade
          }
        }
      }
    case ActionTypes.GET_MENSAGEM_REPROVAR:
      return {
        ...state,
        edital: {
          ...state.edital,
          prioridade: action.prioridade,
          selecionado: {
            ...state.edital.selecionado,
            msg_status: action.msg_status
          }
        }
      }
    default:
      return state
  }
}

export function useStatusEdital() {
  const { showConfirmacao, closeModal } = useContext(ModalContext)
  const [{ edital, userList, selected }, dispatch] = useContext(
    StatusEditalContext
  )

  useEffect(() => {
    const users = MaquinaDeEstadosStore.usuarios
    if (!userList.length && users.length) {
      dispatch({ type: ActionTypes.GET_USER_LIST, userList: users })
    }
  }, []);

  const onChangeUser = value => {
    dispatch({ type: ActionTypes.GET_USER, user: value })
  }

  const onChengeUser = value => {
    dispatch({ type: ActionTypes.GET_USER, user: value })
  }

  const changePriority = value => {
    dispatch({ type: ActionTypes.GET_PRIORIDADE, prioridade: value })
  }

  const changeDisapprovalMessage = value => {
    dispatch({ type: ActionTypes.GET_MENSAGEM_REPROVAR, msg_status: value })
  }

  const data = (status, idUser) => {
    const {
      id,
      selecionado: { prioridade, msg_status }
    } = edital
    let config = {
      edital: id,
      status,
      msg_status,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      usuarios: [idUser],
      prioridade: prioridade
    }
    return config
  }

  const setEdital = edital => {
    dispatch({ type: ActionTypes.GET_EDITAL, ...edital })
  }

  const setSelected = select => {
    dispatch({ type: ActionTypes.GET_SELECTED, selected: select })
  }

  const statusEditalManual = async (userId) => {
    try {
      await Api.post('/portal/edital_status/', data("qualificado", userId))
      await BuscaManualStore.changeEditais(edital.id, "qualificado")
      closeModal()
      showConfirmacao()
    } catch {
      await Api.post('/portal/boletim_status/', data("qualificado", userId))
      await BuscaManualStore.changeEditais(edital.id, "qualificado")
      closeModal()
      showConfirmacao()
    }
  }

  const statusEdital = async (status, id) => {
    try {
      const boletim = edital.selecionado.id
      await Api.post(
        `/portal/${boletim ? 'boletim' : 'edital'}_status/`,
        data(status, id)
      )
      BoletimStore.changeEditais(edital.id, status)
      QualificadosStore.changeEditais(edital.id, status)
      AprovadosStore.changeEditais(edital.id, status)
      ReprovadosStore.changeEditais(edital.id, status)
      dispatch({ type: ActionTypes.GET_STATUS, status })
      closeModal()
      showConfirmacao()
    } catch { }
  }

  const statusDirectEdict = async (editalValue, status, edital) => {
    const {
      id,
      selecionado: { prioridade, msg_status }
    } = editalValue
    let config = {
      edital: id,
      status,
      msg_status,
      fornecedor: MaquinaDeEstadosStore.getIdEmpresa(),
      usuarios: [MaquinaDeEstadosStore.usuarioLogado.id],
      prioridade: prioridade === null ? 'baixa' : prioridade
    }

    try {
      await Api.post(
        `/portal/${Object.keys(edital.selecionado).length ? 'boletim' : 'edital'}_status/`,
        config
      )
      // await Api.post('/portal/boletim_status/', config)
      BoletimStore.changeEditais(editalValue.id, status)
      QualificadosStore.changeEditais(editalValue.id, status)
      AprovadosStore.changeEditais(editalValue.id, status)
      ReprovadosStore.changeEditais(editalValue.id, status)
      dispatch({ type: ActionTypes.GET_EDITAL, ...editalValue })
      dispatch({ type: ActionTypes.GET_STATUS, status })
      showConfirmacao()
    } catch (err) {

    } finally {
      setSelected(false)
    }
  }

  const qualifiedEdictStatus = async status => {
    try {
      await Api.post('/portal/boletim_status/', data(status))
      BoletimStore.changeEditais(edital.id, status)
      QualificadosStore.changeEditais(edital.id, status)
      AprovadosStore.changeEditais(edital.id, status)
      ReprovadosStore.changeEditais(edital.id, status)
      dispatch({ type: ActionTypes.GET_STATUS, status })
      showConfirmacao()
    } catch (err) {
      ConsoleHelper('err', err)
    }
  }

  return {
    statusEdital,
    setEdital,
    changePriority,
    changeDisapprovalMessage,
    edital,
    userList,
    onChangeUser,
    statusEditalManual,
    setSelected,
    statusDirectEdict,
    selected
  }
}

const StatusEditalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StatusEditalContext.Provider value={[state, dispatch]}>
      {children}
    </StatusEditalContext.Provider>
  )
}

export { StatusEditalContext, StatusEditalProvider }
