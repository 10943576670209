import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/newModal'
import { AddAction } from '../Style'
import ReactDOM from 'react-dom'
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from './styles'
import { FormGroup } from 'react-bootstrap'
import UsuariosInputConstumizado from '../InputsCustomizados/UsuariosInputConstumizado'
import TelefoneInputCustomizado from '../InputsCustomizados/TelefoneInputCustomizado'
import SelectEmpresasCustomizado from '../InputsCustomizados/SelectEmpresasCustomizado'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import { ToastDark } from '../../../Stores/Funcoes/FuncoesGerais'

function ModalAddUser({ empresas, state }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  const div = document.getElementById('container-configuracao-geral')

  return (
    <ModalProvider height="auto">
      {div &&
        ReactDOM.createPortal(
          <AddAction
            data-effect="solid"
            className="icon-scale"
            data-tip="ADICIONAR USUÁRIO"
            data-place="left"
            data-type="success"
            onClick={() => setIsModalOpen(true)}
          >
            +
          </AddAction>,
          div
        )}

      {isModalOpen && (
        <ModalLateral
          icon="users"
          title={'ADICIONAR USUÁRIO'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <UserStore toggleModal={setIsModalOpen} />
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const UserStore = inject('StoreConfiguracoesGerais')(
  observer(({ StoreConfiguracoesGerais, toggleModal }) => {
    const {
      empresas,
      onChange,
      validarAdmin,
      adminValidation,
      verificaAdmin
    } = StoreConfiguracoesGerais
    return (
      <UserInfo
        empresas={empresas}
        toggleModal={toggleModal}
        onChange={onChange}
        validarAdmin={validarAdmin}
        adminValidation={adminValidation}
        verificaAdmin={verificaAdmin}
      />
    )
  })
)

const UserInfo = props => {
  const {
    onChange,
    empresas,
    validarAdmin,
    adminValidation,
    toggleModal,
    verificaAdmin
  } = props

  const handleSubmit = (e, validate) => {
    e.preventDefault()
    if (!validate) return validarAdmin('adicionarUsuario')
    return verificaAdmin('adicionarUsuario', toggleModal)
  }

  if (adminValidation)
    return (
      <UserForm onSubmit={e => handleSubmit(e, true)}>
        <h5 style={{ textAlign: 'center' }}>
          {MaquinaDeEstadosStore.getEmailUser()}
        </h5>
        <UsuariosInputConstumizado
          titulo={'Digite a senha do administrador'}
          id={'inputAdminPw'}
          placeholder={'senha...'}
          type={'password'}
          onChange={onChange}
        />
        <RedefinirSenha onClick={() => validarAdmin('fecharModal')}>
          Voltar
        </RedefinirSenha>
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  return (
    <UserForm onSubmit={handleSubmit}>
      <FormGroup>
        <SelectEmpresasCustomizado
          id="empresas_cliente"
          onChange={onChange}
          empresas={empresas}
          titulo="Selecione as Empresas"
        />
        <UsuariosInputConstumizado
          titulo={'Primeiro Nome'}
          id={'first_name'}
          placeholder={'Insira seu primeiro nome..'}
          type={'text'}
          onChange={onChange}
        />
        <UsuariosInputConstumizado
          titulo={'Último Nome'}
          id={'last_name'}
          placeholder={'Insira seu sobrenome..'}
          type={'text'}
          onChange={onChange}
        />
        <UsuariosInputConstumizado
          titulo={'E-mail'}
          id={'email_cobranca'}
          placeholder={'Insira um email válido..'}
          type={'email'}
          onChange={onChange}
        />
        <TelefoneInputCustomizado
          titulo="Telefone"
          id="phone"
          onChange={onChange}
          placeholder="Insira seu telefone"
        />
        <div className="d-flex justify-content-around">
          <div>
            <input
              type="radio"
              name="permissao"
              value="analista"
              onChange={onChange}
            />
            <small className="ml-2">Analista</small>
          </div>
          <div>
            <input
              type="radio"
              name="permissao"
              value="administrador"
              onChange={onChange}
            />
            <small className="ml-2">Administrador</small>
          </div>
        </div>
      </FormGroup>
      <SaveButton type="submit" className="icon-scale-xs">
        SALVAR
      </SaveButton>
    </UserForm>
  )
}

export default ModalAddUser
