import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 20px 10px 20px;
  height: calc(100vh - 243px);
  opacity: 0;
  animation: 1s fadeIn ease-in forwards;
  background: #fff;
  box-shadow: 1px 1px 3px #0000008c;
  border-radius: 15px;
`;

export const TabContainer = styled.div`
  position: absolute;
  display: flex;
  left: 30px;
  top: -25px;
  width: 200px;
  height: 25px;
`;

export const Tab = styled.div`
  display: flex;
  color: #fff;
  font-size: 11px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #418baf;
  font-weight: 500;
  width: 150px;
  box-shadow: -1px 0px 2px 0px #00000070;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.active ? '1' : '0.6'};
  margin-top: ${props => !props.active && '3px'};
  margin-right: 5px;
  cursor: pointer;
  user-select: none;

  :hover {
    opacity: ${props => props.active ? '1' : '0.8'};
  }
`;

export const Header = styled.div`
  position: absolute;
  left: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: translate(-50%);

  .icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: -35px;
    box-shadow: 1px 1px 4px 1px #00000087;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .title {
    text-align: center;
    margin-top: 35px;
    font-size: 16px;
    margin-bottom: 5px;
    display: flex;
    
     b {
       margin-left: 5px;
     }
  }
`;

export const Divider = styled.div`
  width: 92%;
  height: 1px;
  background: #d6d6d6;
  align-self: center;
  margin-top: 75px;
`;

