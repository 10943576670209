import React from 'react';
import ListaDeItens from '../../../../../../Pages/Proposal/CadastrarProposta/contexto/Itens/ListaDeItens';
import styled from 'styled-components';
import Passo from './Passo';
import { FileText } from 'react-feather';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 20px;
  background: #fff;
  height: 70px;
  color: #1b77a2;
  justify-content: space-between;
  box-shadow: 1px 1px 3px #000000e6;

  @media (max-width: 1370px) {
    padding: 5px;
  }
`;

const FluxoDeEnvio = () => {

  return (
    <Container>
      <Passo
        numero={1}
        title="ITENS PROPOSTA"
        subtitle="EDITAR ITENS DA PROPOSTA"
        icon={<FileText />}
      />
      <Passo
        numero={2}
        title="DADOS PROPOSTA"
        subtitle="EDITAR DADOS PARA GERAR PROPOSTA"
        icon={<FileText />}
      />
      <Passo
        numero={3}
        title="ANEXAR DOCUMENTOS"
        subtitle="DE HABILITAÇÃO/PROPOSTA"
        icon={<FileText />}
      />
      <Passo
        numero={4}
        title="CONFIRMAR"
        subtitle="CONFIRMAR DADOS DA PROPOSTA"
        icon={<FileText />}
      />
      <Passo
        numero={5}
        title="ENVIAR PROPOSTA"
        subtitle="PARA O PORTAL"
        icon={<FileText />}
      />
    </Container>
  )
}

export default FluxoDeEnvio
