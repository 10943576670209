import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ImportarPerfil } from '../Style'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import { Bookmark, Trash2 } from 'react-feather'
import { useEffect } from 'react'
// import Api from '../../../../Stores/Conexao/conexao'
import { useFiltroEdital } from './context/FiltroEditaisContext'
import { useHistory, useParams } from 'react-router-dom'

const ImportarDePerfil = inject('MaquinaDeEstadosStore')(
  observer(({ MaquinaDeEstadosStore }) => (
    <ImportarDePerfilComStore perfil={MaquinaDeEstadosStore.perfil || []} />
  ))
)

const ImportarDePerfilComStore = ({ perfil }) => {
  const { id } = useParams();
  const { push } = useHistory();

  const { setPerfilData, limpar, state: { perfis, perfilId } } = useFiltroEdital();
  const [perfilSelected, setPerfilSelected] = useState(id || 0);
  
  useEffect(() => {
    setPerfilSelected(0)
  }, [])

  useEffect(() => {
    if (Number(perfilSelected) && perfis.length) {
      setPerfilData(perfilSelected);
      setTimeout(() => push(`/prospeccao/pesquisa/${perfilSelected}`), 500);
    }
    if (perfilSelected === 0) push(`/prospeccao/pesquisa/${perfilSelected}`);
  }, [perfilSelected])

  useEffect(() => {
    setPerfilSelected(perfilId)
  }, [perfilId])
  

  return (
    <ImportarPerfil>
      <h5>Importar configurações de perfil</h5>
      <div className="input-wrap">
        <TextField
          label="Perfil"
          select
          variant="outlined"
          size="small"
          required
          margin="normal"
          defaultValue={0}
          value={perfilSelected}
          fullWidth
          onChange={({ target: { value } }) => setPerfilSelected(value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Bookmark />
              </InputAdornment>
            )
          }}
        >
          <MenuItem value={0}>Nenhum</MenuItem>
          {perfis.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.nome_perfil}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </ImportarPerfil>
  )
}

export default ImportarDePerfil
