import styled from 'styled-components';


export const BotoesContainer = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  box-shadow: 1px 1px 4px #000000ba;
  background: ${props => props.loading ? '#e8e8e8' : 'transparent'};
  cursor: ${props => props.loading ? 'not-allowed' : 'pointer'};
  user-select: none;

  button {
    border:none;
    background: none;
    width: 50%;
    padding: 10px;
    font-weight: 500;
  }

  #action-submit {
    background: green;
    color: #fff;
    border-right: 1px solid #828282;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    :disabled {
      opacity: 0.6;
    }
  }

  #action-close {
    background: #c5bfbf;
    color: black;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  font-size: 14px;

  .info {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .info-edital {
      margin: 10px 0;
      b {
        font-weight: 700;
        margin-right: 15px;
      }

      :last-child {
        display: flex;
        flex-direction: column;
        max-height: 25%;
        overflow: auto;
      }
    }
  }
`;