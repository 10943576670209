import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DataTable from 'react-data-table-component'
import SubHeader from '../Contexto/SubMenu/SubMenu'
import SectionTitle from '../Contexto/SectionTitle/SecTionTitle'
import { Pagination } from '@material-ui/lab'
import { Container, ContainerTabela, ContainerPaginatio, Body } from '../styles'
import { FileText } from 'react-feather'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import Spinner from '../../../UsefulComponents/Spinner'
import { columns } from './contexto/EstruturaTabela'

const GerenciarProposta = inject(
  'GerenciarPropostaStore',
  'MaquinaDeEstadosStore'
)(
  observer(({ GerenciarPropostaStore, MaquinaDeEstadosStore }) => {
    const { id_fornecedor } = MaquinaDeEstadosStore
    const {
      fetchPropostas,
      pagination,
      total_paginas,
      total_registros,
      propostas
    } = GerenciarPropostaStore
    const props = {
      fetchPropostas,
      page: pagination.page,
      changePage: pagination.changePage,
      total_paginas,
      total_registros,
      propostas
    }

    return <GerenciarPropostaComStore idEmpresa={id_fornecedor} {...props} />
  })
)

const GerenciarPropostaComStore = props => {
  const {
    idEmpresa,
    fetchPropostas,
    changePage,
    page,
    propostas,
    total_paginas
  } = props

  useEffect(() => {
    if (idEmpresa) fetchPropostas()
  }, [idEmpresa])

  const isFetching = false

  const handlePagina = (event, value) => {
    changePage(value)
    fetchPropostas()
  }

  return (
    <Container>
      <SubHeader overflow>
        <SectionTitle title="GERENCIAR PROPOSTA" icon={<FileText />} />
      </SubHeader>
      <Body height="calc(100vh - 130px)">
        {/* <TestHooks /> */}
        <ContainerTabela>
          {isFetching ? (
            <Spinner />
          ) : (
            <DataTable
              className="tabela-gerenciar-proposta"
              fixedHeader
              allowOverflow={true}
              overflowY={true}
              noHeader
              responsive={true}
              columns={columns}
              // onSort={sortEditais}
              // defaultSortAsc={crescente}
              // sortServer={true}
              data={propostas}
              striped={true}
              highlightOnHover
              noDataComponent={
                <ExclamationWarning
                  title="NENHUMA PROPOSTA ENCONTRADA"
                  height="70vh"
                />
              }
            />
          )}
          <ContainerPaginatio>
            <div>
              <Pagination
                count={total_paginas}
                color="primary"
                shape="rounded"
                page={page}
                onChange={(event, value) => handlePagina(event, value)}
                showFirstButton
                showLastButton
              />
            </div>
          </ContainerPaginatio>
        </ContainerTabela>
      </Body>
      {/*<TabelasProposta/>*/}
    </Container>
  )
}

export default GerenciarProposta
