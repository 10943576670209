import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const WrapperImag = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url('/static/Imagens/martelo2.jpg');
  opacity: 0.4;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //background: #cccccc;
`

export const Box = styled.div`
  height: ${props => (props.height === undefined ? '450px' : props.height)};
  width: ${props => (props.width === undefined ? '450px' : props.width)};
  width: 450px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 10px;
  background: #ffff;
  justify-content: center;
  box-shadow: 1px 1px 15px -6px rgba(0, 0, 0, 0.75);
  animation: ${props =>
    props.animation === true
      ? 'slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both'
      : 'slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both'};
  animation-play-state: ${props => props.animationPlayState};

  @keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1200px);
    }
  }

  @keyframes slide-top {
    0% {
      transform: translateY(100px);
    }
    100% {
      transform: translateY(-50px);
    }
  }

  @media (max-width: 475px) {
    width: 95%;
  }
`

export const Logo = styled.figure`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1%;
  img {
    height: 60px;
    width: 60px;
  }
  h4 {
    margin-top: 3%;
    align-items: center;
    justify-content: center;
  }
`
