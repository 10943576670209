import React from 'react';
import {InputValorUnitario} from "../../styles";
import {Container, InputValorDescricao} from './styles'


class MiniModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    }
  }

  // componentDidMount() {
  //   const a = document.getElementById("input-valor-unitario-tabela-itens-proposta");
  //   a.focus();
  // }
  getValue() {
    return {descricao_completa: this.state.value}
  }

  handleChange = (e) => {
    let {value} = e.target;
    this.setState({value})
  }

  getInputNode() {
    // If applicable, should return back the primary html input node that is used to edit the data
    // Otherwise return null
    // If value is an input node, then this node will be focussed on when the editor opens
  }

  disableContainerStyles() {
    // Optional method
    // If set to true, the EditorContainer will not apply default styling to the editor
  }

  render() {
    return (
      <Container>
        <InputValorDescricao
          id="input-valor-unitario-tabela-itens-proposta"
          type="textarea"
          onChange={this.handleChange}
          value={this.state.value}
        />
      </Container>
    )
  }
}

export default MiniModal;
