import React, { useContext } from 'react';
import { PropostaEspecificaContext } from '../../../PropostaEspecifica/context';
import ModalCarregar from '../modalCarregar/ModalCarregar';
import * as S from './styles';



const Info = () => {
  const { edital, openModal, cleanData } = useContext(PropostaEspecificaContext);
  const { comprador, modalidade, descricao, portal } = edital;


  return (
    <S.Container>
      <div className="info">
        <div className="info-edital"><b>ORGÃO: </b>{comprador.nome}</div>
        <div className="info-edital"><b>PORTAL: </b>{portal.nome}</div>
        <div className="info-edital"><b>UASG: </b>{comprador.codigo}</div>
        <div className="info-edital"><b>UF: </b>{comprador.uf}</div>
        <div className="info-edital"><b>MODALIDADE: </b>{modalidade.descricao}</div>
        <div className="info-edital"><b>OBJETO: </b>{descricao}</div>
      </div>
      <S.BotoesContainer>
        <button id="action-submit" onClick={openModal}>
          PRÓXIMO PASSO
        </button>
        <button id="action-close" onClick={cleanData}>
          VOLTAR
        </button>
      </S.BotoesContainer>
      <ModalCarregar />
    </S.Container>
  )
}

export default Info;
