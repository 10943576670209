import styled from "styled-components";

export const ContaneirPainel = styled.div`
  display: flex;
  background: white;
  border-radius: 7px;
  height: auto;
  margin-bottom: 10px;
  box-shadow: 1px 1px 7px 0px #0000004a;
  font-size: 15px;
`;

export const CardPainel = styled.div`

    text-align: ${props => props.textAlign};
    /* width: ${props => props.width}; */
    width: auto;
    height: auto;
    border-right: 1px solid #D3D3D3;
    padding: 10px;
    font-size: ${props =>
    props.fontSize ? `${17 + props.fontSize}px` : "17px"}; 
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    
    span {
        color: #707070;
        font-size: ${props =>
    props.fontSize ? `${17 + props.fontSize}px` : "17px"}; 
        
        @media (max-wdith: 1200px) {
            font-size: ${props =>
    props.fontSize ? `${13 + props.fontSize}px` : "13px"}; 
        }
    }

    .uasg-licitacao {
      display: flex;
      flex-direction: column;

      span {
        font-size: 70%;
      }
    }


    @media (max-width: 1200px) {
        font-size: ${props =>
    props.fontSize ? `${13 + props.fontSize}px` : "13px"}; 
    }
`;

export const CardPainelTitle = styled.div`
    color: #827b7b;
    margin-bottom: 5px;
    text-shadow: 0px 0px 2px #00000017;
    text-transform: uppercase;
    font-size: 50%;
    font-weight: 600;
`;

export const Tabela = styled.table`
  text-align: center;
  border: 1px solid #9ea0a1;
  width: 100%;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: bold;
`;

export const Badge = styled.span`
  color: ${props => (props.color === undefined ? "white" : props.color)};
  background-color: ${props => props.background};
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.7rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-right: 4px;
  margin-left: 4px;
`;

const agendado = {
  cor: "#4b9488",
  corLight: "rgba(232,255,246,0.96)",
  text: "Agendado - Aberto para Propostas"
};

export const situacao = situacao => {
  switch (situacao) {
    case "#228B22":
      return "#4b9488";
    case "#3c8dbc":
      return "#3c8dbc";
    case "#7CCD7C":
      return "#228B22";
    case "#FFA500":
      return "#FFA500";
    case "#FF4500":
      return "#FF4500";
    case "#800080":
      return "#800080";
    default:
      return;
  }
};

const andamento = {
  cor: "#3c8dbc",
  corLight: "rgba(189,245,255,0.96)",
  text: [
    "Pregão temporariamente suspenso",
    "Em andamento - Aceitação/Habilitação",
    "Em andamento - Análise de Proposta",
    "Em andamento - Lance"
  ]
};

const ata = {
  cor: "#228B22",
  corLight: "rgba(204,255,218,0.96)",
  text: "ATA - Homologado"
};

const anulado = {
  cor: "#a50000",
  corLight: "rgba(255,234,223,0.96)",
  text: "ATA - Homologado"
};

const realizado = {
  cor: "#ffcd0c",
  corLight: "rgba(250,255,225,0.96)",
  text: "Realizado - Aguardando Homologação"
};

export const Td = styled.td`
  width: ${props => props.width};
  background: ${props =>
    props.status === andamento.text[0]
      ? andamento.cor
      : props.status === andamento.text[1]
        ? andamento.cor
        : props.status === andamento.text[2]
          ? andamento.cor
          : props.status === andamento.text[3]
            ? andamento.cor
            : props.status === ata.text
              ? ata.cor
              : props.status === agendado.text
                ? agendado.cor
                : props.status === anulado.text
                  ? anulado.cor
                  : props.status === realizado.text
                    ? realizado.cor
                    : null};

  border: 1px solid #9ea0a1;
  color: ${props =>
    props.color === andamento.text[0]
      ? andamento.cor
      : props.color === andamento.text[1]
        ? andamento.cor
        : props.color === andamento.text[2]
          ? andamento.cor
          : props.color === andamento.text[3]
            ? andamento.cor
            : props.color === ata.text
              ? ata.cor
              : props.color === agendado.text
                ? agendado.cor
                : props.color === anulado.text
                  ? anulado.cor
                  : props.color === realizado.text
                    ? realizado.cor
                    : null};
`;

export const Tr = styled.tr`
  font-size: ${props => (props.size ? props.size : "13px")};
  text-align: center;
  border: 1px solid #9ea0a1;
  tab-size: 50px;

  background: ${props =>
    props.status === andamento.text[0]
      ? andamento.corLight
      : props.status === andamento.text[1]
        ? andamento.corLight
        : props.status === andamento.text[2]
          ? andamento.corLight
          : props.status === andamento.text[3]
            ? andamento.corLight
            : props.status === ata.text
              ? ata.corLight
              : props.status === agendado.text
                ? agendado.corLight
                : props.color === anulado.text
                  ? anulado.corLight
                  : props.color === realizado.text
                    ? realizado.corLight
                    : realizado.corLight};

  :hover {
    background-color: rgba(44, 83, 87, 0.76);
    color: white;
    cursor: pointer;
  }
`;

export const Btnzoom = styled.span`
  color: #fff;
  font-size: 17px;
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
  background: ${props => props.background};
  text-align: center;
  cursor: pointer;

  :hover {
    font-size: 17px;
    height: 26px;
    width: 26px;
    background-color: #ff3b00;
    color: white;
    cursor: pointer;
  }
`;

export const Ckeck = styled.span`
  color: #fff;
  font-size: 12px;
  border-radius: 20%;
  display: inline-block;
  height: 22px;
  width: 22px;
  background: ${props => props.background};
  text-align: center;
  cursor: pointer;

  :hover {
    font-size: 17px;
    height: 26px;
    width: 26px;
    background-color: #ff3b00;
    color: white;
    cursor: pointer;
  }
`;

export const Zom = styled.div`
  width: 300px;
  margin-left: 1%;
  margin-right: 1%;
  background: rgb(201, 201, 199);
  background: linear-gradient(
    0deg,
    rgba(201, 201, 199, 1) 40%,
    rgb(249, 249, 249) 80%
  );
  height: 100px;
  float: right;
  border: 1.3px solid rgb(159, 159, 157);
  font-size: 12px;
  padding: 15px;
  font-weight: bold;
  border-radius: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

export const Title = styled.div`
  width: 70%;
  background: ${props => props.background};
  height: 100px;
  //float: right;
  text-align: center;

  h1 {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2.1em;
    text-align: center;
    color: black;
  }
  div {
    text-align: center;
    margin-left: -28px;
  }
`;

export const WrapPainel = styled.div`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "space-between"};
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  font-size: 13px;
  flex-wrap: wrap;
`;

export const Nav = styled.div`
  width: 100%;
  //top: 0;
  margin-left: -0.5%;
  height: 100px;
  border-radius: 20px;
  background: rgb(201, 201, 199);
  background: linear-gradient(
    0deg,
    rgba(201, 201, 199, 1) 19%,
    rgb(245, 245, 245) 79%
  );
  float: left;
  //position: fixed;
  padding: 0%;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
`;
