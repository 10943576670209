import React from "react";
import { CardBarra, CardIcone, Icone, Item, Number } from "./styleEditais";
import { Titulo } from "../styleDashboard";

export const Editais = ({color, title, subTitle, icon, number, size}) => {
  return (
    <Item color={color}>
      <CardIcone>
        <Icone background={color}>{icon}</Icone>
      </CardIcone>
      <Titulo>
        <h5>{title}</h5>
        <span>{subTitle}</span>
        <p>últimos 7 dias</p>
      </Titulo>

      <CardBarra background={color}/>
      <Number fontSize={size}><h2><b>{number}</b></h2></Number>
    </Item>
  );
};
