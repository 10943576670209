import React from "react";
import { InputPerfilName } from "./styles";

const PerfilNameInput = () => {
  return (
    <InputPerfilName
      id="perf-name"
      type="text"
      placeholder="Nome do perfil..."
    />
  );
};

export default PerfilNameInput;
