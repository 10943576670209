import React from 'react'
import { SelectInput } from '../../../UsefulComponents/Inputs'

export function PortaisSelector({ value, onChange }) {
  return (
    <SelectInput
      id="portal-select-type"
      onChange={onChange}
      value={value}
      width="200px"
      icon={
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0615 0H0.938588C0.105855 0 -0.31434 1.01039 0.275699 1.60043L7.49999 8.82582V16.875C7.49999 17.1809 7.64924 17.4676 7.89987 17.643L11.0249 19.8298C11.6414 20.2613 12.5 19.8239 12.5 19.0617V8.82582L19.7245 1.60043C20.3133 1.01156 19.896 0 19.0615 0Z"
            fill="#3A566E"
          />
        </svg>
      }
    >
      <option value="todos">TODOS PORTAIS</option>
      <option value="5">ComprasNet</option>
      <option value="6">Licitações-e</option>
      <option value="9">Compras Públicas</option>
      <option value="877">Portal BLL</option>
      <option value="930">Portal BNC</option>
    </SelectInput>
  )
}
