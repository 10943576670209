import styled from '@react-pdf/styled-components';

export const PagePDF = styled.Page`
  font-family: Roboto;
  padding: 25px;
  background-color: #fff;
`;

export const Propriedade = styled.Text`
  font-family: Open Sans;
  font-weight: 600;
  text-decoration: underline;
  /* color: blue; */
`;

export const ItensPregao = styled.View`
  flex-direction: column;
  min-height: 30px;
  border: 1px solid grey;
  margin: 5px 0px;
  padding: 5px 8px;
  font-size: 9px;
  background: red;

  :nth-child(odd) {
    background: red;
  };
`;
