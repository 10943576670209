import React, { useContext, useEffect } from 'react';
import { Container } from './styles'
import Aviso from './Aviso';
import { PropostaEspecificaContext } from '../../context';
import useAxios from 'axios-hooks';
import { API } from '../../../ListaDeLicitacoes/api';
import { DocumentosContext } from './context';
import DocumentosHabilitacao from './DocumentosHabilitacao';
import DocumentosProposta from './DocumentosProposta';


const EnviarDocumentos = () => {
  // const { setDocsProposta, setDocsHabilitacao } = useContext(DocumentosContext);
  // const { edital: { id } } = useContext(PropostaEspecificaContext);

  // HABILITAR QUANDO FOR LANÇADO O EDITAR PROPOSTA

  // const [{ data, loading, error }, fetchDocuments] = useAxios(
  //   API.buscarDocumentos(id),
  //   { useCache: false }
  // )

  // useEffect(() => {
  //   if (data) {
  //     // Quando habilitar o editar
  //     // setDocsHabilitacao(data.anexo_habilitacao);
  //     // setDocsProposta(data.anexo_proposta);
  //   }
  // }, [data])

  // NÃO DELETAR, HABILITAR QUANDO FOR LANÇADO O EDITAR.

  return (
    <Container>
      <div className="modal-body">
        <div className="body-documentos">
          <DocumentosProposta />
          <DocumentosHabilitacao />
        </div>
        <Aviso title="ATENÇÃO! Não armazenamos os documentos da proposta e habilitação em nosso banco de dados e servidores" />
      </div>
    </Container>
  );
}

export default EnviarDocumentos;

