import React, { useReducer } from 'react';


const initialState = {
  especifica: false
};

const actions = {
  MOSTRAR_LICITACOES: 'MOSTRAR_LICITACOES',
  MOSTRAR_LISTA_ITENS: 'MOSTRAR_LISTA_ITENS',
  CLEAN_DATA: 'CLEAN_DATA'
}

function reducer(state, action) {
  switch (action.type) {
    case actions.MOSTRAR_LICITACOES:
      return { ...state, especifica: false };
    case actions.MOSTRAR_LISTA_ITENS:
      return { ...state, especifica: true };
    case actions.CLEAN_DATA:
      return initialState;
    default:
      return state;
  }
}

const CadastrarPropostaContext = React.createContext(null);

function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    cleanData: () => dispatch({ type: actions.CLEAN_DATA }),
    mostrarLicitacoes: () => dispatch({ type: actions.MOSTRAR_LICITACOES }),
    mostrarListaItens: () => dispatch({ type: actions.MOSTRAR_LISTA_ITENS })
  }

  return (
    <CadastrarPropostaContext.Provider value={value}>
      {children}
    </CadastrarPropostaContext.Provider>
  )
};

const CadastrarPropostaProvider = Provider;

export { CadastrarPropostaContext }
export default CadastrarPropostaProvider;