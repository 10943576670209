import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
`;

export const ContainerTabela = styled.div`
    display: flex;
    height: 84%;

    .rdt_TableHeadRow {
    box-shadow: 1px 1px 9px #949494;
    border: none;
  }

  .rdt_TableCol_Sortable{
    font-weight: bold;
    color: black;
  }
  .rdt_TableRow{
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .tabela-padrao {
    height: 100%;
    overflow: auto;
    opacity: 0;
    animation: 400ms fadeIn ease-out forwards;
  }
`;


export const WrapperSearch = styled.div`
  margin-top: 15px;

  display: flex;
  width: 33%;
  justify-items: end;
  justify-content: center;
  text-align: end;
  align-items: center;
  div{

  margin-left: -35px;

  color: #cbcbcb;
  }
`;
