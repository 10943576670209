import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { LicitacaoGrafico } from "./Licitacao/LicitacaoGrafico";
import { DashBoardEditais } from "./Editais/CardEditais";
import { EditaisGraficos } from "./Editais/EditaisGraficos";
import { AgendaLicitacoes } from "./Licitacao/Agenda";
import { Sincronizadas } from "./Licitacao/LicitacoesSincronizadas";
import { StatusMsg } from "./Mensagens/StatusMsg";
import { Mensagens } from "./Mensagens/Mensagens";

import { Collumn, Container } from "./styleDashboard";

@inject("DashboardStores", "ModalLoadingStore")
@observer
class IndexDashboard extends Component {
  componentDidMount() {
    this.props.DashboardStores.consulta_dashboard();
  }

  render() {
    const { status, data } = this.props.DashboardStores;
    const { estado } = this.props.ModalLoadingStore;
    return (
      <>
        {status && !estado && (
          <Container>
            <Collumn>
              <EditaisGraficos data={data} />
              <DashBoardEditais data={data} />
            </Collumn>

            <Collumn>
              <LicitacaoGrafico data={data} />
              <AgendaLicitacoes data={data} />
              <Sincronizadas data={data} />
            </Collumn>

            <Collumn>
              <StatusMsg data={data} />
              <Mensagens data={data} />
            </Collumn>
          </Container>
        )}
      </>
    );
  }
}
export default IndexDashboard;
