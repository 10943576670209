import React, { useState, useEffect } from 'react'
import {
  closeModal,
  closeModalOutSide,
  ModalLateral,
  ModalProvider
} from '../../../Components/newModal'
import InputMask from 'react-input-mask'
import TextField from '@mui/material/TextField'
import { UserForm, SaveButton, ModalContainer, RedefinirSenha } from './styles'
import { FormGroup } from 'react-bootstrap'
import UsuariosInputConstumizado from '../InputsCustomizados/UsuariosInputConstumizado'
import { Edit, Key } from 'react-feather'
import { inject, observer } from 'mobx-react'
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import CredencialInputConstumizado from '../InputsCustomizados/CredencialInputConstumizado'

import { ModalConfirmacao } from '../../../Components/newModal/modalConfirmacao'

function ModalEditCredential({ empresaId, credencial }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  return (
    <ModalProvider>
      <Edit
        width="16px"
        className="icon-scale mr-1"
        color="#3a566e"
        data-tip="EDITAR CREDENCIAL"
        data-place="bottom"
        data-type="info"
        onClick={setIsModalOpen}
      />

      {isModalOpen && (
        <ModalLateral
          doobleIcon={<Key />}
          title={'EDITAR CREDENCIAL'}
          handleModal={e => closeModalOutSide(e, setAnimation, setIsModalOpen)}
          onClose={() => closeModal(setAnimation, setIsModalOpen)}
          animation={animation}
        >
          <ModalContainer>
            <CredentialHook
              empresaId={empresaId}
              toggleModal={setIsModalOpen}
              credencial={credencial}
            />
          </ModalContainer>
        </ModalLateral>
      )}
    </ModalProvider>
  )
}

const CredentialHook = inject(
  'StoreConfiguracaoCredenciais',
  'StoreConfiguracoesGerais'
)(
  observer(props => {
    const { credencial, empresaId, toggleModal } = props
    const {
      onChange,
      login,
      password,
      repeat_password,
      adminValidation,
      verificaAdmin,
      validarAdmin,
      validarExcluir,
      fornecedor,
      portal,
      popularCredencial
    } = props.StoreConfiguracaoCredenciais

    const { empresas, usuarios } = props.StoreConfiguracoesGerais

    return (
      <CredentialInfo
        usuarios={usuarios}
        empresas={empresas}
        empresaId={empresaId}
        credencial={credencial}
        toggleModal={toggleModal}
        onChange={onChange}
        login={login}
        password={password}
        repeat_password={repeat_password}
        adminValidation={adminValidation}
        verificaAdmin={verificaAdmin}
        validarAdmin={validarAdmin}
        validarExcluir={validarExcluir}
        portal={portal}
        fornecedor={fornecedor}
        popularCredencial={popularCredencial}
      />
    )
  })
)

const CredentialInfo = props => {
  let {
    empresas,
    empresaId,
    toggleModal,
    credencial,
    onChange,
    login,
    password,
    repeat_password,
    adminValidation,
    verificaAdmin,
    validarAdmin,
    validarExcluir,
    portal,
    popularCredencial
  } = props

  useEffect(() => {
    const { fornecedor_id, portal_id, login, password } = credencial
    const object = {
      login,
      password,
      empresaId: fornecedor_id,
      portal: portal_id
    }
    popularCredencial(object)
    return () => {
      popularCredencial('closeModal')
    }
  }, [])

  if (!login.startsWith('J'))
    login = credencial.portal_id === 6 ? `J${login}` : login
  const { nomePortal } = credencial

  const handleSubmit = (e, validate) => {
    e.preventDefault()
    if (!validate) return validarAdmin('editarCredencial')
    if (validarExcluir)
      return verificaAdmin('excluirCredencial', toggleModal, credencial.id)
    return verificaAdmin('editarCredencial', toggleModal, empresaId)
    // return editar_credencial_validacao_admin(portal_id, toggleModal);
  }

  if (adminValidation)
    return (
      <UserForm onSubmit={e => handleSubmit(e, true)}>
        <h5 style={{ textAlign: 'center' }}>
          {MaquinaDeEstadosStore.getEmailUser()}
        </h5>
        <UsuariosInputConstumizado
          titulo={'Digite a senha do administrador'}
          name="inputAdminPw"
          id={'senha'}
          placeholder={'senha...'}
          type={'password'}
          onChange={onChange}
        />
        <RedefinirSenha onClick={() => validarAdmin('fecharModal')}>
          Voltar
        </RedefinirSenha>
        {/* Voltar para a tela anterior */}
        <SaveButton type="submit" className="icon-scale-xs">
          SALVAR
        </SaveButton>
      </UserForm>
    )

  return (
    <UserForm onSubmit={handleSubmit}>
      <FormGroup>
        <h3 style={{ marginBottom: '0.4rem', textAlign: 'center' }}>
          {empresas.find(empresa => empresa.id === empresaId).nome_fantasia}
        </h3>
        <h6 style={{ marginBottom: '3rem', textAlign: 'center' }}>
          {nomePortal}
        </h6>

        {Number(portal) === 5 ? (
          <InputMask mask="999.999.999-99" value={login} onChange={onChange}>
            {() => (
              <TextField
                variant="outlined"
                fullWidth
                name="login"
                id={'add-credencial-login'}
                label={'Informe o CPF de acesso ao gov.br'}
              />
            )}
          </InputMask>
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            label={'Insira seu login de acesso'}
            value={login}
            name="login"
            onChange={onChange}
          />
        )}

        {Number(portal) === 5 ? (
          <CredencialInputConstumizado
            titulo={'Senha'}
            name="password"
            id={'add-credencial-password'}
            placeholder="Informe a senha de acesso ao gov.br"
            type={'password'}
            value={password}
            onChange={onChange}
          />
        ) : (
          <CredencialInputConstumizado
            titulo={'Senha'}
            name="password"
            id={'add-credencial-password'}
            placeholder="Insira sua senha de acesso"
            type={'password'}
            value={password}
            onChange={onChange}
          />
        )}

        <CredencialInputConstumizado
          titulo={'Repetir senha'}
          name="repeat_password"
          id={'credencial-repeat-password'}
          placeholder={'Digite sua senha novamente'}
          value={repeat_password}
          type={'password'}
          onChange={onChange}
        />
      </FormGroup>
      <ModalConfirmacao
        button={
          <RedefinirSenha className="icon-scale-xs">
            Excluir credencial
          </RedefinirSenha>
        }
        title="EXCLUIR CREDENCIAL"
        message="Deseja realmente excluir essa credencial?"
        confirmAction={() => validarAdmin('excluirCredencial')}
      />
      <SaveButton type="submit" className="icon-scale-xs">
        SALVAR
      </SaveButton>
    </UserForm>
  )
}

export default ModalEditCredential
