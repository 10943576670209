import styled from 'styled-components'

export const Sidebar = styled.nav`
  min-width: 65px;
  max-width: 65px;
  background-color: #3a566e;
  color: #ffffff;
  transition: all 0.3s;
  position: fixed;
  height: 100%;
  box-shadow: 0px 1px 8px 1px #00000091;
  z-index: 100;
`

export const SidebarHeader = styled.div`
  height: 87px;
  text-align: center;
  background: #3a566e;
  margin-bottom: 20px;

  .background-logo {
    /* background: white; */
    top: 11px;
    right: 11px;
    position: absolute;
    border-radius: 5px;
  }

  img {
    width: 42px;
    filter: ${props => (props.isPushWorking ? 'none' : 'grayscale(1)')};
  }

  @media (max-height: 650px) {
    height: 50px;
  }
`

export const TagLink = styled.a`
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
`

export const SideBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 53px;
  padding: 10px;
  text-align: center;
  margin: 0.25rem 0;
  font-size: 30px;
  color: ${props => (props.active ? '#396488' : '#fff')};
  background-color: ${props => (props.active ? '#EEF0F3' : 'transparent')};
  box-shadow: ${props =>
    props.active ? '3px 4px 5px rgba(0, 0, 0, 0.2)' : 'none'};
  transition: 0.2s;

  :hover {
    background-color: ${props => (!props.background ? '#eef0f3c9' : '#EEF0F3')};
    color: #396488;
    -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
  }

  @media (max-height: 745px) {
    height: 48px;
  }
`

export const ToolTipStyle = styled.span`
  height: 18px;
  width: 18px;
  color: white;
  background-color: ${props =>
    props.background === undefined
      ? 'rgba(255,169,27,0.95)'
      : props.background};
  font-size: 12px;
  z-index: 99999;
  padding-right: 0.15em;
  padding-left: 0.15em;
  border-radius: 8rem;
  font-weight: normal;
  //display: inline-block;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  margin-left: 8px;
  margin-top: -20px;
`

export const SideBoxFalse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px;
  text-align: center;
  z-index: 99999;
  font-size: 30px;
  color: #ebebeb7a;

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.3s;
  }
`

export const Barra = styled.div`
  width: 100%;
  padding: 5px;
  color: #0b476a;
  border-bottom: 1px solid #47748b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(#47748b);
`

export const BadgeDanger = styled.span`
  color: white;
  min-width: 20px;
  background-color: rgba(200, 3, 3, 0.95);
  font-size: 12px;
  border-radius: 8rem;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  right: -10px;
  bottom: 5px;
`

export const BadgeSuccess = styled.span`
  color: white;
  min-width: 20px;
  background-color: rgba(10, 174, 24, 0.95);
  font-size: 12px;
  border-radius: 8rem;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  right: -10px;
  top: 5px;
`
