import styled from "styled-components";

export const CompradoresContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border: 1.5px solid #c4c4c4;
  border-radius: 8px;
  position: relative;
  padding: 1rem 1.7rem;
  min-height: 140px;

  .title {
    background: #fff;
    position: absolute;
    top: -10px;
    left: 20px;
    width: 90px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    width: 100%;
    height: 130px;
    overflow: auto;
    

    div:nth-child(even) {
      background: #dedede;
    }

    div {
      display: flex;
      justify-content: space-between;
    }

    span {
      color: #5a5a5a;
    }

    .portal-name {
      font-weight: 600;
    }
  }

  @media (max-width: 1500px) {
    padding: 1rem 0.5rem;
    height: 200px;
    overflow: auto;
  }
`;

export const MainContainer = styled.div`
  margin: 1.2rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
`;

export const SelecionarPortal = styled.div`
  display: flex;
  width: 100%;
  margin: ${props => (props.insideModal ? "0" : "10px 0 0 20px")};
  justify-content: ${props => (props.insideModal ? "space-between" : "unset")};
  

  .label {
    font-weight: 500;
    font-size: 14px;
    align-self: center;
    margin-right: 20px;

    @media (max-width: 1500px) {
      font-size: 11px;
    }
  }

  #select-portal-filtro-compradores {
    max-width: ${props => props.insideModal ? "290px" : "unset"};
  }
`;

export const Portal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 5px;
  padding: 0 5px;
  margin-left: ${props => (props.insideModal ? "0" : "20px")};
  color: ${props => (props.selected ? "#fff" : "#3A566E")};
  background-color: ${props => (props.selected ? "#3A566E" : "#fff")};
  border: 1px solid #3a566e;
  text-align: center;
  font-weight: ${props => (props.selected ? "600" : "400")};
  font-size: 13px;

  :hover {
    color: #fff;
    background-color: #3a566e;
  }

  @media (max-width: 1500px) {
    width: 90px;
    margin-left: 10px;
    font-size: 11px;
  }
`;

export const FilterTypeExclusaoInclusao = styled.div`
  width: 100%;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  span {
    font-weight: 500;
  }

  @media (max-width: 1500px) {
    font-size: 11px;
  }
`;

export const SecondaryContainer = styled.div`
  border: 1px solid #d4d2d2;
  border-radius: 3px;
  padding: 3px;
`;

export const ItemContainer = styled.div`
    width: /* ${props => props.width || "100%"} */calc(100% + 10px);
    margin-bottom: ${props => props.marginBottom || "0"};
    margin-right: ${props => props.marginRight || "0"};
    margin: -5px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 1px 1px 6px 0px #4c4a4acc;
    height: 100%;

    .cont-header {
        color: #000;
        font-weight: 500;
        background-color: #fff;
        padding: 0.6rem 1rem; 
        border-radius: 20px 20px 0 0;
        display: flex;  
        align-items: center;
        border-bottom: 1.5px solid #dadada;

        .icon {
            margin-right: 0.7em;
        }
    }

    .cont-body {
        padding: 0.6rem 1.25rem;
        height: 100%

        p {
            font-size: 0.9rem;
        }
        


    }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55vh;

  button {
    margin: 20px !important;
  }

  #exchange-buyers {
    margin: 10px !important;
    cursor: pointer;
    background-color: #358cc5;
    color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 20%;
  }

  #exchange-buyers:disabled {
    background-color: #b3b4b5;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0 0.25rem;
`;

export const ListaCompradores = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${props => (props.filterEd ? "#109A1A" : "#3A566E")};
  border-radius: 10px;
  position: relative;
  height: 100%;
  width: 100%;

  .lista-header {
    background-color: #fff;
    font-size: 0.9rem;
    position: absolute;
    top: -10px;
    left: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .compradores {
    width: 175px;
    color: #3a566e;
  }

  .filtrados {
    width: 85px;
    color: #109a1a;
  }

  #buyers-list {
    margin: 15px 5px;
  }
  #filtered-list {
    margin: 20px 5px;
  }
`;

export const ListaBody = styled.div`
    display: flex;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 300;
    flex-direction: column;
    height: 100%;
    /* max-height: 276px; */
    //max-width: ${props => (props.screenSize === "md" ? "246px" : "390px")};
    overflow: auto;
    overflow-x: hidden;

    select:focus {
      outline: none;
    }
`;

export const DoubleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => (props.noMargin ? "0" : "1rem")};
`;

export const ActionButton = styled.button`
    width: ${props => (props.size ? props.size : "85px")};
    height: ${props => (props.size ? props.size : "85px")};
    background: ${props => props.bgColor || "#fff"};
    text-align: center;
    border-radius: 10px;
    border: 1px solid;
    //border-color: ${props => props.borderColor || "#000"};
    //color: ${props => props.color || "#000"};
    box-shadow: ${props =>
    props.disabled
      ? "inset 1px 3px 6px 0px #00000057, 0px 1px 1px 0px #0000002b, 0px 2px 1px -1px #0000005c"
      : "1px 3px 13px 0px #000000d1, 0px 1px 1px 0px #000000e0, 0px 2px 1px -1px #0000005c"};

    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    margin: 0 1em;
    font-weight: 500;
    transition: 400ms;
    font-size: 0.81rem;

    :focus {
        outline: 0;
    }

`;

export const PortalName = styled.div`
  color: ${props => (props.selected ? "#3a566e" : "#b3b3b3")};
  font-weight: ${props => (props.selected ? 500 : 400)};
`;

export const PortalLogo = styled.img`
  border-radius: 50%;
  box-shadow: 0px 1px 8px 1px #00000094;
  width: 20px;
  height: 20px;
  filter: ${props => (props.greyscale ? "grayscale(100%)" : "none")};
  -webkit-filter: ${props => (props.greyscale ? "grayscale(100%)" : "none")};
`;

export const PortalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 117.5px;
`;

export const MaterialServicosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
`;

export const NomeacaoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e6e4e4;
  border-radius: 8px;
  width: 60vw;
  box-shadow: 1px 1px 10px 3px #c3c3c3;
  margin: 1rem;

  @media (max-width: 1500px) {
    width: 50vw;
  }

  #opcoes-dinamicas-nomeacao {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cacaca;
  }

  #visualizacao-nomeacao {
    display: flex;
    height: 49px;
    align-items: center;
    padding: 0 25px;
  }
`;

export const OpcaoDinamica = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  padding: 15px 35px;
  font-size: 13px;
  color: ${props => (props.selected ? "#3a566e" : "#969696")};
  font-weight: ${props => (props.selected ? "600" : "400")};

  @media (max-width: 1500px) {
    padding: 15px;
  }
`;

export const OpcaoEspecifica = styled.form`
  display: flex;
  flex: 1;
  position: relative;
  margin-right: 30px;
  min-width: 100px;

  input {
    height: 35px;
    border-radius: 7px;
    border: none;
    width: 100%;
    font-size: 11px;
    padding: 0 10px;
    border: 1px solid #c1c1c1;

    :focus {
      outline: 0;
      border: 2px solid #929292;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    background: #0000;
    font-size: 22px;
    color: #327323;
    font-weight: 600;
  }
`;

export const FiltrosExclusivosTexto = styled.h5`
  margin: 0.2rem 0 5px 5px;
  text-shadow: 5px 1px 7px #00000024;
  color: #3a566e;
  font-weight: 500;
  font-size: 15px;

  span {
    font-weight: 900;
  }
`;

export const BlankSpace = styled.div`
  height: 13px;
  width: 13px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  color: #fff;
  width: 240px;
  justify-content: space-between;
  font-size: 0.9rem;
  white-space: nowrap;
  color: #062844;
  margin: auto;
  margin-top: 17px;
  margin-bottom: 7px;
`;

export const CriarPerfilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  white-space: nowrap;
  color: #fff;
  margin-top: 10px;

  .limpar {
    display: flex;
    background-color: white;
    color: red;
  }
`;

export const SaveButton = styled.button`
  display: flex;
  background: #2d6b2dd9;
  color: #fff;
  align-items: center;
  padding: 5px 10px;
  padding-right: 15px;
  border: none;
  border-radius: 7px;
  box-shadow: 1px 1px 4px 1px #8c8c8c;

  svg {
    margin-right: 10px;
  }
`;

export const LimparButton = styled.div`
  display: flex;
  background: #efefef;
  color: #d43f3f;
  align-items: center;
  padding: 5px 10px;
  padding-right: 15px;
  border: none;
  border-radius: 7px;
  box-shadow: 1px 1px 4px 1px #8c8c8c;
  transition: 200ms;

  svg {
    margin-right: 10px;
  }

  :hover {
    background: #f00;
    color: #fff;
  }
`;

export const PerfisContainer = styled.div`
  display: flex;
`;

export const InputPerfilName = styled.input`
  box-shadow: 1px 1px 8px 1px #bdbdbd;
  text-align: center;
  border: none;
  border-radius: 7px;
  font-size: 16px;
  color: #436077;
  padding: 2px 10px;
  width: 180px;
  height: 40px;
  transition: 500ms;

  :focus {
    box-shadow: 1px 1px 8px 2px #717171;
    outline: none;
  }
`;
