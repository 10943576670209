import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { ThumbsDown } from 'react-feather'

import maquinadeestadosstore from '../../../../Stores/MaquinaDeEstadosStore'
import { ModalContext } from '../context/modal'
import { TextAreaInput } from '../../../../UsefulComponents/Inputs'
import { useStatusEdital } from '../context'

import * as S from './style'

const ModalFail = () => {
  const { statusEdital, changeDisapprovalMessage, edital } = useStatusEdital()
  const { closeModal, modalReprovar } = useContext(ModalContext)
  const userId =
    maquinadeestadosstore.usuarios.length &&
    maquinadeestadosstore.usuarios[0].id

  return ReactDOM.createPortal(
    <>
      {modalReprovar && (
        <S.Overlay>
          <S.Container>
            <div className="header">
              <div className="icon background-reprovado">
                <ThumbsDown />
              </div>
              <div className="title color-reprovado">REPROVAR</div>
            </div>
            <div className="body">
              <div className="title-message">MENSAGEM</div>
              <TextAreaInput
                rows={10}
                value={edital.selecionado.msg_status}
                onChange={e => changeDisapprovalMessage(e.target.value)}
              />
            </div>
            <div className="footer">
              <div
                className="confirmar"
                onClick={() => statusEdital('reprovado', userId)}
              >
                CONFIRMAR
              </div>
              <div className="fechar" onClick={closeModal}>
                FECHAR
              </div>
            </div>
          </S.Container>
        </S.Overlay>
      )}
    </>,
    document.querySelector('body')
  )
}

export default ModalFail
