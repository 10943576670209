import React from 'react'
import { FormGroup, InputGroup, InputIcone } from './styles'

const InputMultiploSelect = ({
  icone,
  data = [],
  onChange,
  value,
  name,
  onCLick,
  placeholder,
  disabled,
  selected,
  type
}) => {
  return (
    <FormGroup>
      <InputGroup>
        <InputIcone onClick={onCLick}>{icone}</InputIcone>

        <select
          className="form-control"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          multiple
        >
          {data.map(key => (
            <option selected={selected.includes(key[type])} key={key.id} value={key[type]}>
              {key.nome}
            </option>
          ))}
        </select>
      </InputGroup>
    </FormGroup>
  )
}

export default InputMultiploSelect
