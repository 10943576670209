import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InserLogoEmpresa } from '../ImprimirProposta/styles'
import Cabecalho from '../ImprimirProposta/contexto/Cabecalho'
import Rodape from '../ImprimirProposta/contexto/Rodape'
import { Trash2 } from 'react-feather'
import { ReactTooltip } from '../../../globalStyles'
import ImprimirPropostaStore from '../ImprimirProposta/store'
import { FileInput } from '../../../Components/Modules/Proposta2/CadastrarProposta/PropostaEspecifica/fluxoDeEnvio/dadosPdf/styles'

const DadosEmpresa = ({ props }) => {
  useEffect(() => {
    props.buscarDados()
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <ReactTooltip />
      <div className="modal-body">
        <div>
          <div className="section-header">
            <div className="title">
              <div className="main-title">CONFIGURAÇÕES DA PROPOSTA</div>
              <div className="sub-title">
                Configure a impressão da proposta comercial
              </div>
            </div>
            <div className="upload-company-image">
              <div className="wrapper-image">
                {props.avatarEmpresa && (
                  <Trash2
                    id="remover-avatar-empresa"
                    className="icon-scale-xs logo"
                    data-tip="REMOVER LOGO"
                    data-type="error"
                    data-place="top"
                    data-effect="solid"
                    onClick={ImprimirPropostaStore.excluirAvatar}
                  />
                )}
                {props.uploadingAvatar ? (
                  <FontAwesomeIcon icon="spinner" className="spinner" />
                ) : (
                  <img
                    src={
                      props.avatarEmpresa
                        ? props.avatarEmpresa
                        : '/static/Imagens/logo_wavecode.png'
                    }
                    id="company-logo"
                    alt="company-logo"
                  />
                )}
              </div>
              <div className="limit-size">
                Insira uma imagem menor que 29Kb &#40;492px x 492px&#41;
              </div>
              <InserLogoEmpresa
                htmlFor="enviar-logo-empresa"
                className="icon-scale-xs"
              >
                INSERIR LOGO EMPRESA
              </InserLogoEmpresa>
              <FileInput
                type="file"
                id="enviar-logo-empresa"
                accept="image/*"
                onChange={props.handleSelectedImage}
              />
            </div>
          </div>
          <Cabecalho />
          <Rodape />
        </div>

        <div className="botoes-container">
          <button id="salvar-configuracoes" onClick={props.salvarDados}>
            SALVAR
          </button>
          <button id="limpar-configuracoes" onClick={props.limparVariaveis}>
            LIMPAR
          </button>
        </div>
      </div>
    </>
  )
}

export default DadosEmpresa
