

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { checkNavigatorVersion, checkOS } from './CheckNavigatorVersion';
import * as S from './styles';
import { AlertCircle } from 'react-feather';
import Api from '../../Stores/Conexao/conexao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import maquinadeestadosstore from '../../Stores/MaquinaDeEstadosStore';
import { ToastError } from '../../Stores/Funcoes/FuncoesGerais';

const ModalVerificarNavegador = () => {
  const [navegadorAntigo, setNavegadorAntigo] = useState(checkNavigatorVersion);
  const [ajudaSolicitada, setAjudaSolicitada] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!navegadorAntigo) return null;

  const handleSuporte = async () => {
    setLoading(true);

    const data = {
      fornecedor: maquinadeestadosstore.getIdEmpresa(),
      sist_operacional: checkOS(),
      versao_browser: navigator.sayswho,
    }

    try {
      const res = await Api.post('/browser_desatualizado/', data);
      setAjudaSolicitada(true);
    }
    catch (err) {
      ToastError('Erro')
    }
    finally {
      setLoading(false);
    }
  }

  const closeModal = () => {
    setNavegadorAntigo(false);
  }

  const chrome = { name: 'CHROME', url: 'https://www.google.com/chrome/' };
  const firefox = { name: 'FIREFOX', url: 'https://www.mozilla.org/pt-BR/firefox/new/' };

  const checkNavigator1 = () => {
    if (navigator.sayswho.includes('Chrome')) return chrome;
    if (navigator.sayswho.includes('Firefox')) return firefox;
  }

  const checkNavigator2 = () => {
    if (navigator.sayswho.includes('Chrome')) return firefox;
    if (navigator.sayswho.includes('Firefox')) return chrome;
  }

  return ReactDOM.createPortal(
    <S.Overflow>
      <S.Container>
        <div className="modal-header">
          <div className="title">
            <AlertCircle />
            <span>ATENÇÃO</span>
          </div>
          <button className="close-button icon-scale-xs" onClick={closeModal}>X</button>
        </div>
        <div className="modal-body">
          <div className="section-header">
            <div className="title">
              <div className="main-title">
                SEU NAVEGADOR NÃO ATENDE AOS REQUISITOS MÍNIMOS
              </div>
              <div className="sub-title">
                Alguns módulos <b>NÃO</b> irão funcionar corretamente nesse navegador.
              </div>
            </div>
          </div>
          <div className="section-suggestion">
            <b className="title">SUGESTÕES PARA CORRIGIR ESSE PROBLEMA:</b>
            <span className="suggestion">Atualize para uma versão mais recente <a href={checkNavigator1().url} target="_blank">AQUI</a></span>
            <b style={{ marginBottom: '5px' }}>OU</b>
            <span className="suggestion">
              Utilize outro navegador: <a href={checkNavigator2().url} target="_blank">INSTALAR {checkNavigator2().name}</a></span>
          </div>
          <div className="botoes-container">
            <button
              id="estou-ciente-navegador-antigo"
              className="icon-scale-xs"
              onClick={closeModal}
            >
              ESTOU CIENTE e realizarei o procendimento sugerido
            </button>
            {ajudaSolicitada ? <span className="ajuda-solicitada">Em breve o suporte entrará em contato</span> :
              <button
                id="solicitar-ajuda-suporte"
                className="icon-scale-xs"
                onClick={handleSuporte}
                disabled={loading}
              >
                {loading ? <FontAwesomeIcon icon="spinner" spin /> : 'SOLICITAR AJUDA DO SUPORTE'}
              </button>
            }
          </div>
        </div>
      </S.Container>
    </S.Overflow>
    , document.querySelector('body'))
}

export default ModalVerificarNavegador;
