import { observable, action } from "mobx";

class ExportarPropostaStore {
  @observable propostas = [
    {
      proposta: 1,
      licitacao: 23123,
      orgao: 'Universidade Federal do Maranhão',
      disputa: "2019-10-18T02:00:00",
      status: 'ENVIADA',
      data_envio: "2019-10-18T02:00:00",
      id: 232
    },
    {
      proposta: 1,
      licitacao: 23123,
      orgao: 'Universidade Federal do Maranhão',
      disputa: "2019-10-18T02:00:00",
      status: 'ENVIADA',
      data_envio: "2020-06-26T08:30:00",
      id: 233
    },
    {
      proposta: 1,
      licitacao: 23123,
      orgao: 'Universidade Federal do Maranhão',
      disputa: "2019-10-18T02:00:00",
      status: 'ENVIADA',
      data_envio: "2019-10-18T02:00:00",
      id: 234
    }
  ]



  @action fetchPropostas = async () => {

  }

  @observable pagination = {
    page: 1,
    changePage: (page) => this.pagination.page = page
  }
}

export default new ExportarPropostaStore();
