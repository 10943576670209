import { observable, action } from "mobx";

class PropostaStore {
  @observable modalImprimir = false;
  @action toggleModalImprimir = () => this.modalImprimir = !this.modalImprimir;



}

export default new PropostaStore();
