import { observable, action } from "mobx";

class ModalLoadingStore {
  @observable estado = false;
  @observable status = false;
  @observable requests = 0;

  @action statusOpenModal = () => {
    this.status = true;
  };

  @action statusCloseModal = () => {
    this.status = false;
  };

  @action handleOpenModal = () => {
    if (!this.requests++) this.estado = true;
  };
  @action handleCloseModal = () => {
    if (!--this.requests) this.estado = false;
  };

  @action limparVariaveis = () => {
    this.estado = false;
    this.status = false;
  };
}

const modalLoadingStore = new ModalLoadingStore();
export default modalLoadingStore;
