import React, { useState } from 'react'
import { CardBody, CardFiltroSmall, CardHeard, CardTitulo } from './styles'
import { Bookmark, Layers } from 'react-feather'
import { useFiltroEdital } from "./context/FiltroEditaisContext";
import MaquinaDeEstadosStore from '../../../Stores/MaquinaDeEstadosStore'
import InputMultiploPortais from './inputPortais';

const CardModalidade = () => {
  const { onChangeModalidade, state } = useFiltroEdital()
  const modalidades = MaquinaDeEstadosStore.modalidade.map(m => m.id)
  const [ selectAll, setSelectAll] = useState(true)

  const handleChange = (e) => {
    let select = [...e.target.options].filter(op => op.selected).map(op => Number(op.value))
    onChangeModalidade(select)
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    selectAll ? 
    onChangeModalidade(modalidades) :
    onChangeModalidade([])
  }

  return (
    <CardFiltroSmall>
      <CardHeard>
        <Bookmark />
        <CardTitulo>
          <div>MODALIDADE</div>
          <p>Filtrar licitação por modalidade</p>
        </CardTitulo>
      </CardHeard>
      <CardBody>
        <InputMultiploPortais
          status={false}
          type={'id'}
          selected={state.modalidade}
          onCLick={handleSelectAll}
          onChange={handleChange}
          icone={<Layers />}
          data={MaquinaDeEstadosStore.modalidade.map(m => ({ ...m, nome: m.descricao }))}
        />        
      </CardBody>
    </CardFiltroSmall>
  )
}

export default CardModalidade