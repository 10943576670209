import React, { Component } from 'react'
import InputMask from 'react-input-mask'

import * as S from '../Style'

class TelefoneInputCustomizado extends Component {
  render() {
    const { id, placeholder, defaultValue, onChange, titulo } = this.props
    return (
      <S.CustomInput>
        <span>{titulo}</span>
        <InputMask
          className="phone-input"
          mask="+55 (99) 9 9999-9999"
          maskChar=""
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </S.CustomInput>
    )
  }
}

export default TelefoneInputCustomizado
