import { observable, action } from "mobx";
import { ConsoleHelper } from '../utils/ConsoleHelper'
class StatusConexaoStores {
  @observable isActive = false;

  @action handleOpenModal = () => {
    ConsoleHelper("handleOpenModal")
    this.isActive = true;
  };
  @action handleCloseModal = () => {
    ConsoleHelper("handleCloseModal")
    this.isActive = false;
  };

  @action limparVariaveis = () => {
    ConsoleHelper("limparVariaveis")
    this.isActive = false;
  };
}

const statusConexaoStores = new StatusConexaoStores();
export default statusConexaoStores;
