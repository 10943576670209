import React, { Component } from 'react'
import { CustomInput } from '../Style'
import { FormControl } from 'react-bootstrap'

class SelectPortalInputCustomizado extends Component {

  componentDidMount() {
    if(this.props.portalConfigMonitoramento.length === 1)
      this.props.onChange({target : {name : this.props.portalConfigMonitoramento[0] == 5 ? 'ComprasNet' : 'Licitações-e'}})
  }

  render() {
    const {
      id,
      name,
      onChange,
      titulo,
      portalConfigMonitoramento,
      value
    } = this.props
    
    const rendlePortal = (id) => {
      switch (id) {
        case 5:
          return 'ComprasNet'
        case 6:
          return 'Licitações-e'
        case 877:
          return 'Portal BLL'
        case 930:
          return 'Portal BNC'
        default:
          return 'Licitações-e'
      }
    }

    return (
      <CustomInput>
        <span>{titulo}</span>
        <FormControl
          className="select-portal"
          removeClass="form-control"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          componentClass="select"
          disabled={portalConfigMonitoramento.length === 1}
        >
          {portalConfigMonitoramento.map(id => (
            <option value={id.toString()}>
              {rendlePortal(id)}
            </option>
          ))}
        </FormControl>
      </CustomInput>
    )
  }
}

export default SelectPortalInputCustomizado
