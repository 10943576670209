import styled from 'styled-components'

export const Container = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  /* padding-bottom: 0.8rem; */
  position: absolute;
  top: -73px;
  background-color: #ebebeb;
  width: 560px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  /* height: 50px; */
  padding: 1rem;
  box-shadow: -2px -4px 3px 0.1px #0000004d;

  ::after {
    position: absolute;
    left: -81px;
    content: "";
    height: 100%;
    border-bottom: 51px solid #ebebeb;
    border-left: 56px solid #0000;
    border-top: 24px solid #0000;
    border-right: 25px solid #ebebeb;
  }

  @media (max-width: 1500px) and (min-width: 1200px) {
    width: 539px;
  }

  @media (max-width: 1260px) {
    position: static;
    box-shadow: none;
    border-bottom: 1px solid #c3c3c3;
    width: 100%;

    ::after {
      content: none;
    }
  }

  #icon-action {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: #3A566E;
  justify-content: 'space-between';
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  
`