import React, { useRef, useEffect } from "react";
import Alert from "react-s-alert";
import { toast } from "react-toastify";

const AlertComponent = ({ color, children }) => {
  const compRef = useRef(null);
  useEffect(() => {
    if (compRef) {
      compRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.style.backgroundColor = color;
    }
  }, [compRef]);

  return <div ref={compRef}>{children}</div>;
};

export function CustomAlert(data, color = "#3a566e") {
  return Alert.warning(<AlertComponent color={color}>{data}</AlertComponent>, {
    position: "bottom-right",
    effect: "stackslide",
    timeout: 30000
  });
}
export function CustomAlertNotification(config, msg, color = "#3a566e") {
  return Alert.warning(<AlertComponent color={color}>{msg}</AlertComponent>, {
    customFields: config,
    position: "bottom-right",
    effect: "stackslide",
    timeout: 30000
  });
}

export function AlertaSuccess(dados, timeout) {
  return Alert.success(dados, {
    position: "bottom-left",
    effect: "stackslide",
    timeout: timeout || 3000
  });
}

export function AlertSuccess(message, position) {
  return Alert.success(message, {
    position: position || "bottom-left",
    timeout: 4000
  });
}

export function AlertError(message, position) {
  return Alert.error(message, {
    position: position|| "bottom-left",
    timeout: 3000
  });
}

export function AlertaError(dados, timeout) {
  return Alert.error(dados, {
    position: "bottom-left",
    effect: "stackslide",
    timeout: timeout || 3000
  });
}
export function AlertaTeste(dados) {
  return Alert.info(dados, {
    position: "bottom-left",
    effect: "stackslide",
    timeout: 50000
  });
}

export function AlertaInfo(dados, timeout) {
  return Alert.info(dados, {
    position: "bottom-left",
    effect: "stackslide",
    timeout: timeout || 3000
  });
}

export function AlertaDownload(dados) {
  return Alert.info(dados, {
    position: "bottom",
    effect: "stackslide",
    timeout: 6000
  });
}

export function AlertaMsgVerificada(dados) {
  return Alert.success(dados, {
    position: "bottom",
    effect: "stackslide",
    timeout: 1500
  });
}

export function AlertaDownloadConcluido(dados) {
  return Alert.success(dados, {
    position: "bottom",
    effect: "stackslide",
    // html: true,
    timeout: 60 * 1000
  });
}

export function AlertaDownloadVazio(dados) {
  return Alert.error(dados, {
    position: "bottom",
    effect: "stackslide",
    timeout: 5000
  });
}

export function FormatDate(data) {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  }).format(new Date(data));
}

export function AlertaWarnig(msg, timeout = 10000) {
  return Alert.warning("" + msg, {
    position: "bottom-right",
    effect: "genie",
    timeout: timeout
  });
}



export function AlertaVerde(msg, timeout = 10000) {
  return Alert.success("" + msg, {
    position: "bottom-left",
    effect: "genie",
    timeout: timeout
  });
}

export function AlertaVermelho(msg, timeout = 10000) {
  return Alert.error("" + msg, {
    position: "bottom-left",
    effect: "genie",
    timeout: timeout
  });
}

export function AlertaAzul(msg) {
  return Alert.info("" + msg, {
    position: "bottom-left",
    effect: "genie",
    timeout: 10000,
    html: true
  });
}

export function AlertaVerdeNotificacao(
  msg,
  empresa,
  licitacao_cod,
  id_licitacao,
  id_fornecedor,
  type
) {
  return Alert.success("" + msg, {
    customFields: {
      empresa: empresa,
      licitacao_cod: licitacao_cod,
      id_licitacao: id_licitacao,
      id_fornecedor: id_fornecedor,
      type: type
    },
    width: 500,
    position: "bottom-right",
    effect: "bouncyflip",
    timeout: 30000,
    html: true
  });
}

export function AlertaAzulNotificacao(
  msg,
  empresa,
  licitacao_cod,
  id_licitacao,
  id_fornecedor,
  tipostring,
  portal,
  situacao
) {
  return Alert.info("" + msg, {
    customFields: {
      empresa: empresa,
      licitacao_cod: licitacao_cod,
      id_licitacao: id_licitacao,
      id_fornecedor: id_fornecedor,
      type: tipostring,
      portal: portal,
      situacao,
      tipostring
    },
    width: 500,
    position: "bottom-right",
    effect: "bouncyflip",
    timeout: 30000,
    html: true
  });
}

export const ToastSuccess = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast.success(info, configPadrao)
}

export const ToastError = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast.error(info, configPadrao)
}

export const ToastWarning = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast.warn(info, configPadrao)
}

export const ToastInfo = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast.info(info, configPadrao)
}

export const ToastDefault = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast(info, configPadrao)
}

export const ToastDark = (info, config) => {
  let configPadrao;

  configPadrao = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (config) configPadrao = { ...configPadrao, ...config };

  return toast.dark(info, configPadrao)
}


export function AlertaAzulNotificacaoSituacao(
  msg,
  id_licitacao,
  id_fornecedor,
  portal
) {
  return Alert.info("" + msg, {
    customFields: {
      id_licitacao: id_licitacao,
      id_fornecedor: id_fornecedor,
      tipo: "situacao",
      portal: portal
    },
    position: "bottom-right",
    effect: "genie",
    timeout: 30000,
    html: true
  });
}

export function FormatDateData(data) {
  if (!data) return '';
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: true
  }).format(new Date(data));
}

export function FormatDateSemHora(data) {
  return new Intl.DateTimeFormat("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  }).format(new Date(data));
}

export function FormatDateHora(data) {
  return new Intl.DateTimeFormat("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  }).format(new Date(data));
}

export function DataAtual() {
  let data = new Date();
  let dia = data.getDate();
  let mes = data.getMonth() + 1;
  let ano = data.getFullYear();
  if (dia < 10) {
    dia = "0" + String(dia) + "/";
  } else {
    dia = String(dia) + "/";
  }
  if (mes < 10) {
    mes = "0" + String(mes) + "/";
  } else {
    mes = String(mes) + "/";
  }
  return dia + mes + ano;
}

export function getHour(date) {
  const index = date.lastIndexOf("/") + 5;
  return date.substr(index);
}

export const DateStringToObject = string => {
  // Take dd/mm/yyyy string and converts to Date object
  if (!string) return new Date();
  const day = string.slice(0, 2);
  const month = string.slice(3, 5);
  const year = string.slice(6, 10);
  const hour = string.slice(11, 13)
  const minutes = string.slice(14)

  return new Date(`${month}/${day}/${year} ${hour}:${minutes}`);
};

export const DiffBetweenTwoDates = (date1, date2 = new Date()) => {
  // Expects 2 dates objects, or will have the second one as today.
  const diffInTime = date1.getTime() - date2.getTime();
  return parseInt(diffInTime / (1000 * 3600 * 24));
};

export function removeAcento(text) {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text;
}

export function removeSpecialChar(text) {
  text = text.toLowerCase();
  text = text.replace(new RegExp("°", 'gi'), '?');
  text = text.replace(new RegExp('º', 'gi'), '?');
  text = text.replace(new RegExp('ª', 'gi'), '?');
  text = text.replace(new RegExp('§', 'gi'), '?');
  text = text.replace(new RegExp('&', 'gi'), '?');
  text = text.replace(new RegExp('¨', 'gi'), '?');
  return text;
}
