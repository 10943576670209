import React, { Component } from "react";
import { Col, Grid, Image, Jumbotron, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LOGO from '../../Images/logotipo_2017.png'
import MaquinaDeEstadosStore from "../MaquinaDeEstadosStore";

class PaginaNaoEncontrada extends Component {
  componentDidMount() {
    MaquinaDeEstadosStore._props = this.props;
  }
  render() {
    return (
      <Grid>
        <style type="text/css">
          {`
                        .um {
                            background-image: -webkit-linear-gradient(45deg, #8089ff 0, #54ceff 51%, #8089ff 100%);
	                        background-image: linear-gradient(45deg, #8089ff 0, #54ceff 51%, #8089ff 100%);
                        }
                    `}
        </style>
        <Row className="show-grid">
          <Col style={{ marginLeft: "-8%", marginTop: "19%", width: "100%" }}>
            <Jumbotron
              style={{
                background: "#FFF",
                textAlign: "center",
                marginRight: "-150px"
              }}
            >
              <h1 style={{ fontSize: "180px", color: "#1E90FF" }}>
                <b>404!</b>
              </h1>
              <h2>PÁGINA NÃO ENCONTRADA!</h2>
              <br />
              <p>
                O conteúdo que você solicitou não foi encontrado em nossos
                servidores.
              </p>
              <p>
                <br />

                <Link to={"/dashboard"} title={"Login"}>
                  <Row className="row-logo">
                    <Col md={12} className="text-center ">
                      <Image
                        src={LOGO}
                        style={{ width: "20%" }}
                      />
                      <br />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#1E90FF",
                          marginTop: "10px"
                        }}
                      >
                        CLIQUE AQUI PARA VOLTAR
                      </p>
                    </Col>
                  </Row>
                </Link>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default PaginaNaoEncontrada;
