import React from 'react'
import DataTable from 'react-data-table-component'
import { Pagination } from '@material-ui/lab'

import { columnsBuscaManual } from './Column/tabelaPesquisa'
import { sortEditais } from '../Components/sortEditais'
import { ExclamationWarning } from '../../../Components/Notifications/ExclamationWarning'
import { useFiltroEdital } from '../Filter/context/FiltroEditaisContext'

import { ReactTooltip } from '../../../globalStyles'
import * as S from '../Style'

const TabelaEditais = ({
  toggleModal,
  crescente,
  editais,
  total_paginas,
  page,
  handlePagina,
  warningErrorInRequest
}) => {
  const { state } = useFiltroEdital()

  return (
    <>
      {state.filtro && (
        <S.ContainerTable filter={state.filtro} height="calc(100vh - 126px)">
          <ReactTooltip />
          <DataTable
            className="tabela-padrao"
            fixedHeader
            allowOverflow={true}
            overflowY={true}
            noHeader
            responsive={true}
            columns={columnsBuscaManual}
            onRowClicked={({ id }) => toggleModal(id, state)}
            data={editais}
            onSort={(c, d) => sortEditais(c, d, state)}
            defaultSortAsc={crescente}
            sortServer={true}
            striped={true}
            highlightOnHover
            noDataComponent={
              <ExclamationWarning title={warningErrorInRequest} height="70vh" />
            }
          />
          <S.ContainerPagination>
            <div>
              <Pagination
                count={total_paginas}
                color="primary"
                shape="rounded"
                page={page}
                onChange={(event, value) => handlePagina(event, value)}
                showFirstButton
                showLastButton
              />
            </div>
          </S.ContainerPagination>
        </S.ContainerTable>
      )}
    </>
  )
}

export default TabelaEditais
