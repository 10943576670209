import React from 'react';
import {Container} from "./styles";

const SectionTitle = ({ icon, title }) => {
  return (
    <Container>
      <div className="icon">
        {icon}
      </div>
      <div className="title">
        {title}
      </div>
    </Container>
  )
}

export default SectionTitle
