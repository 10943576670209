import Modal from "react-modal";
import ReactModal from "react-modal";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Grid, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";

const customStyleLoad = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.85)"
  },
  content: {
    color: "white",
    // borderRadius: 5,
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // borderColor: '#CFCFCF',
    // backgroundColor: '#fff',
    // width: '250px',
    // height: '50px',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: "1",
    padding: "10px"
    // boxShadow: '0 4px 25px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }
};

@inject("ModalLoadingStore")
@observer
class ModalLoading extends Component {
  componentWillMount() {
    Modal.setAppElement("body");
  }

  render() {
    return (
      <ReactModal
        style={customStyleLoad}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="fi"
        isOpen={this.props.ModalLoadingStore.estado}
        onRequestClose={this.props.ModalLoadingStore.handleCloseModal}
      >
        <Grid fluid={true} style={{ height: "100%" }}>
          <Row className="text-center">
            <style type="text/css">
              {`
                                       .blur-container{
                                            -webkit-filter: blur(5px);
                                            -moz-filter: blur(5px);
                                            -o-filter: blur(5px);
                                            -ms-filter: blur(5px);
                                            filter: blur(5px);
                                        }
                                    `}
            </style>
            <h3>
              <FontAwesomeIcon icon={faSpinner} spin /> CARREGANDO{" "}
            </h3>
          </Row>
        </Grid>
      </ReactModal>
    );
  }
}

export default ModalLoading;
