import { observable, action } from "mobx";
import Api from "../../../Stores/Conexao/conexao";
import maquinadeestadosstore from "../../../Stores/MaquinaDeEstadosStore";
// import CadastrarPropostaStore from '../CadastrarProposta/store';
import { AlertaError, AlertaSuccess, DataAtual, AlertaVermelho, ToastSuccess, ToastInfo, ToastError } from "../../../Stores/Funcoes/FuncoesGerais";
// import ItensPropostaStore from '../CadastrarProposta/contexto/Itens/store';
import GerenciarProposta from "../GerenciarProposta/store";
// import { roundToNearestMinutesWithOptions } from "date-fns/esm/fp";
import { ReactTooltip } from "../../../globalStyles";

class imprimirPropostaStore {
  @observable avatarEmpresa = "";
  @action excluirAvatar = () => {
    ReactTooltip.hide();
    Api.delete('/portal/proposta_logo/', {
      data: {
        logo: this.avatarEmpresa
      }
    }).then(() => {
      this.avatarEmpresa = "";
      ToastSuccess('Logo removida')
    }).catch(err => {
      ToastError('Erro ao remover logo')
    })
  };
  @observable uploadingAvatar = false;
  @observable uploadingPDF = false;
  @observable PDFSent = false;
  @action handleSelectedImage = e => {
    const img = e.target.files[0]
    if (!img) return
    if (img && img.size > 28209) {
      AlertaError('Erro ao salvar. Tamanho máximo permitido de 29KB.')
      return (e.target.value = null)
    }
    document.getElementById('company-logo').style.width = '50px'
    document.getElementById('company-logo').style.height = '50px'
    // CALL TO API
    const data = new FormData()
    data.append('logo', img)
    data.append('fornecedor', maquinadeestadosstore.getIdEmpresa())
    this.uploadingAvatar = true
    Api.post('/portal/proposta_logo/', data)
      .then(res => {
        this.avatarEmpresa = res.data.logo
        AlertaSuccess('Imagem salva')
      })
      .finally(() => {
        this.uploadingAvatar = false
      })
  }

  @observable assinatura = "";
  @observable loadingAssinatura = false;
  @action excluirAssinatura = () => {
    ReactTooltip.hide();
    Api.delete('/portal/proposta_assinatura/', {
      data: {
        assinatura: this.assinatura
      }
    }).then(() => {
      this.assinatura = "";
      ToastSuccess('Assinatura removida')
    }).catch(err => {
      ToastError('Erro ao remover assinatura')
    })
  }
  @action handleAssinatura = img => {
    // const img = e.target.files[0];
    // if (!img) return;
    // if (img && img.size > 3000000) {
    //   alert('Tamanho máximo permitido de 3mb.');
    //   return e.target.value = null;
    // }
    // const doc = document.getElementById('assinatura-pdf');
    // if (doc) {
    //   doc.style.maxWidth = '200px';
    //   doc.style.maxHeight = '120px';
    // }
    // CALL TO API
    this.loadingAssinatura = true;
    const data = new FormData;
    data.append('assinatura', img);
    data.append('fornecedor', maquinadeestadosstore.getIdEmpresa());
    Api.post('/portal/proposta_assinatura/', data).then((res) => {
      this.assinatura = res.data.assinatura;
      ToastSuccess('Assinatura enviada.')
    }).catch(err => {
      AlertaVermelho('Erro ao enviar assinatura.');
    }).finally(() => {
      this.loadingAssinatura = false;
    });
  }

  @observable error = null;

  @observable id = null;
  @observable razaoSocial = "";
  @observable nomeFantasia = "";
  @observable cnpj = "";
  @observable inscEstadual = "";
  @observable inscMunicipal = "";
  @observable telefone = "";
  @observable celular = "";
  @observable rua = "";
  @observable numero = "";
  @observable bairro = "";
  @observable cidade = "";
  @observable uf = "";
  @observable complemento = "";
  @observable cep = "";
  @observable email = "";
  @observable site = "";

  @observable nome = "";
  @observable rg = "";
  @observable cpf = "";
  @observable dadosBancarios = "";
  @observable observacoes = "";
  @observable assinatura = "";
  @observable validade = "";

  @observable cabecalhoDesabilitado = JSON.parse(localStorage.getItem('proposta-cabecalho-desabilitado')) || false;

  @action changeCabecalhoDesabilitado = () => {
    this.cabecalhoDesabilitado = !this.cabecalhoDesabilitado;
    localStorage.setItem('proposta-cabecalho-desabilitado', this.cabecalhoDesabilitado);
  }



  // DECLARACAO
  @observable declaracao = {};

  @action
  getDeclaracao = async () => {
    try {
      const res = await Api.get(`portal/proposta_declaracao/?fornecedor=${maquinadeestadosstore.getIdEmpresa()}`);
      this.declaracao = res.data
    } catch (err) {
    }
  }

  @action excluirProposta = (id) => {
    Api.delete(`/portal/proposta_excluir/${id}`).then((res) => {
      ToastInfo('Excluindo proposta...');
      GerenciarProposta.fetchPropostas();

    }).finally(() => {

    });
  }

  @action salvarDeclaracao = (data) => {
    Api.post('/portal/proposta_declaracao/', data).then((res) => {
      ToastSuccess("Dados salvos")
    }).finally(() => {
    });
  }

  @observable modalImprimir = false;
  @action toggleModalImprimir = () => {
    this.PDFSent = false;
    this.modalImprimir = !this.modalImprimir;
  }

  @action onChange = (e) => {
    const { target: { name, value } } = e;
    this[name] = value;
  }


  @action validation = () => {
    if (JSON.parse(localStorage.getItem('proposta-cabecalho-desabilitado'))) return true;
    // const key = [
      // this.razaoSocial,
      // this.cnpj,
      // this.inscEstadual,
      // this.inscMunicipal,
      // this.telefone,
      // this.rua,
      // this.numero,
      // this.bairro,
      // this.nome,
      // this.rg,
      // this.cpf,
      // this.dadosBancarios,
      // this.cidade,
      // this.uf,
      // this.cep,
      // this.email,
      // this.validade
    // ]

    // const validations = key.every((key) => {
    //   if (key !== "" && key != null) return true
    // });

    return true
  }





  @action getData = () => {
    return {
      nome_representante: this.nome,
      rg: this.rg,
      cpf: this.cpf,
      dados_bancario: this.dadosBancarios,
      validade_proposta: this.validade,
      obs: this.observacoes,
      assinatura: this.assinatura,
      id: this.id,
      fornecedor: {
        cnpj: this.cnpj,
        nome_fantasia: this.nomeFantasia, // PRECISA MANDAR ISSO?
        razao_social: this.razaoSocial,
        phone: this.telefone,
        insc_municipal: this.inscMunicipal,
        insc_estadual: this.inscEstadual,
        celular: this.celular,
        website: this.site,
        email: this.email,
        logo: this.avatarEmpresa,
        endereco: {
          estado: this.uf,  // PRECISA MANDAR ISSO?
          uf: this.uf,
          cidade: this.cidade,
          rua: this.rua,
          bairro: this.bairro,
          numero: this.numero,
          complemento: this.complemento,
          cep: this.cep
        }
      }
    }
  }

  @action salvarDados = async (e) => {
    if (!this.validation()) {
      return ToastError('Preencha os campos que estão em vermelho')
    }
    // if (!this.assinatura) return AlertaError('É necessário inserir uma assinatura');
    try {
      await Api.post('/portal/proposta_conf/', this.getData());
      ToastSuccess('Dados salvos');
    } catch (err) {};
  };

  @action
  buscarDados = async (setLoading) => {
    if (setLoading) setLoading(true);
    try {
      const res = await Api.get(`portal/proposta_conf/?fornecedor=${maquinadeestadosstore.getIdEmpresa()}`);
      const { assinatura, cpf, dados_bancario, fornecedor, id, nome_representante, obs, rg, validade_proposta } = res.data;
      const { celular, cnpj, email, endereco, insc_estadual, insc_municipal, logo, nome_fantasia, phone, razao_social, website } = fornecedor;
      const { bairro, cep, cidade, complemento, estado, numero, rua, uf } = endereco;
      this.assinatura = assinatura;
      this.cpf = cpf;
      this.dadosBancarios = dados_bancario;
      this.id = id;
      this.nome = nome_representante;
      this.observacoes = obs;
      this.rg = rg;
      this.validade = 'Data de validade conforme o edital'
      this.celular = celular;
      this.cnpj = cnpj;
      this.email = email;
      this.inscEstadual = insc_estadual;
      this.inscMunicipal = insc_municipal;
      this.avatarEmpresa = logo;
      this.nomeFantasia = nome_fantasia;
      this.telefone = phone;
      this.razaoSocial = razao_social;
      this.site = website;
      this.bairro = bairro;
      this.cep = cep;
      this.cidade = cidade;
      this.complemento = complemento;
      this.uf = estado;
      this.numero = numero;
      this.rua = rua;
      this.uf = uf;
      this.validade = validade_proposta || "Data de validade conforme edital";
    } catch (err) { } finally {
      if (setLoading) setLoading(false);
    }
  }

  trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.buscarDados();
  }

  limparVariaveis = () => {
    this.avatarEmpresa = null;
    this.uploadingAvatar = false;
    this.nomeFantasia = "";
    this.validade = DataAtual()
    this.razaoSocial = "";
    this.cnpj = "";
    this.inscEstadual = "";
    this.inscMunicipal = "";
    this.telefone = "";
    this.celular = "";
    this.rua = "";
    this.numero = "";
    this.bairro = "";
    this.cidade = "";
    this.uf = "";
    this.complemento = "";
    this.cep = "";
    this.email = "";
    this.site = "";

    this.nome = "";
    this.rg = "";
    this.cpf = "";
    this.dadosBancarios = "";
    this.observacoes = "";
  }
}

const ImprimirPropostaStore = new imprimirPropostaStore();

export default ImprimirPropostaStore;
