import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { AlertCircle } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalOverlay } from '../Components/newModal/sytleModal'
import {
  ModalContainer,
  ModalHead,
  ModalTitle,
  BodyMessage,
  ActionButtons
} from '../Pages/Monitoring/styles/styles'

@inject('StatusConexaoStores')
@observer
class ModalStatusConexao extends Component {
  render() {
    const {
      isActive,
      handleCloseModal: closeModal
    } = this.props.StatusConexaoStores
    return (
      <>
        {isActive && (
          <ModalOverlay>
            <ModalContainer
              width="700px"
              left="unset"
              right="unset"
              top="unset"
              bottom="unset"
            >
              <ModalHead>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <AlertCircle color="#fff" /> */}
                  <FontAwesomeIcon icon="wifi" color="#FFF" />
                  <ModalTitle>STATUS CONEXÃO</ModalTitle>
                </div>
              </ModalHead>
              <div
                style={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}
              >
                <AlertCircle color={'#3A566E'} size={'5rem'} />
              </div>

              <BodyMessage>Verifique sua conexão com a internet.</BodyMessage>
            </ModalContainer>
          </ModalOverlay>
        )}
      </>
    )
  }
}

export default ModalStatusConexao
