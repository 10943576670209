import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-self: flex-end;
  position: absolute;
  margin: ${props => props.margin || null};
  left: ${props => props.left || 0};

  select {
    border-radius: 7px;
    height: 27px;
  }
`

export const SinglePerfil = styled.div`
  position: relative;
  color: #fff;
  padding: 2px 20px;
  margin: 0 10px;
  background: #5ea3cc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 120px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  opacity: ${props => (props.active ? 1 : '0.6')};
  cursor: pointer;
  transition: 300ms;
  user-select: none;

  :hover {
    opacity: ${props => (props.active ? 1 : '0.8')};
  }

  .counter {
    position: absolute;
    right: -4px;
    top: -5.5px;
    border-radius: 7px;
    padding: 0px 4px;
    background: ${props => (props.active ? '#f9ab19' : '#fff')};
    color: black;
    min-width: 30px;
    font-size: 11px;
    font-weight: 700;
    box-shadow: 0px 0px 5px #3e3c3c;
    transition: 400ms;
    user-select: none;
  }
`
export const ContainerSubHeader = styled.div`
  background: #3a566e;
  padding: 0 30px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

export const Overflow = styled(Container)`
  position: absolute;
  top: 48px;
  width: 100%;
  background: #3a566e;
`;