import React, { useContext } from 'react';
import { CheckCircle } from 'react-feather';
import styled from 'styled-components';
import { PropostaEspecificaContext } from '../context';
import { FluxoDeEnvioContext } from './context';

const Container = styled.div`
  display: flex;
  font-weight: 500;
  align-items: center;
  padding: 0px 18px;
  margin: 7px 0;
  font-size: 14px;
  border-radius: 10px;
  transition: 300ms ease-in;
  cursor: pointer;
  color: ${props => props.active ? "#1b77a2" : props.finished ? "#138213" : "#5d5d5d8c"};

  @media (max-width: 1342px) {
    padding: 0px 10px;
  };

  @media (max-width: 1242px) {
    padding: 0 4px;
  }

  .passo-icon {
    width: 38px;
    height: 38px;
    max-height: 38px;
    max-width: 38px;
    min-width: 38px;
    min-height: 38px; 
    background: ${props => props.active ? '#fff' : "#ffffff8c"};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 50%;
    font-weight: 700;
    border: 1px solid ${props => props.active ? "#1b77a2" : props.finished ? "#15a215" : "#5d5d5d8c"};
  }

  .passo-numero {
    font-size: 34px;
    margin: 0px 10px 3px 0px;
    font-weight: 600;
  }
  
  .container-title {
    display: flex;
    flex-direction: column;

    .passo-title {
      font-size: 12px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 7px;
        width: 16px;
      }
    }
    .passo-subtitle {
      font-size: 10px;
    }
  }

  

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
`;

const Passo = ({ numero, title, icon, subtitle }) => {
  const { steps, setActive } = useContext(FluxoDeEnvioContext);
  const { propostaEnviada } = useContext(PropostaEspecificaContext);

  const titleStatus = title.replace(/ /g, '_');
  const { active, finished } = steps.find(step => step.name === titleStatus);


  return (
    <Container active={active} /* onClick={() => { !propostaEnviada && finished && setActive(titleStatus) }} */ finished={finished} >
      <div className="passo-icon">{icon}</div>
      <div className="passo-numero">{numero}</div>
      <div className="container-title">
        <div className="passo-title">{title} {finished ? <CheckCircle /> : null} </div>
        <div className="passo-subtitle">{subtitle}</div>
      </div>
    </Container>
  )
}

export default Passo