import { observable, action } from "mobx";
import Api from "./Conexao/conexao";
import $ from "jquery";
import ModalLoadingStore from "./ModalLoadingStore";
import "../Debug";
import {
  AlertaVerde,
  AlertaVermelho,
  AlertaError,
  DateStringToObject,
  FormatDateData,
  FormatDateHora
} from "./Funcoes/FuncoesGerais";
import MaquinaDeEstadosStore from "./MaquinaDeEstadosStore";
import SideStores from "./SideStores";
import { situacao } from "../Pages/BiddingPanel/styles";
import moment from "moment";
import { ConsoleHelper } from "../utils/ConsoleHelper";

moment.locale("pt-BR");
class LicitacoesPaginadaStore {
  @observable
  licitacoes = [];
  @observable
  pregaoSuspenso = [];
  @observable
  licitacoes_total = 0;
  @observable
  numero_licitacoes_suspenso = 0;
  @observable
  numero_licitacoes_agendados = 0;
  @observable
  numero_licitacoes_andamento = 0;
  @observable
  numero_licitacoes_ata = 0;
  @observable
  numero_licitacoes_canceladas = 0;
  @observable
  numero_licitacoes_realizadas = 0;
  @observable
  tipo_licitacao = "todas";
  @observable
  pagina_atual = 1;
  @observable
  real_total = 0;
  @observable
  isLoading = true;
  @observable
  licitacoesFilter = [];
  @observable
  agenda = [];
  @observable
  licitacaoMonitorada = [];
  @observable
  totalAmountOfBids = 0;
  @observable
  valueInputSearch = "";

  @action
  setValueInputSearch = value => (this.valueInputSearch = value);

  @observable
  CheckMonitorada = false;

  @observable
  isActive = false;

  // PORTAL
  @observable page = 1;
  @observable portal = "todos";
  @observable portal_all = [5, 6, 9, 877, 930];
  @observable tipo_licitacao = "todas";
  @observable research = "";

  @observable
  animation = false;

  @observable status_limite_monitoramento = false;
  @observable numero_monitorar_chat = 0;

  @action setTipoLicitacao = tipo_licitacao =>
    (this.tipo_licitacao = tipo_licitacao);

  @action setPortal = portal => {
    this.portal = portal;
  };

  @action changePage = page => (this.page = page);

  @action togleModal = () => {
    this.animation = !this.animation;
    setTimeout(() => {
      this.isActive = !this.isActive;
    }, 400);
  };

  @action excluirAgendada = id => {
    Api.post("/licitacoes/desmarca_licitacao/", {
      licitacao: id
    })
      .then(res => {
        this.licitacaoMonitorada = this.licitacaoMonitorada.filter(
          el => el.id !== id
        );
        this.CheckLicitacao(id, true);
        AlertaVerde("Licitação removida das monitoradas!", 1500);
      })
      .catch(err => AlertaError("Erro ao remover licitação das monitoradas!"));
  };

  @action
  AddLicitacoesMonitoradas = () => {
    ModalLoadingStore.handleOpenModal();
    let id = this.licitacaoMonitorada.map(({ id }) => {
      return id;
    });

    Api.post(`licitacoes/monitorar_chat_status/`, { licitacao: id })
      .then(response => {
        this.consulta_api_sem_loading();
        ModalLoadingStore.handleCloseModal();
        AlertaVerde("Licitações adicionadas com sucesso", 1500);
      })
      .catch(error => {
        ModalLoadingStore.handleCloseModal();
        AlertaVermelho("Tente mais tarde");
      });
  };

  @action AddlicitacaoMonitorada = licitacao => {
    if (
      this.status_limite_monitoramento === true ||
      this.licitacaoMonitorada.length === 10
    ) {
      AlertaVermelho("Limite excedido de monitoramento");
    } else {
      this.licitacaoMonitorada = [...this.licitacaoMonitorada, licitacao];
      this.CheckLicitacao(licitacao.id);
      this.togleModalRapido();
    }
  };

  @action DelllicitacaoMonitorada = licitacao => {
    let dados = [];
    dados = this.licitacaoMonitorada.filter(({ id }) => {
      return Number(id) !== Number(licitacao.id);
    });
    this.licitacaoMonitorada = dados;

    this.CheckLicitacao(licitacao.id);
  };

  @action CheckLicitacao = (id, removerMonitorada) => {
    this.licitacoesFilter = this.licitacoesFilter.map(item => {
      if (Number(item.id) === Number(id)) {
        return {
          ...item,
          check: removerMonitorada ? false : !item.check,
          monitorar_chat: removerMonitorada ? false : item.monitorar_chat
        };
      }
      return item;
    });
  };

  @action
  Agenda = () => {
    this.licitacoesFilter.forEach(item => {
      let data = moment(item.data_disputa).format();
      const scheduleCodes = this.agenda.map(elem => elem.codigo);
      if (scheduleCodes && scheduleCodes.includes(item.codigo)) return;

      this.agenda.push({
        id: item.id,
        codigo: item.codigo,
        title: `Cod.: ${item.codigo} | ${item.situacao.descricao} `,
        descricao: situacao(item.situacao.cor),
        desc: item.situacao.descricao,
        start: new Date(new Date(data)),
        end: new Date(new Date(data)),
        action: "select"
      });
    });
  };

  statusLimiteMonitoramento = data => {
    if (MaquinaDeEstadosStore.comboIdEmpresaFree) {
      const { status_limite_monitoramento, numero_monitorar_chat } = data;
      this.status_limite_monitoramento = status_limite_monitoramento;
      this.numero_monitorar_chat = numero_monitorar_chat;
    }
  };

  @action
  consulta_api = () => {
    ModalLoadingStore.handleOpenModal();
    Api.get(`licitacoes/licita/${MaquinaDeEstadosStore.getIdEmpresa()}`, {})
      .then(response => {
        this.statusLimiteMonitoramento(response.data);
        this.licitacoes = response.data.licitacoes.map(licita => {
          return {
            ...licita,
            check: false,
            data_disputa: DateStringToObject(licita.data_disputa)
          };
        });
        this.FilterMonitoramento(this.licitacoes);
        this.FilterSituacao("todas");
      })
      .catch(error => {
        ConsoleHelper("err", error);
      })
      .finally(() => ModalLoadingStore.handleCloseModal());
  };

  formatDate = date => {
    const dateList = date.split("/");
    const dd = dateList[0];
    const mm = dateList[1];
    const yyyy = dateList[2].split(" ")[0];
    const time = "T" + dateList[2].split(" ")[1] + ":00";
    return dd + "-" + mm + "-" + yyyy + time;
  };

  getPortalIds = () => {
    if (this.portal === "todos") {
      return this.portal_all.join(",");
    }
    return [Number(this.portal)];
  };

  @action searchBidsInput = async () => {
    ModalLoadingStore.handleOpenModal();
    const provider = MaquinaDeEstadosStore.getIdEmpresa();

    try {
      const response = await Api.get(
        `licitacoes/bid_search/?search=${this.valueInputSearch}&page=${this.page}&provider=${provider}`
      );
      this.statusLimiteMonitoramento(response.data);
      this.totalAmountOfBids = response.data.licitacoes.length;
      this.licitacoesFilter = response.data.licitacoes;
    } catch (err) {
      ConsoleHelper(err);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  formatDate = date => {
    const dateList = date.split("/");
    const dd = dateList[0];
    const mm = dateList[1];
    const yyyy = dateList[2].split(" ")[0];
    const time = "T" + dateList[2].split(" ")[1] + ":00";
    return dd + "-" + mm + "-" + yyyy + time;
  };

  @action
  searchBids = async () => {
    ModalLoadingStore.handleOpenModal();

    const formData = new FormData();
    formData.append("pagina", this.page);
    formData.append("fornecedor_pk", MaquinaDeEstadosStore.getIdEmpresa());
    formData.append("situacao", this.tipo_licitacao);
    formData.append("portal", this.getPortalIds());
    try {
      const response = await Api.post(
        "licitacoes/licita_paginada_alterado/",
        formData
      );
      this.statusLimiteMonitoramento(response.data);
      this.totalAmountOfBids = response.data.licitacoes_total;
      this.licitacoesFilter = response.data.licitacoes.map(item => ({
        ...item,
        check: false,
        data_disputa: DateStringToObject(this.formatDate(item.data_disputa))
      }));
    } catch (err) {
      ConsoleHelper(err);
    } finally {
      ModalLoadingStore.handleCloseModal();
    }
  };

  @action
  consulta_api_sem_loading = () => {
    Api.get(`licitacoes/licita/${MaquinaDeEstadosStore.getIdEmpresa()}`, {})
      .then(response => {
        this.statusLimiteMonitoramento(response.data);
        this.licitacoes = response.data.licitacoes.map(licita => {
          return { ...licita, check: false };
        });
        this.FilterMonitoramento(this.licitacoes);
        this.FilterSituacao("todas");
      })
      .catch(error => {});
  };

  @action
  consulta_api_super = () => {
    ModalLoadingStore.handleOpenModal();
    Api.get(`licitacoes/licita/${MaquinaDeEstadosStore.getIdEmpresa()}`, {})
      .then(response => {
        this.licitacoes = response.data.licitacoes;
        this.FilterSituacao("todas");
      })
      .catch(error => {
        ConsoleHelper("err", error);
        AlertaVermelho("Tente mais tarde");
      })
      .finally(() => {
        ModalLoadingStore.handleCloseModal();
      });
  };

  @action
  Search = value => {
    let dados = this.licitacoes;
    this.research = value;
    this.licitacoesFilter = dados.filter(
      item =>
        item.codigo.toLowerCase().includes(value.toLowerCase()) ||
        `${FormatDateData(item.data_disputa)} ${FormatDateHora(
          item.data_disputa
        ).slice(0, 5)}`
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        (item.comprador.nome &&
          item.comprador.nome.toLowerCase().includes(value.toLowerCase())) ||
        (item.comprador.codigo &&
          item.comprador.codigo.toLowerCase().includes(value.toLowerCase())) ||
        item.situacao.descricao.toLowerCase().includes(value.toLowerCase())
    );
  };

  @action
  FilterMonitoramento = dados => {
    this.licitacaoMonitorada = dados.filter(item => {
      return item.monitorar_chat === true;
    });
  };

  @action
  FilterSituacao = value => {
    this.tipo_licitacao = value;
    let dados = this.licitacoes;

    switch (value) {
      case "andamento":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.includes(value) ||
            item.situacao.cor.includes("#3c8dbc")
        );
        break;
      case "agendados":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.includes("Agendado") ||
            item.situacao.cor.includes("#228B22")
        );
        break;
      case "suspenso":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.includes(value) ||
            item.situacao.cor.includes("#3c8dvc")
        );
        break;
      case "ata":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.includes(value) ||
            item.situacao.cor.includes("#7CCD7C")
        );
        break;
      case "revogado":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.toLowerCase().includes(value) ||
            item.situacao.cor.includes("#FF4500")
        );
        break;
      case "realizado":
        this.licitacoesFilter = dados.filter(
          item =>
            item.situacao.descricao.includes(value) ||
            item.situacao.cor.includes("#FFA500")
        );
        break;
      case "todas":
        this.licitacoesFilter = dados;
        break;
      default:
        return (this.licitacoesFilter = dados.filter(item =>
          item.situacao.descricao.includes(value)
        ));
    }
  };

  consultaTipo = numero_lc_atual => {
    this.licitacoes_total = numero_lc_atual;
    this.pagina_atual = 1;
    this.consulta_api();
  };

  @action
  notificacaoLicitacao = id => {
    return SideStores.situacao_licitacao_id.includes(id);
  };

  @action
  muda_tipo_licitacao(tipo) {
    $("#" + this.tipo_licitacao).removeClass("active");
    this.tipo_licitacao = tipo;
    let numero_lc_atual = 0;
    if (tipo === "todas") {
      numero_lc_atual = this.licitacoes_total;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    } else if (tipo === "andamento") {
      numero_lc_atual = this.numero_licitacoes_andamento;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    } else if (tipo === "suspenso") {
      numero_lc_atual = this.numero_licitacoes_andamento;
      $("#" + tipo).addClass("active");
      this.consulta_api_super();
    } else if (tipo === "agendados") {
      numero_lc_atual = this.numero_licitacoes_agendados;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    } else if (tipo === "realizadas") {
      numero_lc_atual = this.numero_licitacoes_realizadas;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    } else if (tipo === "cancelados") {
      numero_lc_atual = this.numero_licitacoes_canceladas;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    } else if (tipo === "ata") {
      numero_lc_atual = this.numero_licitacoes_ata;
      $("#" + tipo).addClass("active");
      this.consultaTipo(numero_lc_atual);
    }
  }

  @action
  onPageChanged = data => {
    const { currentPage } = data;
    this.pagina_atual = currentPage;
    $("#" + this.tipo_licitacao).removeClass("active");
    this.consulta_api();
  };

  @action
  formatDate(data) {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    }).format(new Date(data));
  }

  @action limparVariaveis = () => {
    this.licitacoes = [];
    this.pregaoSuspenso = [];
    this.licitacoes_total = 0;
    this.numero_licitacoes_suspenso = 0;
    this.numero_licitacoes_agendados = 0;
    this.numero_licitacoes_andamento = 0;
    this.numero_licitacoes_ata = 0;
    this.numero_licitacoes_canceladas = 0;
    this.numero_licitacoes_realizadas = 0;
    this.tipo_licitacao = "todas";
    this.pagina_atual = 1;
    this.real_total = 0;
    this.isLoading = true;
    this.licitacoesFilter = [];
    this.agenda = [];
  };

  @action trocouDeEmpresa = () => {
    this.limparVariaveis();
    this.consulta_api();
  };
}

const licitacoesPaginadaStore = new LicitacoesPaginadaStore();
export default licitacoesPaginadaStore;
